import Vue from "vue";

import $axios from "../../api.js";

const state = () => ({
    journals: [],

    journal: {
        journal_id: 0,
        journal_no: "",
        journal_date: Vue.moment().format("YYYY-MM-DD"),
        journal_type: 1,
        journal_bill_id: 0,
        journal_payment_id: 0,
        journal_description: "",
        journal_detail: [
            {
                coa_id: "",
                debit: 0,
                credit: 0
            },
            {
                coa_id: "",
                debit: 0,
                credit: 0
            }
        ],
        journal_lock: false,
        recurring_journal: false,
        recurring_name: "",
        created_by: "",
        created_at: "",
        bill: {},
        payment: {},
        mutationIn: {},
        mutationOut: {}
    },
    page: 1, //UNTUK MENCATAT PAGE PAGINATE YANG SEDANG DIAKSES
    last_page: 1,
    search: ""
});

const mutations = {
    ASSIGN_DATA(state, payload) {
        state.journals = payload;
        // state.last_page = payload.meta.last_page
        // state.page = payload.meta.current_page
    },
    SET_PAGE(state, payload) {
        state.page = payload;
    },
    ASSIGN_FORM(state, payload) {
        state.journal = {
            journal_id: payload.journal.id,
            journal_no: payload.journal.journal_no,
            journal_date: payload.journal.journal_date,
            journal_type: payload.journal.journal_type,
            journal_bill_id: payload.journal.journal_bill_id,
            journal_payment_id: payload.journal.journal_payment_id,
            journal_description: payload.journal.journal_description,
            journal_detail: payload.journal.detail,
            journal_lock: payload.locked,
            created_by: payload.journal.created_by,
            created_at: payload.journal.created_at,
            bill: payload.journal.bill,
            payment: payload.journal.payment,
            mutationIn: payload.journal.mutation_in,
            mutationOut: payload.journal.mutation_out
        };
    },
    //ME-RESET STATE NEWS MENJADI KOSONG
    CLEAR_FORM(state) {
        state.journal = {
            journal_id: 0,
            journal_no: "",
            journal_date: Vue.moment().format("YYYY-MM-DD"),
            journal_type: 1,
            journal_bill_id: 0,
            journal_payment_id: 0,
            journal_description: "",
            journal_detail: [
                {
                    coa_id: "",
                    debit: 0,
                    credit: 0
                },
                {
                    coa_id: "",
                    debit: 0,
                    credit: 0
                }
            ],
            journal_lock: false,
            recurring_journal: false,
            recurring_name: "",
            created_by: "",
            created_at: "",
            bill: {},
            payment: {},
            mutationIn: {},
            mutationOut: {}
        };
    },
    CLEAR_DATA(state) {
        state.journals = [];
    }
};

const actions = {
    getJournal({ dispatch, commit, state }, payload) {
        let date_start = payload.date_start;
        let date_end = payload.date_end;
        let rows = payload.rows;

        return new Promise((resolve, reject) => {
            $axios
                .get(
                    `/journal?&rows=${rows}&date_start=${date_start}&date_end=${date_end}`
                )
                .then(response => {
                    commit("ASSIGN_DATA", response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                    reject(error);
                });
        });
    },
    getJournalNo({ state }) {
        $axios
            .get(`/journal/get-journal-no/` + state.journal.journal_date)
            .then(response => {
                state.journal.journal_no = response.data;
                this.loading = false;
            })
            .catch(error => {
                error.response.status == 403
                    ? (window.location.href = "/login")
                    : console.log(error);
                this.alert("Something goes wrong!", 2);
                this.loading = false;
            });
    },
    submitJournal({ dispatch, commit, state }) {
        return new Promise((resolve, reject) => {
            $axios
                .post(`/journal`, state.journal)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                });
        });
    },
    editJournal({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.get(`/journal/${payload}/edit`).then(response => {
                commit("ASSIGN_FORM", response.data);
                resolve(response.data);
            });
        });
    },
    updateJournal({ state, commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .put(`/journal/${payload}`, state.journal)
                .then(response => {
                    //FORM DIBERSIHKAN
                    commit("CLEAR_FORM");
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                });
        });
    },
    //MENGHAPUS DATA
    removeJournal({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .delete(`/journal/${payload}`)
                .then(response => {
                    commit("CLEAR_FORM");
                    resolve(response.data);
                })
                .catch(error => {
                    if (error.response) {
                        commit("SET_ERRORS", error.response.data, {
                            root: true
                        });
                    }
                    reject(error);
                });
        });
    },
    generateDataProfitLoss({ dispatch, commit, state }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .get(`/profit-loss/get-profit-loss`)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                    console.log(error);
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                });
        });
    },
    generateDataBalanceSheet({ dispatch, commit, state }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .get(`/balance-sheet/get-balance-sheet`)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                    console.log(error);
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                });
        });
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
