<template>
    <main>
        <hero title="Create Tax" prevBreadcrumb="Tax Data" routeBreadcrumb="tax" currentBreadcrumb="Create Tax"></hero>

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 1"
                style="height:250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 2"
                style="height:250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Save Data</span
                        >
                    </center>
                </div>
            </div>
            <!-- Basic -->
            <div
                class="block col-12 col-md-8 offset-md-2 block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 0"
            >
                <div class="block-content block-content-full">
                    <form
                        method="POST"
                        enctype="multipart/form-data"
                        onsubmit="return false;"
                    >
                        <!--Form-->
                        <div class="row font-size-sm">
                            <div class="col-12 col-md-10 offset-md-1">
                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Tax Code *</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Tax Code"
                                        v-model="tax.tax_code"
                                        :class="{
                                            'is-invalid': errors.tax_code,
                                        }"
                                    />
                                    <transition name="slide-fade">
                                        <p
                                            class="text-danger"
                                            v-if="errors.tax_code"
                                        >
                                            {{ errors.tax_code[0] }}
                                        </p>
                                    </transition>
                                </div>
                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Tax Name *</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Tax Name"
                                        v-model="tax.tax_name"
                                        :class="{
                                            'is-invalid': errors.tax_name,
                                        }"
                                    />
                                    <transition name="slide-fade">
                                        <p
                                            class="text-danger"
                                            v-if="errors.tax_name"
                                        >
                                            {{ errors.tax_name[0] }}
                                        </p>
                                    </transition>
                                </div>
                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Tax Rate (%) *</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Tax Rate"
                                        v-model="tax.tax_rate"
                                        :class="{
                                            'is-invalid': errors.tax_rate,
                                        }"
                                        @keypress="isNumber($event)"
                                    />
                                    <transition name="slide-fade">
                                        <p
                                            class="text-danger"
                                            v-if="errors.tax_rate"
                                        >
                                            {{ errors.tax_rate[0] }}
                                        </p>
                                    </transition>
                                </div>
                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Account Receivable</label
                                    >
                                    <selectize
                                        class="form-control mt-1"
                                        placeholder="Choose Account No."
                                        v-model="tax.tax_coa_ar"
                                        :class="{
                                            'is-invalid-selectize':
                                                errors.tax_coa_ar,
                                        }"
                                    >
                                        <!-- settings is optional -->
                                        <option
                                            v-for="account in receivableAccounts"
                                            :value="account.id"
                                            :key="account.id"
                                        >
                                            {{ account.account_no }}
                                            {{ account.account_name }}
                                        </option>
                                    </selectize>
                                    <transition name="slide-fade">
                                        <p
                                            class="text-danger"
                                            v-if="errors.tax_coa_ar"
                                        >
                                            {{ errors.tax_coa_ar[0] }}
                                        </p>
                                    </transition>
                                </div>
                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Account Payable</label
                                    >
                                    <selectize
                                        class="form-control mt-1"
                                        placeholder="Choose Account No."
                                        v-model="tax.tax_coa_ap"
                                        :class="{
                                            'is-invalid-selectize':
                                                errors.tax_coa_ap,
                                        }"
                                    >
                                        <!-- settings is optional -->
                                        <option
                                            v-for="account in payableAccounts"
                                            :value="account.id"
                                            :key="account.id"
                                        >
                                            {{ account.account_no }}
                                            {{ account.account_name }}
                                        </option>
                                    </selectize>
                                    <transition name="slide-fade">
                                        <p
                                            class="text-danger"
                                            v-if="errors.tax_coa_ap"
                                        >
                                            {{ errors.tax_coa_ap[0] }}
                                        </p>
                                    </transition>
                                </div>
                            </div>
                        </div>
                        <!--END Form-->
                        <!-- Submit -->
                        <div class="row items-push">
                            <div class="col-12 text-center">
                                <p class="font-size-sm text-muted">
                                    * Require to be filled, Make sure all data is
                                    correctly filled
                                </p>
                                <button
                                    type="submit"
                                    class="btn btn-sm btn-success"
                                    v-ripple="{ center: true }"
                                    @click.prevent="submit"
                                >
                                    <i class="fa fa-fw fa-check mr-1"></i> Save
                                </button>
                                <router-link
                                    type="button"
                                    class="btn btn-sm btn-alt-light"
                                    tag="button"
                                    v-ripple="{ center: true }"
                                    :to="{ name: 'tax' }"
                                >
                                    <i class="fa fa-fw fa-times mr-1"></i> Close
                                </router-link>
                            </div>
                        </div>
                        <!-- END Submit -->
                    </form>
                </div>
            </div>
            <!-- END Basic -->
        </div>
        <!-- END Page Content -->
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

export default {
    name: "create-tax",

    data() {
        return {
            loadingPage: 0,
        };
    },
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState("coa", { coas: (state) => state.coas.accounts }),
        ...mapState("tax", {
            tax: (state) => state.tax,
        }),
        payableAccounts: function () {
            if (this.coas) {
                return this.coas.filter(function (coa) {
                    return (
                        coa.account_type === "Account Payable" ||
                        coa.account_type === "Other Liability" ||
                        coa.account_type === "Other Current Liability"
                    );
                });
            }
        },
        receivableAccounts: function () {
            if (this.coas) {
                return this.coas.filter(function (coa) {
                    return (
                        coa.account_type === "Account Receivable" ||
                        coa.account_type === "Other Asset" ||
                        coa.account_type === "Other Current Asset"
                    );
                });
            }
        },
    },
    mounted() {
        this.getCoaAll();
    },
    methods: {
        //VUEX
        ...mapMutations("tax", ["CLEAR_FORM"]),
        ...mapActions("coa", ["getCoaAll"]),
        ...mapActions("tax", ["submitTax"]),

        isNumber: function (evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46
            ) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        submit() {
            this.loadingPage = 2;
            this.submitTax()
                .then((response) => {
                    this.$router.push({ name: "tax" });
                    this.alert("Successfully create Tax Data ", 1);
                    this.loadingPage = 0;
                })
                .catch((error) => {
                    error.response.status != 422
                        ? this.alert(error.message, 2)
                        : "";
                    this.loadingPage = 0;
                });
        },
    },
    destroyed() {
        //FORM DI BERSIHKAN
        this.CLEAR_FORM();
    },
};
</script>
