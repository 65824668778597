<template>
    <main>
        <hero title="Tutorial Video" prevBreadcrumb="Dashboard" routeBreadcrumb="home" currentBreadcrumb="Tutorial Video"></hero>


        <!-- Page Content -->
        <div class="content">
            <!-- Basic -->
            <div class="row">
                <div
                    class="col-12 col-md-9"
                >
                    <!--Loading-->
                    <div class="block" v-if="loadingPage == 1" style="height:250px">
                        <div
                            class="block-content block-content-full pb-0"
                            style="padding-top: 80px"
                        >
                            <grid-loader
                                class="mx-auto"
                                :color="'#70B9EB'"
                                :size="'10px'"
                            ></grid-loader>
                            <center class="mt-2">
                                <span class="text-center" style="opacity:0.7"
                                    >Loading</span
                                >
                            </center>
                        </div>
                    </div>
                    <!--Video Player-->
                    <div class="block block-rounded block-fx-shadow" style="height:640px" v-if="playedVideo.title == '' && loadingPage == 0">
                        <div class="block-content block-content-full text-center pt-4">
                            <img
                                class="mb-4 mt-5"
                                :src="'/media/proxima/tutorial-video.svg'"
                                alt=""
                                style="width:100%; max-width:500px;"
                            />
                            <br />
                            <p class="mb-1">
                                <b> No Video Selected </b> 
                            </p>
                            <p>
                                Please select Proxima Tutorial Video on the Video List box
                            </p>
                            <p>
                                For all further questions, please get in touch with us
                                by <b> Help : Contact Us</b> menu
                            </p>
                        </div>
                    </div>
                    <div class="block block-rounded block-fx-shadow" style="height:640px" v-else>
                        <div class="block-header" style="padding-right: 10px;">
                            <h3 class="block-title pt-3 pb-2">{{playedVideo.title}} <br>
                                <small class="ml-3 text-black-50">Tripasyfo Development</small>
                            </h3>
                            <div class="block-options" style="align-self: start;" v-if="playedVideo.url !== ''">
                                <button type="button" class="btn-block-option" @click="maximizeVideo(playedVideo)">
                                    <i class="far fa-window-maximize"></i>
                                </button>
                            </div>
                        </div>
                        <div class="block-content pb-3 pt-1 block-rounded">
                            <iframe width="100%" height="480" :src="playedVideo.url" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
                <div
                    class="col-12 col-md-3"
                >
                    <div class="block block-rounded block-fx-shadow" style="height:640px">
                        <div class="block-header text-center" style="border-bottom: 1px solid #dee2e6">
                            <h3 class="block-title">Video List</h3>
                        </div>
                        <div class="block-content pb-0 pt-2 block-scroll" style="height:570px">
                            <div class="row mb-2"  v-for="(item, index) in listVideo" :key="index" @click="clickVideo(item)">
                                <div class="col-12 pb-0 px-4">
                                    <p class="mb-1 font-w600">
                                        {{item.title}}
                                    </p>
                                    <img class="image-play-tumbnail text-pointer" src="/media/proxima/play-button.png" width="50px" height="50px" alt="">
                                    <img class="image-tumbnail text-pointer" :src="'https://img.youtube.com/vi/'+ item.id + '/hqdefault.jpg'" width="100%" height="120px" style="border-radius:8px;object-fit: cover;">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- END Basic -->
        </div>
        <!-- END Page Content -->
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import axios from "axios";

export default {
    name: "edit-profile",
    data() {
        return {
            loadingPage: 0,
            playedVideo :{
                title:"",
                url:"",
                id:""
            },
            listVideo:[
                {
                    title:"1. Autentifikasi & Account",
                    url:"https://www.youtube.com/embed/ZnTslFv-icU",
                    id:"ZnTslFv-icU"
                },
                {
                    title:"2. Interface",
                    url:"https://www.youtube.com/embed/la6_EeVnP8w",
                    id:"la6_EeVnP8w"
                },
                {
                    title:"3. Setup",
                    url:"https://www.youtube.com/embed/-S33Rwy6L8Q",
                    id:"-S33Rwy6L8Q"
                },
                {
                    title:"4. Purchase Modul",
                    url:"https://www.youtube.com/embed/suoGlQ1fhHI",
                    id:"suoGlQ1fhHI"
                },
                {
                    title:"5. Bill Modul",
                    url:"https://www.youtube.com/embed/HN0aEfAB3KY",
                    id:"HN0aEfAB3KY"
                },
                {
                    title:"6. Cash and Bank",
                    url:"https://www.youtube.com/embed/wsTqT32JSn0",
                    id:"wsTqT32JSn0"
                },
                {
                    title:"7. Journal",
                    url:"https://www.youtube.com/embed/jImXWRbj-js",
                    id:"jImXWRbj-js"
                },
                {
                    title:"8. Report",
                    url:"https://www.youtube.com/embed/5JXTqnJrfCc",
                    id:"5JXTqnJrfCc"
                },
                {
                    title:"9. Start a New Year",
                    url:"https://www.youtube.com/embed/QnRSJw_hSlk",
                    id:"QnRSJw_hSlk"
                },
            ]
        };
    },
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState(["auth"])
    },
    methods: {
        clickVideo(val){
            this.loadingPage = 1;
            this.playedVideo.title = val.title;
            this.playedVideo.url = val.url;
            this.playedVideo.id = val.id;
            this.loadingPage = 0;
        },
        maximizeVideo(val){
            window.open(
                val.url,
                "newwindow",
                "width=480,height=360"
            );
            this.playedVideo.title = "";
            this.playedVideo.url = "";
            this.playedVideo.id = "";
        }
    }
};
</script>
