<template>
    <main>
        <hero title="Create Receive Payment" prevBreadcrumb="Receive Payment" routeBreadcrumb="receive-payment" currentBreadcrumb="Create Receive Payment"></hero>

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 1"
                style="height:250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 2"
                style="height:250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Save Data</span
                        >
                    </center>
                </div>
            </div>
            <!-- Basic -->
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 0 || loadingPage == 3"
            >
                <div class="block-content block-content-full">
                    <form
                        method="POST"
                        enctype="multipart/form-data"
                        onsubmit="return false;"
                    >
                        <!--Form-->
                        <div class="row font-size-sm">
                            <div class="col-12 py-0">
                                <div class="row">
                                    <div class="col-12 col-lg-6 pt-0">
                                        <label style="opacity: 0.7"
                                            >Payment Information</label
                                        >
                                        <hr class="mt-2 mb-3" />
                                        <div class="form-group">
                                            <label for="example-text-input"
                                                >Deposit Payment To *</label
                                            >
                                            <selectize
                                                class="form-control"
                                                placeholder="Choose Account No."
                                                v-model="
                                                    receivepayment.payment_coa_id
                                                "
                                                :class="{
                                                    'is-invalid-selectize':
                                                        errors.payment_coa_id,
                                                }"
                                            >
                                                <!-- settings is optional -->
                                                <option
                                                    v-for="account in coas"
                                                    :value="account.id"
                                                    :key="account.id"
                                                >
                                                    {{ account.account_no }}
                                                    {{ account.account_name }}
                                                </option>
                                            </selectize>
                                            <transition name="slide-fade">
                                                <p
                                                    class="text-danger"
                                                    v-if="
                                                        errors.payment_coa_id
                                                    "
                                                >
                                                    {{
                                                        errors
                                                            .payment_coa_id[0]
                                                    }}
                                                </p>
                                            </transition>
                                        </div>
                                        <div class="form-group">
                                            <label for="example-text-input"
                                                >Name Contact *</label
                                            >
                                            <selectize
                                                class="form-control"
                                                placeholder="Contact Name"
                                                v-model="
                                                    receivepayment.payment_contact_id
                                                "
                                                :class="{
                                                    'is-invalid-selectize':
                                                        errors.payment_contact_id,
                                                }"
                                                @input="
                                                    changeContact(
                                                        receivepayment.payment_contact_id
                                                    )
                                                "
                                            >
                                                <!-- settings is optional -->
                                                <option
                                                    v-for="contact in contacts"
                                                    :value="contact.id"
                                                    :key="contact.id"
                                                >
                                                    {{ contact.contact_name }}
                                                </option>
                                            </selectize>
                                            <transition name="slide-fade">
                                                <p
                                                    class="text-danger"
                                                    v-if="
                                                        errors.payment_contact_id
                                                    "
                                                >
                                                    {{
                                                        errors
                                                            .payment_contact_id[0]
                                                    }}
                                                </p>
                                            </transition>
                                        </div>
                                        <div class="form-group">
                                            <label for="example-text-input"
                                                >Amount of Receive *</label
                                            >
                                            <money
                                                class="form-control"
                                                style="text-align: right"
                                                v-model="
                                                    receivepayment.payment_amount
                                                "
                                                :class="{
                                                    'is-invalid':
                                                        errors.payment_amount,
                                                }"
                                                spellcheck="false"
                                            ></money>
                                            <transition name="slide-fade">
                                                <p
                                                    class="text-danger"
                                                    v-if="errors.payment_amount"
                                                >
                                                    {{
                                                        errors.payment_amount[0]
                                                    }}
                                                </p>
                                            </transition>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-6 pt-0">
                                        <label style="opacity: 0.7"
                                            >Payment Detail</label
                                        >
                                        <hr class="mt-2 mb-3" />
                                        <div class="form-group pb-2">
                                            <label for="example-text-input"
                                                >Payment Date *</label
                                            >
                                            <date-picker
                                                class="input-group"
                                                v-model="
                                                    receivepayment.payment_date
                                                "
                                                format="DD/MM/YYYY"
                                                valueType="YYYY-MM-DD"
                                                placeholder="Payment Date"
                                                :class="{
                                                    'is-invalid':
                                                        errors.payment_date,
                                                }"
                                                :default-value="new Date()"
                                                :disabled-date="
                                                    disabledDatePicker
                                                "
                                                @input="changeDate()"
                                            ></date-picker>
                                            <transition name="slide-fade">
                                                <p
                                                    class="text-danger"
                                                    v-if="errors.payment_date"
                                                >
                                                    {{ errors.payment_date[0] }}
                                                </p>
                                            </transition>
                                        </div>
                                        <div class="form-group pb-1">
                                            <label for="example-text-input"
                                                >Payment No. *</label
                                            >
                                            <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Payment No."
                                                v-model="
                                                    receivepayment.payment_no
                                                "
                                                :class="{
                                                    'is-invalid':
                                                        errors.payment_no,
                                                }"
                                            />
                                            <transition name="slide-fade">
                                                <p
                                                    class="text-danger"
                                                    v-if="errors.payment_no"
                                                >
                                                    {{ errors.payment_no[0] }}
                                                </p>
                                            </transition>
                                        </div>
                                        <div class="form-group">
                                            <label for="example-text-input"
                                                >Journal Memo *</label
                                            >
                                            <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Journal Memo"
                                                v-model="
                                                    receivepayment.payment_journal_memo
                                                "
                                                :class="{
                                                    'is-invalid':
                                                        errors.payment_journal_memo,
                                                }"
                                            />
                                            <transition name="slide-fade">
                                                <p
                                                    class="text-danger"
                                                    v-if="
                                                        errors.payment_journal_memo
                                                    "
                                                >
                                                    {{
                                                        errors
                                                            .payment_journal_memo[0]
                                                    }}
                                                </p>
                                            </transition>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div class="table-responsive">
                                        <table
                                            class="table table-vcenter table-border"
                                            style="border: 1px solid #eaecee"
                                        >
                                            <thead
                                                style="
                                                    background-color: whitesmoke;
                                                "
                                            >
                                                <tr>
                                                    <th
                                                        class="table-header-relative column-minwidth-150"
                                                    >
                                                        Invoice Number
                                                    </th>
                                                    <th
                                                        class="table-header-relative column-minwidth-150"
                                                    >
                                                        Date
                                                    </th>
                                                    <th
                                                        class="table-header-relative column-minwidth-150"
                                                        width="20%"
                                                    >
                                                        Amount
                                                    </th>
                                                    <th
                                                        class="table-header-relative column-minwidth-150"
                                                        width="20%"
                                                    >
                                                        Total Due
                                                    </th>
                                                    <th
                                                        class="table-header-relative column-minwidth-250"
                                                        width="20%"
                                                    >
                                                        Amount Applied
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="loadingPage == 3">
                                                <tr>
                                                    <td colspan="5">
                                                        <div
                                                            class="spinner-border spinner-border-lg text-info mx-auto mt-3"
                                                            role="status"
                                                            style="
                                                                display: flex;
                                                                justify-content: center;
                                                            "
                                                        ></div>
                                                        <center
                                                            class="mt-2 mb-4"
                                                        >
                                                            <span
                                                                class="text-center"
                                                                style="
                                                                    opacity: 0.7;
                                                                "
                                                                >Get Contact
                                                                Invoices
                                                                Data</span
                                                            >
                                                        </center>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody
                                                v-else-if="
                                                    receivepayment.payment_bills &&
                                                    receivepayment.payment_bills
                                                        .length > 0
                                                "
                                            >
                                                <tr
                                                    v-for="(
                                                        bill, index
                                                    ) in receivepayment.payment_bills"
                                                    :key="index"
                                                >
                                                    <td
                                                        align="center"
                                                        style="
                                                            vertical-align: middle;
                                                        "
                                                    >
                                                        <span>{{
                                                            bill.bill_invoice_number
                                                        }}</span>
                                                    </td>
                                                    <td
                                                        align="center"
                                                        style="
                                                            vertical-align: middle;
                                                        "
                                                    >
                                                        <span>{{
                                                            bill.bill_date
                                                                | moment(
                                                                    "DD/MM/YYYY"
                                                                )
                                                        }}</span>
                                                    </td>
                                                    <td
                                                        align="right"
                                                        style="
                                                            vertical-align: middle;
                                                        "
                                                        class="pr-4"
                                                    >
                                                        <span>{{
                                                            bill.bill_grand_total
                                                                | currency
                                                        }}</span>
                                                    </td>
                                                    <td
                                                        align="right"
                                                        style="
                                                            vertical-align: middle;
                                                        "
                                                        class="pr-4"
                                                    >
                                                        <span>{{
                                                            (bill.bill_grand_total -
                                                                bill.bill_paid_total)
                                                                | currency
                                                        }}</span>
                                                    </td>
                                                    <td
                                                        align="center"
                                                        style="
                                                            vertical-align: middle;
                                                        "
                                                        class="font-size-sm"
                                                    >
                                                        <money
                                                            class="form-control"
                                                            style="
                                                                text-align: right;
                                                            "
                                                            v-model="
                                                                bill.amount_paid
                                                            "
                                                            spellcheck="false"
                                                            :class="{
                                                                'is-invalid': validateAmount(
                                                                    bill.amount_paid,
                                                                    bill.bill_grand_total -
                                                                        bill.bill_paid_total
                                                                ),
                                                            }"
                                                            @input="
                                                                calculateReceivePayment()
                                                            "
                                                        ></money>
                                                        <span
                                                            class="text-danger journal-account-erro"
                                                            v-if="
                                                                validateAmount(
                                                                    bill.amount_paid,
                                                                    bill.bill_grand_total -
                                                                        bill.bill_paid_total
                                                                )
                                                            "
                                                        >
                                                            Amount More Than
                                                            Total Due
                                                        </span>
                                                        <span
                                                            v-if="
                                                                errors[
                                                                    'receivepayment.bills.' +
                                                                        index +
                                                                        '.amount_paid'
                                                                ]
                                                            "
                                                            class="label-error"
                                                        >
                                                            {{
                                                                errors[
                                                                    "receivepayment.bills." +
                                                                        index +
                                                                        ".amount_paid"
                                                                ][0]
                                                            }}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody v-else>
                                                <tr>
                                                    <td colspan="5">
                                                        <center
                                                            style="
                                                                padding-top: 15px;
                                                                padding-bottom: 15px;
                                                                color: #cccccc;
                                                            "
                                                        >
                                                            Invoice Data is
                                                            Empty
                                                        </center>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <p
                                            class="text-muted font-size-sm d-inline d-sm-none"
                                            style="opacity: 0.5"
                                        >
                                            Swipe left here to see full column
                                            >>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--END Form-->
                        <!-- Submit -->
                        <div class="row items-push">
                            <div class="col-6 pt-0">
                                <!-- <p>+ Adjust Payment</p> -->
                                <label for="field-1" class="control-label">
                                    Adjust Payment :
                                </label>
                                <div class="row">
                                    <div
                                        class="col-12 pt-0"
                                        v-if="
                                            receivepayment.adjustment.detail
                                                .length > 0
                                        "
                                    >
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Adjustment Journal Memo"
                                            v-model="
                                                receivepayment.adjustment
                                                    .journal_description
                                            "
                                            :class="{
                                                'is-invalid':
                                                    errors[
                                                        'adjustment.journal_description'
                                                    ],
                                            }"
                                        />
                                        <transition name="slide-fade">
                                            <p
                                                class="text-danger"
                                                v-if="
                                                    errors[
                                                        'adjustment.journal_description'
                                                    ]
                                                "
                                            >
                                                {{
                                                    errors['adjustment.journal_description'][0],

                                                }}
                                            </p>
                                        </transition>
                                    </div>
                                    <div class="col-12 pt-0">
                                        <div
                                            class="row"
                                            v-for="(
                                                adjustment, index
                                            ) in receivepayment.adjustment
                                                .detail"
                                            :key="index"
                                        >
                                            <div
                                                class="col-6 font-size-sm pt-0"
                                            >
                                                <selectize
                                                    class="form-control"
                                                    placeholder="Choose Account No."
                                                    v-model="
                                                        adjustment.coa_id
                                                    "
                                                >
                                                    >
                                                    <!-- settings is optional -->
                                                    <option
                                                        v-for="account in adjustmentAccounts"
                                                        :value="account.id"
                                                        :key="account.id"
                                                    >
                                                        {{ account.account_no }}
                                                        {{
                                                            account.account_name
                                                        }}
                                                    </option>
                                                </selectize>
                                                <span
                                                    v-if="
                                                        errors[
                                                            'adjustment.detail.' +
                                                                index +
                                                                '.coa_id'
                                                        ]
                                                    "
                                                    class="text-danger"
                                                >
                                                    {{
                                                        errors[
                                                            "adjustment.detail." +
                                                                index +
                                                                ".coa_id"
                                                        ][0]
                                                    }}
                                                </span>
                                            </div>
                                            <div
                                                class="col-5 font-size-sm pt-0 pr-1"
                                            >
                                                <money
                                                    class="form-control"
                                                    style="text-align: right"
                                                    spellcheck="false"
                                                    v-model="adjustment.debit"
                                                ></money>
                                                <span
                                                    v-if="
                                                        errors[
                                                            'adjustment.detail.' +
                                                                index +
                                                                '.debit'
                                                        ]
                                                    "
                                                    class="text-danger"
                                                >
                                                    {{
                                                        errors[
                                                            "adjustment.detail." +
                                                                index +
                                                                ".debit"
                                                        ][0]
                                                    }}
                                                </span>
                                            </div>
                                            <div
                                                class="col-1 font-size-sm text-center pt-2 pl-0"
                                            >
                                                <a
                                                    class="btn-icon-only btn-icon-danger pl-0"
                                                    @click="
                                                        removeAdjusment(index)
                                                    "
                                                >
                                                    <i class="fa fa-times"></i>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 pt-0 text-left">
                                                <p
                                                    v-if="
                                                        receivepayment
                                                            .adjustment.detail
                                                            .length <= 0
                                                    "
                                                    class="m-0 mb-1"
                                                    style="color: #cccccc"
                                                >
                                                    Add Adjust Payment if the
                                                    payment needs to be reduced
                                                    by other costs such as
                                                    transfer fees or tax fees
                                                    etc. Adjust Payment will
                                                    create an adjusting journal
                                                    against the receive payment
                                                    journal.
                                                </p>
                                                <span
                                                    @click="addAdjustment()"
                                                    class="text-pointer text-info"
                                                >
                                                    + Add Adjust Payment
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3 pt-0">
                                <label for="field-1" class="control-label">
                                    Out of Balance :
                                </label>
                                <money
                                    class="form-control"
                                    style="text-align: right"
                                    v-model="
                                        receivepayment.payment_out_of_balance
                                    "
                                    spellcheck="false"
                                    :class="{
                                        'is-invalid':
                                            receivepayment.payment_out_of_balance !=
                                            0,
                                    }"
                                    disabled
                                ></money>
                                <transition name="slide-fade">
                                    <p
                                        class="text-danger"
                                        v-if="
                                            receivepayment.payment_out_of_balance !=
                                            0
                                        "
                                    >
                                        Payment Out of Balance
                                    </p>
                                </transition>
                            </div>
                            <div class="col-3 pt-0">
                                <label for="field-1" class="control-label">
                                    Total Aplied :
                                </label>
                                <money
                                    class="form-control"
                                    style="text-align: right"
                                    v-model="
                                        receivepayment.payment_total_applied
                                    "
                                    spellcheck="false"
                                    disabled
                                ></money>
                            </div>
                            <div class="col-12 text-center mb-0 pb-0">
                                <p class="font-size-sm text-muted mb-0">
                                    * Require to be filled, Make sure all data is
                                    correctly filled
                                </p>
                            </div>
                            <div class="col-12 text-center">
                                <button
                                    type="button"
                                    class="btn btn-sm btn-success mb-1"
                                    v-ripple="{ center: true }"
                                    :disabled="
                                        receivepayment.payment_out_of_balance !=
                                            0 ||
                                        receivepayment.payment_total_applied ==
                                            0
                                    "
                                    @click="saveBill()"
                                >
                                    <i class="fa fa-fw fa-check mr-1"></i> Save
                                </button>
                                <router-link
                                    type="button"
                                    class="btn btn-sm btn-alt-light mb-1"
                                    tag="button"
                                    v-ripple="{ center: true }"
                                    :to="{ name: 'receive-payment' }"
                                >
                                    <i class="fa fa-fw fa-times mr-1"></i> Close
                                </router-link>
                            </div>
                        </div>
                        <!-- END Submit -->
                    </form>
                </div>
            </div>
            <!-- END Basic -->
        </div>
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import moment from "moment";

export default {
    name: "create-receive-payment",
    data() {
        return {
            loadingPage: 0,
            disabled: {},
            coaBills: [],
            // adjustment: [
            //     // {
            //     //     coa_id: 0,
            //     //     debit: 0,
            //     // },
            // ],
        };
    },
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState("coa", { coas: (state) => state.coas.accounts }),
        ...mapState("contact", { contacts: (state) => state.contacts }),
        ...mapState("forbiddendate", {
            forbiddenDateLast: (state) => state.forbiddenDateLast,
        }),
        ...mapState("receivepayment", {
            receivepayment: (state) => state.receivepayment,
        }),
        bankAccounts: function () {
            if (this.coas) {
                return this.coas.filter(function (coa) {
                    return coa.account_type === "Bank";
                });
            }
        },
        adjustmentAccounts: function () {
            if (this.coas) {
                return this.coas.filter(function (coa) {
                    return coa;
                });
            }
        },
    },
    created() {
        this.getJournalMemo();
        this.getReceivePaymentNo();
        this.getContactCustomer();
        this.getCoaAll();
        this.getForbiddenDate();
    },
    methods: {
        //VUEX
        ...mapMutations("receivepayment", ["CLEAR_FORM"]),
        ...mapActions("coa", ["getCoaAll"]),
        ...mapActions("contact", ["getContactCustomer"]),
        ...mapMutations(["CLEAR_ERRORS"]),
        ...mapActions("bill", ["getInvoiceContact"]),
        ...mapActions("forbiddendate", ["getForbiddenDate"]),
        ...mapActions("receivepayment", [
            "getReceivePaymentNo",
            "submitReceivePayment",
        ]),

        //METHOD
        disabledDatePicker(date) {
            const lastDisableDate = new Date(
                moment(this.forbiddenDateLast).add(1, "days")
            );
            lastDisableDate.setHours(0, 0, 0, 0);
            return date < lastDisableDate;
        },
        //GET DATA ADDRESS & BEGINING BALANCE CUSTOMER
        changeContact(val) {
            this.loadingPage = 3;
            if (val) {
                this.getInvoiceContact(val)
                    .then((response) => {
                        this.receivepayment.payment_bills = response;
                        this.loadingPage = 0;
                    })
                    .catch((error) => {
                        console.log(error);
                        this.loadingPage = 0;
                    });
            } else {
                this.receivepayment.payment_bills = [];
                this.loadingPage = 0;
            }
            this.getJournalMemo();
        },
        changeDate() {
            this.getReceivePaymentNo();
            // this.getJournalMemo();
        },
        //GET JOURNAL MEMO
        getJournalMemo() {
            if (
                this.receivepayment.payment_contact_id == "" ||
                this.receivepayment.payment_contact_id == 0
            ) {
                let newJournalMemo = "Payment; ";
                this.receivepayment.payment_journal_memo = newJournalMemo;
            } else {
                var result = this.contacts.find((obj) => {
                    return (
                        obj.id ===
                        parseInt(this.receivepayment.payment_contact_id)
                    );
                });
                let newJournalMemo = "Payment; " + result.contact_name;
                this.receivepayment.payment_journal_memo = newJournalMemo;
            }
        },
        addAdjustment() {
            this.receivepayment.adjustment.detail.push({
                coa_id: 0,
                debit: 0,
            });
        },
        removeAdjusment(index) {
            this.receivepayment.adjustment.detail.splice(index, 1);
        },
        calculateReceivePayment() {
            let ammountReceive = this.receivepayment.payment_amount;
            let totalPaid = 0;
            this.receivepayment.payment_bills.forEach((bill) => {
                totalPaid += bill.amount_paid;
            });
            this.receivepayment.payment_total_applied = totalPaid;
            this.receivepayment.payment_out_of_balance =
                ammountReceive - totalPaid;
        },
        validateAmount(amount, due) {
            if (amount > due) {
                return true;
                validateAmountPass = false;
            } else {
                return false;
                validateAmountPass = true;
            }
        },
        saveBill() {
            this.CLEAR_ERRORS();
            this.loadingPage = 2;
            this.submitReceivePayment()
                .then((response) => {
                    this.CLEAR_FORM();
                    this.alert("Successfully create Receive Payment Data ", 1);
                    this.getJournalMemo();
                    this.getReceivePaymentNo();
                    this.getContactCustomer();
                    this.getCoaAll();
                    this.getForbiddenDate();
                    this.loadingPage = 0;
                })
                .catch((error) => {
                    error.response.status == 500
                        ? this.alert(error.response.data.message, 2)
                        : "";
                    error.response.status != 422 && error.response.status != 500
                        ? this.alert(error.message, 2)
                        : "";
                    this.loadingPage = 0;
                });
        },
    },
    destroyed() {
        this.CLEAR_FORM();
    },
};
</script>
