<template>
    <main>
        <hero title="Edit Journal" prevBreadcrumb="Journal Transaction" routeBreadcrumb="journal-transaction" currentBreadcrumb="Edit Journal"></hero>

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 1"
                style="height:250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity:0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 2"
                style="height:250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity:0.7"
                            >Save Data</span
                        >
                    </center>
                </div>
            </div>
            <!-- Basic -->
            <div
                class="block col-12 block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 0"
            >
                <div
                    class="block mb-0"
                    v-if="
                        (journal.journal_bill_id != null &&
                            journal.journal_bill_id != 0) ||
                            (journal.journal_payment_id != null &&
                                journal.journal_payment_id != 0) ||
                            journal.journal_lock == true
                    "
                >
                    <div
                        class="alert alert-info d-flex align-items-center mb-0"
                        role="alert"
                    >
                        <div class="flex-00-auto">
                            <i class="fa fa-fw fa-info-circle"></i>
                        </div>
                        <div class="flex-fill ml-3">
                            <p class="mb-0">
                                This journal is
                                <b>Year End-Closing Journal</b> or
                                <b>Payment/Sales Journal</b>, you can't update
                                or delete this data
                            </p>
                        </div>
                    </div>
                </div>
                <div class="block-content block-content-full">
                    <form
                        method="POST"
                        enctype="multipart/form-data"
                        onsubmit="return false;"
                    >
                        <!--Form-->
                        <div class="row font-size-sm">
                            <div class="col-12 py-0">
                                <div class="row">
                                    <div
                                        class="form-group col-12 col-md-4 py-0"
                                    >
                                        <label for="example-text-input"
                                            >Date</label
                                        >
                                        <date-picker
                                            class="input-group"
                                            v-model="journal.journal_date"
                                            format="DD/MM/YYYY"
                                            valueType="YYYY-MM-DD"
                                            placeholder="Journal Date"
                                            :class="{
                                                'is-invalid':
                                                    errors.journal_date
                                            }"
                                            @input="getJournalNo()"
                                            :disabled="
                                                (journal.journal_bill_id !=
                                                    null &&
                                                    journal.journal_bill_id !=
                                                        0) ||
                                                    (journal.journal_payment_id !=
                                                        null &&
                                                        journal.journal_payment_id !=
                                                            0) ||
                                                    journal.journal_lock == true
                                            "
                                        ></date-picker>
                                        <p
                                            class="text-danger"
                                            v-if="errors.journal_date"
                                        >
                                            {{ errors.journal_date[0] }}
                                        </p>
                                    </div>
                                    <div
                                        class="form-group col-12 col-md-4 py-0 mb-4 mb-md-4"
                                    >
                                        <label for="example-text-input"
                                            >Journal No.</label
                                        >
                                        <div class="input-group">
                                            <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Journal No."
                                                v-model="journal.journal_no"
                                                @input="mustNumber"
                                                :class="{
                                                    'is-invalid':
                                                        errors.journal_no
                                                }"
                                                :disabled="
                                                    (journal.journal_bill_id !=
                                                        null &&
                                                        journal.journal_bill_id !=
                                                            0) ||
                                                        (journal.journal_payment_id !=
                                                            null &&
                                                            journal.journal_payment_id !=
                                                                0) ||
                                                        journal.journal_lock ==
                                                            true
                                                "
                                            />
                                        </div>
                                        <p
                                            class="text-danger"
                                            v-if="errors.journal_no"
                                        >
                                            {{ errors.journal_no[0] }}
                                        </p>
                                    </div>
                                    <div
                                        class="form-group col-12 col-md-4 py-0 "
                                    >
                                        <button
                                            type="button"
                                            class="btn btn-sm btn-info mt-0 mt-md-4 float-right"
                                            v-ripple="{ center: true }"
                                            @click.prevent="
                                                showRecurringModal()
                                            "
                                            :disabled="
                                                (journal.journal_bill_id !=
                                                    null &&
                                                    journal.journal_bill_id !=
                                                        0) ||
                                                    (journal.journal_payment_id !=
                                                        null &&
                                                        journal.journal_payment_id !=
                                                            0) ||
                                                    journal.journal_lock == true
                                            "
                                        >
                                            <i class="fa fa-history mr-1"></i>
                                            Use Recurring Journal
                                        </button>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Journal Memo</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Account Name"
                                        v-model="journal.journal_description"
                                        :class="{
                                            'is-invalid':
                                                errors.journal_description
                                        }"
                                        :disabled="
                                            (journal.journal_bill_id != null &&
                                                journal.journal_bill_id !=
                                                    0) ||
                                                (journal.journal_payment_id !=
                                                    null &&
                                                    journal.journal_payment_id !=
                                                        0) ||
                                                journal.journal_lock == true
                                        "
                                    />
                                    <p
                                        class="text-danger"
                                        v-if="errors.journal_description"
                                    >
                                        {{ errors.journal_description[0] }}
                                    </p>
                                </div>

                                <div class="form-group">
                                    <div
                                        class="form-check form-check-inline"
                                        v-if="
                                            journal.journal_bill_id == null &&
                                                journal.journal_payment_id ==
                                                    null &&
                                                journal.journal_lock == false
                                        "
                                    >
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            v-model="journal.recurring_journal"
                                            value="1"
                                            id="recurring-check-sm"
                                            :disabled="
                                                (journal.journal_bill_id !=
                                                    null &&
                                                    journal.journal_bill_id !=
                                                        0) ||
                                                    (journal.journal_payment_id !=
                                                        null &&
                                                        journal.journal_payment_id !=
                                                            0) ||
                                                    journal.journal_lock == true
                                            "
                                        />
                                        <label
                                            class="form-check-label"
                                            for="recurring-check-sm"
                                            >Save Journal as Recurring
                                            Journal</label
                                        >
                                    </div>
                                    <transition name="slide-fade">
                                        <input
                                            type="text"
                                            class="form-control mt-2"
                                            placeholder="Recurring Name"
                                            v-model="journal.recurring_name"
                                            v-if="journal.recurring_journal"
                                            :class="{
                                                'is-invalid':
                                                    errors.recurring_name
                                            }"
                                            :disabled="
                                                (journal.journal_bill_id !=
                                                    null &&
                                                    journal.journal_bill_id !=
                                                        0) ||
                                                    (journal.journal_payment_id !=
                                                        null &&
                                                        journal.journal_payment_id !=
                                                            0) ||
                                                    journal.journal_lock == true
                                            "
                                        />
                                        <p
                                            class="text-danger"
                                            v-if="errors.recurring_name"
                                        >
                                            {{ errors.recurring_name[0] }}
                                        </p>
                                    </transition>
                                </div>

                                <div class="form-group">
                                    <div class="table-responsive">
                                        <table
                                            class="table table-vcenter table-borderless mb-0"
                                        >
                                            <thead
                                                style="background-color: whitesmoke;"
                                            >
                                                <tr>
                                                    <th
                                                        class="table-header-relative column-minwidth-250"
                                                    >
                                                        Account No.
                                                    </th>
                                                    <th
                                                        class="table-header-relative column-minwidth-150"
                                                        width="20%"
                                                    >
                                                        Debit
                                                    </th>
                                                    <th
                                                        class="table-header-relative column-minwidth-150"
                                                        width="20%"
                                                    >
                                                        Credit
                                                    </th>
                                                    <th
                                                        class="table-header-relative"
                                                        width="50px"
                                                    ></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    v-for="(detail,
                                                    index) in journal.journal_detail"
                                                    :key="index"
                                                >
                                                    <td
                                                        class="font-size-sm delete-effect journal-detail"
                                                    >
                                                        <selectize
                                                            class="form-control mt-1"
                                                            placeholder="Choose Account No."
                                                            v-model="
                                                                detail.coa_id
                                                            "
                                                            :class="{
                                                                'is-invalid-selectize':
                                                                    errors[
                                                                        'journal_detail.' +
                                                                            index +
                                                                            '.coa_id'
                                                                    ]
                                                            }"
                                                            :disabled="
                                                                (journal.journal_bill_id !=
                                                                    null &&
                                                                    journal.journal_bill_id !=
                                                                        0) ||
                                                                    (journal.journal_payment_id !=
                                                                        null &&
                                                                        journal.journal_payment_id !=
                                                                            0) ||
                                                                    journal.journal_lock ==
                                                                        true
                                                            "
                                                        >
                                                            <!-- settings is optional -->
                                                            <option
                                                                v-for="account in coas"
                                                                :value="
                                                                    account.id
                                                                "
                                                                :key="
                                                                    account.id
                                                                "
                                                                >{{
                                                                    account.account_no
                                                                }}
                                                                {{
                                                                    account.account_name
                                                                }}</option
                                                            >
                                                        </selectize>
                                                        <span
                                                            v-if="
                                                                errors[
                                                                    'journal_detail.' +
                                                                        index +
                                                                        '.coa_id'
                                                                ]
                                                            "
                                                            class="text-danger journal-account-error"
                                                        >
                                                            {{
                                                                errors[
                                                                    "journal_detail." +
                                                                        index +
                                                                        ".coa_id"
                                                                ][0]
                                                            }}
                                                        </span>
                                                    </td>
                                                    <td class="font-size-sm">
                                                        <money
                                                            class="form-control"
                                                            style="text-align: right;"
                                                            v-model="
                                                                detail.debit
                                                            "
                                                            spellcheck="false"
                                                            @focus.native="
                                                                setDebit(index)
                                                            "
                                                            @input="
                                                                calculateDebit()
                                                            "
                                                            :disabled="
                                                                (journal.journal_bill_id !=
                                                                    null &&
                                                                    journal.journal_bill_id !=
                                                                        0) ||
                                                                    (journal.journal_payment_id !=
                                                                        null &&
                                                                        journal.journal_payment_id !=
                                                                            0) ||
                                                                    journal.journal_lock ==
                                                                        true
                                                            "
                                                        ></money>
                                                        <span
                                                            v-if="
                                                                errors[
                                                                    'journal.journal_detail.' +
                                                                        index +
                                                                        '.debit'
                                                                ]
                                                            "
                                                            class="label-error"
                                                        >
                                                            {{
                                                                errors[
                                                                    "journal.journal_detail." +
                                                                        index +
                                                                        ".debit"
                                                                ][0]
                                                            }}
                                                        </span>
                                                    </td>
                                                    <td class="font-size-sm">
                                                        <money
                                                            class="form-control"
                                                            style="text-align: right;"
                                                            v-model="
                                                                detail.credit
                                                            "
                                                            spellcheck="false"
                                                            @focus.native="
                                                                setCredit(index)
                                                            "
                                                            @input="
                                                                calculateCredit()
                                                            "
                                                            :disabled="
                                                                (journal.journal_bill_id !=
                                                                    null &&
                                                                    journal.journal_bill_id !=
                                                                        0) ||
                                                                    (journal.journal_payment_id !=
                                                                        null &&
                                                                        journal.journal_payment_id !=
                                                                            0) ||
                                                                    journal.journal_lock ==
                                                                        true
                                                            "
                                                        ></money>
                                                        <span
                                                            v-if="
                                                                errors[
                                                                    'journal.journal_detail.' +
                                                                        index +
                                                                        '.credit'
                                                                ]
                                                            "
                                                            class="label-error"
                                                            >{{
                                                                errors[
                                                                    "journal.journal_detail." +
                                                                        index +
                                                                        ".credit"
                                                                ][0]
                                                            }}</span
                                                        >
                                                    </td>
                                                    <td
                                                        class="font-size-sm"
                                                        align="center"
                                                        v-if="
                                                            journal.journal_bill_id ==
                                                                null &&
                                                                journal.journal_payment_id ==
                                                                    null &&
                                                                journal.journal_lock ==
                                                                    false
                                                        "
                                                    >
                                                        <button
                                                            type="button"
                                                            class="btn-icon-only btn-icon-danger"
                                                            v-if="index > 1"
                                                            @click="
                                                                removeDetail(
                                                                    index
                                                                )
                                                            "
                                                        >
                                                            <i
                                                                class="fa fa-trash"
                                                            ></i>
                                                        </button>
                                                    </td>
                                                </tr>

                                                <tr
                                                    class="hover-hr-table"
                                                    @click="addDetail()"
                                                    v-if="
                                                        journal.journal_bill_id ==
                                                            null &&
                                                            journal.journal_payment_id ==
                                                                null &&
                                                            journal.journal_lock ==
                                                                false
                                                    "
                                                >
                                                    <td
                                                        class="font-size-sm opacity-50"
                                                        colspan="3"
                                                    >
                                                        <hr class="hr-table" />
                                                    </td>
                                                    <td
                                                        class="font-size-sm"
                                                        align="center"
                                                    >
                                                        <a
                                                            class="btn-icon-only icon-succes"
                                                        >
                                                            <i
                                                                class="fa fa-plus"
                                                            ></i>
                                                        </a>
                                                    </td>
                                                </tr>

                                                <tr v-else>
                                                    <td
                                                        class="font-size-sm opacity-50"
                                                        colspan="5"
                                                    >
                                                        <hr class="hr-table" />
                                                    </td>
                                                </tr>

                                                <tr class="total-diff-journal">
                                                    <td
                                                        class="font-size-sm pt-0 pb-3"
                                                    >
                                                        Total
                                                    </td>
                                                    <td
                                                        class="font-size-sm pt-0 pb-3"
                                                        align="right"
                                                    >
                                                        {{
                                                            total_debit
                                                                | currency
                                                        }}
                                                    </td>
                                                    <td
                                                        class="font-size-sm pt-0 pb-3"
                                                        align="right"
                                                    >
                                                        {{
                                                            total_credit
                                                                | currency
                                                        }}
                                                    </td>
                                                    <td
                                                        class="font-size-sm"
                                                        align="center"
                                                    ></td>
                                                </tr>
                                                <tr class="total-diff-journal">
                                                    <td
                                                        class="font-size-sm pt-3 pb-3"
                                                    >
                                                        Difference
                                                    </td>
                                                    <td
                                                        class="font-size-sm pt-3 pb-3"
                                                        align="right"
                                                    >
                                                        <span
                                                            :style="[
                                                                diff_debit > 0
                                                                    ? {
                                                                          color:
                                                                              'red'
                                                                      }
                                                                    : {
                                                                          display:
                                                                              'none'
                                                                      }
                                                            ]"
                                                        >
                                                            {{
                                                                diff_debit
                                                                    | currency
                                                            }}
                                                        </span>
                                                    </td>
                                                    <td
                                                        class="font-size-sm pt-3 pb-3"
                                                        align="right"
                                                    >
                                                        <span
                                                            :style="[
                                                                diff_credit > 0
                                                                    ? {
                                                                          color:
                                                                              'red'
                                                                      }
                                                                    : {
                                                                          display:
                                                                              'none'
                                                                      }
                                                            ]"
                                                        >
                                                            {{
                                                                diff_credit
                                                                    | currency
                                                            }}
                                                        </span>
                                                    </td>
                                                    <td
                                                        class="font-size-sm"
                                                        align="center"
                                                    ></td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        align="center"
                                                        colspan="4"
                                                    >
                                                        <div
                                                            class="col-12 text-center mb-0 pb-0 d-none d-sm-inline-block mt-2"
                                                        >
                                                            <p
                                                                class="font-size-sm text-muted mb-0"
                                                            >
                                                                * Required
                                                                filled, Make
                                                                sure all data is
                                                                correctly filled
                                                            </p>
                                                        </div>
                                                        <div
                                                            class="col-12 text-center d-none d-sm-inline-block"
                                                        >
                                                            <button
                                                                type="submit"
                                                                class="btn btn-sm btn-success"
                                                                v-ripple="{
                                                                    center: true
                                                                }"
                                                                :disabled="
                                                                    diff_status ||
                                                                        (journal.journal_bill_id !=
                                                                            null &&
                                                                            journal.journal_bill_id !=
                                                                                0) ||
                                                                        (journal.journal_payment_id !=
                                                                            null &&
                                                                            journal.journal_payment_id !=
                                                                                0) ||
                                                                        journal.journal_lock ==
                                                                            true
                                                                "
                                                                @click.prevent="
                                                                    submitData
                                                                "
                                                            >
                                                                <i
                                                                    class="fa fa-fw fa-check mr-1"
                                                                ></i>
                                                                Update
                                                            </button>
                                                            <button
                                                                type="button"
                                                                class="btn btn-sm btn-danger"
                                                                v-ripple="{
                                                                    center: true
                                                                }"
                                                                @click.prevent="
                                                                    deleteData
                                                                "
                                                                :disabled="
                                                                    diff_status ||
                                                                        (journal.journal_bill_id !=
                                                                            null &&
                                                                            journal.journal_bill_id !=
                                                                                0) ||
                                                                        (journal.journal_payment_id !=
                                                                            null &&
                                                                            journal.journal_payment_id !=
                                                                                0) ||
                                                                        journal.journal_lock ==
                                                                            true
                                                                "
                                                            >
                                                                <i
                                                                    class="fa fa-fw fa-trash mr-1"
                                                                ></i>
                                                                Delete
                                                            </button>
                                                            <router-link
                                                                type="button"
                                                                class="btn btn-sm btn-alt-light"
                                                                tag="button"
                                                                v-ripple="{
                                                                    center: true
                                                                }"
                                                                :to="{
                                                                    name:
                                                                        'journal-transaction'
                                                                }"
                                                            >
                                                                <i
                                                                    class="fa fa-fw fa-times mr-1"
                                                                ></i>
                                                                Close
                                                            </router-link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <p
                                            class="text-muted font-size-sm d-inline d-sm-none"
                                            style="opacity:0.5;"
                                        >
                                            Swipe left here to see full column
                                            >>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--END Form-->
                        <!-- Submit -->
                        <div class="row items-push d-inline d-sm-none">
                            <div class="col-12 text-center">
                                <p class="font-size-sm text-muted">
                                    * Require to be filled, Make sure all data is
                                    correctly filled
                                </p>
                                <button
                                    type="submit"
                                    class="btn btn-sm btn-success"
                                    v-ripple="{ center: true }"
                                    @click.prevent="submitData"
                                    :disabled="
                                        diff_status ||
                                            (journal.journal_bill_id != null &&
                                                journal.journal_bill_id !=
                                                    0) ||
                                            (journal.journal_payment_id !=
                                                null &&
                                                journal.journal_payment_id !=
                                                    0) ||
                                            journal.journal_lock == true
                                    "
                                >
                                    <i class="fa fa-fw fa-check mr-1"></i>
                                    Update
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-sm btn-danger"
                                    v-ripple="{ center: true }"
                                    @click.prevent="deleteData"
                                    :disabled="
                                        diff_status ||
                                            (journal.journal_bill_id != null &&
                                                journal.journal_bill_id !=
                                                    0) ||
                                            (journal.journal_payment_id !=
                                                null &&
                                                journal.journal_payment_id !=
                                                    0) ||
                                            journal.journal_lock == true
                                    "
                                >
                                    <i class="fa fa-fw fa-trash mr-1"></i>
                                    Delete
                                </button>
                                <router-link
                                    type="button"
                                    class="btn btn-sm btn-alt-light"
                                    tag="button"
                                    v-ripple="{ center: true }"
                                    :to="{ name: 'journal-transaction' }"
                                >
                                    <i class="fa fa-fw fa-times mr-1"></i> Close
                                </router-link>
                            </div>
                        </div>
                        <!-- END Submit -->
                        <!-- <span class="opacity-25">Created by {{journal.created_by}} at {{journal.created_at | moment("HH:mm DD/MM/YYYY")}}</span> -->
                    </form>
                </div>
            </div>
            <!-- END Basic -->
        </div>
        <!-- END Page Content -->
        <modal name="use-recurring" width="1000" height="auto" adaptive>
            <RecurringJournal></RecurringJournal>
        </modal>
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import RecurringJournal from "./../../../components/RecurringJournal";

export default {
    name: "create-journal",
    components: {
        RecurringJournal
    },
    data() {
        return {
            loadingPage: 1,
            total_debit: 0,
            total_credit: 0,
            diff_debit: 0,
            diff_credit: 0,
            diff_status: true
        };
    },
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState("coa", { coas: state => state.coas.accounts }),
        ...mapState("journal", { journal: state => state.journal })
    },
    mounted() {
        if (this.$route.params.id) {
            this.editJournal(this.$route.params.id)
                .then(response => {
                    this.loadingPage = 0;
                })
                .catch(error => {
                    this.alert(error.message, 2);
                    this.loadingPage = 0;
                });
        } else {
            this.$router.push({ name: "journal-transaction" });
            this.alert("Data not found, select data first", 2);
        }
        this.getCoaAll();
        // Accept socket from RecurringJournal component
        this.$root.$on("setRecurring", id => {
            this.loading = true;
            this.setRecurring(id);
        });
    },
    methods: {
        //VUEX
        ...mapMutations("journal", ["CLEAR_FORM"]),
        ...mapActions("coa", ["getCoaAll"]),
        ...mapMutations(["CLEAR_ERRORS"]),
        ...mapActions("journal", [
            "getJournalNo",
            "updateJournal",
            "editJournal",
            "removeJournal"
        ]),
        ...mapActions("recurringjournal", ["useRecurring"]),

        //METHOD
        mustNumber() {
            // Only for postfix account no. to prevent user input alphabet
            this.postfix = this.postfix.replace(/[^0-9]/g, "");
            // this.checkNo()
        },
        //DETAIL METHOD
        addDetail() {
            this.journal.journal_detail.push({
                coa_id: this.id_start,
                debit: 0,
                credit: 0
            });
        },
        removeDetail(index) {
            this.journal.journal_detail.splice(index, 1);
        },
        calculateClear() {
            (this.total_debit = 0),
                (this.total_credit = 0),
                (this.diff_debit = 0),
                (this.diff_credit = 0),
                (this.diff_status = true);
        },
        //CALCULATE METHOD
        calculateDebit() {
            var total = 0;
            this.journal.journal_detail.forEach(details => {
                total += details.debit;
            });

            this.total_debit = total;
            this.calcucateDiff();
        },
        calculateCredit() {
            var total = 0;
            this.journal.journal_detail.forEach(details => {
                total += details.credit;
            });

            this.total_credit = total;
            this.calcucateDiff();
        },
        calcucateDiff() {
            if (this.total_debit > this.total_credit) {
                this.diff_credit = this.total_debit - this.total_credit;
                this.diff_debit = 0;
                this.diff_status = true;
            } else if (this.total_credit > this.total_debit) {
                this.diff_debit = this.total_credit - this.total_debit;
                this.diff_credit = 0;
                this.diff_status = true;
            } else if (this.total_credit <= 0 && this.total_debit <= 0) {
                this.diff_status = true;
            } else {
                this.diff_debit = 0;
                this.diff_credit = 0;
                this.diff_status = false;
            }
        },
        //SET METHOD
        setDebit(index) {
            if (index === this.journal.journal_detail.length - 1) {
                if (this.diff_debit !== 0) {
                    this.journal.journal_detail[index].debit += this.diff_debit;
                }
            }
        },
        setCredit(index) {
            if (index === this.journal.journal_detail.length - 1) {
                if (this.diff_credit !== 0) {
                    this.journal.journal_detail[
                        index
                    ].credit += this.diff_credit;
                }
            }
        },
        setRecurring(id) {
            this.useRecurring(id)
                .then(response => {
                    this.journal.journal_detail = response.data.detail;
                })
                .catch(error => {
                    this.alert(error.message, 2);
                    console.log(error);
                });
        },
        showRecurringModal() {
            this.$modal.show("use-recurring");
        },
        submitData() {
            this.CLEAR_ERRORS();
            this.loadingPage = 2;
            this.updateJournal(this.$route.params.id)
                .then(response => {
                    this.$router.push({
                        name: "journal-transaction",
                        params: { reloadData: true }
                    });
                    this.$swal({
                        title: "Success",
                        text: "Successfully update Journal Data",
                        icon: "success",
                        buttons: ["Close", "Journal Voucher"]
                    }).then(willDelete => {
                        if (willDelete) {
                            this.$router.push({
                                name: "journal-transaction-voucher",
                                params: { id: response.journal_id }
                            });
                        }
                    });
                    this.loadingPage = 0;
                })
                .catch(error => {
                    error.response.status == 500
                        ? this.alert(error.response.data.message, 2)
                        : "";
                    error.response.status != 422 && error.response.status != 500
                        ? this.alert(error.message, 2)
                        : "";
                    this.loadingPage = 0;
                });
        },
        deleteData() {
            this.$swal({
                title: "Are you sure ?",
                text: "Deleted data cannot be recovery",
                icon: "warning",
                buttons: ["Cancel", "Delete"],
                dangerMode: true
            }).then(willDelete => {
                if (willDelete) {
                    this.removeJournal(this.$route.params.id)
                        .then(() => {
                            this.$router.push({
                                name: "journal-transaction",
                                params: { reloadData: true }
                            });
                            this.alert("Data has been deleted !", 1);
                            this.loadingPage = 0;
                        })
                        .catch(error => {
                            error.response.status == 500
                                ? this.alert(error.response.data.message, 2)
                                : "";
                            error.response.status != 422 &&
                            error.response.status != 500
                                ? this.alert(error.message, 2)
                                : "";
                            this.loadingPage = 0;
                        });
                }
            });
        }
    },
    destroyed() {
        this.CLEAR_FORM();
    }
};
</script>
