<template>
    <main>
        <hero title="UOM Data" prevBreadcrumb="Dashboard" routeBreadcrumb="home" currentBreadcrumb="UOM Data"></hero>

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 1 || !uoms.data"
                style="height: 250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <!-- Dynamic Table Full -->
            <div class="block block-rounded block-mode-loading-oneui" v-else>
                <div class="block-content block-content-full pt-3">
                    <v-app>
                        <v-data-table
                            :headers="headers"
                            :items="datas"
                            :search="search"
                            :hide-default-footer="true"
                            :items-per-page="50"
                            :mobile-breakpoint="0"
                        >
                            <template
                                v-slot:top="{
                                    pagination,
                                    options,
                                    updateOptions,
                                }"
                            >
                                <div class="row">
                                    <div
                                        class="col-12 col-md-5 mb-2 mb-sm-0 pt-0 pb-2"
                                    >
                                        <v-layout justify-start>
                                            <v-data-footer
                                                :pagination="pagination"
                                                :options="options"
                                                @update:options="updateOptions"
                                                :items-per-page-options="[
                                                    10,
                                                    30,
                                                    50,
                                                    100,
                                                    -1,
                                                ]"
                                                items-per-page-text="Rows :"
                                            />
                                        </v-layout>
                                    </div>
                                    <div
                                        class="col-12 col-md-7 col-lg-7 col-xl-7 pb-0 pt-2"
                                    >
                                        <input
                                            uom="text"
                                            class="form-control form-control-sm col-12 col-md-6 float-right ml-1 mt-1 search-index"
                                            name="example-text-input"
                                            placeholder="Search.."
                                            v-model="search"
                                        />
                                        <router-link
                                            uom="button"
                                            class="btn btn-sm btn-info float-right btn-adjust-1"
                                            :to="{
                                                name: 'uom-create',
                                            }"
                                            tag="button"
                                            v-ripple="{ center: true }"
                                        >
                                            <i class="fa fa-plus mr-1"></i> New
                                            Unit
                                        </router-link>
                                    </div>
                                </div>
                            </template>
                            <template v-slot:body="{ items }">
                                <tbody v-if="loadingPage == 2">
                                    <tr class="data-empty">
                                        <td colspan="5" class="py-2">
                                            <grid-loader
                                                class="mx-auto mt-5 pt-5"
                                                :color="'#70B9EB'"
                                                :size="'10px'"
                                            ></grid-loader>
                                            <center class="mt-2 mb-5 pb-5">
                                                <span
                                                    class="text-center"
                                                    style="opacity: 0.7"
                                                    >Loading</span
                                                >
                                            </center>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody
                                    v-if="items.length > 0 && loadingPage == 0"
                                >
                                    <tr
                                        v-for="(uom, index) in items"
                                        :key="index"
                                    >
                                        <td
                                            @click="toEdit(uom.uomId)"
                                            class="text-center font-size-sm"
                                        >
                                            {{ ++index }}
                                        </td>
                                        <td
                                            @click="toEdit(uom.uomId)"
                                            class="font-size-sm"
                                        >
                                            {{ uom.uomName }}
                                        </td>
                                        <td
                                            @click="toEdit(uom.uomId)"
                                            class="text-center font-size-sm"
                                        >
                                            {{ uom.uomCode }}
                                        </td>
                                        <td
                                            @click="toEdit(uom.uomId)"
                                            class="text-center font-size-sm"
                                        >
                                            <span v-if="uom.uomType === 1" >Base Unit</span>
                                            <span v-else>Conversion Unit</span>
                                        </td>
                                        <td
                                            @click="toEdit(uom.uomId)"
                                            class="text-center font-size-sm"
                                        >
                                            <span
                                                class="badge badge-info mr-1"
                                                v-if="uom.uomStatus === 1"
                                                >Active</span
                                            >
                                            <span
                                                class="badge badge-secondary mr-1"
                                                v-else
                                                >Inactive</span
                                            >
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody
                                    v-if="items.length <= 0 && loadingPage == 0"
                                >
                                    <tr class="data-empty">
                                        <td
                                            class="text-center"
                                            colspan="5"
                                            style="
                                                opacity: 0.8;
                                                padding: 2.5rem;
                                            "
                                        >
                                            <center>
                                                <img
                                                    :src="'/media/proxima/empty-data.svg'"
                                                    style="height: 100px"
                                                />
                                            </center>
                                            <br /><span style="opacity: 0.6"
                                                >Data Not Found</span
                                            >
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-data-table>
                    </v-app>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";


export default {
    name: "uom-data",
    data() {
        return {
            search: "",
            awaitingSearch: false,
            rows: "All",
            loadingPage: 1,
            type: "All",
            headers: [
                { text: "No.", align: "center", sortable: false },
                {
                    text: "Unit Name",
                    align: "start",
                    value: "uomName",
                },
                {
                    text: "Code",
                    align: "center",
                    value: "uomCode",
                },
                {
                    text: "Type",
                    align: "center",
                    value: "uomType",
                },
                {
                    text: "Status",
                    align: "center",
                    value: "uomStatus",
                },
            ],
            datas: [],
        };
    },
    created() {
        this.getData();
        this.$session.remove("uom_detail");
    },
    computed: {
        ...mapState("uom", {
            uoms: (state) => state.uoms,
        }),
        ...mapState(["auth"]),
    },
    methods: {
        ...mapActions("uom", ["getUom", "removeUom"]),
        getData() {
            this.loadingPage = 2;
            this.datas = [];
            this.getUom({ rows: this.rows, uom: this.uom })
                .then(() => {
                    this.uoms.data.forEach((element) => {
                        this.datas.push({
                            uomId: element.id,
                            uomName: element.uom_name,
                            uomCode: element.uom_code,
                            uomType: element.uom_type,
                            uomStatus: element.uom_status,
                        });
                    });
                    this.loadingPage = 0;
                })
                .catch((error) => {
                    error.response.status == 500
                        ? this.alert(error.response.data.message, 2)
                        : "";
                    error.response.status != 422 && error.response.status != 500
                        ? this.alert(error.message, 2)
                        : "";
                    this.loadingPage = 0;
                    this.totalUom = 0;
                });
        },
        toEdit(val) {
            this.$router.push({
                name: "uom-edit",
                params: { id: val },
            });
        },
        toJournalVoucher(val) {
            this.$router.push({
                name: "journal-transaction-voucher",
                params: { id: val },
            });
        },
        deleteData(val) {
            this.$swal({
                title: "Are you sure ?",
                text: "Deleted data cannot be recovery",
                icon: "warning",
                buttons: ["Cancel", "Delete"],
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    this.loadingPage = 1;
                    this.removeUom(val)
                        .then(() => {
                            this.alert("Data has been deleted !", 1);
                            this.getData();
                            this.loadingPage = 0;
                        })
                        .catch((error) => {
                            error.response.status == 500
                                ? this.alert(error.response.data.message, 2)
                                : "";
                            error.response.status != 422 &&
                            error.response.status != 500
                                ? this.alert(error.message, 2)
                                : "";
                            this.loadingPage = 0;
                        });
                }
            });
        },
    },
};
</script>
