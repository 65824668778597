<template>
    <main>
        <!-- Page Content -->
        <div class="content pt-3">
            <!-- Dynamic Table Full -->
            <div class="block">
                <div class="block-content-full pb-0 pt-0">
                    <div class="row">
                        <div class="col-12 col-md-7 mb-2 mb-sm-0">
                            <label class="mb-0 mt-2" style="font-size: 14px"
                                >Choose Recurring Journal :
                            </label>
                        </div>
                        <div
                            class="col-12 col-md-5 col-lg-5 col-xl-5 pt-0 pt-md-3"
                        >
                            <input
                                type="text"
                                class="form-control form-control col-12 col-md-8 float-right search-index"
                                name="example-text-input"
                                placeholder="Search.."
                                v-model="search"
                            />
                        </div>
                    </div>
                </div>
                <div class="block-content-full">
                    <div class="table-responsive">
                        <table
                            class="table table-bordered table-vcenter table-hover"
                        >
                            <thead style="background-color: whitesmoke;">
                                <tr>
                                    <th
                                        class="table-header-relative column-minwidth-150"
                                    >
                                        Recurring Name
                                    </th>
                                    <th class="table-header-relative">
                                        Created at
                                    </th>
                                    <th
                                        class="table-header-relative column-minwidth-250"
                                    >
                                        Accounts
                                    </th>
                                    <th
                                        class="table-header-relative sort-head column-minwidth-150"
                                    >
                                        Debit
                                    </th>
                                    <th
                                        class="table-header-relative sort-head column-minwidth-150"
                                    >
                                        Credit
                                    </th>
                                </tr>
                            </thead>
                            <tbody
                                v-if="
                                    recurringjournals.data &&
                                        recurringjournals.data.length > 0
                                "
                            >
                                <tr
                                    v-for="(recurring,
                                    index) in recurringjournals.data"
                                    :key="index"
                                    @click="selectRecurring(recurring.id)"
                                >
                                    <td>{{ recurring.recurring_name }}</td>
                                    <td align="center">
                                        {{
                                            recurring.created_at
                                                | moment("DD/MM/YYYY")
                                        }}
                                    </td>
                                    <td style="white-space: nowrap;">
                                        <span
                                            v-for="(detail,
                                            index2) in recurring.detail"
                                            :key="index2"
                                        >
                                            {{ detail.coa.account_no }}
                                            {{ detail.coa.account_name }}
                                            <br />
                                        </span>
                                    </td>
                                    <td align="right">
                                        <span
                                            v-for="(detail,
                                            index2) in recurring.detail"
                                            :key="index2"
                                        >
                                            <span v-if="detail.debit != 0">{{
                                                detail.debit | currency
                                            }}</span>
                                            <br />
                                        </span>
                                    </td>
                                    <td align="right">
                                        <span
                                            v-for="(detail,
                                            index2) in recurring.detail"
                                            :key="index2"
                                        >
                                            <span v-if="detail.credit != 0">{{
                                                detail.credit | currency
                                            }}</span>
                                            <br />
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr class="data-empty">
                                    <td
                                        class="text-center"
                                        colspan="5"
                                        style="opacity:0.8; padding:2.5rem;"
                                    >
                                        <center>
                                            <img
                                                :src="
                                                    '/media/proxima/empty-data.svg'
                                                "
                                                style="height: 100px;"
                                            />
                                        </center>
                                        <br /><span style="opacity:0.6;"
                                            >Data Not Found</span
                                        >
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p
                            class="text-muted font-size-sm d-inline d-sm-none"
                            style="opacity:0.5;"
                        >
                            Swipe left here to see full column >>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

export default {
    name: "recurringjournal",
    data() {
        return {
            search: "",
            rows: "All",
            loadingPage: true
        };
    },
    computed: {
        ...mapState("recurringjournal", {
            recurringjournals: state => state.recurringjournals
        })
    },
    watch: {
        search() {
            this.getRecurring({
                search: this.search,
                rows: this.rows,
                rowsUpdate: 0
            });
        }
    },
    mounted() {
        this.doLoading(1, 300);
        this.getRecurring({
            search: this.search,
            rows: this.rows,
            rowsUpdate: 0
        });
    },
    methods: {
        ...mapMutations("recurringjournal", ["CLEAR_DATA"]),
        ...mapActions("recurringjournal", ["getRecurring"]),
        getResult() {
            this.doLoading(2, 700);
            this.getRecurring({
                search: this.search,
                rows: this.rows,
                rowsUpdate: 0
            });
        },
        doLoading(type, long) {
            this.loadingPage = type;
            setTimeout(() => {
                this.loadingPage = 0;
            }, long);
        },
        selectRecurring(id) {
            this.$modal.hide("use-recurring");
            document.body.classList.remove("modal-open");
            this.$root.$emit("setRecurring", id); // Emit socket to Create/Edit Journal
        }
    },
    destroyed() {
        this.CLEAR_DATA();
    }
};
</script>
