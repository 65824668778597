import $axios from "../../api.js";

const state = () => ({
    notifications: [],

    notification: {
        title: "",
        content: "",
        type: ""
    },
    page: 1, //UNTUK MENCATAT PAGE PAGINATE YANG SEDANG DIAKSES
    last_page: 1,
    search: ""
});

const mutations = {
    //DIGUNAKAN UNTUK MENERIMA DATA GET ALL DATA YANG AKAN DITAMPILKAN DIINDEX
    ASSIGN_DATA(state, payload) {
        state.notifications = payload;
        state.last_page = payload.meta.last_page;
        state.page = payload.meta.current_page;
    },
    ASSIGN_DATA_LATEST(state, payload) {
        state.notifications = payload;
    },
    //MENGUBAH DATA STATE NEWS
    ASSIGN_FORM(state, payload) {
        state.notification = {
            id: payload.id,
            title: payload.title,
            content: payload.content,
            type: payload.type
        };
    },
    //ME-RESET STATE NEWS MENJADI KOSONG
    CLEAR_FORM(state) {
        state.notification = {
            title: "",
            content: "",
            type: ""
        };
    },
    //ME-RESET STATE NEWS MENJADI KOSONG
    CLEAR_DATA(state) {
        state.notifications = [];
    }
};

const actions = {
    //FUNGSI INI UNTUK MELAKUKAN REQUEST DATA NEWS DARI SERVER
    getNotification({ dispatch, commit, state }, payload) {
        //MENGECEK PAYLOAD ADA ATAU TIDAK
        let search = typeof payload.search != "undefined" ? payload.search : "";
        let rows = payload.rows;
        let page =
            typeof payload.rows != "All" && payload.rowsUpdate != 1
                ? state.page
                : "1";
        let sort = payload.sort;
        let asc = payload.asc != false ? "DESC" : "ASC";

        return new Promise((resolve, reject) => {
            //REQUEST DATA DENGAN ENDPOINT /NEWS
            $axios
                .get(
                    `/notification?page=${page}&search=${search}&rows=${rows}&sort=${sort}&asc=${asc}`
                )
                .then(response => {
                    //SIMPAN DATA KE STATE MELALUI MUTATIONS
                    commit("ASSIGN_DATA", response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    error.response.status !== 401
                        ? reject(error)
                        : dispatch("DO_LOGOUT", null, { root: true });
                });
        });
    },
    //FUNGSI INI UNTUK MELAKUKAN REQUEST DATA NEWS DARI SERVER
    getNotificationLatest({ dispatch, commit, state }, payload) {
        return new Promise((resolve, reject) => {
            //REQUEST DATA DENGAN ENDPOINT /NEWS
            $axios
                .get(`/notification/latest`)
                .then(response => {
                    //SIMPAN DATA KE STATE MELALUI MUTATIONS
                    commit("ASSIGN_DATA_LATEST", response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    error.response.status !== 401
                        ? reject(error)
                        : dispatch("DO_LOGOUT", null, { root: true });
                });
        });
    },
    //FUNGSI UNTUK MENAMBAHKAN DATA BARU
    submitNotification({ dispatch, commit, state }) {
        return new Promise((resolve, reject) => {
            //MENGIRIMKAN PERMINTAAN KE SERVER DAN MELAMPIRKAN DATA YANG AKAN DISIMPAN
            //DARI STATE NEWS
            $axios
                .post(`/notification`, state.notification)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                });
        });
    },
    //UNTUK MENGAMBIL SINGLE DATA DARI SERVER BERDASARKAN CODE NEWS
    editNotification({ commit }, payload) {
        return new Promise((resolve, reject) => {
            //MELAKUKAN REQUEST DENGAN MENGIRIMKAN CODE NEWS DI URL
            $axios
                .get(`/notification/${payload}/edit`)
                .then(response => {
                    //APABIL BERHASIL, DI ASSIGN KE FORM
                    commit("ASSIGN_FORM", response.data.data);
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    //UNTUK MENGUPDATE DATA BERDASARKAN CODE YANG SEDANG DIEDIT
    updateNotification({ state, commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .put(`/notification/${payload}`, state.notification)
                .then(response => {
                    //FORM DIBERSIHKAN
                    commit("CLEAR_FORM");
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                });
        });
    },
    //MENGHAPUS DATA
    removeNotification({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .delete(`/notification/${payload}`)
                .then(response => {
                    commit("CLEAR_FORM");
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
