<template>
    <main>
        <hero title="Create User" prevBreadcrumb="User" routeBreadcrumb="user" currentBreadcrumb="Create User"></hero>

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 1"
                style="height:250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 2"
                style="height:250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Save Data</span
                        >
                    </center>
                </div>
            </div>
            <!-- Basic -->
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 0"
            >
                <div class="block-content block-content-full">
                    <form
                        method="POST"
                        enctype="multipart/form-data"
                        onsubmit="return false;"
                    >
                        <!--Form-->
                        <div class="row push font-size-sm">
                            <div
                                class="col-12 col-md-6 col-lg-6 col-xl-6 pl-2 pl-md-5"
                            >
                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Fullname</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Fullname"
                                        v-model="user.name"
                                        :class="{ 'is-invalid': errors.name }"
                                    />
                                    <p class="text-danger" v-if="errors.name">
                                        {{ errors.name[0] }}
                                    </p>
                                </div>
                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Username</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Username"
                                        v-model="user.username"
                                        @keydown.space.prevent
                                        @input="clearSpace()"
                                        :class="{
                                            'is-invalid': errors.username,
                                        }"
                                    />
                                    <p
                                        class="text-danger"
                                        v-if="errors.username"
                                    >
                                        {{ errors.username[0] }}
                                    </p>
                                </div>
                                <div class="form-group">
                                    <label for="example-email-input"
                                        >Email</label
                                    >
                                    <input
                                        type="email"
                                        class="form-control"
                                        placeholder="Email Address"
                                        v-model="user.email"
                                        :class="{ 'is-invalid': errors.email }"
                                    />
                                    <p class="text-danger" v-if="errors.email">
                                        {{ errors.email[0] }}
                                    </p>
                                </div>
                                <div class="form-group">
                                    <label for="example-password-input"
                                        >Phone Number</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Phone Number"
                                        v-model="user.phone"
                                        @input="mustNumber"
                                        :class="{ 'is-invalid': errors.phone }"
                                    />
                                    <p class="text-danger" v-if="errors.phone">
                                        {{ errors.phone[0] }}
                                    </p>
                                </div>
                                <div class="form-group">
                                    <label class="d-block" for="user-photo"
                                        >Photo</label
                                    >
                                    <input
                                        type="file"
                                        id="user-photo"
                                        @change="uploadImage"
                                        :class="{ 'is-invalid': errors.photo }"
                                    />
                                    <p class="text-danger" v-if="errors.photo">
                                        {{ errors.photo[0] }}
                                    </p>
                                </div>
                                <div class="form-group">
                                    <label for="example-password-input"
                                        >Password</label
                                    >
                                    <input
                                        type="password"
                                        class="form-control"
                                        placeholder="Password"
                                        v-model="user.password"
                                        :class="{
                                            'is-invalid': errors.password,
                                        }"
                                    />
                                    <p
                                        class="text-danger"
                                        v-if="errors.password"
                                    >
                                        {{ errors.password[0] }}
                                    </p>
                                </div>
                                <div class="form-group">
                                    <label for="example-password-input"
                                        >Re-type Pasword</label
                                    >
                                    <input
                                        type="password"
                                        class="form-control"
                                        name="example-password-input"
                                        placeholder="Re-type Pasword"
                                        v-model="user.password_confirmation"
                                        :class="{
                                            'is-invalid':
                                                errors.password_confirmation,
                                        }"
                                    />
                                    <p
                                        class="text-danger"
                                        v-if="errors.password_confirmation"
                                    >
                                        {{ errors.password_confirmation[0] }}
                                    </p>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                                <!--OTHER-->
                                <div class="form-group font-size-sm">
                                    <label class="d-block"
                                        >System Administrator</label
                                    >
                                    <div class="col-12 py-1">
                                        <div
                                            class="col-6 col-md-3 py-2 form-check form-check-inline mr-0"
                                        >
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                v-model="
                                                    assignData.administrator
                                                "
                                            />
                                            <label class="form-check-label"
                                                >Administrator</label
                                            >
                                        </div>
                                    </div>
                                </div>
                                <!--ACCOUNTING-->
                                <div class="form-group font-size-sm">
                                    <label class="d-block">Accounting</label>
                                    <div class="col-12 py-1">
                                        <div
                                            class="col-6 col-md-3 py-2 form-check form-check-inline mr-0"
                                        >
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                v-model="assignData.accChief"
                                            />
                                            <label class="form-check-label"
                                                >Chief</label
                                            >
                                        </div>
                                        <div
                                            class="col-6 col-md-3 py-2 form-check form-check-inline mr-0"
                                        >
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                v-model="
                                                    assignData.accStakeholder
                                                "
                                                :disabled="disabledAcc"
                                            />
                                            <label class="form-check-label"
                                                >Stakeholder</label
                                            >
                                        </div>
                                        <div
                                            class="col-6 col-md-3 py-2 form-check form-check-inline mr-0"
                                        >
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                v-model="
                                                    assignData.accStaffAccount
                                                "
                                                :disabled="disabledAcc"
                                            />
                                            <label class="form-check-label"
                                                >Staff: Account</label
                                            >
                                        </div>
                                        <div
                                            class="col-6 col-md-3 py-2 form-check form-check-inline mr-0"
                                        >
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                v-model="
                                                    assignData.accStaffSetup
                                                "
                                                :disabled="disabledAcc"
                                            />
                                            <label class="form-check-label"
                                                >Staff: Setup Data</label
                                            >
                                        </div>
                                        <div
                                            class="col-6 col-md-3 py-2 form-check form-check-inline mr-0"
                                        >
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                v-model="
                                                    assignData.accStaffAsset
                                                "
                                                :disabled="disabledAcc"
                                            />
                                            <label class="form-check-label"
                                                >Staff: Asset Data</label
                                            >
                                        </div>
                                        <div
                                            class="col-6 col-md-3 py-2 form-check form-check-inline mr-0"
                                        >
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                v-model="
                                                    assignData.accStaffJournal
                                                "
                                                :disabled="disabledAcc"
                                            />
                                            <label class="form-check-label"
                                                >Staff: Journal</label
                                            >
                                        </div>
                                        <div
                                            class="col-6 col-md-3 py-2 form-check form-check-inline mr-0"
                                        >
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                v-model="
                                                    assignData.accStaffBill
                                                "
                                                :disabled="disabledAcc"
                                            />
                                            <label class="form-check-label"
                                                >Staff: Bills</label
                                            >
                                        </div>
                                        <div
                                            class="col-6 col-md-3 py-2 form-check form-check-inline mr-0"
                                        >
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                v-model="
                                                    assignData.accStaffPurchase
                                                "
                                                :disabled="disabledAcc"
                                            />
                                            <label class="form-check-label"
                                                >Staff: Purchase</label
                                            >
                                        </div>
                                        <div
                                            class="col-6 col-md-3 py-2 form-check form-check-inline mr-0"
                                        >
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                v-model="
                                                    assignData.accStaffPettyCash
                                                "
                                                :disabled="disabledAcc"
                                            />
                                            <label class="form-check-label"
                                                >Staff: Petty Cash</label
                                            >
                                        </div>
                                        <div
                                            class="col-6 col-md-3 py-2 form-check form-check-inline mr-0"
                                        >
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                v-model="
                                                    assignData.accStaffBanking
                                                "
                                                :disabled="disabledAcc"
                                            />
                                            <label class="form-check-label"
                                                >Staff: Banking</label
                                            >
                                        </div>
                                        <div
                                            class="col-6 col-md-3 py-2 form-check form-check-inline mr-0"
                                        >
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                v-model="
                                                    assignData.accStaffInventory
                                                "
                                                :disabled="disabledAcc"
                                            />
                                            <label class="form-check-label"
                                                >Staff: Inventory</label
                                            >
                                        </div>
                                        <div
                                            class="col-6 col-md-3 py-2 form-check form-check-inline mr-0"
                                        >
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                v-model="
                                                    assignData.accStaffReportGL
                                                "
                                                :disabled="
                                                    disabledAcc ||
                                                    assignData.accStaffReportGLBank
                                                "
                                            />
                                            <label class="form-check-label"
                                                >Report: General Ledger</label
                                            >
                                        </div>
                                        <div
                                            class="col-6 col-md-3 py-2 form-check form-check-inline mr-0"
                                        >
                                            <input
                                                class="form-check-input align-top"
                                                type="checkbox"
                                                v-model="
                                                    assignData.accStaffReportGLBank
                                                "
                                                :disabled="
                                                    disabledAcc ||
                                                    assignData.accStaffReportGL
                                                "
                                            />
                                            <label class="form-check-label"
                                                >Report: General Ledger
                                                (Bank)</label
                                            >
                                        </div>
                                        <div
                                            class="col-6 col-md-3 py-2 form-check form-check-inline mr-0"
                                        >
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                v-model="
                                                    assignData.accStaffReportTB
                                                "
                                                :disabled="disabledAcc"
                                            />
                                            <label class="form-check-label"
                                                >Report: Trial Balance</label
                                            >
                                        </div>
                                        <div
                                            class="col-6 col-md-3 py-2 form-check form-check-inline mr-0"
                                        >
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                v-model="
                                                    assignData.accStaffReportBS
                                                "
                                                :disabled="disabledAcc"
                                            />
                                            <label class="form-check-label"
                                                >Report: Balance Sheet</label
                                            >
                                        </div>
                                        <div
                                            class="col-6 col-md-3 py-2 form-check form-check-inline mr-0"
                                        >
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                v-model="
                                                    assignData.accStaffReportPL
                                                "
                                                :disabled="disabledAcc"
                                            />
                                            <label class="form-check-label"
                                                >Report: Profit & Loss</label
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--END Form-->
                        <!-- Submit -->
                        <div class="row items-push">
                            <div class="col-12 text-center">
                                <p class="font-size-sm text-muted">
                                    * Require to be filled, Make sure all data is
                                    correctly filled
                                </p>
                                <button
                                    type="submit"
                                    class="btn btn-sm btn-success"
                                    v-ripple="{ center: true }"
                                    @click.prevent="submit"
                                >
                                    <i class="fa fa-fw fa-check mr-1"></i> Save
                                </button>
                                <router-link
                                    type="button"
                                    class="btn btn-sm btn-alt-light"
                                    tag="button"
                                    v-ripple="{ center: true }"
                                    :to="{ name: 'user' }"
                                >
                                    <i class="fa fa-fw fa-times mr-1"></i> Close
                                </router-link>
                            </div>
                        </div>
                        <!-- END Submit -->
                    </form>
                </div>
            </div>
            <!-- END Basic -->
        </div>
        <!-- END Page Content -->
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

export default {
    name: "create-user",

    data() {
        return {
            loadingPage: 0,
            assignData: {},
        };
    },
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState("user", {
            user: (state) => state.user,
        }),
        disabledAcc() {
            return this.assignData.accChief;
        },
    },
    methods: {
        //VUEX
        ...mapMutations("user", ["CLEAR_FORM"]),
        ...mapActions("user", ["submitUser"]),
        ...mapMutations(["CLEAR_ERRORS"]),

        uploadImage(e) {
            let file = e.target.files[0];
            let reader = new FileReader();
            reader.onloadend = (file) => {
                this.user.photo = reader.result;
            };
            reader.readAsDataURL(file);
        },
        clearSpace() {
            this.user.username = this.user.username.replace(/\s/g, "");
        },
        mustNumber() {
            // Only for postfix account no. to prevent user input alphabet
            this.user.phone = this.user.phone.replace(/[^0-9]/g, "");
        },
        submit() {
            this.loadingPage = 2;
            this.CLEAR_ERRORS();
            this.assignData.administrator
                ? this.user.role.push("administrator")
                : "";
            this.assignData.accChief ? this.user.role.push("accChief") : "";
            this.assignData.accStaffAccount
                ? this.user.role.push("accStaffAccount")
                : "";
            this.assignData.accStaffSetup
                ? this.user.role.push("accStaffSetup")
                : "";
            this.assignData.accStaffAsset
                ? this.user.role.push("accStaffAsset")
                : "";
            this.assignData.accStaffInventory
                ? this.user.role.push("accStaffInventory")
                : "";
            this.assignData.accStaffBill
                ? this.user.role.push("accStaffBill")
                : "";
            this.assignData.accStaffPurchase
                ? this.user.role.push("accStaffPurchase")
                : "";
            this.assignData.accStaffJournal
                ? this.user.role.push("accStaffJournal")
                : "";
            this.assignData.accStaffPettyCash
                ? this.user.role.push("accStaffPettyCash")
                : "";
            this.assignData.accStaffBanking
                ? this.user.role.push("accStaffBanking")
                : "";
            this.assignData.accStaffReportGL
                ? this.user.role.push("accStaffReportGL")
                : "";
            this.assignData.accStaffReportGLBank
                ? this.user.role.push("accStaffReportGLBank")
                : "";
            this.assignData.accStaffReportTB
                ? this.user.role.push("accStaffReportTB")
                : "";
            this.assignData.accStaffReportBS
                ? this.user.role.push("accStaffReportBS")
                : "";
            this.assignData.accStaffReportPL
                ? this.user.role.push("accStaffReportPL")
                : "";
            this.assignData.accStakeholder
                ? this.user.role.push("accStakeholder")
                : "";
            this.submitUser()
                .then((reponse) => {
                    this.$router.push({ name: "user" });
                    this.alert("Successfully create User Data ", 1);
                    this.loadingPage = 0;
                })
                .catch((error) => {
                    console.log(error);
                    error.response.status == 500
                        ? this.alert(error.response.data.message, 2)
                        : "";
                    error.response.status != 422 && error.response.status != 500
                        ? this.alert(error.message, 2)
                        : "";
                    this.loadingPage = 0;
                });
        },
    },
    destroyed() {
        //FORM DI BERSIHKAN
        this.CLEAR_FORM();
    },
};
</script>
