var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('div',{staticClass:"row px-2"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"col-sm-2 py-2 mb-1 bg-whitesmoke"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"pr-0 py-1 text-left font-weight-bold",class:{
                        'col-6': _vm.purchase.bill_status == 3,
                        'col-12': _vm.purchase.bill_status != 3
                    }},[_vm._v("\n                    QTY\n                ")]),_vm._v(" "),(_vm.purchase.bill_status == 3)?_c('div',{staticClass:"col-6 pr-0 py-1 text-left font-weight-bold"},[_vm._v("\n                    SOLD\n                ")]):_vm._e()])]),_vm._v(" "),_vm._m(1)]),_vm._v(" "),_vm._l((_vm.purchase.bill_transaction),function(transaction,index){return _c('div',{key:index,staticClass:"transaction-item"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4 pt-2 pb-0"},[_c('div',{staticClass:"row",class:{
                        'd-none': transaction.transaction_format == 2
                    }},[_c('div',{staticClass:"col-1 pr-0 py-1"},[(
                                transaction.ref_order_tr_id == 0 &&
                                _vm.purchase.bill_paid_total == 0 &&
                                    _vm.purchase.bill_status != 4 &&
                                    _vm.purchase.bill_status != 5
                            )?_c('p',{staticClass:"text-center align-middle pt-2 mb-0 font-weight-bolder text-pointer"},[(transaction.transaction_format == 0)?_c('i',{staticClass:"si si-arrow-down",on:{"click":function($event){return _vm.changeTransactionFormat(index, 1)}}}):_vm._e(),_vm._v(" "),(transaction.transaction_format == 1)?_c('i',{staticClass:"si si-arrow-up",on:{"click":function($event){return _vm.changeTransactionFormat(index, 0)}}}):_vm._e()]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"col-8 pr-0 py-1"},[_c('selectize',{staticClass:"form-control mt-1",class:{
                                'is-invalid-selectize':
                                    _vm.errors[
                                        'bill_transaction.' +
                                            index +
                                            '.item_id'
                                    ]
                            },attrs:{"placeholder":'Item',"id":'transaction-item-' + index,"disabled":transaction.ref_order_tr_id != 0 ||
                                _vm.purchase.bill_paid_total != 0 ||
                                    _vm.purchase.bill_status == 5 ||
                                    _vm.purchase.bill_status == 4 ||
                                    _vm.purchase.reference_invoice.length > 0},on:{"input":function($event){return _vm.changeItem(index)}},model:{value:(transaction.item_id),callback:function ($$v) {_vm.$set(transaction, "item_id", $$v)},expression:"transaction.item_id"}},_vm._l((_vm.items),function(item){return _c('option',{key:item.id,domProps:{"value":item.id}},[_vm._v("\n                                "+_vm._s(item.item_name))])}),0),_vm._v(" "),(
                                _vm.errors[
                                    'bill_transaction.' + index + '.item_id'
                                ]
                            )?_c('span',{staticClass:"text-danger data-detail-error"},[_vm._v("\n                            "+_vm._s(_vm.errors[
                                    "bill_transaction." + index + ".item_id"
                                ][0])+"\n                        ")]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"col-3 pr-0 py-1"},[(
                                transaction.transaction_format == 0 ||
                                    transaction.transaction_format == 1
                            )?_c('selectize',{staticClass:"form-control mt-1",class:{
                                'is-invalid-selectize':
                                    _vm.errors[
                                        'bill_transaction.' +
                                            index +
                                            '.uom_id'
                                    ]
                            },attrs:{"placeholder":'Unit',"id":'transaction-uom-' + index,"disabled":transaction.ref_order_tr_id != 0 ||
                                !transaction.uom_list ||
                                    transaction.uom_list.length == 0 ||
                                    _vm.purchase.bill_paid_total != 0 ||
                                    _vm.purchase.bill_status == 5 ||
                                    _vm.purchase.bill_status == 4 ||
                                    _vm.purchase.reference_invoice.length > 0},model:{value:(transaction.uom_id),callback:function ($$v) {_vm.$set(transaction, "uom_id", $$v)},expression:"transaction.uom_id"}},_vm._l((transaction.uom_list),function(uom){return _c('option',{key:uom.id,domProps:{"value":uom.id}},[_vm._v("\n                                "+_vm._s(uom.uom_name))])}),0):_vm._e(),_vm._v(" "),(
                                _vm.errors[
                                    'bill_transaction.' + index + '.uom_id'
                                ]
                            )?_c('span',{staticClass:"text-danger data-detail-error"},[_vm._v("\n                            "+_vm._s(_vm.errors[
                                    "bill_transaction." + index + ".uom_id"
                                ][0])+"\n                        ")]):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"row",class:{
                        'd-none': transaction.transaction_format == 0
                    }},[_c('div',{staticClass:"col-6 offset-1 pr-0 py-1"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(transaction.transaction_description),expression:"transaction.transaction_description"}],staticClass:"form-control my-1",class:{
                                'is-invalid':
                                    _vm.errors[
                                        'bill_transaction.' +
                                            index +
                                            '.transaction_description'
                                    ]
                            },attrs:{"type":"text","placeholder":'Description',"id":'transaction-desc-' + index,"disabled":transaction.ref_order_tr_id != 0 ||
                                _vm.purchase.bill_paid_total != 0 ||
                                    _vm.purchase.bill_status == 5 ||
                                    _vm.purchase.bill_status == 4 ||
                                    _vm.purchase.reference_invoice.length > 0},domProps:{"value":(transaction.transaction_description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(transaction, "transaction_description", $event.target.value)}}}),_vm._v(" "),(
                                _vm.errors[
                                    'bill_transaction.' +
                                        index +
                                        '.transaction_description'
                                ]
                            )?_c('span',{staticClass:"text-danger data-detail-error"},[_vm._v("\n                            "+_vm._s(_vm.errors[
                                    "bill_transaction." +
                                        index +
                                        ".transaction_description"
                                ][0])+"\n                        ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"col-5 pr-0 py-1"},[(
                                transaction.transaction_format == 1 ||
                                    transaction.transaction_format == 2
                            )?_c('selectize',{staticClass:"form-control mt-1",class:{
                                'is-invalid-selectize':
                                    _vm.errors[
                                        'bill_transaction.' +
                                            index +
                                            '.transaction_coa_id'
                                    ]
                            },attrs:{"placeholder":'Account No.',"id":'transaction-coa-' + index,"disabled":transaction.ref_order_tr_id != 0 ||
                                _vm.purchase.bill_paid_total != 0 ||
                                    _vm.purchase.bill_status == 5 ||
                                    _vm.purchase.bill_status == 4 ||
                                    _vm.purchase.reference_invoice.length > 0},model:{value:(transaction.transaction_coa_id),callback:function ($$v) {_vm.$set(transaction, "transaction_coa_id", $$v)},expression:"transaction.transaction_coa_id"}},_vm._l((_vm.transactionAccounts),function(account){return _c('option',{key:account.id,domProps:{"value":account.id}},[_vm._v(_vm._s(account.account_no)+"\n                                "+_vm._s(account.account_name))])}),0):_vm._e(),_vm._v(" "),(
                                _vm.errors[
                                    'bill_transaction.' +
                                        index +
                                        '.transaction_coa_id'
                                ]
                            )?_c('span',{staticClass:"text-danger data-detail-error"},[_vm._v("\n                            "+_vm._s(_vm.errors[
                                    "bill_transaction." +
                                        index +
                                        ".transaction_coa_id"
                                ][0])+"\n                        ")]):_vm._e()],1)])]),_vm._v(" "),_c('div',{staticClass:"col-sm-2 pt-2 pb-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"pr-0 py-1",class:{
                            'col-6': _vm.purchase.bill_status == 3,
                            'col-12': _vm.purchase.bill_status != 3
                        }},[_c('numeric',{staticClass:"form-control mt-1 mb-2",class:{
                                'is-invalid':
                                    _vm.errors[
                                        'bill_transaction.' +
                                            index +
                                            '.transaction_qty'
                                    ]
                            },attrs:{"placeholder":"Qty","separator":".","precision":2,"disabled":transaction.ref_order_tr_id != 0 ||
                                _vm.purchase.bill_paid_total != 0 ||
                                    _vm.purchase.bill_status == 5 ||
                                    _vm.purchase.bill_status == 4 ||
                                    _vm.purchase.reference_invoice.length > 0},on:{"change":function($event){return _vm.calculateTransaction(index)}},model:{value:(transaction.transaction_qty),callback:function ($$v) {_vm.$set(transaction, "transaction_qty", $$v)},expression:"transaction.transaction_qty"}}),_vm._v(" "),(
                                _vm.errors[
                                    'bill_transaction.' +
                                        index +
                                        '.transaction_qty'
                                ]
                            )?_c('span',{staticClass:"text-danger data-detail-error"},[_vm._v("\n                            "+_vm._s(_vm.errors[
                                    "bill_transaction." +
                                        index +
                                        ".transaction_qty"
                                ][0])+"\n                        ")]):_vm._e()],1),_vm._v(" "),(_vm.purchase.bill_status == 3)?_c('div',{staticClass:"col-6 pr-0 py-1"},[_c('numeric',{staticClass:"form-control mt-1 mb-2",attrs:{"id":'qty'.index,"placeholder":"Sold","spellcheck":"false","disabled":"","separator":".","precision":2},model:{value:(transaction.transaction_qty),callback:function ($$v) {_vm.$set(transaction, "transaction_qty", $$v)},expression:"transaction.transaction_qty"}})],1):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"col-sm-6 pt-2 pb-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4 pr-0 py-1"},[_c('money',{staticClass:"form-control mt-1 mb-2 text-right",class:{
                                'is-invalid':
                                    _vm.errors[
                                        'bill_transaction.' +
                                            index +
                                            '.transaction_price'
                                    ]
                            },attrs:{"id":'price'.index,"placeholder":"Unit Price","spellcheck":"false","disabled":transaction.ref_order_tr_id != 0 ||
                                _vm.purchase.bill_paid_total != 0 ||
                                    _vm.purchase.bill_status == 5 ||
                                    _vm.purchase.bill_status == 4 ||
                                    _vm.purchase.reference_invoice.length > 0},nativeOn:{"blur":function($event){return _vm.calculateTransaction(index)}},model:{value:(transaction.transaction_price),callback:function ($$v) {_vm.$set(transaction, "transaction_price", $$v)},expression:"transaction.transaction_price"}}),_vm._v(" "),(
                                _vm.errors[
                                    'bill_transaction.' +
                                        index +
                                        '.transaction_price'
                                ]
                            )?_c('span',{staticClass:"text-danger data-detail-error"},[_vm._v("\n                            "+_vm._s(_vm.errors[
                                    "bill_transaction." +
                                        index +
                                        ".transaction_price"
                                ][0])+"\n                        ")]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"col-3 pr-0 py-1"},[_c('selectize',{staticClass:"form-control mt-1",class:{
                                'is-invalid-selectize':
                                    _vm.errors[
                                        'bill_transaction.' +
                                            index +
                                            '.transaction_tax_id'
                                    ]
                            },attrs:{"placeholder":"Tax","disabled":transaction.ref_order_tr_id != 0 ||
                                _vm.purchase.bill_paid_total != 0 ||
                                    _vm.purchase.bill_status == 5 ||
                                    _vm.purchase.bill_status == 4 ||
                                    _vm.purchase.reference_invoice.length > 0},on:{"input":function($event){return _vm.getTaxDetail(
                                    index,
                                    transaction.transaction_tax_id
                                )}},model:{value:(transaction.transaction_tax_id),callback:function ($$v) {_vm.$set(transaction, "transaction_tax_id", $$v)},expression:"transaction.transaction_tax_id"}},_vm._l((_vm.taxs),function(tax,indeks){return _c('option',{key:indeks,domProps:{"value":tax.id}},[_vm._v("\n                                "+_vm._s(tax.tax_code)+"\n                                ("+_vm._s(tax.tax_rate)+"%)\n                            ")])}),0),_vm._v(" "),(
                                _vm.errors[
                                    'bill_transaction.' +
                                        index +
                                        '.transaction_tax_id'
                                ]
                            )?_c('span',{staticClass:"text-danger data-detail-error"},[_vm._v("\n                            "+_vm._s(_vm.errors[
                                    "bill_transaction." +
                                        index +
                                        ".transaction_tax_id"
                                ][0])+"\n                        ")]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"col-4 pr-0 py-1"},[_c('money',{staticClass:"form-control mt-1 mb-2 text-right",class:{
                                'is-invalid':
                                    _vm.errors[
                                        'bill_transaction.' +
                                            index +
                                            '.transaction_amount'
                                    ]
                            },attrs:{"type":"text","placeholder":"Amount","disabled":""},nativeOn:{"blur":function($event){return _vm.calculateTransaction(index)}},model:{value:(transaction.transaction_amount),callback:function ($$v) {_vm.$set(transaction, "transaction_amount", $$v)},expression:"transaction.transaction_amount"}}),_vm._v(" "),(
                                _vm.errors[
                                    'bill_transaction.' +
                                        index +
                                        '.transaction_amount'
                                ]
                            )?_c('span',{staticClass:"text-danger data-detail-error"},[_vm._v("\n                            "+_vm._s(_vm.errors[
                                    "bill_transaction." +
                                        index +
                                        ".transaction_amount"
                                ][0])+"\n                        ")]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"col-1 px-2 pb-1 text-left pt-3"},[(
                                (index > 0 ||
                                    _vm.purchase.bill_transaction_from_order
                                        .length > 0) &&
                                    transaction.ref_order_tr_id == 0 &&
                                    _vm.purchase.bill_paid_total == 0 &&
                                    _vm.purchase.bill_status != 4 &&
                                    _vm.purchase.bill_status != 5
                            )?_c('a',{staticClass:"btn-icon-only btn-icon-danger mt-1",on:{"click":function($event){return _vm.removeDetail(index)}}},[_c('i',{staticClass:"fa fa-trash"})]):_vm._e()])])])]),_vm._v(" "),(
                transaction.ref_order_tr_id == 0 &&
                _vm.purchase.bill_paid_total == 0 &&
                    _vm.purchase.bill_status != 4 &&
                    _vm.purchase.bill_status != 5
            )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4 pr-0 py-0 text-info text-pointer pl-5 pb-1",class:{
                    'pt-3': _vm.errors['bill_transaction.' + index + '.item_id']
                }},[(
                        transaction.transaction_format == 0 ||
                            transaction.transaction_format == 1
                    )?_c('p',{staticClass:"mb-0 text-pointer"},[_c('span',{on:{"click":function($event){return _vm.changeTransactionFormat(index, 2)}}},[_c('i',{staticClass:"fa fa-exchange-alt mr-1"}),_vm._v("\n                        Switch to Description\n                    ")])]):_vm._e(),_vm._v(" "),(transaction.transaction_format == 2)?_c('p',{staticClass:"mb-0 text-pointer"},[_c('span',{on:{"click":function($event){return _vm.changeTransactionFormat(index, 0)}}},[_c('i',{staticClass:"fa fa-exchange-alt mr-1"}),_vm._v("\n                        Switch to Product & Services\n                    ")])]):_vm._e()])]):_vm._e()])}),_vm._v(" "),_c('div',{staticClass:"row px-2"},[_c('div',{staticClass:"col-12 py-2 mt-2 mb-1 px-1"},[_c('hr',{staticClass:"mt-1"}),_vm._v(" "),(
                    _vm.purchase.bill_paid_total == 0 &&
                        _vm.purchase.bill_status != 4 &&
                        _vm.purchase.bill_status != 5
                )?_c('a',{staticClass:"text-info text-pointer font-semibold",on:{"click":function($event){return _vm.addDetail()}}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v("\n                Add additional transaction\n            ")]):_vm._e()])])],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-sm-4 py-2 mb-1 bg-whitesmoke"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-1 py-1 text-center font-weight-bold"},[_vm._v("\n                    #\n                ")]),_vm._v(" "),_c('div',{staticClass:"col-11 py-1 text-left font-weight-bold"},[_vm._v("\n                    ITEM\n                ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-sm-6 py-2 mb-1 bg-whitesmoke"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4 pr-0 py-1 text-left font-weight-bold"},[_vm._v("\n                    UNIT PRICE\n                ")]),_vm._v(" "),_c('div',{staticClass:"col-3 pr-0 py-1 text-left font-weight-bold"},[_vm._v("\n                    TAX RATE\n                ")]),_vm._v(" "),_c('div',{staticClass:"col-4 pr-0 py-1 text-left font-weight-bold"},[_vm._v("\n                    AMOUNT\n                ")]),_vm._v(" "),_c('div',{staticClass:"col-1 pr-0 py-1 text-left font-weight-bold"})])])}]

export { render, staticRenderFns }