<template>
    <main>
        <hero title="Create Notification" prevBreadcrumb="Notification" routeBreadcrumb="notification" currentBreadcrumb="Create Notification"></hero>

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 1"
                style="height:250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity:0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 2"
                style="height:250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity:0.7"
                            >Save Data</span
                        >
                    </center>
                </div>
            </div>
            <!-- Basic -->
            <div
                class="block col-12 col-md-8 offset-md-2 block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 0"
            >
                <div class="block-content block-content-full">
                    <form
                        method="POST"
                        enctype="multipart/form-data"
                        onsubmit="return false;"
                    >
                        <!--Form-->
                        <div class="row push font-size-sm">
                            <div class="col-12 col-md-10 offset-md-1">
                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Title</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Title"
                                        v-model="notification.title"
                                        :class="{
                                            'is-invalid': errors.title
                                        }"
                                    />
                                    <p
                                        class="text-danger"
                                        v-if="errors.title"
                                    >
                                        {{ errors.title[0] }}
                                    </p>
                                </div>
                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Content</label
                                    >
                                    <textarea
                                        type="text"
                                        class="form-control"
                                        placeholder="Content"
                                        v-model="notification.content"
                                        :class="{
                                            'is-invalid': errors.content
                                        }"
                                    />
                                    <p
                                        class="text-danger"
                                        v-if="errors.content"
                                    >
                                        {{ errors.content[0] }}
                                    </p>
                                </div>
                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Type</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Type"
                                        v-model="notification.type"
                                        :class="{
                                            'is-invalid': errors.type
                                        }"
                                    />
                                    <p
                                        class="text-danger"
                                        v-if="errors.type"
                                    >
                                        {{ errors.type[0] }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!--END Form-->
                        <!-- Submit -->
                        <div class="row items-push">
                            <div class="col-12 text-center">
                                <p class="font-size-sm text-muted">
                                    * Require to be filled, Make sure all data is
                                    correctly filled
                                </p>
                                <button
                                    type="submit"
                                    class="btn btn-sm btn-success"
                                    v-ripple="{ center: true }"
                                    @click.prevent="submit"
                                >
                                    <i class="fa fa-fw fa-check mr-1"></i> Save
                                </button>
                                <router-link
                                    type="button"
                                    class="btn btn-sm btn-alt-light"
                                    tag="button"
                                    v-ripple="{ center: true }"
                                    :to="{ name: 'notification' }"
                                >
                                    <i class="fa fa-fw fa-times mr-1"></i> Close
                                </router-link>
                            </div>
                        </div>
                        <!-- END Submit -->
                    </form>
                </div>
            </div>
            <!-- END Basic -->
        </div>
        <!-- END Page Content -->
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

export default {
    name: "create-notification",

    data() {
        return {
            loadingPage: 0
        };
    },
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState("notification", {
            notification: state => state.notification
        })
    },
    methods: {
        //VUEX
        ...mapMutations("notification", ["CLEAR_FORM"]),
        ...mapActions("notification", ["submitNotification"]),

        mustNumber() {
            // Only for postfix account no. to prevent user input alphabet
            this.notification.notification_rekening = this.notification.notification_rekening.replace(
                /[^0-9]/g,
                ""
            );
        },
        submit() {
            this.loadingPage = 2;
            this.submitNotification()
                .then(() => {
                    this.$router.push({ name: "notification" });
                    this.alert("Successfully create Notification Data ", 1);
                    this.loadingPage = 0;
                })
                .catch(error => {
                    console.log(error);
                    this.loadingPage = 0;
                });
        }
    },
    destroyed() {
        //FORM DI BERSIHKAN
        this.CLEAR_FORM();
    }
};
</script>
