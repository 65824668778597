var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('hero',{attrs:{"title":"Edit Contact","prevBreadcrumb":"Contact Data","routeBreadcrumb":"contact","currentBreadcrumb":"Edit Contact"}}),_vm._v(" "),_c('div',{staticClass:"content"},[(_vm.loadingPage == 1)?_c('div',{staticClass:"block block-rounded block-mode-loading-oneui",staticStyle:{"height":"250px"}},[_c('div',{staticClass:"block-content block-content-full pb-0",staticStyle:{"padding-top":"80px"}},[_c('grid-loader',{staticClass:"mx-auto",attrs:{"color":'#70B9EB',"size":'10px'}}),_vm._v(" "),_c('center',{staticClass:"mt-2"},[_c('span',{staticClass:"text-center",staticStyle:{"opacity":"0.7"}},[_vm._v("Loading")])])],1)]):_vm._e(),_vm._v(" "),(_vm.loadingPage == 2)?_c('div',{staticClass:"block block-rounded block-mode-loading-oneui",staticStyle:{"height":"250px"}},[_c('div',{staticClass:"block-content block-content-full pb-0",staticStyle:{"padding-top":"80px"}},[_c('grid-loader',{staticClass:"mx-auto",attrs:{"color":'#70B9EB',"size":'10px'}}),_vm._v(" "),_c('center',{staticClass:"mt-2"},[_c('span',{staticClass:"text-center",staticStyle:{"opacity":"0.7"}},[_vm._v("Save Data")])])],1)]):_vm._e(),_vm._v(" "),(_vm.loadingPage == 0)?_c('div',{staticClass:"block col-12 col-md-8 offset-md-2 block-rounded block-mode-loading-oneui"},[_c('div',{staticClass:"block-content block-content-full"},[_c('form',{attrs:{"method":"POST","enctype":"multipart/form-data","onsubmit":"return false;"}},[_c('div',{staticClass:"row font-size-sm"},[_c('div',{staticClass:"col-12 col-md-10 offset-md-1"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Contact Type *")]),_vm._v(" "),_c('selectize',{staticClass:"form-control",class:{
                                        'is-invalid-selectize':
                                            _vm.errors.contact_type,
                                    },attrs:{"placeholder":"Choose Membership"},model:{value:(_vm.contact.contact_type),callback:function ($$v) {_vm.$set(_vm.contact, "contact_type", $$v)},expression:"contact.contact_type"}},[_c('option',{attrs:{"value":"1"}},[_vm._v("Customer")]),_vm._v(" "),_c('option',{attrs:{"value":"2"}},[_vm._v("Supplier")])]),_vm._v(" "),(_vm.errors.contact_type)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                    "+_vm._s(_vm.errors.contact_type[0])+"\n                                ")]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Contact Name *")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contact.contact_name),expression:"contact.contact_name"}],staticClass:"form-control",class:{
                                        'is-invalid': _vm.errors.contact_name,
                                    },attrs:{"type":"text","placeholder":"Contact Name"},domProps:{"value":(_vm.contact.contact_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contact, "contact_name", $event.target.value)}}}),_vm._v(" "),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.errors.contact_name)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                        "+_vm._s(_vm.errors.contact_name[0])+"\n                                    ")]):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"form-group mb-2"},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Address *")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contact.contact_address),expression:"contact.contact_address"}],staticClass:"form-control",class:{
                                        'is-invalid':
                                            _vm.errors.contact_address,
                                    },attrs:{"type":"text","placeholder":"Address"},domProps:{"value":(_vm.contact.contact_address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contact, "contact_address", $event.target.value)}}}),_vm._v(" "),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.errors.contact_address)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                        "+_vm._s(_vm.errors.contact_address[0])+"\n                                    ")]):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-12 col-md-6 mb-0 mb-md-3"},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Province")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contact.contact_province),expression:"contact.contact_province"}],staticClass:"form-control",class:{
                                            'is-invalid':
                                                _vm.errors.contact_province,
                                        },attrs:{"type":"text","placeholder":"Province"},domProps:{"value":(_vm.contact.contact_province)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contact, "contact_province", $event.target.value)}}}),_vm._v(" "),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.errors.contact_province)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                            "+_vm._s(_vm.errors.contact_province[0])+"\n                                        ")]):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"form-group col-12 col-md-6 mb-3"},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Postal Code")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contact.contact_postalcode),expression:"contact.contact_postalcode"}],staticClass:"form-control",class:{
                                            'is-invalid':
                                                _vm.errors.contact_postalcode,
                                        },attrs:{"type":"text","placeholder":"Postal Code"},domProps:{"value":(_vm.contact.contact_postalcode)},on:{"keypress":function($event){return _vm.isNumber($event)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contact, "contact_postalcode", $event.target.value)}}}),_vm._v(" "),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.errors.contact_postalcode)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                            "+_vm._s(_vm.errors.contact_postalcode[0])+"\n                                        ")]):_vm._e()])],1)]),_vm._v(" "),_c('div',{staticClass:"form-group mb-3"},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Email")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contact.contact_email),expression:"contact.contact_email"}],staticClass:"form-control",class:{
                                        'is-invalid': _vm.errors.contact_email,
                                    },attrs:{"type":"text","placeholder":"Email"},domProps:{"value":(_vm.contact.contact_email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contact, "contact_email", $event.target.value)}}}),_vm._v(" "),(_vm.errors.contact_email)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                    "+_vm._s(_vm.errors.contact_email[0])+"\n                                ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-12 col-md-6 mb-1"},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Contact Name")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                            _vm.contact.contact_contactname
                                        ),expression:"\n                                            contact.contact_contactname\n                                        "}],staticClass:"form-control",class:{
                                            'is-invalid':
                                                _vm.errors.contact_contactname,
                                        },attrs:{"type":"text","placeholder":"Contact Name"},domProps:{"value":(
                                            _vm.contact.contact_contactname
                                        )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contact, "contact_contactname", $event.target.value)}}}),_vm._v(" "),(_vm.errors.contact_contactname)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                        "+_vm._s(_vm.errors.contact_contactname[0])+"\n                                    ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"form-group col-12 col-md-6 mb-1"},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Contact Phone")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                            _vm.contact.contact_contactphone
                                        ),expression:"\n                                            contact.contact_contactphone\n                                        "}],staticClass:"form-control",class:{
                                            'is-invalid':
                                                _vm.errors.contact_contactphone,
                                        },attrs:{"type":"text","placeholder":"Contact Phone"},domProps:{"value":(
                                            _vm.contact.contact_contactphone
                                        )},on:{"keypress":function($event){return _vm.isNumber($event)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contact, "contact_contactphone", $event.target.value)}}}),_vm._v(" "),(_vm.errors.contact_contactphone)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                        "+_vm._s(_vm.errors.contact_contactphone[0])+"\n                                    ")]):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-12 col-md-6 mb-0 mb-md-3"},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Contact Name 2")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                            _vm.contact.contact_contactname2
                                        ),expression:"\n                                            contact.contact_contactname2\n                                        "}],staticClass:"form-control",class:{
                                            'is-invalid':
                                                _vm.errors.contact_contactname2,
                                        },attrs:{"type":"text","placeholder":"Contact Name 2"},domProps:{"value":(
                                            _vm.contact.contact_contactname2
                                        )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contact, "contact_contactname2", $event.target.value)}}}),_vm._v(" "),(_vm.errors.contact_contactname2)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                        "+_vm._s(_vm.errors.contact_contactname2[0])+"\n                                    ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"form-group col-12 col-md-6"},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Contact Phone 2")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                            _vm.contact.contact_contactphone2
                                        ),expression:"\n                                            contact.contact_contactphone2\n                                        "}],staticClass:"form-control",class:{
                                            'is-invalid':
                                                _vm.errors.contact_contactphone2,
                                        },attrs:{"type":"text","placeholder":"Contact Phone 2"},domProps:{"value":(
                                            _vm.contact.contact_contactphone2
                                        )},on:{"keypress":function($event){return _vm.isNumber($event)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contact, "contact_contactphone2", $event.target.value)}}}),_vm._v(" "),(_vm.errors.contact_contactphone2)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                        "+_vm._s(_vm.errors.contact_contactphone2[0])+"\n                                    ")]):_vm._e()])])])]),_vm._v(" "),_c('div',{staticClass:"row items-push"},[_c('div',{staticClass:"col-12 text-center"},[_c('p',{staticClass:"font-size-sm text-muted"},[_vm._v("\n                                * Require to be filled, Make sure all data is\n                                correctly filled\n                            ")]),_vm._v(" "),_c('button',{directives:[{name:"ripple",rawName:"v-ripple",value:({ center: true }),expression:"{ center: true }"}],staticClass:"btn btn-sm btn-success",attrs:{"type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.submitData($event)}}},[_c('i',{staticClass:"fa fa-fw fa-check mr-1"}),_vm._v("\n                                Update\n                            ")]),_vm._v(" "),_c('button',{directives:[{name:"ripple",rawName:"v-ripple",value:({ center: true }),expression:"{ center: true }"}],staticClass:"btn btn-sm btn-danger",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.deleteData($event)}}},[_c('i',{staticClass:"fa fa-fw fa-trash mr-1"}),_vm._v("\n                                Delete\n                            ")]),_vm._v(" "),_c('router-link',{directives:[{name:"ripple",rawName:"v-ripple",value:({ center: true }),expression:"{ center: true }"}],staticClass:"btn btn-sm btn-alt-light",attrs:{"type":"button","tag":"button","to":{ name: 'contact' }}},[_c('i',{staticClass:"fa fa-fw fa-times mr-1"}),_vm._v(" Close\n                            ")])],1)])])])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }