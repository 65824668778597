<template>
    <main>
        <div
            class="row px-2"
            v-if="this.bill.bill_transaction_from_order.length > 0"
        >
            <div class="col-sm-4 py-2 mb-1 bg-whitesmoke">
                <div class="row">
                    <div class="col-11 py-1 text-left font-weight-bold">
                        ITEM
                    </div>
                </div>
            </div>
            <div class="col-sm-2 py-2 mb-1 bg-whitesmoke">
                <div class="row">
                    <div class="col-6 pr-0 py-1 text-left font-weight-bold">
                        QTY
                    </div>
                    <div class="col-6 pr-0 py-1 text-left font-weight-bold">
                        SOLD
                    </div>
                </div>
            </div>
            <div class="col-sm-6 py-2 mb-1 bg-whitesmoke">
                <div class="row">
                    <div class="col-4 pr-0 py-1 text-left font-weight-bold">
                        UNIT PRICE
                    </div>
                    <div class="col-3 pr-0 py-1 text-left font-weight-bold">
                        TAX RATE
                    </div>
                    <div class="col-4 pr-0 py-1 text-left font-weight-bold">
                        AMOUNT
                    </div>
                    <div
                        class="col-1 pr-0 py-1 text-left font-weight-bold"
                    ></div>
                </div>
            </div>
        </div>
        <div
            class="row"
            v-for="(transaction, index) in bill.bill_transaction_from_order"
            :key="`tr_order_` + index"
        >
            <div class="col-sm-4 py-1">
                <div
                    class="row"
                    v-bind:class="{
                        'd-none': transaction.transaction_format == 2
                    }"
                >
                    <!-- ITEM -->
                    <div class="col-9 pr-0 py-1">
                        <selectize
                            class="form-control mt-1"
                            :placeholder="'Item'"
                            :id="'transaction-item-' + index"
                            v-model="transaction.item_id"
                            :class="{
                                'is-invalid-selectize':
                                    errors[
                                        'bill_transaction_from_order.' +
                                            index +
                                            '.item_id'
                                    ]
                            }"
                            disabled
                        >
                            <!-- settings is optional -->
                            <option
                                v-for="item in items"
                                :value="item.id"
                                :key="item.id"
                            >
                                {{ item.item_name }}</option
                            >
                        </selectize>
                        <span
                            v-if="
                                errors[
                                    'bill_transaction_from_order.' +
                                        index +
                                        '.item_id'
                                ]
                            "
                            class="text-danger data-detail-error"
                        >
                            {{
                                errors[
                                    "bill_transaction_from_order." +
                                        index +
                                        ".item_id"
                                ][0]
                            }}
                        </span>
                    </div>
                    <!-- UOM -->
                    <div class="col-3 pr-0 py-1">
                        <selectize
                            class="form-control mt-1"
                            :placeholder="'Unit'"
                            :id="'transaction-uom-' + index"
                            v-model="transaction.uom_id"
                            :class="{
                                'is-invalid-selectize':
                                    errors[
                                        'bill_transaction_from_order.' +
                                            index +
                                            '.uom_id'
                                    ]
                            }"
                            disabled
                            v-if="
                                transaction.transaction_format == 0 ||
                                    transaction.transaction_format == 1
                            "
                        >
                            <!-- settings is optional -->
                            <option
                                v-for="uom in transaction.uom_list"
                                :value="uom.id"
                                :key="uom.id"
                            >
                                {{ uom.uom_name }}</option
                            >
                        </selectize>
                        <span
                            v-if="
                                errors[
                                    'bill_transaction_from_order.' +
                                        index +
                                        '.uom_id'
                                ]
                            "
                            class="text-danger data-detail-error"
                        >
                            {{
                                errors[
                                    "bill_transaction_from_order." +
                                        index +
                                        ".uom_id"
                                ][0]
                            }}
                        </span>
                    </div>
                </div>
                <!-- DESC & ACCOUNT -->
                <div
                    class="row"
                    v-bind:class="{
                        'd-none': transaction.transaction_format == 0
                    }"
                >
                    <div class="col-6 pr-0 py-1">
                        <!-- DESC -->
                        <input
                            type="text"
                            class="form-control my-1"
                            :placeholder="'Description'"
                            :id="'transaction-desc-' + index"
                            v-model="transaction.transaction_description"
                            :class="{
                                'is-invalid':
                                    errors[
                                        'bill_transaction_from_order.' +
                                            index +
                                            '.transaction_description'
                                    ]
                            }"
                            disabled
                        />
                        <span
                            v-if="
                                errors[
                                    'bill_transaction_from_order.' +
                                        index +
                                        '.transaction_description'
                                ]
                            "
                            class="text-danger data-detail-error"
                        >
                            {{
                                errors[
                                    "bill_transaction_from_order." +
                                        index +
                                        ".transaction_description"
                                ][0]
                            }}
                        </span>
                    </div>
                    <div class="col-6 pr-0 py-1">
                        <!-- COA -->
                        <selectize
                            class="form-control mt-1"
                            :placeholder="'Account No.'"
                            :id="'transaction-coa-' + index"
                            v-model="transaction.transaction_coa_id"
                            :class="{
                                'is-invalid-selectize':
                                    errors[
                                        'bill_transaction_from_order.' +
                                            index +
                                            '.transaction_coa_id'
                                    ]
                            }"
                            v-if="
                                transaction.transaction_format == 1 ||
                                    transaction.transaction_format == 2
                            "
                            disabled
                        >
                            <!-- settings is optional -->
                            <option
                                v-for="account in transactionAccounts"
                                :value="account.id"
                                :key="account.id"
                                >{{ account.account_no }}
                                {{ account.account_name }}</option
                            >
                        </selectize>
                        <span
                            v-if="
                                errors[
                                    'bill_transaction_from_order.' +
                                        index +
                                        '.transaction_coa_id'
                                ]
                            "
                            class="text-danger data-detail-error"
                        >
                            {{
                                errors[
                                    "bill_transaction_from_order." +
                                        index +
                                        ".transaction_coa_id"
                                ][0]
                            }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-sm-2 py-1">
                <div class="row">
                    <div class="col-6 pr-0 py-1 text-center">
                        <!-- QTY -->
                        <numeric
                            class="form-control mt-1 mb-2"
                            placeholder="Qty"
                            v-model="transaction.transaction_qty"
                            spellcheck="false"
                            disabled
                            separator="."
                            :precision="2"
                        />
                        <span class="text-muted-high">
                            Sold : {{ transaction.transaction_done_qty }} /
                            {{ transaction.transaction_qty }}
                        </span>
                    </div>
                    <div class="col-6 pr-0 py-1">
                        <!-- SOLD QTY -->
                        <numeric
                            class="form-control mt-1 mb-2"
                            placeholder="Received Qty"
                            v-model="transaction.transaction_inv_qty"
                            spellcheck="false"
                            separator="."
                            :precision="2"
                            :max="
                                transaction.transaction_qty -
                                    transaction.transaction_done_qty
                            "
                            @blur.native="calculateTransaction(index)"
                            :disabled="
                                transaction.transaction_qty -
                                    transaction.transaction_done_qty ==
                                    0
                            "
                        />
                        <span
                            class="text-muted-high"
                            v-if="
                                transaction.transaction_done_qty ==
                                    transaction.transaction_qty
                            "
                        >
                            Already Sold
                        </span>
                        <span
                            v-if="
                                errors[
                                    'bill_transaction_from_order.' +
                                        index +
                                        '.transaction_inv_qty'
                                ]
                            "
                            class="text-danger data-detail-error"
                        >
                            {{
                                errors[
                                    "bill_transaction_from_order." +
                                        index +
                                        ".transaction_inv_qty"
                                ][0]
                            }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 py-1">
                <div class="row">
                    <div class="col-4 pr-0 py-1">
                        <!-- UNIT PRICE -->
                        <money
                            type="text"
                            class="form-control mt-1 mb-2 text-right"
                            placeholder="Unit Price"
                            v-model="transaction.transaction_price"
                            :class="{
                                'is-invalid':
                                    errors[
                                        'bill_transaction_from_order.' +
                                            index +
                                            '.transaction_price'
                                    ]
                            }"
                            disabled
                        />
                        <span
                            v-if="
                                errors[
                                    'bill_transaction_from_order.' +
                                        index +
                                        '.transaction_price'
                                ]
                            "
                            class="text-danger data-detail-error"
                        >
                            {{
                                errors[
                                    "bill_transaction_from_order." +
                                        index +
                                        ".transaction_price"
                                ][0]
                            }}
                        </span>
                    </div>
                    <div class="col-3 pr-0 py-1">
                        <selectize
                            class="form-control mt-1"
                            placeholder="Tax"
                            v-model="transaction.transaction_tax_id"
                            :class="{
                                'is-invalid-selectize':
                                    errors[
                                        'bill_transaction_from_order.' +
                                            index +
                                            '.transaction_tax_id'
                                    ]
                            }"
                            @input="
                                getTaxDetail(
                                    index,
                                    transaction.transaction_tax_id
                                )
                            "
                            disabled
                        >
                            <!-- settings is optional -->
                            <option
                                v-for="(tax, indeks) in taxs"
                                :value="tax.id"
                                :key="indeks"
                            >
                                {{ tax.tax_code }}
                                ({{ tax.tax_rate }}%)
                            </option>
                        </selectize>
                        <span
                            v-if="
                                errors[
                                    'bill_transaction_from_order.' +
                                        index +
                                        '.transaction_tax_id'
                                ]
                            "
                            class="text-danger data-detail-error"
                        >
                            {{
                                errors[
                                    "bill_transaction_from_order." +
                                        index +
                                        ".transaction_tax_id"
                                ][0]
                            }}
                        </span>
                    </div>
                    <div class="col-4 pr-0 py-1">
                        <!-- AMOUNT -->
                        <money
                            type="text"
                            class="form-control mt-1 mb-2 text-right"
                            placeholder="Amount"
                            v-model="transaction.transaction_amount"
                            :class="{
                                'is-invalid':
                                    errors[
                                        'bill_transaction_from_order.' +
                                            index +
                                            '.transaction_amount'
                                    ]
                            }"
                            disabled
                        />
                        <span class="text-muted-high">
                            Ord. Amnt. :
                            {{ transaction.transaction_amount_base | currency }}
                        </span>
                        <span
                            v-if="
                                errors[
                                    'bill_transaction_from_order.' +
                                        index +
                                        '.transaction_amount'
                                ]
                            "
                            class="text-danger"
                            ><br />
                            {{
                                errors[
                                    "bill_transaction_from_order." +
                                        index +
                                        ".transaction_amount"
                                ][0]
                            }}
                        </span>
                    </div>
                    <div class="col-1 pr-0 py-1"></div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import moment from "moment";

export default {
    name: "associate-transaction-component",
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState("bill", { bill: state => state.bill }),
        ...mapState("coa", { coas: state => state.coas.accounts }),
        ...mapState("coa", { coalink: state => state.coalink }),
        ...mapState("item", { items: state => state.items }),
        ...mapState("tax", { taxs: state => state.taxs }),
        transactionAccounts: function() {
            if (this.coas) {
                return this.coas.filter(function(coa) {
                    return (
                        coa.account_type === "Income" ||
                        coa.account_type === "Other Income"
                    );
                });
            }
        }
    },
    methods: {
        //VUEX
        ...mapActions("coa", ["getCoaAll", "getCoaLink"]),
        ...mapActions("contact", ["getContactCustomer"]),
        ...mapActions("item", ["getItemAll"]),
        ...mapActions("uom", ["getUom"]),
        ...mapActions("tax", ["getTaxAllBills"]),
        //METHOD
        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                // 46 is dot
                $event.preventDefault();
            }
        },
        calculateTransaction(index) {
            this.bill.bill_transaction_from_order[index].transaction_qty = this.bill.bill_transaction_from_order[index].inHandStock == 0 ? 0 : this.bill.bill_transaction_from_order[index].transaction_qty;
            this.bill.bill_transaction_from_order[index].transaction_amount =
                this.bill.bill_transaction_from_order[index]
                    .transaction_inv_qty *
                    this.bill.bill_transaction_from_order[index]
                        .transaction_price +
                (this.bill.bill_transaction_from_order[index]
                    .transaction_inv_qty *
                    this.bill.bill_transaction_from_order[index]
                        .transaction_price *
                    this.bill.bill_transaction_from_order[index]
                        .transaction_tax_rate) /
                    100;
            this.calculate();
        },
        calculate() {
            var sub = 0;
            var tax = 0;

            this.bill.bill_transaction_from_order.forEach(transaction => {
                sub +=
                    transaction.transaction_price *
                    transaction.transaction_inv_qty;
                if (isNaN(transaction.transaction_tax_rate)) {
                } else {
                    let taxTotal =
                        (transaction.transaction_price *
                            transaction.transaction_inv_qty *
                            transaction.transaction_tax_rate) /
                        100;
                    tax = tax + parseInt(taxTotal.toFixed(0));
                }
            });
            this.bill.bill_transaction.forEach(transaction => {
                sub +=
                    transaction.transaction_price * transaction.transaction_qty;
                if (isNaN(transaction.transaction_tax_rate)) {
                } else {
                    let taxTotal =
                        (transaction.transaction_price *
                            transaction.transaction_qty *
                            transaction.transaction_tax_rate) /
                        100;
                    tax = tax + parseInt(taxTotal.toFixed(0));
                }
            });

            this.bill.bill_sub_total = sub.toFixed(0);
            this.bill.bill_tax_total = tax.toFixed(0);
            this.bill.bill_grand_total = (sub + tax).toFixed(0);
        }
    }
};
</script>
