import $axios from "../../api.js";
import SecureLS from "secure-ls";
var ls = new SecureLS({ isCompression: false, encryptionSecret: "d3v3L0P3r!" });

const state = () => ({});

const mutations = {};

const actions = {
    //UNTUK MENGAMBIL SINGLE DATA DARI SERVER BERDASARKAN CODE USER
    editProfile({ commit }, payload) {
        return new Promise((resolve, reject) => {
            //MELAKUKAN REQUEST DENGAN MENGIRIMKAN CODE USER DI URL
            $axios
                .get(`/edit-profile`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    //UNTUK MENGUPDATE DATA BERDASARKAN CODE YANG SEDANG DIEDIT
    updateProfile({ rootState, commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .put(`/edit-profile`, payload)
                .then(response => {
                    ls.set("_ath_", response.data.data);
                    commit(
                        "SET_AUTH",
                        {
                            data: response.data.data,
                            role: ls.get("_rl_"),
                            daylimit: ls.get("_dl_"),
                            type: ls.get("_tp_")
                        },
                        { root: true }
                    );
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                });
        });
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
