import $axios from "../../api.js";
import moment from "moment";


const state = () => ({
    depreciations: [],

    depreciation: {
        depreciation_id: 0,
        category_id: 0,
        journal_id: 0,
        depreciation_memo: "",
        depreciation_period: moment().format("YYYY-MM"),
        depreciation_total: 0,
        user_id: 0,
        detail:[],
        journal:{},
        category:{}
    },
    page: 1, //UNTUK MENCATAT PAGE PAGINATE YANG SEDANG DIAKSES
    last_page: 1,
    search: ""
});

const mutations = {
    ASSIGN_DATA(state, payload) {
        state.depreciations = payload;
        state.last_page = payload.meta.last_page;
        state.page = payload.meta.current_page;
    },
    SET_PAGE(state, payload) {
        state.page = payload;
    },
    ASSIGN_FORM(state, payload) {
        state.depreciation = {
            depreciation_id: payload.id,
            category_id: payload.category_id,
            journal_id: payload.journal_id,
            depreciation_memo: payload.depreciation_memo,
            depreciation_period: payload.depreciation_period,
            depreciation_total: payload.depreciation_total,
            user_id: 0,
            detail:payload.detail,
            journal:payload.journal,
            category:payload.category
        };
    },
    //ME-RESET STATE NEWS MENJADI KOSONG
    CLEAR_FORM(state) {
        state.depreciation = {
            depreciation_id: 0,
            category_id: 0,
            journal_id: 0,
            depreciation_memo: "",
            depreciation_period: moment().format("YYYY-MM"),
            depreciation_total: 0,
            user_id: 0,
            detail:[],
            journal:{},
            category:{}
        };
    },
    CLEAR_DATA(state) {
        state.depreciations = [];
    }
};

const actions = {
    getDepreciation({ dispatch, commit, state }, payload) {
        let search = typeof payload.search != "undefined" ? payload.search : "";
        let rows = payload.rows;
        let page =
            typeof payload.rows != "All" && payload.rowsUpdate != 1
                ? state.page
                : "1";
        let date_start = payload.date_start
        let date_end = payload.date_end

        return new Promise((resolve, reject) => {
            $axios
                .get(`/depreciation?page=${page}&search=${search}&rows=${rows}&date_start=${date_start}&date_end=${date_end}`)
                .then(response => {
                    commit("ASSIGN_DATA", response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                    reject(error);
                });
        });
    },
    submitDepreciation({ dispatch, commit, state }) {
        return new Promise((resolve, reject) => {
            $axios
                .post(`/depreciation`, state.depreciation)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                });
        });
    },
    editDepreciation({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.get(`/depreciation/${payload}/edit`).then(response => {
                commit("ASSIGN_FORM", response.data);
                resolve(response.data);
            });
        });
    },
    updateDepreciation({ state, commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .put(`/depreciation/${payload}`, state.depreciation)
                .then(response => {
                    //FORM DIBERSIHKAN
                    commit("CLEAR_FORM");
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                });
        });
    },
    //MENGHAPUS DATA
    removeDepreciation({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .delete(`/depreciation/${payload}`)
                .then(response => {
                    commit("CLEAR_FORM");
                    resolve(response.data);
                })
                .catch(error => {
                    if (error.response) {
                        commit("SET_ERRORS", error.response.data, {
                            root: true
                        });
                    }
                    reject(error);
                });
        });
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
