<template>
    <!-- Page Content -->
    <div class="content p-0">
        <!--Loading-->
        <div class="block mb-0" v-if="loadingPage == 1" style="height:200px">
            <div
                class="block-content block-content-full pb-0"
                style="padding-top: 80px"
            >
                <grid-loader
                    class="mx-auto"
                    :color="'#70B9EB'"
                    :size="'10px'"
                ></grid-loader>
                <center class="mt-2">
                    <span class="text-center" style="opacity:0.7">Loading</span>
                </center>
            </div>
        </div>
        <div class="block mb-0" v-if="loadingPage == 2" style="height:200px">
            <div
                class="block-content block-content-full pb-0"
                style="padding-top: 80px"
            >
                <div
                    class="spinner-border spinner-border-lg text-info mx-auto"
                    role="status"
                    style="display: flex;
                    justify-content: center;"
                ></div>
                <center class="mt-2">
                    <span class="text-center" style="opacity:0.7"
                        >Save Data</span
                    >
                </center>
            </div>
        </div>
        <!-- Basic -->
        <div class="block mb-0" v-if="loadingPage == 0">
            <form
                method="POST"
                enctype="multipart/form-data"
                onsubmit="return false;"
            >
                <div class="block-header py-3 bg-gray">
                    <div class="row">
                        <div class="col-12 py-0">
                            <p
                                class="mb-1 font-weight-bold"
                                v-if="this.purchase.bill_status == 1"
                            >
                                REVIEW BILL QUOTATION
                            </p>
                            <p
                                class="mb-1 font-weight-bold"
                                v-if="this.purchase.bill_status == 2"
                            >
                                REVIEW BILL ORDER
                            </p>
                            <p
                                class="mb-1 font-weight-bold"
                                v-if="this.purchase.bill_status == 3"
                            >
                                REVIEW BILL INVOICE
                            </p>
                            <h4 class="block-title">
                                {{ this.purchase.bill_number }}
                            </h4>
                        </div>
                    </div>
                </div>
                <div class="block-content block-content-full px-0">
                    <!-- REVIEW SECTION --------------------------->
                    <template>
                        <div class="row mb-1 mx-2">
                            <div class="col-12">
                                <div
                                    class="information-panel"
                                    style="background-color: #f0f0f087;"
                                >
                                    <p class="my-1">
                                        <i class="fa fa-info-circle mr-1"></i
                                        ><b>Information</b>
                                    </p>
                                    <p class="mb-2">
                                        Make sure the data you've entered is
                                        correct
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!--Form-->
                        <div class="row font-size-sm">
                            <div class="col-12 content pt-2">
                                <div class="row mb-1 mx-1">
                                    <div class="col-6 py-1">
                                        <label for="example-text-input"
                                            >Date</label
                                        >
                                        <p class="mt-0 mb-1">
                                            {{
                                                this.purchase.bill_date
                                                    | moment("DD MMMM YYYY")
                                            }}
                                        </p>
                                    </div>
                                    <div class="col-6 py-1">
                                        <label for="example-text-input"
                                            >Due Date</label
                                        >
                                        <p class="mt-0 mb-1">
                                            {{
                                                this.purchase.bill_due_date
                                                    | moment("DD MMMM YYYY")
                                            }}
                                        </p>
                                    </div>
                                </div>
                                <div class="row mb-1 mx-1">
                                    <div class="col-6 py-1">
                                        <label for="example-text-input"
                                            >Customer Name</label
                                        >
                                        <p class="mt-0 mb-1">
                                            {{ this.purchase.contact.contact_name }}
                                        </p>
                                    </div>
                                    <div class="col-6 py-1">
                                        <label for="example-text-input"
                                            >Shipment Address</label
                                        >
                                        <p class="mt-0 mb-1">
                                            {{ this.purchase.bill_shipaddress }}
                                        </p>
                                    </div>
                                </div>
                                <hr class="my-1 mx-1" />
                                <!-- TRANSACTION -->
                                <div class="row mx-1">
                                    <div class="col-7 py-1">
                                        <label for="example-text-input"
                                            >Transaction</label
                                        >
                                    </div>
                                    <div class="col-2 py-1 text-center">
                                        <label for="example-text-input"
                                            >Tax</label
                                        >
                                    </div>
                                    <div class="col-3 py-1 text-right">
                                        <label for="example-text-input"
                                            >Amount</label
                                        >
                                    </div>
                                </div>
                                <div
                                    class="row mx-1"
                                    v-for="(transaction, index) in this.purchase
                                        .bill_transaction_from_order"
                                    :key="'A' + index"
                                >
                                    <div class="col-7 py-1" v-if="transaction.transaction_inv_qty !=0">
                                        <span
                                            v-if="
                                                transaction.transaction_format ==
                                                    0 ||
                                                    transaction.transaction_format ==
                                                        1
                                            "
                                        >
                                            {{
                                                transaction.item_name
                                                    ? transaction.item_name
                                                    : ""
                                            }}
                                        </span>
                                        <span v-if="
                                                transaction.transaction_format ==
                                                    2
                                            ">
                                            {{
                                                transaction.transaction_description !=
                                                null
                                                    ? transaction.transaction_description
                                                    : ""
                                            }}
                                        </span>
                                        <span class="text-muted-high">
                                            [
                                            {{
                                                transaction.transaction_price
                                                    | currency
                                            }}
                                            x
                                            {{ transaction.transaction_inv_qty }}
                                            ]
                                        </span>
                                    </div>
                                    <div class="col-2 py-1 text-center" v-if="transaction.transaction_inv_qty !=0">
                                        {{ transaction.transaction_tax_rate }} %
                                    </div>
                                    <div class="col-3 py-1 text-right" v-if="transaction.transaction_inv_qty !=0">
                                        {{
                                            transaction.transaction_amount
                                                | currency
                                        }}
                                    </div>
                                </div>
                                <div
                                    class="row mx-1"
                                    v-for="(transaction, index) in this.purchase
                                        .bill_transaction"
                                    :key="'B' + index"
                                >
                                    <div class="col-7 py-1">
                                        <span
                                            v-if="
                                                transaction.transaction_format ==
                                                    0 ||
                                                    transaction.transaction_format ==
                                                        1
                                            "
                                        >
                                            {{
                                                transaction.item_name
                                                    ? transaction.item_name
                                                    : ""
                                            }}
                                        </span>
                                        <span v-if="
                                                transaction.transaction_format ==
                                                    2
                                            ">
                                            {{
                                                transaction.transaction_description !=
                                                null
                                                    ? transaction.transaction_description
                                                    : ""
                                            }}
                                        </span>
                                        <span class="text-muted-high">
                                            [
                                            {{
                                                transaction.transaction_price
                                                    | currency
                                            }}
                                            x
                                            {{ transaction.transaction_qty }}
                                            ]
                                        </span>
                                    </div>
                                    <div class="col-2 py-1 text-center">
                                        {{ transaction.transaction_tax_rate }} %
                                    </div>
                                    <div class="col-3 py-1 text-right">
                                        {{
                                            transaction.transaction_amount
                                                | currency
                                        }}
                                    </div>
                                </div>

                                <hr class="my-1 mx-1" />
                                <!-- TOTAL -->
                                <div class="row px-3 mt-4 mb-4">
                                    <div
                                        class="col-6 offset-6"
                                        style="
                                                background-color: #f0f0f087 !important;
                                                width: 350px;
                                                padding-top: 10px;
                                            "
                                    >
                                        <strong>
                                            <p class="mb-2">
                                                Sub Total
                                                <span class="float-right">{{
                                                    purchase.bill_sub_total
                                                        | currency
                                                }}</span>
                                            </p>
                                        </strong>
                                        <p class="mb-2">
                                            Tax
                                            <small class="float-right">{{
                                                purchase.bill_tax_total | currency
                                            }}</small>
                                        </p>
                                        <strong>
                                            <p class="mb-2">
                                                Grand Total
                                                <span class="float-right">{{
                                                    purchase.bill_grand_total
                                                        | currency
                                                }}</span>
                                            </p>
                                        </strong>
                                    </div>
                                </div>

                                <span v-if="this.purchase.bill_status == 3">
                                    <hr class="my-1 mx-1" />
                                    <!-- JOURNAL -->
                                    <div class="row mb-1 mx-1">
                                        <div class="col-6 py-1">
                                            <label for="example-text-input"
                                                >Journal Memo</label
                                            >
                                            <p class="my-1">
                                                {{
                                                    this.purchase.journal
                                                        .journal_memo
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row mb-1 mx-1">
                                        <div class="col-12">
                                            <table class="table table-bordered">
                                                <thead class="bg-body-light">
                                                    <tr>
                                                        <th
                                                            class="text-left text-uppercase"
                                                        >
                                                            Account
                                                        </th>
                                                        <th
                                                            class="text-right text-uppercase"
                                                        >
                                                            Debit
                                                        </th>
                                                        <th
                                                            class="text-right text-uppercase"
                                                        >
                                                            Credit
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        v-for="(transaction,
                                                        index) in this.purchase
                                                            .journal
                                                            .transaction"
                                                        :key="'C' + index"
                                                    >
                                                        <td>
                                                            <p
                                                                class="mb-1 text-left"
                                                            >
                                                                {{
                                                                    transaction.transaction_coa_name
                                                                }}
                                                            </p>
                                                        </td>
                                                        <td class="text-right">
                                                            <p class="mb-1">
                                                                {{
                                                                    transaction.debit
                                                                        | currency
                                                                }}
                                                            </p>
                                                        </td>
                                                        <td class="text-right">
                                                            <p class="mb-1">
                                                                {{
                                                                    transaction.credit
                                                                        | currency
                                                                }}
                                                            </p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            class="font-w700 text-uppercase text-center"
                                                        >
                                                            Total
                                                        </td>
                                                        <td class="text-right">
                                                            <p
                                                                class="font-w700 mb-1"
                                                            >
                                                                {{
                                                                    this.purchase.journal
                                                                        .total_debit
                                                                        | currency
                                                                }}
                                                            </p>
                                                        </td>
                                                        <td class="text-right">
                                                            <p
                                                                class="font-w700 mb-1"
                                                            >
                                                                {{
                                                                    this.purchase.journal
                                                                        .total_credit
                                                                        | currency
                                                                }}
                                                            </p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <!--END Form-->
                    </template>
                </div>
                <div class="block-footer border-t-1">
                    <!-- Submit -->
                    <div class="footer text-right p-3">
                        <template>
                            <button
                                type="button"
                                class="btn btn-sm btn-transparent mr-1"
                                tag="button"
                                @click.prevent="close"
                            >
                                <i class="fa fa-fw fa-undo mr-1"></i> Close
                            </button>
                            <button
                                v-if="type =='submit'"
                                type="submit"
                                class="btn btn-sm btn-info"
                                v-ripple="{ center: true }"
                                @click.prevent="submit"
                            >
                                <i class="fa fa-fw fa-check mr-1"></i>
                                Submit
                            </button>
                            <button
                                v-if="type =='update'"
                                type="submit"
                                class="btn btn-sm btn-info"
                                v-ripple="{ center: true }"
                                @click.prevent="update"
                            >
                                <i class="fa fa-fw fa-check mr-1"></i>
                                Update
                            </button>
                        </template>
                    </div>
                    <!-- END Submit -->
                </div>
            </form>
        </div>
        <!-- END Basic -->
    </div>
    <!-- END Page Content -->
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

export default {
    name: "review-purchase",
    props: ["data","type"],
    data() {
        return {
            loadingPage: 0,
            prefix: "1-",
            showReview: false,
            disableQty: null
        };
    },
    created() {
        // this.setMutationType();
        // this.mutation.detail[0].item_id = this.itemId;
    },
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState("purchase", { purchase: state => state.purchase }),
    },
    methods: {
        //VUEX
        ...mapMutations(["CLEAR_ERRORS"]),
        ...mapMutations("purchase", ["CLEAR_FORM"]),
        ...mapActions("item", ["getCOGSRequest"]),
        ...mapActions("purchase", [
            "submitQuotation",
            "submitOrder",
            "submitInvoice",
            "updatePurchase"
        ]),
        submit() {
            this.CLEAR_ERRORS();
            this.loadingPage = 2;
            if (this.purchase.bill_status == 1) {
                this.submitQuotation()
                    .then(response => {
                        this.CLEAR_FORM();
                        this.$modal.hide("review-purchase");
                        this.alert(
                            "Successfully create Bill Quotation Data ",
                            1
                        );
                        this.loadingPage = 0;
                    })
                    .catch(error => {
                        error.response.status == 500
                            ? this.alert(error.response.data.message, 2)
                            : "";
                        error.response.status != 422 &&
                        error.response.status != 500
                            ? this.alert(error.message, 2)
                            : "";
                        this.loadingPage = 0;
                    });
            } else if (this.purchase.bill_status == 2) {
                this.submitOrder()
                    .then(response => {
                        this.CLEAR_FORM();
                        this.$modal.hide("review-purchase");
                        this.alert("Successfully create Bill Order Data ", 1);
                        this.loadingPage = 0;
                    })
                    .catch(error => {
                        error.response.status == 500
                            ? this.alert(error.response.data.message, 2)
                            : "";
                        error.response.status != 422 &&
                        error.response.status != 500
                            ? this.alert(error.message, 2)
                            : "";
                        this.loadingPage = 0;
                    });
            } else if (this.purchase.bill_status == 3) {
                this.submitInvoice()
                    .then(response => {
                        this.CLEAR_FORM();
                        this.$modal.hide("review-purchase");
                        this.alert("Successfully create Bill Invoice Data ", 1);
                        this.loadingPage = 0;
                    })
                    .catch(error => {
                        error.response.status == 500
                            ? this.alert(error.response.data.message, 2)
                            : "";
                        error.response.status != 422 &&
                        error.response.status != 500
                            ? this.alert(error.message, 2)
                            : "";
                        this.loadingPage = 0;
                    });
            } else {
            }
        },
        update() {
            this.CLEAR_ERRORS();
            this.loadingPage = 2;
            if (this.purchase.bill_status == 1) {
                this.purchase.bill_status = 1;
                this.updatePurchase(this.$route.params.id)
                    .then(response => {
                        this.CLEAR_FORM();
                        this.$modal.hide("review-purchase");
                        this.alert(
                            "Successfully update Purchase Quotation Data ",
                            1
                        );
                        this.loadingPage = 0;
                        this.$router.push({
                            name: "purchase-register",
                            params: { reloadData: true }
                        });
                    })
                    .catch(error => {
                        error.response.status == 500
                            ? this.alert(error.response.data.message, 2)
                            : "";
                        error.response.status != 422 &&
                        error.response.status != 500
                            ? this.alert(error.message, 2)
                            : "";
                        this.loadingPage = 0;
                    });
            } else if (this.purchase.bill_status == 2) {
                this.purchase.bill_status = 2;
                this.updatePurchase(this.$route.params.id)
                    .then(response => {
                        this.CLEAR_FORM();
                        this.$modal.hide("review-purchase");
                        this.alert("Successfully update Purchase Order Data ", 1);
                        this.loadingPage = 0;
                        this.$router.push({
                            name: "purchase-register",
                            params: { reloadData: true }
                        });
                    })
                    .catch(error => {
                        error.response.status == 500
                            ? this.alert(error.response.data.message, 2)
                            : "";
                        error.response.status != 422 &&
                        error.response.status != 500
                            ? this.alert(error.message, 2)
                            : "";
                        this.loadingPage = 0;
                    });
            } else if (this.purchase.bill_status == 3) {
                this.purchase.bill_status = 3;
                this.updatePurchase(this.$route.params.id)
                    .then(response => {
                        this.CLEAR_FORM();
                        this.$modal.hide("review-purchase");
                        this.alert("Successfully update Purchase Invoice Data ", 1);
                        this.loadingPage = 0;
                        this.$router.push({
                            name: "purchase-register",
                            params: { reloadData: true }
                        });
                    })
                    .catch(error => {
                        error.response.status == 500
                            ? this.alert(error.response.data.message, 2)
                            : "";
                        error.response.status != 422 &&
                        error.response.status != 500
                            ? this.alert(error.message, 2)
                            : "";
                        this.loadingPage = 0;
                    });
            }
        },
        close() {
            this.$modal.hide("review-purchase");
            document.body.classList.remove("modal-open");
        }
    }
};
</script>
