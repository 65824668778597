<template>
    <main>
        <!-- Hero -->
        <div class="bg-body-light">
            <div class="content content-full">
                <div
                    class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center"
                >
                    <h1 class="flex-sm-fill h3 my-2">
                        Administrator : Create Workspace
                    </h1>
                    <nav
                        class="flex-sm-00-auto ml-sm-3"
                        aria-label="breadcrumb"
                    >
                        <ol class="breadcrumb breadcrumb-alt">
                            <li class="breadcrumb-item" aria-current="page">
                                <router-link
                                    class="link-fx"
                                    :to="{ name: 'home' }"
                                    >Dashboard</router-link
                                >
                            </li>
                            <li class="breadcrumb-item">Create Workspace</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
        <!-- END Hero -->

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 1"
                style="height:250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity:0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 2"
                style="height:250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity:0.7"
                            >Save Data</span
                        >
                    </center>
                </div>
            </div>
            <!-- Basic -->
            <div
                class="block col-12 col-md-8 offset-md-2 block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 0"
            >
                <div class="block-content block-content-full">
                    <form
                        method="POST"
                        enctype="multipart/form-data"
                        onsubmit="return false;"
                    >
                        <!--Form-->
                        <div class="row font-size-sm">
                            <div class="col-12 col-md-10 offset-md-1">
                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Workspace Name</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Workspace Name"
                                        v-model="workspace.workspace_name"
                                        :class="{
                                            'is-invalid': errors.workspace_name
                                        }"
                                    />
                                    <p
                                        class="text-danger"
                                        v-if="errors.workspace_name"
                                    >
                                        {{ errors.workspace_name[0] }}
                                    </p>
                                </div>

                                <div class="form-group">
                                    <label for="example-password-input"
                                        >Workspace Type</label
                                    >
                                    <selectize
                                        class="form-control"
                                        placeholder="Workspace Type"
                                        v-model="workspace.workspace_level"
                                        :class="{
                                            'is-invalid-selectize':
                                                errors.workspace_level
                                        }"
                                    >
                                        <!-- settings is optional -->
                                        <option value="2"
                                            >Subsidiary Workspace</option
                                        >
                                        <option value="3"
                                            >Branch Company Workspace</option
                                        >
                                    </selectize>
                                    <span
                                        v-if="errors.membership_plan"
                                        class="text-danger"
                                    >
                                        {{ errors.membership_plan[0] }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <!--END Form-->
                        <!-- Submit -->
                        <div class="row items-push">
                            <div class="col-12 text-center">
                                <p
                                    class="font-size-sm text-muted"
                                >* Require to be filled, Make sure all data is correctly filled</p>
                                <button
                                    type="submit"
                                    class="btn btn-sm btn-success"
                                    v-ripple="{ center: true }"
                                    @click.prevent="submit"
                                >
                                    <i class="fa fa-fw fa-check mr-1"></i> Save
                                </button>
                                <router-link
                                    type="button"
                                    class="btn btn-sm btn-alt-light"
                                    tag="button"
                                    v-ripple="{ center: true }"
                                    :to="{ name: 'workspace' }"
                                >
                                    <i class="fa fa-fw fa-times mr-1"></i> Close
                                </router-link>
                            </div>
                        </div>
                        <!-- END Submit -->
                    </form>
                </div>
            </div>
            <!-- END Basic -->
        </div>
        <!-- END Page Content -->
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

export default {
    name: "create-workspace",

    data() {
        return {
            loadingPage: 2
        };
    },
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState("workspace", {
            workspace: state => state.workspace
        })
    },
    mounted() {
        this.doLoading(1, 200);
    },
    methods: {
        //VUEX
        ...mapMutations(["CLEAR_ERRORS"]),
        ...mapMutations("workspace", ["CLEAR_FORM"]),
        ...mapActions("workspace", ["submitWorkspace"]),

        //METHOD
        doLoading(type, time) {
            this.loadingPage = type;
            setTimeout(() => {
                this.loadingPage = 0;
            }, 500);
        },
        submit() {
            this.CLEAR_ERRORS();
            this.loadingPage = 2;
            this.submitWorkspace()
                .then(response => {
                    this.CLEAR_FORM();
                    this.alert("Successfully create New Workspace", 1);
                    this.$router.push({ name: "workspace" });
                    this.loadingPage = 0;
                })
                .catch(error => {
                    error.response.status == 500
                        ? this.alert(error.response.data.message, 2)
                        : "";
                    error.response.status != 422 && error.response.status != 500
                        ? this.alert(error.message, 2)
                        : "";
                    console.log(error);
                    this.loadingPage = 0;
                });
        }
    },
    destroyed() {
        //FORM DI BERSIHKAN
        this.CLEAR_FORM();
    }
};
</script>
