import $axios from "../../api.js";

const state = () => ({
    taxs: [],

    tax: {
        tax_id: 0,
        tax_code: "",
        tax_name: "",
        tax_rate: "",
        tax_coa_ar: "",
        tax_coa_ap: ""
    },
    page: 1, //UNTUK MENCATAT PAGE PAGINATE YANG SEDANG DIAKSES
    last_page: 1,
    search: ""
});

const mutations = {
    ASSIGN_DATA(state, payload) {
        state.taxs = payload;
        state.last_page = payload.meta.last_page;
        state.page = payload.meta.current_page;
    },
    SET_PAGE(state, payload) {
        state.page = payload;
    },
    ASSIGN_FORM(state, payload) {
        state.tax = {
            tax_id: payload.id,
            tax_code: payload.tax_code,
            tax_name: payload.tax_name,
            tax_rate: payload.tax_rate,
            tax_coa_ar: payload.tax_coa_ar,
            tax_coa_ap: payload.tax_coa_ap
        };
    },
    //ME-RESET STATE NEWS MENJADI KOSONG
    CLEAR_FORM(state) {
        state.tax = {
            tax_id: 0,
            tax_code: "",
            tax_name: "",
            tax_rate: "",
            tax_coa_ar: "",
            tax_coa_ap: ""
        };
    },
    CLEAR_DATA(state) {
        state.taxs = [];
    }
};

const actions = {
    getTax({ dispatch, commit, state }, payload) {
        let search = typeof payload.search != "undefined" ? payload.search : "";
        let rows = payload.rows;
        let page =
            typeof payload.rows != "All" && payload.rowsUpdate != 1
                ? state.page
                : "1";

        return new Promise((resolve, reject) => {
            $axios
                .get(`/tax?page=${page}&search=${search}&rows=${rows}`)
                .then(response => {
                    commit("ASSIGN_DATA", response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                    reject(error);
                });
        });
    },
    getTaxAll({ state }) {
        $axios
            .get(`/tax/retrive-all-data`)
            .then(response => {
                state.taxs = response.data;
                this.loading = false;
            })
            .catch(error => {
                error.response.status === 401
                    ? dispatch("DO_LOGOUT", null, { root: true })
                    : "";
                reject(error);
            });
    },
    getTaxAllBills({ state }) {
        $axios
            .get(`/tax/retrive-all-data-bills`)
            .then(response => {
                state.taxs = response.data;
                this.loading = false;
            })
            .catch(error => {
                error.response.status === 401
                    ? dispatch("DO_LOGOUT", null, { root: true })
                    : "";
                reject(error);
            });
    },
    getTaxAllPurchases({ state }) {
        $axios
            .get(`/tax/retrive-all-data-purchases`)
            .then(response => {
                state.taxs = response.data;
                this.loading = false;
            })
            .catch(error => {
                error.response.status === 401
                    ? dispatch("DO_LOGOUT", null, { root: true })
                    : "";
                reject(error);
            });
    },
    submitTax({ dispatch, commit, state }) {
        return new Promise((resolve, reject) => {
            $axios
                .post(`/tax`, state.tax)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                });
        });
    },
    editTax({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.get(`/tax/${payload}/edit`).then(response => {
                commit("ASSIGN_FORM", response.data);
                resolve(response.data);
            });
        });
    },
    updateTax({ state, commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .put(`/tax/${payload}`, state.tax)
                .then(response => {
                    //FORM DIBERSIHKAN
                    commit("CLEAR_FORM");
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                });
        });
    },
    //MENGHAPUS DATA
    removeTax({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .delete(`/tax/${payload}`)
                .then(response => {
                    commit("CLEAR_FORM");
                    resolve(response.data);
                })
                .catch(error => {
                    if (error.response) {
                        commit("SET_ERRORS", error.response.data, {
                            root: true
                        });
                    }
                    reject(error);
                });
        });
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
