import Vue from "vue";

import $axios from "../../api.js";

const state = () => ({
    purchases: [],

    purchase: {
        forbidden: false,
        bills_id: 0,
        bill_type: "Sales",
        bill_contact_id: "",
        bill_contact_beginning_balance: 0,
        bill_date: Vue.moment().format("YYYY-MM-DD"),
        bill_due_date: "",
        bill_shipaddress: "",
        bill_status: 1,
        bill_quotation_number: "",
        bill_order_number: "",
        bill_invoice_number: "",
        bill_coa_id: "",
        bill_journal_memo: "",
        bill_sub_total: 0,
        bill_tax_total: 0,
        bill_grand_total: 0,
        bill_paid_total: 0,
        bill_transaction: [
            {
                transaction_format: 0,
                transaction_tax_id: "",
                transaction_tax_rate: 0,
                transaction_coa_id: "",
                transaction_description: "",
                transaction_qty: 0,
                transaction_price: 0,
                transaction_amount: 0,
                transaction_done_qty: 0,
                item_id: 0,
                uom_id: 0,
                ref_order_tr_id: 0,
                inHandStock: 0
            }
        ],
        bill_transaction_delete: [],
        bill_transaction_from_order: [],
        bill_reference_id: 0,
        bill_reference_list: [],
        reference_invoice: [],
        reference_order: {},
        journal: {},
        contact: {},
        created_by: "",
        created_at: ""
    },
    page: 1, //UNTUK MENCATAT PAGE PAGINATE YANG SEDANG DIAKSES
    last_page: 1,
    search: ""
});

const mutations = {
    ASSIGN_DATA(state, payload) {
        state.purchases = payload;
        state.last_page = payload.meta.last_page;
        state.page = payload.meta.current_page;
    },
    SET_PAGE(state, payload) {
        state.page = payload;
    },
    ASSIGN_FORM(state, payload) {
        state.purchase = {
            forbidden: payload.forbidden,
            bills_id: payload.id,
            bill_type: payload.bill_type,
            bill_contact_id: payload.bill_contact_id,
            bill_contact_beginning_balance:
                payload.bill_contact_beginning_balance,
            bill_date: payload.bill_date,
            bill_due_date: payload.bill_due_date,
            bill_shipaddress: payload.bill_shipaddress,
            bill_status: payload.bill_status,
            bill_quotation_number: payload.bill_quotation_number,
            bill_order_number: payload.bill_order_number,
            bill_invoice_number: payload.bill_invoice_number,
            bill_journal_memo: payload.bill_journal_memo,
            bill_coa_id: payload.bill_coa_id,
            bill_sub_total: payload.bill_sub_total,
            bill_tax_total: payload.bill_tax_total,
            bill_grand_total: payload.bill_grand_total,
            bill_paid_total: payload.bill_paid_total,
            bill_transaction: payload.transaction,
            bill_transaction_delete: [],
            bill_transaction_from_order: [],
            journal: payload.journal,
            contact: payload.contact,
            bill_reference_id: payload.bill_reference_id,
            payment_detail: payload.payment_detail,
            reference_invoice: payload.reference_invoice,
            reference_order: payload.reference_order,
            created_by: payload.created_by,
            created_at: payload.created_at
        };
    },
    //ME-RESET STATE NEWS MENJADI KOSONG
    CLEAR_FORM(state) {
        state.purchase = {
            forbidden: false,
            bills_id: 0,
            bill_type: "Sales",
            bill_contact_id: "",
            bill_contact_beginning_balance: 0,
            bill_date: Vue.moment().format("YYYY-MM-DD"),
            bill_due_date: Vue.moment().format("YYYY-MM-DD"),
            bill_shipaddress: "",
            bill_status: 1,
            bill_quotation_number: "",
            bill_order_number: "",
            bill_invoice_number: "",
            bill_coa_id: "",
            bill_journal_memo: "",
            bill_sub_total: 0,
            bill_tax_total: 0,
            bill_grand_total: 0,
            bill_paid_total: 0,
            bill_transaction: [
                {
                    transaction_format: 0,
                    transaction_tax_id: "",
                    transaction_tax_rate: 0,
                    transaction_coa_id: "",
                    transaction_description: "",
                    transaction_amount: 0,
                    transaction_qty: 0,
                    transaction_price: 0,
                    transaction_done_qty: 0,
                    item_id: 0,
                    uom_id: 0,
                    ref_order_tr_id: 0
                }
            ],
            bill_transaction_delete: [],
            bill_transaction_from_order: [],
            bill_reference_id: 0,
            bill_reference_list: [],
            reference_invoice: [],
            reference_order: {},
            journal: {},
            contact: {},
            created_by: "",
            created_at: ""
        };
    },
    CLEAR_DATA(state) {
        state.purchases = [];
    }
};

const actions = {
    getQuotationNo({ dispatch, commit, state }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .get(`/purchase/get-quotation-no/` + state.purchase.bill_date)
                .then(response => {
                    state.purchase.bill_quotation_number = response.data;
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    console.log(error);
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                });
        });
    },
    getOrderNo({ dispatch, commit, state }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .get(`/purchase/get-order-no/` + state.purchase.bill_date)
                .then(response => {
                    state.purchase.bill_order_number = response.data;
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    console.log(error);
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                });
        });
    },
    getInvoiceNo({ dispatch, commit, state }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .get(`/purchase/get-invoice-no/` + state.purchase.bill_date)
                .then(response => {
                    state.purchase.bill_invoice_number = response.data;
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    console.log(error);
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                });
        });
    },
    getPurchase({ dispatch, commit, state }, payload) {
        let date_start = payload.date_start;
        let date_end = payload.date_end;
        let type = payload.type;
        let rows = payload.rows;
        return new Promise((resolve, reject) => {
            $axios
                .get(
                    `/purchase?rows=${rows}&type=${type}&date_start=${date_start}&date_end=${date_end}`
                )
                .then(response => {
                    if (response.data.bill_status > 2) {
                        state.bill_journal_memo =
                            response.data.journal.journal_description;
                    }
                    commit("ASSIGN_DATA", response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                    reject(error);
                });
        });
    },
    getInvoiceContact({ dispatch, commit, state }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .get(`/purchase/get-invoice-contact/${payload}`)
                .then(response => {
                    resolve(response.data);
                });
        });
    },
    getOrderContact({ dispatch, commit, state }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .get(`/purchase/get-order-contact/${payload}`)
                .then(response => {
                    resolve(response.data);
                });
        });
    },
    reviewSubmitPurchase({ dispatch, commit, state }) {
        return new Promise((resolve, reject) => {
            $axios
                .post(`/purchase/insert-review`, state.purchase)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                });
        });
    },
    reviewUpdatePurchase({ dispatch, commit, state }) {
        return new Promise((resolve, reject) => {
            $axios
                .post(`/purchase/update-review`, state.purchase)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                });
        });
    },
    submitQuotation({ dispatch, commit, state }) {
        return new Promise((resolve, reject) => {
            $axios
                .post(`/purchase/insert-quotation`, state.purchase)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                });
        });
    },
    submitOrder({ dispatch, commit, state }) {
        return new Promise((resolve, reject) => {
            $axios
                .post(`/purchase/insert-order`, state.purchase)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                });
        });
    },
    submitInvoice({ dispatch, commit, state }) {
        return new Promise((resolve, reject) => {
            $axios
                .post(`/purchase/insert-invoice`, state.purchase)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                });
        });
    },
    setOrder({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.get(`/purchase/${payload}/edit`).then(response => {
                resolve(response.data);
            });
        });
    },
    editPurchase({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .get(`/purchase/${payload}/edit`)
                .then(response => {
                    commit("ASSIGN_FORM", response.data.data);
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    updatePurchase({ state, commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .put(`/purchase/${payload}`, state.purchase)
                .then(response => {
                    //FORM DIBERSIHKAN
                    commit("CLEAR_FORM");
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                });
        });
    },
    printPurchase({ dispatch, commit, state }, payload) {
        return new Promise((resolve, reject) => {
            $axios({
                url: "/purchase/print/" + payload.bills_id,
                method: "GET",
                responseType: "blob" // important
            }).then(response => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute(
                    "download",
                    payload.type + " " + payload.bills_no + ".pdf"
                );
                document.body.appendChild(link);
                link.click();
                resolve(response.data);
            });
        });
    },
    removePurchase({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .delete(`/purchase/${payload}`)
                .then(response => {
                    commit("CLEAR_FORM");
                    resolve(response.data);
                })
                .catch(error => {
                    if (error.response) {
                        commit("SET_ERRORS", error.response.data, {
                            root: true
                        });
                    }
                    reject(error);
                });
        });
    },
    generateDataChartPurchases({ dispatch, commit, state }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .get(`/purchase/get-all-purchases`)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                    console.log(error);
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                });
        });
    },
    generatePurchaseReport({ dispatch, commit, state }, payload) {
        let date_start = payload.date_start;
        let date_end = payload.date_end;
        let supplier_id = payload.supplier_id;
        let bill_type = payload.bill_type;

        return new Promise((resolve, reject) => {
            $axios
                .get(
                    `/purchase/get-purchases-report?supp=${supplier_id}&type=${bill_type}&date_start=${date_start}&date_end=${date_end}`
                )
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                    console.log(error);
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                });
        });
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
