<!-- HTML SECTION -->
<template>
    <div class="page-container">
        <!-- Page Content -->
        <div class="hero-static d-flex align-items-center">
            <div class="w-100">
                <!-- Sign In Section -->
                <div class="bg-white">
                    <div class="content content-full">
                        <div class="row justify-content-center">
                            <div class="col-md-8 col-lg-6 col-xl-4 py-4">
                                <!-- Header -->
                                <div class="text-center">
                                    <p class="mb-3">
                                        <img
                                            :src="
                                                '/media/proxima/logotype@1x.png'
                                            "
                                            height="45px"
                                            style="
                                                margin-bottom: 5px;
                                                margin-right: 2px;
                                            "
                                        />
                                    </p>
                                    <h1 class="h4 mb-1">Sign In</h1>
                                    <h2 class="h6 font-w400 text-muted mb-3">
                                        Sign In to Your Proxima Account
                                    </h2>

                                    <div
                                        v-if="
                                            this.$route.params.afterRegister ==
                                                1
                                        "
                                        class="alert alert-info"
                                        role="alert"
                                    >
                                        <button
                                            type="button"
                                            class="close"
                                            data-dismiss="alert"
                                            aria-label="Close"
                                        >
                                            <span aria-hidden="true"
                                                >&times;</span
                                            >
                                        </button>
                                        <p class="mb-0">
                                            Registrasi berhasil, silahkan login
                                            menggunakan akun anda
                                        </p>
                                    </div>
                                </div>
                                <!-- END Header -->

                                <!-- Sign In Form -->
                                <!-- jQuery Validation (.js-validation-signin class is initialized in js/pages/op_auth_signin.min.js which was auto compiled from _es6/pages/op_auth_signin.js) -->
                                <!-- For more info and examples you can check out https://github.com/jzaefferer/jquery-validation -->
                                <form class="js-validation-signin">
                                    <div class="py-3">
                                        <div class="form-group has-feedback">
                                            <input
                                                type="text"
                                                class="form-control form-control-lg form-control-alt"
                                                placeholder="Username"
                                                v-model="data.username"
                                                style="border-color: #d5d5d5"
                                                @keydown.space.prevent
                                                :class="{
                                                    'is-invalid':
                                                        errors.username ||
                                                        errors.invalid
                                                }"
                                            />
                                            <span
                                                class="glyphicon glyphicon-envelope form-control-feedback"
                                            ></span>
                                            <p
                                                class="text-danger"
                                                v-if="errors.username"
                                            >
                                                {{ errors.username[0] }}
                                            </p>
                                        </div>
                                        <!-- Password -->
                                        <div
                                            class="form-group has-feedback noselect"
                                            v-if="!passwordHidden"
                                        >
                                            <input
                                                type="text"
                                                class="form-control form-control-lg form-control-alt d-inline"
                                                placeholder="Password"
                                                v-model="data.password"
                                                style="border-color: #d5d5d5; margin-right: -35px; padding-right: 35px"
                                                :class="{
                                                    'is-invalid':
                                                        errors.password ||
                                                        errors.invalid
                                                }"
                                                autocomplete="off"
                                            />
                                            <span
                                                class="display-eye fa fa-eye-slash text-pointer"
                                                @click="hidePassword"
                                            ></span>
                                            <p
                                                class="text-danger"
                                                v-if="errors.password"
                                            >
                                                {{ errors.password[0] }}
                                            </p>
                                        </div>
                                        <div
                                            class="form-group has-feedback noselect"
                                            v-if="passwordHidden"
                                        >
                                            <input
                                                type="password"
                                                class="form-control form-control-lg form-control-alt d-inline"
                                                placeholder="Password"
                                                v-model="data.password"
                                                style="border-color: #d5d5d5; margin-right: -35px; padding-right: 35px"
                                                :class="{
                                                    'is-invalid':
                                                        errors.password ||
                                                        errors.invalid
                                                }"
                                                autocomplete="off"
                                            />
                                            <span
                                                class="display-eye fa fa-eye text-pointer"
                                                @click="showPassword"
                                            ></span>
                                            <p
                                                class="text-danger"
                                                v-if="errors.password"
                                            >
                                                {{ errors.password[0] }}
                                            </p>
                                        </div>
                                        <p
                                            class="text-danger"
                                            v-if="errors.invalid"
                                        >
                                            {{ errors.invalid }}
                                        </p>
                                        <div class="form-group">
                                            <div
                                                class="d-md-flex align-items-md-center justify-content-md-between"
                                            >
                                                <div
                                                    class="custom-control custom-switch"
                                                >
                                                    <input
                                                        type="checkbox"
                                                        class="custom-control-input"
                                                        id="login-remember"
                                                        v-model="
                                                            data.remember_me
                                                        "
                                                        autocomplete="off"
                                                    />
                                                    <label
                                                        class="custom-control-label font-w400"
                                                        for="login-remember"
                                                        >Remember Me</label
                                                    >
                                                </div>
                                                <div class="py-2">
                                                    <!-- <a class="font-size-sm" href="op_auth_reminder2.html">Forgot Password?</a> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="form-group row justify-content-center mb-0"
                                    >
                                        <div class="col-12">
                                            <button
                                                type="submit"
                                                @click.prevent="postLogin"
                                                class="btn btn-block btn-sm btn-info"
                                                v-if="
                                                    !loadingPage ||
                                                        errors.password ||
                                                        errors.username ||
                                                        errors.invalid
                                                "
                                            >
                                                <i
                                                    class="fa fa-fw fa-sign-in-alt mr-1"
                                                ></i>
                                                Sign In
                                            </button>
                                            <div
                                                class="block-content block-content-full pb-0"
                                                v-else
                                            >
                                                <div
                                                    class="spinner-border spinner-border-lg text-info mx-auto"
                                                    role="status"
                                                    style="
                                                        display: flex;
                                                        justify-content: center;
                                                    "
                                                ></div>
                                            </div>
                                        </div>
                                        <div
                                            class="col-6 text-center footer-lane-right"
                                            style="border"
                                        >
                                            <span>
                                                Belum Punya Akun ?
                                                <br />
                                                <router-link
                                                    class="text-info font-size-sm"
                                                    :to="{
                                                        name: 'register'
                                                    }"
                                                    >Daftar disini
                                                </router-link>
                                            </span>
                                        </div>
                                        <div class="col-6 text-center">
                                            <span>
                                                Ingin Berlangganan ?
                                                <br />
                                                <a
                                                    class="text-info font-size-sm"
                                                    href="https://tripasysfo.com/proxima#price"
                                                    >Registrasi disini
                                                </a>
                                            </span>
                                        </div>
                                    </div>
                                </form>
                                <!-- END Sign In Form -->
                            </div>
                        </div>
                    </div>
                </div>
                <!-- END Sign In Section -->

                <!-- Footer -->
                <div class="font-size-sm text-center text-muted py-3">
                    <strong>Proxima - Aquarius</strong> &copy; Tripasysfo
                    Development
                </div>
                <!-- END Footer -->
            </div>
        </div>
        <!-- END Page Content -->
    </div>
</template>

<!-- JAVASCRIPT SECTION -->
<script>
import { mapActions, mapMutations, mapGetters, mapState } from "vuex";
export default {
    data() {
        return {
            loadingPage: 0,
            data: {
                username: "",
                password: "",
                remember_me: false
            },
            passwordHidden: true
        };
    },
    //SEBELUM COMPONENT DI-RENDER
    created() {
        //KITA MELAKUKAN PENGECEKAN JIKA SUDAH LOGIN DIMANA VALUE isAuth BERNILAI TRUE
        if (this.isAuth) {
            //MAKA DI-DIRECT KE ROUTE DENGAN NAME home
            this.$router.push({ name: "home" });
        }
    },
    computed: {
        ...mapGetters(["isAuth"]), //MENGAMBIL GETTERS isAuth DARI VUEX
        ...mapState(["errors"]),
        ...mapState(["auth"])
    },
    methods: {
        ...mapActions("auth", ["submit"]), //MENGISIASI FUNGSI submit() DARI VUEX AGAR DAPAT DIGUNAKAN PADA COMPONENT TERKAIT. submit() BERASAL DARI ACTION PADA FOLDER STORES/auth.js
        ...mapMutations(["CLEAR_ERRORS"]),
        // Show Hide Password
        hidePassword() {
            this.passwordHidden = true;
        },
        showPassword() {
            this.passwordHidden = false;
        },
        postLogin() {
            this.CLEAR_ERRORS();
            this.loadingPage = 1;
            this.submit(this.data)
                .then(() => {
                    if (this.isAuth) {
                        this.CLEAR_ERRORS();
                        if (this.auth.type == "General") {
                            this.$router.push({ name: "home" });
                        } else {
                            this.$router.push({ name: "home" });
                        }
                    }
                    this.loadingPage = 0;
                })
                .catch(error => {
                    this.loadingPage = 0;
                });
        }
    }
};
</script>