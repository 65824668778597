<template>
    <main>
        <div class="row px-2">
            <div class="col-sm-4 py-2 mb-1 bg-whitesmoke">
                <div class="row">
                    <div class="col-1 py-1 text-center font-weight-bold">
                        #
                    </div>
                    <div class="col-11 py-1 text-left font-weight-bold">
                        ITEM
                    </div>
                </div>
            </div>
            <div class="col-sm-2 py-2 mb-1 bg-whitesmoke">
                <div class="row">
                    <div
                        class="pr-0 py-1 text-left font-weight-bold"
                        v-bind:class="{
                            'col-6': bill.bill_status == 3,
                            'col-12': bill.bill_status != 3
                        }"
                    >
                        QTY
                    </div>
                    <div
                        class="col-6 pr-0 py-1 text-left font-weight-bold"
                        v-if="bill.bill_status == 3"
                    >
                        SOLD
                    </div>
                </div>
            </div>
            <div class="col-sm-6 py-2 mb-1 bg-whitesmoke">
                <div class="row">
                    <div class="col-4 pr-0 py-1 text-left font-weight-bold">
                        UNIT PRICE
                    </div>
                    <div class="col-3 pr-0 py-1 text-left font-weight-bold">
                        TAX RATE
                    </div>
                    <div class="col-4 pr-0 py-1 text-left font-weight-bold">
                        AMOUNT
                    </div>
                    <div
                        class="col-1 pr-0 py-1 text-left font-weight-bold"
                    ></div>
                </div>
            </div>
        </div>
        <div
            class="transaction-item"
            v-for="(transaction, index) in bill.bill_transaction"
            :key="index"
        >
            <!-- TRANSACTION FORM -->
            <div class="row">
                <div class="col-sm-4 pt-2 pb-0">
                    <div
                        class="row"
                        v-bind:class="{
                            'd-none': transaction.transaction_format == 2
                        }"
                    >
                        <div class="col-1 pr-0 py-1">
                            <p
                                class="text-center align-middle pt-2 mb-0 font-weight-bolder text-pointer"
                                v-if="
                                    transaction.ref_order_tr_id == 0 &&
                                    bill.bill_paid_total == 0 &&
                                        bill.bill_status != 4 &&
                                        bill.bill_status != 5
                                "
                            >
                                <i
                                    class="si si-arrow-down"
                                    v-if="transaction.transaction_format == 0"
                                    @click="changeTransactionFormat(index, 1)"
                                ></i>
                                <i
                                    class="si si-arrow-up"
                                    v-if="transaction.transaction_format == 1"
                                    @click="changeTransactionFormat(index, 0)"
                                ></i>
                            </p>
                        </div>
                        <!-- ITEM -->
                        <div class="col-8 pr-0 py-1">
                            <selectize
                                class="form-control mt-1"
                                :placeholder="'Item'"
                                :id="'transaction-item-' + index"
                                v-model="transaction.item_id"
                                :class="{
                                    'is-invalid-selectize':
                                        errors[
                                            'bill_transaction.' +
                                                index +
                                                '.item_id'
                                        ]
                                }"
                                @input="changeItem(index)"
                                :disabled="
                                    transaction.ref_order_tr_id != 0 ||
                                    bill.bill_paid_total != 0 ||
                                        bill.bill_status == 5 ||
                                        bill.bill_status == 4 ||
                                        bill.reference_invoice.length > 0
                                "
                            >
                                <!-- settings is optional -->
                                <option
                                    v-for="item in items"
                                    :value="item.id"
                                    :key="item.id"
                                >
                                    {{ item.item_name }}</option
                                >
                            </selectize>
                            <span
                                v-if="
                                    errors[
                                        'bill_transaction.' + index + '.item_id'
                                    ]
                                "
                                class="text-danger data-detail-error"
                            >
                                {{
                                    errors[
                                        "bill_transaction." + index + ".item_id"
                                    ][0]
                                }}
                            </span>
                        </div>
                        <!-- UOM -->
                        <div class="col-3 pr-0 py-1">
                            <selectize
                                class="form-control mt-1"
                                :placeholder="'Unit'"
                                :id="'transaction-uom-' + index"
                                v-model="transaction.uom_id"
                                :class="{
                                    'is-invalid-selectize':
                                        errors[
                                            'bill_transaction.' +
                                                index +
                                                '.uom_id'
                                        ]
                                }"
                                :disabled="
                                    transaction.ref_order_tr_id != 0 ||
                                    !transaction.uom_list ||
                                        transaction.uom_list.length == 0 ||
                                        bill.bill_paid_total != 0 ||
                                        bill.bill_status == 5 ||
                                        bill.bill_status == 4 ||
                                        bill.reference_invoice.length > 0
                                "
                                v-if="
                                    transaction.transaction_format == 0 ||
                                        transaction.transaction_format == 1
                                "
                            >
                                <!-- settings is optional -->
                                <option
                                    v-for="uom in transaction.uom_list"
                                    :value="uom.id"
                                    :key="uom.id"
                                >
                                    {{ uom.uom_name }}</option
                                >
                            </selectize>
                            <span
                                v-if="
                                    errors[
                                        'bill_transaction.' + index + '.uom_id'
                                    ]
                                "
                                class="text-danger data-detail-error"
                            >
                                {{
                                    errors[
                                        "bill_transaction." + index + ".uom_id"
                                    ][0]
                                }}
                            </span>
                        </div>
                    </div>
                    <!-- DESC & ACCOUNT -->
                    <div
                        class="row"
                        v-bind:class="{
                            'd-none': transaction.transaction_format == 0
                        }"
                    >
                        <div class="col-6 offset-1 pr-0 py-1">
                            <!-- DESC -->
                            <input
                                type="text"
                                class="form-control my-1"
                                :placeholder="'Description'"
                                :id="'transaction-desc-' + index"
                                v-model="transaction.transaction_description"
                                :class="{
                                    'is-invalid':
                                        errors[
                                            'bill_transaction.' +
                                                index +
                                                '.transaction_description'
                                        ]
                                }"
                                :disabled="
                                    transaction.ref_order_tr_id != 0 ||
                                    bill.bill_paid_total != 0 ||
                                        bill.bill_status == 5 ||
                                        bill.bill_status == 4 ||
                                        bill.reference_invoice.length > 0
                                "
                            />
                            <span
                                v-if="
                                    errors[
                                        'bill_transaction.' +
                                            index +
                                            '.transaction_description'
                                    ]
                                "
                                class="text-danger data-detail-error"
                            >
                                {{
                                    errors[
                                        "bill_transaction." +
                                            index +
                                            ".transaction_description"
                                    ][0]
                                }}
                            </span>
                        </div>
                        <div class="col-5 pr-0 py-1">
                            <!-- COA -->
                            <selectize
                                class="form-control mt-1"
                                :placeholder="'Account No.'"
                                :id="'transaction-coa-' + index"
                                v-model="transaction.transaction_coa_id"
                                :class="{
                                    'is-invalid-selectize':
                                        errors[
                                            'bill_transaction.' +
                                                index +
                                                '.transaction_coa_id'
                                        ]
                                }"
                                v-if="
                                    transaction.transaction_format == 1 ||
                                        transaction.transaction_format == 2
                                "
                                :disabled="
                                    transaction.ref_order_tr_id != 0 ||
                                    bill.bill_paid_total != 0 ||
                                        bill.bill_status == 5 ||
                                        bill.bill_status == 4 ||
                                        bill.reference_invoice.length > 0
                                "
                            >
                                <!-- settings is optional -->
                                <option
                                    v-for="account in transactionAccounts"
                                    :value="account.id"
                                    :key="account.id"
                                    >{{ account.account_no }}
                                    {{ account.account_name }}</option
                                >
                            </selectize>
                            <span
                                v-if="
                                    errors[
                                        'bill_transaction.' +
                                            index +
                                            '.transaction_coa_id'
                                    ]
                                "
                                class="text-danger data-detail-error"
                            >
                                {{
                                    errors[
                                        "bill_transaction." +
                                            index +
                                            ".transaction_coa_id"
                                    ][0]
                                }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-2 pt-2 pb-0">
                    <div class="row">
                        <div
                            class="pr-0 py-1"
                            v-bind:class="{
                                'col-6': bill.bill_status == 3,
                                'col-12': bill.bill_status != 3
                            }"
                        >
                            <!-- QTY -->
                            <numeric
                                class="form-control mt-1 mb-2"
                                placeholder="Qty"
                                v-model="transaction.transaction_qty"
                                :class="{
                                    'is-invalid':
                                        errors[
                                            'bill_transaction.' +
                                                index +
                                                '.transaction_qty'
                                        ]
                                }"
                                separator="."
                                :precision="2"
                                @change="calculateTransaction(index)"
                                :max="
                                    transaction.inHandStock != undefined &&
                                    transaction.transaction_format != 2
                                        ? transaction.inHandStock
                                        : 9007199254740991
                                "
                                :disabled="
                                    transaction.ref_order_tr_id != 0 ||
                                    bill.bill_paid_total != 0 ||
                                        bill.bill_status == 5 ||
                                        bill.bill_status == 4 ||
                                        bill.reference_invoice.length > 0
                                "
                            />
                            <div
                                v-if="transaction.transaction_format != 2"
                                class="text-muted-high data-detail-stock"
                            >
                                Stock : {{ transaction.inHandStock }}
                            </div>
                            <span
                                v-if="
                                    errors[
                                        'bill_transaction.' +
                                            index +
                                            '.transaction_qty'
                                    ]
                                "
                                class="text-danger data-detail-error"
                            >
                                {{
                                    errors[
                                        "bill_transaction." +
                                            index +
                                            ".transaction_qty"
                                    ][0]
                                }}
                            </span>
                        </div>
                        <div
                            class="col-6 pr-0 py-1"
                            v-if="bill.bill_status == 3"
                        >
                            <!-- STD QTY -->
                            <numeric
                                :id="'qty'.index"
                                class="form-control mt-1 mb-2"
                                placeholder="Sold"
                                v-model="transaction.transaction_qty"
                                spellcheck="false"
                                disabled
                                separator="."
                                :precision="2"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 pt-2 pb-0">
                    <div class="row">
                        <div class="col-4 pr-0 py-1">
                            <!-- UNIT PRICE -->
                            <money
                                :id="'price'.index"
                                class="form-control mt-1 mb-2 text-right"
                                placeholder="Unit Price"
                                v-model="transaction.transaction_price"
                                spellcheck="false"
                                :class="{
                                    'is-invalid':
                                        errors[
                                            'bill_transaction.' +
                                                index +
                                                '.transaction_price'
                                        ]
                                }"
                                @blur.native="calculateTransaction(index)"
                                :disabled="
                                    transaction.ref_order_tr_id != 0 ||
                                    bill.bill_paid_total != 0 ||
                                        bill.bill_status == 5 ||
                                        bill.bill_status == 4 ||
                                        bill.reference_invoice.length > 0
                                "
                            />
                            <span
                                v-if="
                                    errors[
                                        'bill_transaction.' +
                                            index +
                                            '.transaction_price'
                                    ]
                                "
                                class="text-danger data-detail-error"
                            >
                                {{
                                    errors[
                                        "bill_transaction." +
                                            index +
                                            ".transaction_price"
                                    ][0]
                                }}
                            </span>
                        </div>
                        <div class="col-3 pr-0 py-1">
                            <selectize
                                class="form-control mt-1"
                                placeholder="Tax"
                                v-model="transaction.transaction_tax_id"
                                :class="{
                                    'is-invalid-selectize':
                                        errors[
                                            'bill_transaction.' +
                                                index +
                                                '.transaction_tax_id'
                                        ]
                                }"
                                @input="
                                    getTaxDetail(
                                        index,
                                        transaction.transaction_tax_id
                                    )
                                "
                                :disabled="
                                    transaction.ref_order_tr_id != 0 ||
                                    bill.bill_paid_total != 0 ||
                                        bill.bill_status == 5 ||
                                        bill.bill_status == 4 ||
                                        bill.reference_invoice.length > 0
                                "
                            >
                                <!-- settings is optional -->
                                <option
                                    v-for="(tax, indeks) in taxs"
                                    :value="tax.id"
                                    :key="indeks"
                                >
                                    {{ tax.tax_code }}
                                    ({{ tax.tax_rate }}%)
                                </option>
                            </selectize>
                            <span
                                v-if="
                                    errors[
                                        'bill_transaction.' +
                                            index +
                                            '.transaction_tax_id'
                                    ]
                                "
                                class="text-danger data-detail-error"
                            >
                                {{
                                    errors[
                                        "bill_transaction." +
                                            index +
                                            ".transaction_tax_id"
                                    ][0]
                                }}
                            </span>
                        </div>
                        <div class="col-4 pr-0 py-1">
                            <!-- AMOUNT -->
                            <money
                                type="text"
                                class="form-control mt-1 mb-2 text-right"
                                placeholder="Amount"
                                v-model="transaction.transaction_amount"
                                :class="{
                                    'is-invalid':
                                        errors[
                                            'bill_transaction.' +
                                                index +
                                                '.transaction_amount'
                                        ]
                                }"
                                disabled
                                @blur.native="calculateTransaction(index)"
                            />
                            <span
                                v-if="
                                    errors[
                                        'bill_transaction.' +
                                            index +
                                            '.transaction_amount'
                                    ]
                                "
                                class="text-danger data-detail-error"
                            >
                                {{
                                    errors[
                                        "bill_transaction." +
                                            index +
                                            ".transaction_amount"
                                    ][0]
                                }}
                            </span>
                        </div>
                        <div class="col-1 px-2 pb-1 text-left pt-3">
                            <a
                                class="btn-icon-only btn-icon-danger mt-1"
                                v-if="
                                    (index > 0 ||
                                        bill.bill_transaction_from_order
                                            .length > 0) &&
                                        transaction.ref_order_tr_id == 0 &&
                                        bill.bill_paid_total == 0 &&
                                        bill.bill_status != 4 &&
                                        bill.bill_status != 5
                                "
                                @click="removeDetail(index)"
                            >
                                <i class="fa fa-trash"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- CHANGE TYPE -->
            <div
                class="row"
                v-if="
                    transaction.ref_order_tr_id == 0 &&
                    bill.bill_paid_total == 0 &&
                        bill.bill_status != 4 &&
                        bill.bill_status != 5
                "
            >
                <div
                    class="col-4 pr-0 py-0 text-info text-pointer pl-5 pb-1"
                    :class="{
                        'pt-3': errors['bill_transaction.' + index + '.item_id']
                    }"
                >
                    <p
                        class="mb-0 text-pointer"
                        v-if="
                            transaction.transaction_format == 0 ||
                                transaction.transaction_format == 1
                        "
                    >
                        <span @click="changeTransactionFormat(index, 2)">
                            <i class="fa fa-exchange-alt mr-1"></i>
                            Switch to Description
                        </span>
                    </p>
                    <p
                        class="mb-0 text-pointer"
                        v-if="transaction.transaction_format == 2"
                    >
                        <span @click="changeTransactionFormat(index, 0)">
                            <i class="fa fa-exchange-alt mr-1"></i>
                            Switch to Product & Services
                        </span>
                    </p>
                </div>
            </div>
        </div>
        <div class="row px-2">
            <div class="col-12 py-2 mt-2 mb-1 px-1">
                <hr class="mt-1" />
                <a
                    class="text-info text-pointer font-semibold"
                    @click="addDetail()"
                    v-if="
                        bill.bill_paid_total == 0 &&
                            bill.bill_status != 4 &&
                            bill.bill_status != 5
                    "
                >
                    <i class="fa fa-plus"></i>
                    Add additional transaction
                </a>
            </div>
        </div>
    </main>
</template>
<script>
import { mapActions, mapState, mapMutations } from "vuex";
import moment from "moment";

export default {
    name: "transaction-component",
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState("bill", { bill: state => state.bill }),
        ...mapState("coa", { coas: state => state.coas.accounts }),
        ...mapState("coa", { coalink: state => state.coalink }),
        ...mapState("item", { items: state => state.items }),
        ...mapState("tax", { taxs: state => state.taxs }),
        transactionAccounts: function() {
            if (this.coas) {
                return this.coas.filter(function(coa) {
                    return (
                        coa.account_type === "Income" ||
                        coa.account_type === "Other Income"
                    );
                });
            }
        }
    },
    methods: {
        //VUEX
        ...mapActions("item", ["getInHandStock"]),
        //METHOD
        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                // 46 is dot
                $event.preventDefault();
            }
        },
        changeItem(index) {
            var itemSelected = this.items.find(obj => {
                return obj.id == this.bill.bill_transaction[index].item_id;
            });
            //get stock in hand
            this.getInHandStock({
                item: this.bill.bill_transaction[index].item_id,
                warehouse: 1,
                date: this.bill.bill_date
            }).then(response => {
                this.bill.bill_transaction[index].inHandStock = response;
            });
            //get coa for inventory coa for mutation if tracked
            if (itemSelected.is_inventory == 1) {
                this.bill.bill_transaction[index].inventory_coa_id =
                    itemSelected.inventory_coa_id;
                this.bill.bill_transaction[index].cogs_coa_id =
                    itemSelected.expense_coa_id;
            } else {
                this.bill.bill_transaction[index].inventory_coa_id = 0;
                this.bill.bill_transaction[index].cogs_coa_id = 0;
            }
            //other mandatory field
            this.bill.bill_transaction[index].transaction_tax_id =
                itemSelected.sell_tax_id;
            this.bill.bill_transaction[index].transaction_price =
                itemSelected.sell_price;
            this.bill.bill_transaction[index].transaction_coa_id =
                itemSelected.income_coa_id;
            this.bill.bill_transaction[index].item_name =
                itemSelected.item_name;
            this.bill.bill_transaction[index].uom_list = itemSelected.uom_list;
            this.bill.bill_transaction[index].uom_id =
                itemSelected.uom_list[0].id;
            this.getTaxDetail(index, itemSelected.sell_tax_id);
        },
        changeTransactionFormat(index, val) {
            this.bill.bill_transaction[index].transaction_format = val;
            this.bill.bill_transaction[index].inHandStock
                ? delete this.bill.bill_transaction[index].inHandStock
                : "";
            // this.bill.bill_transaction[index].transaction_qty = 0;
            // if (val == 0) {
            //     this.bill.bill_transaction[index].transaction_desc = "";
            //     this.bill.bill_transaction[index].transaction_coa_id = 0;
            // }
            // if (val == 2) {
            //     this.bill.bill_transaction[index].item_id = 0;
            //     this.bill.bill_transaction[index].uom_id = 0;
            //     this.bill.bill_transaction[index].uom_list = [];
            //     this.bill.bill_transaction[index].cogs_coa_id = 0;
            //     this.bill.bill_transaction[index].inventory_coa_id = 0;
            // }
        },
        getTaxDetail(index, val) {
            var result = this.taxs.find(obj => {
                return obj.id === parseInt(val);
            });
            this.bill.bill_transaction[index].transaction_tax_rate =
                result.tax_rate;
            //RE CALCULATE BILLS
            this.calculateTransaction(index);
        },
        addDetail() {
            this.bill.bill_transaction.push({
                transaction_format: 0,
                transaction_tax_id: "",
                transaction_tax_rate: 0,
                transaction_coa_id: "",
                transaction_description: "",
                transaction_amount: 0,
                transaction_qty: 0,
                transaction_price: 0,
                item_id: 0,
                transaction_done_qty: 0,
                uom_id: 0,
                ref_order_tr_id: 0
            });
        },
        removeDetail(index) {
            if (this.bill.bill_transaction[index].hasOwnProperty("id")) {
                this.bill.bill_transaction_delete.push(
                    this.bill.bill_transaction[index].id
                );
            }
            this.bill.bill_transaction.splice(index, 1);
            this.calculate();
        },
        calculateTransaction(index) {
            if (this.bill.bill_transaction[index].transaction_format != 2) {
                this.bill.bill_transaction[index].transaction_qty =
                    this.bill.bill_transaction[index].inHandStock == 0
                        ? 0
                        : this.bill.bill_transaction[index].transaction_qty;
            }
            this.bill.bill_transaction[index].transaction_amount =
                this.bill.bill_transaction[index].transaction_qty *
                    this.bill.bill_transaction[index].transaction_price +
                (this.bill.bill_transaction[index].transaction_qty *
                    this.bill.bill_transaction[index].transaction_price *
                    this.bill.bill_transaction[index].transaction_tax_rate) /
                    100;
            this.calculate();
        },
        calculate() {
            var sub = 0;
            var tax = 0;

            if (this.bill.bill_status == 3) {
                this.bill.bill_transaction_from_order.forEach(transaction => {
                    sub +=
                        transaction.transaction_price *
                        transaction.transaction_inv_qty;
                    if (isNaN(transaction.transaction_tax_rate)) {
                    } else {
                        let taxTotal =
                            (transaction.transaction_price *
                                transaction.transaction_inv_qty *
                                transaction.transaction_tax_rate) /
                            100;
                        tax = tax + parseInt(taxTotal.toFixed(0));
                    }
                });
            }

            this.bill.bill_transaction.forEach(transaction => {
                sub +=
                    transaction.transaction_price * transaction.transaction_qty;
                if (isNaN(transaction.transaction_tax_rate)) {
                } else {
                    let taxTotal =
                        (transaction.transaction_price *
                            transaction.transaction_qty *
                            transaction.transaction_tax_rate) /
                        100;
                    tax = tax + parseInt(taxTotal.toFixed(0));
                }
            });

            this.bill.bill_sub_total = sub.toFixed(0);
            this.bill.bill_tax_total = tax.toFixed(0);
            this.bill.bill_grand_total = (sub + tax).toFixed(0);
        }
    }
};
</script>