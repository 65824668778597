import Vue from "vue";
// [#BASE003]
import router from "./router";
// END [#BASE003]

// [#BASE002]
import store from "./store.js";
// END [#BASE002]

import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import VueSwal from "vue-swal";
import CKEditor from "@ckeditor/ckeditor5-vue";
import VueCurrencyFilter from "vue-currency-filter";
import Selectize from "vue2-selectize";
import Paginate from "vuejs-paginate";
import Datepicker from "vue2-datepicker";
import money from "v-money";
import "vue2-datepicker/index.css";
import VModal from "vue-js-modal";
import VueHtmlToPaper from "vue-html-to-paper";
let basePath = window.location.origin;
import GridLoader from "vue-spinner/src/GridLoader.vue";
import VueSession from "vue-session";
import AnimatedNumber from "animated-number-vue";
import App from "./layouts/App.vue";
import HeroComponent from "./components/HeroComponent";
import ProgressBar from "vue-simple-progress";
import numeric from "vue-numeric";
var VueTruncate = require("vue-truncate-filter");

import "../../public/css/proxima.css";
import "../../public/css/aurora.css";
// import "../../public/css/themes/modern.min.css";

import SecureLS from "secure-ls";
var ls = new SecureLS({ isCompression: false, encryptionSecret: "d3v3L0P3r!" });

const options = {
    name: "Document",
    specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
    styles: [`${basePath}/css/proxima-print.min.css`]
};
Vue.use(VueHtmlToPaper, options);
Vue.use(VueHtmlToPaper);

Vue.use(Vuetify);
Vue.use(VueSwal);
Vue.use(VueSession);
Vue.use(require("vue-moment"));
Vue.use(VueTruncate);
Vue.use(ls);
Vue.use(CKEditor);
Vue.use(VModal);
Vue.use(VueCurrencyFilter, {
    symbol: "Rp",
    thousandsSeparator: ".",
    symbolPosition: "front",
    symbolSpacing: false
});
Vue.use(money, {
    decimal: ",",
    thousands: ".",
    prefix: "Rp",
    precision: 0
});

Vue.component("paginate", Paginate);
Vue.component("selectize", Selectize);
Vue.component("date-picker", Datepicker);
Vue.component("grid-loader", GridLoader);
Vue.component("animated-number", AnimatedNumber);
Vue.component("hero", HeroComponent);
Vue.component("progress-bar", ProgressBar);
Vue.component("numeric", numeric);

Vue.mixin({
    methods: {
        alert(text, tipe) {
            if (tipe === 1) {
                this.$swal({
                    title: "Succes",
                    text: text,
                    icon: "success"
                });
            } else if (tipe === 2) {
                this.$swal({
                    title: "Something Wrong",
                    text: text,
                    icon: "error"
                });
            } else if (tipe === 3) {
                this.$swal({
                    title: "Are you sure ?",
                    text: text,
                    icon: "warning",
                    buttons: ["Cancel", "Delete"],
                    dangerMode: true
                }).then(willDelete => {
                    if (willDelete) {
                        this.alert("Data has been deleted !", 1);
                    }
                });
            } else if (tipe === 4) {
                this.$swal({
                    title: "Are you sure ?",
                    text: text,
                    icon: "warning",
                    buttons: ["Cancel", "Update"],
                    dangerMode: false
                }).then(willDelete => {
                    if (willDelete) {
                        this.alert("Data has been deleted !", 1);
                    }
                });
            }
        }
    }
});

// window.axios = axios;

new Vue({
    router,
    ls,
    Selectize,
    store,
    el: "#app",
    vuetify: new Vuetify(),
    render: h => h(App)
});
