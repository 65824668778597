import $axios from "../../api.js";

const state = () => ({
    generalLedger: {
        date_start: "",
        date_end: "",
        data: {}
    },
    trialBalance: {
        date_start: "",
        date_end: "",
        data: {}
    },
    profitAndLoss: {
        date_start: "",
        date_end: "",
        data: {}
    },
    balanceSheet: {
        date_start: "",
        date_end: "",
        data: {}
    },
    itemSummary: {
        date_start: "",
        date_end: "",
        data: { trackedItem: [], untrackedItem: [] }
    },
    itemDetail: {
        date_start: "",
        date_end: "",
        data: {}
    },
    cashFlow: {
        date_start: "",
        date_end: "",
        data: {}
    }
});
const mutations = {
    ASSIGN_GL(state, payload) {
        state.generalLedger = {
            date_start: payload.date_start,
            date_end: payload.date_end,
            data: payload.data
        };
    },
    CLEAR_GL(state, payload) {
        state.generalLedger = {
            date_start: "",
            date_end: "",
            data: {}
        };
    },
    ASSIGN_BS(state, payload) {
        state.balanceSheet = {
            date_start: payload.date_start,
            date_end: payload.date_end,
            data: payload.data,
            level: payload.level,
            type: payload.type
        };
    },
    CLEAR_BS(state, payload) {
        state.balanceSheet = {
            date_start: "",
            date_end: "",
            data: {},
            level: 1,
            type: 1
        };
    },
    ASSIGN_TB(state, payload) {
        state.trialBalance = {
            date_start: payload.date_start,
            date_end: payload.date_end,
            data: payload.data
        };
    },
    CLEAR_TB(state, payload) {
        state.trialBalance = {
            date_start: "",
            date_end: "",
            data: {}
        };
    },
    ASSIGN_PL(state, payload) {
        state.profitAndLoss = {
            date_start: payload.date_start,
            date_end: payload.date_end,
            data: payload.data,
            level: payload.level,
            type: payload.type
        };
    },
    CLEAR_PL(state, payload) {
        state.profitAndLoss = {
            date_start: "",
            date_end: "",
            data: {},
            level: 1,
            type: 1
        };
    },
    ASSIGN_IS(state, payload) {
        state.itemSummary = {
            date_start: payload.date_start,
            date_end: payload.date_end,
            data: payload.data
        };
    },
    CLEAR_IS(state, payload) {
        state.itemSummary = {
            date_start: "",
            date_end: "",
            data: {}
        };
    },
    ASSIGN_ID(state, payload) {
        state.itemDetail = {
            date_start: payload.date_start,
            date_end: payload.date_end,
            data: payload.data,
            item: payload.item
        };
    },
    CLEAR_ID(state, payload) {
        state.itemDetail = {
            date_start: "",
            date_end: "",
            data: {},
            item: payload.item
        };
    },
    ASSIGN_CF(state, payload) {
        state.cashFlow = {
            date_start: payload.date_start,
            date_end: payload.date_end,
            data: payload.data,
            period: payload.period
        };
    },
    CLEAR_ID(state, payload) {
        state.cashFlow = {
            date_start: "",
            date_end: "",
            data: {},
            period: payload.period
        };
    }
};
const actions = {
    generateReportGL({ dispatch, commit, state }, payload) {
        let date_start = payload.date_start;
        let date_end = payload.date_end;
        let account_start = payload.account_start;
        let account_end = payload.account_end;

        return new Promise((resolve, reject) => {
            $axios
                .get(
                    `/general-ledger/display?dateStart=${date_start}&dateEnd=${date_end}&accountStart=${account_start}&accountEnd=${account_end}`
                )
                .then(response => {
                    commit("ASSIGN_GL", response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                    reject(error);
                });
        });
    },
    pdfReportGL({ dispatch, commit, state }, payload) {
        return new Promise((resolve, reject) => {
            $axios({
                url:
                    "/general-ledger/report2?account_start=" +
                    payload.account_start +
                    "&account_end=" +
                    payload.account_end +
                    "&date_start=" +
                    payload.date_start +
                    "&date_end=" +
                    payload.date_end,
                method: "GET",
                responseType: "blob" // important
            })
                .then(response => {
                    const url = window.URL.createObjectURL(
                        new Blob([response.data])
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "General Ledger.pdf");
                    document.body.appendChild(link);
                    link.click();
                    resolve(response.data);
                })
                .catch(error => {
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                    reject(error);
                });
        });
    },
    xlsReportGL({ dispatch, commit, state }, payload) {
        return new Promise((resolve, reject) => {
            $axios({
                url:
                    "/general-ledger/export?account_start=" +
                    payload.account_start +
                    "&account_end=" +
                    payload.account_end +
                    "&date_start=" +
                    payload.date_start +
                    "&date_end=" +
                    payload.date_end,
                method: "GET",
                responseType: "blob" // important
            }).then(response => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "General Ledger.xlsx");
                document.body.appendChild(link);
                link.click();
                resolve(response.data);
            });
        });
    },
    generateReportTB({ dispatch, commit, state }, payload) {
        let date_start = payload.date_start;
        let date_end = payload.date_end;
        let account_start = payload.account_start;
        let account_end = payload.account_end;

        return new Promise((resolve, reject) => {
            $axios
                .get(
                    `/trial-balance/display?dateStart=${date_start}&dateEnd=${date_end}&accountStart=${account_start}&accountEnd=${account_end}`
                )
                .then(response => {
                    commit("ASSIGN_TB", response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                    reject(error);
                });
        });
    },
    generateReportCumulativeTB({ dispatch, commit, state }, payload) {
        let date_start = payload.date_start;
        let date_end = payload.date_end;
        let account_start = payload.account_start;
        let account_end = payload.account_end;

        return new Promise((resolve, reject) => {
            $axios
                .get(
                    `/trial-balance-cumulative/display?dateStart=${date_start}&dateEnd=${date_end}&accountStart=${account_start}&accountEnd=${account_end}`
                )
                .then(response => {
                    commit("ASSIGN_TB", response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                    reject(error);
                });
        });
    },
    pdfReportTB({ dispatch, commit, state }, payload) {
        return new Promise((resolve, reject) => {
            $axios({
                url:
                    "/trial-balance/report?account_start=" +
                    payload.account_start +
                    "&account_end=" +
                    payload.account_end +
                    "&date_start=" +
                    payload.date_start +
                    "&date_end=" +
                    payload.date_end,
                method: "GET",
                responseType: "blob" // important
            }).then(response => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "Trial Balance.pdf");
                document.body.appendChild(link);
                link.click();
                resolve(response.data);
            });
        });
    },
    xlsReportTB({ dispatch, commit, state }, payload) {
        return new Promise((resolve, reject) => {
            $axios({
                url:
                    "/trial-balance/export?account_start=" +
                    payload.account_start +
                    "&account_end=" +
                    payload.account_end +
                    "&date_start=" +
                    payload.date_start +
                    "&date_end=" +
                    payload.date_end,
                method: "GET",
                responseType: "blob" // important
            }).then(response => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "Trial Balance.xlsx");
                document.body.appendChild(link);
                link.click();
                resolve(response.data);
            });
        });
    },
    generateReportBS({ dispatch, commit, state }, payload) {
        let date_start = payload.date_start;
        let date_end = payload.date_end;
        let account_level = payload.account_level;
        let type = payload.type;

        return new Promise((resolve, reject) => {
            $axios
                .get(
                    `/balance-sheet/display?dateStart=${date_start}&dateEnd=${date_end}&accountLevel=${account_level}&type=${type}`
                )
                .then(response => {
                    commit("ASSIGN_BS", response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                    reject(error);
                });
        });
    },
    pdfReportBS({ dispatch, commit, state }, payload) {
        return new Promise((resolve, reject) => {
            $axios({
                url:
                    "/balance-sheet/report?account_level=" +
                    payload.account_level +
                    "&date_start=" +
                    payload.date_start +
                    "&date_end=" +
                    payload.date_end +
                    "&type=" +
                    payload.type,
                method: "GET",
                responseType: "blob" // important
            }).then(response => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "Balance Sheet.pdf");
                document.body.appendChild(link);
                link.click();
                resolve(response.data);
            });
        });
    },
    xlsReportBS({ dispatch, commit, state }, payload) {
        return new Promise((resolve, reject) => {
            $axios({
                url:
                    "/balance-sheet/export?account_level=" +
                    payload.account_level +
                    "&account_end=" +
                    payload.account_end +
                    "&date_start=" +
                    payload.date_start +
                    "&date_end=" +
                    payload.date_end +
                    "&type=" +
                    payload.type,
                method: "GET",
                responseType: "blob" // important
            }).then(response => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "Balance Sheet.xlsx");
                document.body.appendChild(link);
                link.click();
                resolve(response.data);
            });
        });
    },
    generateReportPL({ dispatch, commit, state }, payload) {
        let date_start = payload.date_start;
        let date_end = payload.date_end;
        let account_level = payload.account_level;
        let type = payload.type;

        return new Promise((resolve, reject) => {
            $axios
                .get(
                    `/profit-loss/display?dateStart=${date_start}&dateEnd=${date_end}&accountLevel=${account_level}&type=${type}`
                )
                .then(response => {
                    commit("ASSIGN_PL", response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                    reject(error);
                });
        });
    },
    pdfReportPL({ dispatch, commit, state }, payload) {
        return new Promise((resolve, reject) => {
            $axios({
                url:
                    "/profit-loss/report?account_level=" +
                    payload.account_level +
                    "&date_start=" +
                    payload.date_start +
                    "&date_end=" +
                    payload.date_end +
                    "&type=" +
                    payload.type,
                method: "GET",
                responseType: "blob" // important
            }).then(response => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "Profit & Loss.pdf");
                document.body.appendChild(link);
                link.click();
                resolve(response.data);
            });
        });
    },
    xlsReportPL({ dispatch, commit, state }, payload) {
        return new Promise((resolve, reject) => {
            $axios({
                url:
                    "/profit-loss/export?account_level=" +
                    payload.account_level +
                    "&account_end=" +
                    payload.account_end +
                    "&date_start=" +
                    payload.date_start +
                    "&date_end=" +
                    payload.date_end +
                    "&type=" +
                    payload.type,
                method: "GET",
                responseType: "blob" // important
            }).then(response => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "Profit & Loss.xlsx");
                document.body.appendChild(link);
                link.click();
                resolve(response.data);
            });
        });
    },
    generateReportIS({ dispatch, commit, state }, payload) {
        let date_start = payload.date_start;
        let date_end = payload.date_end;
        let show_all = payload.show_all;
        return new Promise((resolve, reject) => {
            $axios
                .get(
                    `/item-summary/display?dateStart=${date_start}&dateEnd=${date_end}&showAll=${show_all}`
                )
                .then(response => {
                    commit("ASSIGN_IS", response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                    reject(error);
                });
        });
    },
    pdfReportIS({ dispatch, commit, state }, payload) {
        return new Promise((resolve, reject) => {
            $axios({
                url:
                    "/item-summary/report?dateStart=" +
                    payload.date_start +
                    "&dateEnd=" +
                    payload.date_end +
                    "&showAll=" +
                    payload.show_all,
                method: "GET",
                responseType: "blob" // important
            })
                .then(response => {
                    const url = window.URL.createObjectURL(
                        new Blob([response.data])
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "General Ledger.pdf");
                    document.body.appendChild(link);
                    link.click();
                    resolve(response.data);
                })
                .catch(error => {
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                    reject(error);
                });
        });
    },
    xlsReportIS({ dispatch, commit, state }, payload) {
        return new Promise((resolve, reject) => {
            $axios({
                url:
                    "/item-summary/export?date_start=" +
                    payload.date_start +
                    "&date_end=" +
                    payload.date_end +
                    "&type=" +
                    payload.type,
                method: "GET",
                responseType: "blob" // important
            }).then(response => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "Item Summary Report.xlsx");
                document.body.appendChild(link);
                link.click();
                resolve(response.data);
            });
        });
    },
    generateReportID({ dispatch, commit, state }, payload) {
        let date_start = payload.date_start;
        let date_end = payload.date_end;
        let item = payload.item;

        return new Promise((resolve, reject) => {
            $axios
                .get(
                    `/item-detail/display?dateStart=${date_start}&dateEnd=${date_end}&item=${item}`
                )
                .then(response => {
                    commit("ASSIGN_ID", response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                    reject(error);
                });
        });
    },
    pdfReportID({ dispatch, commit, state }, payload) {
        return new Promise((resolve, reject) => {
            $axios({
                url:
                    "/item-detail/report?dateStart=" +
                    payload.date_start +
                    "&dateEnd=" +
                    payload.date_end +
                    "&item=" +
                    payload.item,
                method: "GET",
                responseType: "blob" // important
            })
                .then(response => {
                    const url = window.URL.createObjectURL(
                        new Blob([response.data])
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "General Ledger.pdf");
                    document.body.appendChild(link);
                    link.click();
                    resolve(response.data);
                })
                .catch(error => {
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                    reject(error);
                });
        });
    },
    xlsReportID({ dispatch, commit, state }, payload) {
        return new Promise((resolve, reject) => {
            $axios({
                url:
                    "/item-detail/export?date_start=" +
                    payload.date_start +
                    "&date_end=" +
                    payload.date_end +
                    "&item=" +
                    payload.item,
                method: "GET",
                responseType: "blob" // important
            }).then(response => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "Item Detail Report.xlsx");
                document.body.appendChild(link);
                link.click();
                resolve(response.data);
            });
        });
    },
    generateReportCF({ dispatch, commit, state }, payload) {
        let date_start = payload.date_start;
        let date_end = payload.date_end;
        let period = payload.period;
        return new Promise((resolve, reject) => {
            $axios
                .get(
                    `/cash-flow/display?dateStart=${date_start}&dateEnd=${date_end}&period=${period}`
                )
                .then(response => {
                    commit("ASSIGN_CF", response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                    reject(error);
                });
        });
    },
    pdfReportCF({ dispatch, commit, state }, payload) {
        return new Promise((resolve, reject) => {
            $axios({
                url:
                    "/cash-flow/report?dateStart=" +
                    payload.date_start +
                    "&dateEnd=" +
                    payload.date_end +
                    "&item=" +
                    payload.item,
                method: "GET",
                responseType: "blob" // important
            })
                .then(response => {
                    const url = window.URL.createObjectURL(
                        new Blob([response.data])
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "General Ledger.pdf");
                    document.body.appendChild(link);
                    link.click();
                    resolve(response.data);
                })
                .catch(error => {
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                    reject(error);
                });
        });
    },
    xlsReportCF({ dispatch, commit, state }, payload) {
        return new Promise((resolve, reject) => {
            $axios({
                url:
                    "/cash-flow/export?date_start=" +
                    payload.date_start +
                    "&date_end=" +
                    payload.date_end +
                    "&period=" +
                    payload.period,
                method: "GET",
                responseType: "blob" // important
            }).then(response => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "Cash Flow Report.xlsx");
                document.body.appendChild(link);
                link.click();
                resolve(response.data);
            });
        });
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
