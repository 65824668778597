<template>
    <main>
        <hero
            title="Detail Bill"
            prevBreadcrumb="Bill Register"
            routeBreadcrumb="purchase-register"
            currentBreadcrumb="Detail Bill"
        ></hero>

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <!-- Invoice -->
            <div class="block block-rounded block-mode-loading-oneui mb-2">
                <div class="block-header">
                    <h3 class="block-title">
                        {{ purchase.bill_number }}
                    </h3>
                    <div class="block-options">
                        <router-link
                            v-if="purchase.bill_status == 3 || purchase.bill_status == 4"
                            :to="{
                                name: 'journal-transaction-voucher',
                                params: { id: purchase.journal.id }
                            }"
                            tag="button"
                            type="button"
                            class="btn-block-option text-warning"
                        >
                            <i class="si si-notebook mr-1"></i>
                            <span class="d-none d-md-inline-block">
                                Journal Voucher
                            </span>
                        </router-link>
                        <router-link
                            :to="{
                                name: 'purchase-edit',
                                params: { id: purchase.bills_id }
                            }"
                            tag="button"
                            type="button"
                            class="btn-block-option text-success"
                        >
                            <i class="si si-pencil mr-1"></i>
                            <span class="d-none d-md-inline-block">
                                Edit Data
                            </span>
                        </router-link>
                        <!-- Print Page functionality is initialized in Helpers.print() -->
                        <button
                            type="button"
                            class="btn-block-option text-info"
                            @click="print()"
                        >
                            <i class="si si-printer mr-1"></i>
                            <span class="d-none d-md-inline-block"
                                >Print Document</span
                            >
                        </button>
                    </div>
                </div>
            </div>
            <div class="block bg-transparent mb-2">
                <div class="block-content pt-0 bg-transparent text-right">
                    <input
                        class="form-check-input"
                        style="margin-top:3.5px"
                        type="checkbox"
                        v-model="useHeader"
                    />
                    <label class="form-check-label">Default Header</label>
                </div>
            </div>
            <!--Loading-->
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 1"
                style="height:250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>

            <!-- Basic -->
            <div
                class="block block-rounded block-mode-loading-oneui block-transparent"
                v-else
            >
                <div class="row">
                    <div class="col-8">
                        <div
                            class="content content-boxed bg-white block-rounded p-0"
                            id="printMe"
                        >
                            <div class="block-content">
                                <div
                                    class="p-4"
                                    style="min-height: 800px;zoom: 0.9;"
                                >
                                    <!-- HEADER PART -->
                                    <div class="row pt-0" v-if="useHeader">
                                        <div
                                            class="col-8 font-size-sm pb-0 pt-0"
                                        >
                                            <p
                                                class="h3 mb-2 mt-3 text-uppercase"
                                            >
                                                {{
                                                    auth.data.workspace
                                                        .workspace_company_name
                                                }}
                                            </p>
                                            <p class="mb-0">
                                                {{
                                                    auth.data.workspace
                                                        .workspace_company_address
                                                }}
                                            </p>
                                            <p class="mb-0">
                                                <span
                                                    v-if="
                                                        auth.data.workspace
                                                            .workspace_company_province !==
                                                            '' &&
                                                            auth.data.workspace
                                                                .workspace_company_province !==
                                                                null
                                                    "
                                                >
                                                    {{
                                                        auth.data.workspace
                                                            .workspace_company_province
                                                    }}
                                                </span>
                                                <span
                                                    v-if="
                                                        auth.data.workspace
                                                            .workspace_company_postal !==
                                                            '' &&
                                                            auth.data.workspace
                                                                .workspace_company_postal !==
                                                                null
                                                    "
                                                >
                                                    ,
                                                    {{
                                                        auth.data.workspace
                                                            .workspace_company_postal
                                                    }}
                                                </span>
                                            </p>
                                            <p class="mb-0">
                                                <span
                                                    v-if="
                                                        auth.data.workspace
                                                            .workspace_company_phone !==
                                                            '' &&
                                                            auth.data.workspace
                                                                .workspace_company_phone !==
                                                                null
                                                    "
                                                >
                                                    {{
                                                        auth.data.workspace
                                                            .workspace_company_phone
                                                    }}
                                                </span>
                                                <span
                                                    v-if="
                                                        auth.data.workspace
                                                            .workspace_company_email !==
                                                            '' &&
                                                            auth.data.workspace
                                                                .workspace_company_email !==
                                                                null
                                                    "
                                                >
                                                    {{
                                                        "| " +
                                                            auth.data.workspace
                                                                .workspace_company_email
                                                    }}
                                                </span>
                                            </p>
                                        </div>
                                        <div
                                            class="col-4 text-right font-size-sm pb-0 pt-0"
                                        >
                                            <img
                                                :src="
                                                    '/storage/workspace/' +
                                                        auth.data.workspace
                                                            .workspace_company_logo
                                                "
                                                alt=""
                                                style="
                                            min-heigh: 100px;
                                            max-height: 120px;
                                            max-width: 180px;
                                        "
                                                @error="errorLogoUrl"
                                            />
                                        </div>
                                    </div>
                                    <hr class="mb-3" v-if="useHeader" />
                                    <div class="row mt-3">
                                        <p
                                            class="h3 mx-auto text-uppercase text-center"
                                            v-if="purchase.bill_status == 1"
                                        >
                                            Quotation
                                        </p>
                                        <p
                                            class="h3 mx-auto text-uppercase text-center"
                                            v-if="purchase.bill_status == 2"
                                        >
                                            Order
                                        </p>
                                        <p
                                            class="h3 mx-auto text-uppercase text-center"
                                            v-if="purchase.bill_status > 2"
                                        >
                                            Invoice
                                        </p>
                                    </div>
                                    <div class="row mb-5">
                                        <div
                                            class="col-6 text-left font-size-sm font-print-lg"
                                        >
                                            <p class="mb-1">Deliver To :</p>
                                            <p
                                                class="font-w700 mb-0 text-uppercase"
                                            >
                                                {{ purchase.contact.contact_name }}
                                            </p>
                                            <p class="mb-0">
                                                {{ purchase.bill_shipaddress }}
                                            </p>
                                            <p class="mb-0">
                                                <span
                                                    v-if="
                                                        purchase.contact
                                                            .contact_contactphone !==
                                                            '' &&
                                                            purchase.contact
                                                                .contact_contactphone !==
                                                                null
                                                    "
                                                >
                                                    {{
                                                        purchase.contact
                                                            .contact_contactphone ? purchase.contact
                                                            .contact_contactphone : ''
                                                    }}
                                                </span>
                                            </p>
                                            <p class="mb-0">
                                                <span>
                                                    {{
                                                        purchase.contact
                                                            .contact_email ? purchase.contact
                                                            .contact_email :'' 
                                                    }}
                                                </span>
                                            </p>
                                        </div>
                                        <div
                                            class="col-6 font-size-sm text-right font-print-lg"
                                        >
                                            <p
                                                class="mb-0 font-w700 mt-3"
                                                v-if="purchase.bill_status == 1"
                                            >
                                                No. Quotation :
                                                {{ purchase.bill_quotation_number }}
                                            </p>
                                            <p
                                                class="mb-0 font-w700 mt-3"
                                                v-if="purchase.bill_status == 2"
                                            >
                                                No. Order :
                                                {{ purchase.bill_order_number }}
                                            </p>
                                            <p
                                                class="mb-0 font-w700 mt-3"
                                                v-if="purchase.bill_status > 2"
                                            >
                                                No. Invoice :
                                                {{ purchase.bill_invoice_number }}
                                            </p>
                                            <p class="mb-0">
                                                Date :
                                                {{
                                                    purchase.bill_date
                                                        | moment("DD MMMM YYYY")
                                                }}
                                            </p>
                                            <p class="mb-0">
                                                <span
                                                    v-if="
                                                        purchase.bill_due_date !=
                                                            null
                                                    "
                                                >
                                                    Due Date :
                                                    {{
                                                        purchase.bill_due_date
                                                            | moment(
                                                                "DD MMMM YYYY"
                                                            )
                                                    }}
                                                </span>
                                            </p>
                                        </div>
                                    </div>

                                    <!-- Table -->
                                    <div class="table-responsive push mt-5">
                                        <table class="table table-bordered">
                                            <thead class="bg-body-light">
                                                <tr>
                                                    <th
                                                        class="text-center"
                                                        style="width: 60px"
                                                    >
                                                        No.
                                                    </th>
                                                    <th class="text-center">
                                                        Description
                                                    </th>
                                                    <th
                                                        class="text-center"
                                                        style="width: 20%"
                                                    >
                                                        Amount
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody class="font-print-md">
                                                <tr
                                                    v-for="(transaction,
                                                    index) in purchase.bill_transaction"
                                                    :key="index"
                                                >
                                                    <td class="text-center">
                                                        {{ ++index }}
                                                    </td>
                                                    <td>
                                                        <p
                                                            class="font-w600 mb-1 font-size-print"
                                                        >
                                                            <span
                                                                v-if="
                                                                    transaction.transaction_format ==
                                                                        0 ||
                                                                        transaction.transaction_format ==
                                                                            1
                                                                "
                                                            >
                                                                {{
                                                                    transaction.item.item_name
                                                                        ? transaction.item.item_name
                                                                        : ""
                                                                }}
                                                            </span>
                                                            <span
                                                                v-if="
                                                                    transaction.transaction_format ==
                                                                        2
                                                                "
                                                            >
                                                                {{
                                                                    transaction.transaction_description !=
                                                                    null
                                                                        ? transaction.transaction_description
                                                                        : ""
                                                                }}
                                                            </span>
                                                            <span
                                                                class="text-muted-high"
                                                            >
                                                                [
                                                                {{
                                                                    transaction.transaction_price
                                                                        | currency
                                                                }}
                                                                x
                                                                {{
                                                                    transaction.transaction_qty
                                                                }}
                                                                ]
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td class="text-right">
                                                        {{
                                                            transaction.transaction_amount
                                                                | currency
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        colspan="2"
                                                        class="font-w600 text-right"
                                                    >
                                                        Sub Total
                                                    </td>
                                                    <td class="text-right">
                                                        {{
                                                            purchase.bill_sub_total
                                                                | currency
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        colspan="2"
                                                        class="font-w600 text-right"
                                                    >
                                                        Tax Total
                                                    </td>
                                                    <td class="text-right">
                                                        {{
                                                            purchase.bill_tax_total
                                                                | currency
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        colspan="2"
                                                        class="font-w700 text-right bg-body-light"
                                                    >
                                                        TOTAL
                                                    </td>
                                                    <td
                                                        class="font-w700 text-right bg-body-light"
                                                    >
                                                        {{
                                                            purchase.bill_grand_total
                                                                | currency
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        colspan="2"
                                                        class="font-w600 text-right"
                                                    >
                                                        Paid Amount
                                                    </td>
                                                    <td class="text-right">
                                                        {{
                                                            purchase.bill_paid_total
                                                                | currency
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        colspan="2"
                                                        class="font-w600 text-right"
                                                    >
                                                        Amount Due
                                                    </td>
                                                    <td
                                                        class="font-w600 text-right"
                                                    >
                                                        {{
                                                            (purchase.bill_grand_total -
                                                                purchase.bill_paid_total)
                                                                | currency
                                                        }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- END Table -->

                                    <div class="row mt-4 mb-5">
                                        <div
                                            class="col-6 text-left font-size-sm pt-5 mt-2"
                                        >
                                            <div
                                                class="form-group d-print-none"
                                                v-if="purchase.bill_status > 2"
                                            >
                                                <label for="example-text-input"
                                                    >Choose Bank Data *</label
                                                >
                                                <selectize
                                                    class="form-control"
                                                    placeholder="Choose Bank Name"
                                                    v-model="selectedBank"
                                                    @input="
                                                        selectBank(selectedBank)
                                                    "
                                                >
                                                    <option
                                                        v-for="bank in banks.data"
                                                        :value="bank.id"
                                                        :key="bank.id"
                                                    >
                                                        {{ bank.bank_name }}
                                                    </option>
                                                </selectize>
                                            </div>
                                            <p
                                                class="mb-1"
                                                v-if="purchase.bill_status > 2"
                                                v-bind:class="{
                                                    'd-print-none':
                                                        selectedBank == ''
                                                }"
                                            >
                                                Payment Info :
                                            </p>
                                            <p
                                                class="font-w700 mb-0 text-uppercase"
                                                v-if="purchase.bill_status > 2"
                                                v-bind:class="{
                                                    'd-print-none':
                                                        selectedBank == ''
                                                }"
                                            >
                                                {{ docBank.bank_name }} ({{
                                                    docBank.bank_cabang
                                                }})
                                            </p>
                                            <p
                                                class="mb-0"
                                                v-if="purchase.bill_status > 2"
                                                v-bind:class="{
                                                    'd-print-none':
                                                        selectedBank == ''
                                                }"
                                            >
                                                {{ docBank.bank_rekening }}
                                            </p>
                                            <p
                                                class="mb-0"
                                                v-if="purchase.bill_status > 2"
                                                v-bind:class="{
                                                    'd-print-none':
                                                        selectedBank == ''
                                                }"
                                            >
                                                an.
                                                {{ docBank.bank_rekening_name }}
                                            </p>

                                            <div
                                                class="form-group d-print-none mt-5"
                                            >
                                                <label for="example-text-input"
                                                    >Post Note *</label
                                                >
                                                <ckeditor
                                                    tag-name="textarea"
                                                    :editor="editor"
                                                    v-model="docPostNote"
                                                    :config="editorConfig"
                                                ></ckeditor>
                                            </div>
                                            <span
                                                class="d-none d-print-inline-block"
                                                v-html="docPostNote"
                                            ></span>
                                        </div>
                                        <div
                                            class="col-6 font-size-sm text-right pt-5 mt-2"
                                        >
                                            <div
                                                class="form-group text-left d-print-none"
                                            >
                                                <label for="example-text-input"
                                                    >Choose Signature *</label
                                                >
                                                <selectize
                                                    class="form-control"
                                                    placeholder="Choose Signature"
                                                    v-model="selectedSignature"
                                                    @input="
                                                        selectSignature(
                                                            selectedSignature
                                                        )
                                                    "
                                                >
                                                    <option
                                                        v-for="signature in signatures.data"
                                                        :value="signature.id"
                                                        :key="signature.id"
                                                    >
                                                        {{
                                                            signature.signature_name
                                                        }}
                                                    </option>
                                                </selectize>
                                            </div>
                                            <p
                                                class="mb-0 font-w700 mt-6 ml-9 text-center"
                                                style="border-bottom: 1px solid grey"
                                                v-bind:class="{
                                                    'd-print-none':
                                                        selectedSignature == ''
                                                }"
                                            >
                                                {{
                                                    docSignature.signature_name
                                                }}
                                            </p>
                                            <p
                                                class="mb-0 font-w700 ml-9 text-center"
                                                v-bind:class="{
                                                    'd-print-none':
                                                        selectedSignature == ''
                                                }"
                                            >
                                                {{
                                                    docSignature.signature_position
                                                }}
                                            </p>
                                        </div>
                                    </div>

                                    <hr />
                                    <p
                                        class="d-none d-print-inline-block"
                                        style="opacity: 0.6"
                                    >
                                        <i> Printed : {{ dateNow }} </i>
                                    </p>
                                    <!-- END Footer -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="content content-boxed p-0">
                            <div
                                class="block-content bg-white block-rounded px-3 py-3 mb-3"
                            >
                                <p class="font-w700 mb-3">General</p>
                                <p class="text-muted-high mb-0">Status</p>
                                <p class="mb-2" v-if="purchase.bill_status == 1">
                                    Quotation
                                </p>
                                <p class="mb-2" v-if="purchase.bill_status == 2">
                                    Order
                                </p>
                                <p class="mb-2" v-if="purchase.bill_status == 3">
                                    Open Invoice
                                </p>
                                <p class="mb-2" v-if="purchase.bill_status == 4">
                                    Close Invoice
                                </p>
                                <p class="text-muted-high mb-0">Issued To</p>
                                <p class="mb-2">
                                    {{ purchase.contact.contact_name }}
                                </p>
                                <div class="row">
                                    <div class="col-6 py-0">
                                        <p class="text-muted-high mb-0">
                                            On
                                        </p>
                                        <p class="mb-2">
                                            {{ purchase.bill_date | moment("DD/MM/YYYY")}}
                                        </p>
                                    </div>
                                    <div class="col-6 py-0" v-if="purchase.bill_due_date != null">
                                        <p class="text-muted-high mb-0">
                                            Due On
                                        </p>
                                        <p class="mb-2">
                                            {{ purchase.bill_due_date | moment("DD/MM/YYYY")}}
                                        </p>
                                    </div>
                                </div>
                                <p class="text-muted-high mb-0">Amount</p>
                                <p class="font-w700 mb-2">
                                    {{ purchase.bill_grand_total | currency }}
                                </p>
                            </div>
                            <!-- BILLED INFO FOR ORDER BILL -->
                            <div
                                class="block-content bg-white block-rounded px-3 py-3 mb-3"
                                v-if="purchase.bill_status == 2 || purchase.bill_status == 5"
                            >
                                <p class="font-w700 mb-0">Invoiced Info</p>
                                <p class="text-muted-high mb-02">
                                    Track the billing process for this Order. Billed amount is associated to any purchase data associated to this order
                                </p>
                                <div class="row">
                                    <div class="col-6 py-0">
                                        <p class="text-muted-high mb-0">
                                            Billed Amount
                                        </p>
                                        <p class="mb-2">
                                            {{ purchase.bill_paid_total | currency }}
                                        </p>
                                    </div>
                                    <div class="col-6 py-0">
                                        <p class="text-muted-high mb-0">
                                            Remaining Amount
                                        </p>
                                        <p class="mb-2">
                                            {{ purchase.bill_grand_total - purchase.bill_paid_total | currency }}
                                        </p>
                                    </div>
                                    <div class="col-12">
                                        <progress-bar
                                            bar-color="#70b9eb"
                                            :val="Math.round(purchase.bill_paid_total/purchase.bill_grand_total * 100)"
                                            :text="Math.round(purchase.bill_paid_total/purchase.bill_grand_total * 100) + '%'"
                                            :title="Math.round(purchase.bill_paid_total/purchase.bill_grand_total * 100) + '%'"
                                            size="medium"
                                        ></progress-bar>
                                    </div>
                                </div>
                            </div>
                            <!-- BILLED HISTORY FOR ORER BILL -->
                            <div
                                class="block-content bg-white block-rounded px-3 py-3 mb-3"
                                v-if="purchase.bill_status == 2 || purchase.bill_status == 5"
                            >
                                <p class="font-w700 mb-4">Invoiced History</p>
                                <div
                                    v-for="(invoice,
                                    index) in purchase.reference_invoice"
                                    :key="index"
                                    class="mb-2"
                                >
                                    <p class="mb-0">
                                        {{
                                            invoice.bill_date
                                                | moment("DD MMMM YYYY")
                                        }}
                                    </p>
                                    <a
                                        class="text-pointer text-info mb-2"
                                        @click="
                                            toBillInvoiceDetail(
                                                invoice.id
                                            )
                                        "
                                    >
                                        {{ invoice.bill_number }}
                                    </a>
                                </div>
                                <span v-if="purchase.reference_invoice.length == 0">
                                    <p class="text-center text-muted-high">
                                        No payment data yet
                                    </p>
                                </span>
                            </div>


                            <!-- PAYMENT INFO FOR INVOICE BILL -->
                            <div
                                class="block-content bg-white block-rounded px-3 py-3 mb-3"
                                v-if="purchase.bill_status == 3 || purchase.bill_status == 4"
                            >
                                <p class="font-w700 mb-0">Payment Info</p>
                                <p class="text-muted-high mb-02">
                                    Track the payment process of this Invoice
                                </p>
                                <div class="row">
                                    <div class="col-6 py-0">
                                        <p class="text-muted-high mb-0">
                                            Received Amount
                                        </p>
                                        <p class="mb-2">
                                            {{ purchase.bill_paid_total | currency }}
                                        </p>
                                    </div>
                                    <div class="col-6 py-0">
                                        <p class="text-muted-high mb-0">
                                            Remaining Amount
                                        </p>
                                        <p class="mb-2">
                                            {{ purchase.bill_grand_total - purchase.bill_paid_total | currency }}
                                        </p>
                                    </div>
                                    <div class="col-12">
                                        <progress-bar
                                            bar-color="#70b9eb"
                                            :val="Math.round(purchase.bill_paid_total/purchase.bill_grand_total * 100)"
                                            :text="Math.round(purchase.bill_paid_total/purchase.bill_grand_total * 100) + '%'"
                                            :title="Math.round(purchase.bill_paid_total/purchase.bill_grand_total * 100) + '%'"
                                            size="medium"
                                        ></progress-bar>
                                    </div>
                                </div>
                            </div>
                            <!-- PAYMENT HISTORY FOR INVOICE BILL -->
                            <div
                                class="block-content bg-white block-rounded px-3 py-3 mb-3"
                                v-if="purchase.bill_status == 3 || purchase.bill_status == 4"
                            >
                                <p class="font-w700 mb-4">Payment History</p>
                                <div
                                    v-for="(payment,
                                    index) in purchase.payment_detail"
                                    :key="index"
                                    class="mb-2"
                                >
                                    <p class="mb-0">
                                        {{
                                            payment.payment.payment_date
                                                | moment("DD MMMM YYYY")
                                        }}
                                    </p>
                                    <a
                                        class="text-pointer text-info mb-2"
                                        @click="
                                            toReceivePaymentDetail(
                                                payment.payment_id
                                            )
                                        "
                                    >
                                        {{ payment.payment.payment_no }}
                                    </a>
                                </div>
                                <span v-if="purchase.payment_detail.length == 0">
                                    <p class="text-center text-muted-high">
                                        No payment data yet
                                    </p>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- END Invoice -->
            </div>
            <!-- END Basic -->
        </div>
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import moment from "moment";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
    name: "edit-bills",
    data() {
        return {
            loadingPage: 1,
            type: "Quotation",
            items: [],
            disabled: {},
            coaBills: [],
            useHeader: true,
            forbiddenData: false,
            dateNow: moment().format("MMMM Do YYYY, HH:mm:ss"),
            selectedBank: "",
            docBank: {
                bank_name: "[select bank]",
                bank_cabang: "[select bank]",
                bank_rekening: "[select bank]",
                bank_rekening_name: "[select bank]"
            },
            selectedSignature: "",
            docSignature: {
                signature_name: "[select signature]",
                signature_position: "[select signature]"
            },
            editor: ClassicEditor,
            editorConfig: {
                toolbar: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "bulletedList",
                    "numberedList",
                    "blockQuote",
                    "undo",
                    "redo",
                    "insertTable"
                ]
            },
            docPostNote: ""
        };
    },
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState(["auth"]),
        ...mapState("purchase", { purchase: state => state.purchase }),
        ...mapState("bank", { banks: state => state.banks }),
        ...mapState("signature", { signatures: state => state.signatures })
    },
    created() {
        if (this.$route.params.id) {
            this.editPurchase(this.$route.params.id)
                .then(response => {
                    this.$session.set("billregister_detail", response.data.id);
                    this.getSignatureAndBank();
                    this.loadingPage = 0;
                })
                .catch(error => {
                    console.log(error);
                    this.loadingPage = 0;
                    if (error.response.status == 500) {
                        this.$router.push({
                            name: "purchase-register",
                            params: { reloadData: true }
                        });
                        this.alert(error.response.data.message, 2);
                    }
                });
        } else if (this.$session.has("billregister_detail")) {
            this.editPurchase(this.$session.get("billregister_detail"))
                .then(response => {
                    this.getSignatureAndBank();
                    this.loadingPage = 0;
                })
                .catch(error => {
                    console.log(error);
                    this.loadingPage = 0;
                    if (error.response.status == 500) {
                        this.$router.push({
                            name: "purchase-register",
                            params: { reloadData: true }
                        });
                        this.alert(error.response.data.message, 2);
                    }
                });
        } else {
            this.$router.push({ name: "purchase-register" });
            this.alert("Data not found, select data first", 2);
        }
    },
    methods: {
        //VUEX
        ...mapMutations("purchase", ["CLEAR_FORM"]),
        ...mapMutations(["CLEAR_ERRORS"]),
        ...mapActions("purchase", [
            "editPurchase",
            "getQuotationNo",
            "getOrderNo",
            "getInvoiceNo",
            "updateBill",
            "removeBill",
            "printBill"
        ]),
        ...mapActions("signature", ["getSignatureDocument"]),
        ...mapActions("bank", ["getBankDocument"]),
        getSignatureAndBank() {
            if (this.purchase.bill_status == 1) {
                this.getSignatureDocument({
                    document: "signature_bill_quotation"
                })
                    .then(response => {
                        this.loadingPage = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.loadingPage = false;
                    });
                this.getBankDocument({
                    document: "bank_bill_quotation"
                })
                    .then(response => {
                        this.loadingPage = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.loadingPage = false;
                    });
            } else if (this.purchase.bill_status == 2) {
                this.getSignatureDocument({
                    document: "signature_bill_order"
                })
                    .then(response => {
                        this.loadingPage = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.loadingPage = false;
                    });
                this.getBankDocument({ document: "bank_bill_order" })
                    .then(response => {
                        this.loadingPage = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.loadingPage = false;
                    });
            } else {
                this.getSignatureDocument({
                    document: "signature_bill_invoice"
                })
                    .then(response => {
                        this.loadingPage = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.loadingPage = false;
                    });
                this.getBankDocument({ document: "bank_bill_invoice" })
                    .then(response => {
                        this.loadingPage = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.loadingPage = false;
                    });
            }
        },
        selectBank(val) {
            if (val != "") {
                var result = this.banks.data.find(obj => {
                    return obj.id === parseInt(val);
                });
                this.docBank.bank_name = result.bank_name;
                this.docBank.bank_cabang = result.bank_cabang;
                this.docBank.bank_rekening = result.bank_rekening;
                this.docBank.bank_rekening_name = result.bank_rekening_name;
            } else {
                this.docBank.bank_name = "[select bank]";
                this.docBank.bank_cabang = "[select bank]";
                this.docBank.bank_rekening = "[select bank]";
                this.docBank.bank_rekening_name = "[select bank]";
            }
        },
        selectSignature(val) {
            if (val != "") {
                var result = this.signatures.data.find(obj => {
                    return obj.id === parseInt(val);
                });
                this.docSignature.signature_name = result.signature_name;
                this.docSignature.signature_position =
                    result.signature_position;
            } else {
                this.docSignature.signature_name = "[select signature]";
                this.docSignature.signature_position = "[select signature]";
            }
        },
        errorLogoUrl(event) {
            event.target.src = "/media/workspace/no-logo.jpg";
        },
        toReceivePaymentDetail(val) {
            this.$router.push({
                name: "receive-payment-detail",
                params: { id: val }
            });
        },
        toBillInvoiceDetail(val) {
            this.$router.push({
                name: "purchase-detail",
                params: { id: val }
            });
        },
        print() {
            var browserName = navigator.appName;
            if (browserName == "Safari") {
                var printContents = document.getElementById("printMe")
                    .innerHTML;
                var originalContents = document.body.innerHTML;
                document.body.innerHTML = printContents;
                window.print();
                document.body.innerHTML = originalContents;
            } else {
                this.$htmlToPaper("printMe");
            }
        }
    },
    destroyed() {
        this.CLEAR_FORM();
    }
};
</script>
