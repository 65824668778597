<template>
    <main>
        <hero title="Edit Receive Payment" prevBreadcrumb="Receive Payment" routeBreadcrumb="receive-payment" currentBreadcrumb="Edit Receive Payment"></hero>

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 1"
                style="height:250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 2"
                style="height:250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Save Data</span
                        >
                    </center>
                </div>
            </div>
            <!-- Basic -->
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 0 || loadingPage == 3"
            >
                <div class="block mb-0" v-if="forbiddenData">
                    <div
                        class="alert alert-info d-flex align-items-center mb-0"
                        role="alert"
                    >
                        <div class="flex-00-auto">
                            <i class="fa fa-fw fa-info-circle"></i>
                        </div>
                        <div class="flex-fill ml-3">
                            <p class="mb-0">
                                This receive payment data is
                                <b> included into closing date of year </b>, you
                                can't edit or delete this data
                            </p>
                        </div>
                    </div>
                </div>
                <div class="block-content block-content-full">
                    <form
                        method="POST"
                        enctype="multipart/form-data"
                        onsubmit="return false;"
                    >
                        <!--Form-->
                        <div class="row font-size-sm">
                            <div class="col-12 py-0">
                                <div class="row">
                                    <div class="col-12 col-lg-6 pt-0">
                                        <label style="opacity: 0.7"
                                            >Payment Information</label
                                        >
                                        <hr class="mt-2 mb-3" />
                                        <div class="form-group">
                                            <label for="example-text-input"
                                                >Deposit Payment To *</label
                                            >
                                            <selectize
                                                class="form-control"
                                                placeholder="Choose Account No."
                                                v-model="
                                                    receivepayment.payment_coa_id
                                                "
                                                :class="{
                                                    'is-invalid-selectize':
                                                        errors.payment_coa_id,
                                                }"
                                                disabled
                                            >
                                                <!-- settings is optional -->
                                                <option
                                                    v-for="account in coas"
                                                    :value="account.id"
                                                    :key="account.id"
                                                >
                                                    {{ account.account_no }}
                                                    {{ account.account_name }}
                                                </option>
                                            </selectize>
                                            <transition name="slide-fade">
                                                <p
                                                    class="text-danger"
                                                    v-if="
                                                        errors.payment_coa_id
                                                    "
                                                >
                                                    {{
                                                        errors
                                                            .payment_coa_id[0]
                                                    }}
                                                </p>
                                            </transition>
                                        </div>
                                        <div class="form-group">
                                            <label for="example-text-input"
                                                >Name Contact *</label
                                            >
                                            <selectize
                                                class="form-control"
                                                placeholder="Contact Name"
                                                v-model="
                                                    receivepayment.payment_contact_id
                                                "
                                                :class="{
                                                    'is-invalid-selectize':
                                                        errors.payment_contact_id,
                                                }"
                                                @input="
                                                    changeContact(
                                                        receivepayment.payment_contact_id
                                                    )
                                                "
                                                disabled
                                            >
                                                <!-- settings is optional -->
                                                <option
                                                    v-for="contact in contacts"
                                                    :value="contact.id"
                                                    :key="contact.id"
                                                >
                                                    {{ contact.contact_name }}
                                                </option>
                                            </selectize>
                                            <p
                                                v-if="errors.bill_contact_id"
                                                class="text-danger"
                                            >
                                                {{ errors.bill_contact_id[0] }}
                                            </p>
                                        </div>
                                        <div class="form-group">
                                            <label for="example-text-input"
                                                >Amount of Receive *</label
                                            >
                                            <money
                                                class="form-control"
                                                style="text-align: right"
                                                v-model="
                                                    receivepayment.payment_amount
                                                "
                                                :class="{
                                                    'is-invalid':
                                                        errors.payment_out_of_balance,
                                                }"
                                                spellcheck="false"
                                                disabled
                                            ></money>
                                            <transition name="slide-fade">
                                                <p
                                                    class="text-danger"
                                                    v-if="errors.payment_amount"
                                                >
                                                    {{
                                                        errors.payment_amount[0]
                                                    }}
                                                </p>
                                            </transition>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-6 pt-0">
                                        <label style="opacity: 0.7"
                                            >Payment Detail</label
                                        >
                                        <hr class="mt-2 mb-3" />
                                        <div class="form-group pb-2">
                                            <label for="example-text-input"
                                                >Payment Date *</label
                                            >
                                            <date-picker
                                                class="input-group"
                                                v-model="
                                                    receivepayment.payment_date
                                                "
                                                format="DD/MM/YYYY"
                                                valueType="YYYY-MM-DD"
                                                placeholder="Bill Date"
                                                :class="{
                                                    'is-invalid':
                                                        errors.payment_date,
                                                }"
                                                :default-value="new Date()"
                                                :disabled-date="
                                                    disabledDatePicker
                                                "
                                                @input="changeDate()"
                                                :disabled="forbiddenData"
                                            ></date-picker>
                                            <transition name="slide-fade">
                                                <p
                                                    class="text-danger"
                                                    v-if="errors.payment_date"
                                                >
                                                    {{ errors.payment_date[0] }}
                                                </p>
                                            </transition>
                                        </div>
                                        <div class="form-group pb-1">
                                            <label for="example-text-input"
                                                >Payment No. *</label
                                            >
                                            <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Contact Address"
                                                v-model="
                                                    receivepayment.payment_no
                                                "
                                                :class="{
                                                    'is-invalid':
                                                        errors.payment_no,
                                                }"
                                            />
                                            <transition name="slide-fade">
                                                <p
                                                    class="text-danger"
                                                    v-if="errors.payment_no"
                                                >
                                                    {{ errors.payment_no[0] }}
                                                </p>
                                            </transition>
                                        </div>
                                        <div class="form-group">
                                            <label for="example-text-input"
                                                >Journal Memo *</label
                                            >
                                            <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Bill Invoice Number"
                                                v-model="
                                                    receivepayment.payment_journal_memo
                                                "
                                                :class="{
                                                    'is-invalid':
                                                        errors.payment_journal_memo,
                                                }"
                                            />
                                            <transition name="slide-fade">
                                                <p
                                                    class="text-danger"
                                                    v-if="
                                                        errors.payment_journal_memo
                                                    "
                                                >
                                                    {{
                                                        errors
                                                            .payment_journal_memo[0]
                                                    }}
                                                </p>
                                            </transition>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div class="table-responsive">
                                        <table
                                            class="table table-vcenter table-border"
                                            style="border: 1px solid #eaecee"
                                        >
                                            <thead
                                                style="
                                                    background-color: whitesmoke;
                                                "
                                            >
                                                <tr>
                                                    <th
                                                        class="table-header-relative column-minwidth-150"
                                                    >
                                                        Invoice Number
                                                    </th>
                                                    <th
                                                        class="table-header-relative column-minwidth-150"
                                                    >
                                                        Invoice Date
                                                    </th>
                                                    <th
                                                        class="table-header-relative column-minwidth-150"
                                                        width="20%"
                                                    >
                                                        Amount
                                                    </th>
                                                    <th
                                                        class="table-header-relative column-minwidth-150"
                                                        width="20%"
                                                    >
                                                        Total Due
                                                    </th>
                                                    <th
                                                        class="table-header-relative column-minwidth-250"
                                                        width="20%"
                                                    >
                                                        Amount Applied
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="loadingPage == 3">
                                                <tr>
                                                    <td colspan="5">
                                                        <div
                                                            class="spinner-border spinner-border-lg text-info mx-auto mt-3"
                                                            role="status"
                                                            style="
                                                                display: flex;
                                                                justify-content: center;
                                                            "
                                                        ></div>
                                                        <center
                                                            class="mt-2 mb-4"
                                                        >
                                                            <span
                                                                class="text-center"
                                                                style="
                                                                    opacity: 0.7;
                                                                "
                                                                >Get Contact
                                                                Invoices
                                                                Data</span
                                                            >
                                                        </center>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody
                                                v-else-if="
                                                    receivepayment.payment_bills &&
                                                    receivepayment.payment_bills
                                                        .length > 0
                                                "
                                            >
                                                <tr
                                                    v-for="(
                                                        receivepayment, index
                                                    ) in receivepayment.payment_bills"
                                                    :key="index"
                                                >
                                                    <td
                                                        align="center"
                                                        style="
                                                            vertical-align: middle;
                                                        "
                                                    >
                                                        <span>{{
                                                            receivepayment.bill
                                                                .bill_invoice_number
                                                        }}</span>
                                                    </td>
                                                    <td
                                                        align="center"
                                                        style="
                                                            vertical-align: middle;
                                                        "
                                                    >
                                                        <span>{{
                                                            receivepayment.bill
                                                                .bill_date
                                                                | moment(
                                                                    "DD/MM/YYYY"
                                                                )
                                                        }}</span>
                                                    </td>
                                                    <td
                                                        align="right"
                                                        style="
                                                            vertical-align: middle;
                                                        "
                                                        class="pr-4"
                                                    >
                                                        <span>{{
                                                            receivepayment.bill
                                                                .bill_grand_total
                                                                | currency
                                                        }}</span>
                                                    </td>
                                                    <td
                                                        align="right"
                                                        style="
                                                            vertical-align: middle;
                                                        "
                                                        class="pr-4"
                                                    >
                                                        <span>{{
                                                            (receivepayment.bill
                                                                .bill_grand_total -
                                                                receivepayment
                                                                    .bill
                                                                    .bill_paid_total)
                                                                | currency
                                                        }}</span>
                                                    </td>
                                                    <td
                                                        align="right"
                                                        style="
                                                            vertical-align: middle;
                                                        "
                                                        class="pr-4"
                                                    >
                                                        <span>{{
                                                            receivepayment.payment_amount
                                                                | currency
                                                        }}</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody v-else>
                                                <tr>
                                                    <td colspan="5">
                                                        <center
                                                            style="
                                                                padding-top: 15px;
                                                                padding-bottom: 15px;
                                                                color: #cccccc;
                                                            "
                                                        >
                                                            Invoice Data is
                                                            Empty
                                                        </center>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <p
                                            class="text-muted font-size-sm d-inline d-sm-none"
                                            style="opacity: 0.5"
                                        >
                                            Swipe left here to see full column
                                            >>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--END Form-->
                        <!-- Submit -->
                        <div class="row items-push">
                            <div class="col-6 pt-0">
                                <template
                                    v-if="receivepayment.adjustment != null"
                                >
                                    <!-- <p>+ Adjust Payment</p> -->
                                    <label for="field-1" class="control-label">
                                        Adjust Payment :
                                    </label>
                                    <div class="row">
                                        <div
                                            class="col-12 pt-0"
                                            v-if="
                                                receivepayment.adjustment.detail
                                                    .length > 0
                                            "
                                        >
                                            <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Adjustment Journal Memo"
                                                v-model="
                                                    receivepayment.adjustment
                                                        .journal_description
                                                "
                                                :class="{
                                                    'is-invalid':
                                                        errors[
                                                            'adjustment.journal_description'
                                                        ],
                                                }"
                                            />
                                            <transition name="slide-fade">
                                                <p
                                                    class="text-danger"
                                                    v-if="
                                                        errors[
                                                            'adjustment.journal_description'
                                                        ]
                                                    "
                                                >
                                                    {{
                                                        errors[
                                                            "adjustment.journal_description"
                                                        ][0]
                                                    }}
                                                </p>
                                            </transition>
                                        </div>
                                        <div class="col-12 pt-0">
                                            <div
                                                class="row"
                                                v-for="(
                                                    adjustment, index
                                                ) in receivepayment.adjustment
                                                    .detail"
                                                :key="index"
                                            >
                                                <div
                                                    class="col-6 font-size-sm pt-0"
                                                    v-if="
                                                        adjustment.coa_id !==
                                                        receivepayment.payment_coa_id
                                                    "
                                                >
                                                    <selectize
                                                        class="form-control"
                                                        placeholder="Choose Account No."
                                                        v-model="
                                                            adjustment.coa_id
                                                        "
                                                        disabled
                                                    >
                                                        >
                                                        <!-- settings is optional -->
                                                        <option
                                                            v-for="account in coas"
                                                            :value="account.id"
                                                            :key="account.id"
                                                        >
                                                            {{
                                                                account.account_no
                                                            }}
                                                            {{
                                                                account.account_name
                                                            }}
                                                        </option>
                                                    </selectize>
                                                </div>
                                                <div
                                                    class="col-6 font-size-sm pt-0"
                                                    v-if="
                                                        adjustment.coa_id !==
                                                        receivepayment.payment_coa_id
                                                    "
                                                >
                                                    <money
                                                        class="form-control"
                                                        style="
                                                            text-align: right;
                                                        "
                                                        spellcheck="false"
                                                        v-model="
                                                            adjustment.debit
                                                        "
                                                        disabled
                                                    ></money>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 pt-0 text-left">
                                            <span
                                                @click="
                                                    toJournalVoucher(
                                                        receivepayment
                                                            .adjustment.id
                                                    )
                                                "
                                                class="text-pointer text-info"
                                            >
                                                {{
                                                    receivepayment.adjustment
                                                        .journal_description
                                                }}
                                            </span>
                                        </div>
                                    </div>
                                </template>
                            </div>
                            <div class="col-3 offset-3 pt-0">
                                <label for="field-1" class="control-label">
                                    Total Aplied :
                                </label>
                                <money
                                    class="form-control"
                                    style="text-align: right"
                                    v-model="
                                        receivepayment.payment_total_applied
                                    "
                                    spellcheck="false"
                                    disabled
                                ></money>
                            </div>
                            <div class="col-12 text-center mb-0 pb-0">
                                <p class="font-size-sm text-muted">
                                    * Require to be filled, Make sure all data is
                                    correctly filled
                                </p>
                                <button
                                    v-if="!forbiddenData"
                                    type="submit"
                                    class="btn btn-sm btn-success"
                                    v-ripple="{ center: true }"
                                    @click.prevent="submitData"
                                >
                                    <i class="fa fa-fw fa-check mr-1"></i>
                                    Update
                                </button>
                                <button
                                    v-if="!forbiddenData"
                                    type="button"
                                    class="btn btn-sm btn-danger"
                                    v-ripple="{ center: true }"
                                    @click.prevent="deleteData"
                                >
                                    <i class="fa fa-fw fa-trash mr-1"></i>
                                    Delete
                                </button>
                                <router-link
                                    type="button"
                                    class="btn btn-sm btn-alt-light"
                                    tag="button"
                                    v-ripple="{ center: true }"
                                    :to="{ name: 'receive-payment' }"
                                >
                                    <i class="fa fa-fw fa-times mr-1"></i> Close
                                </router-link>
                            </div>
                        </div>
                        <!-- END Submit -->
                        <!-- <span class="opacity-25">Created by {{receivepayment.created_by}} at {{receivepayment.created_at | moment("HH:mm DD/MM/YYYY")}}</span> -->
                    </form>
                </div>
            </div>
            <!-- END Basic -->
        </div>
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import moment from "moment";

export default {
    name: "edit-receive-payment",
    data() {
        return {
            loadingPage: 1,
            disabled: {},
            dateOriginal: "",
            forbiddenData: false,
        };
    },
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState("coa", { coas: (state) => state.coas.accounts }),
        ...mapState("contact", { contacts: (state) => state.contacts }),
        ...mapState("forbiddendate", {
            forbiddenDateLast: (state) => state.forbiddenDateLast,
        }),
        ...mapState("receivepayment", {
            receivepayment: (state) => state.receivepayment,
        }),
        bankAccounts: function () {
            if (this.coas) {
                return this.coas.filter(function (coa) {
                    return coa.account_type === "Bank";
                });
            }
        },
    },
    created() {
        if (this.$route.params.id) {
            this.editReceivePayment(this.$route.params.id)
                .then((response) => {
                    response.locked == true
                        ? (this.forbiddenData = true)
                        : (this.forbiddenData = false);
                    response.data.payment_date < this.forbiddenDateLast
                        ? (this.forbiddenData = true)
                        : (this.forbiddenData = false);
                    this.dateOriginal = response.data.payment_date;
                    this.receivePaymentNoOriginal = response.data.payment_no;
                    this.loadingPage = 0;
                })
                .catch((error) => {
                    this.alert(error.message, 2);
                    this.loadingPage = 0;
                });
        } else {
            this.$router.push({ name: "receive-payment" });
            this.alert("Data not found, select data first", 2);
        }
        this.getContactCustomer();
        this.getCoaAll();
        this.getForbiddenDate();
    },
    methods: {
        //VUEX
        ...mapMutations("receivepayment", ["CLEAR_FORM"]),
        ...mapActions("coa", ["getCoaAll"]),
        ...mapActions("contact", ["getContactCustomer"]),
        ...mapMutations(["CLEAR_ERRORS"]),
        ...mapActions("bill", ["getInvoiceContact"]),
        ...mapActions("forbiddendate", ["getForbiddenDate"]),
        ...mapActions("receivepayment", [
            "getReceivePaymentNo",
            "editReceivePayment",
            "updateReceivePayment",
            "removeReceivePayment",
        ]),

        //METHOD
        disabledDatePicker(date) {
            const lastDisableDate = new Date(
                moment(this.forbiddenDateLast).add(1, "days")
            );
            lastDisableDate.setHours(0, 0, 0, 0);
            return date < lastDisableDate;
        },
        //GET DATA ADDRESS & BEGINING BALANCE CUSTOMER
        changeContact(val) {
            this.loadingPage = 3;
            this.getInvoiceContact(val)
                .then((response) => {
                    this.receivepayment.payment_bills = response;
                    this.loadingPage = 0;
                })
                .catch((error) => {
                    console.log(error);
                    this.loadingPage = 0;
                });
            this.getJournalMemo();
        },
        changeDate() {
            if (
                moment(this.dateOriginal).format("YYYY-MM") ==
                moment(this.receivepayment.payment_date).format("YYYY-MM")
            ) {
                this.receivepayment.payment_no = this.receivePaymentNoOriginal;
                // this.getJournalMemo();
            } else {
                this.getReceivePaymentNo();
                // this.getJournalMemo();
            }
        },
        calculateReceivePayment() {
            let ammountReceive = this.receivepayment.payment_amount;
            let totalPaid = 0;
            this.receivepayment.payment_bills.forEach((bill) => {
                totalPaid += bill.amount_paid;
            });
            this.receivepayment.payment_total_applied = totalPaid;
            this.receivepayment.payment_out_of_balance =
                ammountReceive - totalPaid;
        },
        //GET JOURNAL MEMO
        getJournalMemo() {
            if (this.receivepayment.journal == null) {
                if (
                    this.receivepayment.payment_contact_id == "" ||
                    this.receivepayment.payment_contact_id == 0
                ) {
                    let newJournalMemo = "Payment; ";
                    this.receivepayment.payment_journal_memo = newJournalMemo;
                } else {
                    var result = this.contacts.find((obj) => {
                        return (
                            obj.id ===
                            parseInt(this.receivepayment.payment_contact_id)
                        );
                    });
                    let newJournalMemo = "Payment; " + result.contact_name;
                    this.receivepayment.payment_journal_memo = newJournalMemo;
                }
            } else {
                this.receivepayment.payment_journal_memo = this.receivepayment.journal.journal_description;
            }
        },
        toJournalVoucher(val) {
            this.$router.push({
                name: "journal-transaction-voucher",
                params: { id: val },
            });
        },
        submitData() {
            this.CLEAR_ERRORS();
            this.loadingPage = 2;
            this.updateReceivePayment(this.$route.params.id)
                .then(() => {
                    this.$router.push({
                        name: "receive-payment",
                        params: { reloadData: true },
                    });
                    this.alert("Successfully Update Data", 1);
                    this.loadingPage = 0;
                })
                .catch((error) => {
                    error.response.status == 500
                        ? this.alert(error.response.data.message, 2)
                        : "";
                    error.response.status != 422 && error.response.status != 500
                        ? this.alert(error.message, 2)
                        : "";
                    this.loadingPage = 0;
                });
        },
        deleteData() {
            this.$swal({
                title: "Are you sure ?",
                text: "Deleted data cannot be recovery",
                icon: "warning",
                buttons: ["Cancel", "Delete"],
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    this.removeReceivePayment(this.$route.params.id)
                        .then(() => {
                            this.$router.push({
                                name: "receive-payment",
                                params: { reloadData: true },
                            });
                            this.alert("Data has been deleted !", 1);
                            this.loadingPage = 0;
                        })
                        .catch((error) => {
                            error.response.status == 500
                                ? this.alert(error.response.data.message, 2)
                                : "";
                            error.response.status != 422 &&
                            error.response.status != 500
                                ? this.alert(error.message, 2)
                                : "";
                            this.loadingPage = 0;
                        });
                }
            });
        },
    },
    destroyed() {
        this.CLEAR_FORM();
    },
};
</script>
