import $axios from "../../api.js";

const state = () => ({
    assets: [],

    asset: {
        asset_id: 0,
        asset_name: "",
        category_id: 0,
        asset_code: "",
        asset_acquisition_date: "",
        asset_acquisition_value: 0,
        asset_residual_value: 0,
        asset_status: "",
        user_id: 0,
        assetData:[],
        category:{},
        depreciationDetail:[],
        accumulated_depreciation: 0,
        book_value: 0
    },
    page: 1, //UNTUK MENCATAT PAGE PAGINATE YANG SEDANG DIAKSES
    last_page: 1,
    search: ""
});

const mutations = {
    ASSIGN_DATA(state, payload) {
        state.assets = payload;
        state.last_page = payload.meta.last_page;
        state.page = payload.meta.current_page;
    },
    SET_PAGE(state, payload) {
        state.page = payload;
    },
    ASSIGN_FORM(state, payload) {
        state.asset = {
            asset_id: payload.id,
            asset_name: payload.asset_name,
            category_id: payload.category_id,
            asset_code: payload.asset_code,
            asset_acquisition_date: payload.asset_acquisition_date,
            asset_acquisition_value: payload.asset_acquisition_value,
            asset_residual_value: payload.asset_residual_value,
            asset_status: payload.asset_status,
            user_id: payload.user_id,
            assetData:[],
            category:payload.category,
            depreciationDetail:payload.depreciation_detail,
            accumulated_depreciation: payload.accumulated_depreciation,
            book_value: payload.book_value
        };
    },
    //ME-RESET STATE NEWS MENJADI KOSONG
    CLEAR_FORM(state) {
        state.asset = {
            asset_id: 0,
            asset_name: "",
            category_id: 0,
            asset_code: "",
            asset_acquisition_date: "",
            asset_acquisition_value: 0,
            asset_residual_value: 0,
            asset_status: "",
            user_id: 0,
            assetData:[],
            category:{},
            depreciationDetail:[],
            accumulated_depreciation: 0,
            book_value: 0
        };
    },
    CLEAR_DATA(state) {
        state.assets = [];
    }
};

const actions = {
    getAsset({ dispatch, commit, state }, payload) {
        let search = typeof payload.search != "undefined" ? payload.search : "";
        let rows = payload.rows;
        let page =
            typeof payload.rows != "All" && payload.rowsUpdate != 1
                ? state.page
                : "1";

        return new Promise((resolve, reject) => {
            $axios
                .get(`/asset?page=${page}&search=${search}&rows=${rows}`)
                .then(response => {
                    commit("ASSIGN_DATA", response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                    reject(error);
                });
        });
    },
    submitAsset({ dispatch, commit, state }) {
        return new Promise((resolve, reject) => {
            $axios
                .post(`/asset`, state.asset)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                });
        });
    },
    editAsset({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.get(`/asset/${payload}/edit`).then(response => {
                commit("ASSIGN_FORM", response.data);
                resolve(response.data);
            });
        });
    },
    updateAsset({ state, commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .put(`/asset/${payload}`, state.asset)
                .then(response => {
                    //FORM DIBERSIHKAN
                    commit("CLEAR_FORM");
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                });
        });
    },
    //MENGHAPUS DATA
    removeAsset({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .delete(`/asset/${payload}`)
                .then(response => {
                    commit("CLEAR_FORM");
                    resolve(response.data);
                })
                .catch(error => {
                    if (error.response) {
                        commit("SET_ERRORS", error.response.data, {
                            root: true
                        });
                    }
                    reject(error);
                });
        });
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
