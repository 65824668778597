import $axios from "../../api.js";

const state = () => ({});
const mutations = {};
const actions = {
    getClosingJournal({ dispatch, commit, state }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .get(
                    `/closing-year/get-closing-journal?dateStart=${payload.date_start}&dateEnd=${payload.date_end}`
                )
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                });
        });
    },
    doClosingJournal({ dispatch, commit, state }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .post(`/closing-year/close`, {
                    date_start: payload.date_start,
                    date_end: payload.date_end
                })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                });
        });
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
