<template>
    <nav id="sidebar" aria-label="Main Navigation" data-simplebar="init">
        <div class="simplebar-wrapper" style="margin: 0px">
            <div class="simplebar-height-auto-observer-wrapper">
                <div class="simplebar-height-auto-observer"></div>
            </div>
            <div class="simplebar-mask">
                <div class="simplebar-offset" style="right: 0px; bottom: 0px">
                    <div
                        class="simplebar-content-wrapper"
                        style="height: 100%; overflow: hidden scroll"
                    >
                        <div class="simplebar-content" style="padding: 0px">
                            <!-- Side Header -->
                            <div class="content-header bg-white-5">
                                <!-- Logo -->
                                <router-link
                                    class="font-w600 text-dual"
                                    :to="{ name: 'home' }"
                                >
                                    <span class="smini-visible">
                                        <img
                                            :src="
                                                '/media/proxima/logogram_1@1x.png'
                                            "
                                            height="30px"
                                            style="margin-bottom: 5px"
                                        />
                                        <!-- <span class="font-w700 font-size-h5">System</span> -->
                                    </span>
                                    <span class="smini-hide">
                                        <img
                                            :src="
                                                '/media/proxima/logo-expand.svg'
                                            "
                                            height="30px"
                                            style="
                                                margin-bottom: 5px;
                                                margin-right: 2px;
                                            "
                                        />
                                        <!-- <span class="font-w700 font-size-h5">System</span> -->
                                    </span>
                                </router-link>
                                <!-- END Logo -->

                                <!-- Extra -->
                                <div>
                                    <!-- Close Sidebar, Visible only on mobile screens -->
                                    <!-- Layout API, functionality initialized in Template._uiApiLayout() -->
                                    <a
                                        class="d-lg-none btn btn-sm btn-dual ml-2"
                                        data-toggle="layout"
                                        data-action="sidebar_close"
                                        href="javascript:void(0)"
                                    >
                                        <i class="fa fa-fw fa-times"></i>
                                    </a>
                                    <!-- END Close Sidebar -->
                                </div>
                                <!-- END Extra -->
                            </div>
                            <!-- END Side Header -->

                            <!-- Side Navigation GENERAL TYPE USER --------------------------------------------------------->
                            <div class="content-side content-side-full">
                                <ul class="nav-main">
                                    <!--Dashboard-->
                                    <li class="nav-main-item">
                                        <router-link
                                            class="nav-main-link d-lg-none"
                                            :to="{ name: 'home' }"
                                            data-toggle="layout"
                                            data-action="sidebar_toggle"
                                            exact
                                        >
                                            <i
                                                class="nav-main-link-icon si si-speedometer"
                                            ></i>
                                            <span class="nav-main-link-name"
                                                >Dashboard</span
                                            >
                                        </router-link>
                                        <router-link
                                            class="nav-main-link d-none d-lg-inline-block"
                                            :to="{ name: 'home' }"
                                            exact
                                        >
                                            <i
                                                class="nav-main-link-icon si si-speedometer"
                                            ></i>
                                            <span class="nav-main-link-name"
                                                >Dashboard</span
                                            >
                                        </router-link>
                                    </li>
                                    <!-------------------------BUSINESS--------------------------------------->
                                    <li
                                        class="nav-main-heading"
                                        v-if="
                                            auth.role.includes(
                                                'masterAccount'
                                            ) ||
                                                auth.role.includes(
                                                    'devAccount'
                                                ) ||
                                                auth.role.includes(
                                                    'accChief'
                                                ) ||
                                                auth.role.includes(
                                                    'accStaffBill'
                                                )  ||
                                                auth.role.includes(
                                                    'accStaffPurchase'
                                                ) ||
                                                auth.role.includes(
                                                    'accStaffPettyCash'
                                                ) ||
                                                auth.role.includes(
                                                    'accStaffBanking'
                                                ) ||
                                                auth.role.includes(
                                                    'accStaffInventory'
                                                )
                                        "
                                    >
                                        Business
                                    </li>
                                    <li
                                        class="nav-main-item"
                                        v-bind:class="{
                                            open:
                                                this.$route.path ==
                                                    '/bill-enter' ||
                                                this.$route.path ==
                                                    '/bill-register' ||
                                                this.$route.path ==
                                                    '/bill-register/edit' ||
                                                this.$route.path ==
                                                    '/bill-register/detail'
                                        }"
                                        v-if="
                                            auth.role.includes(
                                                'masterAccount'
                                            ) ||
                                                auth.role.includes(
                                                    'devAccount'
                                                ) ||
                                                auth.role.includes(
                                                    'accChief'
                                                ) ||
                                                auth.role.includes(
                                                    'accStaffBill'
                                                )
                                        "
                                    >
                                        <a
                                            class="nav-main-link nav-main-link-submenu"
                                            data-toggle="submenu"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            href="#"
                                        >
                                            <i
                                                class="nav-main-link-icon fa fa-file-invoice-dollar"
                                            ></i>
                                            <span class="nav-main-link-name"
                                                >Bill</span
                                            >
                                        </a>
                                        <ul class="nav-main-submenu">
                                            <li class="nav-main-item">
                                                <router-link
                                                    class="nav-main-link d-lg-none"
                                                    :to="{ name: 'bill-enter' }"
                                                    data-toggle="layout"
                                                    data-action="sidebar_toggle"
                                                    v-if="
                                                        auth.role.includes(
                                                            'masterAccount'
                                                        ) ||
                                                            auth.role.includes(
                                                                'devAccount'
                                                            ) ||
                                                            auth.role.includes(
                                                                'accChief'
                                                            ) ||
                                                            auth.role.includes(
                                                                'accStaffBill'
                                                            )
                                                    "
                                                >
                                                    <i
                                                        class="nav-main-link-icon far fa-edit"
                                                    ></i>
                                                    <span
                                                        class="nav-main-link-name"
                                                        >Enter Bill</span
                                                    >
                                                </router-link>
                                                <router-link
                                                    class="nav-main-link d-none d-lg-inline-block"
                                                    :to="{ name: 'bill-enter' }"
                                                    v-if="
                                                        auth.role.includes(
                                                            'masterAccount'
                                                        ) ||
                                                            auth.role.includes(
                                                                'devAccount'
                                                            ) ||
                                                            auth.role.includes(
                                                                'accChief'
                                                            ) ||
                                                            auth.role.includes(
                                                                'accStaffBill'
                                                            )
                                                    "
                                                >
                                                    <i
                                                        class="nav-main-link-icon far fa-edit"
                                                    ></i>
                                                    <span
                                                        class="nav-main-link-name"
                                                        >Enter Bill</span
                                                    >
                                                </router-link>
                                            </li>
                                            <li class="nav-main-item">
                                                <router-link
                                                    class="nav-main-link d-lg-none"
                                                    :to="{
                                                        name: 'bill-register'
                                                    }"
                                                    data-toggle="layout"
                                                    data-action="sidebar_toggle"
                                                    v-if="
                                                        auth.role.includes(
                                                            'masterAccount'
                                                        ) ||
                                                            auth.role.includes(
                                                                'devAccount'
                                                            ) ||
                                                            auth.role.includes(
                                                                'accChief'
                                                            ) ||
                                                            auth.role.includes(
                                                                'accStaffBill'
                                                            )
                                                    "
                                                >
                                                    <i
                                                        class="nav-main-link-icon fa fa-list-ul"
                                                    ></i>
                                                    <span
                                                        class="nav-main-link-name"
                                                        >Bill Register</span
                                                    >
                                                </router-link>
                                                <router-link
                                                    class="nav-main-link d-none d-lg-inline-block"
                                                    :to="{
                                                        name: 'bill-register'
                                                    }"
                                                    v-if="
                                                        auth.role.includes(
                                                            'masterAccount'
                                                        ) ||
                                                            auth.role.includes(
                                                                'devAccount'
                                                            ) ||
                                                            auth.role.includes(
                                                                'accChief'
                                                            ) ||
                                                            auth.role.includes(
                                                                'accStaffBill'
                                                            )
                                                    "
                                                >
                                                    <i
                                                        class="nav-main-link-icon fa fa-list-ul"
                                                    ></i>
                                                    <span
                                                        class="nav-main-link-name"
                                                        >Bill Register</span
                                                    >
                                                </router-link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li
                                        class="nav-main-item"
                                        v-bind:class="{
                                            open:
                                                this.$route.path ==
                                                    '/purchase-enter' ||
                                                this.$route.path ==
                                                    '/purchase-register' ||
                                                this.$route.path ==
                                                    '/purchase-register/edit' ||
                                                this.$route.path ==
                                                    '/purchase-register/detail'
                                        }"
                                        v-if="
                                            auth.role.includes(
                                                'masterAccount'
                                            ) ||
                                                auth.role.includes(
                                                    'devAccount'
                                                ) ||
                                                auth.role.includes(
                                                    'accChief'
                                                ) ||
                                                auth.role.includes(
                                                    'accStaffPurchase'
                                                )
                                        "
                                    >
                                        <a
                                            class="nav-main-link nav-main-link-submenu"
                                            data-toggle="submenu"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            href="#"
                                        >
                                            <i
                                                class="nav-main-link-icon fa fa-wallet"
                                            ></i>
                                            <span class="nav-main-link-name"
                                                >Purchase</span
                                            >
                                        </a>
                                        <ul class="nav-main-submenu">
                                            <li class="nav-main-item">
                                                <router-link
                                                    class="nav-main-link d-lg-none"
                                                    :to="{
                                                        name: 'purchase-enter'
                                                    }"
                                                    data-toggle="layout"
                                                    data-action="sidebar_toggle"
                                                    v-if="
                                                        auth.role.includes(
                                                            'masterAccount'
                                                        ) ||
                                                            auth.role.includes(
                                                                'devAccount'
                                                            ) ||
                                                            auth.role.includes(
                                                                'accChief'
                                                            ) ||
                                                            auth.role.includes(
                                                                'accStaffPurchase'
                                                            )
                                                    "
                                                >
                                                    <i
                                                        class="nav-main-link-icon far fa-edit"
                                                    ></i>
                                                    <span
                                                        class="nav-main-link-name"
                                                        >Enter Purchase</span
                                                    >
                                                </router-link>
                                                <router-link
                                                    class="nav-main-link d-none d-lg-inline-block"
                                                    :to="{
                                                        name: 'purchase-enter'
                                                    }"
                                                    v-if="
                                                        auth.role.includes(
                                                            'masterAccount'
                                                        ) ||
                                                            auth.role.includes(
                                                                'devAccount'
                                                            ) ||
                                                            auth.role.includes(
                                                                'accChief'
                                                            ) ||
                                                            auth.role.includes(
                                                                'accStaffPurchase'
                                                            )
                                                    "
                                                >
                                                    <i
                                                        class="nav-main-link-icon far fa-edit"
                                                    ></i>
                                                    <span
                                                        class="nav-main-link-name"
                                                        >Enter Purchase</span
                                                    >
                                                </router-link>
                                            </li>
                                            <li class="nav-main-item">
                                                <router-link
                                                    class="nav-main-link d-lg-none"
                                                    :to="{
                                                        name:
                                                            'purchase-register'
                                                    }"
                                                    data-toggle="layout"
                                                    data-action="sidebar_toggle"
                                                    v-if="
                                                        auth.role.includes(
                                                            'masterAccount'
                                                        ) ||
                                                            auth.role.includes(
                                                                'devAccount'
                                                            ) ||
                                                            auth.role.includes(
                                                                'accChief'
                                                            ) ||
                                                            auth.role.includes(
                                                                'accStaffPurchase'
                                                            )
                                                    "
                                                >
                                                    <i
                                                        class="nav-main-link-icon fa fa-list-ul"
                                                    ></i>
                                                    <span
                                                        class="nav-main-link-name"
                                                        >Purchase Register</span
                                                    >
                                                </router-link>
                                                <router-link
                                                    class="nav-main-link d-none d-lg-inline-block"
                                                    :to="{
                                                        name:
                                                            'purchase-register'
                                                    }"
                                                    v-if="
                                                        auth.role.includes(
                                                            'masterAccount'
                                                        ) ||
                                                            auth.role.includes(
                                                                'devAccount'
                                                            ) ||
                                                            auth.role.includes(
                                                                'accChief'
                                                            ) ||
                                                            auth.role.includes(
                                                                'accStaffPurchase'
                                                            )
                                                    "
                                                >
                                                    <i
                                                        class="nav-main-link-icon fa fa-list-ul"
                                                    ></i>
                                                    <span
                                                        class="nav-main-link-name"
                                                        >Purchase Register</span
                                                    >
                                                </router-link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li
                                        class="nav-main-item"
                                        v-bind:class="{
                                            open:
                                                this.$route.path ==
                                                    '/pay-purchase' ||
                                                this.$route.path ==
                                                    '/pay-purchase/create' ||
                                                this.$route.path ==
                                                    '/pay-purchase/detail' ||
                                                this.$route.path ==
                                                    '/pay-purchase/edit' ||
                                                this.$route.path ==
                                                    '/receive-payment' ||
                                                this.$route.path ==
                                                    '/receive-payment/create' ||
                                                this.$route.path ==
                                                    '/receive-payment/edit' ||
                                                this.$route.path ==
                                                    '/receive-payment/detail' ||
                                                this.$route.path ==
                                                    '/expense' ||
                                                this.$route.path ==
                                                    '/expense/create' ||
                                                this.$route.path ==
                                                    '/expense/edit' ||
                                                this.$route.path ==
                                                    '/expense/detail' ||
                                                this.$route.path == '/income' ||
                                                this.$route.path ==
                                                    '/income/create' ||
                                                this.$route.path ==
                                                    '/income/detail' ||
                                                this.$route.path ==
                                                    '/income/edit'
                                        }"
                                        v-if="
                                            auth.role.includes(
                                                'masterAccount'
                                            ) ||
                                                auth.role.includes(
                                                    'devAccount'
                                                ) ||
                                                auth.role.includes(
                                                    'accChief'
                                                ) ||
                                                auth.role.includes(
                                                    'accStaffPettyCash'
                                                ) ||
                                                auth.role.includes(
                                                    'accStaffBanking'
                                                )
                                        "
                                    >
                                        <a
                                            class="nav-main-link nav-main-link-submenu"
                                            data-toggle="submenu"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            href="#"
                                        >
                                            <i
                                                class="nav-main-link-icon fa fa-money-bill"
                                            ></i>
                                            <span class="nav-main-link-name"
                                                >Cash & Bank</span
                                            >
                                        </a>
                                        <ul class="nav-main-submenu">
                                            <li class="nav-main-item">
                                                <router-link
                                                    class="nav-main-link d-lg-none"
                                                    :to="{
                                                        name: 'receive-payment'
                                                    }"
                                                    data-toggle="layout"
                                                    data-action="sidebar_toggle"
                                                    v-if="
                                                        auth.role.includes(
                                                            'masterAccount'
                                                        ) ||
                                                            auth.role.includes(
                                                                'devAccount'
                                                            ) ||
                                                            auth.role.includes(
                                                                'accChief'
                                                            ) ||
                                                            auth.role.includes(
                                                                'accStaffBanking'
                                                            )
                                                    "
                                                >
                                                    <i
                                                        class="nav-main-link-icon fa fa-tasks"
                                                    ></i>
                                                    <span
                                                        class="nav-main-link-name"
                                                        >Receive Payment</span
                                                    >
                                                </router-link>
                                                <router-link
                                                    class="nav-main-link d-none d-lg-inline-block"
                                                    :to="{
                                                        name: 'receive-payment'
                                                    }"
                                                    v-if="
                                                        auth.role.includes(
                                                            'masterAccount'
                                                        ) ||
                                                            auth.role.includes(
                                                                'devAccount'
                                                            ) ||
                                                            auth.role.includes(
                                                                'accChief'
                                                            ) ||
                                                            auth.role.includes(
                                                                'accStaffBanking'
                                                            )
                                                    "
                                                >
                                                    <i
                                                        class="nav-main-link-icon fa fa-tasks"
                                                    ></i>
                                                    <span
                                                        class="nav-main-link-name"
                                                        >Receive Payment</span
                                                    >
                                                </router-link>
                                            </li>
                                            <li class="nav-main-item">
                                                <router-link
                                                    class="nav-main-link d-lg-none"
                                                    :to="{
                                                        name: 'pay-purchase'
                                                    }"
                                                    data-toggle="layout"
                                                    data-action="sidebar_toggle"
                                                    v-if="
                                                        auth.role.includes(
                                                            'masterAccount'
                                                        ) ||
                                                            auth.role.includes(
                                                                'devAccount'
                                                            ) ||
                                                            auth.role.includes(
                                                                'accChief'
                                                            ) ||
                                                            auth.role.includes(
                                                                'accStaffBanking'
                                                            )
                                                    "
                                                >
                                                    <i
                                                        class="nav-main-link-icon fa fa-tasks"
                                                    ></i>
                                                    <span
                                                        class="nav-main-link-name"
                                                        >Pay Purchase</span
                                                    >
                                                </router-link>
                                                <router-link
                                                    class="nav-main-link d-none d-lg-inline-block"
                                                    :to="{
                                                        name: 'pay-purchase'
                                                    }"
                                                    v-if="
                                                        auth.role.includes(
                                                            'masterAccount'
                                                        ) ||
                                                            auth.role.includes(
                                                                'devAccount'
                                                            ) ||
                                                            auth.role.includes(
                                                                'accChief'
                                                            ) ||
                                                            auth.role.includes(
                                                                'accStaffBanking'
                                                            )
                                                    "
                                                >
                                                    <i
                                                        class="nav-main-link-icon fa fa-tasks"
                                                    ></i>
                                                    <span
                                                        class="nav-main-link-name"
                                                        >Pay Purchase</span
                                                    >
                                                </router-link>
                                            </li>
                                            <li class="nav-main-item">
                                                <router-link
                                                    class="nav-main-link d-lg-none"
                                                    :to="{ name: 'expense' }"
                                                    data-toggle="layout"
                                                    data-action="sidebar_toggle"
                                                    v-if="
                                                        auth.role.includes(
                                                            'masterAccount'
                                                        ) ||
                                                            auth.role.includes(
                                                                'devAccount'
                                                            ) ||
                                                            auth.role.includes(
                                                                'accChief'
                                                            ) ||
                                                            auth.role.includes(
                                                                'accStaffBanking'
                                                            ) ||
                                                            auth.role.includes(
                                                                'accStaffPettyCash'
                                                            )
                                                    "
                                                >
                                                    <i
                                                        class="nav-main-link-icon fa fa-sign-out-alt"
                                                    ></i>
                                                    <span
                                                        class="nav-main-link-name"
                                                        >Expense</span
                                                    >
                                                </router-link>
                                                <router-link
                                                    class="nav-main-link d-none d-lg-inline-block"
                                                    :to="{ name: 'expense' }"
                                                    v-if="
                                                        auth.role.includes(
                                                            'masterAccount'
                                                        ) ||
                                                            auth.role.includes(
                                                                'devAccount'
                                                            ) ||
                                                            auth.role.includes(
                                                                'accChief'
                                                            ) ||
                                                            auth.role.includes(
                                                                'accStaffBanking'
                                                            ) ||
                                                            auth.role.includes(
                                                                'accStaffPettyCash'
                                                            )
                                                    "
                                                >
                                                    <i
                                                        class="nav-main-link-icon fa fa-sign-out-alt"
                                                    ></i>
                                                    <span
                                                        class="nav-main-link-name"
                                                        >Expense</span
                                                    >
                                                </router-link>
                                            </li>
                                            <li class="nav-main-item">
                                                <router-link
                                                    class="nav-main-link d-lg-none"
                                                    :to="{ name: 'income' }"
                                                    data-toggle="layout"
                                                    data-action="sidebar_toggle"
                                                    v-if="
                                                        auth.role.includes(
                                                            'masterAccount'
                                                        ) ||
                                                            auth.role.includes(
                                                                'devAccount'
                                                            ) ||
                                                            auth.role.includes(
                                                                'accChief'
                                                            ) ||
                                                            auth.role.includes(
                                                                'accStaffBanking'
                                                            ) ||
                                                            auth.role.includes(
                                                                'accStaffPettyCash'
                                                            )
                                                    "
                                                >
                                                    <i
                                                        class="nav-main-link-icon fa fa-sign-in-alt"
                                                    ></i>
                                                    <span
                                                        class="nav-main-link-name"
                                                        >Income</span
                                                    >
                                                </router-link>
                                                <router-link
                                                    class="nav-main-link d-none d-lg-inline-block"
                                                    :to="{ name: 'income' }"
                                                    v-if="
                                                        auth.role.includes(
                                                            'masterAccount'
                                                        ) ||
                                                            auth.role.includes(
                                                                'devAccount'
                                                            ) ||
                                                            auth.role.includes(
                                                                'accChief'
                                                            ) ||
                                                            auth.role.includes(
                                                                'accStaffBanking'
                                                            ) ||
                                                            auth.role.includes(
                                                                'accStaffPettyCash'
                                                            )
                                                    "
                                                >
                                                    <i
                                                        class="nav-main-link-icon fa fa-sign-in-alt"
                                                    ></i>
                                                    <span
                                                        class="nav-main-link-name"
                                                        >Income</span
                                                    >
                                                </router-link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li
                                        class="nav-main-item"
                                        v-if="
                                            auth.role.includes(
                                                'masterAccount'
                                            ) ||
                                                auth.role.includes(
                                                    'devAccount'
                                                ) ||
                                                auth.role.includes(
                                                    'accChief'
                                                ) ||
                                                auth.role.includes(
                                                    'accStaffInventory'
                                                )
                                        "
                                    >
                                        <router-link
                                            class="nav-main-link d-lg-none"
                                            v-bind:class="{
                                                'router-link-active':
                                                    this.$route.path ==
                                                    '/adjustment/detail'
                                            }"
                                            :to="{ name: 'products-services' }"
                                            data-toggle="layout"
                                            data-action="sidebar_toggle"
                                        >
                                            <i
                                                class="nav-main-link-icon fa fa-box mr-1"
                                            ></i>
                                            <span class="nav-main-link-name"
                                                >Products & Services</span
                                            >
                                        </router-link>
                                        <router-link
                                            class="nav-main-link d-none d-lg-inline-block"
                                            v-bind:class="{
                                                'router-link-active':
                                                    this.$route.path ==
                                                    '/adjustment/detail'
                                            }"
                                            :to="{ name: 'products-services' }"
                                        >
                                            <i
                                                class="nav-main-link-icon fa fa-box mr-1"
                                            ></i>
                                            <span class="nav-main-link-name"
                                                >Products & Services</span
                                            >
                                        </router-link>
                                    </li>
                                    <!-------------------------BOOKEEPING--------------------------------------->
                                    <li
                                        class="nav-main-heading"
                                        v-if="
                                            auth.role.includes(
                                                'masterAccount'
                                            ) ||
                                                auth.role.includes(
                                                    'devAccount'
                                                ) ||
                                                auth.role.includes(
                                                    'accChief'
                                                ) ||
                                                auth.role.includes(
                                                    'accStaffAccount'
                                                ) ||
                                                auth.role.includes(
                                                    'accStaffJournal'
                                                ) ||
                                                auth.role.includes(
                                                    'accStakeholder'
                                                ) ||
                                                auth.role.includes(
                                                    'accStaffBill'
                                                ) ||
                                                auth.role.includes(
                                                    'accStaffPurchase'
                                                ) ||
                                                auth.role.includes(
                                                    'accStaffReportGL'
                                                ) ||
                                                auth.role.includes(
                                                    'accStaffReportGLBank'
                                                ) ||
                                                auth.role.includes(
                                                    'accStaffReportBS'
                                                ) ||
                                                auth.role.includes(
                                                    'accStaffReportTB'
                                                ) ||
                                                auth.role.includes(
                                                    'accStaffReportPL'
                                                ) ||
                                                auth.role.includes(
                                                    'accStaffInventory'
                                                )
                                        "
                                    >
                                        Bookkeeping
                                    </li>
                                    <li
                                        class="nav-main-item"
                                        v-bind:class="{
                                            open:
                                                this.$route.path == '/coa' ||
                                                this.$route.path ==
                                                    '/coa/create' ||
                                                this.$route.path ==
                                                    '/coa/edit' ||
                                                this.$route.path ==
                                                    '/coa-linked'
                                        }"
                                        v-if="
                                            auth.role.includes(
                                                'masterAccount'
                                            ) ||
                                                auth.role.includes(
                                                    'devAccount'
                                                ) ||
                                                auth.role.includes(
                                                    'accChief'
                                                ) ||
                                                auth.role.includes(
                                                    'accStaffAccount'
                                                )
                                        "
                                    >
                                        <a
                                            class="nav-main-link nav-main-link-submenu"
                                            data-toggle="submenu"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            href="#"
                                        >
                                            <i
                                                class="nav-main-link-icon far fa-list-alt"
                                            ></i>
                                            <span class="nav-main-link-name">
                                                Accounts</span
                                            >
                                        </a>
                                        <ul class="nav-main-submenu">
                                            <li class="nav-main-item">
                                                <router-link
                                                    class="nav-main-link d-lg-none"
                                                    :to="{ name: 'coa' }"
                                                    data-toggle="layout"
                                                    data-action="sidebar_toggle"
                                                >
                                                    <i
                                                        class="nav-main-link-icon fa fa-list-ul"
                                                    ></i>
                                                    <span
                                                        class="nav-main-link-name"
                                                        >Chart of Accounts</span
                                                    >
                                                </router-link>
                                                <router-link
                                                    class="nav-main-link d-none d-lg-inline-block"
                                                    :to="{ name: 'coa' }"
                                                >
                                                    <i
                                                        class="nav-main-link-icon fa fa-list-ul"
                                                    ></i>
                                                    <span
                                                        class="nav-main-link-name"
                                                        >Chart of Accounts</span
                                                    >
                                                </router-link>
                                            </li>
                                            <li class="nav-main-item">
                                                <router-link
                                                    class="nav-main-link d-lg-none"
                                                    :to="{ name: 'coa-linked' }"
                                                    data-toggle="layout"
                                                    data-action="sidebar_toggle"
                                                >
                                                    <i
                                                        class="nav-main-link-icon fa fa-bezier-curve"
                                                    ></i>
                                                    <span
                                                        class="nav-main-link-name"
                                                        >Linked Accounts</span
                                                    >
                                                </router-link>
                                                <router-link
                                                    class="nav-main-link d-none d-lg-inline-block"
                                                    :to="{ name: 'coa-linked' }"
                                                >
                                                    <i
                                                        class="nav-main-link-icon fa fa-bezier-curve"
                                                    ></i>
                                                    <span
                                                        class="nav-main-link-name"
                                                        >Linked Accounts</span
                                                    >
                                                </router-link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li
                                        class="nav-main-item"
                                        v-bind:class="{
                                            open:
                                                this.$route.path ==
                                                    '/journal-record' ||
                                                this.$route.path ==
                                                    '/journal-transaction' ||
                                                this.$route.path ==
                                                    '/journal-transaction/edit' ||
                                                this.$route.path ==
                                                    '/journal-transaction/voucher' ||
                                                this.$route.path ==
                                                    '/recurring-journal'
                                        }"
                                        v-if="
                                            auth.role.includes(
                                                'masterAccount'
                                            ) ||
                                                auth.role.includes(
                                                    'devAccount'
                                                ) ||
                                                auth.role.includes(
                                                    'accChief'
                                                ) ||
                                                auth.role.includes(
                                                    'accStaffJournal'
                                                )
                                        "
                                    >
                                        <a
                                            class="nav-main-link nav-main-link-submenu"
                                            data-toggle="submenu"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            href="#"
                                        >
                                            <i
                                                class="nav-main-link-icon fa fa-book"
                                            ></i>
                                            <span class="nav-main-link-name">
                                                Journal</span
                                            >
                                        </a>
                                        <ul class="nav-main-submenu">
                                            <li class="nav-main-item">
                                                <router-link
                                                    class="nav-main-link d-lg-none"
                                                    :to="{
                                                        name: 'journal-record'
                                                    }"
                                                    data-toggle="layout"
                                                    data-action="sidebar_toggle"
                                                >
                                                    <i
                                                        class="nav-main-link-icon far fa-edit"
                                                    ></i>
                                                    <span
                                                        class="nav-main-link-name"
                                                        >Record Journal
                                                        Entry</span
                                                    >
                                                </router-link>
                                                <router-link
                                                    class="nav-main-link d-none d-lg-inline-block"
                                                    :to="{
                                                        name: 'journal-record'
                                                    }"
                                                >
                                                    <i
                                                        class="nav-main-link-icon far fa-edit"
                                                    ></i>
                                                    <span
                                                        class="nav-main-link-name"
                                                        >Record Journal
                                                        Entry</span
                                                    >
                                                </router-link>
                                            </li>
                                            <li class="nav-main-item">
                                                <router-link
                                                    class="nav-main-link d-lg-none"
                                                    :to="{
                                                        name:
                                                            'journal-transaction'
                                                    }"
                                                    data-toggle="layout"
                                                    data-action="sidebar_toggle"
                                                >
                                                    <i
                                                        class="nav-main-link-icon fa fa-list-ul"
                                                    ></i>
                                                    <span
                                                        class="nav-main-link-name"
                                                        >Transaction
                                                        Journal</span
                                                    >
                                                </router-link>
                                                <router-link
                                                    class="nav-main-link d-none d-lg-inline-block"
                                                    :to="{
                                                        name:
                                                            'journal-transaction'
                                                    }"
                                                >
                                                    <i
                                                        class="nav-main-link-icon fa fa-list-ul"
                                                    ></i>
                                                    <span
                                                        class="nav-main-link-name"
                                                        >Transaction
                                                        Journal</span
                                                    >
                                                </router-link>
                                            </li>
                                            <li class="nav-main-item">
                                                <router-link
                                                    class="nav-main-link d-lg-none"
                                                    :to="{
                                                        name:
                                                            'recurring-journal'
                                                    }"
                                                    data-toggle="layout"
                                                    data-action="sidebar_toggle"
                                                >
                                                    <i
                                                        class="nav-main-link-icon fa fa-history"
                                                    ></i>
                                                    <span
                                                        class="nav-main-link-name"
                                                        >Recurring Journal</span
                                                    >
                                                </router-link>
                                                <router-link
                                                    class="nav-main-link d-none d-lg-inline-block"
                                                    :to="{
                                                        name:
                                                            'recurring-journal'
                                                    }"
                                                >
                                                    <i
                                                        class="nav-main-link-icon fa fa-history"
                                                    ></i>
                                                    <span
                                                        class="nav-main-link-name"
                                                        >Recurring Journal</span
                                                    >
                                                </router-link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li
                                        class="nav-main-item"
                                        v-if="
                                            auth.role.includes(
                                                'masterAccount'
                                            ) ||
                                                auth.role.includes(
                                                    'devAccount'
                                                ) ||
                                                auth.role.includes(
                                                    'accChief'
                                                ) ||
                                                auth.role.includes(
                                                    'accStakeholder'
                                                ) ||
                                                auth.role.includes(
                                                    'accStaffBill'
                                                ) ||
                                                auth.role.includes(
                                                    'accStaffPurchase'
                                                ) ||
                                                auth.role.includes(
                                                    'accStaffReportGL'
                                                ) ||
                                                auth.role.includes(
                                                    'accStaffReportGLBank'
                                                ) ||
                                                auth.role.includes(
                                                    'accStaffReportBS'
                                                ) ||
                                                auth.role.includes(
                                                    'accStaffReportTB'
                                                ) ||
                                                auth.role.includes(
                                                    'accStaffReportPL'
                                                ) ||
                                                auth.role.includes(
                                                    'accStaffInventory'
                                                )
                                        "
                                    >
                                        <router-link
                                            class="nav nav-main-link d-lg-none"
                                            :to="{ name: 'report' }"
                                            data-toggle="layout"
                                            data-action="sidebar_toggle"
                                        >
                                            <i
                                                class="nav-main-link-icon fa fa-report mr-1"
                                            ></i>
                                            <span class="nav-main-link-name"
                                                >Reports</span
                                            >
                                        </router-link>
                                        <router-link
                                            class="nav-main-link d-none d-lg-inline-block"
                                            :to="{ name: 'report' }"
                                        >
                                            <i
                                                class="nav-main-link-icon fa fa-print mr-1"
                                            ></i>
                                            <span class="nav-main-link-name"
                                                >Reports</span
                                            >
                                        </router-link>
                                    </li>
                                    <li
                                        class="nav-main-item"
                                        v-if="
                                            auth.role.includes(
                                                'masterAccount'
                                            ) ||
                                                auth.role.includes(
                                                    'devAccount'
                                                ) ||
                                                auth.role.includes('accChief')
                                        "
                                    >
                                        <router-link
                                            class="nav-main-link d-lg-none"
                                            :to="{ name: 'start-new-year' }"
                                            data-toggle="layout"
                                            data-action="sidebar_toggle"
                                        >
                                            <i
                                                class="nav-main-link-icon si si-refresh mr-1"
                                            ></i>
                                            <span class="nav-main-link-name"
                                                >Start a New Year</span
                                            >
                                        </router-link>
                                        <router-link
                                            class="nav-main-link d-none d-lg-inline-block"
                                            :to="{ name: 'start-new-year' }"
                                        >
                                            <i
                                                class="nav-main-link-icon si si-refresh mr-1"
                                            ></i>
                                            <span class="nav-main-link-name"
                                                >Start a New Year</span
                                            >
                                        </router-link>
                                    </li>

                                    <!-------------------------ADMIN--------------------------------------->

                                    <!--Administrator-->
                                    <li
                                        class="nav-main-heading"
                                        v-if="
                                            auth.role.includes(
                                                'masterAccount'
                                            ) ||
                                                auth.role.includes(
                                                    'devAccount'
                                                ) ||
                                                auth.role.includes(
                                                    'administrator'
                                                ) ||
                                                auth.role.includes(
                                                    'accStaffInventory'
                                                )
                                        "
                                    >
                                        Administrator
                                    </li>
                                    <li
                                        class="nav-main-item"
                                        v-if="
                                            auth.role.includes(
                                                'masterAccount'
                                            ) ||
                                                auth.role.includes(
                                                    'devAccount'
                                                ) ||
                                                auth.role.includes(
                                                    'administrator'
                                                )
                                        "
                                    >
                                        <router-link
                                            class="nav-main-link d-lg-none"
                                            :to="{ name: 'user' }"
                                            data-toggle="layout"
                                            data-action="sidebar_toggle"
                                        >
                                            <i
                                                class="nav-main-link-icon far fa-user"
                                            ></i>
                                            <span class="nav-main-link-name"
                                                >User</span
                                            >
                                        </router-link>
                                        <router-link
                                            class="nav-main-link d-none d-lg-inline-block"
                                            :to="{ name: 'user' }"
                                        >
                                            <i
                                                class="nav-main-link-icon far fa-user"
                                            ></i>
                                            <span class="nav-main-link-name"
                                                >User</span
                                            >
                                        </router-link>
                                    </li>
                                    <li
                                        class="nav-main-item"
                                        v-if="
                                            auth.role.includes(
                                                'masterAccount'
                                            ) ||
                                                auth.role.includes(
                                                    'devAccount'
                                                ) ||
                                                auth.role.includes(
                                                    'administrator'
                                                )
                                        "
                                    >
                                        <router-link
                                            class="nav-main-link d-lg-none"
                                            :to="{ name: 'signature' }"
                                            data-toggle="layout"
                                            data-action="sidebar_toggle"
                                        >
                                            <i
                                                class="nav-main-link-icon fa fa-signature"
                                            ></i>
                                            <span class="nav-main-link-name"
                                                >Signature List</span
                                            >
                                        </router-link>
                                        <router-link
                                            class="nav-main-link d-none d-lg-inline-block"
                                            :to="{ name: 'signature' }"
                                        >
                                            <i
                                                class="nav-main-link-icon fa fa-signature"
                                            ></i>
                                            <span class="nav-main-link-name"
                                                >Signature List</span
                                            >
                                        </router-link>
                                    </li>
                                    <li
                                        class="nav-main-item"
                                        v-if="
                                            auth.role.includes(
                                                'masterAccount'
                                            ) ||
                                                auth.role.includes(
                                                    'devAccount'
                                                ) ||
                                                auth.role.includes(
                                                    'administrator'
                                                )
                                        "
                                    >
                                        <router-link
                                            class="nav-main-link d-lg-none"
                                            :to="{ name: 'bank' }"
                                            data-toggle="layout"
                                            data-action="sidebar_toggle"
                                        >
                                            <i
                                                class="nav-main-link-icon far fa-money-bill-alt"
                                            ></i>
                                            <span class="nav-main-link-name"
                                                >Bank List</span
                                            >
                                        </router-link>
                                        <router-link
                                            class="nav-main-link d-none d-lg-inline-block"
                                            :to="{ name: 'bank' }"
                                        >
                                            <i
                                                class="nav-main-link-icon far fa-money-bill-alt"
                                            ></i>
                                            <span class="nav-main-link-name"
                                                >Bank List</span
                                            >
                                        </router-link>
                                    </li>
                                    <li
                                        class="nav-main-item"
                                        v-if="
                                            auth.role.includes(
                                                'masterAccount'
                                            ) ||
                                                auth.role.includes('devAccount')
                                        "
                                    >
                                        <router-link
                                            class="nav-main-link d-lg-none"
                                            :to="{ name: 'workspace' }"
                                            data-toggle="layout"
                                            data-action="sidebar_toggle"
                                        >
                                            <i
                                                class="nav-main-link-icon fa fa-archive"
                                            ></i>
                                            <span class="nav-main-link-name"
                                                >Workspace</span
                                            >
                                        </router-link>
                                        <router-link
                                            class="nav-main-link d-none d-lg-inline-block"
                                            :to="{ name: 'workspace' }"
                                        >
                                            <i
                                                class="nav-main-link-icon fa fa-archive"
                                            ></i>
                                            <span class="nav-main-link-name"
                                                >Workspace</span
                                            >
                                        </router-link>
                                    </li>
                                    <li
                                        class="nav-main-item"
                                        v-if="
                                            auth.role.includes(
                                                'masterAccount'
                                            ) ||
                                                auth.role.includes(
                                                    'devAccount'
                                                ) ||
                                                auth.role.includes(
                                                    'administrator'
                                                ) ||
                                                auth.role.includes('accChief')
                                        "
                                    >
                                        <router-link
                                            class="nav-main-link d-lg-none"
                                            :to="{ name: 'activity' }"
                                            data-toggle="layout"
                                            data-action="sidebar_toggle"
                                        >
                                            <i
                                                class="nav-main-link-icon far fa-clock"
                                            ></i>
                                            <span class="nav-main-link-name"
                                                >Activity</span
                                            >
                                        </router-link>
                                        <router-link
                                            class="nav-main-link d-none d-lg-inline-block"
                                            :to="{ name: 'activity' }"
                                        >
                                            <i
                                                class="nav-main-link-icon far fa-clock"
                                            ></i>
                                            <span class="nav-main-link-name"
                                                >Activity</span
                                            >
                                        </router-link>
                                        <router-link
                                            class="nav-main-link d-lg-none"
                                            :to="{ name: 'notification' }"
                                            data-toggle="layout"
                                            data-action="sidebar_toggle"
                                        >
                                            <i
                                                class="nav-main-link-icon far fa-bell"
                                            ></i>
                                            <span class="nav-main-link-name"
                                                >Notification</span
                                            >
                                        </router-link>
                                        <router-link
                                            class="nav-main-link d-none d-lg-inline-block"
                                            :to="{ name: 'notification' }"
                                        >
                                            <i
                                                class="nav-main-link-icon far fa-bell"
                                            ></i>
                                            <span class="nav-main-link-name"
                                                >Notification</span
                                            >
                                        </router-link>
                                    </li>

                                    <li
                                        class="nav-main-heading"
                                        v-if="
                                            auth.role.includes(
                                                'masterAccount'
                                            ) ||
                                                auth.role.includes(
                                                    'devAccount'
                                                ) ||
                                                auth.role.includes(
                                                    'accChief'
                                                ) ||
                                                auth.role.includes(
                                                    'accStaffSetup'
                                                ) ||
                                                auth.role.includes(
                                                    'accStaffAsset'
                                                ) ||
                                                auth.role.includes(
                                                    'accStaffInventory'
                                                )
                                        "
                                    >
                                        Data Setup
                                    </li>
                                    <li
                                        class="nav-main-item"
                                        v-if="
                                            auth.role.includes(
                                                'masterAccount'
                                            ) ||
                                                auth.role.includes(
                                                    'devAccount'
                                                ) ||
                                                auth.role.includes(
                                                    'accChief'
                                                ) ||
                                                auth.role.includes(
                                                    'accStaffSetup'
                                                ) ||
                                                auth.role.includes(
                                                    'accStaffInventory'
                                                )
                                        "
                                    >
                                        <router-link
                                            class="nav-main-link d-lg-none"
                                            :to="{ name: 'contact' }"
                                            data-toggle="layout"
                                            data-action="sidebar_toggle"
                                        >
                                            <i
                                                class="nav-main-link-icon fa fa-users-cog"
                                            ></i>
                                            <span class="nav-main-link-name"
                                                >Contact Data</span
                                            >
                                        </router-link>
                                        <router-link
                                            class="nav-main-link d-none d-lg-inline-block"
                                            :to="{ name: 'contact' }"
                                        >
                                            <i
                                                class="nav-main-link-icon fa fa-users-cog"
                                            ></i>
                                            <span class="nav-main-link-name"
                                                >Contact Data</span
                                            >
                                        </router-link>
                                    </li>
                                    <li
                                        class="nav-main-item"
                                        v-if="
                                            auth.role.includes(
                                                'masterAccount'
                                            ) ||
                                                auth.role.includes(
                                                    'devAccount'
                                                ) ||
                                                auth.role.includes(
                                                    'accChief'
                                                ) ||
                                                auth.role.includes(
                                                    'accStaffSetup'
                                                ) ||
                                                auth.role.includes(
                                                    'accStaffInventory'
                                                )
                                        "
                                    >
                                        <router-link
                                            class="nav-main-link d-lg-none"
                                            :to="{ name: 'tax' }"
                                            data-toggle="layout"
                                            data-action="sidebar_toggle"
                                        >
                                            <i
                                                class="nav-main-link-icon fa fa-table"
                                            ></i>
                                            <span class="nav-main-link-name"
                                                >Tax Data</span
                                            >
                                        </router-link>
                                        <router-link
                                            class="nav-main-link d-none d-lg-inline-block"
                                            :to="{ name: 'tax' }"
                                        >
                                            <i
                                                class="nav-main-link-icon fa fa-table"
                                            ></i>
                                            <span class="nav-main-link-name"
                                                >Tax Data</span
                                            >
                                        </router-link>
                                    </li>
                                    <li
                                        class="nav-main-item"
                                        v-if="
                                            auth.role.includes(
                                                'masterAccount'
                                            ) ||
                                                auth.role.includes(
                                                    'devAccount'
                                                ) ||
                                                auth.role.includes(
                                                    'accChief'
                                                ) ||
                                                auth.role.includes(
                                                    'accStaffInventory'
                                                )
                                        "
                                    >
                                        <router-link
                                            class="nav-main-link d-lg-none"
                                            :to="{
                                                name: 'uom'
                                            }"
                                            data-toggle="layout"
                                            data-action="sidebar_toggle"
                                        >
                                            <i
                                                class="nav-main-link-icon fa fa-pencil-ruler"
                                            ></i>
                                            <span class="nav-main-link-name"
                                                >UOM Data</span
                                            >
                                        </router-link>
                                        <router-link
                                            class="nav-main-link d-none d-lg-inline-block"
                                            :to="{
                                                name: 'uom'
                                            }"
                                        >
                                            <i
                                                class="nav-main-link-icon fa fa-pencil-ruler"
                                            ></i>
                                            <span class="nav-main-link-name"
                                                >UOM Data</span
                                            >
                                        </router-link>
                                    </li>
                                    <li
                                        class="nav-main-item"
                                        v-if="
                                            auth.role.includes(
                                                'masterAccount'
                                            ) ||
                                                auth.role.includes(
                                                    'devAccount'
                                                ) ||
                                                auth.role.includes(
                                                    'accChief'
                                                ) ||
                                                auth.role.includes(
                                                    'accStaffInventory'
                                                )
                                        "
                                    >
                                        <router-link
                                            class="nav-main-link d-lg-none"
                                            :to="{ name: 'warehouse' }"
                                            data-toggle="layout"
                                            data-action="sidebar_toggle"
                                        >
                                            <i
                                                class="nav-main-link-icon fa fa-warehouse"
                                            ></i>
                                            <span class="nav-main-link-name"
                                                >Warehouse Data</span
                                            >
                                        </router-link>
                                        <router-link
                                            class="nav-main-link d-none d-lg-inline-block"
                                            :to="{ name: 'warehouse' }"
                                        >
                                            <i
                                                class="nav-main-link-icon fa fa-warehouse"
                                            ></i>
                                            <span class="nav-main-link-name"
                                                >Warehouse Data</span
                                            >
                                        </router-link>
                                    </li>
                                    <li
                                        class="nav-main-item"
                                        v-if="
                                            auth.role.includes(
                                                'masterAccount'
                                            ) ||
                                                auth.role.includes(
                                                    'devAccount'
                                                ) ||
                                                auth.role.includes(
                                                    'accChief'
                                                ) ||
                                                auth.role.includes(
                                                    'accStaffInventory'
                                                )
                                        "
                                    >
                                        <router-link
                                            class="nav-main-link d-lg-none"
                                            :to="{
                                                name: 'item-type'
                                            }"
                                            data-toggle="layout"
                                            data-action="sidebar_toggle"
                                        >
                                            <i
                                                class="nav-main-link-icon fa fa-swatchbook"
                                            ></i>
                                            <span class="nav-main-link-name"
                                                >Item Type Data</span
                                            >
                                        </router-link>
                                        <router-link
                                            class="nav-main-link d-none d-lg-inline-block"
                                            :to="{
                                                name: 'item-type'
                                            }"
                                        >
                                            <i
                                                class="nav-main-link-icon fa fa-swatchbook"
                                            ></i>
                                            <span class="nav-main-link-name"
                                                >Item Type Data</span
                                            >
                                        </router-link>
                                    </li>

                                    <!-------------------------ASSET--------------------------------------->

                                    <!--Asset Management-->
                                    <!-- <li
                                        class="nav-main-heading"
                                        v-if="
                                            auth.role.includes(
                                                'masterAccount'
                                            ) ||
                                                auth.role.includes(
                                                    'devAccount'
                                                ) ||
                                                auth.role.includes(
                                                    'accChief'
                                                ) ||
                                                auth.role.includes(
                                                    'accStaffAsset'
                                                )
                                        "
                                    >
                                        Asset Management
                                    </li>
                                    <li class="nav-main-item">
                                        <router-link
                                            class="nav-main-link d-lg-none"
                                            :to="{
                                                name: 'asset'
                                            }"
                                            data-toggle="layout"
                                            data-action="sidebar_toggle"
                                        >
                                            <i
                                                class="nav-main-link-icon fa fa-pallet"
                                            ></i>
                                            <span class="nav-main-link-name"
                                                >Asset</span
                                            >
                                        </router-link>
                                        <router-link
                                            class="nav-main-link d-none d-lg-inline-block"
                                            :to="{
                                                name: 'asset'
                                            }"
                                        >
                                            <i
                                                class="nav-main-link-icon fa fa-pallet"
                                            ></i>
                                            <span class="nav-main-link-name"
                                                >Asset</span
                                            >
                                        </router-link>
                                    </li>
                                    <li class="nav-main-item">
                                        <router-link
                                            class="nav-main-link d-lg-none"
                                            :to="{
                                                name: 'asset-depreciation'
                                            }"
                                            data-toggle="layout"
                                            data-action="sidebar_toggle"
                                        >
                                            <i
                                                class="nav-main-link-icon fa fa-list-alt"
                                            ></i>
                                            <span class="nav-main-link-name"
                                                >Depreciation</span
                                            >
                                        </router-link>
                                        <router-link
                                            class="nav-main-link d-none d-lg-inline-block"
                                            :to="{
                                                name: 'asset-depreciation'
                                            }"
                                        >
                                            <i
                                                class="nav-main-link-icon fa fa-list-alt"
                                            ></i>
                                            <span class="nav-main-link-name"
                                                >Depreciation</span
                                            >
                                        </router-link>
                                    </li>
                                    <li class="nav-main-item">
                                        <router-link
                                            class="nav-main-link d-lg-none"
                                            :to="{
                                                name: 'asset-category'
                                            }"
                                            data-toggle="layout"
                                            data-action="sidebar_toggle"
                                        >
                                            <i
                                                class="nav-main-link-icon fa fa-swatchbook"
                                            ></i>
                                            <span class="nav-main-link-name"
                                                >Category</span
                                            >
                                        </router-link>
                                        <router-link
                                            class="nav-main-link d-none d-lg-inline-block"
                                            :to="{
                                                name: 'asset-category'
                                            }"
                                        >
                                            <i
                                                class="nav-main-link-icon fa fa-swatchbook"
                                            ></i>
                                            <span class="nav-main-link-name"
                                                >Category</span
                                            >
                                        </router-link>
                                    </li> -->

                                    <!-- Help -->
                                    <li class="nav-main-separator"></li>

                                    <li
                                        class="nav-main-item"
                                        v-bind:class="{
                                            open:
                                                this.$route.path ==
                                                    '/user-guides' ||
                                                this.$route.path ==
                                                    '/tutorial-video'
                                        }"
                                    >
                                        <a
                                            class="nav-main-link nav-main-link-submenu"
                                            data-toggle="submenu"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            href="#"
                                        >
                                            <i
                                                class="nav-main-link-icon fa fa-question"
                                            ></i>
                                            <span class="nav-main-link-name"
                                                >Help</span
                                            >
                                        </a>
                                        <ul class="nav-main-submenu">
                                            <li class="nav-main-item">
                                                <router-link
                                                    class="nav-main-link d-lg-none"
                                                    :to="{
                                                        name: 'user-guides'
                                                    }"
                                                    data-toggle="layout"
                                                    data-action="sidebar_toggle"
                                                >
                                                    <i
                                                        class="nav-main-link-icon fa fa-book"
                                                    ></i>
                                                    <span
                                                        class="nav-main-link-name"
                                                        >User Guides</span
                                                    >
                                                </router-link>
                                                <router-link
                                                    class="nav-main-link d-none d-lg-inline-block"
                                                    :to="{
                                                        name: 'user-guides'
                                                    }"
                                                >
                                                    <i
                                                        class="nav-main-link-icon fa fa-book"
                                                    ></i>
                                                    <span
                                                        class="nav-main-link-name"
                                                        >User Guides</span
                                                    >
                                                </router-link>
                                            </li>
                                            <li class="nav-main-item">
                                                <router-link
                                                    class="nav-main-link d-lg-none"
                                                    :to="{
                                                        name: 'tutorial-video'
                                                    }"
                                                    data-toggle="layout"
                                                    data-action="sidebar_toggle"
                                                >
                                                    <i
                                                        class="nav-main-link-icon fa fa-video"
                                                    ></i>
                                                    <span
                                                        class="nav-main-link-name"
                                                        >Tutorial Video</span
                                                    >
                                                </router-link>
                                                <router-link
                                                    class="nav-main-link d-none d-lg-inline-block"
                                                    :to="{
                                                        name: 'tutorial-video'
                                                    }"
                                                >
                                                    <i
                                                        class="nav-main-link-icon fa fa-video"
                                                    ></i>
                                                    <span
                                                        class="nav-main-link-name"
                                                        >Tutorial Video</span
                                                    >
                                                </router-link>
                                            </li>
                                            <li class="nav-main-item">
                                                <a
                                                    class="nav-main-link d-lg-none"
                                                    href="#"
                                                    @click="openTawkTo()"
                                                    data-toggle="layout"
                                                    data-action="sidebar_toggle"
                                                >
                                                    <i
                                                        class="nav-main-link-icon fa fa-phone-alt"
                                                    ></i>
                                                    <span
                                                        class="nav-main-link-name"
                                                        >Contact Us</span
                                                    >
                                                </a>
                                                <a
                                                    class="nav-main-link d-none d-lg-inline-block"
                                                    href="#"
                                                    @click="openTawkTo()"
                                                >
                                                    <i
                                                        class="nav-main-link-icon fa fa-phone-alt"
                                                    ></i>
                                                    <span
                                                        class="nav-main-link-name"
                                                        >Contact Us</span
                                                    >
                                                </a>
                                            </li>
                                        </ul>
                                    </li>

                                    <!--Logout-->
                                    <li class="nav-main-item">
                                        <button
                                            class="nav-main-link"
                                            @click="logout()"
                                        >
                                            <i
                                                class="nav-main-link-icon fa fa-power-off"
                                            ></i>
                                            <span
                                                class="nav-main-link-name"
                                                style="flex: unset"
                                                >Logout</span
                                            >
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            <!-- END Side Navigation -->
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="simplebar-placeholder"
                style="width: auto; height: 733px"
            ></div>
        </div>
        <div
            class="simplebar-track simplebar-horizontal"
            style="visibility: hidden"
        >
            <div
                class="simplebar-scrollbar"
                style="width: 0px; display: none"
            ></div>
        </div>
        <div
            class="simplebar-track simplebar-vertical"
            style="visibility: visible"
        >
            <div
                class="simplebar-scrollbar"
                style="
                    height: 679px;
                    transform: translate3d(0px, 0px, 0px);
                    display: block;
                "
            ></div>
        </div>
    </nav>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import SecureLS from "secure-ls";
var ls = new SecureLS({ isCompression: false, encryptionSecret: "d3v3L0P3r!" });

export default {
    computed: {
        ...mapState(["auth"])
    },
    methods: {
        //KETIKA TOMBOL LOGOUT DITEKAN, FUNGSI INI DIJALANKAN
        ...mapActions(["CLEAR_STATE"]),
        ...mapActions("auth", ["clearStorage"]),
        openTawkTo() {
            window.open(
                "https://tawk.to/chat/5f619555f0e7167d0010b148/default",
                "newwindow",
                "width=300,height=640"
            );
        },
        logout() {
            this.$swal({
                title: "Logout from system ?",
                text: "You can go back into the system by re-loggin",
                icon: "warning",
                buttons: ["Cancel", "Logout"],
                dangerMode: true
            }).then(willDelete => {
                this.clearStorage();
                if (willDelete) {
                    return new Promise((resolve, reject) => {
                        localStorage.removeItem("_tk__"); //MENGHAPUS TOKEN DARI LOCALSTORAGE
                        ls.remove("_ath_"); //MENGHAPUS auth DARI LOCALSTORAGE
                        ls.remove("_wk_spc_"); //MENGHAPUS auth DARI LOCALSTORAGE
                        ls.remove("_rl_"); //MENGHAPUS auth DARI LOCALSTORAGE
                        localStorage.clear();
                        sessionStorage.clear();
                        resolve();
                    }).then(() => {
                        this.CLEAR_STATE();
                        this.$store.state.token = localStorage.getItem("_tk__");
                        this.$store.state.authuser = ls.get("_ath_");
                        this.$store.state.auth.data = ls.get("_ath_");
                        this.$store.state.auth.role = ls.get("_rl_");
                        this.$router.push("/login"); //REDIRECT KE PAGE LOGIN
                    });
                }
            });
        }
    }
};
</script>
