<template>
    <main>
        <hero
            title="Edit Item"
            prevBreadcrumb="Products & Services"
            routeBreadcrumb="products-services"
            currentBreadcrumb="Edit Item"
        ></hero>

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <div
                class="block col-12 col-md-10 offset-md-1 block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 1"
                style="height: 250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <div
                class="block col-12 col-md-10 offset-md-1 block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 2"
                style="height: 250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Save Data</span
                        >
                    </center>
                </div>
            </div>
            <form
                method="POST"
                enctype="multipart/form-data"
                onsubmit="return false;"
            >
                <div
                    class="block col-12 col-md-10 offset-md-1 block-rounded block-mode-loading-oneui mb-0"
                    v-if="forbiddenData && loadingPage == 0"
                >
                    <div
                        class="alert alert-info d-flex align-items-center mb-0"
                        role="alert"
                    >
                        <div class="flex-00-auto">
                            <i class="fa fa-fw fa-info-circle"></i>
                        </div>
                        <div class="flex-fill ml-3">
                            <p class="mb-0">
                                This item data is
                                <b> included into closing date of year </b>, you
                                can't edit or delete this data
                            </p>
                        </div>
                    </div>
                </div>
                <!-- Basic -->
                <div
                    class="block col-12 col-md-10 offset-md-1 block-rounded block-mode-loading-oneui"
                    v-if="loadingPage == 0"
                >
                    <div class="block-content block-content-full">
                        <h5>General</h5>
                        <!--Form-->
                        <div class="row font-size-sm">
                            <div class="col-12">
                                <!-- WITH UNIT OF MEASURE -->
                                <div class="row mt-1">
                                    <div class="form-group col-4 py-1">
                                        <label for="example-text-input"
                                            >Code *</label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Item Code or SKU"
                                            v-model="item.item_code"
                                            :class="{
                                                'is-invalid': errors.item_code
                                            }"
                                        />
                                        <transition name="slide-fade">
                                            <p
                                                class="text-danger"
                                                v-if="errors.item_code"
                                            >
                                                {{ errors.item_code[0] }}
                                            </p>
                                        </transition>
                                    </div>
                                    <div class="form-group col-8 py-1">
                                        <label for="example-text-input"
                                            >Name *</label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Enter item name, specification, etc"
                                            v-model="item.item_name"
                                            :class="{
                                                'is-invalid': errors.item_name
                                            }"
                                        />
                                        <transition name="slide-fade">
                                            <p
                                                class="text-danger"
                                                v-if="errors.item_name"
                                            >
                                                {{ errors.item_name[0] }}
                                            </p>
                                        </transition>
                                    </div>
                                </div>
                                <!-- WITH UNIT OF MEASURE -->
                                <div class="row mt-1">
                                    <div class="form-group col-4 py-1">
                                        <label for="example-text-input"
                                            >Base Unit *</label
                                        >
                                        <selectize
                                            class="form-control"
                                            placeholder="Item base unit"
                                            v-model="item.uom_id"
                                            :class="{
                                                'is-invalid-selectize':
                                                    errors.uom_id
                                            }"
                                        >
                                            <option
                                                v-for="uomdata in uoms"
                                                :value="uomdata.id"
                                                :key="uomdata.id"
                                            >
                                                {{ uomdata.uom_name }}
                                                ({{ uomdata.uom_code }})
                                            </option>
                                        </selectize>
                                        <transition name="slide-fade">
                                            <p
                                                class="text-danger"
                                                v-if="errors.uom_id"
                                            >
                                                {{ errors.uom_id[0] }}
                                            </p>
                                        </transition>
                                    </div>
                                    <div class="form-group col-4 py-1">
                                        <label for="example-text-input"
                                            >Type</label
                                        >
                                        <selectize
                                            class="form-control"
                                            placeholder="Item Type"
                                            v-model="item.type_id"
                                            :class="{
                                                'is-invalid-selectize':
                                                    errors.type_id
                                            }"
                                            @input="setItemType()"
                                        >
                                            <!-- settings is optional -->
                                            <option
                                                v-for="type in types"
                                                :value="type.id"
                                                :key="type.id"
                                            >
                                                {{ type.type_name }}
                                            </option>
                                        </selectize>
                                        <transition name="slide-fade">
                                            <p
                                                class="text-danger"
                                                v-if="errors.type_id"
                                            >
                                                {{ errors.type_id[0] }}
                                            </p>
                                        </transition>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Description</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Item Description"
                                        v-model="item.item_desc"
                                        :class="{
                                            'is-invalid': errors.item_desc
                                        }"
                                    />
                                    <transition name="slide-fade">
                                        <p
                                            class="text-danger"
                                            v-if="errors.item_desc"
                                        >
                                            {{ errors.item_desc[0] }}
                                        </p>
                                    </transition>
                                </div>
                            </div>
                        </div>
                        <!--END Form-->
                    </div>
                </div>
                <!-- END Basic -->

                <!-- Basic -->
                <div
                    class="block col-12 col-md-10 offset-md-1 block-rounded block-mode-loading-oneui"
                    v-if="loadingPage == 0"
                >
                    <div class="block-content block-content-full">
                        <h5 class="mb-3">Opening Balance</h5>
                        <!--Form-->
                        <div class="row font-size-sm">
                            <div class="col-12">
                                <div class="form-notification">
                                    <div class="row mb-1">
                                        <div class="col-12">
                                            <div class="information-panel">
                                                <p class="my-1">
                                                    <i
                                                        class="fa fa-info-circle mr-1"
                                                    ></i
                                                    ><b>Information</b>
                                                </p>
                                                <p class="mb-2">
                                                    You can fill the Opening
                                                    Balance Amount if Item
                                                    already have Opening
                                                    Balance, this Opening
                                                    Balance will not included to
                                                    any account's opening
                                                    balance, it will only used
                                                    for Item Stock & Mutation
                                                    purpose only
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-1 mb-2">
                                    <div class="form-group col-4 py-0 mb-1">
                                        <label for="example-text-input"
                                            >Opening Date</label
                                        >
                                        <date-picker
                                            class="input-group"
                                            v-model="item.opening_date"
                                            format="DD/MM/YYYY"
                                            valueType="YYYY-MM-DD"
                                            placeholder="Issued On"
                                            :class="{
                                                'is-invalid':
                                                    errors.opening_date
                                            }"
                                            :default-value="new Date()"
                                            :disabled-date="disabledDatePicker"
                                        ></date-picker>
                                        <transition name="slide-fade">
                                            <p
                                                class="text-danger"
                                                v-if="errors.opening_date"
                                            >
                                                {{ errors.opening_date[0] }}
                                            </p>
                                        </transition>
                                    </div>
                                    <div
                                        class="form-group col-5 py-0 mb-1 mt-1 text-muted-high"
                                    >
                                        Opening Date will be set as the initial
                                        date for this inventory item, all
                                        transaction & mutation for these item
                                        can only be made after the opening date.
                                    </div>
                                </div>
                                <div class="row mt-1">
                                    <div class="form-group col-4 py-0 mb-1">
                                        <label for="example-text-input"
                                            >Opening Quantity</label
                                        >
                                        <numeric
                                            class="form-control"
                                            placeholder="Quantity"
                                            v-model.number="item.opening_qty"
                                            :class="{
                                                'is-invalid': errors.opening_qty
                                            }"
                                            separator="."
                                            :precision="2"
                                            @input="calculate()"
                                        />
                                        <transition name="slide-fade">
                                            <p
                                                class="text-danger"
                                                v-if="errors.opening_qty"
                                            >
                                                {{ errors.opening_qty[0] }}
                                            </p>
                                        </transition>
                                    </div>
                                    <div class="form-group col-4 py-0 mb-1">
                                        <label for="example-text-input"
                                            >Opening Cost per Item</label
                                        >
                                        <money
                                            class="form-control"
                                            style="text-align: right;"
                                            v-model="item.opening_price"
                                            spellcheck="false"
                                            :class="{
                                                'is-invalid':
                                                    errors.opening_price
                                            }"
                                            @input="calculate()"
                                        ></money>
                                        <transition name="slide-fade">
                                            <p
                                                class="text-danger"
                                                v-if="errors.opening_price"
                                            >
                                                {{ errors.opening_price[0] }}
                                            </p>
                                        </transition>
                                    </div>
                                    <div class="form-group col-4 py-0 mb-1">
                                        <label for="example-text-input"
                                            >Opening Amount</label
                                        >
                                        <money
                                            class="form-control"
                                            style="text-align: right;"
                                            v-model="item.opening_amount"
                                            spellcheck="false"
                                            :class="{
                                                'is-invalid':
                                                    errors.opening_amount
                                            }"
                                        ></money>
                                        <transition name="slide-fade">
                                            <p
                                                class="text-danger"
                                                v-if="errors.opening_amount"
                                            >
                                                {{ errors.opening_amount[0] }}
                                            </p>
                                        </transition>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--END Form-->
                    </div>
                </div>
                <!-- END Basic -->

                <!-- Basic -->
                <div
                    class="block col-12 col-md-10 offset-md-1 block-rounded block-mode-loading-oneui"
                    v-if="loadingPage == 0"
                >
                    <div class="block-content block-content-full">
                        <h5 class="mb-3">Bookkeeping</h5>
                        <!--Form-->
                        <div class="row font-size-sm">
                            <div class="col-12">
                                <!-- INVENTORY SECTION -->
                                <div class="form-group">
                                    <div
                                        class="form-check form-check-inline mr-0 mb-1"
                                    >
                                        <input
                                            class="form-check-input mr-1"
                                            type="checkbox"
                                            id="checkbox-inventory"
                                            @change="
                                                activeInactiveInventory($event)
                                            "
                                            v-model="checkedInventory"
                                            :disabled="
                                                item.datamutation.length > 0
                                            "
                                        />
                                        <label
                                            class="form-check-label font-weight-bolder ml-1"
                                            for="checkbox-inventory"
                                            >I Track this inventory item</label
                                        >
                                    </div>
                                    <div class="form-information ml-4">
                                        Track the quantity and value of stock on
                                        hand. This options is suitable for
                                        business that
                                        <b class="font-weight-bolder">
                                            track item price when the item is
                                            purchased or acquired before being
                                            sold and using Perpetual FIFO
                                            inventory system,</b
                                        >
                                        if you don’t do this or using Periodic
                                        or using Average Inventory Method,
                                        proceed with not tracking this inventory
                                    </div>
                                    <div
                                        class="form-notification ml-4"
                                        v-if="checkedInventory"
                                    >
                                        <div class="row mb-1">
                                            <div class="col-12">
                                                <div class="information-panel">
                                                    <p class="my-1">
                                                        <i
                                                            class="fa fa-info-circle mr-1"
                                                        ></i
                                                        ><b>Information</b>
                                                    </p>
                                                    <p class="mb-2">
                                                        You cannot change this
                                                        value once they appear
                                                        in a transaction (bills,
                                                        purchase, etc)
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mt-1 ml-4">
                                        <div
                                            class="form-group col-4 py-0 mb-1 pl-0"
                                            v-if="checkedInventory"
                                        >
                                            <label for="example-text-input"
                                                >Inventory Account *</label
                                            >
                                            <selectize
                                                class="form-control mt-0"
                                                placeholder="Choose Account No."
                                                v-model="item.inventory_coa_id"
                                                :class="{
                                                    'is-invalid-selectize':
                                                        errors.inventory_coa_id
                                                }"
                                                :disabled="
                                                    item.datamutation.length > 0
                                                "
                                            >
                                                <!-- settings is optional -->
                                                <option
                                                    v-for="account in inventoryAccounts"
                                                    :value="account.id"
                                                    :key="account.id"
                                                >
                                                    {{ account.account_no }}
                                                    {{ account.account_name }}
                                                </option>
                                            </selectize>
                                            <transition name="slide-fade">
                                                <p
                                                    class="text-danger"
                                                    v-if="
                                                        errors.inventory_coa_id
                                                    "
                                                >
                                                    {{
                                                        errors
                                                            .inventory_coa_id[0]
                                                    }}
                                                </p>
                                            </transition>
                                        </div>
                                        <div
                                            class="form-group col-4 py-0 mb-1"
                                            v-if="checkedInventory"
                                        >
                                            <label for="example-text-input"
                                                >COGS Account *</label
                                            >
                                            <selectize
                                                class="form-control mt-0"
                                                placeholder="Choose Account No."
                                                v-model="item.expense_coa_id"
                                                :class="{
                                                    'is-invalid-selectize':
                                                        errors.expense_coa_id
                                                }"
                                            >
                                                <!-- settings is optional -->
                                                <option
                                                    v-for="account in expenseAccounts"
                                                    :value="account.id"
                                                    :key="account.id"
                                                >
                                                    {{ account.account_no }}
                                                    {{ account.account_name }}
                                                </option>
                                            </selectize>
                                            <transition name="slide-fade">
                                                <p
                                                    class="text-danger"
                                                    v-if="errors.expense_coa_id"
                                                >
                                                    {{
                                                        errors.expense_coa_id[0]
                                                    }}
                                                </p>
                                            </transition>
                                        </div>
                                    </div>
                                </div>
                                <!-- PURCHASE SECTION -->
                                <div class="form-group">
                                    <div
                                        class="form-check form-check-inline mr-0 mb-1"
                                    >
                                        <input
                                            class="form-check-input mr-1"
                                            type="checkbox"
                                            id="checkbox-purchase"
                                            @change="
                                                activeInactivePurchase($event)
                                            "
                                            v-model="checkedPurchase"
                                        />
                                        <label
                                            class="form-check-label font-weight-bolder ml-1"
                                            for="checkbox-purchase"
                                            >I Purchase this item</label
                                        >
                                    </div>
                                    <div class="form-information ml-4">
                                        Add item to purchases. Enabling
                                        expense/any other reports made for this
                                        item
                                    </div>
                                    <div class="row mt-1 ml-4">
                                        <div
                                            class="form-group col-4 py-0 mb-1 pl-0"
                                            v-if="
                                                checkedPurchase &&
                                                    !checkedInventory
                                            "
                                        >
                                            <label for="example-text-input"
                                                >Purchase Account *</label
                                            >
                                            <selectize
                                                class="form-control mt-0"
                                                placeholder="Choose Account No."
                                                v-model="item.expense_coa_id"
                                                :class="{
                                                    'is-invalid-selectize':
                                                        errors.expense_coa_id
                                                }"
                                            >
                                                <!-- settings is optional -->
                                                <option
                                                    v-for="account in expenseAccounts"
                                                    :value="account.id"
                                                    :key="account.id"
                                                >
                                                    {{ account.account_no }}
                                                    {{ account.account_name }}
                                                </option>
                                            </selectize>
                                            <transition name="slide-fade">
                                                <p
                                                    class="text-danger"
                                                    v-if="errors.expense_coa_id"
                                                >
                                                    {{
                                                        errors.expense_coa_id[0]
                                                    }}
                                                </p>
                                            </transition>
                                        </div>
                                        <div
                                            class="form-group col-4 py-0 mb-1"
                                            v-bind:class="{
                                                'pl-0':
                                                    checkedPurchase &&
                                                    checkedInventory
                                            }"
                                            v-if="checkedPurchase"
                                        >
                                            <label for="example-text-input"
                                                >Purchase Price *</label
                                            >
                                            <money
                                                class="form-control"
                                                style="text-align: right;"
                                                v-model="item.purchase_price"
                                                spellcheck="false"
                                                :class="{
                                                    'is-invalid':
                                                        errors.purchase_price
                                                }"
                                            ></money>
                                            <transition name="slide-fade">
                                                <p
                                                    class="text-danger"
                                                    v-if="errors.purchase_price"
                                                >
                                                    {{
                                                        errors.purchase_price[0]
                                                    }}
                                                </p>
                                            </transition>
                                        </div>
                                        <div
                                            class="form-group col-4 py-0 mb-1"
                                            v-if="checkedPurchase"
                                        >
                                            <label for="example-text-input"
                                                >Purchase Tax *</label
                                            >
                                            <selectize
                                                class="form-control mt-0"
                                                placeholder="Choose Tax"
                                                v-model="item.purchase_tax_id"
                                                :class="{
                                                    'is-invalid-selectize':
                                                        errors.purchase_tax_id
                                                }"
                                            >
                                                <!-- settings is optional -->
                                                <option
                                                    v-for="tax in purchaseTaxs"
                                                    :value="tax.id"
                                                    :key="tax.id"
                                                >
                                                    {{ tax.tax_code }}
                                                    ({{ tax.tax_rate }}%)
                                                </option>
                                            </selectize>
                                            <transition name="slide-fade">
                                                <p
                                                    class="text-danger"
                                                    v-if="
                                                        errors.purchase_tax_id
                                                    "
                                                >
                                                    {{
                                                        errors
                                                            .purchase_tax_id[0]
                                                    }}
                                                </p>
                                            </transition>
                                        </div>
                                    </div>

                                    <div class="row ml-4">
                                        <div
                                            class="form-group col-12 py-0 pl-0"
                                            v-if="checkedPurchase"
                                        >
                                            <label for="example-text-input"
                                                >Description</label
                                            >
                                            <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Item Description"
                                                v-model="item.purchase_desc"
                                                :class="{
                                                    'is-invalid':
                                                        errors.purchase_desc
                                                }"
                                            />
                                            <transition name="slide-fade">
                                                <p
                                                    class="text-danger"
                                                    v-if="errors.purchase_desc"
                                                >
                                                    {{
                                                        errors.purchase_desc[0]
                                                    }}
                                                </p>
                                            </transition>
                                        </div>
                                    </div>
                                </div>
                                <!-- SELL SECTION -->
                                <div class="form-group">
                                    <div
                                        class="form-check form-check-inline mr-0 mb-1"
                                    >
                                        <input
                                            class="form-check-input mr-1"
                                            type="checkbox"
                                            id="checkbox-sell"
                                            @change="activeInactiveSell($event)"
                                            v-model="checkedSell"
                                        />
                                        <label
                                            class="form-check-label font-weight-bolder ml-1"
                                            for="checkbox-sell"
                                            >I Sell this item</label
                                        >
                                    </div>
                                    <div class="form-information ml-4">
                                        Add item to purchases. Enabling
                                        expense/any other reports made for this
                                        item
                                    </div>
                                    <div class="row mt-1 ml-4">
                                        <div
                                            class="form-group col-4 py-0 mb-1 pl-0"
                                            v-if="checkedSell"
                                        >
                                            <label for="example-text-input"
                                                >Sales Account *</label
                                            >
                                            <selectize
                                                class="form-control mt-0"
                                                placeholder="Choose Account No."
                                                v-model="item.income_coa_id"
                                                :class="{
                                                    'is-invalid-selectize':
                                                        errors.income_coa_id
                                                }"
                                            >
                                                <!-- settings is optional -->
                                                <option
                                                    v-for="account in incomeAccounts"
                                                    :value="account.id"
                                                    :key="account.id"
                                                >
                                                    {{ account.account_no }}
                                                    {{ account.account_name }}
                                                </option>
                                            </selectize>
                                            <transition name="slide-fade">
                                                <p
                                                    class="text-danger"
                                                    v-if="errors.income_coa_id"
                                                >
                                                    {{
                                                        errors.income_coa_id[0]
                                                    }}
                                                </p>
                                            </transition>
                                        </div>
                                        <div
                                            class="form-group col-4 py-0 mb-1"
                                            v-if="checkedSell"
                                        >
                                            <label for="example-text-input"
                                                >Sales Price *</label
                                            >
                                            <money
                                                class="form-control"
                                                style="text-align: right;"
                                                v-model="item.sell_price"
                                                spellcheck="false"
                                                :class="{
                                                    'is-invalid':
                                                        errors.sell_price
                                                }"
                                            ></money>
                                            <transition name="slide-fade">
                                                <p
                                                    class="text-danger"
                                                    v-if="errors.sell_price"
                                                >
                                                    {{ errors.sell_price[0] }}
                                                </p>
                                            </transition>
                                        </div>
                                        <div
                                            class="form-group col-4 py-0 mb-1"
                                            v-if="checkedSell"
                                        >
                                            <label for="example-text-input"
                                                >Sales Tax *</label
                                            >
                                            <selectize
                                                class="form-control mt-0"
                                                placeholder="Choose Tax"
                                                v-model="item.sell_tax_id"
                                                :class="{
                                                    'is-invalid-selectize':
                                                        errors.sell_tax_id
                                                }"
                                            >
                                                <!-- settings is optional -->
                                                <option
                                                    v-for="tax in purchaseTaxs"
                                                    :value="tax.id"
                                                    :key="tax.id"
                                                >
                                                    {{ tax.tax_code }}
                                                    ({{ tax.tax_rate }}%)
                                                </option>
                                            </selectize>
                                            <transition name="slide-fade">
                                                <p
                                                    class="text-danger"
                                                    v-if="errors.sell_tax_id"
                                                >
                                                    {{ errors.sell_tax_id[0] }}
                                                </p>
                                            </transition>
                                        </div>
                                    </div>

                                    <div class="row ml-4">
                                        <div
                                            class="form-group col-12 py-0 pl-0"
                                            v-if="checkedSell"
                                        >
                                            <label for="example-text-input"
                                                >Description</label
                                            >
                                            <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Item Description"
                                                v-model="item.sell_desc"
                                                :class="{
                                                    'is-invalid':
                                                        errors.sell_desc
                                                }"
                                            />
                                            <transition name="slide-fade">
                                                <p
                                                    class="text-danger"
                                                    v-if="errors.sell_desc"
                                                >
                                                    {{ errors.sell_desc[0] }}
                                                </p>
                                            </transition>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--END Form-->
                    </div>
                </div>
                <!-- END Basic -->

                <!-- Basic -->
                <div
                    class="block col-12 col-md-10 offset-md-1 block-rounded block-mode-loading-oneui"
                    v-if="loadingPage == 0"
                >
                    <div class="block-content block-content-full py-0">
                        <!-- Submit -->
                        <div class="row items-push">
                            <div class="col-12 text-right mb-0">
                                <button
                                    type="button"
                                    class="btn btn-sm btn-success mr-1"
                                    v-ripple="{ center: true }"
                                    @click.prevent="submitData"
                                >
                                    <i class="fa fa-fw fa-check mr-1"></i>
                                    Update
                                </button>
                                <button
                                    type="button"
                                    v-if="
                                        (item.item_status == 1 ||
                                            item.item_status == 0) &&
                                            item.datamutation.length <= 0
                                    "
                                    class="btn btn-sm btn-danger mr-1"
                                    v-ripple="{ center: true }"
                                    @click.prevent="deleteData"
                                >
                                    <i class="fa fa-fw fa-trash mr-1"></i>
                                    Delete
                                </button>
                                <button
                                    type="button"
                                    v-if="
                                        item.item_status == 1 &&
                                            item.datamutation.length > 0
                                    "
                                    class="btn btn-sm btn-secondary mr-1"
                                    v-ripple="{ center: true }"
                                    @click.prevent="archiveData"
                                >
                                    <i class="fa fa-fw fa-archive mr-1"></i>
                                    Archive
                                </button>
                                <button
                                    v-if="
                                        item.item_status == 0 &&
                                            item.datamutation.length > 0
                                    "
                                    type="button"
                                    class="btn btn-sm btn-secondary mr-1"
                                    v-ripple="{ center: true }"
                                    @click.prevent="unarchiveData"
                                >
                                    <i class="fa fa-fw fa-archive mr-1"></i>
                                    Unarchive
                                </button>
                                <router-link
                                    type="button"
                                    class="btn btn-sm btn-alt-light"
                                    tag="button"
                                    v-ripple="{ center: true }"
                                    :to="{ name: 'products-services' }"
                                >
                                    <i class="fa fa-fw fa-times mr-1"></i> Close
                                </router-link>
                            </div>
                        </div>
                        <!-- END Submit -->
                    </div>
                </div>
                <!-- END Basic -->
            </form>
        </div>
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import moment from "moment";

export default {
    name: "edit-bills",
    data() {
        return {
            loadingPage: 1,
            items: [],
            disabled: {},
            forbiddenData: false,
            checked: true,
            checkedSell: false,
            checkedPurchase: false,
            checkedInventory: false
        };
    },
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState("item", { item: state => state.item }),
        ...mapState("coa", { coas: state => state.coas.accounts }),
        ...mapState("tax", { taxs: state => state.taxs }),
        ...mapState("forbiddendate", {
            forbiddenDateLast: state => state.forbiddenDateLast
        }),
        ...mapState("uom", { uoms: state => state.uoms }),
        ...mapState("itemtype", {
            types: state => state.types
        }),
        inventoryAccounts: function() {
            if (this.coas) {
                return this.coas.filter(function(coa) {
                    return (
                        coa.account_type === "Bank" ||
                        coa.account_type === "Account Receivable" ||
                        coa.account_type === "Other Current Asset" ||
                        coa.account_type === "Other Asset" ||
                        coa.account_type === "Fixed Asset"
                    );
                    // return coa;
                });
            }
        },
        incomeAccounts: function() {
            if (this.coas) {
                return this.coas.filter(function(coa) {
                    return (
                        coa.account_type === "Income" ||
                        coa.account_type === "Other Income"
                    );
                });
            }
        },
        expenseAccounts: function() {
            if (this.coas) {
                if (this.checkedInventory == true) {
                    return this.coas.filter(function(coa) {
                        return coa.account_type === "Cost of Sales";
                        // return coa;
                    });
                } else {
                    return this.coas.filter(function(coa) {
                        return (
                            coa.account_type === "Expense" ||
                            coa.account_type === "Other Expense"
                        );
                        // return coa;
                    });
                }
            }
        },
        sellTaxs: function() {
            if (this.taxs) {
                return this.taxs.filter(function(tax) {
                    return tax.tax_coa_ap !== null || tax.tax_rate == 0;
                });
            }
        },
        purchaseTaxs: function() {
            if (this.taxs) {
                return this.taxs.filter(function(tax) {
                    return tax.tax_coa_ar !== null || tax.tax_rate == 0;
                });
            }
        }
    },
    created() {
        this.getTypeAll();
        this.getUomBase();
        this.getCoaAll();
        this.getTaxAll();
        this.getForbiddenDate();
        if (this.$route.params.id) {
            this.editItem(this.$route.params.id)
                .then(response => {
                    response.item_status == 1
                        ? (this.checked = true)
                        : (this.checked = false);
                    response.is_inventory == 1
                        ? (this.checkedInventory = true)
                        : (this.checkedInventory = false);
                    response.is_purchase == 1
                        ? (this.checkedPurchase = true)
                        : (this.checkedPurchase = false);
                    response.is_sell == 1
                        ? (this.checkedSell = true)
                        : (this.checkedSell = false);
                    this.loadingPage = 0;
                })
                .catch(error => {
                    console.log(error);
                    this.loadingPage = 0;
                });
        } else {
            this.$router.push({ name: "products-services" });
            this.alert("Data not found, select data first", 2);
        }
    },
    methods: {
        //VUEX
        ...mapMutations("item", ["CLEAR_FORM"]),
        ...mapMutations(["CLEAR_ERRORS"]),
        ...mapActions("item", [
            "editItem",
            "updateItem",
            "removeItem",
            "printItem",
            "archiveItem",
            "unarchiveItem"
        ]),
        ...mapActions("coa", ["getCoaAll"]),
        ...mapActions("tax", ["getTaxAll"]),
        ...mapActions("itemtype", ["getTypeAll"]),
        ...mapActions("uom", ["getUomBase"]),
        ...mapActions("forbiddendate", ["getForbiddenDate"]),
        activeInactive() {
            this.checked == true
                ? (this.item.item_status = 1)
                : (this.item.item_status = 0);
        },
        activeInactiveSell() {
            this.checkedSell == true
                ? (this.item.is_sell = 1)
                : (this.item.is_sell = 0);
        },
        activeInactivePurchase() {
            this.checkedPurchase == true
                ? (this.item.is_purchase = 1)
                : (this.item.is_purchase = 0);
        },
        activeInactiveInventory() {
            this.checkedInventory == true
                ? (this.item.is_inventory = 1)
                : (this.item.is_inventory = 0);
            this.item.expense_coa_id == 0;
        },
        calculate() {
            this.item.opening_amount =
                this.item.opening_qty * this.item.opening_price;
        },
        disabledDatePicker(date) {
            const lastDisableDate = new Date(
                moment(this.forbiddenDateLast).add(1, "days")
            );
            lastDisableDate.setHours(0, 0, 0, 0);
            return date < lastDisableDate;
        },
        setItemType() {
            let obj = this.types.find(o => o.id == this.item.type_id);
            console.log(obj);
            this.item.is_inventory = obj.is_inventory;
            this.item.is_purchase = obj.is_purchase;
            this.item.is_sell = obj.is_sell;
            this.checkedInventory = obj.is_inventory == 1 ? true : false;
            this.checkedSell = obj.is_sell == 1 ? true : false;
            this.checkedPurchase = obj.is_purchase == 1 ? true : false;
            this.item.inventory_coa_id = obj.inventory_coa_id;
            this.item.income_coa_id = obj.income_coa_id;
            this.item.expense_coa_id = obj.expense_coa_id;
            this.item.sell_tax_id = obj.sell_tax_id;
            this.item.purchase_tax_id = obj.purchase_tax_id;
        },
        submitData() {
            this.loadingPage = 2;
            this.CLEAR_ERRORS();
            this.updateItem(this.$route.params.id)
                .then(response => {
                    this.CLEAR_FORM();
                    this.alert("Successfully update Item Data ", 1);
                    this.loadingPage = 0;
                    this.$router.push({
                        name: "products-services-detail",
                        params: { id: this.$route.params.id, warehouse: 1 }
                    });
                })
                .catch(error => {
                    error.response.status == 500
                        ? this.alert(error.response.data.message, 2)
                        : "";
                    error.response.status != 422 && error.response.status != 500
                        ? this.alert(error.message, 2)
                        : "";
                    this.loadingPage = 0;
                });
        },
        archiveData() {
            this.loadingPage = 2;
            this.CLEAR_ERRORS();
            this.archiveItem(this.$route.params.id)
                .then(response => {
                    this.CLEAR_FORM();
                    this.alert("Successfully archive Item Data ", 1);
                    this.loadingPage = 0;
                    this.$router.push({
                        name: "products-services-detail",
                        params: { id: this.$route.params.id, warehouse: 1 }
                    });
                })
                .catch(error => {
                    error.response.status == 500
                        ? this.alert(error.response.data.message, 2)
                        : "";
                    error.response.status != 422 && error.response.status != 500
                        ? this.alert(error.message, 2)
                        : "";
                    this.loadingPage = 0;
                });
        },
        unarchiveData() {
            this.loadingPage = 2;
            this.CLEAR_ERRORS();
            this.unarchiveItem(this.$route.params.id)
                .then(response => {
                    this.CLEAR_FORM();
                    this.alert("Successfully unarchive Item Data ", 1);
                    this.loadingPage = 0;
                    this.$router.push({
                        name: "products-services-detail",
                        params: { id: this.$route.params.id, warehouse: 1 }
                    });
                })
                .catch(error => {
                    error.response.status == 500
                        ? this.alert(error.response.data.message, 2)
                        : "";
                    error.response.status != 422 && error.response.status != 500
                        ? this.alert(error.message, 2)
                        : "";
                    this.loadingPage = 0;
                });
        },
        deleteData() {
            this.$swal({
                title: "Are you sure ?",
                text: "Deleted data cannot be recovery",
                icon: "warning",
                buttons: ["Cancel", "Delete"],
                dangerMode: true
            }).then(willDelete => {
                if (willDelete) {
                    this.loadingPage = 1;
                    this.removeItem(this.$route.params.id)
                        .then(() => {
                            this.alert("Data has been deleted !", 1);
                            this.loadingPage = 0;
                            this.$router.push({ name: "products-services" });
                        })
                        .catch(error => {
                            error.response.status == 500
                                ? this.alert(error.response.data.message, 2)
                                : "";
                            error.response.status != 422 &&
                            error.response.status != 500
                                ? this.alert(error.message, 2)
                                : "";
                            this.loadingPage = 0;
                        });
                }
            });
        }
    },
    destroyed() {
        this.CLEAR_FORM();
    }
};
</script>
