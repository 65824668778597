import $axios from "../api.js";

const state = () => ({
    register: {}
});

const mutations = {
    //ME-RESET STATE NEWS MENJADI KOSONG
    CLEAR_DATA(state) {
        state.register = [];
    }
};

const actions = {
    countVisit({ dispatch, commit, state }) {
        return new Promise((resolve, reject) => {
            $axios
                .post(`/count-visit`, state.register)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                });
        });
    },
    submitRegister({ dispatch, commit, state }) {
        return new Promise((resolve, reject) => {
            $axios
                .post(`/register`, state.register)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                });
        });
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
