var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('hero',{attrs:{"title":"Create Category","prevBreadcrumb":"Asset Category Data","routeBreadcrumb":"asset-category","currentBreadcrumb":"Create Category"}}),_vm._v(" "),_c('div',{staticClass:"content"},[(_vm.loadingPage == 1)?_c('div',{staticClass:"block col-12 col-md-8 offset-md-2 block-rounded block-mode-loading-oneui",staticStyle:{"height":"250px"}},[_c('div',{staticClass:"block-content block-content-full pb-0",staticStyle:{"padding-top":"80px"}},[_c('grid-loader',{staticClass:"mx-auto",attrs:{"color":'#70B9EB',"size":'10px'}}),_vm._v(" "),_c('center',{staticClass:"mt-2"},[_c('span',{staticClass:"text-center",staticStyle:{"opacity":"0.7"}},[_vm._v("Loading")])])],1)]):_vm._e(),_vm._v(" "),(_vm.loadingPage == 2)?_c('div',{staticClass:"block col-12 col-md-8 offset-md-2 block-rounded block-mode-loading-oneui",staticStyle:{"height":"250px"}},[_c('div',{staticClass:"block-content block-content-full pb-0",staticStyle:{"padding-top":"80px"}},[_c('grid-loader',{staticClass:"mx-auto",attrs:{"color":'#70B9EB',"size":'10px'}}),_vm._v(" "),_c('center',{staticClass:"mt-2"},[_c('span',{staticClass:"text-center",staticStyle:{"opacity":"0.7"}},[_vm._v("Save Data")])])],1)]):_vm._e(),_vm._v(" "),(_vm.loadingPage == 0)?_c('div',{staticClass:"block col-12 col-md-8 offset-md-2  block-rounded block-mode-loading-oneui"},[_c('div',{staticClass:"block-content block-content-full"},[_c('form',{attrs:{"method":"POST","enctype":"multipart/form-data","onsubmit":"return false;"}},[_c('div',{staticClass:"row font-size-sm"},[_c('div',{staticClass:"col-12 col-md-10 offset-md-1"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Category Name *")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.category.category_name),expression:"category.category_name"}],staticClass:"form-control",class:{
                                        'is-invalid': _vm.errors.category_name,
                                    },attrs:{"type":"text","placeholder":"Category Name"},domProps:{"value":(_vm.category.category_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.category, "category_name", $event.target.value)}}}),_vm._v(" "),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.errors.category_name)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                        "+_vm._s(_vm.errors.category_name[0])+"\n                                    ")]):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Depreciate Method *")]),_vm._v(" "),_c('selectize',{staticClass:"form-control mt-1",class:{
                                        'is-invalid-selectize':
                                            _vm.errors.depreciate_method,
                                    },attrs:{"placeholder":"Choose Account No."},model:{value:(_vm.category.depreciate_method),callback:function ($$v) {_vm.$set(_vm.category, "depreciate_method", $$v)},expression:"category.depreciate_method"}},[_c('option',{attrs:{"value":"none"}},[_vm._v("\n                                        No. Depreciation\n                                    ")]),_vm._v(" "),_c('option',{attrs:{"value":"straight"}},[_vm._v("\n                                        Straight Line\n                                    ")])]),_vm._v(" "),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.errors.depreciate_method)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                        "+_vm._s(_vm.errors.depreciate_method[0])+"\n                                    ")]):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Useful Life (Year) *")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.category.year_life),expression:"category.year_life"}],staticClass:"form-control",class:{
                                        'is-invalid': _vm.errors.year_life,
                                    },attrs:{"type":"text","placeholder":"Useful Life","maxlength":"2"},domProps:{"value":(_vm.category.year_life)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.category, "year_life", $event.target.value)},function($event){return _vm.mustNumber()}]}}),_vm._v(" "),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.errors.year_life)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                        "+_vm._s(_vm.errors.year_life[0])+"\n                                    ")]):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Asset Account *")]),_vm._v(" "),_c('selectize',{staticClass:"form-control mt-1",class:{
                                        'is-invalid-selectize':
                                            _vm.errors.coa_id,
                                    },attrs:{"placeholder":"Choose Account No."},model:{value:(_vm.category.coa_id),callback:function ($$v) {_vm.$set(_vm.category, "coa_id", $$v)},expression:"category.coa_id"}},_vm._l((_vm.assetAccounts),function(account){return _c('option',{key:account.id,domProps:{"value":account.id}},[_vm._v("\n                                        "+_vm._s(account.account_no)+"\n                                        "+_vm._s(account.account_name)+"\n                                    ")])}),0),_vm._v(" "),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.errors.coa_id)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                        "+_vm._s(_vm.errors.coa_id[0])+"\n                                    ")]):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Depreciation Account *")]),_vm._v(" "),_c('selectize',{staticClass:"form-control mt-1",class:{
                                        'is-invalid-selectize':
                                            _vm.errors.depreciation_coa_id,
                                    },attrs:{"placeholder":"Choose Account No."},model:{value:(
                                        _vm.category.depreciation_coa_id
                                    ),callback:function ($$v) {_vm.$set(_vm.category, "depreciation_coa_id", $$v)},expression:"\n                                        category.depreciation_coa_id\n                                    "}},_vm._l((_vm.depreciationAccounts),function(account){return _c('option',{key:account.id,domProps:{"value":account.id}},[_vm._v("\n                                        "+_vm._s(account.account_no)+"\n                                        "+_vm._s(account.account_name)+"\n                                    ")])}),0),_vm._v(" "),_c('transition',{attrs:{"name":"slide-fade"}},[(
                                            _vm.errors.depreciation_coa_id
                                        )?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                        "+_vm._s(_vm.errors
                                                .depreciation_coa_id[0])+"\n                                    ")]):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Accumulated Account *")]),_vm._v(" "),_c('selectize',{staticClass:"form-control mt-1",class:{
                                        'is-invalid-selectize':
                                            _vm.errors.accumulated_coa_id,
                                    },attrs:{"placeholder":"Choose Account No."},model:{value:(
                                        _vm.category.accumulated_coa_id
                                    ),callback:function ($$v) {_vm.$set(_vm.category, "accumulated_coa_id", $$v)},expression:"\n                                        category.accumulated_coa_id\n                                    "}},_vm._l((_vm.accumulatedAccounts),function(account){return _c('option',{key:account.id,domProps:{"value":account.id}},[_vm._v("\n                                        "+_vm._s(account.account_no)+"\n                                        "+_vm._s(account.account_name)+"\n                                    ")])}),0),_vm._v(" "),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.errors.accumulated_coa_id)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                        "+_vm._s(_vm.errors.accumulated_coa_id[0])+"\n                                    ")]):_vm._e()])],1)])]),_vm._v(" "),_c('div',{staticClass:"row items-push"},[_c('div',{staticClass:"col-12 text-center"},[_c('p',{staticClass:"font-size-sm text-muted"},[_vm._v("\n                                * Required filled, Make sure all data is\n                                correctly filled\n                            ")]),_vm._v(" "),_c('button',{directives:[{name:"ripple",rawName:"v-ripple",value:({ center: true }),expression:"{ center: true }"}],staticClass:"btn btn-sm btn-success",attrs:{"type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('i',{staticClass:"fa fa-fw fa-check mr-1"}),_vm._v(" Save\n                            ")]),_vm._v(" "),_c('router-link',{directives:[{name:"ripple",rawName:"v-ripple",value:({ center: true }),expression:"{ center: true }"}],staticClass:"btn btn-sm btn-alt-light",attrs:{"type":"button","tag":"button","to":{ name: 'asset-category' }}},[_c('i',{staticClass:"fa fa-fw fa-times mr-1"}),_vm._v(" Close\n                            ")])],1)])])])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }