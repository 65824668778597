<template>
    <main>
        <hero title="Detail Receive Payment" prevBreadcrumb="Receive Payment" routeBreadcrumb="receive-payment" currentBreadcrumb="Detail Receive Payment"></hero>

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <!-- Invoice -->
            <div class="block block-rounded block-mode-loading-oneui mb-2">
                <div class="block-header">
                    <h3 class="block-title">{{ receivepayment.payment_no }}</h3>
                    <div class="block-options">
                        <router-link
                            :to="{
                                name: 'journal-transaction-voucher',
                                params: { id: receivepayment.journal.id },
                            }"
                            tag="button"
                            type="button"
                            class="btn-block-option text-warning"
                        >
                            <i class="si si-notebook mr-1"></i>
                            <span class="d-none d-md-inline-block">
                                Journal Voucher
                            </span>
                        </router-link>
                        <router-link
                            v-if="receivepayment.adjustment != null"
                            :to="{
                                name: 'journal-transaction-voucher',
                                params: { id: receivepayment.adjustment.id },
                            }"
                            tag="button"
                            type="button"
                            class="btn-block-option text-warning"
                        >
                            <i class="si si-notebook mr-1"></i>
                            <span class="d-none d-md-inline-block">
                                Adjustment Payment
                            </span>
                        </router-link>
                        <router-link
                            :to="{
                                name: 'receive-payment-edit',
                                params: { id: this.receivepayment.payment_id },
                            }"
                            tag="button"
                            type="button"
                            class="btn-block-option text-success"
                        >
                            <i class="si si-pencil mr-1"></i>
                            <span class="d-none d-md-inline-block">
                                Edit Data
                            </span>
                        </router-link>
                        <!-- Print Page functionality is initialized in Helpers.print() -->
                        <button
                            type="button"
                            class="btn-block-option text-info"
                            @click="print()"
                        >
                            <i class="si si-printer mr-1"></i>
                            <span class="d-none d-md-inline-block"
                                >Print Document</span
                            >
                        </button>
                    </div>
                </div>
            </div>
            <div class="block bg-transparent mb-2">
                <div class="block-content pt-0 bg-transparent text-right">
                    <input
                        class="form-check-input"
                        style="margin-top:3.5px"
                        type="checkbox"
                        v-model="useHeader"
                    />
                    <label class="form-check-label">Default Header</label>
                </div>
            </div>
            <!--Loading-->
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 1"
                style="height:250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <!-- Basic -->
            <div class="block block-rounded block-mode-loading-oneui" v-else>
                <div class="content content-boxed" id="printMe">
                    <div class="block-content">
                        <div class="p-0 p-md-5" style="min-height: 800px">
                            <div class="row pt-0" v-if="useHeader">
                                <div class="col-6 font-size-sm pb-0 pt-0">
                                    <p class="h3 mb-2 mt-3 text-uppercase">
                                        {{
                                            auth.data.workspace
                                                .workspace_company_name
                                        }}
                                    </p>
                                    <p class="mb-0">
                                        {{
                                            auth.data.workspace
                                                .workspace_company_address
                                        }}
                                    </p>
                                    <p class="mb-0">
                                        <span
                                            v-if="
                                                auth.data.workspace
                                                    .workspace_company_province !==
                                                    '' &&
                                                auth.data.workspace
                                                    .workspace_company_province !==
                                                    null
                                            "
                                        >
                                            {{
                                                auth.data.workspace
                                                    .workspace_company_province
                                            }}
                                        </span>
                                        <span
                                            v-if="
                                                auth.data.workspace
                                                    .workspace_company_postal !==
                                                    '' &&
                                                auth.data.workspace
                                                    .workspace_company_postal !==
                                                    null
                                            "
                                        >
                                            ,
                                            {{
                                                auth.data.workspace
                                                    .workspace_company_postal
                                            }}
                                        </span>
                                    </p>
                                    <p class="mb-0">
                                        <span
                                            v-if="
                                                auth.data.workspace
                                                    .workspace_company_phone !==
                                                    '' &&
                                                auth.data.workspace
                                                    .workspace_company_phone !==
                                                    null
                                            "
                                        >
                                            {{
                                                auth.data.workspace
                                                    .workspace_company_phone
                                            }}
                                        </span>
                                        <span
                                            v-if="
                                                auth.data.workspace
                                                    .workspace_company_email !==
                                                    '' &&
                                                auth.data.workspace
                                                    .workspace_company_email !==
                                                    null
                                            "
                                        >
                                            {{
                                                "| " +
                                                auth.data.workspace
                                                    .workspace_company_email
                                            }}
                                        </span>
                                    </p>
                                </div>
                                <div
                                    class="col-6 text-right font-size-sm pb-0 pt-0"
                                >
                                    <img
                                        :src="
                                            '/storage/workspace/' +
                                            auth.data.workspace
                                                .workspace_company_logo
                                        "
                                        alt=""
                                        style="
                                            min-heigh: 100px;
                                            max-height: 120px;
                                            max-width: 180px;
                                        "
                                        @error="errorLogoUrl"
                                    />
                                </div>
                            </div>
                            <hr class="mb-3" v-if="useHeader"/>
                            <div class="row mt-3">
                                <p
                                    class="h2 mx-auto text-uppercase text-center"
                                >
                                    Receive Payment
                                </p>
                            </div>
                            <div class="row mb-5">
                                <div class="col-6 text-left font-size-sm">
                                    <p class="mb-1">Receive From :</p>
                                    <p class="font-w700 mb-0 text-uppercase">
                                        {{
                                            receivepayment.contact.contact_name
                                        }}
                                    </p>
                                    <p class="mb-0">
                                        {{
                                            receivepayment.contact
                                                .contact_address
                                        }}
                                    </p>
                                    <p class="mb-0">
                                        <span
                                            v-if="
                                                receivepayment.contact
                                                    .contact_province !== '' &&
                                                receivepayment.contact
                                                    .contact_province !== null
                                            "
                                        >
                                            {{
                                                receivepayment.contact
                                                    .contact_province
                                            }}
                                        </span>
                                        <span
                                            v-if="
                                                receivepayment.contact
                                                    .contact_postalcode !==
                                                    '' &&
                                                receivepayment.contact
                                                    .contact_postalcode !== null
                                            "
                                        >
                                            ,
                                            {{
                                                receivepayment.contact
                                                    .contact_postalcode
                                            }}
                                        </span>
                                    </p>
                                    <p class="mb-0">
                                        <span
                                            v-if="
                                                receivepayment.contact
                                                    .contact_contactphone !==
                                                    '' &&
                                                receivepayment.contact
                                                    .contact_contactphone !==
                                                    null
                                            "
                                        >
                                            {{
                                                receivepayment.contact
                                                    .contact_contactphone
                                            }}
                                        </span>
                                        <span
                                            v-if="
                                                receivepayment.contact
                                                    .contact_email !== '' &&
                                                receivepayment.contact
                                                    .contact_email !== null &&
                                                receivepayment.contact
                                                    .contact_email !== '-'
                                            "
                                        >
                                            {{
                                                "| " +
                                                receivepayment.contact
                                                    .contact_email
                                            }}
                                        </span>
                                    </p>
                                </div>
                                <div class="col-6 font-size-sm text-right">
                                    <p class="mb-0 font-w700 mt-3">
                                        No. Receive :
                                        {{ receivepayment.payment_no }}
                                    </p>
                                    <p class="mb-0">
                                        Date :
                                        {{
                                            receivepayment.payment_date
                                                | moment("DD MMMM YYYY")
                                        }}
                                    </p>
                                </div>
                            </div>

                            <!-- Table -->
                            <div class="table-responsive push">
                                <table class="table table-bordered">
                                    <thead class="bg-body-light">
                                        <tr>
                                            <th
                                                class="text-center"
                                                style="width: 60px"
                                            >
                                                No.
                                            </th>
                                            <th class="text-center">
                                                Description
                                            </th>
                                            <th
                                                class="text-center"
                                                style="width: 20%"
                                            >
                                                Amount
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class="font-print-md">
                                        <tr
                                            v-for="(
                                                paymentBill, index
                                            ) in receivepayment.payment_bills"
                                            :key="index"
                                        >
                                            <td class="text-center">
                                                {{ ++index }}
                                            </td>
                                            <td>
                                                <p class="font-w600 mb-1">
                                                    Invoice
                                                    {{
                                                        paymentBill.bill
                                                            .bill_invoice_number
                                                    }}
                                                </p>
                                            </td>
                                            <td class="text-right">
                                                {{
                                                    paymentBill.payment_amount
                                                        | currency
                                                }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                colspan="2"
                                                class="font-w700 text-uppercase text-right"
                                            >
                                                Payment Total
                                            </td>
                                            <td class="font-w700 text-right">
                                                {{
                                                    receivepayment.payment_total_applied
                                                        | currency
                                                }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!-- END Table -->

                            <div class="row mt-4 mb-5">
                                <div class="col-6 text-left font-size-sm pt-5 mt-2">
                                    <div class="form-group d-print-none">
                                        <label for="example-text-input"
                                            >Post Note *</label
                                        >
                                        <ckeditor
                                            tag-name="textarea"
                                            :editor="editor"
                                            v-model="docPostNote"
                                            :config="editorConfig"
                                        ></ckeditor>
                                    </div>
                                    <span
                                        class="d-none d-print-inline-block"
                                        v-html="docPostNote"
                                    ></span>
                                </div>
                                <div class="col-6 font-size-sm text-right pt-5 mt-2">
                                    <div
                                        class="form-group text-left d-print-none"
                                    >
                                        <label for="example-text-input"
                                            >Choose Signature *</label
                                        >
                                        <selectize
                                            class="form-control"
                                            placeholder="Choose Signature"
                                            v-model="selectedSignature"
                                            @input="
                                                selectSignature(
                                                    selectedSignature
                                                )
                                            "
                                        >
                                            <option
                                                v-for="signature in signatures.data"
                                                :value="signature.id"
                                                :key="signature.id"
                                            >
                                                {{ signature.signature_name }}
                                            </option>
                                        </selectize>
                                    </div>
                                    <p
                                        class="mb-0 font-w700 mt-6 ml-9 text-center"
                                        style="border-bottom: 1px solid grey"
                                        v-bind:class="{
                                            'd-print-none':
                                                selectedSignature == '',
                                        }"
                                    >
                                        {{ docSignature.signature_name }}
                                    </p>
                                    <p
                                        class="mb-0 font-w700 ml-9 text-center"
                                        v-bind:class="{
                                            'd-print-none':
                                                selectedSignature == '',
                                        }"
                                    >
                                        {{ docSignature.signature_position }}
                                    </p>
                                </div>
                            </div>

                            <!-- Footer -->
                            <hr />
                            <p
                                class="d-none d-print-inline-block"
                                style="opacity: 0.6"
                            >
                                <i> Printed : {{ dateNow }} </i>
                            </p>
                            <!-- END Footer -->
                        </div>
                    </div>
                </div>
                <!-- END Invoice -->
            </div>
            <!-- END Basic -->
        </div>
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import moment from "moment";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
    name: "print-receive-payment",
    data() {
        return {
            loadingPage: 1,
            disabled: {},
            dateOriginal: "",
            useHeader: true,
            forbiddenData: false,
            dateNow: moment().format("MMMM Do YYYY, HH:mm:ss"),
            selectedSignature: "",
            docSignature: {
                signature_name: "[select signature]",
                signature_position: "[select signature]",
            },
            editor: ClassicEditor,
            editorConfig: {
                toolbar: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "bulletedList",
                    "numberedList",
                    "blockQuote",
                    "undo",
                    "redo",
                    "insertTable",
                ],
            },
            docPostNote: "",
        };
    },
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState(["auth"]),
        ...mapState("receivepayment", {
            receivepayment: (state) => state.receivepayment,
        }),
        ...mapState("signature", { signatures: (state) => state.signatures }),
    },
    created() {
        if (this.$route.params.id) {
            this.editReceivePayment(this.$route.params.id)
                .then((response) => {
                    this.$session.set(
                        "receivepayment_detail",
                        response.data.id
                    );
                    this.loadingPage = 0;
                })
                .catch((error) => {
                    this.alert(error.message, 2);
                    this.loadingPage = 0;
                });
        } else if (this.$session.has("receivepayment_detail")) {
            this.editReceivePayment(this.$session.get("receivepayment_detail"))
                .then((response) => {
                    this.loadingPage = 0;
                })
                .catch((error) => {
                    this.alert(error.message, 2);
                    this.loadingPage = 0;
                });
        } else {
            this.$router.push({ name: "receive-payment" });
            this.alert("Data not found, select data first", 2);
        }
        this.getSignatureDocument({ document: "signature_receive_payment" })
            .then((response) => {
                this.loadingPage = false;
            })
            .catch((error) => {
                console.log(error);
                this.loadingPage = false;
            });
    },
    methods: {
        //VUEX
        ...mapMutations("receivepayment", ["CLEAR_FORM"]),
        ...mapMutations(["CLEAR_ERRORS"]),
        ...mapActions("forbiddendate", ["getForbiddenDate"]),
        ...mapActions("receivepayment", [
            "getReceivePaymentNo",
            "editReceivePayment",
            "updateReceivePayment",
            "removeReceivePayment",
        ]),
        ...mapActions("signature", ["getSignatureDocument"]),

        selectSignature(val) {
            if (val != "") {
                var result = this.signatures.data.find((obj) => {
                    return obj.id === parseInt(val);
                });
                this.docSignature.signature_name = result.signature_name;
                this.docSignature.signature_position =
                    result.signature_position;
            } else {
                this.docSignature.signature_name = "[select signature]";
                this.docSignature.signature_position = "[select signature]";
            }
        },
        errorLogoUrl(event) {
            event.target.src = "/media/workspace/no-logo.jpg";
        },
        print() {
            var browserName = navigator.appName;
            if (browserName == "Safari") {
                var printContents = document.getElementById("printMe")
                    .innerHTML;
                var originalContents = document.body.innerHTML;
                document.body.innerHTML = printContents;
                window.print();
                document.body.innerHTML = originalContents;
            } else {
                this.$htmlToPaper("printMe");
            }
        },
    },
    destroyed() {
        this.CLEAR_FORM();
    },
};
</script>
