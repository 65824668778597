import $axios from "../../api.js";
import SecureLS from "secure-ls";
var ls = new SecureLS({ isCompression: false, encryptionSecret: "d3v3L0P3r!" });

const state = () => ({
    workspaces: ls.get("_wk_spc_"), //UNTUK MENAMPUNG DATA PROJECTS YANG DIDAPATKAN DARI DATABASE

    //UNTUK MENAMPUNG VALUE DARI FORM INPUTAN NANTINYA
    //STATE INI AKAN DIGUNAKAN PADA FORM ADD PROJECT YANG AKAN DIBAHAS KEMUDIAN
    workspace: {
        workspace_name: "",
        workspace_company_name: "",
        workspace_company_address: "",
        workspace_company_province: "",
        workspace_company_postal: "",
        workspace_company_phone: "",
        workspace_company_email: "",
        workspace_company_logo: "",
        workspace_company_banner: "",
        user: ls.get("_ath_")
    }
});

const mutations = {
    //MEMASUKKAN DATA KE STATE PROJECTS
    ASSIGN_DATA(state, payload) {
        state.workspaces = payload;
    },
    //MENGUBAH DATA STATE PROJECT
    ASSIGN_FORM(state, payload) {
        state.workspace = {
            workspace_name: payload.workspace_name,
            workspace_company_name: payload.workspace_company_name,
            workspace_company_address: payload.workspace_company_address,
            workspace_company_province: payload.workspace_company_province,
            workspace_company_postal: payload.workspace_company_postal,
            workspace_company_phone: payload.workspace_company_phone,
            workspace_company_email: payload.workspace_company_email,
            workspace_company_logo: payload.workspace_company_logo,
            workspace_company_banner: payload.workspace_company_banner,
            user: ls.get("_ath_")
        };
    },
    //ME-RESET STATE PROJECT MENJADI KOSONG
    CLEAR_FORM(state) {
        state.workspace = {
            workspace_name: "",
            workspace_company_name: "",
            workspace_company_address: "",
            workspace_company_province: "",
            workspace_company_postal: "",
            workspace_company_phone: "",
            workspace_company_email: "",
            workspace_company_logo: "",
            workspace_company_banner: "",
            user: ls.get("_ath_")
        };
    },
    //ME-RESET STATE PROJECT MENJADI KOSONG
    CLEAR_DATA(state) {
        state.workspaces = [];
    }
};

const actions = {
    //FUNGSI INI UNTUK MELAKUKAN REQUEST DATA PROJECT DARI SERVER
    getWorkspaces({ dispatch, commit }) {
        return new Promise((resolve, reject) => {
            //GET USER SCHEMA LIST
            $axios
                .get(`/workspace-list`)
                .then(response => {
                    ls.set("_wk_spc_", response.data);
                    resolve(response.data);
                    commit("ASSIGN_DATA", response.data);
                })
                .catch(error => {
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                    reject(error);
                });
        });
    },
    //FUNGSI INI UNTUK MELAKUKAN REQUEST DATA PROJECT DARI SERVER
    getWorkspace({ dispatch, commit }, payload) {
        return new Promise((resolve, reject) => {
            //MELAKUKAN REQUEST DENGAN MENGIRIMKAN CODE USER DI URL
            $axios
                .get(`/workspace/${payload}/edit`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    //FUNGSI UNTUK MENAMBAHKAN DATA BARU
    submitWorkspace({ dispatch, commit, state }) {
        return new Promise((resolve, reject) => {
            //BIKIN PROJECT BARU
            $axios
                .post(`/workspace-builder`, state.workspace)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                    reject(error);
                });
        });
    },
    //FUNGSI UNTUK PINDAH PROJECT
    changeWorkspace({ state, commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            //MENGIRIMKAN PERMINTAAN KE SERVER DAN MELAMPIRKAN DATA YANG AKAN DISIMPAN
            $axios
                .post(`/workspace-change?workspace=${payload}`)
                .then(response => {
                    ls.set("_ath_", response.data.data);
                    ls.set("_rl_", response.data.role);
                    commit(
                        "SET_AUTH",
                        {
                            data: response.data.data,
                            role: response.data.role,
                            daylimit: ls.get("_dl_"),
                            type: ls.get("_tp_")
                        },
                        {
                            root: true
                        }
                    );
                    resolve(response.data);
                    // dispatch('getWorkspace', response.data.data.workspace_token) ;
                })
                .catch(error => {
                    reject(error);
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                });
        });
    },
    //FUNGSI UNTUK MENAMBAHKAN USER BARU
    assignUserWorkspace({ dispatch, commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .post(`/workspace/assign-user`, payload)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                });
        });
    },
    //FUNGSI UNTUK MENAMBAHKAN USER BARU
    deleteUserWorkspace({ dispatch, commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .post(`/workspace/delete-user`, payload)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                });
        });
    },
    //UNTUK MENGUPDATE DATA BERDASARKAN CODE YANG SEDANG DIEDIT
    updateWorkspace({ state, commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .post(`/workspace/update`, payload)
                .then(response => {
                    ls.set("_ath_", response.data.data);
                    commit(
                        "SET_AUTH",
                        {
                            data: response.data.data,
                            role: response.data.role,
                            daylimit: ls.get("_dl_"),
                            type: ls.get("_tp_")
                        },
                        {
                            root: true
                        }
                    );
                    commit("CLEAR_FORM");
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                });
        });
    },
    //DELETE DATA WORKSPACE
    deleteWorkspace({ dispatch, state, commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .post(`/workspace/delete`, payload)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                });
        });
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
