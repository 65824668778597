var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('hero',{attrs:{"title":"Create Warehouse","prevBreadcrumb":"Warehouse Data","routeBreadcrumb":"warehouse","currentBreadcrumb":"Create Warehouse"}}),_vm._v(" "),_c('div',{staticClass:"content"},[(_vm.loadingPage == 1)?_c('div',{staticClass:"block col-12 col-md-8 offset-md-2 block-rounded block-mode-loading-oneui",staticStyle:{"height":"250px"}},[_c('div',{staticClass:"block-content block-content-full pb-0",staticStyle:{"padding-top":"80px"}},[_c('grid-loader',{staticClass:"mx-auto",attrs:{"color":'#70B9EB',"size":'10px'}}),_vm._v(" "),_c('center',{staticClass:"mt-2"},[_c('span',{staticClass:"text-center",staticStyle:{"opacity":"0.7"}},[_vm._v("Loading")])])],1)]):_vm._e(),_vm._v(" "),(_vm.loadingPage == 2)?_c('div',{staticClass:"block col-12 col-md-8 offset-md-2 block-rounded block-mode-loading-oneui",staticStyle:{"height":"250px"}},[_c('div',{staticClass:"block-content block-content-full pb-0",staticStyle:{"padding-top":"80px"}},[_c('grid-loader',{staticClass:"mx-auto",attrs:{"color":'#70B9EB',"size":'10px'}}),_vm._v(" "),_c('center',{staticClass:"mt-2"},[_c('span',{staticClass:"text-center",staticStyle:{"opacity":"0.7"}},[_vm._v("Save Data")])])],1)]):_vm._e(),_vm._v(" "),(_vm.loadingPage == 0)?_c('div',{staticClass:"block col-12 col-md-8 offset-md-2  block-rounded block-mode-loading-oneui"},[_c('div',{staticClass:"block-content block-content-full"},[_c('form',{attrs:{"method":"POST","enctype":"multipart/form-data","onsubmit":"return false;"}},[_c('div',{staticClass:"row font-size-sm"},[_c('div',{staticClass:"col-12 col-md-10 offset-md-1"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Warehouse Name *")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.warehouse.warehouse_name),expression:"warehouse.warehouse_name"}],staticClass:"form-control",class:{
                                        'is-invalid': _vm.errors.warehouse_name,
                                    },attrs:{"type":"text","placeholder":"Warehouse Name"},domProps:{"value":(_vm.warehouse.warehouse_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.warehouse, "warehouse_name", $event.target.value)}}}),_vm._v(" "),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.errors.warehouse_name)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                        "+_vm._s(_vm.errors.warehouse_name[0])+"\n                                    ")]):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Warehouse Location *")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.warehouse.warehouse_location),expression:"warehouse.warehouse_location"}],staticClass:"form-control",class:{
                                        'is-invalid': _vm.errors.warehouse_location,
                                    },attrs:{"type":"text","placeholder":"Warehouse Location"},domProps:{"value":(_vm.warehouse.warehouse_location)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.warehouse, "warehouse_location", $event.target.value)}}}),_vm._v(" "),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.errors.warehouse_location)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                        "+_vm._s(_vm.errors.warehouse_location[0])+"\n                                    ")]):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Workspace Status")]),_vm._v(" "),_c('selectize',{staticClass:"form-control mt-1",class:{
                                        'is-invalid-selectize':
                                            _vm.errors.warehouse_status,
                                    },attrs:{"placeholder":"Choose Workspace Status"},model:{value:(_vm.warehouse.warehouse_status),callback:function ($$v) {_vm.$set(_vm.warehouse, "warehouse_status", $$v)},expression:"warehouse.warehouse_status"}},[_c('option',{attrs:{"value":"Active"}},[_vm._v("\n                                        Active\n                                    ")]),_vm._v(" "),_c('option',{attrs:{"value":"Inactive"}},[_vm._v("\n                                        Inactive\n                                    ")])]),_vm._v(" "),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.errors.warehouse_status)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                        "+_vm._s(_vm.errors.warehouse_status[0])+"\n                                    ")]):_vm._e()])],1)])]),_vm._v(" "),_c('div',{staticClass:"row items-push"},[_c('div',{staticClass:"col-12 text-center"},[_c('p',{staticClass:"font-size-sm text-muted"},[_vm._v("\n                                * Required filled, Make sure all data is\n                                correctly filled\n                            ")]),_vm._v(" "),_c('button',{directives:[{name:"ripple",rawName:"v-ripple",value:({ center: true }),expression:"{ center: true }"}],staticClass:"btn btn-sm btn-success",attrs:{"type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('i',{staticClass:"fa fa-fw fa-check mr-1"}),_vm._v(" Save\n                            ")]),_vm._v(" "),_c('router-link',{directives:[{name:"ripple",rawName:"v-ripple",value:({ center: true }),expression:"{ center: true }"}],staticClass:"btn btn-sm btn-alt-light",attrs:{"type":"button","tag":"button","to":{ name: 'warehouse' }}},[_c('i',{staticClass:"fa fa-fw fa-times mr-1"}),_vm._v(" Close\n                            ")])],1)])])])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }