<template>
    <main>
        <hero
            title="Bill Register"
            prevBreadcrumb="Dashboard"
            routeBreadcrumb="home"
            currentBreadcrumb="Bill Register"
        ></hero>

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 1 || !datas"
                style="height:250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity:0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <!-- Dynamic Table Full -->
            <div class="block block-rounded block-mode-loading-oneui" v-else>
                <v-tabs
                    background-color="white"
                    style="border-radius: 8px;"
                    v-model="tab"
                >
                    <v-tab
                        v-for="item in items"
                        :key="item"
                        @click="typeChange(item)"
                        :disabled="loadingPage == 2"
                        v-bind:class="{
                            'v-tab--active': item === type,
                            'v-tab--active--false': item !== type
                        }"
                    >
                        {{ item }}
                    </v-tab>
                </v-tabs>
                <div class="block-content block-content-full pb-0 pt-3">
                    <div class="row">
                        <div
                            class="col-12 col-md-7 col-lg-7 col-xl-7 mb-2 mb-sm-0 pb-0 px-0 d-md-none d-inline"
                        >
                            <label class="col-12 col-md-1 py-0">Date :</label>
                            <date-picker
                                v-model="date_start"
                                type="date"
                                format="DD/MM/YYYY"
                                valueType="YYYY-MM-DD"
                                placeholder="Datepicker"
                                class="col-12"
                            ></date-picker>
                            <date-picker
                                v-model="date_end"
                                type="date"
                                format="DD/MM/YYYY"
                                valueType="YYYY-MM-DD"
                                placeholder="Datepicker"
                                class="col-12"
                            ></date-picker>
                            <button
                                type="button"
                                class="btn btn-sm btn-info float-right mb-5 mb-md-0"
                                tag="button"
                                v-ripple="{ center: true }"
                                style="margin-top: 2px;"
                                @click="getData()"
                                :disabled="
                                    loadingPage == 2 ||
                                        date_start == null ||
                                        date_end == null
                                "
                            >
                                <i class="fa fa-calendar"></i> Apply Date
                            </button>
                        </div>
                        <div
                            class="col-12 col-md-7 col-lg-7 col-xl-7 mb-2 mb-sm-0 pb-0 d-md-inline d-none"
                        >
                            <label>Date :</label>
                            <date-picker
                                v-model="date_start"
                                type="date"
                                format="DD/MM/YYYY"
                                valueType="YYYY-MM-DD"
                                placeholder="Datepicker"
                            ></date-picker>
                            <date-picker
                                v-model="date_end"
                                type="date"
                                format="DD/MM/YYYY"
                                valueType="YYYY-MM-DD"
                                placeholder="Datepicker"
                            ></date-picker>
                            <button
                                type="button"
                                class="btn btn-sm btn-info ml-1 mb-5 mb-md-1"
                                tag="button"
                                v-ripple="{ center: true }"
                                @click="getData()"
                                :disabled="
                                    loadingPage == 2 ||
                                        date_start == null ||
                                        date_end == null
                                "
                            >
                                <i class="fa fa-calendar mr-1"></i> Apply Date
                            </button>
                        </div>
                        <div
                            class="col-12 col-md-5 col-lg-5 col-xl-5 pb-0 pt-0 pt-md-3"
                        >
                            <input
                                type="text"
                                class="form-control form-control col-12 col-md-8 float-right search-index"
                                name="example-text-input"
                                placeholder="Search.."
                                v-model="search"
                            />
                        </div>
                    </div>
                </div>
                <div class="block-content block-content-full py-2">
                    <v-app>
                        <v-data-table
                            :headers="headers"
                            :items="datas"
                            :search="search"
                            :hide-default-footer="true"
                            :items-per-page="50"
                            :mobile-breakpoint="0"
                        >
                            <template
                                v-slot:top="{
                                    pagination,
                                    options,
                                    updateOptions
                                }"
                            >
                                <div class="row">
                                    <div
                                        class="col-12 col-md-7 mb-2 mb-sm-0 pt-0 pb-2"
                                    >
                                        <v-layout justify-start>
                                            <v-data-footer
                                                :pagination="pagination"
                                                :options="options"
                                                @update:options="updateOptions"
                                                :items-per-page-options="[
                                                    10,
                                                    30,
                                                    50,
                                                    100,
                                                    -1
                                                ]"
                                                items-per-page-text="Rows :"
                                            />
                                        </v-layout>
                                    </div>
                                    <div
                                        class="col-12 col-md-5 col-lg-5 col-xl-5 pb-0 pt-2"
                                    >
                                        <button
                                            type="button"
                                            class="btn btn-sm btn-alt-secondary float-right m-1 mb-5 mb-md-0"
                                            tag="button"
                                            v-ripple="{ center: true }"
                                            style="vertical-align: middle;"
                                            @click="getData()"
                                            :disabled="loadingPage == 2"
                                        >
                                            <i class="fa fa-sync"></i>
                                        </button>
                                        <router-link
                                            v-if="type == 'All Sales'"
                                            type="button"
                                            class="btn btn-sm btn-info float-right btn-adjust-1"
                                            :to="{
                                                name: 'bill-enter',
                                                params: { type: 'Quotation' }
                                            }"
                                            tag="button"
                                            v-ripple="{ center: true }"
                                            :disabled="loadingPage == 2"
                                        >
                                            <i class="fa fa-plus mr-1"></i>
                                            Enter Bill
                                        </router-link>
                                        <router-link
                                            v-if="type == 'Quotation'"
                                            type="button"
                                            class="btn btn-sm btn-info float-right btn-adjust-1"
                                            :to="{
                                                name: 'bill-enter',
                                                params: { type: 'Quotation' }
                                            }"
                                            tag="button"
                                            v-ripple="{ center: true }"
                                            :disabled="loadingPage == 2"
                                        >
                                            <i class="fa fa-plus mr-1"></i>
                                            Quotation
                                        </router-link>
                                        <router-link
                                            v-if="type == 'Order'"
                                            type="button"
                                            class="btn btn-sm btn-info float-right btn-adjust-1"
                                            :to="{
                                                name: 'bill-enter',
                                                params: { type: 'Order' }
                                            }"
                                            tag="button"
                                            v-ripple="{ center: true }"
                                            :disabled="loadingPage == 2"
                                        >
                                            <i class="fa fa-plus mr-1"></i>
                                            Order
                                        </router-link>
                                        <router-link
                                            v-if="type == 'Open Invoice'"
                                            type="button"
                                            class="btn btn-sm btn-info float-right btn-adjust-1"
                                            :to="{
                                                name: 'bill-enter',
                                                params: { type: 'Invoice' }
                                            }"
                                            tag="button"
                                            v-ripple="{ center: true }"
                                            :disabled="loadingPage == 2"
                                        >
                                            <i class="fa fa-plus mr-1"></i> Open
                                            Invoice
                                        </router-link>
                                    </div>
                                </div>
                            </template>
                            <template v-slot:body="{ items }">
                                <tbody v-if="loadingPage == 2">
                                    <tr class="data-empty">
                                        <td colspan="7" class="py-2">
                                            <grid-loader
                                                class="mx-auto mt-5 pt-5"
                                                :color="'#70B9EB'"
                                                :size="'10px'"
                                            ></grid-loader>
                                            <center class="mt-2 mb-5 pb-5">
                                                <span
                                                    class="text-center"
                                                    style="opacity:0.7"
                                                    >Loading</span
                                                >
                                            </center>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody
                                    v-if="items.length > 0 && loadingPage == 0"
                                >
                                    <tr
                                        v-for="(bill, index) in items"
                                        :key="index"
                                    >
                                        <td
                                            @click="toDetail(bill.billId)"
                                            class="text-top text-center"
                                        >
                                            {{ ++index }}
                                        </td>
                                        <td
                                            @click="toDetail(bill.billId)"
                                            class="text-top"
                                        >
                                            <!-- IF billNo is Array it means billNo use older no (bill_quotation_number, 
                                            bill_order_number, bill_invoice_number) if not Array it means
                                            billNo use new no (bill_number) -->
                                            <template
                                                v-if="
                                                    Array.isArray(bill.billNo)
                                                "
                                            >
                                                <span
                                                    v-if="bill.billStatus === 1"
                                                    >{{ bill.billNo[2] }}</span
                                                >
                                                <span
                                                    v-if="bill.billStatus === 2"
                                                >
                                                    {{ bill.billNo[1] }}
                                                    <span
                                                        style="opacity:0.4;"
                                                        v-if="
                                                            bill.billNo[2] !==
                                                                null
                                                        "
                                                    >
                                                        <br />
                                                        {{ bill.billNo[0] }}
                                                    </span>
                                                </span>
                                                <span
                                                    v-if="bill.billStatus > 2"
                                                >
                                                    {{ bill.billNo[0] }}
                                                    <span
                                                        style="opacity:0.4;"
                                                        v-if="
                                                            bill.billNo[1] !==
                                                                null
                                                        "
                                                    >
                                                        <br />
                                                        {{ bill.billNo[1] }}
                                                    </span>
                                                    <span
                                                        style="opacity:0.4;"
                                                        v-if="
                                                            bill.billNo[2] !==
                                                                null
                                                        "
                                                    >
                                                        <br />
                                                        {{ bill.billNo[2] }}
                                                    </span>
                                                </span>
                                            </template>
                                            <span v-else>{{
                                                bill.billNo
                                            }}</span>
                                        </td>
                                        <td
                                            @click="toDetail(bill.billId)"
                                            class="text-top"
                                            align="center"
                                        >
                                            {{ bill.billDate }}
                                        </td>
                                        <td
                                            @click="toDetail(bill.billId)"
                                            class="text-top"
                                            align="center"
                                            v-if="
                                                type == 'Open Invoice' ||
                                                    type == 'Order'
                                            "
                                        >
                                            {{ bill.billDueDate }}
                                        </td>
                                        <td
                                            @click="toDetail(bill.billId)"
                                            class="text-top"
                                            style="max-width:300px;"
                                        >
                                            <span v-html="bill.billContact">
                                            </span>
                                        </td>
                                        <td
                                            @click="toDetail(bill.billId)"
                                            class="text-top"
                                            align="right"
                                        >
                                            {{ bill.billAmount | currency }}
                                        </td>
                                        <td
                                            @click="toDetail(bill.billId)"
                                            class="text-top"
                                            align="right"
                                            v-if="
                                                type == 'Open Invoice' ||
                                                    type == 'Order'
                                            "
                                        >
                                            {{ bill.billPaid | currency }}
                                        </td>
                                        <td
                                            @click="toDetail(bill.billId)"
                                            class="text-top"
                                            align="right"
                                            v-if="
                                                type == 'Open Invoice' ||
                                                    type == 'Order'
                                            "
                                        >
                                            <span
                                                v-if="
                                                    bill.billStatus == 2 ||
                                                        bill.billStatus == 3 ||
                                                        bill.billStatus == 4
                                                "
                                            >
                                                {{
                                                    bill.billAmountDue
                                                        | currency
                                                }}
                                            </span>
                                            <span v-else>
                                                -
                                            </span>
                                        </td>
                                        <td
                                            @click="toDetail(bill.billId)"
                                            class="text-top"
                                            align="center"
                                            v-if="type == 'All Sales'"
                                        >
                                            <span v-if="bill.billStatus === 1"
                                                >Quotation</span
                                            >
                                            <span
                                                v-else-if="
                                                    bill.billStatus === 2
                                                "
                                                >Order</span
                                            >
                                            <span
                                                v-else-if="
                                                    bill.billStatus === 5
                                                "
                                                >Closed Order</span
                                            >
                                            <span
                                                v-else-if="
                                                    bill.billStatus === 3
                                                "
                                                >Invoice</span
                                            >
                                            <span v-else>Closed Invoice</span>
                                        </td>
                                        <!-- DROPDOWN AURORA -->
                                        <td class="text-top" align="right">
                                            <div
                                                class="dropdown dropleft push m-0"
                                            >
                                                <button
                                                    type="button"
                                                    class="btn btn-sm btn-alt-secondary btn-transparent btn-rounded text--lighten-4"
                                                    id="dropdown-dropleft"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    <i
                                                        class="fa fa-fw fa-ellipsis-v"
                                                    ></i>
                                                </button>
                                                <div
                                                    class="dropdown-menu dropdown-menu-rounded  px-1"
                                                    aria-labelledby="dropdown-dropleft"
                                                >
                                                    <button
                                                        @click="
                                                            toDetail(
                                                                bill.billId
                                                            )
                                                        "
                                                        class="btn btn-sm btn-transparent btn-alt-info dropdown-item-horizontal text-info"
                                                        v-ripple="{
                                                            center: true
                                                        }"
                                                        style="position:inline-block"
                                                    >
                                                        <i
                                                            class="si si-magnifier mr-1"
                                                        ></i>
                                                        <span
                                                            class="d-none d-md-inline"
                                                            >Detail</span
                                                        >
                                                    </button>
                                                    <button
                                                        v-if="
                                                            bill.billStatus ==
                                                                3 ||
                                                                bill.billStatus ==
                                                                    4
                                                        "
                                                        @click="
                                                            toJournalVoucher(
                                                                bill.billJournalId
                                                            )
                                                        "
                                                        class="btn btn-sm btn-transparent btn-alt-warning dropdown-item-horizontal text-warning"
                                                        v-ripple="{
                                                            center: true
                                                        }"
                                                        style="position:inline-block"
                                                    >
                                                        <i
                                                            class="si si-notebook mr-1"
                                                        ></i>
                                                        <span
                                                            class="d-none d-md-inline"
                                                            >Journal</span
                                                        >
                                                    </button>
                                                    <button
                                                        @click="
                                                            toEdit(bill.billId)
                                                        "
                                                        class="btn btn-sm btn-transparent btn-alt-success dropdown-item-horizontal text-success"
                                                        v-ripple="{
                                                            center: true
                                                        }"
                                                        style="position:inline-block"
                                                    >
                                                        <i
                                                            class="si si-pencil mr-1"
                                                        ></i>
                                                        <span
                                                            class="d-none d-md-inline"
                                                            >Edit</span
                                                        >
                                                    </button>
                                                    <button
                                                        v-if="
                                                            bill.billPaid == 0
                                                        "
                                                        class="btn btn-sm btn-transparent btn-alt-danger text-danger dropdown-item-horizontal"
                                                        v-ripple="{
                                                            center: true
                                                        }"
                                                        @click.prevent="
                                                            deleteData(
                                                                bill.billId
                                                            )
                                                        "
                                                    >
                                                        <i
                                                            class="si si-trash mr-1"
                                                        ></i>
                                                        <span
                                                            class="d-none d-md-inline"
                                                            >Delete</span
                                                        >
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody
                                    v-if="items.length <= 0 && loadingPage == 0"
                                >
                                    <tr class="data-empty">
                                        <td
                                            class="text-center"
                                            colspan="7"
                                            style="opacity:0.8; padding:2.5rem;"
                                        >
                                            <center>
                                                <img
                                                    :src="
                                                        '/media/proxima/empty-data.svg'
                                                    "
                                                    style="height: 100px;"
                                                />
                                            </center>
                                            <br /><span style="opacity:0.6;"
                                                >Data Not Found</span
                                            >
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-data-table>
                    </v-app>
                </div>
                <div class="block-content block-content-full pt-0">
                    <div class="row">
                        <div
                            class="col-3 pt-0"
                            v-bind:class="{
                                'offset-6': this.type == 'Open Invoice',
                                'offset-9': this.type != 'Open Invoice'
                            }"
                        >
                            <label for="field-1" class="control-label">
                                Total Amount :
                            </label>
                            <money
                                class="form-control"
                                style="text-align: right;"
                                v-model="amountData"
                                spellcheck="false"
                                disabled
                            ></money>
                        </div>
                        <div
                            class="col-3 pt-0"
                            v-if="this.type == 'Open Invoice'"
                        >
                            <label for="field-1" class="control-label">
                                Due Amount :
                            </label>
                            <money
                                class="form-control"
                                style="text-align: right;"
                                v-model="amountDueData"
                                spellcheck="false"
                                disabled
                            ></money>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";

export default {
    name: "bill-register",
    data() {
        return {
            search: "",
            awaitingSearch: false,
            rows: "All",
            loadingPage: 1,
            type: "All Sales",
            tab: null,
            items: [
                "All Sales",
                "Quotation",
                "Order",
                "Closed Order",
                "Open Invoice",
                "Closed Invoice"
            ],
            headers: [
                { text: "No.", align: "center", sortable: false },
                { text: "Bills No.", align: "start", value: "billNo" },
                { text: "Date", align: "center", value: "billDate" },
                { text: "Contact Name", value: "billContact" },
                {
                    text: "Amount",
                    align: "right",
                    value: "billAmount",
                    sortable: false
                },
                { text: "Status", align: "center", value: "billStatus" },
                { text: "", sortable: false }
            ],
            date_start: moment()
                .subtract(30, "days")
                .format("YYYY-MM-DD"),
            date_end: moment().format("YYYY-MM-DD"),
            amountData: 0,
            amountDueData: 0,
            datas: []
        };
    },
    created() {
        if (
            this.$session.has("billregister_datas") &&
            !this.$route.params.reloadData
        ) {
            this.loadingPage = 0;
            this.type = this.$session.get("billregister_type");
            this.datas = this.$session.get("billregister_datas");
            this.tab = this.$session.get("billregister_tab")
                ? this.$session.get("billregister_tab")
                : null;
            this.date_start = this.$session.get("billregister_date_start");
            this.date_end = this.$session.get("billregister_date_end");
            this.search = this.$session.get("billregister_search");
            this.amountData = this.$session.get("billregister_amount");
            this.amountDataDue = this.$session.get("billregister_amountDue");
            this.setTableHeader();
        } else if (
            this.$session.has("billregister_datas") &&
            this.$route.params.reloadData
        ) {
            this.date_start = this.$session.get("billregister_date_start");
            this.date_end = this.$session.get("billregister_date_end");
            this.search = this.$session.get("billregister_search");
            this.$session.set("billregister_type", this.type);
            this.getData();
            // clean loading 1st
        } else {
            this.getData();
        }
        // this.getData();
        this.$session.remove("billregister_detail");
    },
    computed: {
        ...mapState("bill", {
            bills: state => state.bills
        })
    },
    methods: {
        //VUEX
        ...mapActions("bill", ["getBill", "removeBill"]),
        //METHOD
        typeChange(val) {
            this.loadingPage = 2;
            this.type = val;
            this.getData();
        },
        setTableHeader() {
            if (this.type == "All Sales") {
                this.headers = [
                    { text: "No.", align: "center", sortable: false },
                    { text: "Bills No.", align: "start", value: "billNo" },
                    { text: "Date", align: "center", value: "billDate" },
                    { text: "Contact Name", value: "billContact" },
                    {
                        text: "Amount",
                        align: "right",
                        value: "billAmount",
                        sortable: false
                    },
                    { text: "Status", align: "center", value: "billStatus" },
                    { text: "", sortable: false }
                ];
            } else if (
                this.type == "Quotation" ||
                this.type == "Closed Invoice" ||
                this.type == "Closed Order"
            ) {
                this.headers = [
                    { text: "No.", align: "center", sortable: false },
                    { text: "Bills No.", align: "start", value: "billNo" },
                    { text: "Date", align: "center", value: "billDate" },
                    { text: "Contact Name", value: "billContact" },
                    {
                        text: "Amount",
                        align: "right",
                        value: "billAmount"
                    },
                    { text: "", sortable: false }
                ];
            } else if (this.type == "Order") {
                this.headers = [
                    { text: "No.", align: "center", sortable: false },
                    { text: "Bills No.", align: "start", value: "billNo" },
                    { text: "Date", align: "center", value: "billDate" },
                    { text: "Due Date", align: "center", value: "billDueDate" },
                    { text: "Contact Name", value: "billContact" },
                    {
                        text: "Amount",
                        align: "right",
                        value: "billAmount"
                    },
                    {
                        text: "Billed",
                        align: "right",
                        value: "billPaid"
                    },
                    {
                        text: "Remain",
                        align: "right",
                        value: "billAmountDue"
                    },
                    { text: "", sortable: false }
                ];
            } else if (this.type == "Open Invoice") {
                this.headers = [
                    { text: "No.", align: "center", sortable: false },
                    { text: "Bills No.", align: "start", value: "billNo" },
                    { text: "Date", align: "center", value: "billDate" },
                    { text: "Due Date", align: "center", value: "billDueDate" },
                    { text: "Contact Name", value: "billContact" },
                    {
                        text: "Amount",
                        align: "right",
                        value: "billAmount"
                    },
                    {
                        text: "Paid",
                        align: "right",
                        value: "billPaid"
                    },
                    {
                        text: "Remain",
                        align: "right",
                        value: "billAmountDue"
                    },
                    { text: "", sortable: false }
                ];
            }
        },
        getData() {
            this.loadingPage = 2;
            this.datas = [];
            this.amountData = 0;
            this.amountDueData = 0;
            this.$session.set(
                "billregister_date_start",
                moment(this.date_start).format("YYYY-MM-DD")
            );
            this.$session.set(
                "billregister_date_end",
                moment(this.date_end).format("YYYY-MM-DD")
            );
            this.setTableHeader();
            this.getBill({
                rows: this.rows,
                type: this.type,
                date_start: new Date(this.date_start).toDateString(),
                date_end: new Date(this.date_end).toDateString()
            })
                .then(() => {
                    this.bills.data.forEach(element => {
                        let dataNo = [];
                        if (element.bill_number == null) {
                            dataNo.push(element.bill_invoice_number);
                            dataNo.push(element.bill_order_number);
                            dataNo.push(element.bill_quotation_number);
                        }
                        let dataContact =
                            element.contact.contact_name +
                            '<br><small style="color:#c2c2c2!important;">' +
                            element.contact.contact_address +
                            "</small>";
                        this.datas.push({
                            billId: element.id,
                            billDate: moment(element.bill_date).format(
                                "DD/MM/YYYY"
                            ),
                            billDueDate: moment(element.bill_due_date).format(
                                "DD/MM/YYYY"
                            ),
                            billNo:
                                element.bill_number == null
                                    ? dataNo
                                    : element.bill_number,
                            billContact: dataContact,
                            billAmount: element.bill_grand_total,
                            billPaid: element.bill_paid_total,
                            billAmountDue:
                                element.bill_grand_total -
                                element.bill_paid_total,
                            billStatus: element.bill_status,
                            billJournalId:
                                element.bill_status == 3 ||
                                element.bill_status == 4
                                    ? element.journal.id
                                    : 0
                        });
                        this.amountData =
                            this.amountData + element.bill_grand_total;
                        if (this.type == "Open Invoice") {
                            this.amountDueData =
                                this.amountDueData +
                                (element.bill_grand_total -
                                    element.bill_paid_total);
                        } else {
                            this.amountDueData = 0;
                        }
                    });
                    this.loadingPage = 0;
                    this.$session.set("billregister_datas", this.datas);
                    this.$session.set("billregister_amount", this.amountData);
                    this.$session.set(
                        "billregister_amountDue",
                        this.amountDueData
                    );
                    this.$session.set("billregister_type", this.type);
                    this.$session.set("billregister_tab", this.tab);
                })
                .catch(error => {
                    error.response.status == 500
                        ? this.alert(error.response.data.message, 2)
                        : "";
                    error.response.status != 422 && error.response.status != 500
                        ? this.alert(error.message, 2)
                        : "";
                    this.loadingPage = 0;
                    this.amountData = 0;
                    this.amountDueData = 0;
                });
        },
        toDetail(val) {
            this.$router.push({ name: "bill-detail", params: { id: val } });
        },
        toEdit(val) {
            this.$router.push({ name: "bill-edit", params: { id: val } });
        },
        toJournalVoucher(val) {
            this.$router.push({
                name: "journal-transaction-voucher",
                params: { id: val }
            });
        },
        deleteData(val) {
            this.$swal({
                title: "Are you sure ?",
                text: "Deleted data cannot be recovery",
                icon: "warning",
                buttons: ["Cancel", "Delete"],
                dangerMode: true
            }).then(willDelete => {
                if (willDelete) {
                    this.removeBill(val)
                        .then(() => {
                            this.loadingPage = 1;
                            this.alert("Data has been deleted !", 1);
                            this.getData();
                        })
                        .catch(error => {
                            error.response.status == 500
                                ? this.alert(error.response.data.message, 2)
                                : "";
                            error.response.status != 422 &&
                            error.response.status != 500
                                ? this.alert(error.message, 2)
                                : "";
                            this.loadingPage = 0;
                        });
                }
            });
        }
    }
};
</script>