var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('hero',{attrs:{"title":"Create Signature List","prevBreadcrumb":"Signature List","routeBreadcrumb":"signature","currentBreadcrumb":"Create Signature List"}}),_vm._v(" "),_c('div',{staticClass:"content"},[(_vm.loadingPage == 1)?_c('div',{staticClass:"block block-rounded block-mode-loading-oneui",staticStyle:{"height":"250px"}},[_c('div',{staticClass:"block-content block-content-full pb-0",staticStyle:{"padding-top":"80px"}},[_c('grid-loader',{staticClass:"mx-auto",attrs:{"color":'#70B9EB',"size":'10px'}}),_vm._v(" "),_c('center',{staticClass:"mt-2"},[_c('span',{staticClass:"text-center",staticStyle:{"opacity":"0.7"}},[_vm._v("Loading")])])],1)]):_vm._e(),_vm._v(" "),(_vm.loadingPage == 2)?_c('div',{staticClass:"block block-rounded block-mode-loading-oneui",staticStyle:{"height":"250px"}},[_c('div',{staticClass:"block-content block-content-full pb-0",staticStyle:{"padding-top":"80px"}},[_c('grid-loader',{staticClass:"mx-auto",attrs:{"color":'#70B9EB',"size":'10px'}}),_vm._v(" "),_c('center',{staticClass:"mt-2"},[_c('span',{staticClass:"text-center",staticStyle:{"opacity":"0.7"}},[_vm._v("Save Data")])])],1)]):_vm._e(),_vm._v(" "),(_vm.loadingPage == 0)?_c('div',{staticClass:"block col-12 col-md-8 offset-md-2 block-rounded block-mode-loading-oneui"},[_c('div',{staticClass:"block-content block-content-full"},[_c('form',{attrs:{"method":"POST","enctype":"multipart/form-data","onsubmit":"return false;"}},[_c('div',{staticClass:"row push font-size-sm"},[_c('div',{staticClass:"col-12 col-md-10 offset-md-1"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Name")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.signature.signature_name),expression:"signature.signature_name"}],staticClass:"form-control",class:{
                                        'is-invalid': _vm.errors.signature_name
                                    },attrs:{"type":"text","placeholder":"Fullname"},domProps:{"value":(_vm.signature.signature_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.signature, "signature_name", $event.target.value)}}}),_vm._v(" "),(_vm.errors.signature_name)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                    "+_vm._s(_vm.errors.signature_name[0])+"\n                                ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Job Position")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.signature.signature_position),expression:"signature.signature_position"}],staticClass:"form-control",class:{
                                        'is-invalid':
                                            _vm.errors.signature_position
                                    },attrs:{"type":"text","placeholder":"Job Position"},domProps:{"value":(_vm.signature.signature_position)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.signature, "signature_position", $event.target.value)}}}),_vm._v(" "),(_vm.errors.signature_position)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                    "+_vm._s(_vm.errors.signature_position[0])+"\n                                ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"form-group font-size-sm"},[_c('label',{staticClass:"d-block"},[_vm._v("Document Type :")]),_vm._v(" "),_c('div',{staticClass:"row py-1 px-3"},[_c('div',{staticClass:"col-6 col-md-4 py-2 pr-0 form-check form-check-inline mr-0"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                _vm.signature.signature_voucher
                                            ),expression:"\n                                                signature.signature_voucher\n                                            "}],staticClass:"form-check-input",attrs:{"type":"checkbox","true-value":"1","false-value":"0"},domProps:{"checked":Array.isArray(
                                                _vm.signature.signature_voucher
                                            )?_vm._i(
                                                _vm.signature.signature_voucher
                                            ,null)>-1:_vm._q(
                                                _vm.signature.signature_voucher
                                            ,"1")},on:{"change":function($event){var $$a=
                                                _vm.signature.signature_voucher
                                            ,$$el=$event.target,$$c=$$el.checked?("1"):("0");if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.signature, "signature_voucher", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.signature, "signature_voucher", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.signature, "signature_voucher", $$c)}}}}),_vm._v(" "),_c('label',{staticClass:"form-check-label"},[_vm._v("Journal Voucher")])]),_vm._v(" "),_c('div',{staticClass:"col-6 col-md-4 py-2 pr-0 form-check form-check-inline mr-0"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                _vm.signature.signature_inventory
                                            ),expression:"\n                                                signature.signature_inventory\n                                            "}],staticClass:"form-check-input",attrs:{"type":"checkbox","true-value":"1","false-value":"0"},domProps:{"checked":Array.isArray(
                                                _vm.signature.signature_inventory
                                            )?_vm._i(
                                                _vm.signature.signature_inventory
                                            ,null)>-1:_vm._q(
                                                _vm.signature.signature_inventory
                                            ,"1")},on:{"change":function($event){var $$a=
                                                _vm.signature.signature_inventory
                                            ,$$el=$event.target,$$c=$$el.checked?("1"):("0");if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.signature, "signature_inventory", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.signature, "signature_inventory", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.signature, "signature_inventory", $$c)}}}}),_vm._v(" "),_c('label',{staticClass:"form-check-label"},[_vm._v("Inventory Document")])]),_vm._v(" "),_c('div',{staticClass:"col-6 col-md-4 py-2 pr-0 form-check form-check-inline mr-0"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                _vm.signature.signature_bill_quotation
                                            ),expression:"\n                                                signature.signature_bill_quotation\n                                            "}],staticClass:"form-check-input",attrs:{"type":"checkbox","true-value":"1","false-value":"0"},domProps:{"checked":Array.isArray(
                                                _vm.signature.signature_bill_quotation
                                            )?_vm._i(
                                                _vm.signature.signature_bill_quotation
                                            ,null)>-1:_vm._q(
                                                _vm.signature.signature_bill_quotation
                                            ,"1")},on:{"change":function($event){var $$a=
                                                _vm.signature.signature_bill_quotation
                                            ,$$el=$event.target,$$c=$$el.checked?("1"):("0");if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.signature, "signature_bill_quotation", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.signature, "signature_bill_quotation", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.signature, "signature_bill_quotation", $$c)}}}}),_vm._v(" "),_c('label',{staticClass:"form-check-label"},[_vm._v("Bill Quotation")])]),_vm._v(" "),_c('div',{staticClass:"col-6 col-md-4 py-2 pr-0 form-check form-check-inline mr-0"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                _vm.signature.signature_bill_order
                                            ),expression:"\n                                                signature.signature_bill_order\n                                            "}],staticClass:"form-check-input",attrs:{"type":"checkbox","true-value":"1","false-value":"0"},domProps:{"checked":Array.isArray(
                                                _vm.signature.signature_bill_order
                                            )?_vm._i(
                                                _vm.signature.signature_bill_order
                                            ,null)>-1:_vm._q(
                                                _vm.signature.signature_bill_order
                                            ,"1")},on:{"change":function($event){var $$a=
                                                _vm.signature.signature_bill_order
                                            ,$$el=$event.target,$$c=$$el.checked?("1"):("0");if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.signature, "signature_bill_order", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.signature, "signature_bill_order", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.signature, "signature_bill_order", $$c)}}}}),_vm._v(" "),_c('label',{staticClass:"form-check-label"},[_vm._v("Bill Order")])]),_vm._v(" "),_c('div',{staticClass:"col-6 col-md-4 py-2 pr-0 form-check form-check-inline mr-0"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                _vm.signature.signature_bill_invoice
                                            ),expression:"\n                                                signature.signature_bill_invoice\n                                            "}],staticClass:"form-check-input",attrs:{"type":"checkbox","true-value":"1","false-value":"0"},domProps:{"checked":Array.isArray(
                                                _vm.signature.signature_bill_invoice
                                            )?_vm._i(
                                                _vm.signature.signature_bill_invoice
                                            ,null)>-1:_vm._q(
                                                _vm.signature.signature_bill_invoice
                                            ,"1")},on:{"change":function($event){var $$a=
                                                _vm.signature.signature_bill_invoice
                                            ,$$el=$event.target,$$c=$$el.checked?("1"):("0");if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.signature, "signature_bill_invoice", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.signature, "signature_bill_invoice", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.signature, "signature_bill_invoice", $$c)}}}}),_vm._v(" "),_c('label',{staticClass:"form-check-label"},[_vm._v("Bill Invoice")])]),_vm._v(" "),_c('div',{staticClass:"col-6 col-md-4 py-2 pr-0 form-check form-check-inline mr-0"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                _vm.signature.signature_purchase_quotation
                                            ),expression:"\n                                                signature.signature_purchase_quotation\n                                            "}],staticClass:"form-check-input",attrs:{"type":"checkbox","true-value":"1","false-value":"0"},domProps:{"checked":Array.isArray(
                                                _vm.signature.signature_purchase_quotation
                                            )?_vm._i(
                                                _vm.signature.signature_purchase_quotation
                                            ,null)>-1:_vm._q(
                                                _vm.signature.signature_purchase_quotation
                                            ,"1")},on:{"change":function($event){var $$a=
                                                _vm.signature.signature_purchase_quotation
                                            ,$$el=$event.target,$$c=$$el.checked?("1"):("0");if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.signature, "signature_purchase_quotation", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.signature, "signature_purchase_quotation", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.signature, "signature_purchase_quotation", $$c)}}}}),_vm._v(" "),_c('label',{staticClass:"form-check-label"},[_vm._v("Purchase Quotation")])]),_vm._v(" "),_c('div',{staticClass:"col-6 col-md-4 py-2 pr-0 form-check form-check-inline mr-0"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                _vm.signature.signature_purchase_order
                                            ),expression:"\n                                                signature.signature_purchase_order\n                                            "}],staticClass:"form-check-input",attrs:{"type":"checkbox","true-value":"1","false-value":"0"},domProps:{"checked":Array.isArray(
                                                _vm.signature.signature_purchase_order
                                            )?_vm._i(
                                                _vm.signature.signature_purchase_order
                                            ,null)>-1:_vm._q(
                                                _vm.signature.signature_purchase_order
                                            ,"1")},on:{"change":function($event){var $$a=
                                                _vm.signature.signature_purchase_order
                                            ,$$el=$event.target,$$c=$$el.checked?("1"):("0");if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.signature, "signature_purchase_order", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.signature, "signature_purchase_order", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.signature, "signature_purchase_order", $$c)}}}}),_vm._v(" "),_c('label',{staticClass:"form-check-label"},[_vm._v("Purchase Order")])]),_vm._v(" "),_c('div',{staticClass:"col-6 col-md-4 py-2 pr-0 form-check form-check-inline mr-0"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                _vm.signature.signature_purchase_invoice
                                            ),expression:"\n                                                signature.signature_purchase_invoice\n                                            "}],staticClass:"form-check-input",attrs:{"type":"checkbox","true-value":"1","false-value":"0"},domProps:{"checked":Array.isArray(
                                                _vm.signature.signature_purchase_invoice
                                            )?_vm._i(
                                                _vm.signature.signature_purchase_invoice
                                            ,null)>-1:_vm._q(
                                                _vm.signature.signature_purchase_invoice
                                            ,"1")},on:{"change":function($event){var $$a=
                                                _vm.signature.signature_purchase_invoice
                                            ,$$el=$event.target,$$c=$$el.checked?("1"):("0");if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.signature, "signature_purchase_invoice", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.signature, "signature_purchase_invoice", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.signature, "signature_purchase_invoice", $$c)}}}}),_vm._v(" "),_c('label',{staticClass:"form-check-label"},[_vm._v("Purchase Invoice")])]),_vm._v(" "),_c('div',{staticClass:"col-6 col-md-4 py-2 pr-0 form-check form-check-inline mr-0"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                _vm.signature.signature_receive_payment
                                            ),expression:"\n                                                signature.signature_receive_payment\n                                            "}],staticClass:"form-check-input",attrs:{"type":"checkbox","true-value":"1","false-value":"0"},domProps:{"checked":Array.isArray(
                                                _vm.signature.signature_receive_payment
                                            )?_vm._i(
                                                _vm.signature.signature_receive_payment
                                            ,null)>-1:_vm._q(
                                                _vm.signature.signature_receive_payment
                                            ,"1")},on:{"change":function($event){var $$a=
                                                _vm.signature.signature_receive_payment
                                            ,$$el=$event.target,$$c=$$el.checked?("1"):("0");if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.signature, "signature_receive_payment", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.signature, "signature_receive_payment", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.signature, "signature_receive_payment", $$c)}}}}),_vm._v(" "),_c('label',{staticClass:"form-check-label"},[_vm._v("Receive Payment")])]),_vm._v(" "),_c('div',{staticClass:"col-6 col-md-4 py-2 pr-0 form-check form-check-inline mr-0"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                _vm.signature.signature_pay_purchase
                                            ),expression:"\n                                                signature.signature_pay_purchase\n                                            "}],staticClass:"form-check-input",attrs:{"type":"checkbox","true-value":"1","false-value":"0"},domProps:{"checked":Array.isArray(
                                                _vm.signature.signature_pay_purchase
                                            )?_vm._i(
                                                _vm.signature.signature_pay_purchase
                                            ,null)>-1:_vm._q(
                                                _vm.signature.signature_pay_purchase
                                            ,"1")},on:{"change":function($event){var $$a=
                                                _vm.signature.signature_pay_purchase
                                            ,$$el=$event.target,$$c=$$el.checked?("1"):("0");if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.signature, "signature_pay_purchase", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.signature, "signature_pay_purchase", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.signature, "signature_pay_purchase", $$c)}}}}),_vm._v(" "),_c('label',{staticClass:"form-check-label"},[_vm._v("Pay Purchase")])]),_vm._v(" "),_c('div',{staticClass:"col-6 col-md-4 py-2 pr-0 form-check form-check-inline mr-0"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                _vm.signature.signature_expense
                                            ),expression:"\n                                                signature.signature_expense\n                                            "}],staticClass:"form-check-input",attrs:{"type":"checkbox","true-value":"1","false-value":"0"},domProps:{"checked":Array.isArray(
                                                _vm.signature.signature_expense
                                            )?_vm._i(
                                                _vm.signature.signature_expense
                                            ,null)>-1:_vm._q(
                                                _vm.signature.signature_expense
                                            ,"1")},on:{"change":function($event){var $$a=
                                                _vm.signature.signature_expense
                                            ,$$el=$event.target,$$c=$$el.checked?("1"):("0");if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.signature, "signature_expense", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.signature, "signature_expense", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.signature, "signature_expense", $$c)}}}}),_vm._v(" "),_c('label',{staticClass:"form-check-label"},[_vm._v("Expense")])]),_vm._v(" "),_c('div',{staticClass:"col-6 col-md-4 py-2 pr-0 form-check form-check-inline mr-0"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                _vm.signature.signature_income
                                            ),expression:"\n                                                signature.signature_income\n                                            "}],staticClass:"form-check-input",attrs:{"type":"checkbox","true-value":"1","false-value":"0"},domProps:{"checked":Array.isArray(
                                                _vm.signature.signature_income
                                            )?_vm._i(
                                                _vm.signature.signature_income
                                            ,null)>-1:_vm._q(
                                                _vm.signature.signature_income
                                            ,"1")},on:{"change":function($event){var $$a=
                                                _vm.signature.signature_income
                                            ,$$el=$event.target,$$c=$$el.checked?("1"):("0");if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.signature, "signature_income", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.signature, "signature_income", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.signature, "signature_income", $$c)}}}}),_vm._v(" "),_c('label',{staticClass:"form-check-label"},[_vm._v("Income")])]),_vm._v(" "),_c('div',{staticClass:"col-6 col-md-4 py-2 pr-0 form-check form-check-inline mr-0"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                _vm.signature.signature_general_ledger
                                            ),expression:"\n                                                signature.signature_general_ledger\n                                            "}],staticClass:"form-check-input",attrs:{"type":"checkbox","true-value":"1","false-value":"0"},domProps:{"checked":Array.isArray(
                                                _vm.signature.signature_general_ledger
                                            )?_vm._i(
                                                _vm.signature.signature_general_ledger
                                            ,null)>-1:_vm._q(
                                                _vm.signature.signature_general_ledger
                                            ,"1")},on:{"change":function($event){var $$a=
                                                _vm.signature.signature_general_ledger
                                            ,$$el=$event.target,$$c=$$el.checked?("1"):("0");if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.signature, "signature_general_ledger", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.signature, "signature_general_ledger", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.signature, "signature_general_ledger", $$c)}}}}),_vm._v(" "),_c('label',{staticClass:"form-check-label"},[_vm._v("General Ledger")])]),_vm._v(" "),_c('div',{staticClass:"col-6 col-md-4 py-2 pr-0 form-check form-check-inline mr-0"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                _vm.signature.signature_trial_balance
                                            ),expression:"\n                                                signature.signature_trial_balance\n                                            "}],staticClass:"form-check-input",attrs:{"type":"checkbox","true-value":"1","false-value":"0"},domProps:{"checked":Array.isArray(
                                                _vm.signature.signature_trial_balance
                                            )?_vm._i(
                                                _vm.signature.signature_trial_balance
                                            ,null)>-1:_vm._q(
                                                _vm.signature.signature_trial_balance
                                            ,"1")},on:{"change":function($event){var $$a=
                                                _vm.signature.signature_trial_balance
                                            ,$$el=$event.target,$$c=$$el.checked?("1"):("0");if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.signature, "signature_trial_balance", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.signature, "signature_trial_balance", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.signature, "signature_trial_balance", $$c)}}}}),_vm._v(" "),_c('label',{staticClass:"form-check-label"},[_vm._v("Trial Balance")])]),_vm._v(" "),_c('div',{staticClass:"col-6 col-md-4 py-2 pr-0 form-check form-check-inline mr-0"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                _vm.signature.signature_balance_sheet
                                            ),expression:"\n                                                signature.signature_balance_sheet\n                                            "}],staticClass:"form-check-input",attrs:{"type":"checkbox","true-value":"1","false-value":"0"},domProps:{"checked":Array.isArray(
                                                _vm.signature.signature_balance_sheet
                                            )?_vm._i(
                                                _vm.signature.signature_balance_sheet
                                            ,null)>-1:_vm._q(
                                                _vm.signature.signature_balance_sheet
                                            ,"1")},on:{"change":function($event){var $$a=
                                                _vm.signature.signature_balance_sheet
                                            ,$$el=$event.target,$$c=$$el.checked?("1"):("0");if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.signature, "signature_balance_sheet", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.signature, "signature_balance_sheet", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.signature, "signature_balance_sheet", $$c)}}}}),_vm._v(" "),_c('label',{staticClass:"form-check-label"},[_vm._v("Balance Sheet")])]),_vm._v(" "),_c('div',{staticClass:"col-6 col-md-4 py-2 pr-0 form-check form-check-inline mr-0"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                _vm.signature.signature_profit_loss
                                            ),expression:"\n                                                signature.signature_profit_loss\n                                            "}],staticClass:"form-check-input",attrs:{"type":"checkbox","true-value":"1","false-value":"0"},domProps:{"checked":Array.isArray(
                                                _vm.signature.signature_profit_loss
                                            )?_vm._i(
                                                _vm.signature.signature_profit_loss
                                            ,null)>-1:_vm._q(
                                                _vm.signature.signature_profit_loss
                                            ,"1")},on:{"change":function($event){var $$a=
                                                _vm.signature.signature_profit_loss
                                            ,$$el=$event.target,$$c=$$el.checked?("1"):("0");if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.signature, "signature_profit_loss", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.signature, "signature_profit_loss", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.signature, "signature_profit_loss", $$c)}}}}),_vm._v(" "),_c('label',{staticClass:"form-check-label"},[_vm._v("Profit Loss")])])])])])]),_vm._v(" "),_c('div',{staticClass:"row items-push"},[_c('div',{staticClass:"col-12 text-center"},[_c('p',{staticClass:"font-size-sm text-muted"},[_vm._v("\n                                * Require to be filled, Make sure all data is\n                                correctly filled\n                            ")]),_vm._v(" "),_c('button',{directives:[{name:"ripple",rawName:"v-ripple",value:({ center: true }),expression:"{ center: true }"}],staticClass:"btn btn-sm btn-success",attrs:{"type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('i',{staticClass:"fa fa-fw fa-check mr-1"}),_vm._v(" Save\n                            ")]),_vm._v(" "),_c('router-link',{directives:[{name:"ripple",rawName:"v-ripple",value:({ center: true }),expression:"{ center: true }"}],staticClass:"btn btn-sm btn-alt-light",attrs:{"type":"button","tag":"button","to":{ name: 'signature' }}},[_c('i',{staticClass:"fa fa-fw fa-times mr-1"}),_vm._v(" Close\n                            ")])],1)])])])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }