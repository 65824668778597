<template>
    <main>
        <hero title="Report Document" prevBreadcrumb="Purchase Report" routeBreadcrumb="purchase-report" currentBreadcrumb="Report Document"></hero>

        <!-- Page Content -->
        <div class="content">
            <div class="block mb-2 block-rounded block-mode-loading-oneui">
                <div class="block-header">
                    <h3 class="block-title">General Ledger</h3>
                    <div class="block-options">
                        <!-- Print Page functionality is initialized in Helpers.print() -->
                        <button
                            type="button"
                            class="btn-block-option text-info"
                            @click="print()"
                        >
                            <i class="si si-printer mr-1"></i>
                            <span class="d-none d-md-inline-block"
                                >Print Document</span
                            >
                        </button>
                        <!-- Print Page functionality is initialized in Helpers.print() -->
                        <button
                            type="button"
                            class="btn-block-option text-success"
                            @click="refresh()"
                        >
                            <i class="si si-reload mr-1"></i>
                            <span class="d-none d-md-inline-block"
                                >Refresh</span
                            >
                        </button>
                    </div>
                </div>
            </div>
            <div class="block bg-transparent mb-2">
                <div class="block-content pt-0 bg-transparent text-right">
                    <input
                        class="form-check-input"
                        style="margin-top: 3.5px"
                        type="checkbox"
                        v-model="useHeader"
                    />
                    <label class="form-check-label">Default Header</label>
                </div>
            </div>
            <!--Loading-->
            <div class="block" v-if="loadingPage == 1" style="height: 250px">
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Purchases Report</span
                        >
                    </center>
                </div>
            </div>
            <!-- Basic -->
            <div class="block block-rounded block-mode-loading-oneui" v-else>
                <div class="content content-boxed" id="printMe">
                    <div class="block-content">
                        <div
                            class="p-0 p-md-5 p-print"
                            style="min-height: 800px"
                        >
                            <div class="row pt-0" v-if="useHeader">
                                <div class="col-6 font-size-sm pb-0 pt-0">
                                    <p class="h3 mb-2 mt-3 text-uppercase">
                                        {{
                                            auth.data.workspace
                                                .workspace_company_name
                                        }}
                                    </p>
                                    <p class="mb-0">
                                        {{
                                            auth.data.workspace
                                                .workspace_company_address
                                        }}
                                    </p>
                                    <p class="mb-0">
                                        <span
                                            v-if="
                                                auth.data.workspace
                                                    .workspace_company_province !==
                                                    '' &&
                                                    auth.data.workspace
                                                        .workspace_company_province !==
                                                        null
                                            "
                                        >
                                            {{
                                                auth.data.workspace
                                                    .workspace_company_province
                                            }}
                                        </span>
                                        <span
                                            v-if="
                                                auth.data.workspace
                                                    .workspace_company_postal !==
                                                    '' &&
                                                    auth.data.workspace
                                                        .workspace_company_postal !==
                                                        null
                                            "
                                        >
                                            ,
                                            {{
                                                auth.data.workspace
                                                    .workspace_company_postal
                                            }}
                                        </span>
                                    </p>
                                    <p class="mb-0">
                                        <span
                                            v-if="
                                                auth.data.workspace
                                                    .workspace_company_phone !==
                                                    '' &&
                                                    auth.data.workspace
                                                        .workspace_company_phone !==
                                                        null
                                            "
                                        >
                                            {{
                                                auth.data.workspace
                                                    .workspace_company_phone
                                            }}
                                        </span>
                                        <span
                                            v-if="
                                                auth.data.workspace
                                                    .workspace_company_email !==
                                                    '' &&
                                                    auth.data.workspace
                                                        .workspace_company_email !==
                                                        null
                                            "
                                        >
                                            {{
                                                "| " +
                                                    auth.data.workspace
                                                        .workspace_company_email
                                            }}
                                        </span>
                                    </p>
                                </div>
                                <div
                                    class="col-6 text-right font-size-sm pb-0 pt-0"
                                >
                                    <img
                                        :src="
                                            '/storage/workspace/' +
                                                auth.data.workspace
                                                    .workspace_company_logo
                                        "
                                        alt=""
                                        style="
                                            min-heigh: 100px;
                                            max-height: 120px;
                                            max-width: 180px;
                                        "
                                        @error="errorLogoUrl"
                                    />
                                </div>
                            </div>

                            <hr v-if="useHeader" />

                            <!-- Invoice Info -->
                            <div class="row mb-2">
                                <!-- Company Info -->
                                <div class="col-6 font-size-sm">
                                    <p class="h3 mb-1">Purchases Report</p>
                                    <span v-if="data.contact == 'All Supplier'">
                                        <p class="mb-0">
                                            <b> All Supplier </b>
                                        </p>
                                        <p class="mb-2"></p>
                                    </span>
                                    <span v-else>
                                        <p class="mb-0">
                                            <b>
                                                {{ data.contact.contact_name }}
                                            </b>
                                        </p>
                                        <p class="mb-2">
                                            {{ data.contact.contact_address }}
                                        </p>
                                    </span>
                                </div>
                                <!-- END Company Info -->

                                <!-- Client Info -->
                                <div class="col-6 text-right font-size-sm">
                                    <p class="mb-2">
                                        From :
                                        {{
                                            this.$route.params.date_start
                                                | moment("DD/MM/YYYY")
                                        }}
                                    </p>
                                    <p class="mb-2">
                                        To :
                                        {{
                                            this.$route.params.date_end
                                                | moment("DD/MM/YYYY")
                                        }}
                                    </p>
                                </div>
                                <!-- END Client Info -->
                            </div>
                            <!-- END Invoice Info -->

                            <!-- Table -->
                            <div
                                class="table-responsive push"
                                v-if="loadingPage == 2"
                            >
                                <div
                                    class="block-content block-content-full pb-0"
                                    style="padding-top: 80px"
                                >
                                    <grid-loader
                                        class="mx-auto"
                                        :color="'#70B9EB'"
                                        :size="'10px'"
                                    ></grid-loader>
                                    <center class="mt-2 mb-5 pb-2">
                                        <span
                                            class="text-center"
                                            style="opacity: 0.7"
                                            >Refresh Report</span
                                        >
                                    </center>
                                </div>
                            </div>
                            <div
                                class="table-responsive push"
                                v-if="loadingPage == 0"
                            >
                                <table
                                    class="table table-vcenter table-bordered"
                                >
                                    <thead style="background-color: whitesmoke">
                                        <tr>
                                            <th
                                                class="table-header-relative"
                                                style="width: 80px"
                                            >
                                                No.
                                            </th>
                                            <th class="table-header-relative">
                                                Date
                                            </th>
                                            <th
                                                class="table-header-relative sort-head"
                                            >
                                                Purchase No.
                                            </th>
                                            <th class="table-header-relative">
                                                Status
                                            </th>
                                            <th
                                                class="table-header-relative column-minwidth-100"
                                            >
                                                Amount
                                            </th>
                                            <th
                                                class="table-header-relative column-minwidth-100"
                                            >
                                                Paid Amount
                                            </th>
                                            <th
                                                class="table-header-relative column-minwidth-100"
                                            >
                                                Due Amount
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody
                                        v-if="
                                            this.data.bills.length > 0 &&
                                                loadingPage == 0
                                        "
                                    >
                                        <tr
                                            v-for="(bill, index) in data.bills"
                                            :key="index"
                                        >
                                            <td class="text-center">
                                                {{ ++index }}
                                            </td>
                                            <td class="" align="center">
                                                {{
                                                    bill.bill_date
                                                        | moment("DD/MM/YYYY")
                                                }}
                                            </td>
                                            <td class="">
                                                <span
                                                    v-if="
                                                        bill.bill_status === 1
                                                    "
                                                    >{{
                                                        bill.bill_quotation_number
                                                    }}</span
                                                >
                                                <span
                                                    v-if="
                                                        bill.bill_status === 2
                                                    "
                                                >
                                                    {{
                                                        bill.bill_order_number
                                                    }}
                                                    <span
                                                        style="opacity: 0.4"
                                                        v-if="
                                                            bill.bill_quotation_number !==
                                                                null
                                                        "
                                                    >
                                                        <br />
                                                        {{
                                                            bill.bill_quotation_number
                                                        }}
                                                    </span>
                                                </span>
                                                <span
                                                    v-if="bill.bill_status > 2"
                                                >
                                                    {{
                                                        bill.bill_invoice_number
                                                    }}
                                                    <span
                                                        style="opacity: 0.4"
                                                        v-if="
                                                            bill.bill_order_number !==
                                                                null
                                                        "
                                                    >
                                                        <br />
                                                        {{
                                                            bill.bill_order_number
                                                        }}
                                                    </span>
                                                    <span
                                                        style="opacity: 0.4"
                                                        v-if="
                                                            bill.bill_quotation_number !==
                                                                null
                                                        "
                                                    >
                                                        <br />
                                                        {{
                                                            bill.bill_quotation_number
                                                        }}
                                                    </span>
                                                </span>
                                                <span
                                                    v-if="
                                                        data.contact ==
                                                            'All Supplier'
                                                    "
                                                >
                                                    <br />
                                                    {{
                                                        bill.contact
                                                            .contact_name
                                                    }}
                                                </span>
                                            </td>
                                            <td
                                                @click="toDetail(bill.id)"
                                                class=""
                                                align="center"
                                            >
                                                <span
                                                    v-if="
                                                        bill.bill_status === 1
                                                    "
                                                    >Quotation</span
                                                >
                                                <span
                                                    v-else-if="
                                                        bill.bill_status === 2
                                                    "
                                                    >Order</span
                                                >
                                                <span
                                                    v-else-if="
                                                        bill.bill_status === 3
                                                    "
                                                    >Invoice</span
                                                >
                                                <span v-else
                                                    >Closed Invoice</span
                                                >
                                            </td>
                                            <td
                                                @click="toDetail(bill.id)"
                                                class=""
                                                align="right"
                                            >
                                                {{
                                                    bill.bill_grand_total
                                                        | currency
                                                }}
                                            </td>
                                            <td
                                                @click="toDetail(bill.id)"
                                                class=""
                                                align="right"
                                            >
                                                <span>
                                                    {{
                                                        bill.bill_paid_total
                                                            | currency
                                                    }}
                                                </span>
                                            </td>
                                            <td
                                                @click="toDetail(bill.id)"
                                                class=""
                                                align="right"
                                            >
                                                <span>
                                                    {{
                                                        (bill.bill_grand_total -
                                                            bill.bill_paid_total)
                                                            | currency
                                                    }}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr
                                            style="background-color: whitesmoke"
                                        >
                                            <td
                                                class="font-w600 text-center"
                                                colspan="4"
                                            >
                                                TOTAL
                                            </td>
                                            <td class="font-w600 text-right">
                                                {{ totalAmount | currency }}
                                            </td>
                                            <td class="font-w600 text-right">
                                                {{ totalAmountPaid | currency }}
                                            </td>
                                            <td class="font-w600 text-right">
                                                {{ totalAmountDue | currency }}
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody
                                        v-if="
                                            data.bills.length <= 0 &&
                                                loadingPage == 0
                                        "
                                    >
                                        <tr class="data-empty">
                                            <td
                                                class="text-center"
                                                colspan="7"
                                                style="
                                                    opacity: 0.8;
                                                    padding: 2.5rem;
                                                "
                                            >
                                                <span style="opacity: 0.6"
                                                    >No Purchases Data</span
                                                >
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!-- END Table -->

                            <hr class="d-print-none" />

                            <div class="row mt-4 mb-5">
                                <div class="col-6 text-left font-size-sm">
                                    <div class="form-group d-print-none">
                                        <label for="example-text-input"
                                            >Post Note *</label
                                        >
                                        <ckeditor
                                            tag-name="textarea"
                                            :editor="editor"
                                            v-model="docPostNote"
                                            :config="editorConfig"
                                        ></ckeditor>
                                    </div>
                                    <span
                                        class="d-none d-print-inline-block"
                                        v-html="docPostNote"
                                    ></span>
                                </div>
                                <div class="col-6 font-size-sm text-right">
                                    <div
                                        class="form-group text-left d-print-none"
                                    >
                                        <label for="example-text-input"
                                            >Choose Signature *</label
                                        >
                                        <selectize
                                            class="form-control"
                                            placeholder="Choose Signature"
                                            v-model="selectedSignature"
                                            @input="
                                                selectSignature(
                                                    selectedSignature
                                                )
                                            "
                                        >
                                            <option
                                                v-for="signature in signatures.data"
                                                :value="signature.id"
                                                :key="signature.id"
                                            >
                                                {{ signature.signature_name }}
                                            </option>
                                        </selectize>
                                    </div>
                                    <p
                                        class="mb-0 font-w700 mt-6 ml-9 text-center"
                                        style="border-bottom: 1px solid grey"
                                        v-bind:class="{
                                            'd-print-none':
                                                selectedSignature == ''
                                        }"
                                    >
                                        {{ docSignature.signature_name }}
                                    </p>
                                    <p
                                        class="mb-0 font-w700 ml-9 text-center"
                                        v-bind:class="{
                                            'd-print-none':
                                                selectedSignature == ''
                                        }"
                                    >
                                        {{ docSignature.signature_position }}
                                    </p>
                                </div>
                            </div>

                            <hr />
                            <p
                                class="d-none d-print-inline-block"
                                style="opacity: 0.6"
                            >
                                <i> Printed : {{ dateNow }} </i>
                            </p>
                            <!-- END Footer -->
                        </div>
                    </div>
                </div>
                <!-- END Invoice -->
            </div>
        </div>
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import moment from "moment";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "../../../../../public/css/proxima-print.css";

export default {
    name: "bill-report-display",
    data() {
        return {
            loadingPage: 1,
            useHeader: true,
            dateNow: moment().format("MMMM Do YYYY, HH:mm:ss"),
            selectedSignature: "",
            docSignature: {
                signature_name: "[select signature]",
                signature_position: "[select signature]"
            },
            editor: ClassicEditor,
            editorConfig: {
                toolbar: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "bulletedList",
                    "numberedList",
                    "blockQuote",
                    "undo",
                    "redo",
                    "insertTable"
                ]
            },
            docPostNote: "",
            data: {},
            totalAmount: 0,
            totalAmountPaid: 0,
            totalAmountDue: 0
        };
    },
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState("coa", { coas: state => state.coas.accounts }),
        ...mapState(["auth"]),
        ...mapState("signature", { signatures: state => state.signatures })
    },
    created() {
        if (
            this.$route.params.date_start &&
            this.$route.params.date_end &&
            this.$route.params.supplier_id &&
            this.$route.params.bill_type
        ) {
            this.getSignatureDocument({ document: "signature_general_ledger" });
            this.generatePurchaseReport({
                date_start: this.$route.params.date_start,
                date_end: this.$route.params.date_end,
                supplier_id: this.$route.params.supplier_id,
                bill_type: this.$route.params.bill_type
            })
                .then(response => {
                    this.convertArray(response.data);
                    this.loadingPage = 0;
                })
                .catch(error => {
                    console.log(error);
                    this.loadingPage = 0;
                });
        } else {
            this.$router.push({ name: "purchase-report" });
            this.alert("Select Customer, Purchase Type, Date First", 2);
        }
    },
    methods: {
        ...mapActions("coa", ["getCoaAll"]),
        ...mapActions("report", [
            "pdfReportGL",
            "xlsReportGL",
            "generateReportGL"
        ]),
        ...mapActions("purchase", ["generatePurchaseReport"]),
        ...mapMutations("report", ["CLEAR_GL"]),
        ...mapActions("signature", ["getSignatureDocument"]),
        convertArray(val) {
            val.bills.forEach(item => {
                this.totalAmount = this.totalAmount + item.bill_grand_total;
                this.totalAmountPaid =
                    this.totalAmountPaid + item.bill_paid_total;
                this.totalAmountDue = this.totalAmount - this.totalAmountPaid;
            });
            this.data = val;
        },
        selectSignature(val) {
            if (val != "") {
                var result = this.signatures.data.find(obj => {
                    return obj.id === parseInt(val);
                });
                this.docSignature.signature_name = result.signature_name;
                this.docSignature.signature_position =
                    result.signature_position;
            } else {
                this.docSignature.signature_name = "[select signature]";
                this.docSignature.signature_position = "[select signature]";
            }
        },
        errorLogoUrl(event) {
            event.target.src = "/media/workspace/no-logo.jpg";
        },
        //METHOD
        print() {
            const prtHtml = document.getElementById("printMe").innerHTML;
            // var browserName  = navigator.appName;
            var printContents = document.getElementById("printMe").innerHTML;
            var originalContents = document.body.innerHTML;
            document.body.innerHTML = printContents;
            window.print();
            document.body.innerHTML = originalContents;
        },
        refresh() {
            this.loadingPage = 2;
            this.data = {
                billls: [],
                contact: {
                    contact_name: ""
                }
            };
            this.totalAmount = 0;
            this.totalAmountPaid = 0;
            this.totalAmountDue = 0;
            this.generatePurchaseReport({
                date_start: this.$route.params.date_start,
                date_end: this.$route.params.date_end,
                supplier_id: this.$route.params.supplier_id,
                bill_type: this.$route.params.bill_type
            })
                .then(response => {
                    this.convertArray(response.data);
                    this.loadingPage = 0;
                })
                .catch(error => {
                    console.log(error);
                    this.loadingPage = 0;
                });
        }
    },
    destroyed() {
        this.CLEAR_GL();
    }
};
</script>
