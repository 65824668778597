<template>
    <main>
        <hero
            title="Report"
            prevBreadcrumb="Dashboard"
            routeBreadcrumb="home"
            currentBreadcrumb="Report"
        ></hero>

        <!-- Page Content -->
        <div class="content">
            <div
                class="block col-12 block-rounded block-mode-loading-oneui bg-transparent p-0"
                style="min-height: 350px"
            >
                <div class="container py-0">
                    <div class="row">
                        <div class="col-3 p-0">
                            <div
                                class="block mb-0 block-transparent block-report-link"
                                v-bind:class="{
                                    'bg-white': this.reportType == 1
                                }"
                                @click="changeType(1)"
                            >
                                <div class="block-header pb-0">
                                    <h3 class="block-title">
                                        <i class="si si-notebook"></i>
                                        Financial Statement
                                    </h3>
                                </div>
                                <div class="block-content py-1 text-muted-high">
                                    <p>
                                        Examine business activity and business
                                        financial performance
                                    </p>
                                </div>
                            </div>
                            <div
                                class="block mb-0 block-transparent block-report-link"
                                v-bind:class="{
                                    'bg-white': this.reportType == 2
                                }"
                                @click="changeType(2)"
                            >
                                <div class="block-header pb-0">
                                    <h3 class="block-title">
                                        <i class="si si-notebook"></i>
                                        Balance
                                    </h3>
                                </div>
                                <div class="block-content py-1 text-muted-high">
                                    <p>
                                        Reports related to your Ledger &
                                        Accounts
                                    </p>
                                </div>
                            </div>
                            <div
                                class="block mb-0 block-transparent block-report-link"
                                v-bind:class="{
                                    'bg-white': this.reportType == 3
                                }"
                                @click="changeType(3)"
                            >
                                <div class="block-header pb-0">
                                    <h3 class="block-title">
                                        <i class="si si-notebook"></i>
                                        Transaction
                                    </h3>
                                </div>
                                <div class="block-content py-1 text-muted-high">
                                    <p>
                                        Provide detailed informations of all
                                        your transaction
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-9 p-0">
                            <ReportFinancialStatement
                                v-if="this.reportType == 1"
                            ></ReportFinancialStatement>
                            <ReportBalance
                                v-if="this.reportType == 2"
                            ></ReportBalance>
                            <ReportTransaction
                                v-if="this.reportType == 3"
                            ></ReportTransaction>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END Page Content -->
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import moment from "moment";
import ReportBalance from "./component/ReportBalance.vue";
import ReportFinancialStatement from "./component/ReportFinancialStatement.vue";
import ReportTransaction from "./component/ReportTransaction.vue";

export default {
    name: "report",
    components: {
        ReportBalance,
        ReportFinancialStatement,
        ReportTransaction
    },
    data() {
        return {
            reportType: this.$session.has("report_type") ? this.$session.get("report_type") : 1
        };
    },
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState(["auth"])
    },
    mounted() {},
    methods: {
        changeType(val) {
            this.reportType = val;
            this.$session.set("report_type", val);
        }
    }
};
</script>
