import $axios from "../../api.js";

const state = () => ({
    signatures: [],

    signature: {
        signature_position: "",
        signature_name: "",
        signature_type: "",
        signature_bill_quotation: 0,
        signature_bill_order: 0,
        signature_bill_invoice: 0,
        signature_purchase_quotation: 0,
        signature_purchase_order: 0,
        signature_purchase_invoice: 0,
        signature_receive_payment: 0,
        signature_pay_purchase: 0,
        signature_expense: 0,
        signature_income: 0,
        signature_general_ledger: 0,
        signature_trial_balance: 0,
        signature_balance_sheet: 0,
        signature_profit_loss: 0,
        signature_voucher: 0,
        signature_asset: 0,
        signature_inventory: 0
    },
    page: 1, //UNTUK MENCATAT PAGE PAGINATE YANG SEDANG DIAKSES
    last_page: 1,
    search: ""
});

const mutations = {
    //MEMASUKKAN DATA KE STATE NEWS
    ASSIGN_DATA(state, payload) {
        state.signatures = payload;
        state.last_page = payload.meta.last_page;
        state.page = payload.meta.current_page;
    },
    //MEMASUKKAN DATA KE STATE NEWS
    ASSIGN_DATA_ONLY(state, payload) {
        state.signatures = payload;
    },
    //MENGUBAH DATA STATE PAGE
    SET_PAGE(state, payload) {
        state.page = payload;
    },
    //MENGUBAH DATA STATE NEWS
    ASSIGN_FORM(state, payload) {
        state.signature = {
            signature_position: payload.signature_position,
            signature_name: payload.signature_name,
            signature_type: payload.signature_type,
            signature_bill_quotation: payload.signature_bill_quotation,
            signature_bill_order: payload.signature_bill_order,
            signature_bill_invoice: payload.signature_bill_invoice,
            signature_purchase_quotation: payload.signature_purchase_quotation,
            signature_purchase_order: payload.signature_purchase_order,
            signature_purchase_invoice: payload.signature_purchase_invoice,
            signature_receive_payment: payload.signature_receive_payment,
            signature_pay_purchase: payload.signature_pay_purchase,
            signature_expense: payload.signature_expense,
            signature_income: payload.signature_income,
            signature_general_ledger: payload.signature_general_ledger,
            signature_trial_balance: payload.signature_trial_balance,
            signature_balance_sheet: payload.signature_balance_sheet,
            signature_profit_loss: payload.signature_profit_loss,
            signature_voucher: payload.signature_voucher,
            signature_asset: payload.signature_asset,
            signature_inventory: payload.signature_inventory
        };
    },
    //ME-RESET STATE NEWS MENJADI KOSONG
    CLEAR_FORM(state) {
        state.signature = {
            signature_position: "",
            signature_name: "",
            invoice_sign: 0,
            po_sign: 0,
            signature_type: "",
            signature_bill_quotation: 0,
            signature_bill_order: 0,
            signature_bill_invoice: 0,
            signature_purchase_quotation: 0,
            signature_purchase_order: 0,
            signature_purchase_invoice: 0,
            signature_receive_payment: 0,
            signature_pay_purchase: 0,
            signature_expense: 0,
            signature_income: 0,
            signature_general_ledger: 0,
            signature_trial_balance: 0,
            signature_balance_sheet: 0,
            signature_profit_loss: 0,
            signature_voucher: 0,
            signature_asset: 0,
            signature_inventory: 0
        };
    },
    //ME-RESET STATE NEWS MENJADI KOSONG
    CLEAR_DATA(state) {
        state.signatures = [];
    }
};

const actions = {
    //FUNGSI INI UNTUK MELAKUKAN REQUEST DATA NEWS DARI SERVER
    getSignature({ dispatch, commit, state }, payload) {
        //MENGECEK PAYLOAD ADA ATAU TIDAK
        let search = typeof payload.search != "undefined" ? payload.search : "";
        let rows = payload.rows;
        let page =
            typeof payload.rows != "All" && payload.rowsUpdate != 1
                ? state.page
                : "1";
        let sort = payload.sort;
        let asc = payload.asc != false ? "DESC" : "ASC";

        return new Promise((resolve, reject) => {
            //REQUEST DATA DENGAN ENDPOINT /NEWS
            $axios
                .get(
                    `/signature?page=${page}&search=${search}&rows=${rows}&sort=${sort}&asc=${asc}`
                )
                .then(response => {
                    //SIMPAN DATA KE STATE MELALUI MUTATIONS
                    commit("ASSIGN_DATA", response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                    reject(error);
                });
        });
    },
    //FUNGSI INI UNTUK MELAKUKAN REQUEST DATA NEWS DARI SERVER
    getSignatureDocument({ dispatch, commit, state }, payload) {
        return new Promise((resolve, reject) => {
            //REQUEST DATA DENGAN ENDPOINT /NEWS
            $axios
                .get(`/signature/document?doc=${payload.document}`)
                .then(response => {
                    //SIMPAN DATA KE STATE MELALUI MUTATIONS
                    commit("ASSIGN_DATA_ONLY", response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                    reject(error);
                });
        });
    },
    //FUNGSI UNTUK MENAMBAHKAN DATA BARU
    submitSignature({ dispatch, commit, state }) {
        return new Promise((resolve, reject) => {
            //MENGIRIMKAN PERMINTAAN KE SERVER DAN MELAMPIRKAN DATA YANG AKAN DISIMPAN
            //DARI STATE NEWS
            $axios
                .post(`/signature`, state.signature)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                });
        });
    },
    //UNTUK MENGAMBIL SINGLE DATA DARI SERVER BERDASARKAN CODE NEWS
    editSignature({ commit }, payload) {
        return new Promise((resolve, reject) => {
            //MELAKUKAN REQUEST DENGAN MENGIRIMKAN CODE NEWS DI URL
            $axios
                .get(`/signature/${payload}/edit`)
                .then(response => {
                    //APABIL BERHASIL, DI ASSIGN KE FORM
                    commit("ASSIGN_FORM", response.data.data);
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    //UNTUK MENGUPDATE DATA BERDASARKAN CODE YANG SEDANG DIEDIT
    updateSignature({ state, commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .put(`/signature/${payload}`, state.signature)
                .then(response => {
                    //FORM DIBERSIHKAN
                    commit("CLEAR_FORM");
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                });
        });
    },
    //MENGHAPUS DATA
    removeSignature({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .delete(`/signature/${payload}`)
                .then(response => {
                    commit("CLEAR_FORM");
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
