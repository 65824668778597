<template>
    <main>
        <hero title="Recurring Journal" prevBreadcrumb="Dashboard" routeBreadcrumb="home" currentBreadcrumb="Recurring Journal"></hero>

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 1 || !recurringjournals.data"
                style="height:250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity:0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <!-- Dynamic Table Full -->
            <div class="block block-rounded block-mode-loading-oneui" v-else>
                <div class="block-content block-content-full pb-0 pt-3">
                    <div class="row">
                        <!-- <div
                            class="col-12 col-md-7 col-lg-7 col-xl-7 mb-2 mb-sm-0 pb-0 px-0 d-md-none d-inline"
                        >
                            <label class="col-12 col-md-1 py-0">Date :</label>
                            <date-picker
                                v-model="date_start"
                                type="date"
                                format="DD/MM/YYYY"
                                placeholder="Datepicker"
                                class="col-12"
                                @input="getResult()"
                            ></date-picker>
                            <date-picker
                                v-model="date_end"
                                type="date"
                                format="DD/MM/YYYY"
                                placeholder="Datepicker"
                                class="col-12"
                                @input="getResult()"
                            ></date-picker>
                        </div>
                        <div
                            class="col-12 col-md-7 col-lg-7 col-xl-7 mb-2 mb-sm-0 pb-0 d-md-inline d-none"
                        >
                            <label>Date :</label>
                            <date-picker
                                v-model="date_start"
                                type="date"
                                format="DD/MM/YYYY"
                                placeholder="Datepicker"
                                @input="getResult()"
                            ></date-picker>
                            <date-picker
                                v-model="date_end"
                                type="date"
                                format="DD/MM/YYYY"
                                placeholder="Datepicker"
                                @input="getResult()"
                            ></date-picker>
                        </div> -->
                    </div>
                </div>
                <div class="block-content block-content-full pb-0 pt-0">
                    <div class="row">
                        <div class="col-12 col-md-7 mb-2 mb-sm-0 pb-0">
                            <label>Show :</label>
                            <select
                                class="form-control-sm custom-select"
                                style="max-width:115px"
                                v-model="rows"
                            >
                                <option value="10">10 Rows</option>
                                <option value="20">20 Rows</option>
                                <option value="50">50 Rows</option>
                                <option value="100">100 Rows</option>
                                <option value="All">All Rows</option>
                            </select>
                        </div>
                        <div
                            class="col-12 col-md-5 col-lg-5 col-xl-5 pb-0 pt-0 pt-md-3"
                        >
                            <input
                                type="text"
                                class="form-control form-control col-12 col-md-8 float-right search-index"
                                name="example-text-input"
                                placeholder="Search.."
                                v-model="search"
                            />
                        </div>
                    </div>
                </div>
                <div class="block-content block-content-full">
                    <div class="table-responsive">
                        <table class="table table-vcenter">
                            <thead style="background-color: whitesmoke;">
                                <tr>
                                    <th
                                        class="table-header-relative column-minwidth-150"
                                    >
                                        Recurring Name
                                    </th>
                                    <th class="table-header-relative">
                                        Created at
                                    </th>
                                    <th
                                        class="table-header-relative column-minwidth-250"
                                    >
                                        Accounts
                                    </th>
                                    <th
                                        class="table-header-relative column-minwidth-150"
                                    >
                                        Debit
                                    </th>
                                    <th
                                        class="table-header-relative column-minwidth-150"
                                    >
                                        Credit
                                    </th>
                                    <th class="table-header-relative"></th>
                                </tr>
                            </thead>
                            <tbody
                                v-if="
                                    recurringjournals.data &&
                                        recurringjournals.data.length > 0
                                "
                            >
                                <tr v-if="loadingPage == 2">
                                    <td colspan="6">
                                        <div
                                            class="spinner-border spinner-border-lg text-info mx-auto mt-5"
                                            role="status"
                                            style="display: flex;
                                            justify-content: center;"
                                        ></div>
                                        <center class="mt-2 mb-4">
                                            <span
                                                class="text-center"
                                                style="opacity:0.7"
                                                >Loading</span
                                            >
                                        </center>
                                    </td>
                                </tr>
                                <tr
                                    v-else
                                    v-for="(recurring,
                                    index) in recurringjournals.data"
                                    :key="index"
                                >
                                    <td>{{ recurring.recurring_name }}</td>
                                    <td align="center">
                                        {{
                                            recurring.created_at
                                                | moment("DD/MM/YYYY")
                                        }}
                                    </td>
                                    <td style="white-space: nowrap;">
                                        <span
                                            v-for="(detail,
                                            index2) in recurring.detail"
                                            :key="index2"
                                        >
                                            {{ detail.coa.account_no }}
                                            {{ detail.coa.account_name }}
                                            <br />
                                        </span>
                                    </td>
                                    <td align="right">
                                        <span
                                            v-for="(detail,
                                            index2) in recurring.detail"
                                            :key="index2"
                                        >
                                            <span v-if="detail.debit != 0">{{
                                                detail.debit | currency
                                            }}</span>
                                            <br />
                                        </span>
                                    </td>
                                    <td align="right">
                                        <span
                                            v-for="(detail,
                                            index2) in recurring.detail"
                                            :key="index2"
                                        >
                                            <span v-if="detail.credit != 0">{{
                                                detail.credit | currency
                                            }}</span>
                                            <br />
                                        </span>
                                    </td>
                                    <td class="font-size-sm" align="center">
                                        <a
                                            class="btn-icon-only btn-icon-danger"
                                            @click="
                                                deleteRecurring(recurring.id)
                                            "
                                        >
                                            <i class="fa fa-trash"></i>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr class="data-empty">
                                    <td
                                        class="text-center"
                                        colspan="6"
                                        style="opacity:0.8; padding:2.5rem;"
                                    >
                                        <center>
                                            <img
                                                :src="
                                                    '/media/proxima/empty-data.svg'
                                                "
                                                style="height: 100px;"
                                            />
                                        </center>
                                        <br /><span style="opacity:0.6;"
                                            >Data Not Found</span
                                        >
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p
                            class="text-muted font-size-sm d-inline d-sm-none"
                            style="opacity:0.5;"
                        >
                            Swipe left here to see full column >>
                        </p>
                    </div>

                    <paginate
                        v-model="page"
                        :page-count="
                            this.$store.state.recurringjournal.last_page
                        "
                        :prev-text="'Prev'"
                        :next-text="'Next'"
                        :container-class="'pagination mt-3 mt-md-0'"
                        :pageClass="'page-item'"
                        :pageLinkClass="'page-link'"
                        :prevClass="'page-item'"
                        :prevLinkClass="'page-link'"
                        :nextClass="'page-item'"
                        :nextLinkClass="'page-link'"
                    ></paginate>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
    name: "recurring-journal",
    data() {
        return {
            search: "",
            awaitingSearch: false,
            rows: 100,
            loadingPage: 1,
            type:
                this.$route.params.type != null
                    ? this.$route.params.type
                    : "All Accounts"
            // date_start: new Date(
            //     new Date().getFullYear(),
            //     new Date().getMonth(),
            //     1
            // ),
            // date_end: new Date(
            //     new Date().getFullYear(),
            //     new Date().getMonth(),
            //     new Date().getDate()
            // )
        };
    },
    computed: {
        ...mapState("recurringjournal", {
            recurringjournals: state => state.recurringjournals
        }),
        page: {
            get() {
                return this.$store.state.recurringjournal.page
                    ? this.$store.state.recurringjournal.page
                    : 1;
            },
            set(val) {
                this.$store.commit("recurringjournal/SET_PAGE", val);
                this.getRecurring({
                    search: this.search,
                    rows: this.rows,
                    rowsUpdate: 0
                });
            }
        }
    },
    watch: {
        search() {
            this.loadingPage = 2;
            if (!this.awaitingSearch) {
                setTimeout(() => {
                    this.getRecurring({
                        search: this.search,
                        rows: this.rows
                        // date_start: new Date(this.date_start).toDateString(),
                        // date_end: new Date(this.date_end).toDateString()
                    })
                        .then(response => {
                            this.loadingPage = 0;
                        })
                        .catch(error => {
                            console.log(error);
                            this.loadingPage = 0;
                        });
                    this.awaitingSearch = false;
                }, 1500);
            }
            this.awaitingSearch = true;
        },
        rows() {
            this.getRecurring({
                search: this.search,
                rows: this.rows,
                rowsUpdate: 1,
                type: this.type
            });
        }
    },
    mounted() {
        this.getRecurring({
            search: this.search,
            rows: this.rows
            // date_start: new Date(this.date_start).toDateString(),
            // date_end: new Date(this.date_end).toDateString()
        })
            .then(response => {
                this.loadingPage = 0;
            })
            .catch(error => {
                console.log(error);
                this.loadingPage = 0;
            });
    },
    methods: {
        ...mapActions("recurringjournal", ["getRecurring", "removeRecurring"]),
        alert(text, tipe) {
            if (tipe === 1) {
                this.$swal({
                    title: "Succes",
                    text: text,
                    icon: "success"
                });
            } else if (tipe === 2) {
                this.$swal({
                    title: "Something Wrong",
                    text: text,
                    icon: "error"
                });
            } else if (tipe === 3) {
                this.$swal({
                    title: "Are you sure ?",
                    text: text,
                    icon: "warning",
                    buttons: ["Cancel", "Delete"],
                    dangerMode: true
                }).then(willDelete => {
                    if (willDelete) {
                        this.alert("Data has been deleted !", 1);
                    }
                });
            } else if (tipe === 4) {
                this.$swal({
                    title: "Are you sure ?",
                    text: text,
                    icon: "warning",
                    buttons: ["Cancel", "Update"],
                    dangerMode: false
                }).then(willDelete => {
                    if (willDelete) {
                        this.alert("Data has been deleted !", 1);
                    }
                });
            }
        },
        deleteRecurring(id) {
            this.loadingPage = 1;
            this.$swal({
                title: "Are you sure to delete this recurring?",
                text: "Data cannot be recovered.",
                icon: "warning",
                buttons: ["Cancel", "Delete"],
                dangerMode: true
            }).then(willDelete => {
                if (willDelete) {
                    this.removeRecurring(id)
                        .then(response => {
                            this.alert("Successfully Delete Data", 1);
                            this.getRecurring({
                                search: this.search,
                                rows: this.rows,
                                date_start: new Date(
                                    this.date_start
                                ).toDateString(),
                                date_end: new Date(this.date_end).toDateString()
                            });
                            this.loadingPage = 0;
                        })
                        .catch(error => {
                            this.alert(error.message, 2);
                            this.loadingPage = 0;
                        });
                }
                this.loadingPage = 0;
            });
        }
    }
};
</script>
