<template>
    <main>
        <hero title="Detail Notification" prevBreadcrumb="Dashboard" routeBreadcrumb="home" currentBreadcrumb="Detail Notification"></hero>

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 1"
                style="height: 250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <!-- Detail Notification -->
            <div class="row" v-else>
                <div class="col-md-8">
                    <div class="block">
                        <div class="block-content">
                            <div class="row items-push mb-2">
                                <h2 class="block-title"> {{ notification.title }}  <small> {{ notification.type }} </small></h2>
                                <router-link
                                
                                    type="button"
                                    class="btn btn-sm btn-alt-success"
                                    tag="button"
                                    v-ripple="{ center: true }"
                                    :to="{
                                        name: 'notification-edit',
                                        params: { id: notification.id }
                                    }"
                                >
                                    <i class="far fa-edit mr-1"></i> Edit                                 
                                </router-link>
                                <router-link
                                    type="button"
                                    class="btn btn-sm btn-alt-light"
                                    tag="button"
                                    v-ripple="{ center: true }"
                                    :to="{ name: 'notification' }"
                                >
                                    <i class="fa fa-fw fa-times mr-1"></i> Close
                                </router-link>                       
                            </div>
                            <p style="text-indent: 45px;" align="justify"> {{ notification.content }} </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
    name: "notification",
    data() {
        return {
            loadingPage: 1,
        };
    },
    mounted() {
        if (this.$route.params.id) {
            this.editNotification(this.$route.params.id)
                .then(response => {
                    this.loadingPage = 0;
                })
                .catch(error => {
                    console.log(error);
                    this.loadingPage = 0;
                });
        } else {
            this.$router.push({ name: "notification" });
            this.alert("Data not found, select data first", 2);
        }
    },
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState("notification", {
            notification: state => state.notification
        }),
    },
    methods: {
        ...mapActions("notification", ["editNotification"]),
    },
};
</script>


