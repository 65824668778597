import $axios from "../../api.js";

const state = () => ({
    activities: [],
    allActivities: []
});

const mutations = {
    //MEMASUKKAN DATA KE STATE activities
    ASSIGN_DATA(state, payload) {
        state.activities = payload;
    },
    //ME-RESET STATE NEWS MENJADI KOSONG
    CLEAR_DATA(state) {
        state.activities = [];
    },
    //MEMASUKKAN DATA KE STATE all activites
    ASSIGN_DATA_ALL(state, payload) {
        state.allActivities = payload;
    },
    //ME-RESET STATE NEWS MENJADI KOSONG
    CLEAR_DATA_ALL(state) {
        state.allActivities = [];
    }
};

const actions = {
    //FUNGSI INI UNTUK MELAKUKAN REQUEST DATA NEWS DARI SERVER
    getActivities({ dispatch, commit, state }, payload) {
        return new Promise((resolve, reject) => {
            //REQUEST DATA DENGAN ENDPOINT /NEWSnot
            $axios
                .get(`/activity`)
                .then(response => {
                    //SIMPAN DATA KE STATE MELALUI MUTATIONS
                    commit("ASSIGN_DATA", response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    error.response.status !== 401
                        ? reject(error)
                        : dispatch("DO_LOGOUT", null, { root: true });
                });
        });
    },
    //FUNGSI INI UNTUK MELAKUKAN REQUEST DATA NEWS DARI SERVER
    getAllActivities({ dispatch, commit, state }, payload) {
        return new Promise((resolve, reject) => {
            let date_start = payload.date_start;
            let date_end = payload.date_end;

            //REQUEST DATA DENGAN ENDPOINT /NEWS
            $axios
                .get(
                    `/activity/retrive-all-data?date_start=${date_start}&date_end=${date_end}`
                )
                .then(response => {
                    //SIMPAN DATA KE STATE MELALUI MUTATIONS
                    commit("ASSIGN_DATA_ALL", response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    error.response.status !== 401
                        ? reject(error)
                        : dispatch("DO_LOGOUT", null, { root: true });
                });
        });
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
