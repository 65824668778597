<template>
    <main>
        <hero title="Create UOM" prevBreadcrumb="UOM Data" routeBreadcrumb="uom" currentBreadcrumb="Create UOM"></hero>

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <div
                class="block col-12 col-md-8 offset-md-2 block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 1"
                style="height: 250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <div
                class="block col-12 col-md-8 offset-md-2 block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 2"
                style="height: 250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Save Data</span
                        >
                    </center>
                </div>
            </div>
            <!-- Basic -->
            <div
                class="block col-12 col-md-8 offset-md-2  block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 0"
            >
                <div class="block-content block-content-full">
                    <form
                        method="POST"
                        encuom="multipart/form-data"
                        onsubmit="return false;"
                    >
                        <!--Form-->
                        <div class="row font-size-sm">
                            <div class="col-12 col-md-10 offset-md-1">
                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Unit Name *</label
                                    >
                                    <input
                                        uom="text"
                                        class="form-control"
                                        placeholder="Unit Name"
                                        v-model="uom.uom_name"
                                        :class="{
                                            'is-invalid': errors.uom_name,
                                        }"
                                    />
                                    <transition name="slide-fade">
                                        <p
                                            class="text-danger"
                                            v-if="errors.uom_name"
                                        >
                                            {{ errors.uom_name[0] }}
                                        </p>
                                    </transition>
                                </div>
                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Unit Code *</label
                                    >
                                    <input
                                        uom="text"
                                        class="form-control"
                                        placeholder="Unit Code"
                                        v-model="uom.uom_code"
                                        :class="{
                                            'is-invalid': errors.uom_code,
                                        }"
                                    />
                                    <transition name="slide-fade">
                                        <p
                                            class="text-danger"
                                            v-if="errors.uom_code"
                                        >
                                            {{ errors.uom_code[0] }}
                                        </p>
                                    </transition>
                                </div>
                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Unit Description</label
                                    >
                                    <input
                                        uom="text"
                                        class="form-control"
                                        placeholder="Unit Description"
                                        v-model="uom.uom_desc"
                                        :class="{
                                            'is-invalid': errors.uom_desc,
                                        }"
                                    />
                                    <transition name="slide-fade">
                                        <p
                                            class="text-danger"
                                            v-if="errors.uom_desc"
                                        >
                                            {{ errors.uom_desc[0] }}
                                        </p>
                                    </transition>
                                </div>
                                <div class="row">
                                    <div class="form-group col-6 pb-1 pt-0">
                                        <label for="example-text-input"
                                            >Status *</label
                                        >
                                        <selectize
                                            class="form-control mt-1"
                                            placeholder="Choose Status"
                                            v-model="uom.uom_status"
                                            :class="{
                                                'is-invalid-selectize':
                                                    errors.uom_status,
                                            }"
                                        >
                                            <option value="1">
                                                Active
                                            </option>
                                            <option value="0">
                                                Inactive
                                            </option>
                                        </selectize>
                                        <transition name="slide-fade">
                                            <p
                                                class="text-danger"
                                                v-if="errors.uom_status"
                                            >
                                                {{ errors.uom_status[0] }}
                                            </p>
                                        </transition>
                                    </div>
                                    <div class="form-group col-6 pb-1 pt-0">
                                        <label for="example-text-input"
                                            >Type *</label
                                        >
                                        <selectize
                                            class="form-control mt-1"
                                            placeholder="Choose Account No."
                                            v-model="uom.uom_type"
                                            :class="{
                                                'is-invalid-selectize':
                                                    errors.uom_type,
                                            }"
                                        >
                                            <option value="1">
                                                Base Unit
                                            </option>
                                            <option value="2">
                                                Conversion Unit
                                            </option>
                                        </selectize>
                                        <transition name="slide-fade">
                                            <p
                                                class="text-danger"
                                                v-if="errors.uom_type"
                                            >
                                                {{ errors.uom_type[0] }}
                                            </p>
                                        </transition>
                                    </div>
                                </div>
                                <!-- <hr v-if="uom.uom_type == 2"> -->
                                <div class="row" v-if="uom.uom_type == 2">
                                    <div class="form-group col-6 pb-1 pt-0">
                                        <label for="example-text-input"
                                            >Base Unit</label
                                        >
                                        <selectize
                                            class="form-control"
                                            placeholder="Choose Type"
                                            v-model="
                                                uom.uom_conversion_head
                                            "
                                            :class="{
                                                'is-invalid-selectize':
                                                    errors.uom_conversion_head,
                                            }"
                                        >
                                            <!-- settings is optional -->
                                            <option
                                                v-for="uomdata in uoms"
                                                :value="uomdata.id"
                                                :key="uomdata.id"
                                            >
                                                {{ uomdata.uom_name }}
                                            </option>
                                        </selectize>
                                        <transition name="slide-fade">
                                            <p
                                                class="text-danger"
                                                v-if="errors.uom_conversion_head"
                                            >
                                                {{ errors.uom_conversion_head[0] }}
                                            </p>
                                        </transition>
                                    </div>
                                    <div class="form-group col-6 pb-1 pt-0">
                                        <label for="example-text-input"
                                            >Value</label
                                        >
                                        <input
                                            uom="text"
                                            class="form-control"
                                            placeholder="Unit Name"
                                            v-model="uom.uom_conversion_val"
                                            :class="{
                                                'is-invalid': errors.uom_name,
                                            }"
                                        />
                                        <transition name="slide-fade">
                                            <p
                                                class="text-danger"
                                                v-if="errors.uom_name"
                                            >
                                                {{ errors.uom_name[0] }}
                                            </p>
                                        </transition>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--END Form-->
                        <!-- Submit -->
                        <div class="row items-push">
                            <div class="col-12 text-center">
                                <p class="font-size-sm text-muted">
                                    * Required filled, Make sure all data is
                                    correctly filled
                                </p>
                                <button
                                    uom="submit"
                                    class="btn btn-sm btn-success"
                                    v-ripple="{ center: true }"
                                    @click.prevent="submit"
                                >
                                    <i class="fa fa-fw fa-check mr-1"></i> Save
                                </button>
                                <router-link
                                    uom="button"
                                    class="btn btn-sm btn-alt-light"
                                    tag="button"
                                    v-ripple="{ center: true }"
                                    :to="{ name: 'uom' }"
                                >
                                    <i class="fa fa-fw fa-times mr-1"></i> Close
                                </router-link>
                            </div>
                        </div>
                        <!-- END Submit -->
                    </form>
                </div>
            </div>
            <!-- END Basic -->
        </div>
        <!-- END Page Content -->
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

export default {
    name: "create-uom",

    data() {
        return {
            loadingPage: 0,
        };
    },
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState("uom", { uoms: (state) => state.uoms }),
        ...mapState("uom", {
            uom: (state) => state.uom,
        }),
    },
    mounted() {
        this.getUomAll();
    },
    methods: {
        //VUEX
        ...mapMutations("uom", ["CLEAR_FORM"]),
        ...mapActions("uom", ["getUomAll", "submitUom"]),

        isNumber: function (evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46
            ) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        submit() {
            this.loadingPage = 2;
            this.submitUom()
                .then((response) => {
                    this.$router.push({ name: "uom" });
                    this.alert("Successfully create Unit Data ", 1);
                    this.loadingPage = 0;
                })
                .catch((error) => {
                    error.response.status != 422
                        ? this.alert(error.message, 2)
                        : "";
                    this.loadingPage = 0;
                });
        },
    },
    destroyed() {
        //FORM DI BERSIHKAN
        this.CLEAR_FORM();
    },
};
</script>
