<template>
    <main>
        <hero title="Detail Item" prevBreadcrumb="Products & Services" routeBreadcrumb="products-services" currentBreadcrumb="Detail Item"></hero>

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <!-- Invoice -->
            <div class="block mb-4 block-rounded block-mode-loading-oneui">
                <div class="block-header">
                    <div class="block-title">
                        <span class="pill-item-code mr-1">{{ item.item_code }}</span>
                        <span class="pill-item-code disabled" v-if="item.item_status == 0">Archived</span>
                        <br />
                        <span class="pt-1 d-inline-block">{{
                            item.item_name
                        }}</span>
                    </div>
                    <div class="block-options">
                        <button
                            type="button"
                            class="btn btn-sm btn-outline-info mr-1"
                            @click="showAdjustmentModal()"
                            v-if="item.item_status == 1"
                        >
                            <i class="fa fa-fw fa-plus"></i>
                            <span class="d-none d-md-inline-block">
                                Adjustment
                            </span>
                        </button>
                        <div class="dropdown">
                            <button
                                type="button"
                                class="btn btn-sm btn-info dropdown-toggle"
                                id="dropdown-default-primary"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                Manage
                            </button>
                            <div
                                class="dropdown-menu dropdown-menu-right dropdown-menu-rounded-a font-size-sm mt-2"
                                aria-labelledby="dropdown-default-primary"
                            >
                                <router-link
                                    :to="{
                                        name: 'products-services-edit',
                                        params: { id: item.item_id }
                                    }"
                                    tag="a"
                                    class="dropdown-item"
                                >
                                    <i class="si si-pencil mr-1"></i>
                                    <span class="d-none d-md-inline-block">
                                        Edit Data
                                    </span>
                                </router-link>
                                <a
                                    class="dropdown-item"
                                    href="javascript:void(0)"
                                    ><i class="si si-printer mr-1"></i>
                                    <span class="d-none d-md-inline-block"
                                        >Print Document</span
                                    ></a
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--Loading-->
            <div class="block" v-if="loadingPage == 1" style="height: 250px">
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <!-- Basic -->
            <div class="block block-rounded block-mode-loading-oneui" v-else>
                <div class="block-content">
                    <v-tabs
                        background-color="white"
                        style="border-radius: 8px;"
                    >
                        <v-tab
                            v-for="tab in tabs"
                            :key="tab"
                            @click="typeChange(tab)"
                            :disabled="loadingPage == 2"
                            v-bind:class="{
                                'v-tab--active': tab === activeTab,
                                'v-tab--active--false': tab !== activeTab
                            }"
                        >
                            {{ tab }}
                        </v-tab>
                    </v-tabs>
                </div>
                <div class="block-content" id="printMe" v-if="activeTab == 'General'">
                    <div class="p-0 p-md-2">
                        <!--Form-->
                        <div class="row font-size-sm">
                            <div class="col-12 py-0">
                                <div class="row pt-2">
                                    <div class="col-3 py-0">
                                        <div class="form-group border-r-1 pr-3 mb-1">
                                            <p>Quantity on Hand <i class="si si-info" data-toggle="popover" data-placement="top" title="Quantity on Hand" data-content="This is example content. You can put a description or more info here."></i></p>
                                            <p class="pb-3 font-weight-bolder font-size-h5 mb-2">
                                                {{item.item_qty}}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-3 py-0 pl-0">
                                        <div class="form-group border-r-1 pr-3 mb-1">
                                            <p>Total Value</p>
                                            <p class="mb-0 font-weight-bolder font-size-h5">
                                                {{item.item_value | currency}}
                                            </p>
                                            <p class="mb-2 text-muted font-small">
                                                {{item.item_value/item.item_qty | currency}}
                                                <i class="si si-info" data-toggle="popover" data-placement="top" title="Average Price" data-content="Current Average Price"></i>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-3 py-0 pl-0">
                                        <div class="form-group border-r-1 pr-3 mb-1">
                                            <p>on Hold <i class="si si-info" data-toggle="popover" data-placement="top" title="On Hold" data-content="This is example content. You can put a description or more info here."></i></p>
                                            <p class="pb-3 font-weight-bolder font-size-h5 mb-2">
                                                {{this.onHandQty}}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-3 py-0 pl-0">
                                        <div class="form-group mb-1">
                                            <p>on Order <i class="si si-info" data-toggle="popover" data-placement="top" title="On Order" data-content="This is example content. You can put a description or more info here."></i></p>
                                            <p class="pb-3 font-weight-bolder font-size-h5 mb-2">
                                                {{this.onOrderQty}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <hr class="my-0" />
                            </div>
                        </div>
                        <!--END Form-->
                        <p class="mt-4 mb-2 form-group font-weight-bold">Transaction History</p>
                        <!-- Table -->
                        <div class="table-responsive push">
                            <table class="table table-bordered">
                                <thead class="bg-body-light">
                                    <tr>
                                        <th
                                            class="text-center align-middle"
                                            style="
                                                width: 60px;
                                            "
                                        >
                                            Date
                                        </th>
                                        <th
                                            class="text-center align-middle"
                                            style="
                                                font-size: 11px !important;
                                            "
                                        >
                                            Reference
                                        </th>
                                        <th
                                            class="text-center"
                                            colspan="2"
                                            style="
                                                font-size: 11px !important;
                                            "
                                        >
                                            Quantity
                                        </th>
                                        <th
                                            class="text-center"
                                            style="
                                                font-size: 11px !important;
                                            "
                                        >
                                            Cost
                                        </th>
                                        <th
                                            class="text-center"
                                            style="
                                                font-size: 11px !important;
                                            "
                                        >
                                            Amount
                                        </th>
                                    </tr>
                                </thead>
                                <tbody
                                    v-if="
                                        item.datamutation.length > 0 &&
                                            loadingPage == 0
                                    "
                                >
                                    <tr v-if="item.opening_qty != 0">
                                        <td class="text-center align-middle">
                                            {{
                                                item.opening_date
                                                    | moment("DD/MM/YYYY")
                                            }}
                                        </td>
                                        <td class="align-middle">
                                            <span class="text-info font-weight-bold"> Opening Balance</span>
                                        </td>
                                        <td class="text-center align-middle">
                                            <span v-if="item.opening_qty > 0 "> + {{ item.opening_qty }}</span>
                                        </td>
                                        <td class="text-center align-middle">
                                            <span v-if="item.opening_qty < 0 "> + {{ item.opening_qty }}</span>
                                        </td>
                                        <td class="text-right align-middle">
                                            <span v-if="item.opening_price != 0 ">{{ item.opening_price | currency }}</span>
                                        </td>
                                        <td class="text-right align-middle">
                                            <span v-if="item.opening_amount != 0 ">{{ item.opening_amount | currency }}</span>
                                        </td>
                                    </tr>
                                    <tr
                                        v-for="(detail,
                                        index) in item.datamutation"
                                        :key="index"
                                    >
                                        <td class="text-center align-middle">
                                            {{
                                                detail.mutation_date
                                                    | moment("DD/MM/YYYY")
                                            }}
                                        </td>
                                        <td class="align-middle">
                                                <router-link
                                                    :to="{
                                                        name: 'adjustment-detail',
                                                        params: { id: detail.mutation_id },
                                                    }"
                                                    class="alert-link"
                                                    tag="a"
                                                >
                                                    {{ detail.mutation_type == 1 ? 'Adjustment In' : ''}} 
                                                    {{ detail.mutation_type == 2 ? 'Adjustment Out' : ''}} 
                                                    {{ detail.mutation_type == 3 ? 'Purchase Adjustment' : ''}} 
                                                    {{ detail.mutation_type == 4 ? 'Bill Adjustment' : ''}} 
                                                    {{ detail.mutation_no }}
                                                </router-link>
                                        </td>
                                        <td class="text-center align-middle">
                                            <span v-if="detail.in_qty != 0 "> + {{ detail.in_qty }}</span>
                                        </td>
                                        <td class="text-center align-middle">
                                            <span v-if="detail.out_qty != 0 "> - {{ detail.out_qty }}</span>
                                        </td>
                                        <td class="text-right align-middle">
                                            <span v-if="detail.in_price != 0 ">{{ detail.in_price | currency }}</span>
                                            <span v-if="detail.out_price != 0 ">{{ detail.out_price | currency }}</span>
                                        </td>
                                        <td class="text-right align-middle">
                                            <span v-if="detail.in_amount != 0 ">{{ detail.in_amount | currency }}</span>
                                            <span v-if="detail.out_amount != 0 ">{{ detail.out_amount | currency }}</span>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr class="data-empty">
                                        <td
                                            class="text-center"
                                            colspan="12"
                                            style="
                                                opacity: 0.8;
                                                padding: 2.5rem;
                                            "
                                        >
                                            <span style="opacity: 0.6"
                                                >No Data Mutation</span
                                            >
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- END Table -->
                        <!-- <p class="text-center" style="opacity: 0.5">
                            No Depreciation Data
                        </p> -->
                        <hr class="mt-3" />
                        <p
                            class="d-none d-print-inline-block"
                            style="opacity: 0.6"
                        >
                            <i> Printed : {{ dateNow }} </i>
                        </p>
                        <!-- END Footer -->
                    </div>
                </div>
                <div class="block-content" id="printMe" v-if="activeTab == 'Bookkeeping'">
                    <div class="p-0 p-md-2">
                        <!--Form-->
                        <div class="row font-size-sm">
                            <div class="col-12 py-0">
                                <div class="row pt-2">
                                    <div class="col-5"></div>
                                </div>
                            </div>
                            <div class="col-12 py-0">
                                <div class="row pt-2">
                                    <div class="col-4 py-0">
                                        <div class="form-group mb-1">
                                            <p class="font-weight-bolder font-size-h5 mb-2">Purchasing</p>
                                            <div class="row" v-if="item.is_purchase == 1">
                                                <div class="col-6">
                                                    <p class="text-muted-high mb-0">Purchase Price</p>
                                                    <p class="mb-0">{{item.purchase_price | currency}}</p>
                                                </div>
                                                <div class="col-6">
                                                    <p class="text-muted-high mb-0">Applied Tax on Purchase</p>
                                                    <p class="mb-0">{{item.tax_purchase.tax_name}} ({{item.tax_purchase.tax_code}})</p>
                                                </div>
                                                <div class="col-12" v-if="item.is_inventory == 0">
                                                    <p class="text-muted-high mb-0">Purchase Account</p>
                                                    <p class="mb-0"><b>{{item.coa_expense.account_no}}</b> {{item.coa_expense.account_name}}</p>
                                                </div>
                                                <div class="col-12">
                                                    <p class="text-muted-high mb-0">Purchase Description</p>
                                                    <p class="mb-0">Purchase MS-LGT170 Mouse Logitech B 170 Wireless</p>
                                                </div>
                                            </div>
                                            <div class="row" v-else>
                                                <div class="col-12">
                                                    <p class="text-muted-high mb-0">You do not Purchase this Item</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4 py-0">
                                        <div class="form-group mb-1">
                                            <p class="font-weight-bolder font-size-h5 mb-2">Selling</p>
                                            <div class="row"  v-if="item.is_sell == 1">
                                                <div class="col-6">
                                                    <p class="text-muted-high mb-0">Selling Price</p>
                                                    <p class="font-size-md mb-0">{{item.sell_price | currency}}</p>
                                                </div>
                                                <div class="col-6">
                                                    <p class="text-muted-high mb-0">Applied Tax on Selling</p>
                                                    <p class="font-size-md mb-0">{{item.tax_selling.tax_name}} ({{item.tax_selling.tax_code}}) </p>
                                                </div>
                                                <div class="col-12">
                                                    <p class="text-muted-high mb-0">Selling Account</p>
                                                    <p class="font-size-md mb-0"><b>{{item.coa_income.account_no}}</b> {{item.coa_income.account_name}}</p>
                                                </div>
                                                <div class="col-12">
                                                    <p class="text-muted-high mb-0">Selling Description</p>
                                                    <p class="font-size-md mb-0">Purchase MS-LGT170 Mouse Logitech B 170 Wireless</p>
                                                </div>
                                            </div>
                                            <div class="row" v-else>
                                                <div class="col-12">
                                                    <p class="text-muted-high mb-0">You do not Sell this Item</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4 py-0">
                                        <div class="form-group mb-1">
                                            <p class="font-weight-bolder font-size-h5 mb-2">Tracking</p>
                                            <div class="row"  v-if="item.is_inventory == 1">
                                                <div class="col-12">
                                                    <p class="text-muted-high mb-0">Inventory Tracking Account</p>
                                                    <p class="font-size-md mb-0"><b>{{item.coa_inventory.account_no}}</b> {{item.coa_inventory.account_name}}</p>
                                                </div>
                                                <div class="col-12" v-if="item.is_purchase == 1">
                                                    <p class="text-muted-high mb-0">Cost of Goods Sold Account</p>
                                                    <p class="font-size-md mb-0"><b>{{item.coa_expense.account_no}}</b> {{item.coa_expense.account_name}}</p>
                                                </div>
                                            </div>
                                            <div class="row" v-else>
                                                <div class="col-12">
                                                    <p class="text-muted-high mb-0">You do not Track this Item</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--END Form-->
                        <hr class="mt-3" />
                        <p
                            class="d-none d-print-inline-block"
                            style="opacity: 0.6"
                        >
                            <i> Printed : {{ dateNow }} </i>
                        </p>
                        <!-- END Footer -->
                    </div>
                </div>
            </div>
            <!-- END Basic -->
        </div>

        <modal
            name="adjustment-item"
            width="95%"
            classes="modal-proxima-lg block-rounded block-mode-loading-oneui"
            height="auto"
            @before-close="clearAdjustmentModal()"
            adaptive
        >
            <Adjustment :itemId="this.item.item_id" :warehouseId="0"></Adjustment>
        </modal>
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import moment from "moment";
import Adjustment from "../adjustment/Adjustment.vue";

export default {
    name: "detail-item",
    components: {
        Adjustment
    },
    data() {
        return {
            loadingPage: 1,
            items: [],
            disabled: {},
            forbiddenData: false,
            onHandQty: 0,
            onOrderQty: 0,
            dateNow: moment().format("MMMM Do YYYY, HH:mm:ss"),
            activeTab: "General",
            tabs: ["General", "Bookkeeping"],
        };
    },
    mounted() {
        this.$root.$on("refreshItemDetail", id => {
            this.getData();
        });
    },
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState(["auth"]),
        ...mapState("item", { item: state => state.item }),
        ...mapState("itemtype", {
            types: state => state.types
        }),
    },
    created() {
        this.getData();
    },
    methods: {
        //VUEX
        ...mapMutations("item", ["CLEAR_FORM"]),
        ...mapMutations(["CLEAR_ERRORS"]),
        ...mapActions("item", [
            "detailItem",
            "getQuotationNo",
            "getOrderNo",
            "getInvoiceNo",
            "updateItem",
            "removeItem",
            "printItem",
            "getOnHoldStock",
            "getOnOrderStock"
        ]),
        ...mapActions("signature", ["getSignatureDocument"]),
        ...mapActions("bank", ["getBankDocument"]),
        getData() {
            if (this.$route.params.id) {
                this.getMutationData(this.$route.params.id, this.$route.params.warehouse);
                this.getOnHoldStock({item: this.$route.params.id, warehouse: 1}).then((response) => {this.onHandQty = response});
                this.getOnOrderStock({item: this.$route.params.id, warehouse: 1}).then((response) => {this.onOrderQty = response});
            } else if (this.$session.has("item_detail")) {
                this.getMutationData(this.$session.get("item_detail"), this.$session.get("item_warehouse"));
                this.getOnHoldStock({item: this.$session.get("item_detail"), warehouse: 1}).then((response) => {this.onHandQty = response});
                this.getOnOrderStock({item: this.$session.get("item_detail"), warehouse: 1}).then((response) => {this.onOrderQty = response});
            } else {
                this.$router.push({ name: "products-services" });
                this.alert("Data not found, select data first", 2);
            }
        },
        showAdjustmentModal() {
            this.$modal.show("adjustment-item");
            document.body.classList.add("modal-open");
        },
        closeAdjustmentModal() {
            this.$modal.hide("adjustment-item");
            document.body.classList.remove("modal-open");
        },
        clearAdjustmentModal() {
            document.body.classList.remove("modal-open");
        },
        errorLogoUrl(event) {
            event.target.src = "/media/workspace/no-logo.jpg";
        },
        toDetailAdjustment(val) {
            this.$router.push({
                name: "adjustment-detail",
                params: { id: val },
            });
        },
        toEditAdjustment(val) {
            this.$router.push({
                name: "adjustment-edit",
                params: { id: val },
            });
        },
        typeChange(val) {
            this.activeTab = val;
            if(val == 'General'){
                this.loadingPage = 2;
                this.getMutationData(this.item.item_id, 0);
            }
        },
        getMutationData(item, warehouse){
            this.detailItem({
                item: item,
                warehouse: warehouse
            })
                .then(response => {
                    let cal_bal_qty = 0;
                    let cal_bal_cogs = 0;
                    let cal_bal_amount = 0;
                    this.item.datamutation.forEach(element => {
                        cal_bal_qty =
                            cal_bal_qty + element.in_qty - element.out_qty;
                        cal_bal_cogs = 0 + element.in_price + element.out_price;
                        cal_bal_amount =
                            cal_bal_amount +
                            element.in_amount -
                            element.out_amount;
                        element.bal_qty = cal_bal_qty;
                        element.bal_cogs = cal_bal_cogs;
                        element.bal_amount = cal_bal_amount;
                    });
                    this.$session.set("item_detail", item);
                    this.$session.set("item_warehouse", warehouse);
                    this.loadingPage = 0;
                })
                .catch(error => {
                    console.log(error);
                    this.loadingPage = 0;
                });
        },
        print() {
            var browserName = navigator.appName;
            if (browserName == "Safari") {
                var printContents = document.getElementById("printMe")
                    .innerHTML;
                var originalContents = document.body.innerHTML;
                document.body.innerHTML = printContents;
                window.print();
                document.body.innerHTML = originalContents;
            } else {
                this.$htmlToPaper("printMe");
            }
        }
    },
    destroyed() {
        this.CLEAR_FORM();
    }
};
</script>
