<template>
    <main>
        <!-- Hero -->
        <div class="bg-body-light">
            <div class="content content-full">
                <div
                    class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center"
                >
                    <h1 class="flex-sm-fill h3 my-2">Accounting : Edit Item</h1>
                    <nav
                        class="flex-sm-00-auto ml-sm-3"
                        aria-label="breadcrumb"
                    >
                        <ol class="breadcrumb breadcrumb-alt">
                            <li class="breadcrumb-item" aria-current="page">
                                <router-link
                                    class="link-fx"
                                    :to="{ name: 'item' }"
                                    >Item Data</router-link
                                >
                            </li>
                            <li class="breadcrumb-item">Edit Item</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
        <!-- END Hero -->

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <div
                class="block col-12 col-md-8 offset-md-2"
                v-if="loadingPage == 1"
                style="height: 250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <div
                class="block col-12 col-md-8 offset-md-2"
                v-if="loadingPage == 2"
                style="height: 250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Save Data</span
                        >
                    </center>
                </div>
            </div>
            <!-- Basic -->
            <div
                class="block col-12 col-md-8 offset-md-2"
                v-if="loadingPage == 0"
            >
                <div class="block mb-0" v-if="forbiddenData">
                    <div
                        class="alert alert-info d-flex align-items-center mb-0"
                        role="alert"
                    >
                        <div class="flex-00-auto">
                            <i class="fa fa-fw fa-info-circle"></i>
                        </div>
                        <div class="flex-fill ml-3">
                            <p class="mb-0">
                                This item data is
                                <b> included into closing date of year </b>, you
                                can't edit or delete this data
                            </p>
                        </div>
                    </div>
                </div>
                <div class="block-content block-content-full">
                    <form
                        method="POST"
                        enctype="multipart/form-data"
                        onsubmit="return false;"
                    >
                        <!--Form-->
                        <div class="row font-size-sm">
                            <div class="col-12 col-md-10 offset-md-1">
                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Item Type *</label
                                    >
                                    <selectize
                                        class="form-control"
                                        placeholder="Choose Type"
                                        v-model="item.type_id"
                                        :class="{
                                            'is-invalid-selectize':
                                                errors.type_id,
                                        }"
                                        :disabled="item.datamutation.length > 0"
                                    >
                                        <!-- settings is optional -->
                                        <option
                                            v-for="type in types"
                                            :value="type.id"
                                            :key="type.id"
                                        >
                                            {{ type.type_name }}
                                        </option>
                                    </selectize>
                                    <transition name="slide-fade">
                                        <p
                                            class="text-danger"
                                            v-if="errors.type_id"
                                        >
                                            {{ errors.type_id[0] }}
                                        </p>
                                    </transition>
                                </div>
                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Item Name *</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Item Name"
                                        v-model="item.item_name"
                                        :class="{
                                            'is-invalid': errors.item_name,
                                        }"
                                    />
                                    <transition name="slide-fade">
                                        <p
                                            class="text-danger"
                                            v-if="errors.item_name"
                                        >
                                            {{ errors.item_name[0] }}
                                        </p>
                                    </transition>
                                </div>
                                <!-- WITH UNIT OF MEASURE -->
                                <div class="row mt-1">
                                    <div class="form-group col-6 py-1">
                                        <label for="example-text-input"
                                            >Item Code *</label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Item Code"
                                            v-model="item.item_code"
                                            :class="{
                                                'is-invalid': errors.item_code,
                                            }"
                                        />
                                        <transition name="slide-fade">
                                            <p
                                                class="text-danger"
                                                v-if="errors.item_code"
                                            >
                                                {{ errors.item_code[0] }}
                                            </p>
                                        </transition>
                                    </div>
                                    <div class="form-group col-6 py-1">
                                        <label for="example-text-input"
                                            >Item Unit *</label
                                        >
                                        <selectize
                                            class="form-control"
                                            placeholder="Choose Unit"
                                            v-model="item.uom_id"
                                            :class="{
                                                'is-invalid-selectize':
                                                    errors.uom_id,
                                            }"
                                            :disabled="
                                                item.datamutation.length > 0
                                            "
                                        >
                                            <option
                                                v-for="uomdata in uoms"
                                                :value="uomdata.id"
                                                :key="uomdata.id"
                                            >
                                                {{ uomdata.uom_name }} ({{
                                                    uomdata.uom_code
                                                }})
                                            </option>
                                        </selectize>
                                        <transition name="slide-fade">
                                            <p
                                                class="text-danger"
                                                v-if="errors.uom_id"
                                            >
                                                {{ errors.uom_id[0] }}
                                            </p>
                                        </transition>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Item Description</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Item Description"
                                        v-model="item.item_desc"
                                        :class="{
                                            'is-invalid': errors.item_desc,
                                        }"
                                    />
                                    <transition name="slide-fade">
                                        <p
                                            class="text-danger"
                                            v-if="errors.item_desc"
                                        >
                                            {{ errors.item_desc[0] }}
                                        </p>
                                    </transition>
                                </div>
                                <div class="form-group mb-5">
                                    <label>Active Status</label>
                                    <div
                                        class="custom-control custom-switch mb-1"
                                    >
                                        <input
                                            type="checkbox"
                                            class="custom-control-input"
                                            id="item-active"
                                            @change="activeInactive($event)"
                                            v-model="checked"
                                        />
                                        <label
                                            class="custom-control-label"
                                            for="item-active"
                                            style="padding-top: 2px"
                                            >{{
                                                item.item_status == 1
                                                    ? "Active"
                                                    : "Inactive"
                                            }}</label
                                        >
                                    </div>
                                </div>
                                <hr class="pb-1 pt-0 mt-1" />
                            </div>
                        </div>
                        <!--END Form-->
                        <!-- Submit -->
                        <div class="row items-push">
                            <div class="col-12 text-center mb-0 pb-0">
                                <p class="font-size-sm text-muted mb-0">
                                    * Required filled, Make sure all data is
                                    correctly filled
                                </p>
                            </div>
                            <div class="col-12 text-center">
                                <button
                                    type="button"
                                    v-if="!forbiddenData"
                                    class="btn btn-sm btn-success"
                                    v-ripple="{ center: true }"
                                    @click.prevent="submitData"
                                >
                                    <i class="fa fa-fw fa-check mr-1"></i>
                                    Update
                                </button>
                                <button
                                    type="button"
                                    v-if="!forbiddenData"
                                    class="btn btn-sm btn-danger"
                                    v-ripple="{ center: true }"
                                    @click.prevent="deleteData"
                                >
                                    <i class="fa fa-fw fa-trash mr-1"></i>
                                    Delete
                                </button>
                                <router-link
                                    type="button"
                                    class="btn btn-sm btn-alt-light"
                                    tag="button"
                                    v-ripple="{ center: true }"
                                    :to="{ name: 'item' }"
                                >
                                    <i class="fa fa-fw fa-times mr-1"></i> Close
                                </router-link>
                            </div>
                        </div>
                        <!-- END Submit -->
                        <!-- <span class="opacity-25">Created by {{item.created_by}} at {{item.created_at | moment("HH:mm DD/MM/YYYY")}}</span> -->
                    </form>
                </div>
            </div>
            <!-- END Basic -->
        </div>
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import moment from "moment";

export default {
    name: "edit-bills",
    data() {
        return {
            loadingPage: 1,
            items: [],
            disabled: {},
            forbiddenData: false,
            checked: true,
        };
    },
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState("item", { item: (state) => state.item }),
        ...mapState("forbiddendate", {
            forbiddenDateLast: (state) => state.forbiddenDateLast,
        }),
        ...mapState("uom", { uoms: (state) => state.uoms }),
        ...mapState("itemtype", {
            types: (state) => state.types,
        }),
    },
    created() {
        this.getTypeAll();
        this.getUomBase();
        if (this.$route.params.id) {
            this.editItem(this.$route.params.id)
                .then((response) => {
                    response.item_status == 1
                        ? (this.checked = true)
                        : (this.checked = false);
                    this.loadingPage = 0;
                })
                .catch((error) => {
                    console.log(error);
                    this.loadingPage = 0;
                });
        } else {
            this.$router.push({ name: "item" });
            this.alert("Data not found, select data first", 2);
        }
    },
    methods: {
        //VUEX
        ...mapMutations("item", ["CLEAR_FORM"]),
        ...mapMutations(["CLEAR_ERRORS"]),
        ...mapActions("item", [
            "editItem",
            "getItemNo",
            "updateItem",
            "removeItem",
            "printItem",
        ]),
        ...mapActions("itemtype", ["getTypeAll"]),
        ...mapActions("uom", ["getUomBase"]),

        //METHOD
        disabledDatePicker(date) {
            const lastDisableDate = new Date(
                moment(this.forbiddenDateLast).add(1, "days")
            );
            lastDisableDate.setHours(0, 0, 0, 0);
            return date < lastDisableDate;
        },
        changeDate() {
            this.getItemNo();
            // this.getJournalMemo();
        },
        activeInactive() {
            this.checked == true
                ? (this.item.item_status = 1)
                : (this.item.item_status = 0);
        },
        //GET DATA TAX RATE
        getTaxDetail(index, val) {
            var result = this.taxs.find((obj) => {
                return obj.id === parseInt(val);
            });
            this.item.bill_transaction[index].transaction_tax_rate =
                result.tax_rate;
            //RE CALCULATE BILLS
            this.calculate();
        },
        //GET JOURNAL MEMO
        getJournalMemo() {
            if (this.item.journal == null) {
                if (
                    this.item.bill_contact_id == "" ||
                    this.item.bill_contact_id == 0
                ) {
                    let newJournalMemo = "Item; ";
                    this.item.bill_journal_memo = newJournalMemo;
                } else {
                    var result = this.contacts.find((obj) => {
                        return obj.id === parseInt(this.item.bill_contact_id);
                    });
                    let newJournalMemo = "Item; " + result.contact_name;
                    this.item.bill_journal_memo = newJournalMemo;
                }
            } else {
                this.item.bill_journal_memo = this.item.journal.journal_description;
            }
        },
        addDetail() {
            this.item.bill_transaction.push({
                id: 0,
                transaction_tax_id: "",
                transaction_tax_rate: 0,
                transaction_coa_id: "",
                transaction_description: "",
                transaction_amount: 0,
            });
        },
        removeDetail(index, transaction_id) {
            this.item.bill_transaction.splice(index, 1);
            this.item.bill_transaction_delete.push(transaction_id);
        },
        calculate() {
            var sub = 0;
            var tax = 0;
            this.item.bill_transaction.forEach((transaction) => {
                sub += transaction.transaction_amount;
            });

            this.item.bill_transaction.forEach((transaction) => {
                if (isNaN(transaction.transaction_tax_rate)) {
                } else {
                    tax +=
                        transaction.transaction_amount *
                        (transaction.transaction_tax_rate / 100);
                }
            });

            this.item.bill_sub_total = sub.toFixed(0);
            this.item.bill_tax_total = tax.toFixed(0);
            this.item.bill_grand_total = (sub + tax).toFixed(0);
        },
        submitData() {
            this.loadingPage = 2;
            this.CLEAR_ERRORS();
            this.item.bill_status = 3;
            this.updateItem(this.$route.params.id)
                .then((response) => {
                    this.CLEAR_FORM();
                    this.alert("Successfully update Item Data ", 1);
                    this.loadingPage = 0;
                    this.$router.push({ name: "item" });
                })
                .catch((error) => {
                    error.response.status == 500
                        ? this.alert(error.response.data.message, 2)
                        : "";
                    error.response.status != 422 && error.response.status != 500
                        ? this.alert(error.message, 2)
                        : "";
                    this.loadingPage = 0;
                });
        },
        deleteData() {
            this.$swal({
                title: "Are you sure ?",
                text: "Deleted data cannot be recovery",
                icon: "warning",
                buttons: ["Cancel", "Delete"],
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    this.loadingPage = 1;
                    this.removeItem(this.$route.params.id)
                        .then(() => {
                            this.alert("Data has been deleted !", 1);
                            this.loadingPage = 0;
                            this.$router.push({ name: "item" });
                        })
                        .catch((error) => {
                            error.response.status == 500
                                ? this.alert(error.response.data.message, 2)
                                : "";
                            error.response.status != 422 &&
                            error.response.status != 500
                                ? this.alert(error.message, 2)
                                : "";
                            this.loadingPage = 0;
                        });
                }
            });
        },
    },
    destroyed() {
        this.CLEAR_FORM();
    },
};
</script>
