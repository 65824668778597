import Vue from "vue";
import $axios from "../../api.js";

const state = () => ({
    items: [],

    item: {
        item_id: 0,
        type_id: 0,
        uom_id: 0,
        item_name: "",
        item_code: "",
        item_desc: "",
        item_qty: 0,
        item_value: 0,
        item_status: 0,
        is_sell: 0,
        is_purchase: 0,
        is_inventory: 0,
        income_coa_id: 0,
        expense_coa_id: 0,
        inventory_coa_id: 0,
        purchase_desc: "",
        purchase_price: 0,
        purchase_tax_id: 0,
        sell_desc: "",
        sell_price: 0,
        sell_tax_id: 0,
        opening_qty: 0,
        opening_price: 0,
        opening_amount: 0,
        opening_date: Vue.moment().format("YYYY-MM-DD"),
        user_id: 0,
        actualStock: 0,
        onHandStock: 0,
        onOrderStock: 0,
        inHandStock: 0,
        datamutation: []
    },
    page: 1, //UNTUK MENCATAT PAGE PAGINATE YANG SEDANG DIAKSES
    last_page: 1,
    search: ""
});

const mutations = {
    ASSIGN_DATA(state, payload) {
        state.items = payload;
        state.last_page = payload.meta.last_page;
        state.page = payload.meta.current_page;
    },
    SET_PAGE(state, payload) {
        state.page = payload;
    },
    ASSIGN_FORM(state, payload) {
        state.item = {
            item_id: payload.id,
            type_id: payload.type_id,
            uom_id: payload.uom_id,
            item_name: payload.item_name,
            item_code: payload.item_code,
            item_desc: payload.item_desc,
            item_qty: payload.item_qty,
            item_value: payload.item_value,
            item_status: payload.item_status,
            is_sell: payload.is_sell,
            is_purchase: payload.is_purchase,
            is_inventory: payload.is_inventory,
            income_coa_id: payload.income_coa_id,
            expense_coa_id: payload.expense_coa_id,
            inventory_coa_id: payload.inventory_coa_id,
            purchase_desc: payload.purchase_desc,
            purchase_price:
                payload.purchase_price == null ? 0 : payload.purchase_price,
            purchase_tax_id: payload.purchase_tax_id,
            sell_desc: payload.sell_desc,
            sell_price: payload.sell_price == null ? 0 : payload.sell_price,
            sell_tax_id: payload.sell_tax_id,
            opening_qty: payload.opening_qty == null ? 0 : payload.opening_qty,
            opening_price:
                payload.opening_price == null ? 0 : payload.opening_price,
            opening_amount:
                payload.opening_amount == null ? 0 : payload.opening_amount,
            opening_date:
                payload.opening_date == null
                    ? Vue.moment().format("YYYY-MM-DD")
                    : payload.opening_date,
            user_id: payload.user_id,
            datamutation: payload.datamutation,
            type: payload.type,
            uom: payload.uom,
            coa_income: payload.coa_income,
            coa_expense: payload.coa_expense,
            coa_inventory: payload.coa_inventory,
            tax_purchase: payload.tax_purchase,
            tax_selling: payload.tax_selling,
            actualStock: payload.actualStock,
            onHandStock: payload.onHandStock,
            onOrderStock: payload.onOrderStock,
            inHandStock: payload.inHandStock
        };
    },
    //ME-RESET STATE NEWS MENJADI KOSONG
    CLEAR_FORM(state) {
        state.item = {
            item_id: 0,
            type_id: 0,
            uom_id: 0,
            item_name: "",
            item_code: "",
            item_desc: "",
            item_qty: 0,
            item_value: 0,
            item_status: 0,
            is_sell: 0,
            is_purchase: 0,
            is_inventory: 0,
            income_coa_id: 0,
            expense_coa_id: 0,
            inventory_coa_id: 0,
            purchase_desc: "",
            purchase_price: 0,
            purchase_tax_id: 0,
            sell_desc: "",
            sell_price: 0,
            sell_tax_id: 0,
            user_id: 0,
            datamutation: [],
            coa_income: null,
            coa_expense: null,
            coa_inventory: null,
            tax_purchase: null,
            tax_selling: null,
            actualStock: 0,
            onHandStock: 0,
            onOrderStock: 0,
            inHandStock: 0
        };
    },
    CLEAR_DATA(state) {
        state.items = [];
    }
};

const actions = {
    getItem({ dispatch, commit, state }, payload) {
        let search = typeof payload.search != "undefined" ? payload.search : "";
        let warehouse = payload.warehouse;
        let rows = payload.rows;
        let page =
            typeof payload.rows != "All" && payload.rowsUpdate != 1
                ? state.page
                : "1";
        let type = typeof payload.type != "undefined" ? payload.type : "";
        return new Promise((resolve, reject) => {
            $axios
                .get(
                    `/item?page=${page}&search=${search}&rows=${rows}&warehouse=${warehouse}&type=${type}`
                )
                .then(response => {
                    commit("ASSIGN_DATA", response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                    reject(error);
                });
        });
    },
    getItemAll({ dispatch, state }, payload) {
        let type = typeof payload.type != "undefined" ? payload.type : "All";
        $axios
            .get(`/item/retrive-all-data?type=${type}`)
            .then(response => {
                state.items = response.data;
                this.loading = false;
            })
            .catch(error => {
                error.response.status === 401
                    ? dispatch("DO_LOGOUT", null, { root: true })
                    : "";
                reject(error);
            });
    },
    getItemIndicator({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.get(`/item/indicator`).then(response => {
                commit("ASSIGN_FORM", response.data);
                resolve(response.data);
            });
        });
    },
    getCOGSRequest({ dispatch, commit, state }, payload) {
        let warehouse = payload.warehouse;
        let date = payload.date;
        let item = payload.item;
        let qty = payload.qty;

        return new Promise((resolve, reject) => {
            $axios
                .get(
                    `/item/available-stock-price?date=${date}&qty=${qty}&warehouse=${warehouse}&item=${item}`
                )
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                    reject(error);
                });
        });
    },
    getActualStock({ dispatch, commit, state }, payload) {
        let warehouse = payload.warehouse;
        let item = payload.item;

        return new Promise((resolve, reject) => {
            $axios
                .get(`/item/actual-stock?warehouse=${warehouse}&item=${item}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                    reject(error);
                });
        });
    },
    getOnHoldStock({ dispatch, commit, state }, payload) {
        let warehouse = payload.warehouse;
        let item = payload.item;

        return new Promise((resolve, reject) => {
            $axios
                .get(`/item/on-hold-stock?warehouse=${warehouse}&item=${item}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                    reject(error);
                });
        });
    },
    getOnOrderStock({ dispatch, commit, state }, payload) {
        let warehouse = payload.warehouse;
        let item = payload.item;

        return new Promise((resolve, reject) => {
            $axios
                .get(`/item/on-order-stock?warehouse=${warehouse}&item=${item}`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                    reject(error);
                });
        });
    },
    getInHandStock({ dispatch, commit, state }, payload) {
        let warehouse = payload.warehouse;
        let item = payload.item;
        let date = payload.date;

        return new Promise((resolve, reject) => {
            $axios
                .get(
                    `/item/in-hand-stock?warehouse=${warehouse}&item=${item}&date=${date}`
                )
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                    reject(error);
                });
        });
    },
    submitItem({ dispatch, commit, state }) {
        return new Promise((resolve, reject) => {
            $axios
                .post(`/item`, state.item)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                });
        });
    },
    detailItem({ dispatch, commit, state }, payload) {
        let warehouse = payload.warehouse;
        let item = payload.item;

        return new Promise((resolve, reject) => {
            $axios
                .get(`/item/detail?item=${item}&warehouse=${warehouse}`)
                .then(response => {
                    commit("ASSIGN_FORM", response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                    reject(error);
                });
        });
    },
    editItem({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.get(`/item/${payload}/edit`).then(response => {
                commit("ASSIGN_FORM", response.data);
                resolve(response.data);
            });
        });
    },
    updateItem({ state, commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .put(`/item/${payload}`, state.item)
                .then(response => {
                    //FORM DIBERSIHKAN
                    commit("CLEAR_FORM");
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                });
        });
    },
    archiveItem({ state, commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .post(`/item/archive`, state.item)
                .then(response => {
                    //FORM DIBERSIHKAN
                    commit("CLEAR_FORM");
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                });
        });
    },
    unarchiveItem({ state, commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .post(`/item/unarchive`, state.item)
                .then(response => {
                    //FORM DIBERSIHKAN
                    commit("CLEAR_FORM");
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                });
        });
    },
    removeItem({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .delete(`/item/${payload}`)
                .then(response => {
                    commit("CLEAR_FORM");
                    resolve(response.data);
                })
                .catch(error => {
                    if (error.response) {
                        commit("SET_ERRORS", error.response.data, {
                            root: true
                        });
                    }
                    reject(error);
                });
        });
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
