import $axios from "../../api.js";

const state = () => ({
    coas: [],

    coa: {
        coa_id: 0,
        account_role: "Header",
        account_no: "",
        account_name: "",
        account_type: "Asset",
        account_level: "",
        account_head: "",
        account_position: "",
        account_opening_balance: "",
        account_ending_balance: "",
        account_link: ""
    },
    coalink: {
        current_earning: 0,
        retained_earning: 0,
        historical_balancing: 0,
        tracking_receiveable: 0,
        contact_receipt: 0,
        pay_purchase: 0,
        tracking_payable: 0
    },
    page: 1, //UNTUK MENCATAT PAGE PAGINATE YANG SEDANG DIAKSES
    last_page: 1,
    search: ""
});

const mutations = {
    //MEMASUKKAN DATA KE STATE COA
    ASSIGN_DATA(state, payload) {
        state.coas = payload;
        // state.last_page = payload.meta.last_page;
        // state.page = payload.meta.current_page;
    },
    ASSIGN_DATA_ONLY(state, payload) {
        state.coas = payload;
    },
    //
    //MENGUBAH DATA STATE PAGE
    SET_PAGE(state, payload) {
        state.page = payload;
    },
    //MENGUBAH DATA STATE COA
    ASSIGN_FORM(state, payload) {
        state.coa = {
            coa_id: payload.id,
            account_role: payload.account_role,
            account_no: payload.account_no,
            account_name: payload.account_name,
            account_type: payload.account_type,
            account_level: payload.account_level,
            account_head: payload.account_head,
            account_position: payload.account_position,
            account_opening_balance: payload.account_opening_balance,
            account_ending_balance: payload.account_ending_balance,
            account_link: payload.account_link
        };
    },
    //ME-RESET STATE COA MENJADI KOSONG
    CLEAR_FORM(state) {
        state.coa = {
            coa_id: 0,
            account_role: "Header",
            account_no: "",
            account_name: "",
            account_type: "Asset",
            account_level: "",
            account_head: "",
            account_position: "",
            account_opening_balance: "",
            account_ending_balance: "",
            account_link: ""
        };
    },
    //MENGUBAH DATA STATE COA LINK
    ASSIGN_FORM_COALINK(state, payload) {
        state.coalink = {
            current_earning: payload.current_earning,
            retained_earning: payload.retained_earning,
            historical_balancing: payload.historical_balancing,
            tracking_receiveable: payload.tracking_receiveable,
            contact_receipt: payload.contact_receipt,
            pay_purchase: payload.pay_purchase,
            tracking_payable: payload.tracking_payable
        };
    },
    //ME-RESET STATE COA LINK MENJADI KOSONG
    CLEAR_FORM_COALINK(state) {
        state.coalink = {
            current_earning: 0,
            retained_earning: 0,
            historical_balancing: 0,
            tracking_receiveable: 0,
            contact_receipt: 0,
            pay_purchase: 0,
            tracking_payable: 0
        };
    },
    //ME-RESET STATE COA MENJADI KOSONG
    CLEAR_DATA(state) {
        state.coas = [];
        state.coalink = {
            current_earning: 0,
            retained_earning: 0,
            historical_balancing: 0,
            tracking_receiveable: 0,
            contact_receipt: 0,
            pay_purchase: 0,
            tracking_payable: 0
        };
    }
};

const actions = {
    //FUNGSI INI UNTUK MELAKUKAN REQUEST DATA COA DARI SERVER
    getCoa({ dispatch, commit, state }, payload) {
        //MENGECEK PAYLOAD ADA ATAU TIDAK
        let search = typeof payload.search != "undefined" ? payload.search : "";
        let type = payload.type;
        let rows = payload.rows;
        let page =
            typeof payload.rows != "All" && payload.rowsUpdate != 1
                ? state.page
                : "1";

        return new Promise((resolve, reject) => {
            //REQUEST DATA DENGAN ENDPOINT /COA
            $axios
                .get(
                    `/coa?page=${page}&search=${search}&rows=${rows}&type=${type}`
                )
                .then(response => {
                    //SIMPAN DATA KE STATE MELALUI MUTATIONS
                    commit("ASSIGN_DATA", response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                    reject(error);
                });
        });
    },
    //FUNGSI INI UNTUK MELAKUKAN REQUEST DATA COA UNTUK KEBUTUHAN PEMANGGILAN COA DIBERBAGAI TEMPAT DARI SERVER
    getCoaAll({ commit, state }, payload) {
        return new Promise((resolve, reject) => {
            //REQUEST DATA DENGAN ENDPOINT /COA
            $axios
                .get(`/coa/retrive-all-data`)
                .then(response => {
                    //SIMPAN DATA KE STATE MELALUI MUTATIONS
                    commit("CLEAR_DATA");
                    commit("ASSIGN_DATA_ONLY", response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    console.log(error);
                });
        });
    },
    //FUNGSI INI UNTUK MELAKUKAN REQUEST DATA LINK COA DARI SERVER
    getCoaLink({ dispatch, state }, payload) {
        // dispatch('getCoaAll');
        return new Promise((resolve, reject) => {
            $axios
                .get(`/coa/get-linked-accounts`)
                .then(response => {
                    state.coalink.current_earning =
                        response.data.current_earning == null
                            ? ""
                            : response.data.current_earning.coa_id;
                    state.coalink.retained_earning =
                        response.data.retained_earning == null
                            ? ""
                            : response.data.retained_earning.coa_id;
                    state.coalink.historical_balancing =
                        response.data.historical_balancing == null
                            ? ""
                            : response.data.historical_balancing.coa_id;
                    state.coalink.tracking_receiveable =
                        response.data.tracking_receiveable == null
                            ? ""
                            : response.data.tracking_receiveable.coa_id;
                    state.coalink.contact_receipt =
                        response.data.contact_receipt == null
                            ? ""
                            : response.data.contact_receipt.coa_id;
                    state.coalink.pay_purchase =
                        response.data.pay_purchase == null
                            ? ""
                            : response.data.pay_purchase.coa_id;
                    state.coalink.tracking_payable =
                        response.data.tracking_payable == null
                            ? ""
                            : response.data.tracking_payable.coa_id;
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    console.log(error);
                });
        });
    },
    //UNTUK MENGUPDATE DATA BERDASARKAN CODE YANG SEDANG DIEDIT
    setCoaLink({ state, commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .post(`/coa/set-linked-accounts`, state.coalink)
                .then(response => {
                    //FORM DIBERSIHKAN
                    commit("CLEAR_FORM");
                    resolve(response.data);
                })
                .catch(error => {
                    if (error.response) {
                        commit("SET_ERRORS", error.response.data, {
                            root: true
                        });
                    }
                    reject(error);
                });
        });
    },
    checkCoaNo({ state }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .get(`/coa/search_no/` + payload.prefix + payload.postfix)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    console.log(error);
                });
        });
    },
    //FUNGSI UNTUK MENAMBAHKAN DATA BARU
    submitCoa({ dispatch, commit, state }) {
        return new Promise((resolve, reject) => {
            //MENGIRIMKAN PERMINTAAN KE SERVER DAN MELAMPIRKAN DATA YANG AKAN DISIMPAN
            //DARI STATE COA
            $axios
                .post(`/coa`, state.coa)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                });
        });
    },
    //UNTUK MENGAMBIL SINGLE DATA DARI SERVER BERDASARKAN CODE COA
    editCoa({ commit }, payload) {
        return new Promise((resolve, reject) => {
            //MELAKUKAN REQUEST DENGAN MENGIRIMKAN CODE COA DI URL
            $axios
                .get(`/coa/${payload}/edit`)
                .then(response => {
                    //APABIL BERHASIL, DI ASSIGN KE FORM
                    commit("ASSIGN_FORM", response.data.data);
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    console.log(error);
                });
        });
    },
    //UNTUK MENGUPDATE DATA BERDASARKAN CODE YANG SEDANG DIEDIT
    updateCoa({ state, commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .put(`/coa/${payload}`, state.coa)
                .then(response => {
                    //FORM DIBERSIHKAN
                    commit("CLEAR_FORM");
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                });
        });
    },
    //MENGHAPUS DATA
    removeCoa({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .delete(`/coa/${payload}`)
                .then(response => {
                    commit("CLEAR_FORM");
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    //AVAILABLE LEVEL EDIT COA
    getAvailableLevel({ state, commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .get(`/coa/get-available-level/${payload}`)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                    console.log(error);
                });
        });
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
