<template>
    <div class="container">
        <div class="row justify-content-center mt-5">
            <div class="col-12 col-md-8 text-center">
                <img class="not-found mb-5 mt-5" src="media/proxima/empty-state.svg"/>
                <h4>404 : Page Not Found</h4>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {},
};
</script>
