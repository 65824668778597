import $axios from "../../api.js";

const state = () => ({
    banks: [],

    bank: {
        bank_name: "",
        bank_cabang: "",
        bank_rekening: "",
        bank_rekening_name: "",
        bank_type: "",
        bank_bill_quotation: 0,
        bank_bill_order: 0,
        bank_bill_invoice: 0,
        bank_expense: 0,
        bank_income: 0
    },
    page: 1, //UNTUK MENCATAT PAGE PAGINATE YANG SEDANG DIAKSES
    last_page: 1,
    search: ""
});

const mutations = {
    //MEMASUKKAN DATA KE STATE NEWS
    ASSIGN_DATA(state, payload) {
        state.banks = payload;
        state.last_page = payload.meta.last_page;
        state.page = payload.meta.current_page;
    },
    //MEMASUKKAN DATA KE STATE NEWS
    ASSIGN_DATA_ONLY(state, payload) {
        state.banks = payload;
    },
    //MENGUBAH DATA STATE PAGE
    SET_PAGE(state, payload) {
        state.page = payload;
    },
    //MENGUBAH DATA STATE NEWS
    ASSIGN_FORM(state, payload) {
        state.bank = {
            bank_name: payload.bank_name,
            bank_cabang: payload.bank_cabang,
            bank_rekening: payload.bank_rekening,
            bank_rekening_name: payload.bank_rekening_name,
            bank_type: payload.bank_type,
            bank_bill_quotation: payload.bank_bill_quotation,
            bank_bill_order: payload.bank_bill_order,
            bank_bill_invoice: payload.bank_bill_invoice,
            bank_expense: payload.bank_expense,
            bank_income: payload.bank_income
        };
    },
    //ME-RESET STATE NEWS MENJADI KOSONG
    CLEAR_FORM(state) {
        state.bank = {
            bank_name: "",
            bank_cabang: "",
            bank_rekening: "",
            bank_rekening_name: "",
            bank_type: "",
            bank_bill_quotation: 0,
            bank_bill_order: 0,
            bank_bill_invoice: 0,
            bank_expense: 0,
            bank_income: 0
        };
    },
    //ME-RESET STATE NEWS MENJADI KOSONG
    CLEAR_DATA(state) {
        state.banks = [];
    }
};

const actions = {
    //FUNGSI INI UNTUK MELAKUKAN REQUEST DATA NEWS DARI SERVER
    getBank({ dispatch, commit, state }, payload) {
        //MENGECEK PAYLOAD ADA ATAU TIDAK
        let search = typeof payload.search != "undefined" ? payload.search : "";
        let rows = payload.rows;
        let page =
            typeof payload.rows != "All" && payload.rowsUpdate != 1
                ? state.page
                : "1";
        let sort = payload.sort;
        let asc = payload.asc != false ? "DESC" : "ASC";

        return new Promise((resolve, reject) => {
            //REQUEST DATA DENGAN ENDPOINT /NEWS
            $axios
                .get(
                    `/bank?page=${page}&search=${search}&rows=${rows}&sort=${sort}&asc=${asc}`
                )
                .then(response => {
                    //SIMPAN DATA KE STATE MELALUI MUTATIONS
                    commit("ASSIGN_DATA", response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    error.response.status !== 401
                        ? reject(error)
                        : dispatch("DO_LOGOUT", null, { root: true });
                });
        });
    },
    //FUNGSI INI UNTUK MELAKUKAN REQUEST DATA DARI SERVER
    getBankDocument({ dispatch, commit, state }, payload) {
        return new Promise((resolve, reject) => {
            //REQUEST DATA DENGAN ENDPOINT /NEWS
            $axios
                .get(`/bank/document?doc=${payload.document}`)
                .then(response => {
                    //SIMPAN DATA KE STATE MELALUI MUTATIONS
                    commit("ASSIGN_DATA_ONLY", response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                    reject(error);
                });
        });
    },
    //FUNGSI UNTUK MENAMBAHKAN DATA BARU
    submitBank({ dispatch, commit, state }) {
        return new Promise((resolve, reject) => {
            //MENGIRIMKAN PERMINTAAN KE SERVER DAN MELAMPIRKAN DATA YANG AKAN DISIMPAN
            //DARI STATE NEWS
            $axios
                .post(`/bank`, state.bank)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                });
        });
    },
    //UNTUK MENGAMBIL SINGLE DATA DARI SERVER BERDASARKAN CODE NEWS
    editBank({ commit }, payload) {
        return new Promise((resolve, reject) => {
            //MELAKUKAN REQUEST DENGAN MENGIRIMKAN CODE NEWS DI URL
            $axios
                .get(`/bank/${payload}/edit`)
                .then(response => {
                    //APABIL BERHASIL, DI ASSIGN KE FORM
                    commit("ASSIGN_FORM", response.data.data);
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    //UNTUK MENGUPDATE DATA BERDASARKAN CODE YANG SEDANG DIEDIT
    updateBank({ state, commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .put(`/bank/${payload}`, state.bank)
                .then(response => {
                    //FORM DIBERSIHKAN
                    commit("CLEAR_FORM");
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                });
        });
    },
    //MENGHAPUS DATA
    removeBank({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .delete(`/bank/${payload}`)
                .then(response => {
                    commit("CLEAR_FORM");
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
