<template>
    <main>
        <!-- Hero -->
        <div class="bg-body-light">
            <div class="content content-full">
                <div
                    class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center"
                >
                    <h1 class="flex-sm-fill h3 my-2">
                        Accounting : General Ledger Report
                    </h1>
                    <nav
                        class="flex-sm-00-auto ml-sm-3"
                        aria-label="breadcrumb"
                    >
                        <ol class="breadcrumb breadcrumb-alt">
                            <li class="breadcrumb-item" aria-current="page">
                                <router-link
                                    class="link-fx"
                                    :to="{ name: 'general-ledger' }"
                                    >General Ledger</router-link
                                >
                            </li>
                            <li class="breadcrumb-item">
                                General Ledger Report
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
        <!-- END Hero -->

        <!-- Page Content -->
        <div class="content">
            <div class="block mb-2 block-rounded block-mode-loading-oneui">
                <div class="block-header">
                    <h3 class="block-title">General Ledger</h3>
                    <div class="block-options">
                        <!-- Print Page functionality is initialized in Helpers.print() -->
                        <button
                            type="button"
                            class="btn-block-option text-info"
                            @click="print()"
                        >
                            <i class="si si-printer mr-1"></i>
                            <span class="d-none d-md-inline-block"
                                >Print Document</span
                            >
                        </button>
                        <!-- Print Page functionality is initialized in Helpers.print() -->
                        <button
                            type="button"
                            class="btn-block-option text-success"
                            @click="refresh()"
                        >
                            <i class="si si-reload mr-1"></i>
                            <span class="d-none d-md-inline-block"
                                >Refresh</span
                            >
                        </button>
                    </div>
                </div>
            </div>
            <div class="block bg-transparent mb-2">
                <div class="block-content pt-0 bg-transparent text-right">
                    <input
                        class="form-check-input"
                        style="margin-top:3.5px"
                        type="checkbox"
                        v-model="useHeader"
                    />
                    <label class="form-check-label">Default Header</label>
                </div>
            </div>
            <!--Loading-->
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 2"
                style="height:250px"
            >                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity:0.7"
                            >Generating Report</span
                        >
                    </center>
                </div>
            </div>
            <!-- Basic -->
            <div class="block block-rounded block-mode-loading-oneui" v-else>
                <div class="content content-boxed" id="printMe">
                    <div class="block-content">
                        <div
                            class="p-0 p-md-5 p-print"
                            style="min-height:800px"
                        >
                            <div class="row pt-0" v-if="useHeader">
                                <div class="col-6 font-size-sm pb-0 pt-0">
                                    <p class="h3 mb-2 mt-3 text-uppercase">
                                        {{
                                            auth.data.workspace
                                                .workspace_company_name
                                        }}
                                    </p>
                                    <p class="mb-0">
                                        {{
                                            auth.data.workspace
                                                .workspace_company_address
                                        }}
                                    </p>
                                    <p class="mb-0">
                                        <span
                                            v-if="
                                                auth.data.workspace
                                                    .workspace_company_province !==
                                                    '' &&
                                                    auth.data.workspace
                                                        .workspace_company_province !==
                                                        null
                                            "
                                        >
                                            {{
                                                auth.data.workspace
                                                    .workspace_company_province
                                            }}
                                        </span>
                                        <span
                                            v-if="
                                                auth.data.workspace
                                                    .workspace_company_postal !==
                                                    '' &&
                                                    auth.data.workspace
                                                        .workspace_company_postal !==
                                                        null
                                            "
                                        >
                                            ,
                                            {{
                                                auth.data.workspace
                                                    .workspace_company_postal
                                            }}
                                        </span>
                                    </p>
                                    <p class="mb-0">
                                        <span
                                            v-if="
                                                auth.data.workspace
                                                    .workspace_company_phone !==
                                                    '' &&
                                                    auth.data.workspace
                                                        .workspace_company_phone !==
                                                        null
                                            "
                                        >
                                            {{
                                                auth.data.workspace
                                                    .workspace_company_phone
                                            }}
                                        </span>
                                        <span
                                            v-if="
                                                auth.data.workspace
                                                    .workspace_company_email !==
                                                    '' &&
                                                    auth.data.workspace
                                                        .workspace_company_email !==
                                                        null
                                            "
                                        >
                                            {{
                                                "| " +
                                                    auth.data.workspace
                                                        .workspace_company_email
                                            }}
                                        </span>
                                    </p>
                                </div>
                                <div
                                    class="col-6 text-right font-size-sm pb-0 pt-0"
                                >
                                    <img
                                        :src="
                                            '/storage/workspace/' +
                                                auth.data.workspace
                                                    .workspace_company_logo
                                        "
                                        alt=""
                                        style="min-heigh:100px; max-height:120px; max-width:180px;"
                                        @error="errorLogoUrl"
                                    />
                                </div>
                            </div>

                            <hr v-if="useHeader"/>

                            <!-- Invoice Info -->
                            <div class="row mb-2">
                                <!-- Company Info -->
                                <div class="col-6 font-size-sm">
                                    <p class="h3 mb-1">General Ledger</p>
                                    <p class="mb-2">Currency : IDR</p>
                                </div>
                                <!-- END Company Info -->

                                <!-- Client Info -->
                                <div class="col-6 text-right font-size-sm">
                                    <p class="mb-2">
                                        From :
                                        {{
                                            this.$route.params.date_start
                                                | moment("DD/MM/YYYY")
                                        }}
                                    </p>
                                    <p class="mb-2">
                                        To :
                                        {{
                                            this.$route.params.date_end
                                                | moment("DD/MM/YYYY")
                                        }}
                                    </p>
                                </div>
                                <!-- END Client Info -->
                            </div>
                            <!-- END Invoice Info -->

                            <!-- Table -->
                            <div
                                class="table-responsive push"
                                v-if="loadingPage == 2"
                            >
                                <div
                                    class="block-content block-content-full pb-0"
                                    style="padding-top: 80px"
                                >
                                    <grid-loader
                                        class="mx-auto"
                                        :color="'#70B9EB'"
                                        :size="'10px'"
                                    ></grid-loader>
                                    <center class="mt-2 mb-5 pb-2">
                                        <span
                                            class="text-center"
                                            style="opacity:0.7"
                                            >Refresh Report</span
                                        >
                                    </center>
                                </div>
                            </div>
                            <div
                                class="table-responsive push"
                                v-if="loadingPage == 0"
                            >
                                <table
                                    v-for="coa in datas"
                                    :key="coa.index"
                                    class="table table-bordered font-print-xs mb-4"
                                >
                                    <tr class="bg-body-light">
                                        <td style="font-weight: bold;">
                                            {{ coa.acc_no }}
                                        </td>
                                        <td
                                            style="font-weight: bold;"
                                            colspan="3"
                                        >
                                            {{ coa.acc_name }}
                                        </td>
                                        <td
                                            style="font-weight: bold;"
                                            colspan="2"
                                        >
                                            Opening Balance:
                                            <span style="float :right;">{{
                                                coa.ob
                                                    | currency({ symbol: "" })
                                            }}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            style="font-weight: bold; width: 90px;"
                                        >
                                            Journal No.
                                        </td>
                                        <td
                                            style="font-weight: bold; width: 95px;"
                                        >
                                            Date
                                        </td>
                                        <td style="font-weight: bold; width: ;">
                                            Memo
                                        </td>
                                        <td
                                            align="center"
                                            style="font-weight: bold; width: 120px;"
                                        >
                                            Debit
                                        </td>
                                        <td
                                            align="center"
                                            style="font-weight: bold; width: 120px;"
                                        >
                                            Credit
                                        </td>
                                        <td
                                            align="center"
                                            style="font-weight: bold; width: 140px;"
                                        >
                                            Ending Balance
                                        </td>
                                    </tr>
                                    <tr
                                        v-for="journal in coa.detail"
                                        :key="journal.index"
                                    >
                                        <template v-if="journal.j_date == null">
                                            <td colspan="6" class="text-center">
                                                No Transaction
                                            </td>
                                        </template>
                                        <template v-else>
                                            <td
                                                style="max-width:50px;word-wrap:break-word;"
                                            >
                                                {{ journal.j_no }}
                                            </td>
                                            <td
                                                style="max-width:50px;word-wrap:break-word;"
                                            >
                                                {{
                                                    journal.j_date
                                                        | moment("DD/MM/YYYY")
                                                }}
                                            </td>
                                            <td
                                                style="max-width:50px;word-wrap:break-word;"
                                            >
                                                {{ journal.j_desc }}
                                            </td>
                                            <td
                                                align="right"
                                                style="white-space: nowrap;"
                                            >
                                                {{
                                                    journal.debit
                                                        | currency({
                                                            symbol: ""
                                                        })
                                                }}
                                            </td>
                                            <td
                                                align="right"
                                                style="white-space: nowrap;"
                                            >
                                                {{
                                                    journal.credit
                                                        | currency({
                                                            symbol: ""
                                                        })
                                                }}
                                            </td>
                                            <td
                                                align="right"
                                                style="white-space: nowrap;"
                                            >
                                                {{
                                                    journal.eb
                                                        | currency({
                                                            symbol: ""
                                                        })
                                                }}
                                            </td>
                                        </template>
                                    </tr>
                                    <tr>
                                        <td
                                            colspan="3"
                                            style="font-weight: bold; border-top: 1px solid black;"
                                        >
                                            Total
                                        </td>
                                        <td
                                            align="right"
                                            style="font-weight: bold; white-space: nowrap; border-top: 1px solid black;"
                                        >
                                            {{
                                                coa.tDebit
                                                    | currency({ symbol: "" })
                                            }}
                                        </td>
                                        <td
                                            align="right"
                                            style="font-weight: bold; white-space: nowrap; border-top: 1px solid black;"
                                        >
                                            {{
                                                coa.tCredit
                                                    | currency({ symbol: "" })
                                            }}
                                        </td>
                                        <td
                                            style="border-top: 1px solid black;"
                                        ></td>
                                    </tr>
                                    <tr>
                                        <td
                                            colspan="3"
                                            style="font-weight: bold;"
                                        >
                                            Mutasi
                                        </td>
                                        <td
                                            align="right"
                                            style="font-weight: bold;"
                                        >
                                            {{
                                                coa.acc_position == "Debit"
                                                    ? coa.mDebit
                                                    : 0
                                                      | currency({ symbol: "" })
                                            }}
                                        </td>
                                        <td
                                            align="right"
                                            style="font-weight: bold;"
                                        >
                                            {{
                                                coa.acc_position == "Credit"
                                                    ? coa.mCredit
                                                    : 0
                                                      | currency({ symbol: "" })
                                            }}
                                        </td>
                                        <td
                                            align="right"
                                            style="font-weight: bold;"
                                        ></td>
                                    </tr>
                                    <tr>
                                        <td
                                            colspan="5"
                                            style="font-weight: bold;"
                                        >
                                            Ending Balance
                                        </td>
                                        <td
                                            align="right"
                                            style="font-weight: bold;"
                                        >
                                            {{
                                                coa.eb
                                                    | currency({ symbol: "" })
                                            }}
                                        </td>
                                    </tr>
                                    <tr class="bg-body-light">
                                        <td colspan="6"></td>
                                    </tr>
                                </table>
                            </div>
                            <!-- END Table -->

                            <hr />

                            <div class="row mt-4 mb-5">
                                <div class="col-6 text-left font-size-sm">
                                    <div class="form-group d-print-none">
                                        <label for="example-text-input"
                                            >Post Note *</label
                                        >
                                        <ckeditor
                                            tag-name="textarea"
                                            :editor="editor"
                                            v-model="docPostNote"
                                            :config="editorConfig"
                                        ></ckeditor>
                                    </div>
                                    <span
                                        class="d-none d-print-inline-block"
                                        v-html="docPostNote"
                                    ></span>
                                </div>
                                <div class="col-6 font-size-sm text-right">
                                    <div
                                        class="form-group text-left d-print-none"
                                    >
                                        <label for="example-text-input"
                                            >Choose Signature *</label
                                        >
                                        <selectize
                                            class="form-control"
                                            placeholder="Choose Signature"
                                            v-model="selectedSignature"
                                            @input="
                                                selectSignature(
                                                    selectedSignature
                                                )
                                            "
                                        >
                                            <option
                                                v-for="signature in signatures.data"
                                                :value="signature.id"
                                                :key="signature.id"
                                                >{{
                                                    signature.signature_name
                                                }}</option
                                            >
                                        </selectize>
                                    </div>
                                    <p
                                        class="mb-0 font-w700 mt-6 ml-9 text-center"
                                        style="border-bottom:1px solid grey"
                                        v-bind:class="{
                                            'd-print-none':
                                                selectedSignature == ''
                                        }"
                                    >
                                        {{ docSignature.signature_name }}
                                    </p>
                                    <p
                                        class="mb-0 font-w700 ml-9 text-center"
                                        v-bind:class="{
                                            'd-print-none':
                                                selectedSignature == ''
                                        }"
                                    >
                                        {{ docSignature.signature_position }}
                                    </p>
                                </div>
                            </div>

                            <hr />
                            <p
                                class="d-none d-print-inline-block"
                                style="opacity:0.6;"
                            >
                                <i> Printed : {{ dateNow }} </i>
                            </p>
                            <!-- END Footer -->
                        </div>
                    </div>
                </div>
                <!-- END Invoice -->
            </div>
        </div>
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import moment from "moment";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "../../../../../public/css/proxima-print.css";

export default {
    name: "general-ledger-display",
    data() {
        return {
            loadingPage: 1,
            useHeader: true,
            dateNow: moment().format("MMMM Do YYYY, HH:mm:ss"),
            selectedSignature: "",
            docSignature: {
                signature_name: "[select signature]",
                signature_position: "[select signature]"
            },
            editor: ClassicEditor,
            editorConfig: {
                toolbar: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "bulletedList",
                    "numberedList",
                    "blockQuote",
                    "undo",
                    "redo",
                    "insertTable"
                ]
            },
            docPostNote: "",
            datas: {}
        };
    },
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState("coa", { coas: state => state.coas.accounts }),
        ...mapState(["auth"]),
        ...mapState("report", { generalLedger: state => state.generalLedger }),
        ...mapState("signature", { signatures: state => state.signatures })
    },
    created() {
        if (
            this.$route.params.date_start &&
            this.$route.params.date_end &&
            this.$route.params.account_start &&
            this.$route.params.account_end
        ) {
            this.getSignatureDocument({ document: "signature_general_ledger" });
            this.generateReportGL({
                date_start: this.$route.params.date_start,
                date_end: this.$route.params.date_end,
                account_end: this.$route.params.account_end,
                account_start: this.$route.params.account_start
            })
                .then(response => {
                    this.convertArray(this.generalLedger.data);
                    this.loadingPage = 0;
                })
                .catch(error => {
                    console.log(error);
                    this.loadingPage = 0;
                });
        } else {
            this.$router.push({ name: "general-ledger" });
            this.alert("Select Date & Account First", 2);
        }
    },
    methods: {
        ...mapActions("coa", ["getCoaAll"]),
        ...mapActions("report", [
            "pdfReportGL",
            "xlsReportGL",
            "generateReportGL"
        ]),
        ...mapMutations("report", ["CLEAR_GL"]),
        ...mapActions("signature", ["getSignatureDocument"]),
        convertArray(val) {
            // const result = {}
            // val.forEach(item => {
            //     if (!result[item['acc_no']]){
            //         result[item['acc_no']] = {
            //             'account_no' : item['acc_no'],
            //             'account_name' : item['acc_name'],
            //             'opening_balance' : item['op_bal_todate'],
            //             'total_debit' : 0,
            //             'total_credit' : 0,
            //             'mutation_debit' : 0,
            //             'mutation_credit' : 0,
            //             'eBalance' : 0,
            //             'journal' : [],
            //         }
            //     }
            //     result[item['acc_no']].journal.push(item)
            //     result[item['acc_no']].total_debit = result[item['acc_no']].total_debit + item.debit
            //     result[item['acc_no']].total_credit = result[item['acc_no']].total_credit + item.credit
            //     if(item.account_position == 'Debit'){
            //         result[item['acc_no']].mutation_debit = Math.abs(result[item['acc_no']].total_debit - result[item['acc_no']].total_credit)
            //     }else{
            //         result[item['acc_no']].mutation_credit = Math.abs(result[item['acc_no']].total_debit - result[item['acc_no']].total_credit)
            //     }
            //     result[item['acc_no']].eBalance = item.eBalance

            // })
            this.datas = val;
        },
        selectSignature(val) {
            if (val != "") {
                var result = this.signatures.data.find(obj => {
                    return obj.id === parseInt(val);
                });
                this.docSignature.signature_name = result.signature_name;
                this.docSignature.signature_position =
                    result.signature_position;
            } else {
                this.docSignature.signature_name = "[select signature]";
                this.docSignature.signature_position = "[select signature]";
            }
        },
        errorLogoUrl(event) {
            event.target.src = "/media/workspace/no-logo.jpg";
        },
        //METHOD
        print() {
            const prtHtml = document.getElementById("printMe").innerHTML;
            // var browserName  = navigator.appName;
            var printContents = document.getElementById("printMe").innerHTML;
            var originalContents = document.body.innerHTML;
            document.body.innerHTML = printContents;
            window.print();
            document.body.innerHTML = originalContents;
            // Get all stylesheets HTML
            // let stylesHtml = '';
            // for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
            // stylesHtml += node.outerHTML;
            // }
            // console.log(stylesHtml);

            // Open the print window
            // const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

            // WinPrint.document.write(`<!DOCTYPE html>
            // <html>
            // <head>
            //     ${stylesHtml}
            // </head>
            // <body>
            //     ${prtHtml}
            // </body>
            // </html>`);

            // WinPrint.document.close();
            // WinPrint.focus();
            // WinPrint.print();
            // WinPrint.close();
        },
        refresh() {
            this.loadingPage = 2;
            this.datas = {};
            this.generateReportGL({
                date_start: this.$route.params.date_start,
                date_end: this.$route.params.date_end,
                account_end: this.$route.params.account_end,
                account_start: this.$route.params.account_start
            })
                .then(response => {
                    this.convertArray(this.generalLedger.data);
                    this.loadingPage = 0;
                })
                .catch(error => {
                    console.log(error);
                    this.loadingPage = 0;
                });
        }
    },
    destroyed() {
        this.CLEAR_GL();
    }
};
</script>
