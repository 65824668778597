<template>
    <main>
        <hero title="Asset Data" prevBreadcrumb="Dashboard" routeBreadcrumb="home" currentBreadcrumb="Asset Data"></hero>

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 1 || !assets.data"
                style="height: 250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <!-- Dynamic Table Full -->
            <div class="block block-rounded block-mode-loading-oneui" v-else>
                <div class="block-content block-content-full pt-3">
                    <v-app>
                        <v-data-table
                            :headers="headers"
                            :items="datas"
                            :search="search"
                            :hide-default-footer="true"
                            :items-per-page="50"
                            :mobile-breakpoint="0"
                        >
                            <template
                                v-slot:top="{
                                    pagination,
                                    options,
                                    updateOptions,
                                }"
                            >
                                <div class="row">
                                    <div
                                        class="col-12 col-md-7 mb-2 mb-sm-0 pt-0 pb-2"
                                    >
                                        <v-layout justify-start>
                                            <v-data-footer
                                                :pagination="pagination"
                                                :options="options"
                                                @update:options="updateOptions"
                                                :items-per-page-options="[
                                                    10,
                                                    30,
                                                    50,
                                                    100,
                                                    -1,
                                                ]"
                                                items-per-page-text="Rows :"
                                            />
                                        </v-layout>
                                    </div>
                                    <div
                                        class="col-12 col-md-5 col-lg-5 col-xl-5 pb-0 pt-2"
                                    >
                                        <input
                                            type="text"
                                            class="form-control form-control-sm col-12 col-md-6 float-right ml-1 mt-1 search-index"
                                            name="example-text-input"
                                            placeholder="Search.."
                                            v-model="search"
                                        />
                                        <router-link
                                            type="button"
                                            class="btn btn-sm btn-info float-right btn-adjust-1"
                                            :to="{ name: 'asset-create' }"
                                            tag="button"
                                            v-ripple="{ center: true }"
                                        >
                                            <i class="fa fa-plus mr-1"></i>Asset
                                            Acquisition
                                        </router-link>
                                    </div>
                                </div>
                            </template>
                            <template v-slot:body="{ items }">
                                <tbody v-if="loadingPage == 2">
                                    <tr class="data-empty">
                                        <td colspan="7" class="py-2">
                                            <grid-loader
                                                class="mx-auto mt-5 pt-5"
                                                :color="'#70B9EB'"
                                                :size="'10px'"
                                            ></grid-loader>
                                            <center class="mt-2 mb-5 pb-5">
                                                <span
                                                    class="text-center"
                                                    style="opacity: 0.7"
                                                    >Loading</span
                                                >
                                            </center>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody
                                    v-if="items.length > 0 && loadingPage == 0"
                                >
                                    <tr
                                        v-for="(asset, index) in items"
                                        :key="index"
                                    >
                                        <td
                                            @click="toDetail(asset.assetId)"
                                            class="text-center font-size-sm"
                                        >
                                            {{ ++index }}
                                        </td>
                                        <td
                                            @click="toDetail(asset.assetId)"
                                            class="font-size-sm"
                                        >
                                            <span v-html="asset.assetName">
                                            </span>
                                        </td>
                                        <td
                                            @click="toDetail(asset.assetId)"
                                            class="font-size-sm text-center"
                                            align="center"
                                        >
                                            {{ asset.assetAcqDate }}
                                            <br>
                                            <span
                                                class="badge badge-info mr-1"
                                                v-if="asset.assetStatus === 'Active'"
                                                >Active</span
                                            >
                                            <span
                                                class="badge badge-secondary mr-1"
                                                v-else
                                                >Inactive</span
                                            >
                                        </td>
                                        <td
                                            @click="toDetail(asset.assetId)"
                                            class="font-size-sm text-right"
                                            style="max-width: 300px"
                                        >
                                            {{ asset.assetAcqVal | currency }}
                                        </td>
                                        <td
                                            @click="toDetail(asset.assetId)"
                                            class="font-size-sm text-right"
                                            style="max-width: 300px"
                                        >
                                            {{ asset.assetMonDep | currency }}
                                        </td>
                                        <td
                                            @click="toDetail(asset.assetId)"
                                            class="font-size-sm text-right"
                                            style="max-width: 300px"
                                        >
                                            {{ asset.assetAccDep | currency }}
                                        </td>
                                        <td
                                            @click="toDetail(asset.assetId)"
                                            class="font-size-sm text-right"
                                            style="max-width: 300px"
                                        >
                                            {{ asset.assetBookVal | currency }}
                                        </td>
                                        <!-- DROPDOWN AURORA -->
                                        <td align="right">
                                            <div
                                                class="dropdown dropleft push m-0"
                                            >
                                                <button
                                                    type="button"
                                                    class="btn btn-sm btn-alt-secondary btn-transparent btn-rounded text--lighten-4"
                                                    id="dropdown-dropleft"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    <i
                                                        class="fa fa-fw fa-ellipsis-v"
                                                    ></i>
                                                </button>
                                                <div
                                                    class="dropdown-menu dropdown-menu-rounded font-size-sm px-1"
                                                    aria-labelledby="dropdown-dropleft"
                                                >
                                                    <button
                                                        @click="
                                                            toDetail(
                                                                asset.assetId
                                                            )
                                                        "
                                                        class="btn btn-sm btn-transparent btn-alt-info dropdown-item-horizontal text-info"
                                                        v-ripple="{
                                                            center: true,
                                                        }"
                                                        style="
                                                            position: inline-block;
                                                        "
                                                    >
                                                        <i
                                                            class="si si-magnifier mr-1"
                                                        ></i>
                                                        <span
                                                            class="d-none d-md-inline"
                                                            >Detail</span
                                                        >
                                                    </button>
                                                    <!-- <button
                                                        @click="
                                                            toJournalVoucher(
                                                                asset.assetJournalId
                                                            )
                                                        "
                                                        class="btn btn-sm btn-transparent btn-alt-warning dropdown-item-horizontal text-warning"
                                                        v-ripple="{
                                                            center: true,
                                                        }"
                                                        style="
                                                            position: inline-block;
                                                        "
                                                    >
                                                        <i
                                                            class="si si-notebook mr-1"
                                                        ></i>
                                                        <span
                                                            class="d-none d-md-inline"
                                                            >Journal</span
                                                        >
                                                    </button> -->
                                                    <button
                                                        @click="
                                                            toEdit(
                                                                asset.assetId
                                                            )
                                                        "
                                                        class="btn btn-sm btn-transparent btn-alt-success dropdown-item-horizontal text-success"
                                                        v-ripple="{
                                                            center: true,
                                                        }"
                                                        style="
                                                            position: inline-block;
                                                        "
                                                    >
                                                        <i
                                                            class="si si-pencil mr-1"
                                                        ></i>
                                                        <span
                                                            class="d-none d-md-inline"
                                                            >Edit</span
                                                        >
                                                    </button>
                                                    <button
                                                        class="btn btn-sm btn-transparent btn-alt-danger text-danger dropdown-item-horizontal"
                                                        v-ripple="{
                                                            center: true,
                                                        }"
                                                        @click.prevent="
                                                            deleteData(
                                                                asset.assetId
                                                            )
                                                        "
                                                    >
                                                        <i
                                                            class="si si-trash mr-1"
                                                        ></i>
                                                        <span
                                                            class="d-none d-md-inline"
                                                            >Delete</span
                                                        >
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody
                                    v-if="items.length <= 0 && loadingPage == 0"
                                >
                                    <tr class="data-empty">
                                        <td
                                            class="text-center"
                                            colspan="7"
                                            style="
                                                opacity: 0.8;
                                                padding: 2.5rem;
                                            "
                                        >
                                            <center>
                                                <img
                                                    :src="'/media/proxima/empty-data.svg'"
                                                    style="height: 100px"
                                                />
                                            </center>
                                            <br /><span style="opacity: 0.6"
                                                >Data Not Found</span
                                            >
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-data-table>
                    </v-app>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";

export default {
    name: "asset-register",
    data() {
        return {
            search: "",
            awaitingSearch: false,
            rows: "All",
            loadingPage: 1,
            type: "All Assets",
            amountExp: 0,
            totalAsset: 0,
            headers: [
                { text: "No.", align: "center", sortable: false },
                { text: "Asset Name", align: "start", value: "assetName" },
                { text: "Acq. Date", align: "center", value: "assetAcqDate" },
                { text: "Acq. Value", align: "center", value: "assetAcqVal" },
                { text: "Monthly Dep.", align: "center", value: "assetMonDep" },
                {
                    text: "Accumulated Dep.",
                    align: "center",
                    value: "assetAccDep",
                },
                { text: "Book Value", align: "center", value: "assetBookVal" },
                { text: "", sortable: false },
            ],
            datas: [],
        };
    },
    created() {
        this.getData();
        this.$session.remove("asset_detail");
    },
    computed: {
        ...mapState("asset", { assets: (state) => state.assets }),
        ...mapState(["auth"]),
    },
    methods: {
        ...mapActions("asset", ["getAsset", "removeAsset"]),
        ...mapActions("assetcategory", ["getCategory"]),
        getData() {
            this.loadingPage = 2;
            this.datas = [];
            this.getAsset({ rows: this.rows, type: this.type })
                .then(() => {
                    this.assets.data.forEach((element) => {
                        let dataAsset =
                            element.asset_name +
                            '<br><small style="color:#c2c2c2!important;">' +
                            element.category.category_name +
                            " | " +
                            element.asset_code +
                            "</small>";
                        this.datas.push({
                            assetId: element.id,
                            assetName: dataAsset,
                            assetCode: element.asset_code,
                            assetAcqVal: element.asset_acquisition_value,
                            assetStatus: element.statusAsset,
                            assetAcqDate: moment(
                                element.asset_acquisition_date
                            ).format("DD/MM/YYYY"),
                            assetMonDep: 0,
                            assetAccDep: element.accumulated_depreciation,
                            assetBookVal: element.book_value
                        });
                    });
                    this.loadingPage = 0;
                })
                .catch((error) => {
                    error.response.status == 500
                        ? this.alert(error.response.data.message, 2)
                        : "";
                    error.response.status != 422 && error.response.status != 500
                        ? this.alert(error.message, 2)
                        : "";
                    this.loadingPage = 0;
                    this.totalAsset = 0;
                });
        },
        toDetail(val) {
            this.$router.push({ name: "asset-detail", params: { id: val } });
        },
        toEdit(val) {
            this.$router.push({ name: "asset-edit", params: { id: val } });
        },
        toJournalVoucher(val) {
            this.$router.push({
                name: "journal-transaction-voucher",
                params: { id: val },
            });
        },
        deleteData(val) {
            this.$swal({
                title: "Are you sure ?",
                text: "Deleted data cannot be recovery",
                icon: "warning",
                buttons: ["Cancel", "Delete"],
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    this.loadingPage = 1;
                    this.removeAsset(val)
                        .then(() => {
                            this.alert("Data has been deleted !", 1);
                            this.getData();
                            this.loadingPage = 0;
                        })
                        .catch((error) => {
                            error.response.status == 500
                                ? this.alert(error.response.data.message, 2)
                                : "";
                            error.response.status != 422 &&
                            error.response.status != 500
                                ? this.alert(error.message, 2)
                                : "";
                            this.loadingPage = 0;
                        });
                }
            });
        },
    },
};
</script>
