<template>
    <main>
        <hero title="Create Depreciation" prevBreadcrumb="Asset Depreciation" routeBreadcrumb="asset-depreciation" currentBreadcrumb="Create Depreciation"></hero>

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <div
                class="block col-12 col-md-8 offset-md-2 block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 1"
                style="height: 250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <div
                class="block col-12 col-md-8 offset-md-2 block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 2"
                style="height: 250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Save Data</span
                        >
                    </center>
                </div>
            </div>
            <!-- Basic -->
            <div
                class="block col-12 col-md-8 offset-md-2  block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 0"
            >
                <div class="block-content block-content-full">
                    <form
                        method="POST"
                        enctype="multipart/form-data"
                        onsubmit="return false;"
                    >
                        <!--Form-->
                        <div class="row font-size-sm">
                            <div class="col-12 col-md-10 offset-md-1">
                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Select Period *</label
                                    >
                                    <date-picker
                                        class="input-group"
                                        type="month"
                                        format="MMMM YYYY"
                                        valueType="YYYY-MM"
                                        v-model="depreciation.depreciation_period"
                                        placeholder="Depreciation Period"
                                        :class="{
                                            'is-invalid': errors.depreciation_period,
                                        }"
                                    ></date-picker>
                                    <transition name="slide-fade">
                                        <p
                                            class="text-danger"
                                            v-if="errors.depreciation_period"
                                        >
                                            {{ errors.depreciation_period[0] }}
                                        </p>
                                    </transition>
                                </div>
                            </div>
                        </div>
                        <!--END Form-->
                        <!-- Submit -->
                        <div class="row items-push">
                            <div class="col-12 text-center">
                                <p class="font-size-sm text-muted">
                                    * Required filled, Make sure all data is
                                    correctly filled
                                </p>
                                <button
                                    type="submit"
                                    class="btn btn-sm btn-success"
                                    v-ripple="{ center: true }"
                                    @click.prevent="submit"
                                    :disabled="depreciation.depreciation_period ==''"
                                >
                                    <i class="fa fa-fw fa-check mr-1"></i> Process
                                </button>
                                <router-link
                                    type="button"
                                    class="btn btn-sm btn-alt-light"
                                    tag="button"
                                    v-ripple="{ center: true }"
                                    :to="{ name: 'asset-depreciation' }"
                                >
                                    <i class="fa fa-fw fa-times mr-1"></i> Close
                                </router-link>
                            </div>
                        </div>
                        <!-- END Submit -->
                    </form>
                </div>
            </div>
            <!-- END Basic -->
        </div>
        <!-- END Page Content -->
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import moment from "moment";


export default {
    name: "depreciation",

    data() {
        return {
            loadingPage: 0,
        };
    },
    computed: {
        ...mapState("depreciation", {
            depreciation: (state) => state.depreciation,
        }),
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
    },
    methods: {
        //VUEX
        ...mapMutations("depreciation", ["CLEAR_FORM"]),
        ...mapActions("coa", ["getCoaAll"]),
        ...mapActions("depreciation", ["submitDepreciation"]),

        isNumber: function (evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46
            ) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        submit() {
            this.loadingPage = 2;
            this.submitDepreciation()
                .then((response) => {
                    this.$router.push({ name: "asset-depreciation" });
                    this.alert("Successfully create Asset Depreciation Data ", 1);
                    this.loadingPage = 0;
                })
                .catch((error) => {
                    error.response.status == 500
                        ? this.alert(error.response.data.message, 2)
                        : "";
                    error.response.status != 422 &&
                    error.response.status != 500
                        ? this.alert(error.message, 2)
                        : "";
                    this.loadingPage = 0;
                });
        },
    },
    destroyed() {
        //FORM DI BERSIHKAN
        this.CLEAR_FORM();
    },
};
</script>
