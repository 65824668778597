<template>
    <main>
        <!-- Hero -->
        <div class="bg-body-light">
            <div class="content content-full">
                <div
                    class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center"
                >
                    <h1 class="flex-sm-fill h3 my-2">
                        Accounting : Trial Balance Cumulative Report
                    </h1>
                    <nav
                        class="flex-sm-00-auto ml-sm-3"
                        aria-label="breadcrumb"
                    >
                        <ol class="breadcrumb breadcrumb-alt">
                            <li class="breadcrumb-item" aria-current="page">
                                <router-link
                                    class="link-fx"
                                    :to="{ name: 'general-ledger' }"
                                    >Trial Balance</router-link
                                >
                            </li>
                            <li class="breadcrumb-item">
                                Trial Balance Cumulative Report
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
        <!-- END Hero -->

        <!-- Page Content -->
        <div class="content">
            <div class="block mb-2 block-rounded block-mode-loading-oneui">
                <div class="block-header">
                    <h3 class="block-title">Trial Balance</h3>
                    <div class="block-options">
                        <!-- Print Page functionality is initialized in Helpers.print() -->
                        <button
                            type="button"
                            class="btn-block-option text-info"
                            @click="print()"
                        >
                            <i class="si si-printer mr-1"></i>
                            <span class="d-none d-md-inline-block"
                                >Print Document</span
                            >
                        </button>
                    </div>
                </div>
            </div>
            <div class="block bg-transparent mb-2">
                <div class="block-content pt-0 bg-transparent text-right">
                    <input
                        class="form-check-input"
                        style="margin-top:3.5px"
                        type="checkbox"
                        v-model="useHeader"
                    />
                    <label class="form-check-label">Default Header</label>
                </div>
            </div>
            <!--Loading-->
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 2"
                style="height:250px"
            >                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity:0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <!-- Basic -->
            <div class="block block-rounded block-mode-loading-oneui" v-else>
                <div class="content content-boxed" id="printMe">
                    <div class="block-content">
                        <div
                            class="p-0 p-md-5"
                            style="min-height:800px; @media print {
                                @page {
                                    size: landscape !important;
                                }
                            }"
                        >
                            <div class="row pt-0" v-if="useHeader">
                                <div class="col-6 font-size-sm pb-0 pt-0">
                                    <p class="h3 mb-2 mt-3 text-uppercase">
                                        {{
                                            auth.data.workspace
                                                .workspace_company_name
                                        }}
                                    </p>
                                    <p class="mb-0">
                                        {{
                                            auth.data.workspace
                                                .workspace_company_address
                                        }}
                                    </p>
                                    <p class="mb-0">
                                        <span
                                            v-if="
                                                auth.data.workspace
                                                    .workspace_company_province !==
                                                    '' &&
                                                    auth.data.workspace
                                                        .workspace_company_province !==
                                                        null
                                            "
                                        >
                                            {{
                                                auth.data.workspace
                                                    .workspace_company_province
                                            }}
                                        </span>
                                        <span
                                            v-if="
                                                auth.data.workspace
                                                    .workspace_company_postal !==
                                                    '' &&
                                                    auth.data.workspace
                                                        .workspace_company_postal !==
                                                        null
                                            "
                                        >
                                            ,
                                            {{
                                                auth.data.workspace
                                                    .workspace_company_postal
                                            }}
                                        </span>
                                    </p>
                                    <p class="mb-0">
                                        <span
                                            v-if="
                                                auth.data.workspace
                                                    .workspace_company_phone !==
                                                    '' &&
                                                    auth.data.workspace
                                                        .workspace_company_phone !==
                                                        null
                                            "
                                        >
                                            {{
                                                auth.data.workspace
                                                    .workspace_company_phone
                                            }}
                                        </span>
                                        <span
                                            v-if="
                                                auth.data.workspace
                                                    .workspace_company_email !==
                                                    '' &&
                                                    auth.data.workspace
                                                        .workspace_company_email !==
                                                        null
                                            "
                                        >
                                            {{
                                                "| " +
                                                    auth.data.workspace
                                                        .workspace_company_email
                                            }}
                                        </span>
                                    </p>
                                </div>
                                <div
                                    class="col-6 text-right font-size-sm pb-0 pt-0"
                                >
                                    <img
                                        :src="
                                            '/storage/workspace/' +
                                                auth.data.workspace
                                                    .workspace_company_logo
                                        "
                                        alt=""
                                        style="min-heigh:100px; max-height:120px; max-width:180px;"
                                        @error="errorLogoUrl"
                                    />
                                </div>
                            </div>

                            <hr v-if="useHeader"/>

                            <!-- Invoice Info -->
                            <div class="row mb-2">
                                <!-- Company Info -->
                                <div class="col-6 font-size-sm">
                                    <p class="h3 mb-1">Trial Balance</p>
                                    <p class="mb-2">Currency : IDR</p>
                                </div>
                                <!-- END Company Info -->

                                <!-- Client Info -->
                                <div class="col-6 text-right font-size-sm">
                                    <p class="mb-2">
                                        From :
                                        {{
                                            trialBalance.date_start
                                                | moment("DD/MM/YYYY")
                                        }}
                                    </p>
                                    <p class="mb-2">
                                        To :
                                        {{
                                            trialBalance.date_end
                                                | moment("DD/MM/YYYY")
                                        }}
                                    </p>
                                </div>
                                <!-- END Client Info -->
                            </div>
                            <!-- END Invoice Info -->

                            <!-- Table -->
                            <div
                                class="table-responsive push font-size-print-table horizontal-scroll"
                            >
                                <table class="table table-bordered">
                                    <tr
                                        class="bg-body-light font-size-print-table"
                                    >
                                        <td
                                            align="center"
                                            style="font-weight: bold;"
                                            colspan="2"
                                        >
                                            Account
                                        </td>
                                        <td
                                            v-for="workspace in trialBalance.data"
                                            :key="workspace.index"
                                            align="center"
                                            style="font-weight: bold;"
                                            colspan="4"
                                        >
                                            {{
                                                workspace.workspace
                                                    .workspace_name
                                            }}
                                        </td>
                                    </tr>
                                    <tr
                                        class="bg-body-light font-size-print-table"
                                    >
                                        <td
                                            class="text-center"
                                            style="font-weight: bold; vertical-align:middle;"
                                            rowspan="2"
                                        >
                                            No.
                                        </td>
                                        <td
                                            class="text-center"
                                            style="font-weight: bold; vertical-align:middle;"
                                            rowspan="2"
                                        >
                                            Name
                                        </td>
                                        <template
                                            v-for="workspace in trialBalance.data"
                                        >
                                            <td
                                                align="center"
                                                style="font-weight: bold;"
                                                colspan="2"
                                                :key="workspace.index"
                                            >
                                                O
                                            </td>
                                            <!-- <td align="center" style="font-weight: bold;" colspan="2" :key="workspace.index">M</td> -->
                                            <td
                                                align="center"
                                                style="font-weight: bold;"
                                                colspan="2"
                                                :key="workspace.index"
                                            >
                                                E
                                            </td>
                                        </template>
                                    </tr>
                                    <tr
                                        class="bg-body-light font-size-print-table"
                                    >
                                        <template
                                            v-for="workspace in trialBalance.data"
                                        >
                                            <td
                                                align="center"
                                                style="font-weight: bold; "
                                                :key="workspace.index"
                                            >
                                                Debit
                                            </td>
                                            <td
                                                align="center"
                                                style="font-weight: bold; "
                                                :key="workspace.index"
                                            >
                                                Credit
                                            </td>
                                            <!-- <td align="center" style="font-weight: bold; " :key="workspace.index">Debit</td> -->
                                            <!-- <td align="center" style="font-weight: bold; " :key="workspace.index">Credit</td> -->
                                            <td
                                                align="center"
                                                style="font-weight: bold; "
                                                :key="workspace.index"
                                            >
                                                Debit
                                            </td>
                                            <td
                                                align="center"
                                                style="font-weight: bold; "
                                                :key="workspace.index"
                                            >
                                                Credit
                                            </td>
                                        </template>
                                    </tr>
                                    <tr
                                        class="font-size-print-table"
                                        v-for="coa in trialBalance.data[0]
                                            .coa_list"
                                        :key="coa.index"
                                    >
                                        <td>{{ coa.account_no }}</td>
                                        <td>{{ coa.account_name }}</td>
                                        <template
                                            v-for="workspace2 in trialBalance.data"
                                        >
                                            <template
                                                v-for="coa2 in workspace2.coa_list"
                                            >
                                                <td
                                                    align="right"
                                                    style="white-space: nowrap;"
                                                    :key="coa2.index"
                                                    v-if="
                                                        coa2.account_no ==
                                                            coa.account_no
                                                    "
                                                >
                                                    {{
                                                        coa2.opening_balance_debit
                                                            | currency({
                                                                symbol: ""
                                                            })
                                                    }}
                                                </td>
                                                <td
                                                    align="right"
                                                    style="white-space: nowrap;"
                                                    :key="coa2.index"
                                                    v-if="
                                                        coa2.account_no ==
                                                            coa.account_no
                                                    "
                                                >
                                                    {{
                                                        coa2.opening_balance_credit
                                                            | currency({
                                                                symbol: ""
                                                            })
                                                    }}
                                                </td>
                                                <!-- <td align='right' style='white-space: nowrap;' :key="coa2.index" v-if="coa2.account_no == coa.account_no">{{coa2.mutation_debit | currency({symbol: ''}) }}</td> -->
                                                <!-- <td align='right' style='white-space: nowrap;' :key="coa2.index" v-if="coa2.account_no == coa.account_no">{{coa2.mutation_credit | currency({symbol: ''}) }}</td> -->
                                                <td
                                                    align="right"
                                                    style="white-space: nowrap;"
                                                    :key="coa2.index"
                                                    v-if="
                                                        coa2.account_no ==
                                                            coa.account_no
                                                    "
                                                >
                                                    {{
                                                        coa2.ending_balance_debit
                                                            | currency({
                                                                symbol: ""
                                                            })
                                                    }}
                                                </td>
                                                <td
                                                    align="right"
                                                    style="white-space: nowrap;"
                                                    :key="coa2.index"
                                                    v-if="
                                                        coa2.account_no ==
                                                            coa.account_no
                                                    "
                                                >
                                                    {{
                                                        coa2.ending_balance_credit
                                                            | currency({
                                                                symbol: ""
                                                            })
                                                    }}
                                                </td>
                                            </template>
                                        </template>
                                    </tr>

                                    <tr
                                        v-if="
                                            trialBalance.data.coa_list &&
                                                trialBalance.data.coa_list
                                                    .length === 0
                                        "
                                    >
                                        <td colspan="6" class="text-center">
                                            No Account
                                        </td>
                                    </tr>
                                    <tr
                                        class="bg-body-light font-size-print-table"
                                    >
                                        <td
                                            align="center"
                                            colspan="2"
                                            style="font-weight: bold; border-top:"
                                        >
                                            Total
                                        </td>
                                        <template
                                            v-for="workspace in trialBalance.data"
                                        >
                                            <td
                                                align="right"
                                                style="font-weight: bold; white-space: nowrap;"
                                                :key="workspace.index"
                                            >
                                                {{
                                                    workspace.total_opening_debit
                                                        | currency({
                                                            symbol: ""
                                                        })
                                                }}
                                            </td>
                                            <td
                                                align="right"
                                                style="font-weight: bold; white-space: nowrap;"
                                                :key="workspace.index"
                                            >
                                                {{
                                                    workspace.total_opening_credit
                                                        | currency({
                                                            symbol: ""
                                                        })
                                                }}
                                            </td>
                                            <!-- <td align="right" style="font-weight: bold; white-space: nowrap;" :key="workspace.index">{{workspace.total_mutation_debit | currency({symbol: ''}) }}</td> -->
                                            <!-- <td align="right" style="font-weight: bold; white-space: nowrap;" :key="workspace.index">{{workspace.total_mutation_credit | currency({symbol: ''}) }}</td> -->
                                            <td
                                                align="right"
                                                style="font-weight: bold; white-space: nowrap;"
                                                :key="workspace.index"
                                            >
                                                {{
                                                    workspace.total_ending_debit
                                                        | currency({
                                                            symbol: ""
                                                        })
                                                }}
                                            </td>
                                            <td
                                                align="right"
                                                style="font-weight: bold; white-space: nowrap;"
                                                :key="workspace.index"
                                            >
                                                {{
                                                    workspace.total_ending_credit
                                                        | currency({
                                                            symbol: ""
                                                        })
                                                }}
                                            </td>
                                        </template>
                                    </tr>
                                </table>
                            </div>
                            <!-- END Table -->

                            <hr />

                            <div class="row mt-4 mb-5">
                                <div class="col-6 text-left font-size-sm">
                                    <div class="form-group d-print-none">
                                        <label for="example-text-input"
                                            >Post Note *</label
                                        >
                                        <ckeditor
                                            tag-name="textarea"
                                            :editor="editor"
                                            v-model="docPostNote"
                                            :config="editorConfig"
                                        ></ckeditor>
                                    </div>
                                    <span
                                        class="d-none d-print-inline-block"
                                        v-html="docPostNote"
                                    ></span>
                                </div>
                                <div class="col-6 font-size-sm text-right">
                                    <div
                                        class="form-group text-left d-print-none"
                                    >
                                        <label for="example-text-input"
                                            >Choose Signature *</label
                                        >
                                        <selectize
                                            class="form-control"
                                            placeholder="Choose Signature"
                                            v-model="selectedSignature"
                                            @input="
                                                selectSignature(
                                                    selectedSignature
                                                )
                                            "
                                        >
                                            <option
                                                v-for="signature in signatures.data"
                                                :value="signature.id"
                                                :key="signature.id"
                                                >{{
                                                    signature.signature_name
                                                }}</option
                                            >
                                        </selectize>
                                    </div>
                                    <p
                                        class="mb-0 font-w700 mt-6 ml-9 text-center"
                                        style="border-bottom:1px solid grey"
                                        v-bind:class="{
                                            'd-print-none':
                                                selectedSignature == ''
                                        }"
                                    >
                                        {{ docSignature.signature_name }}
                                    </p>
                                    <p
                                        class="mb-0 font-w700 ml-9 text-center"
                                        v-bind:class="{
                                            'd-print-none':
                                                selectedSignature == ''
                                        }"
                                    >
                                        {{ docSignature.signature_position }}
                                    </p>
                                </div>
                            </div>

                            <hr />
                            <p
                                class="d-none d-print-inline-block"
                                style="opacity:0.6;"
                            >
                                <i> Printed : {{ dateNow }} </i>
                            </p>
                            <!-- END Footer -->
                        </div>
                    </div>
                </div>
                <!-- END Invoice -->
            </div>
        </div>
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import moment from "moment";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
let basePath = window.location.origin;

const options = {
    name: "Document",
    specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
    styles: [
        `${basePath}/css/proxima-print.css`,
        `${basePath}/css/proxima-landscape.css`
    ]
};

export default {
    name: "trial-balance-display",

    data() {
        return {
            loadingPage: 1,
            useHeader: true,
            dateNow: moment().format("MMMM Do YYYY, HH:mm:ss"),
            selectedSignature: "",
            docSignature: {
                signature_name: "[select signature]",
                signature_position: "[select signature]"
            },
            editor: ClassicEditor,
            editorConfig: {
                toolbar: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "bulletedList",
                    "numberedList",
                    "blockQuote",
                    "undo",
                    "redo",
                    "insertTable"
                ]
            },
            docPostNote: ""
        };
    },
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState("coa", { coas: state => state.coas.accounts }),
        ...mapState(["auth"]),
        ...mapState("report", { trialBalance: state => state.trialBalance }),
        ...mapState("signature", { signatures: state => state.signatures })
    },
    mounted() {
        if (
            this.$route.params.date_start &&
            this.$route.params.date_end &&
            this.$route.params.account_start &&
            this.$route.params.account_end
        ) {
            this.generateReportCumulativeTB({
                date_start: this.$route.params.date_start,
                date_end: this.$route.params.date_end,
                account_end: this.$route.params.account_end,
                account_start: this.$route.params.account_start
            })
                .then(response => {
                    this.loadingPage = 0;
                })
                .catch(error => {
                    console.log(error);
                    this.loadingPage = 0;
                });
            this.getSignatureDocument({ document: "signature_trial_balance" })
                .then(response => {
                    this.loadingPage = false;
                })
                .catch(error => {
                    console.log(error);
                    this.loadingPage = false;
                });
        } else {
            this.$router.push({ name: "trial-balance-cumulative" });
            this.alert("Select Date & Account First", 2);
        }
    },
    methods: {
        ...mapActions("coa", ["getCoaAll"]),
        ...mapActions("report", [
            "pdfReportTB",
            "xlsReportTB",
            "generateReportCumulativeTB"
        ]),
        ...mapActions("signature", ["getSignatureDocument"]),

        selectSignature(val) {
            if (val != "") {
                var result = this.signatures.data.find(obj => {
                    return obj.id === parseInt(val);
                });
                this.docSignature.signature_name = result.signature_name;
                this.docSignature.signature_position =
                    result.signature_position;
            } else {
                this.docSignature.signature_name = "[select signature]";
                this.docSignature.signature_position = "[select signature]";
            }
        },
        errorLogoUrl(event) {
            event.target.src = "/media/workspace/no-logo.jpg";
        },
        //METHOD
        print() {
            var browserName = navigator.appName;
            if (browserName == "Safari") {
                var printContents = document.getElementById("printMe")
                    .innerHTML;
                var originalContents = document.body.innerHTML;
                document.body.innerHTML = printContents;
                window.print();
                document.body.innerHTML = originalContents;
            } else {
                this.$htmlToPaper("printMe", options);
            }
        }
    }
};
</script>
