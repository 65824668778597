<template>
    <main>
        <hero title="Linked Account" prevBreadcrumb="Dashboard" routeBreadcrumb="home" currentBreadcrumb="Linked Account"></hero>

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <div
                class="block col-12 col-md-8 offset-md-2 block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 1"
                style="height:250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity:0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <div
                class="block col-12 col-md-8 offset-md-2 block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 2"
                style="height:250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity:0.7"
                            >Save Data</span
                        >
                    </center>
                </div>
            </div>
            <!-- Basic -->
            <div
                class="block col-12 col-md-8 offset-md-2 block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 0"
            >
                <div class="block-content block-content-full">
                    <form
                        method="POST"
                        enctype="multipart/form-data"
                        onsubmit="return false;"
                    >
                        <!--Form-->
                        <div class="row font-size-sm">
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label for="example-text-input">Bill</label>
                                </div>
                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Bank Account for Contact Receipt</label
                                    >
                                    <selectize
                                        class="form-control"
                                        placeholder="Choose Account"
                                        v-model="coalink.contact_receipt"
                                        :class="{
                                            'is-invalid-selectize':
                                                errors.contact_receipt
                                        }"
                                    >
                                        <!-- settings is optional -->
                                        <option
                                            v-for="account in assetAccounts"
                                            :value="account.id"
                                            :key="account.id"
                                            >{{ account.account_no }}
                                            {{ account.account_name }}</option
                                        >
                                    </selectize>
                                    <transition name="slide-fade">
                                        <p
                                            class="text-danger"
                                            v-if="errors.contact_receipt"
                                        >
                                            {{ errors.contact_receipt }}
                                        </p>
                                    </transition>
                                </div>
                                <div
                                    class="form-group"
                                    style="border-bottom: 1px solid #eaeaea;padding-bottom: 15px;"
                                >
                                    <label for="example-text-input"
                                        >Asset Account for Tracking
                                        Receiveable</label
                                    >
                                    <selectize
                                        class="form-control"
                                        placeholder="Choose Account"
                                        v-model="coalink.tracking_receiveable"
                                        :class="{
                                            'is-invalid-selectize':
                                                errors.tracking_receiveable
                                        }"
                                    >
                                        <!-- settings is optional -->
                                        <option
                                            v-for="account in receivableAccounts"
                                            :value="account.id"
                                            :key="account.id"
                                            >{{ account.account_no }}
                                            {{ account.account_name }}</option
                                        >
                                    </selectize>
                                    <transition name="slide-fade">
                                        <p
                                            class="text-danger"
                                            v-if="errors.tracking_receiveable"
                                        >
                                            {{ errors.tracking_receiveable }}
                                        </p>
                                    </transition>
                                </div>
                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Purchase</label
                                    >
                                </div>
                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Bank Account for Pay Purchase</label
                                    >
                                    <selectize
                                        class="form-control"
                                        placeholder="Choose Account"
                                        v-model="coalink.pay_purchase"
                                        :class="{
                                            'is-invalid-selectize':
                                                errors.pay_purchase
                                        }"
                                    >
                                        <!-- settings is optional -->
                                        <option
                                            v-for="account in assetAccounts"
                                            :value="account.id"
                                            :key="account.id"
                                            >{{ account.account_no }}
                                            {{ account.account_name }}</option
                                        >
                                    </selectize>
                                    <transition name="slide-fade">
                                        <p
                                            class="text-danger"
                                            v-if="errors.pay_purchase"
                                        >
                                            {{ errors.pay_purchase }}
                                        </p>
                                    </transition>
                                </div>
                                <div
                                    class="form-group"
                                    style="border-bottom: 1px solid #eaeaea;padding-bottom: 15px;"
                                >
                                    <label for="example-text-input"
                                        >Liability Account for Tracking
                                        Payable</label
                                    >
                                    <selectize
                                        class="form-control"
                                        placeholder="Choose Account"
                                        v-model="coalink.tracking_payable"
                                        :class="{
                                            'is-invalid-selectize':
                                                errors.tracking_payable
                                        }"
                                    >
                                        <!-- settings is optional -->
                                        <option
                                            v-for="account in payableAccounts"
                                            :value="account.id"
                                            :key="account.id"
                                            >{{ account.account_no }}
                                            {{ account.account_name }}</option
                                        >
                                    </selectize>
                                    <transition name="slide-fade">
                                        <p
                                            class="text-danger"
                                            v-if="errors.tracking_payable"
                                        >
                                            {{ errors.tracking_payable }}
                                        </p>
                                    </transition>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Bookeeping</label
                                    >
                                </div>
                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Equity Account for Current
                                        Earning</label
                                    >
                                    <selectize
                                        class="form-control"
                                        placeholder="Choose Account"
                                        v-model="coalink.current_earning"
                                        :class="{
                                            'is-invalid-selectize':
                                                errors.current_earning
                                        }"
                                    >
                                        <!-- settings is optional -->
                                        <option
                                            v-for="account in equityAccounts"
                                            :value="account.id"
                                            :key="account.id"
                                            >{{ account.account_no }}
                                            {{ account.account_name }}</option
                                        >
                                    </selectize>
                                    <transition name="slide-fade">
                                        <p
                                            class="text-danger"
                                            v-if="errors.current_earning"
                                        >
                                            {{ errors.current_earning }}
                                        </p>
                                    </transition>
                                </div>
                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Equity Account for Retained
                                        Earning</label
                                    >
                                    <selectize
                                        class="form-control"
                                        placeholder="Choose Account"
                                        v-model="coalink.retained_earning"
                                        :class="{
                                            'is-invalid-selectize':
                                                errors.retained_earning
                                        }"
                                    >
                                        <!-- settings is optional -->
                                        <option
                                            v-for="account in equityAccounts"
                                            :value="account.id"
                                            :key="account.id"
                                            >{{ account.account_no }}
                                            {{ account.account_name }}</option
                                        >
                                    </selectize>
                                    <p
                                        class="text-danger"
                                        v-if="errors.retained_earning"
                                    >
                                        {{ errors.retained_earning }}
                                    </p>
                                </div>
                                <div
                                    class="form-group"
                                    style="border-bottom: 1px solid #eaeaea;padding-bottom: 15px;"
                                >
                                    <label for="example-text-input"
                                        >Equity Account for Historical
                                        Balancing</label
                                    >
                                    <selectize
                                        class="form-control"
                                        placeholder="Choose Account"
                                        v-model="coalink.historical_balancing"
                                        :class="{
                                            'is-invalid-selectize':
                                                errors.historical_balancing
                                        }"
                                    >
                                        <!-- settings is optional -->
                                        <option
                                            v-for="account in equityAccounts"
                                            :value="account.id"
                                            :key="account.id"
                                            >{{ account.account_no }}
                                            {{ account.account_name }}</option
                                        >
                                    </selectize>
                                    <transition name="slide-fade">
                                        <p
                                            class="text-danger"
                                            v-if="errors.historical_balancing"
                                        >
                                            {{ errors.historical_balancing }}
                                        </p>
                                    </transition>
                                </div>
                            </div>
                        </div>
                        <!--END Form-->
                        <!-- Submit -->
                        <div class="row items-push">
                            <div class="col-12 text-center">
                                <transition name="slide-fade">
                                    <p
                                        class="text-danger mt-2"
                                        v-if="errors.all"
                                    >
                                        {{ errors.all }}
                                    </p>
                                </transition>
                                <button
                                    type="submit"
                                    class="btn btn-sm btn-info"
                                    v-ripple="{ center: true }"
                                    @click.prevent="submit()"
                                >
                                    <i class="fa fa-link mr-1"></i> Set Link
                                    Account
                                </button>
                                <p class="font-size-sm text-muted mt-2 mb-2">
                                    Linked Account will affect information on
                                    Financial Report
                                </p>
                                <!-- <router-link
                                    type="button"
                                    class="btn btn-sm btn-alt-light"
                                    tag="button"
                                    v-ripple="{ center: true }"
                                    :to="{name: 'coa'}"
                                >
                                    <i class="fa fa-fw fa-times mr-1"></i> Close
                                </router-link> -->
                            </div>
                        </div>
                        <!-- END Submit -->
                    </form>
                </div>
            </div>
            <!-- END Basic -->
        </div>
        <!-- END Page Content -->
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

export default {
    name: "create-coa",

    data() {
        return {
            loadingPage: 1
        };
    },
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState("coa", {
            coas: state => state.coas.accounts
        }),
        ...mapState("coa", {
            coalink: state => state.coalink
        }),
        equityAccounts: function() {
            if (this.coas) {
                return this.coas.filter(function(coa) {
                    return coa.account_type === "Equity";
                });
            }
        },
        assetAccounts: function() {
            if (this.coas) {
                return this.coas.filter(function(coa) {
                    return coa.account_type === "Bank";
                });
            }
        },
        payableAccounts: function() {
            if (this.coas) {
                return this.coas.filter(function(coa) {
                    return (
                        coa.account_type === "Account Payable" ||
                        coa.account_type === "Other Current Liability" ||
                        coa.account_type === "Other Liability"
                    );
                });
            }
        },
        receivableAccounts: function() {
            if (this.coas) {
                return this.coas.filter(function(coa) {
                    return (
                        coa.account_type === "Account Receivable" ||
                        coa.account_type === "Bank" ||
                        coa.account_type === "Other Current Asset"
                    );
                });
            }
        }
    },
    created() {
        this.getCoaAll()
            .then(response => {
                this.getCoaLink()
                    .then(response => {
                        this.loadingPage = 0;
                    })
                    .catch(error => {
                        console.log(error);
                        this.loadingPage = 0;
                    });
            })
            .catch(error => {
                console.log(error);
                this.loadingPage = 0;
            });
    },
    methods: {
        //VUEX
        ...mapMutations("coa", ["CLEAR_FORM_COALINK"]),
        ...mapMutations(["CLEAR_ERRORS"]),
        ...mapActions("coa", ["setCoaLink", "getCoaAll", "getCoaLink"]),

        //METHOD
        submit() {
            this.CLEAR_ERRORS();
            this.loadingPage = 2;
            this.setCoaLink()
                .then(() => {
                    this.alert("Successfully Update Accounts Link ", 1);
                    this.loadingPage = 0;
                })
                .catch(error => {
                    console.log(error);
                    this.loadingPage = 0;
                });
        }
    },
    destroyed() {
        //FORM DI BERSIHKAN
        this.CLEAR_FORM_COALINK();
    }
};
</script>
