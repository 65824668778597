<template>
    <main>
        <hero title="Asset Acquisition" prevBreadcrumb="Asset Data" routeBreadcrumb="asset" currentBreadcrumb="Asset Acquisition"></hero>

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <div class="block" v-if="loadingPage == 1" style="height: 250px">
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <div class="block" v-if="loadingPage == 2" style="height: 250px">
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Save Data</span
                        >
                    </center>
                </div>
            </div>
            <!-- Basic -->
            <div class="block" v-if="loadingPage == 0">
                <div class="block-content block-content-full">
                    <form
                        method="POST"
                        enctype="multipart/form-data"
                        onsubmit="return false;"
                    >
                        <!--Form-->
                        <div class="row font-size-sm">
                            <div class="col-12 py-0">
                                <div class="row">
                                    <div class="col-12 col-lg-6 pt-0">
                                        <label style="opacity: 0.7"
                                            >Asset Data</label
                                        >
                                        <hr class="mt-2 mb-3" />
                                        <div class="form-group">
                                            <label for="example-text-input"
                                                >Asset Category *</label
                                            >
                                            <selectize
                                                class="form-control"
                                                placeholder="Choose Category"
                                                v-model="
                                                    asset.asset_category_id
                                                "
                                                :class="{
                                                    'is-invalid-selectize':
                                                        errors.asset_category_id,
                                                }"
                                            >
                                                <!-- settings is optional -->
                                                <option
                                                    v-for="category in categories"
                                                    :value="category.id"
                                                    :key="category.id"
                                                >
                                                    {{ category.category_name }}
                                                </option>
                                            </selectize>
                                            <transition name="slide-fade">
                                                <p
                                                    class="text-danger"
                                                    v-if="
                                                        errors.asset_category_id
                                                    "
                                                >
                                                    {{
                                                        errors
                                                            .asset_category_id[0]
                                                    }}
                                                </p>
                                            </transition>
                                        </div>
                                        <div class="form-group">
                                            <label for="example-text-input"
                                                >Asset Name *</label
                                            >
                                            <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Asset Name"
                                                v-model="asset.asset_name"
                                                :class="{
                                                    'is-invalid':
                                                        errors.bill_journal_memo,
                                                }"
                                            />
                                            <transition name="slide-fade">
                                                <p
                                                    class="text-danger"
                                                    v-if="
                                                        errors.bill_journal_memo
                                                    "
                                                >
                                                    {{
                                                        errors
                                                            .bill_journal_memo[0]
                                                    }}
                                                </p>
                                            </transition>
                                        </div>
                                        <transition name="slide-fade">
                                            <div class="form-group">
                                                <label for="example-text-input"
                                                    >Asset Code *</label
                                                >
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="Asset Code"
                                                    v-model="asset.asset_code"
                                                    :class="{
                                                        'is-invalid':
                                                            errors.bill_journal_memo,
                                                    }"
                                                />
                                                <transition name="slide-fade">
                                                    <p
                                                        class="text-danger"
                                                        v-if="
                                                            errors.bill_journal_memo
                                                        "
                                                    >
                                                        {{
                                                            errors
                                                                .bill_journal_memo[0]
                                                        }}
                                                    </p>
                                                </transition>
                                            </div>
                                        </transition>
                                    </div>
                                    <div class="col-12 col-lg-6 pt-0">
                                        <label style="opacity: 0.7"
                                            >Acquisition Detail *</label
                                        >
                                        <hr class="mt-2 mb-3" />

                                        <div class="row">
                                            <div
                                                class="form-group col-12 pb-1 pt-0"
                                            >
                                                <label for="example-text-input"
                                                    >Acquisition Date</label
                                                >
                                                <date-picker
                                                    class="input-group"
                                                    v-model="
                                                        asset.asset_acquisition_date
                                                    "
                                                    format="DD/MM/YYYY"
                                                    valueType="YYYY-MM-DD"
                                                    placeholder="Acquisition Date"
                                                    :class="{
                                                        'is-invalid':
                                                            errors.bill_date,
                                                    }"
                                                    :default-value="new Date()"
                                                    :disabled-date="
                                                        disabledDatePicker
                                                    "
                                                ></date-picker>
                                                <transition name="slide-fade">
                                                    <p
                                                        class="text-danger"
                                                        v-if="errors.bill_date"
                                                    >
                                                        {{
                                                            errors.bill_date[0]
                                                        }}
                                                    </p>
                                                </transition>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div
                                                class="form-group col-6 pb-1 pt-0"
                                            >
                                                <label for="example-text-input"
                                                    >Acquired Value *</label
                                                >
                                                <money
                                                    class="form-control"
                                                    style="text-align: right"
                                                    spellcheck="false"
                                                    v-model="
                                                        asset.asset_acquisition_value
                                                    "
                                                ></money>
                                                <transition name="slide-fade">
                                                    <p
                                                        class="text-danger"
                                                        v-if="
                                                            this.asset.asset_acquisition_value < 0                                                        "
                                                    >
                                                        Residual Value cannot be negative
                                                    </p>
                                                </transition>
                                            </div>
                                            <div
                                                class="form-group col-6 pb-1 pt-0"
                                            >
                                                <label for="example-text-input"
                                                    >Residual Value *</label
                                                >
                                                <money
                                                    class="form-control"
                                                    style="text-align: right"
                                                    spellcheck="false"
                                                    v-model="
                                                        asset.asset_residual_value
                                                    "
                                                ></money>
                                                <transition name="slide-fade">
                                                    <p
                                                        class="text-danger"
                                                        v-if="
                                                            this.asset.asset_acquisition_value > 0 && this.asset.asset_residual_value > this.asset.asset_acquisition_value
                                                        "
                                                    >
                                                        Residual Value cannot be greater than Acquisition Value
                                                    </p>
                                                </transition>
                                                <transition name="slide-fade">
                                                    <p
                                                        class="text-danger"
                                                        v-if="
                                                            this.asset.asset_residual_value < 0                                                        "
                                                    >
                                                        Residual Value cannot be negative
                                                    </p>
                                                </transition>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div
                                                class="form-group col-12 pb-1 pt-3 text-right"
                                            >
                                                <a
                                                    class="btn-icon-only icon-succes hover-hr-table pr-2"
                                                    @click="addDetail()"
                                                    v-if="disableSave == false"
                                                >
                                                    <i class="fa fa-plus"></i>
                                                    Add to List
                                                </a>
                                                <a
                                                    class="btn-icon pr-2"
                                                    style="opacity: 0.5"
                                                    v-else
                                                >
                                                    <i class="fa fa-plus"></i>
                                                    Add to List
                                                </a>
                                                <a
                                                    class="btn-icon-only btn-icon-danger hover-hr-table"
                                                    @click="clearData()"
                                                >
                                                    <i class="fa fa-times"></i>
                                                    Clear Data
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <hr class="pb-1 pt-0 mt-1" />

                                <div class="form-group">
                                    <div class="table-responsive">
                                        <table
                                            class="table table-vcenter table-border mb-0"
                                        >
                                            <thead
                                                style="
                                                    background-color: whitesmoke;
                                                "
                                            >
                                                <tr>
                                                    <th
                                                        class="table-header-relative"
                                                    >
                                                        No.
                                                    </th>
                                                    <th
                                                        class="table-header-relative column-minwidth-150"
                                                    >
                                                        Category
                                                    </th>
                                                    <th
                                                        class="table-header-relative column-minwidth-150"
                                                    >
                                                        Name
                                                    </th>
                                                    <th
                                                        class="table-header-relative column-minwidth-150"
                                                    >
                                                        Code
                                                    </th>
                                                    <th
                                                        class="table-header-relative column-minwidth-150"
                                                    >
                                                        Acquisition Date
                                                    </th>
                                                    <th
                                                        class="table-header-relative column-minwidth-150 text-right"
                                                    >
                                                        Acquired Value
                                                    </th>
                                                    <th
                                                        class="table-header-relative column-minwidth-150 text-right"
                                                    >
                                                        Residual Value
                                                    </th>
                                                    <th
                                                        class="table-header-relative"
                                                    ></th>
                                                </tr>
                                            </thead>
                                            <tbody
                                                v-if="
                                                    this.assetData.length > 0 &&
                                                    loadingPage == 0
                                                "
                                            >
                                                <tr
                                                    v-for="(
                                                        data, index
                                                    ) in assetData"
                                                    :key="index"
                                                >
                                                    <td
                                                        class="text-center font-size-sm py-3"
                                                    >
                                                        {{ index + 1 }}
                                                    </td>
                                                    <td
                                                        class="text-center font-size-sm py-3"
                                                    >
                                                        {{
                                                            data.asset_category
                                                        }}
                                                    </td>
                                                    <td
                                                        class="text-center font-size-sm py-3"
                                                    >
                                                        {{ data.asset_name }}
                                                    </td>
                                                    <td
                                                        class="font-size-sm py-3 text-center"
                                                    >
                                                        {{ data.asset_code }}
                                                    </td>
                                                    <td
                                                        class="font-size-sm py-3 text-center"
                                                    >
                                                        {{
                                                            data.asset_acquisition_date
                                                                | moment(
                                                                    "DD/MM/YYYY "
                                                                )
                                                        }}
                                                    </td>
                                                    <td
                                                        class="font-size-sm py-3 text-right"
                                                    >
                                                        {{
                                                            data.asset_acquisition_value
                                                                | currency
                                                        }}
                                                    </td>
                                                    <td
                                                        class="font-size-sm py-3 text-right"
                                                    >
                                                        {{
                                                            data.asset_residual_value
                                                                | currency
                                                        }}
                                                    </td>
                                                    <td
                                                        class="font-size-sm"
                                                        align="center"
                                                    >
                                                        <a
                                                            class="btn-icon-only btn-icon-danger"
                                                            @click="
                                                                removeDetail(
                                                                    index
                                                                )
                                                            "
                                                        >
                                                            <i
                                                                class="fa fa-trash"
                                                            ></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody
                                                v-if="
                                                    this.assetData.length <=
                                                        0 && loadingPage == 0
                                                "
                                            >
                                                <tr class="data-empty">
                                                    <td colspan="8">
                                                        <center
                                                            style="
                                                                padding-top: 8px;
                                                                padding-bottom: 8px;
                                                                color: #cccccc;
                                                            "
                                                        >
                                                            Asset List is Empty
                                                        </center>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <p
                                            class="text-muted font-size-sm d-inline d-sm-none"
                                            style="opacity: 0.5"
                                        >
                                            Swipe left here to see full column
                                            >>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--END Form-->
                        <!-- Submit -->
                        <div class="row items-push">
                            <div class="col-3 pt-3 offset-9">
                                <label for="field-1" class="control-label">
                                    Total Asset Value :
                                </label>
                                <money
                                    class="form-control"
                                    style="text-align: right"
                                    :value="totalAssetVal"
                                    spellcheck="false"
                                    disabled
                                ></money>
                            </div>
                            <div class="col-12 text-center mb-0 pb-0">
                                <p class="m-0 mb-1 text-muted">
                                    Fill all the field of Asset Data &
                                    Acquisition Detail, then click "Add to List" to
                                    add data to the list <br />
                                    that will be saved below, this form will
                                    only store data in the list
                                </p>
                            </div>
                            <div class="col-12 text-center">
                                <button
                                    type="submit"
                                    class="btn btn-sm btn-success"
                                    v-ripple="{ center: true }"
                                    @click.prevent="submit"
                                >
                                    <i class="fa fa-fw fa-check mr-1"></i> Save
                                </button>
                                <router-link
                                    type="button"
                                    class="btn btn-sm btn-alt-light"
                                    tag="button"
                                    v-ripple="{ center: true }"
                                    :to="{ name: 'asset' }"
                                >
                                    <i class="fa fa-fw fa-times mr-1"></i> Close
                                </router-link>
                            </div>
                        </div>
                        <!-- END Submit -->
                    </form>
                </div>
            </div>
            <!-- END Basic -->
        </div>
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import moment from "moment";

export default {
    name: "asset-create",
    data() {
        return {
            loadingPage: 0,
            disabled: {},
            asset: {
                asset_name: "",
                asset_category: "",
                asset_category_id: "",
                asseet_code: "",
                asset_acquisition_date: "",
                asset_acquisition_value: 0,
                asset_residual_value: 0,
            },
        };
    },
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState("asset", { assetData: (state) => state.asset.assetData }),
        ...mapState("assetcategory", {
            categories: (state) => state.categories,
        }),
        ...mapState("coa", { coas: (state) => state.coas.accounts }),
        ...mapState("forbiddendate", {
            forbiddenDateLast: (state) => state.forbiddenDateLast,
        }),
        disableSave() {
            if (this.asset.asset_name === "") {
                return true;
            } else if (this.asset.asset_category_id === "") {
                return true;
            } else if (this.asset.accet_code === "") {
                return true;
            } else if (this.asset.asset_acquisition_date === "") {
                return true;
            } else if (this.asset.asset_acquisition_date == null) {
                return true;
            } else if (this.asset.asset_acquisition_value == 0) {
                return true;
            } else if (this.asset.asset_residual_value > this.asset.asset_acquisition_value) {
                return true;
            } else if (this.asset.asset_residual_value < 0) {
                return true;
            } else if (this.asset.asset_acquisition_value < 0) {
                return true;
            } else {
                return false;
            }
        },
        totalAssetVal () {
            var total = 0;
            if(this.assetData.length == 0 ){
                return total;
            } else {
                for (var i = 0, l = this.assetData.length; i<l; i++) {
                    total += this.assetData[i].asset_acquisition_value;
                }
                return total;
            }
        }
    },
    created() {
        this.getCoaAll();
        this.getCategoryAll();
        this.getForbiddenDate();
    },
    methods: {
        //VUEX
        ...mapMutations("asset", ["CLEAR_FORM"]),
        ...mapActions("coa", ["getCoaAll"]),
        ...mapMutations(["CLEAR_ERRORS"]),
        ...mapActions("asset", ["submitAsset"]),
        ...mapActions("assetcategory", ["getCategoryAll"]),
        ...mapActions("forbiddendate", ["getForbiddenDate"]),
        disabledDatePicker(date) {
            const lastDisableDate = new Date(
                moment(this.forbiddenDateLast).add(1, "days")
            );
            lastDisableDate.setHours(0, 0, 0, 0);
            return date < lastDisableDate;
        },
        addDetail() {
            var result = this.categories.find((obj) => {
                return obj.id === parseInt(this.asset.asset_category_id);
            });
            this.assetData.push({
                asset_name: this.asset.asset_name,
                asset_category_id: this.asset.asset_category_id,
                asset_category: result.category_name,
                asset_code: this.asset.asset_code,
                asset_acquisition_date: this.asset.asset_acquisition_date,
                asset_acquisition_value: this.asset.asset_acquisition_value,
                asset_residual_value: this.asset.asset_residual_value,
            });
        },
        clearData() {
            this.asset.asset_name = "";
            this.asset.asset_category = "";
            this.asset.asset_category_id = "";
            this.asset.asset_code = "";
            this.asset.asset_acquisition_date = "";
            this.asset.asset_acquisition_value = 0;
            this.asset.asset_residual_value = 0;
        },
        removeDetail(index) {
            this.assetData.splice(index, 1);
        },
        submit() {
            this.CLEAR_ERRORS();
            this.loadingPage = 2;
            this.submitAsset()
                .then((response) => {
                    console.log(response);
                    this.CLEAR_FORM();
                    this.clearData();
                    this.alert("Successfully create Asset Data ", 1);
                    this.loadingPage = 0;
                })
                .catch((error) => {
                    error.response.status == 500
                        ? this.alert(error.response.data.message, 2)
                        : "";
                    error.response.status != 422 && error.response.status != 500
                        ? this.alert(error.message, 2)
                        : "";
                    this.loadingPage = 0;
                });
        },
    },
    destroyed() {
        this.CLEAR_FORM();
    },
};
</script>
