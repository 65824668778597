var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('hero',{attrs:{"title":"Linked Account","prevBreadcrumb":"Dashboard","routeBreadcrumb":"home","currentBreadcrumb":"Linked Account"}}),_vm._v(" "),_c('div',{staticClass:"content"},[(_vm.loadingPage == 1)?_c('div',{staticClass:"block col-12 col-md-8 offset-md-2 block-rounded block-mode-loading-oneui",staticStyle:{"height":"250px"}},[_c('div',{staticClass:"block-content block-content-full pb-0",staticStyle:{"padding-top":"80px"}},[_c('grid-loader',{staticClass:"mx-auto",attrs:{"color":'#70B9EB',"size":'10px'}}),_vm._v(" "),_c('center',{staticClass:"mt-2"},[_c('span',{staticClass:"text-center",staticStyle:{"opacity":"0.7"}},[_vm._v("Loading")])])],1)]):_vm._e(),_vm._v(" "),(_vm.loadingPage == 2)?_c('div',{staticClass:"block col-12 col-md-8 offset-md-2 block-rounded block-mode-loading-oneui",staticStyle:{"height":"250px"}},[_c('div',{staticClass:"block-content block-content-full pb-0",staticStyle:{"padding-top":"80px"}},[_c('grid-loader',{staticClass:"mx-auto",attrs:{"color":'#70B9EB',"size":'10px'}}),_vm._v(" "),_c('center',{staticClass:"mt-2"},[_c('span',{staticClass:"text-center",staticStyle:{"opacity":"0.7"}},[_vm._v("Save Data")])])],1)]):_vm._e(),_vm._v(" "),(_vm.loadingPage == 0)?_c('div',{staticClass:"block col-12 col-md-8 offset-md-2 block-rounded block-mode-loading-oneui"},[_c('div',{staticClass:"block-content block-content-full"},[_c('form',{attrs:{"method":"POST","enctype":"multipart/form-data","onsubmit":"return false;"}},[_c('div',{staticClass:"row font-size-sm"},[_c('div',{staticClass:"col-12 col-md-6"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Bank Account for Contact Receipt")]),_vm._v(" "),_c('selectize',{staticClass:"form-control",class:{
                                        'is-invalid-selectize':
                                            _vm.errors.contact_receipt
                                    },attrs:{"placeholder":"Choose Account"},model:{value:(_vm.coalink.contact_receipt),callback:function ($$v) {_vm.$set(_vm.coalink, "contact_receipt", $$v)},expression:"coalink.contact_receipt"}},_vm._l((_vm.assetAccounts),function(account){return _c('option',{key:account.id,domProps:{"value":account.id}},[_vm._v(_vm._s(account.account_no)+"\n                                        "+_vm._s(account.account_name))])}),0),_vm._v(" "),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.errors.contact_receipt)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                        "+_vm._s(_vm.errors.contact_receipt)+"\n                                    ")]):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"form-group",staticStyle:{"border-bottom":"1px solid #eaeaea","padding-bottom":"15px"}},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Asset Account for Tracking\n                                    Receiveable")]),_vm._v(" "),_c('selectize',{staticClass:"form-control",class:{
                                        'is-invalid-selectize':
                                            _vm.errors.tracking_receiveable
                                    },attrs:{"placeholder":"Choose Account"},model:{value:(_vm.coalink.tracking_receiveable),callback:function ($$v) {_vm.$set(_vm.coalink, "tracking_receiveable", $$v)},expression:"coalink.tracking_receiveable"}},_vm._l((_vm.receivableAccounts),function(account){return _c('option',{key:account.id,domProps:{"value":account.id}},[_vm._v(_vm._s(account.account_no)+"\n                                        "+_vm._s(account.account_name))])}),0),_vm._v(" "),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.errors.tracking_receiveable)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                        "+_vm._s(_vm.errors.tracking_receiveable)+"\n                                    ")]):_vm._e()])],1),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Bank Account for Pay Purchase")]),_vm._v(" "),_c('selectize',{staticClass:"form-control",class:{
                                        'is-invalid-selectize':
                                            _vm.errors.pay_purchase
                                    },attrs:{"placeholder":"Choose Account"},model:{value:(_vm.coalink.pay_purchase),callback:function ($$v) {_vm.$set(_vm.coalink, "pay_purchase", $$v)},expression:"coalink.pay_purchase"}},_vm._l((_vm.assetAccounts),function(account){return _c('option',{key:account.id,domProps:{"value":account.id}},[_vm._v(_vm._s(account.account_no)+"\n                                        "+_vm._s(account.account_name))])}),0),_vm._v(" "),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.errors.pay_purchase)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                        "+_vm._s(_vm.errors.pay_purchase)+"\n                                    ")]):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"form-group",staticStyle:{"border-bottom":"1px solid #eaeaea","padding-bottom":"15px"}},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Liability Account for Tracking\n                                    Payable")]),_vm._v(" "),_c('selectize',{staticClass:"form-control",class:{
                                        'is-invalid-selectize':
                                            _vm.errors.tracking_payable
                                    },attrs:{"placeholder":"Choose Account"},model:{value:(_vm.coalink.tracking_payable),callback:function ($$v) {_vm.$set(_vm.coalink, "tracking_payable", $$v)},expression:"coalink.tracking_payable"}},_vm._l((_vm.payableAccounts),function(account){return _c('option',{key:account.id,domProps:{"value":account.id}},[_vm._v(_vm._s(account.account_no)+"\n                                        "+_vm._s(account.account_name))])}),0),_vm._v(" "),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.errors.tracking_payable)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                        "+_vm._s(_vm.errors.tracking_payable)+"\n                                    ")]):_vm._e()])],1)]),_vm._v(" "),_c('div',{staticClass:"col-12 col-md-6"},[_vm._m(2),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Equity Account for Current\n                                    Earning")]),_vm._v(" "),_c('selectize',{staticClass:"form-control",class:{
                                        'is-invalid-selectize':
                                            _vm.errors.current_earning
                                    },attrs:{"placeholder":"Choose Account"},model:{value:(_vm.coalink.current_earning),callback:function ($$v) {_vm.$set(_vm.coalink, "current_earning", $$v)},expression:"coalink.current_earning"}},_vm._l((_vm.equityAccounts),function(account){return _c('option',{key:account.id,domProps:{"value":account.id}},[_vm._v(_vm._s(account.account_no)+"\n                                        "+_vm._s(account.account_name))])}),0),_vm._v(" "),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.errors.current_earning)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                        "+_vm._s(_vm.errors.current_earning)+"\n                                    ")]):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Equity Account for Retained\n                                    Earning")]),_vm._v(" "),_c('selectize',{staticClass:"form-control",class:{
                                        'is-invalid-selectize':
                                            _vm.errors.retained_earning
                                    },attrs:{"placeholder":"Choose Account"},model:{value:(_vm.coalink.retained_earning),callback:function ($$v) {_vm.$set(_vm.coalink, "retained_earning", $$v)},expression:"coalink.retained_earning"}},_vm._l((_vm.equityAccounts),function(account){return _c('option',{key:account.id,domProps:{"value":account.id}},[_vm._v(_vm._s(account.account_no)+"\n                                        "+_vm._s(account.account_name))])}),0),_vm._v(" "),(_vm.errors.retained_earning)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                    "+_vm._s(_vm.errors.retained_earning)+"\n                                ")]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"form-group",staticStyle:{"border-bottom":"1px solid #eaeaea","padding-bottom":"15px"}},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Equity Account for Historical\n                                    Balancing")]),_vm._v(" "),_c('selectize',{staticClass:"form-control",class:{
                                        'is-invalid-selectize':
                                            _vm.errors.historical_balancing
                                    },attrs:{"placeholder":"Choose Account"},model:{value:(_vm.coalink.historical_balancing),callback:function ($$v) {_vm.$set(_vm.coalink, "historical_balancing", $$v)},expression:"coalink.historical_balancing"}},_vm._l((_vm.equityAccounts),function(account){return _c('option',{key:account.id,domProps:{"value":account.id}},[_vm._v(_vm._s(account.account_no)+"\n                                        "+_vm._s(account.account_name))])}),0),_vm._v(" "),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.errors.historical_balancing)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                        "+_vm._s(_vm.errors.historical_balancing)+"\n                                    ")]):_vm._e()])],1)])]),_vm._v(" "),_c('div',{staticClass:"row items-push"},[_c('div',{staticClass:"col-12 text-center"},[_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.errors.all)?_c('p',{staticClass:"text-danger mt-2"},[_vm._v("\n                                    "+_vm._s(_vm.errors.all)+"\n                                ")]):_vm._e()]),_vm._v(" "),_c('button',{directives:[{name:"ripple",rawName:"v-ripple",value:({ center: true }),expression:"{ center: true }"}],staticClass:"btn btn-sm btn-info",attrs:{"type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.submit()}}},[_c('i',{staticClass:"fa fa-link mr-1"}),_vm._v(" Set Link\n                                Account\n                            ")]),_vm._v(" "),_c('p',{staticClass:"font-size-sm text-muted mt-2 mb-2"},[_vm._v("\n                                Linked Account will affect information on\n                                Financial Report\n                            ")])],1)])])])]):_vm._e()])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Bill")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Purchase")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Bookeeping")])])}]

export { render, staticRenderFns }