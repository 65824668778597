<template>
    <main>
        <hero title="Detail Income" prevBreadcrumb="Incomes" routeBreadcrumb="income" currentBreadcrumb="Detail Income"></hero>

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <!-- Invoice -->
            <div class="block block-rounded block-mode-loading-oneui mb-2">
                <div class="block-header">
                    <h3 class="block-title">
                        Income {{ income.bill_number }}
                    </h3>
                    <div class="block-options">
                        <router-link
                            :to="{
                                name: 'journal-transaction-voucher',
                                params: { id: income.journal.id },
                            }"
                            tag="button"
                            type="button"
                            class="btn-block-option text-warning"
                        >
                            <i class="si si-notebook mr-1"></i>
                            <span class="d-none d-md-inline-block">
                                Journal Voucher
                            </span>
                        </router-link>
                        <router-link
                            :to="{
                                name: 'income-edit',
                                params: { id: income.bills_id }
                            }"
                            tag="button"
                            type="button"
                            class="btn-block-option text-success"
                        >
                            <i class="si si-pencil mr-1"></i>
                            <span class="d-none d-md-inline-block">
                                Edit Data
                            </span>
                        </router-link>
                        <!-- Print Page functionality is initialized in Helpers.print() -->
                        <button
                            type="button"
                            class="btn-block-option text-info"
                            @click="print()"
                        >
                            <i class="si si-printer mr-1"></i>
                            <span class="d-none d-md-inline-block"
                                >Print Document</span
                            >
                        </button>
                    </div>
                </div>
            </div>
            <div class="block bg-transparent mb-2">
                <div class="block-content pt-0 bg-transparent text-right">
                    <input
                        class="form-check-input"
                        style="margin-top:3.5px"
                        type="checkbox"
                        v-model="useHeader"
                    />
                    <label class="form-check-label">Default Header</label>
                </div>
            </div>
            <!--Loading-->
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 1"
                style="height:250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity:0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <!-- Basic -->
            <div class="block block-rounded block-mode-loading-oneui" v-else>
                <div class="content content-boxed" id="printMe">
                    <div class="block-content">
                        <div class="p-0 p-md-5" style="min-height:800px">
                            <div class="row pt-0" v-if="useHeader">
                                <div class="col-6 font-size-sm pb-0 pt-0">
                                    <p class="h3 mb-2 mt-3 text-uppercase">
                                        {{
                                            auth.data.workspace
                                                .workspace_company_name
                                        }}
                                    </p>
                                    <p class="mb-0">
                                        {{
                                            auth.data.workspace
                                                .workspace_company_address
                                        }}
                                    </p>
                                    <p class="mb-0">
                                        <span
                                            v-if="
                                                auth.data.workspace
                                                    .workspace_company_province !==
                                                    '' &&
                                                    auth.data.workspace
                                                        .workspace_company_province !==
                                                        null
                                            "
                                        >
                                            {{
                                                auth.data.workspace
                                                    .workspace_company_province
                                            }}
                                        </span>
                                        <span
                                            v-if="
                                                auth.data.workspace
                                                    .workspace_company_postal !==
                                                    '' &&
                                                    auth.data.workspace
                                                        .workspace_company_postal !==
                                                        null
                                            "
                                        >
                                            ,
                                            {{
                                                auth.data.workspace
                                                    .workspace_company_postal
                                            }}
                                        </span>
                                    </p>
                                    <p class="mb-0">
                                        <span
                                            v-if="
                                                auth.data.workspace
                                                    .workspace_company_phone !==
                                                    '' &&
                                                    auth.data.workspace
                                                        .workspace_company_phone !==
                                                        null
                                            "
                                        >
                                            {{
                                                auth.data.workspace
                                                    .workspace_company_phone
                                            }}
                                        </span>
                                        <span
                                            v-if="
                                                auth.data.workspace
                                                    .workspace_company_email !==
                                                    '' &&
                                                    auth.data.workspace
                                                        .workspace_company_email !==
                                                        null
                                            "
                                        >
                                            {{
                                                "| " +
                                                    auth.data.workspace
                                                        .workspace_company_email
                                            }}
                                        </span>
                                    </p>
                                </div>
                                <div
                                    class="col-6 text-right font-size-sm pb-0 pt-0"
                                >
                                    <img
                                        :src="
                                            '/storage/workspace/' +
                                                auth.data.workspace
                                                    .workspace_company_logo
                                        "
                                        alt=""
                                        style="min-heigh:100px; max-height:120px; max-width:180px;"
                                        @error="errorLogoUrl"
                                    />
                                </div>
                            </div>
                            <hr class="mb-3" v-if="useHeader"/>
                            <div class="row mt-3">
                                <p
                                    class="h2 mx-auto text-uppercase text-center"
                                >
                                    Income
                                </p>
                            </div>
                            <div class="row mb-5">
                                <div class="col-6 text-left font-size-sm">
                                    <p class="mb-1">Income From :</p>
                                    <p class="font-w700 mb-0 text-uppercase">
                                        {{ income.contact.contact_name }}
                                    </p>
                                    <p class="mb-0">
                                        {{ income.contact.contact_address }},
                                    </p>
                                    <p class="mb-0">
                                        <span
                                            v-if="
                                                income.contact
                                                    .contact_province !== '' &&
                                                    income.contact
                                                        .contact_province !==
                                                        null
                                            "
                                        >
                                            {{
                                                income.contact.contact_province
                                            }}
                                        </span>
                                        <span
                                            v-if="
                                                income.contact
                                                    .contact_postalcode !==
                                                    '' &&
                                                    income.contact
                                                        .contact_postalcode !==
                                                        null
                                            "
                                        >
                                            ,
                                            {{
                                                income.contact
                                                    .contact_postalcode
                                            }}
                                        </span>
                                    </p>
                                    <p class="mb-0">
                                        <span
                                            v-if="
                                                income.contact
                                                    .contact_contactphone !==
                                                    '' &&
                                                    income.contact
                                                        .contact_contactphone !==
                                                        null
                                            "
                                        >
                                            {{
                                                income.contact
                                                    .contact_contactphone
                                            }}
                                        </span>
                                        <span
                                            v-if="
                                                income.contact.contact_email !==
                                                    '' &&
                                                    income.contact
                                                        .contact_email !==
                                                        null &&
                                                    income.contact
                                                        .contact_email !== '-'
                                            "
                                        >
                                            {{
                                                "| " +
                                                    income.contact.contact_email
                                            }}
                                        </span>
                                    </p>
                                </div>
                                <div class="col-6 font-size-sm text-right">
                                    <p class="mb-0 font-w700 mt-3">
                                        No. Income : {{ income.bill_number }}
                                    </p>
                                    <p class="mb-0">
                                        Date :
                                        {{
                                            income.bill_date
                                                | moment("DD MMMM YYYY")
                                        }}
                                    </p>
                                </div>
                            </div>

                            <!-- Table -->
                            <div class="table-responsive push">
                                <table class="table table-bordered">
                                    <thead class="bg-body-light">
                                        <tr>
                                            <th
                                                class="text-center"
                                                style="width: 60px;"
                                            >
                                                No.
                                            </th>
                                            <th class="text-center">
                                                Description
                                            </th>
                                            <th
                                                class="text-center"
                                                style="width: 20%"
                                            >
                                                Amount
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class="font-print-md">
                                        <tr
                                            v-for="(transaction,
                                            index) in income.bill_transaction"
                                            :key="index"
                                        >
                                            <td class="text-center">
                                                {{ ++index }}
                                            </td>
                                            <td>
                                                <p
                                                    class="font-w600 mb-1 font-size-print"
                                                >
                                                    {{
                                                        transaction.transaction_description
                                                    }}
                                                </p>
                                            </td>
                                            <td class="text-right">
                                                {{
                                                    transaction.transaction_amount
                                                        | currency
                                                }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                colspan="2"
                                                class="font-w600 text-right"
                                            >
                                                Sub Total
                                            </td>
                                            <td class="text-right">
                                                {{
                                                    income.bill_sub_total
                                                        | currency
                                                }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                colspan="2"
                                                class="font-w600 text-right"
                                            >
                                                Tax Total
                                            </td>
                                            <td class="text-right">
                                                {{
                                                    income.bill_tax_total
                                                        | currency
                                                }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                colspan="2"
                                                class="font-w700 text-right bg-body-light"
                                            >
                                                TOTAL
                                            </td>
                                            <td
                                                class="font-w700 text-right bg-body-light"
                                            >
                                                {{
                                                    income.bill_grand_total
                                                        | currency
                                                }}
                                            </td>
                                        </tr>
                                        <!-- <tr>
                                                <td colspan="2" class="font-w600 text-right">Paid Amount</td>
                                                <td class="text-right">{{income.bill_paid_total|currency}}</td>
                                            </tr>
                                            <tr>
                                            <td colspan="2" class="font-w600 text-right">Amount Due</td>
                                                <td class="font-w600 text-right">{{income.bill_grand_total - income.bill_paid_total | currency}}</td>
                                            </tr> -->
                                    </tbody>
                                </table>
                            </div>
                            <!-- END Table -->

                            <div class="row mt-5 mb-5">
                                <!-- Bank & Post Note -->
                                <div
                                    class="col-12 col-md-6 text-left font-size-sm pt-5 mt-2"
                                >
                                    <!-- Bank -->
                                    <div class="form-group d-print-none">
                                        <label for="example-text-input"
                                            >Choose Bank Data *</label
                                        >
                                        <selectize
                                            class="form-control"
                                            placeholder="Choose Bank Name"
                                            v-model="selectedBank"
                                            @input="selectBank(selectedBank)"
                                        >
                                            <option
                                                v-for="bank in banks.data"
                                                :value="bank.id"
                                                :key="bank.id"
                                                >{{ bank.bank_name }}</option
                                            >
                                        </selectize>
                                    </div>
                                    <p
                                        class="mb-1"
                                        v-bind:class="{
                                            'd-print-none': selectedBank == ''
                                        }"
                                    >
                                        Income For :
                                    </p>
                                    <p
                                        class="font-w700 mb-0 text-uppercase"
                                        v-bind:class="{
                                            'd-print-none': selectedBank == ''
                                        }"
                                    >
                                        {{ docBank.bank_name }} ({{
                                            docBank.bank_cabang
                                        }})
                                    </p>
                                    <p
                                        class="mb-0"
                                        v-bind:class="{
                                            'd-print-none': selectedBank == ''
                                        }"
                                    >
                                        {{ docBank.bank_rekening }}
                                    </p>
                                    <p
                                        class="mb-0"
                                        v-bind:class="{
                                            'd-print-none': selectedBank == ''
                                        }"
                                    >
                                        an. {{ docBank.bank_rekening_name }}
                                    </p>
                                    <!-- Post Note -->
                                    <div class="form-group d-print-none mt-5">
                                        <label for="example-text-input"
                                            >Post Note *</label
                                        >
                                        <ckeditor
                                            tag-name="textarea"
                                            :editor="editor"
                                            v-model="docPostNote"
                                            :config="editorConfig"
                                        >
                                            <p
                                                class="mb-0"
                                                v-bind:class="{
                                                    'd-print-none':
                                                        selectedBank == ''
                                                }"
                                            >
                                                an.
                                                {{ docBank.bank_rekening_name }}
                                            </p>
                                        </ckeditor>
                                    </div>
                                    <span
                                        class="d-none d-print-inline-block mt-1"
                                        v-html="docPostNote"
                                    ></span>
                                </div>
                                <!-- Signature 2 -->
                                <div
                                    class="col-12 col-md-3 font-size-sm text-right pt-5 mt-2"
                                >
                                    <div
                                        class="form-group text-left d-print-none"
                                    >
                                        <label for="example-text-input"
                                            >Choose Signature 2*</label
                                        >
                                        <input
                                            type="text"
                                            class="form-control mb-1"
                                            placeholder="Text Signature"
                                            v-model="
                                                docSignature2.signature_text
                                            "
                                        />
                                        <selectize
                                            class="form-control"
                                            placeholder="Choose Signature"
                                            v-model="selectedSignature2"
                                            @input="
                                                selectSignature2(
                                                    selectedSignature2
                                                )
                                            "
                                        >
                                            <option
                                                v-for="signature in signatures.data"
                                                :value="signature.id"
                                                :key="signature.id"
                                                >{{
                                                    signature.signature_name
                                                }}</option
                                            >
                                        </selectize>
                                    </div>
                                    <p
                                        class="mb-0 font-w700 text-center mb-2"
                                        v-bind:class="{
                                            'd-print-none':
                                                docSignature2.signature_text ==
                                                ''
                                        }"
                                    >
                                        {{ docSignature2.signature_text }}
                                    </p>
                                    <p
                                        class="mb-0 font-w700 mt-6 ml-2 text-center"
                                        style="border-bottom:1px solid grey"
                                        v-bind:class="{
                                            'd-print-none':
                                                selectedSignature2 == ''
                                        }"
                                    >
                                        {{ docSignature2.signature_name }}
                                    </p>
                                    <p
                                        class="mb-0 font-w700 ml-2 text-center"
                                        v-bind:class="{
                                            'd-print-none':
                                                selectedSignature2 == ''
                                        }"
                                    >
                                        {{ docSignature2.signature_position }}
                                    </p>
                                </div>
                                <!-- Signature 1 -->
                                <div
                                    class="col-12 col-md-3 font-size-sm text-right pt-5 mt-2"
                                >
                                    <div
                                        class="form-group text-left d-print-none"
                                    >
                                        <label for="example-text-input"
                                            >Choose Signature *</label
                                        >
                                        <input
                                            type="text"
                                            class="form-control mb-1"
                                            placeholder="Text Signature"
                                            v-model="
                                                docSignature.signature_text
                                            "
                                        />
                                        <selectize
                                            class="form-control"
                                            placeholder="Choose Signature"
                                            v-model="selectedSignature"
                                            @input="
                                                selectSignature(
                                                    selectedSignature
                                                )
                                            "
                                        >
                                            <option
                                                v-for="signature in signatures.data"
                                                :value="signature.id"
                                                :key="signature.id"
                                                >{{
                                                    signature.signature_name
                                                }}</option
                                            >
                                        </selectize>
                                    </div>
                                    <p
                                        class="mb-0 font-w700 text-center mb-2"
                                        v-bind:class="{
                                            'd-print-none':
                                                docSignature.signature_text ==
                                                ''
                                        }"
                                    >
                                        {{ docSignature.signature_text }}
                                    </p>
                                    <p
                                        class="mb-0 font-w700 mt-6 ml-2 text-center"
                                        style="border-bottom:1px solid grey"
                                        v-bind:class="{
                                            'd-print-none':
                                                selectedSignature == ''
                                        }"
                                    >
                                        {{ docSignature.signature_name }}
                                    </p>
                                    <p
                                        class="mb-0 font-w700 ml-2 text-center"
                                        v-bind:class="{
                                            'd-print-none':
                                                selectedSignature == ''
                                        }"
                                    >
                                        {{ docSignature.signature_position }}
                                    </p>
                                </div>
                            </div>

                            <hr />
                            <p
                                class="d-none d-print-inline-block"
                                style="opacity:0.6;"
                            >
                                <i> Printed : {{ dateNow }} </i>
                            </p>
                            <!-- END Footer -->
                        </div>
                    </div>
                </div>
                <!-- END Invoice -->
            </div>
            <!-- END Basic -->
        </div>
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import moment from "moment";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
    name: "detail-income",
    data() {
        return {
            loadingPage: 1,
            items: [],
            disabled: {},
            coaBills: [],
            useHeader: true,
            forbiddenData: false,
            dateNow: moment().format("MMMM Do YYYY, HH:mm:ss"),
            selectedSignature: "",
            docSignature: {
                signature_name: "[select signature]",
                signature_position: "[select signature]",
                signature_text: ""
            },
            selectedSignature2: "",
            docSignature2: {
                signature_name: "[select signature]",
                signature_position: "[select signature]",
                signature_text: ""
            },
            selectedBank: "",
            docBank: {
                bank_name: "[select bank]",
                bank_cabang: "[select bank]",
                bank_rekening: "[select bank]",
                bank_rekening_name: "[select bank]"
            },
            editor: ClassicEditor,
            editorConfig: {
                toolbar: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "bulletedList",
                    "numberedList",
                    "blockQuote",
                    "undo",
                    "redo",
                    "insertTable"
                ]
            },
            docPostNote: ""
        };
    },
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState(["auth"]),
        ...mapState("income", { income: state => state.income }),
        ...mapState("signature", { signatures: state => state.signatures }),
        ...mapState("bank", { banks: state => state.banks })
    },
    created() {
        if (this.$route.params.id) {
            this.editIncome(this.$route.params.id)
                .then(response => {
                    this.$session.set("income_detail", response.data.id);
                    this.loadingPage = 0;
                })
                .catch(error => {
                    console.log(error);
                    this.loadingPage = 0;
                });
        } else if (this.$session.has("income_detail")) {
            this.editIncome(this.$session.get("income_detail"))
                .then(response => {
                    this.loadingPage = 0;
                })
                .catch(error => {
                    console.log(error);
                    this.loadingPage = 0;
                });
        } else {
            this.$router.push({ name: "income" });
            this.alert("Data not found, select data first", 2);
        }
        this.getSignatureAndBank();
    },
    methods: {
        //VUEX
        ...mapMutations("income", ["CLEAR_FORM"]),
        ...mapMutations(["CLEAR_ERRORS"]),
        ...mapActions("income", [
            "editIncome",
            "getQuotationNo",
            "getOrderNo",
            "getInvoiceNo",
            "updateIncome",
            "removeIncome",
            "printIncome"
        ]),
        ...mapActions("signature", ["getSignatureDocument"]),
        ...mapActions("bank", ["getBankDocument"]),
        getSignatureAndBank(){
            this.getSignatureDocument({ document: "signature_income" })
                .then(response => {
                    this.loadingPage = false;
                })
                .catch(error => {
                    console.log(error);
                    this.loadingPage = false;
                });
            this.getBankDocument({ document: "bank_income" })
                .then(response => {
                    this.loadingPage = false;
                })
                .catch(error => {
                    console.log(error);
                    this.loadingPage = false;
                });
        },
        selectBank(val) {
            if (val != "") {
                var result = this.banks.data.find(obj => {
                    return obj.id === parseInt(val);
                });
                this.docBank.bank_name = result.bank_name;
                this.docBank.bank_cabang = result.bank_cabang;
                this.docBank.bank_rekening = result.bank_rekening;
                this.docBank.bank_rekening_name = result.bank_rekening_name;
            } else {
                this.docBank.bank_name = "[select bank]";
                this.docBank.bank_cabang = "[select bank]";
                this.docBank.bank_rekening = "[select bank]";
                this.docBank.bank_rekening_name = "[select bank]";
            }
        },
        selectSignature(val) {
            if (val != "") {
                var result = this.signatures.data.find(obj => {
                    return obj.id === parseInt(val);
                });
                this.docSignature.signature_name = result.signature_name;
                this.docSignature.signature_position =
                    result.signature_position;
            } else {
                this.docSignature.signature_name = "[select signature]";
                this.docSignature.signature_position = "[select signature]";
            }
        },
        selectSignature2(val) {
            if (val != "") {
                var result = this.signatures.data.find(obj => {
                    return obj.id === parseInt(val);
                });
                this.docSignature2.signature_name = result.signature_name;
                this.docSignature2.signature_position =
                    result.signature_position;
            } else {
                this.docSignature2.signature_name = "[select signature]";
                this.docSignature2.signature_position = "[select signature]";
            }
        },
        errorLogoUrl(event) {
            event.target.src = "/media/workspace/no-logo.jpg";
        },
        print() {
            var browserName = navigator.appName;
            if (browserName == "Safari") {
                var printContents = document.getElementById("printMe")
                    .innerHTML;
                var originalContents = document.body.innerHTML;
                document.body.innerHTML = printContents;
                window.print();
                document.body.innerHTML = originalContents;
            } else {
                this.$htmlToPaper("printMe");
            }
        }
    },
    destroyed() {
        this.CLEAR_FORM();
    }
};
</script>
