import Vue from "vue";

import $axios from "../../api.js";

const state = () => ({
    paypurchases: [],

    paypurchase: {
        payment_id: 0,
        payment_contact_id: 0,
        payment_coa_id: 0,
        payment_no: "",
        payment_date: Vue.moment().format("YYYY-MM-DD"),
        payment_amount: 0,
        payment_journal_memo: "",
        payment_lock: false,
        payment_detail: [],
        payment_bills: [],
        payment_out_of_balance: 0,
        payment_total_applied: 0,
        payment_type: "Purchase",
        payment_reference_id: 0,
        payment_file: "",
        contact: {},
        account: {},
        journal: {},
        adjustment: {
            detail: []
        },
        created_by: "",
        created_at: ""
    },
    page: 1, //UNTUK MENCATAT PAGE PAGINATE YANG SEDANG DIAKSES
    last_page: 1,
    search: ""
});

const mutations = {
    ASSIGN_DATA(state, payload) {
        state.paypurchases = payload;
        state.last_page = payload.meta.last_page;
        state.page = payload.meta.current_page;
    },
    //
    SET_PAGE(state, payload) {
        state.page = payload;
    },
    ASSIGN_FORM(state, payload) {
        state.paypurchase = {
            payment_id: payload.id,
            payment_contact_id: payload.payment_contact_id,
            payment_coa_id: payload.payment_coa_id,
            payment_no: payload.payment_no,
            payment_date: payload.payment_date,
            payment_amount: payload.payment_amount,
            payment_journal_memo: payload.payment_journal_memo,
            payment_lock: payload.locked,
            payment_detail: [],
            payment_bills: payload.payment_detail,
            payment_out_of_balance: 0,
            payment_total_applied: payload.payment_amount,
            payment_type: payload.payment_type,
            payment_reference_id: payload.payment_reference_id,
            payment_file: payload.payment_file,
            contact: payload.contact,
            account: payload.account,
            journal: payload.journal,
            adjustment: payload.adjustment,
            created_by: payload.created_by,
            created_at: payload.created_at
        };
    },
    CLEAR_FORM(state) {
        state.paypurchase = {
            payment_id: 0,
            payment_contact_id: 0,
            payment_coa_id: 0,
            payment_no: "",
            payment_date: Vue.moment().format("YYYY-MM-DD"),
            payment_amount: 0,
            payment_journal_memo: "",
            payment_lock: false,
            payment_detail: [],
            payment_bills: [],
            payment_out_of_balance: 0,
            payment_total_applied: 0,
            payment_type: "Purchase",
            payment_reference_id: 0,
            payment_file: "",
            contact: {},
            account: {},
            journal: {},
            adjustment: {
                detail: []
            },
            created_by: "",
            created_at: ""
        };
    },
    CLEAR_DATA(state) {
        state.paypurchases = [];
    }
};

const actions = {
    getPayPurchaseNo({ dispatch, commit, state }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .get(
                    `/pay-purchase/get-payment-no/` +
                        state.paypurchase.payment_date
                )
                .then(response => {
                    state.paypurchase.payment_no = response.data;
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    console.log(error);
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                });
        });
    },
    getPayPurchase({ dispatch, commit, state }, payload) {
        //MENGECEK PAYLOAD ADA ATAU TIDAK
        let rows = payload.rows;
        let date_start = payload.date_start;
        let date_end = payload.date_end;

        return new Promise((resolve, reject) => {
            //REQUEST DATA DENGAN ENDPOINT /COA
            $axios
                .get(
                    `/pay-purchase?rows=${rows}&date_start=${date_start}&date_end=${date_end}`
                )
                .then(response => {
                    //SIMPAN DATA KE STATE MELALUI MUTATIONS
                    commit("ASSIGN_DATA", response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                    reject(error);
                });
        });
    },
    // //FUNGSI UNTUK MENAMBAHKAN DATA BARU
    submitPayPurchase({ dispatch, commit, state }) {
        return new Promise((resolve, reject) => {
            $axios
                .post(`/pay-purchase`, state.paypurchase)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                });
        });
    },
    //UNTUK MENGAMBIL SINGLE DATA DARI SERVER BERDASARKAN id PayPurchase
    editPayPurchase({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .get(`/pay-purchase/${payload}/edit`)
                .then(response => {
                    commit("ASSIGN_FORM", response.data.data);
                    resolve(response.data);
                })
                .catch(error => {
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                    reject(error);
                });
        });
    },
    //UNTUK MENGUPDATE DATA BERDASARKAN CODE YANG SEDANG DIEDIT
    updatePayPurchase({ state, commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .put(`/pay-purchase/${payload}`, state.paypurchase)
                .then(response => {
                    //FORM DIBERSIHKAN
                    commit("CLEAR_FORM");
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                });
        });
    },
    //MENGHAPUS DATA
    removePayPurchase({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .delete(`/pay-purchase/${payload}`)
                .then(response => {
                    commit("CLEAR_FORM");
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
