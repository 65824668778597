<template>
    <main>
        <hero
            title="Journal Voucher"
            prevBreadcrumb="Journal Transaction"
            routeBreadcrumb="journal-transaction"
            currentBreadcrumb="Journal Voucher"
        ></hero>

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <!-- Invoice -->
            <div class="block block-rounded block-mode-loading-oneui mb-2">
                <div class="block-header">
                    <h3 class="block-title">
                        Journal {{ journal.journal_no }}
                    </h3>
                    <div class="block-options">
                        <router-link
                            :to="{
                                name: 'journal-transaction-edit',
                                params: { id: journal.journal_id }
                            }"
                            tag="button"
                            type="button"
                            class="btn-block-option text-success"
                        >
                            <i class="si si-pencil mr-1"></i>
                            <span class="d-none d-md-inline-block">
                                Edit Data
                            </span>
                        </router-link>
                        <!-- Print Page functionality is initialized in Helpers.print() -->
                        <button
                            type="button"
                            class="btn-block-option text-info"
                            @click="print()"
                        >
                            <i class="si si-printer mr-1"></i>
                            <span class="d-none d-md-inline-block"
                                >Print Document</span
                            >
                        </button>
                    </div>
                </div>
            </div>
            <div class="block bg-transparent mb-2">
                <div class="block-content pt-0 bg-transparent text-right">
                    <input
                        class="form-check-input"
                        style="margin-top: 3.5px"
                        type="checkbox"
                        v-model="useHeader"
                    />
                    <label class="form-check-label">Default Header</label>
                </div>
            </div>
            <!--Loading-->
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 1"
                style="height:250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <!-- Basic -->
            <div class="block block-rounded block-mode-loading-oneui block-transparent" v-else>
                <div class="row">
                    <div class="col-8">
                        <div class="content content-boxed bg-white block-rounded p-0" id="printMe">
                            <div class="block-content">
                                <div
                                    class="p-4"
                                    style="min-height: 800px;zoom: 0.9;"
                                >
                                    <div class="row pt-0" v-if="useHeader">
                                        <div
                                            class="col-8 font-size-sm pb-0 pt-0"
                                        >
                                            <p
                                                class="h3 mb-2 mt-3 text-uppercase"
                                            >
                                                {{
                                                    auth.data.workspace
                                                        .workspace_company_name
                                                }}
                                            </p>
                                            <p class="mb-0">
                                                {{
                                                    auth.data.workspace
                                                        .workspace_company_address
                                                }}
                                            </p>
                                            <p class="mb-0">
                                                <span
                                                    v-if="
                                                        auth.data.workspace
                                                            .workspace_company_province !==
                                                            '' &&
                                                            auth.data.workspace
                                                                .workspace_company_province !==
                                                                null
                                                    "
                                                >
                                                    {{
                                                        auth.data.workspace
                                                            .workspace_company_province
                                                    }}
                                                </span>
                                                <span
                                                    v-if="
                                                        auth.data.workspace
                                                            .workspace_company_postal !==
                                                            '' &&
                                                            auth.data.workspace
                                                                .workspace_company_postal !==
                                                                null
                                                    "
                                                >
                                                    ,
                                                    {{
                                                        auth.data.workspace
                                                            .workspace_company_postal
                                                    }}
                                                </span>
                                            </p>
                                            <p class="mb-0">
                                                <span
                                                    v-if="
                                                        auth.data.workspace
                                                            .workspace_company_phone !==
                                                            '' &&
                                                            auth.data.workspace
                                                                .workspace_company_phone !==
                                                                null
                                                    "
                                                >
                                                    {{
                                                        auth.data.workspace
                                                            .workspace_company_phone
                                                    }}
                                                </span>
                                                <span
                                                    v-if="
                                                        auth.data.workspace
                                                            .workspace_company_email !==
                                                            '' &&
                                                            auth.data.workspace
                                                                .workspace_company_email !==
                                                                null
                                                    "
                                                >
                                                    {{
                                                        "| " +
                                                            auth.data.workspace
                                                                .workspace_company_email
                                                    }}
                                                </span>
                                            </p>
                                        </div>
                                        <div
                                            class="col-4 text-right font-size-sm pb-0 pt-0"
                                        >
                                            <img
                                                :src="
                                                    '/storage/workspace/' +
                                                        auth.data.workspace
                                                            .workspace_company_logo
                                                "
                                                alt=""
                                                style="
                                            min-heigh: 100px;
                                            max-height: 120px;
                                            max-width: 180px;
                                        "
                                                @error="errorLogoUrl"
                                            />
                                        </div>
                                    </div>
                                    <hr class="mb-4" v-if="useHeader" />
                                    <p class="h3 mb-2">Journal Voucher</p>
                                    <div class="row mb-0">
                                        <div
                                            class="form-group col-12 col-md-4 py-0 mb-0"
                                        >
                                            <label
                                                for="example-text-input font-w600"
                                                >Journal No.
                                            </label>
                                            <div class="input-group">
                                                <p>{{ journal.journal_no }}</p>
                                            </div>
                                        </div>
                                        <div
                                            class="form-group col-12 col-md-5 py-0 mb-0"
                                        >
                                            <label
                                                for="example-text-input font-w600"
                                                >Journal Memo</label
                                            >
                                            <div class="input-group">
                                                <p>
                                                    {{
                                                        journal.journal_description
                                                    }}
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            class="form-group col-12 col-md-3 py-0 mb-0"
                                        >
                                            <label
                                                for="example-text-input font-w600"
                                                >Journal Date</label
                                            >
                                            <div class="input-group">
                                                <p>
                                                    {{
                                                        journal.journal_date
                                                            | moment(
                                                                "DD/MM/YYYY"
                                                            )
                                                    }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row font-size-sm">
                                        <div class="col-12 py-0">
                                            <div class="form-group">
                                                <div
                                                    class="table-responsive push"
                                                >
                                                    <table
                                                        class="table table-bordered"
                                                    >
                                                        <thead
                                                            class="bg-body-light"
                                                        >
                                                            <tr>
                                                                <th
                                                                    class="text-center text-uppercase"
                                                                >
                                                                    Account No.
                                                                </th>
                                                                <th
                                                                    class="text-center text-uppercase"
                                                                >
                                                                    Debit
                                                                </th>
                                                                <th
                                                                    class="text-center text-uppercase"
                                                                    style="width: 20%"
                                                                >
                                                                    Credit
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr
                                                                v-for="(detail,
                                                                index) in journal.journal_detail"
                                                                :key="index"
                                                            >
                                                                <td>
                                                                    <p
                                                                        class="mb-1"
                                                                    >
                                                                        {{
                                                                            detail
                                                                                .coa
                                                                                .account_no
                                                                        }}
                                                                        {{
                                                                            detail
                                                                                .coa
                                                                                .account_name
                                                                        }}
                                                                    </p>
                                                                </td>
                                                                <td
                                                                    class="text-right"
                                                                >
                                                                    <p
                                                                        class="mb-1"
                                                                    >
                                                                        {{
                                                                            detail.debit
                                                                                | currency
                                                                        }}
                                                                    </p>
                                                                </td>
                                                                <td
                                                                    class="text-right"
                                                                >
                                                                    <p
                                                                        class="mb-1"
                                                                    >
                                                                        {{
                                                                            detail.credit
                                                                                | currency
                                                                        }}
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td
                                                                    class="font-w700 text-uppercase text-center"
                                                                >
                                                                    Total
                                                                </td>
                                                                <td
                                                                    class="text-right"
                                                                >
                                                                    <p
                                                                        class="font-w700 mb-1"
                                                                    >
                                                                        {{
                                                                            total_debit
                                                                                | currency
                                                                        }}
                                                                    </p>
                                                                </td>

                                                                <td
                                                                    class="text-right"
                                                                >
                                                                    <p
                                                                        class="font-w700 mb-1"
                                                                    >
                                                                        {{
                                                                            total_credit
                                                                                | currency
                                                                        }}
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                                <div
                                                    class="table-responsive push"
                                                >
                                                    <!-- MUTATION OUT -->
                                                    <p
                                                        class="d-print-none"
                                                        v-if="
                                                            journal.journal_type ==
                                                                5 &&
                                                                journal.journal_bill_id !=
                                                                    null
                                                        "
                                                    >
                                                        Journal Voucher for
                                                        Inventory Ajdustment Out
                                                        :
                                                        <a
                                                            class="text-pointer text-info font-w700"
                                                            @click="
                                                                toMutationOutDetail(
                                                                    journal.journal_bill_id
                                                                )
                                                            "
                                                        >
                                                            {{
                                                                journal
                                                                    .mutationOut
                                                                    .mutation_no
                                                            }}
                                                        </a>
                                                    </p>
                                                    <!-- MUTATION IN -->
                                                    <p
                                                        class="d-print-none"
                                                        v-if="
                                                            journal.journal_type ==
                                                                5 &&
                                                                journal.journal_payment_id !=
                                                                    null
                                                        "
                                                    >
                                                        Journal Voucher for
                                                        Inventory Ajdustment In
                                                        :
                                                        <a
                                                            class="text-pointer text-info font-w700"
                                                            @click="
                                                                toMutationInDetail(
                                                                    journal.journal_payment_id
                                                                )
                                                            "
                                                        >
                                                            {{
                                                                journal
                                                                    .mutationIn
                                                                    .mutation_no
                                                            }}
                                                        </a>
                                                    </p>
                                                    <!-- BILL -->
                                                    <p
                                                        class="d-print-none"
                                                        v-if="
                                                            journal.journal_type ==
                                                                1 &&
                                                                journal.bill !=
                                                                    null &&
                                                                journal.bill
                                                                    .bill_type ==
                                                                    'Sales'
                                                        "
                                                    >
                                                        Journal Voucher for Bill
                                                        Invoice :
                                                        <a
                                                            class="text-pointer text-info font-w700"
                                                            @click="
                                                                toBillDetail(
                                                                    journal.journal_bill_id
                                                                )
                                                            "
                                                        >
                                                            {{
                                                                journal.bill
                                                                    .bill_invoice_number
                                                            }}
                                                        </a>
                                                    </p>
                                                    <!-- PURCHASE -->
                                                    <p
                                                        class="d-print-none"
                                                        v-if="
                                                            journal.journal_type ==
                                                                1 &&
                                                                journal.bill !=
                                                                    null &&
                                                                journal.bill
                                                                    .bill_type ==
                                                                    'Purchases'
                                                        "
                                                    >
                                                        Journal Voucher for
                                                        Purchase Invoice :
                                                        <a
                                                            class="text-pointer text-info font-w700"
                                                            @click="
                                                                toPurchaseDetail(
                                                                    journal.journal_bill_id
                                                                )
                                                            "
                                                        >
                                                            {{
                                                                journal.bill
                                                                    .bill_invoice_number
                                                            }}
                                                        </a>
                                                    </p>
                                                    <!-- EXPENSE -->
                                                    <p
                                                        class="d-print-none"
                                                        v-if="
                                                            journal.journal_type ==
                                                                1 &&
                                                                journal.bill !=
                                                                    null &&
                                                                journal.bill
                                                                    .bill_type ==
                                                                    'Expenses'
                                                        "
                                                    >
                                                        Journal Voucher for
                                                        Expense No. :
                                                        <a
                                                            class="text-pointer text-info font-w700"
                                                            @click="
                                                                toExpenseDetail(
                                                                    journal.journal_bill_id
                                                                )
                                                            "
                                                        >
                                                            {{
                                                                journal.bill
                                                                    .bill_number
                                                            }}
                                                        </a>
                                                    </p>
                                                    <!-- INCOME -->
                                                    <p
                                                        class="d-print-none"
                                                        v-if="
                                                            journal.journal_type ==
                                                                1 &&
                                                                journal.bill !=
                                                                    null &&
                                                                journal.bill
                                                                    .bill_type ==
                                                                    'Incomes'
                                                        "
                                                    >
                                                        Journal Voucher for
                                                        Income No. :
                                                        <a
                                                            class="text-pointer text-info font-w700"
                                                            @click="
                                                                toIncomeDetail(
                                                                    journal.journal_bill_id
                                                                )
                                                            "
                                                        >
                                                            {{
                                                                journal.bill
                                                                    .bill_number
                                                            }}
                                                        </a>
                                                    </p>
                                                    <!-- RECEIVE PAYMENT -->
                                                    <p
                                                        class="d-print-none"
                                                        v-if="
                                                            journal.payment !=
                                                                null &&
                                                                journal.payment
                                                                    .payment_type ==
                                                                    'Receive' &&
                                                                journal.journal_type ==
                                                                    1
                                                        "
                                                    >
                                                        Journal Voucher for
                                                        Receive Payment No. :
                                                        <a
                                                            class="text-pointer text-info font-w700"
                                                            @click="
                                                                toReceivePaymentDetail(
                                                                    journal.journal_payment_id
                                                                )
                                                            "
                                                        >
                                                            {{
                                                                journal.payment
                                                                    .payment_no
                                                            }}
                                                        </a>
                                                    </p>
                                                    <!-- ADJUSTMENT RECEIVE PAYMENT -->
                                                    <p
                                                        class="d-print-none"
                                                        v-if="
                                                            journal.payment !=
                                                                null &&
                                                                journal.payment
                                                                    .payment_type ==
                                                                    'Receive' &&
                                                                journal.journal_type ==
                                                                    3
                                                        "
                                                    >
                                                        Journal Voucher for
                                                        Adjustment Receive
                                                        Payment No. :
                                                        <a
                                                            class="text-pointer text-info font-w700"
                                                            @click="
                                                                toReceivePaymentDetail(
                                                                    journal.journal_payment_id
                                                                )
                                                            "
                                                        >
                                                            {{
                                                                journal.payment
                                                                    .payment_no
                                                            }}
                                                        </a>
                                                    </p>
                                                    <!-- PURCHASE -->
                                                    <p
                                                        class="d-print-none"
                                                        v-if="
                                                            journal.journal_type ==
                                                                1 &&
                                                                journal.payment !=
                                                                    null &&
                                                                journal.payment
                                                                    .payment_type ==
                                                                    'Purchase'
                                                        "
                                                    >
                                                        Journal Voucher for
                                                        Purchase No. :
                                                        <a
                                                            class="text-pointer text-info font-w700"
                                                            @click="
                                                                toPayPurchaseDetail(
                                                                    journal.journal_payment_id
                                                                )
                                                            "
                                                        >
                                                            {{
                                                                journal.payment
                                                                    .payment_no
                                                            }}
                                                        </a>
                                                    </p>
                                                    <!-- ADJUSTMENT PAY PURCHASE -->
                                                    <p
                                                        class="d-print-none"
                                                        v-if="
                                                            journal.payment !=
                                                                null &&
                                                                journal.payment
                                                                    .payment_type ==
                                                                    'Purchase' &&
                                                                journal.journal_type ==
                                                                    3
                                                        "
                                                    >
                                                        Journal Voucher for
                                                        Adjustment Pay Purchase
                                                        No. :
                                                        <a
                                                            class="text-pointer text-info font-w700"
                                                            @click="
                                                                toReceivePaymentDetail(
                                                                    journal.journal_payment_id
                                                                )
                                                            "
                                                        >
                                                            {{
                                                                journal.payment
                                                                    .payment_no
                                                            }}
                                                        </a>
                                                    </p>
                                                </div>

                                                <div class="row mt-4 mb-5">
                                                    <div
                                                        class="col-6 text-left font-size-sm pt-5 mt-2"
                                                    >
                                                        <div
                                                            class="form-group d-print-none"
                                                        >
                                                            <label
                                                                for="example-text-input"
                                                                >Post Note
                                                                *</label
                                                            >
                                                            <ckeditor
                                                                tag-name="textarea"
                                                                :editor="editor"
                                                                v-model="
                                                                    docPostNote
                                                                "
                                                                :config="
                                                                    editorConfig
                                                                "
                                                            ></ckeditor>
                                                        </div>
                                                        <span
                                                            class="d-none d-print-inline-block"
                                                            v-html="docPostNote"
                                                        ></span>
                                                    </div>
                                                    <div
                                                        class="col-6 font-size-sm text-right pt-5 mt-2"
                                                    >
                                                        <div
                                                            class="form-group text-left d-print-none"
                                                        >
                                                            <label
                                                                for="example-text-input"
                                                                >Choose
                                                                Signature
                                                                *</label
                                                            >
                                                            <selectize
                                                                class="form-control"
                                                                placeholder="Choose Signature"
                                                                v-model="
                                                                    selectedSignature
                                                                "
                                                                @input="
                                                                    selectSignature(
                                                                        selectedSignature
                                                                    )
                                                                "
                                                            >
                                                                <option
                                                                    v-for="signature in signatures.data"
                                                                    :value="
                                                                        signature.id
                                                                    "
                                                                    :key="
                                                                        signature.id
                                                                    "
                                                                >
                                                                    {{
                                                                        signature.signature_name
                                                                    }}
                                                                </option>
                                                            </selectize>
                                                        </div>
                                                        <p
                                                            class="mb-0 font-w700 mt-6 ml-9 text-center"
                                                            style="
                                                        border-bottom: 1px solid
                                                            grey;
                                                    "
                                                            v-bind:class="{
                                                                'd-print-none':
                                                                    selectedSignature ==
                                                                    ''
                                                            }"
                                                        >
                                                            {{
                                                                docSignature.signature_name
                                                            }}
                                                        </p>
                                                        <p
                                                            class="mb-0 font-w700 ml-9 text-center"
                                                            v-bind:class="{
                                                                'd-print-none':
                                                                    selectedSignature ==
                                                                    ''
                                                            }"
                                                        >
                                                            {{
                                                                docSignature.signature_position
                                                            }}
                                                        </p>
                                                    </div>
                                                </div>

                                                <hr />
                                                <p
                                                    class="d-none d-print-inline-block"
                                                    style="opacity: 0.6"
                                                >
                                                    <i>
                                                        Printed : {{ dateNow }}
                                                    </i>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="content content-boxed p-0" v-if="this.journal.bill != null">
                            <div
                                class="block-content bg-white block-rounded px-3 py-3 mb-3"
                            >
                                <p class="font-w700 mb-3" v-if="this.journal.bill.bill_type == 'Sales'">Bill Document</p>
                                <p class="font-w700 mb-3" v-if="this.journal.bill.bill_type == 'Purchases'">Purchase Document</p>
                                <p class="text-muted-high mb-0" v-if="this.journal.bill.bill_type == 'Sales'">Bill No.</p>
                                <p class="text-muted-high mb-0" v-if="this.journal.bill.bill_type == 'Purchases'">Purchase No.</p>
                                <p class="mb-2">
                                    {{ this.journal.bill.bill_number }}
                                </p>
                                <p class="text-muted-high mb-0">Issued To</p>
                                <p class="mb-2">
                                    {{ this.journal.bill.contact.contact_name }}
                                </p>
                                <p class="text-muted-high mb-0">Address</p>
                                <p class="mb-2">
                                    {{ this.journal.bill.bill_shipaddress }}
                                </p>
                                <div class="row">
                                    <div class="col-6 py-0">
                                        <p class="text-muted-high mb-0">
                                            On
                                        </p>
                                        <p class="mb-2">
                                            {{ this.journal.bill.bill_date | moment("DD/MM/YYYY")}}
                                        </p>
                                    </div>
                                    <div class="col-6 py-0">
                                        <p class="text-muted-high mb-0">
                                            Due On
                                        </p>
                                        <p class="mb-2">
                                            {{ this.journal.bill.bill_due_date | moment("DD/MM/YYYY")}}
                                        </p>
                                    </div>
                                </div>
                                <p class="text-muted-high mb-0">Amount</p>
                                <p class="font-w700 mb-2">
                                    {{ this.journal.bill.bill_grand_total | currency }}
                                </p>
                                <p class="mt-2 mb-0 text-right text-info text-pointer" v-if="this.journal.bill.bill_type == 'Sales'" @click="toBillDetail(journal.journal_bill_id)">
                                    <span>Go to Bill Document</span>
                                </p>
                                <p class="mt-2 mb-0 text-right text-info text-pointer" v-if="this.journal.bill.bill_type == 'Purchases'" @click="toPurchaseDetail(journal.journal_bill_id)">
                                    <span>Go to Purchase Document</span>
                                </p>
                            </div>
                        </div>
                        <div class="content content-boxed p-0" v-if="this.journal.payment != null">
                            <div
                                class="block-content bg-white block-rounded px-3 py-3 mb-3"
                            >
                                <p class="font-w700 mb-3" v-if="this.journal.payment.payment_type == 'Receive'">Receive Payment Document</p>
                                <p class="font-w700 mb-3" v-if="this.journal.payment.payment_type == 'Purchase'">Purchase Payment Document</p>
                                <p class="text-muted-high mb-0">Payment No.</p>
                                <p class="mb-2">
                                    {{ this.journal.payment.payment_no }}
                                </p>
                                <p class="text-muted-high mb-0">Issued To</p>
                                <p class="mb-2">
                                    {{ this.journal.payment.contact.contact_name }}
                                </p>
                                <p class="text-muted-high mb-0">Address</p>
                                <p class="mb-2">
                                    {{ this.journal.payment.contact.contact_address }}
                                </p>
                                <div class="row">
                                    <div class="col-6 py-0">
                                        <p class="text-muted-high mb-0">
                                            On
                                        </p>
                                        <p class="mb-2">
                                            {{ this.journal.payment.payment_date | moment("DD/MM/YYYY")}}
                                        </p>
                                    </div>
                                </div>
                                <p class="text-muted-high mb-0">Amount</p>
                                <p class="font-w700 mb-2">
                                    {{ this.journal.payment.payment_amount | currency }}
                                </p>
                                <p class="mt-2 mb-0 text-right text-info text-pointer" v-if="this.journal.payment.payment_type == 'Receive'" @click="toReceivePaymentDetail(journal.journal_payment_id)">
                                    <span>Go to Receive Payment Document</span>
                                </p>
                                <p class="mt-2 mb-0 text-right text-info text-pointer" v-if="this.journal.payment.payment_type == 'Purchase'" @click="toPayPurchaseDetail(journal.journal_payment_id)">
                                    <span>Go to Purchase Payment Document</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- END Invoice -->
            </div>
            <!-- END Basic -->
        </div>
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import moment from "moment";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
    name: "voucher-journal",
    data() {
        return {
            loadingPage: 1,
            total_debit: 0,
            total_credit: 0,
            diff_debit: 0,
            diff_credit: 0,
            diff_status: true,
            useHeader: true,
            dateNow: moment().format("MMMM Do YYYY, HH:mm:ss"),
            selectedSignature: "",
            docSignature: {
                signature_name: "[select signature]",
                signature_position: "[select signature]"
            },
            editor: ClassicEditor,
            editorConfig: {
                toolbar: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "bulletedList",
                    "numberedList",
                    "blockQuote",
                    "undo",
                    "redo",
                    "insertTable"
                ]
            },
            docPostNote: ""
        };
    },
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState("coa", { coas: state => state.coas.accounts }),
        ...mapState("journal", { journal: state => state.journal }),
        ...mapState(["auth"]),
        ...mapState("signature", { signatures: state => state.signatures })
    },
    mounted() {
        if (this.$route.params.id) {
            this.editJournal(this.$route.params.id)
                .then(response => {
                    this.$session.set("journal_voucher", response.journal.id);
                    this.calculateDebit();
                    this.calculateCredit();
                    this.getSignature();
                    this.loadingPage = 0;
                })
                .catch(error => {
                    this.alert(error.message, 2);
                    this.loadingPage = 0;
                });
        } else if (this.$session.has("journal_voucher")) {
            this.editJournal(this.$session.get("journal_voucher"))
                .then(response => {
                    this.calculateDebit();
                    this.calculateCredit();
                    this.getSignature();
                    this.loadingPage = 0;
                })
                .catch(error => {
                    this.alert(error.message, 2);
                    this.loadingPage = 0;
                });
        } else {
            this.$router.push({ name: "journal-transaction" });
            this.alert("Data not found, select data first", 2);
        }
    },
    methods: {
        //VUEX
        ...mapMutations("journal", ["CLEAR_FORM"]),
        ...mapActions("coa", ["getCoaAll"]),
        ...mapMutations(["CLEAR_ERRORS"]),
        ...mapActions("journal", [
            "getJournalNo",
            "updateJournal",
            "editJournal",
            "removeJournal"
        ]),
        ...mapActions("signature", ["getSignatureDocument"]),
        getSignature() {
            this.getSignatureDocument({
                document: "signature_voucher"
            })
                .then(response => {
                    this.loadingPage = false;
                })
                .catch(error => {
                    console.log(error);
                    this.loadingPage = false;
                });
        },
        selectSignature(val) {
            if (val != "") {
                var result = this.signatures.data.find(obj => {
                    return obj.id === parseInt(val);
                });
                this.docSignature.signature_name = result.signature_name;
                this.docSignature.signature_position =
                    result.signature_position;
            } else {
                this.docSignature.signature_name = "[select signature]";
                this.docSignature.signature_position = "[select signature]";
            }
        },
        //CALCULATE METHOD
        calculateDebit() {
            var total = 0;
            this.journal.journal_detail.forEach(details => {
                total += details.debit;
            });

            this.total_debit = total;
            this.calcucateDiff();
        },
        calculateCredit() {
            var total = 0;
            this.journal.journal_detail.forEach(details => {
                total += details.credit;
            });

            this.total_credit = total;
            this.calcucateDiff();
        },
        calcucateDiff() {
            if (this.total_debit > this.total_credit) {
                this.diff_credit = this.total_debit - this.total_credit;
                this.diff_debit = 0;
                this.diff_status = true;
            } else if (this.total_credit > this.total_debit) {
                this.diff_debit = this.total_credit - this.total_debit;
                this.diff_credit = 0;
                this.diff_status = true;
            } else if (this.total_credit <= 0 && this.total_debit <= 0) {
                this.diff_status = true;
            } else {
                this.diff_debit = 0;
                this.diff_credit = 0;
                this.diff_status = false;
            }
        },
        toBillDetail(val) {
            this.$router.push({ name: "bill-detail", params: { id: val } });
        },
        toPurchaseDetail(val) {
            this.$router.push({ name: "purchase-detail", params: { id: val } });
        },
        toExpenseDetail(val) {
            this.$router.push({ name: "expense-detail", params: { id: val } });
        },
        toIncomeDetail(val) {
            this.$router.push({ name: "income-detail", params: { id: val } });
        },
        toReceivePaymentDetail(val) {
            this.$router.push({
                name: "receive-payment-detail",
                params: { id: val }
            });
        },
        toPayPurchaseDetail(val) {
            this.$router.push({
                name: "pay-purchase-detail",
                params: { id: val }
            });
        },
        toMutationInDetail(val) {
            this.$router.push({
                name: "incoming-item-detail",
                params: { id: val }
            });
        },
        toMutationOutDetail(val) {
            this.$router.push({
                name: "outgoing-item-detail",
                params: { id: val }
            });
        },
        goToBill(val){
            this.$router.push({ name: "bill-detail", params: { id: val } });
        },
        errorLogoUrl(event) {
            event.target.src = "/media/workspace/no-logo.jpg";
        },
        print() {
            var browserName = navigator.appName;
            if (browserName == "Safari") {
                var printContents = document.getElementById("printMe")
                    .innerHTML;
                var originalContents = document.body.innerHTML;
                document.body.innerHTML = printContents;
                window.print();
                document.body.innerHTML = originalContents;
            } else {
                this.$htmlToPaper("printMe");
            }
        }
    },
    destroyed() {
        this.CLEAR_FORM();
    }
};
</script>
