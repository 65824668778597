<!-- HTML SECTION -->
<template>
    <!-- Page Content -->
    <div class="page-container">
        <!-- Main Container -->
        <main id="main-container">
            <!-- Page Content -->
            <div class="hero-static">
                <div
                    class="content"
                    style="
                        background: url(media/proxima/background.png) no-repeat
                            center top;
                        background-size: cover;
                    "
                >
                    <div class="row justify-content-center">
                        <div class="col-md-8 col-lg-6 col-xl-4">
                            <!-- Sign Up Block -->
                            <div
                                class="block block-themed block-fx-shadow mb-0 block-rounded block-mode-loading-oneui"
                                style="border-radius: 15px !important"
                            >
                                <div
                                    class="block-header bg-info"
                                    style="
                                        border-top-left-radius: 15px;
                                        border-top-right-radius: 15px;
                                    "
                                >
                                    <img
                                        class="m-auto"
                                        :src="
                                            '/media/proxima/logotype-white.png'
                                        "
                                        height="30px"
                                    />
                                </div>
                                <div class="block-content">
                                    <div class="p-sm-3 px-lg-4 py-lg-5">
                                        <h3 class="mb-2 text-black-75">
                                            Daftar Akun
                                        </h3>
                                        <p class="text-black-75">
                                            Daftar dan coba gratis Proxima
                                            selama 7 Hari !
                                        </p>

                                        <!-- Sign Up Form -->
                                        <!-- jQuery Validation (.js-validation-signup class is initialized in js/pages/op_auth_signup.min.js which was auto compiled from _es6/pages/op_auth_signup.js) -->
                                        <!-- For more info and examples you can check out https://github.com/jzaefferer/jquery-validation -->
                                        <form class="js-validation-signup">
                                            <div class="py-3">
                                                <div class="form-group">
                                                    <input
                                                        type="email"
                                                        class="form-control form-control-lg form-control-alt"
                                                        placeholder="Email"
                                                        v-model="register.email"
                                                        style="
                                                            border-color: #d5d5d5;
                                                        "
                                                        @keydown.space.prevent
                                                        :class="{
                                                            'is-invalid':
                                                                errors.email ||
                                                                errors.invalid,
                                                        }"
                                                    />
                                                    <p
                                                        class="text-danger"
                                                        v-if="errors.email"
                                                    >
                                                        {{ errors.email[0] }}
                                                    </p>
                                                </div>
                                                <div class="form-group">
                                                    <input
                                                        type="text"
                                                        class="form-control form-control-lg form-control-alt"
                                                        placeholder="Nama Lengkap"
                                                        v-model="register.name"
                                                        style="
                                                            border-color: #d5d5d5;
                                                        "
                                                        :class="{
                                                            'is-invalid':
                                                                errors.name ||
                                                                errors.invalid,
                                                        }"
                                                    />
                                                    <p
                                                        class="text-danger"
                                                        v-if="errors.name"
                                                    >
                                                        {{ errors.name[0] }}
                                                    </p>
                                                </div>
                                                <div class="form-group">
                                                    <input
                                                        type="text"
                                                        class="form-control form-control-lg form-control-alt"
                                                        placeholder="Nama Perusahaan"
                                                        v-model="
                                                            register.company
                                                        "
                                                        style="
                                                            border-color: #d5d5d5;
                                                        "
                                                        :class="{
                                                            'is-invalid':
                                                                errors.company ||
                                                                errors.invalid,
                                                        }"
                                                    />
                                                    <p
                                                        class="text-danger"
                                                        v-if="errors.company"
                                                    >
                                                        {{ errors.company[0] }}
                                                    </p>
                                                </div>
                                                <div class="form-group">
                                                    <input
                                                        type="text"
                                                        class="form-control form-control-lg form-control-alt"
                                                        placeholder="Username"
                                                        v-model="
                                                            register.username
                                                        "
                                                        style="
                                                            border-color: #d5d5d5;
                                                        "
                                                        @keydown.space.prevent
                                                        :class="{
                                                            'is-invalid':
                                                                errors.username ||
                                                                errors.invalid,
                                                        }"
                                                    />
                                                    <p
                                                        class="text-danger"
                                                        v-if="errors.username"
                                                    >
                                                        {{ errors.username[0] }}
                                                    </p>
                                                </div>
                                                <!-- Password -->
                                                <div
                                                    class="form-group has-feedback"
                                                    v-if="!passwordHidden"
                                                >
                                                    <input
                                                        type="text"
                                                        class="form-control form-control-lg form-control-alt d-inline"
                                                        placeholder="Password"
                                                        v-model="register.password"
                                                        style="border-color: #d5d5d5; margin-right: -45px; padding-right: 35px"
                                                        :class="{
                                                            'is-invalid':
                                                                errors.password ||
                                                                errors.invalid
                                                        }"
                                                        autocomplete="off"
                                                    />
                                                    <span
                                                        class="display-eye fa fa-eye-slash text-pointer"
                                                        @click="hidePassword"
                                                    ></span>
                                                    <p
                                                        class="text-danger"
                                                        v-if="errors.password"
                                                    >
                                                        {{ errors.password[0] }}
                                                    </p>
                                                </div>
                                                <div
                                                    class="form-group has-feedback"
                                                    v-if="passwordHidden"
                                                >
                                                    <input
                                                        type="password"
                                                        class="form-control form-control-lg form-control-alt d-inline"
                                                        placeholder="Password"
                                                        v-model="register.password"
                                                        style="border-color: #d5d5d5; margin-right: -45px; padding-right: 35px"
                                                        :class="{
                                                            'is-invalid':
                                                                errors.password ||
                                                                errors.invalid
                                                        }"
                                                        autocomplete="off"
                                                    />
                                                    <span
                                                        class="display-eye fa fa-eye text-pointer"
                                                        @click="showPassword"
                                                    ></span>
                                                    <p
                                                        class="text-danger"
                                                        v-if="errors.password"
                                                    >
                                                        {{ errors.password[0] }}
                                                    </p>
                                                </div>
                                                <div class="form-group">
                                                    <input
                                                        type="password"
                                                        class="form-control form-control-lg form-control-alt"
                                                        placeholder="Konfirmasi Password"
                                                        v-model="
                                                            register.password_confirmation
                                                        "
                                                        style="
                                                            border-color: #d5d5d5;
                                                        "
                                                        :class="{
                                                            'is-invalid':
                                                                errors.password_confirmation ||
                                                                errors.invalid,
                                                        }"
                                                        autocomplete="off"
                                                    />
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-12">
                                                    <button
                                                        @click.prevent="
                                                            postRegister
                                                        "
                                                        type="submit"
                                                        class="btn btn-sm btn-block btn-info"
                                                        v-if="loadingPage == 0"
                                                    >
                                                        <i
                                                            class="fa fa-fw fa-plus mr-1"
                                                        ></i>
                                                        Buat Akun
                                                    </button>
                                                </div>
                                                <div
                                                    class="col-6 text-center footer-lane-right"
                                                >
                                                    <span>
                                                        Sudah Punya Akun ?
                                                        <br />
                                                        <router-link
                                                            class="text-info font-size-sm"
                                                            :to="{
                                                                name: 'login'
                                                            }"
                                                            >Login disini
                                                        </router-link>
                                                    </span>
                                                </div>
                                                <div class="col-6 text-center">
                                                    <span>
                                                        Ingin Berlangganan ?
                                                        <br />
                                                        <a
                                                            class="text-info font-size-sm"
                                                            href="https://tripasysfo.com/proxima#price"
                                                            >Registrasi disini
                                                        </a>
                                                    </span>
                                                </div>
                                            </div>
                                        </form>
                                        <!-- END Sign Up Form -->
                                    </div>
                                </div>
                            </div>
                            <!-- END Sign Up Block -->
                        </div>
                    </div>
                    <!-- Footer -->
                    <div class="font-size-sm text-center text-muted py-3">
                        <strong>Proxima - Aquarius</strong> &copy; Tripasysfo
                        Development
                    </div>
                    <!-- END Footer -->
                </div>
            </div>
            <!-- END Page Content -->
        </main>
        <!-- END Main Container -->
    </div>
    <!-- END Page Content -->
</template>

<!-- JAVASCRIPT SECTION -->
<script>
import { mapActions, mapMutations, mapGetters, mapState } from "vuex";
export default {
    data() {
        return {
            loadingPage: 0,
            passwordHidden: true
        };
    },
    computed: {
        ...mapState("affiliate", { register: state => state.register }),
        ...mapState(["errors"]),
        ...mapGetters(["isAuth"]) //MENGAMBIL GETTERS isAuth DARI VUEX
    },
    mounted() {
        if (this.$route.query.ref) {
            this.register.referral = this.$route.query.ref;
            this.countVisit()
                .then(() => {
                    // console.log("counted");
                })
                .catch(error => {
                    console.log("error count");
                });
        }
        //KITA MELAKUKAN PENGECEKAN JIKA SUDAH LOGIN DIMANA VALUE isAuth BERNILAI TRUE
        if (this.isAuth) {
            //MAKA DI-DIRECT KE ROUTE DENGAN NAME home
            this.$router.push({ name: "home" });
        }
    },
    methods: {
        ...mapActions("auth", ["submit"]), //MENGISIASI FUNGSI submit() DARI VUEX AGAR DAPAT DIGUNAKAN PADA COMPONENT TERKAIT. submit() BERASAL DARI ACTION PADA FOLDER STORES/auth.js
        ...mapActions("affiliate", ["countVisit", "submitRegister"]),
        ...mapMutations(["CLEAR_ERRORS"]),
        // Show Hide Password
        hidePassword() {
            this.passwordHidden = true;
        },
        showPassword() {
            this.passwordHidden = false;
        },
        postRegister() {
            this.CLEAR_ERRORS();
            this.loadingPage = 1;
            this.submitRegister(this.data)
                .then(() => {
                    this.loadingPage = 0;
                    this.$router.push({
                        name: "login",
                        params: { afterRegister: 1 }
                    });
                })
                .catch(error => {
                    this.loadingPage = 0;
                });
        }
    }
};
</script>
