<template>
    <main>
        <hero title="Signature List" prevBreadcrumb="Dashboard" routeBreadcrumb="home" currentBreadcrumb="Signature List"></hero>

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 1 || !signatures.data"
                style="height:250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity:0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <!-- Dynamic Table Full -->
            <div class="block block-rounded block-mode-loading-oneui" v-else>
                <div class="block-content block-content-full pb-0 pt-3">
                    <div class="row">
                        <div
                            class="col-12 col-md-4 col-lg-4 col-xl-6 mb-2 mb-sm-0 pb-0"
                        >
                            <label>Show :</label>
                            <select
                                class="form-control-sm custom-select"
                                style="max-width:115px"
                                v-model="rows"
                            >
                                <option value="10">10 Rows</option>
                                <option value="20">20 Rows</option>
                                <option value="50">50 Rows</option>
                                <option value="100">100 Rows</option>
                                <option value="All">All Rows</option>
                            </select>
                        </div>
                        <div class="col-12 col-md-8 col-lg-8 col-xl-6 pb-0">
                            <input
                                type="text"
                                class="form-control form-control-sm col-12 col-md-6 float-right ml-1 mt-1 search-index"
                                name="example-text-input"
                                placeholder="Search.."
                                v-model="search"
                            />
                            <!-- <button
                                type="button"
                                class="btn btn-sm btn-success btn-icon float-right m-1"
                                v-ripple="{ center: true }"
                            >
                                <i class="fa fa-file-excel"></i>
                            </button>
                            <button
                                type="button"
                                class="btn btn-sm btn-danger btn-icon float-right m-1"
                                v-ripple="{ center: true }"
                            >
                                <i class="fa fa-file-pdf"></i>
                            </button> -->
                            <router-link
                                type="button"
                                class="btn btn-sm btn-info float-right m-1"
                                :to="{ name: 'signature-create' }"
                                tag="button"
                                v-ripple="{ center: true }"
                            >
                                <i class="fa fa-plus mr-1"></i> Add Data
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="block-content block-content-full">
                    <div class="table-responsive">
                        <table class="table  table-vcenter table-hover">
                            <thead style="background-color: whitesmoke;">
                                <tr>
                                    <th
                                        class="table-header-relative text-center"
                                        style="width: 80px;"
                                    >
                                        No.
                                    </th>
                                    <th
                                        class="table-header-relative sort-head"
                                        @click="sortBy('signature_name')"
                                    >
                                        Name
                                        <i
                                            class="fa fa-sort sort-icon"
                                            v-bind:class="{
                                                active: sort == 'signature_name'
                                            }"
                                        ></i>
                                    </th>
                                    <th
                                        class="table-header-relative sort-head"
                                        @click="sortBy('signature_position')"
                                    >
                                        Position
                                        <i
                                            class="fa fa-sort sort-icon"
                                            v-bind:class="{
                                                active:
                                                    sort == 'signature_position'
                                            }"
                                        ></i>
                                    </th>
                                    <th
                                        class="table-header-relative"
                                        style="width: 400px;"
                                    >
                                        Document
                                    </th>
                                </tr>
                            </thead>
                            <tbody
                                v-if="
                                    signatures.data &&
                                        signatures.data.length > 0
                                "
                            >
                                <tr v-if="loadingPage == 2">
                                    <td colspan="4">
                                        <grid-loader
                                            class="mx-auto mt-5"
                                            :color="'#70B9EB'"
                                            :size="'10px'"
                                        ></grid-loader>
                                        <center class="mt-2 mb-5">
                                            <span
                                                class="text-center"
                                                style="opacity:0.7"
                                                >Loading</span
                                            >
                                        </center>
                                    </td>
                                </tr>
                                <router-link
                                    :to="{
                                        name: 'signature-edit',
                                        params: { id: signature.id }
                                    }"
                                    v-else
                                    tag="tr"
                                    v-for="(signature,
                                    index) in signatures.data"
                                    :key="index"
                                >
                                    <td class="text-center font-size-sm">
                                        {{ index + 1 }}
                                    </td>
                                    <td class="font-size-sm">
                                        {{ signature.signature_name }}
                                    </td>
                                    <td class="font-size-sm">
                                        {{ signature.signature_position }}
                                    </td>
                                    <td class="font-size-sm text-center">
                                        <span
                                            class="badge badge-info"
                                            v-if="
                                                signature.signature_voucher ==
                                                    1
                                            "
                                            >Journal Voucher</span
                                        >
                                        <span
                                            class="badge badge-info"
                                            v-if="
                                                signature.signature_asset ==
                                                    1
                                            "
                                            >Asset Document</span
                                        >
                                        <span
                                            class="badge badge-info"
                                            v-if="
                                                signature.signature_inventory ==
                                                    1
                                            "
                                            >Inventory Document</span
                                        >
                                        <span
                                            class="badge badge-info"
                                            v-if="
                                                signature.signature_bill_quotation ==
                                                    1
                                            "
                                            >Bill Quotation</span
                                        >
                                        <span
                                            class="badge badge-info"
                                            v-if="
                                                signature.signature_bill_order ==
                                                    1
                                            "
                                            >Bill Order</span
                                        >
                                        <span
                                            class="badge badge-info"
                                            v-if="
                                                signature.signature_bill_invoice ==
                                                    1
                                            "
                                            >Bill Invoice</span
                                        >
                                        <span
                                            class="badge badge-info"
                                            v-if="
                                                signature.signature_purchase_quotation ==
                                                    1
                                            "
                                            >Purchase Quotation</span
                                        >
                                        <span
                                            class="badge badge-info"
                                            v-if="
                                                signature.signature_purchase_order ==
                                                    1
                                            "
                                            >Purchase Order</span
                                        >
                                        <span
                                            class="badge badge-info"
                                            v-if="
                                                signature.signature_purchase_invoice ==
                                                    1
                                            "
                                            >Purchase Invoice</span
                                        >
                                        <span
                                            class="badge badge-info"
                                            v-if="
                                                signature.signature_receive_payment ==
                                                    1
                                            "
                                            >Receive Payment</span
                                        >
                                        <span
                                            class="badge badge-info"
                                            v-if="
                                                signature.signature_pay_Purchase ==
                                                    1
                                            "
                                            >Pay Purchase</span
                                        >
                                        <span
                                            class="badge badge-info"
                                            v-if="
                                                signature.signature_expense == 1
                                            "
                                            >Expense</span
                                        >
                                        <span
                                            class="badge badge-info"
                                            v-if="
                                                signature.signature_income == 1
                                            "
                                            >Income</span
                                        >
                                        <span
                                            class="badge badge-info"
                                            v-if="
                                                signature.signature_general_ledger ==
                                                    1
                                            "
                                            >General Ledger</span
                                        >
                                        <span
                                            class="badge badge-info"
                                            v-if="
                                                signature.signature_trial_balance ==
                                                    1
                                            "
                                            >Trial Balance</span
                                        >
                                        <span
                                            class="badge badge-info"
                                            v-if="
                                                signature.signature_balance_sheet ==
                                                    1
                                            "
                                            >Balance Sheet</span
                                        >
                                        <span
                                            class="badge badge-info"
                                            v-if="
                                                signature.signature_profit_loss ==
                                                    1
                                            "
                                            >Profit Loss</span
                                        >
                                    </td>
                                </router-link>
                            </tbody>
                            <tbody v-else>
                                <tr class="data-empty">
                                    <td
                                        class="text-center"
                                        colspan="5"
                                        style="opacity:0.8; padding:2.5rem;"
                                    >
                                        <center>
                                            <img
                                                :src="
                                                    '/media/proxima/empty-data.svg'
                                                "
                                                style="height: 100px;"
                                            />
                                        </center>
                                        <br /><span style="opacity:0.6;"
                                            >Data Not Found</span
                                        >
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p
                            class="text-muted font-size-sm d-inline d-sm-none"
                            style="opacity:0.5;"
                        >
                            Swipe left here to see full column >>
                        </p>
                    </div>

                    <paginate
                        v-model="page"
                        :page-count="this.$store.state.signature.last_page"
                        :prev-text="'Prev'"
                        :next-text="'Next'"
                        :container-class="'pagination mt-3 mt-md-0'"
                        :pageClass="'page-item'"
                        :pageLinkClass="'page-link'"
                        :prevClass="'page-item'"
                        :prevLinkClass="'page-link'"
                        :nextClass="'page-item'"
                        :nextLinkClass="'page-link'"
                    >
                    </paginate>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
    name: "signature",
    data() {
        return {
            search: "",
            rows: 20,
            loadingPage: 1,
            sort: "updated_at",
            asc: true
        };
    },
    created() {
        this.getSignature({
            search: this.search,
            rows: this.rows,
            sort: this.sort,
            asc: this.asc
        })
            .then(response => {
                this.loadingPage = 0;
            })
            .catch(error => {
                console.log(error);
                this.loadingPage = 0;
            });
    },
    computed: {
        ...mapState("signature", {
            signatures: state => state.signatures
        }),
        page: {
            get() {
                return this.$store.state.signature.page
                    ? this.$store.state.signature.page
                    : 1;
            },
            set(val) {
                this.$store.commit("news/SET_PAGE", val);
                this.getSignature({
                    search: this.search,
                    rows: this.rows,
                    rowsUpdate: 0,
                    sort: this.sort,
                    asc: this.asc
                });
            }
        }
    },
    watch: {
        search() {
            this.getSignature({
                search: this.search,
                rows: this.rows,
                rowsUpdate: 1,
                sort: this.sort,
                asc: this.asc
            });
        },
        rows() {
            this.getSignature({
                search: this.search,
                rows: this.rows,
                rowsUpdate: 1,
                sort: this.sort,
                asc: this.asc
            });
        }
    },
    methods: {
        ...mapActions("signature", ["getSignature"]),
        sortBy(val) {
            this.asc = this.sort == val ? !this.asc : false;
            this.sort = val;
            this.getSignature({
                search: this.search,
                rows: this.rows,
                rowsUpdate: 1,
                sort: this.sort,
                asc: this.asc
            });
        }
    }
};
</script>
