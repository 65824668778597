<template>
    <div id="tracked-item" class="text-xs">
        <div class="row">
            <div
                class="col-12 font-weight-bold mb-0 py-2"
                style="font-size:15px"
            >
                {{ tracked == 1 ? "Tracked Inventory" : "Untracked Inventory" }}
            </div>
        </div>

        <div class="row-report" v-for="(item, index) in data" :key="index">
            <div class="col-report-sm">
                <p class="m-0">
                    {{ item.item_code }}
                </p>
            </div>
            <div class="col-report-lg">
                <p class="m-0">{{ item.item_name }}</p>
            </div>
            <div class="col-report" v-if="type == 1">
                <p class="m-0 text-right" v-if="item.amountOpening >= 0">
                    {{
                        item.amountOpening != 0
                            ? item.amountOpening
                            : 0 | currency
                    }}
                </p>
                <p class="m-0 text-right" v-if="item.amountOpening < 0">
                    ({{
                        item.amountOpening != 0
                            ? Math.abs(item.amountOpening)
                            : 0 | currency
                    }})
                </p>
            </div>
            <div class="col-report-sm" v-if="type == 2">
                <p class="m-0 text-right" v-if="item.qtyOpening >= 0">
                    {{ item.qtyOpening != 0 ? item.qtyOpening : "-" }}
                </p>
                <p class="m-0 text-right" v-if="item.qtyOpening < 0">
                    ({{
                        item.qtyOpening != 0 ? Math.abs(item.qtyOpening) : "-"
                    }})
                </p>
            </div>
            <div class="col-report-sm" v-if="type == 2">
                <p class="m-0 text-right" v-if="item.amountOpening >= 0">
                    {{
                        item.amountOpening != 0
                            ? item.amountOpening
                            : 0 | currency
                    }}
                </p>
                <p class="m-0 text-right" v-if="item.amountOpening < 0">
                    ({{
                        item.amountOpening != 0
                            ? Math.abs(item.amountOpening)
                            : 0 | currency
                    }})
                </p>
            </div>
            <div class="col-report-sm" v-if="type == 2">
                <p class="m-0 text-right">
                    {{ item.qtyPurchased != 0 ? item.qtyPurchased : "-" }}
                </p>
            </div>
            <div class="col-report-sm" v-if="type == 2">
                <p class="m-0 text-right">
                    {{ item.qtySold != 0 ? "(" + item.qtySold + ")" : "-" }}
                </p>
            </div>
            <div class="col-report-sm" v-if="type == 2">
                <p class="m-0 text-right" v-if="item.qtyAdjusted >= 0">
                    {{ item.qtyAdjusted != 0 ? item.qtyAdjusted : "-" }}
                </p>
                <p class="m-0 text-right" v-if="item.qtyAdjusted < 0">
                    ({{
                        item.qtyAdjusted != 0
                            ? Math.abs(item.qtyAdjusted)
                            : "-"
                    }})
                </p>
            </div>
            <div class="col-report">
                <p class="m-0 text-right">
                    {{ Math.abs(item.amountPurchased) | currency }}
                </p>
            </div>
            <div class="col-report">
                <p class="m-0 text-right">
                    ({{ Math.abs(item.amountMutationSold) | currency }})
                </p>
            </div>
            <div class="col-report">
                <p class="m-0 text-right">
                    {{ Math.abs(item.amountAdjusted) | currency }}
                </p>
            </div>
            <div class="col-report" v-if="type == 2">
                <p class="m-0 text-right">
                    {{ Math.abs(item.amountSold) | currency }}
                </p>
            </div>
            <div class="col-report" v-if="type == 2">
                <p class="m-0 text-right">
                    {{ item.netProfit | currency }}
                </p>
            </div>
            <div class="col-report-2sm" v-if="type == 2">
                <p class="m-0 text-right">
                    {{ Math.abs(item.marginProfit) }}%
                </p>
            </div>
            <div class="col-report">
                <p class="m-0 text-right" v-if="item.stockOnDate >= 0">
                    {{ item.stockOnDate != 0 ? item.stockOnDate : "-" }}
                </p>
                <p class="m-0 text-right" v-if="item.stockOnDate < 0">
                    ({{
                        item.stockOnDate != 0
                            ? Math.abs(item.stockOnDate)
                            : "-"
                    }})
                </p>
            </div>
            <div class="col-report-sm">
                <p class="m-0 text-right" v-if="item.valOnDate >= 0">
                    {{ item.valOnDate != 0 ? item.valOnDate : 0 | currency }}
                </p>
                <p class="m-0 text-right" v-if="item.valOnDate < 0">
                    ({{
                        item.valOnDate != 0
                            ? Math.abs(item.valOnDate)
                            : 0 | currency
                    }})
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "item-list",
    props: ["type", "data", "tracked"],
};
</script>
