<template>
    <main>
        <!-- Hero -->
        <div class="bg-body-light">
            <div class="content content-full">
                <div
                    class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center"
                >
                    <h1 class="flex-sm-fill h3 my-2">
                        Accounting : Start a New Year
                    </h1>
                    <nav
                        class="flex-sm-00-auto ml-sm-3"
                        aria-label="breadcrumb"
                    >
                        <ol class="breadcrumb breadcrumb-alt">
                            <li class="breadcrumb-item" aria-current="page">
                                <router-link
                                    class="link-fx"
                                    :to="{ name: 'home' }"
                                    >Dashboard</router-link
                                >
                            </li>
                            <li class="breadcrumb-item">Start a New Year</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
        <!-- END Hero -->

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 2"
                style="height: 250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <!-- Basic -->
            <div
                class="block col-12 block-rounded block-mode-loading-oneui"
                v-else
            >
                <div class="block-content block-content-full">
                    <div class="row font-size-sm mx-4">
                        <div class="col-8 py-0 pr-3">
                            <div class="row">
                                <p>
                                    <b>Important Notes</b>
                                    <br />1. System will moves the balance of
                                    your current year earning account to your
                                    retained earnings account. <br />2. System
                                    will empty the balance of your income, cost
                                    of sales, expense, other income, and other
                                    expense accounts.
                                    <br />
                                    3. System will prevent create and edit
                                    journal in
                                    {{ date_start | moment("MMMM YYYY") }} until
                                    {{ date_end | moment("MMMM YYYY") }}.
                                    <br />
                                    4. System will keep journal in
                                    {{ date_start | moment("MMMM YYYY") }} until
                                    {{ date_end | moment("MMMM YYYY") }}.
                                    <br />
                                    5. System will automatically make journal
                                    entries as below :
                                    <br />
                                </p>
                            </div>
                        </div>
                        <div class="col-4 py-0">
                            <div class="row">
                                <div class="form-group col-12 py-0">
                                    <label for="example-text-input"
                                        >Date Start</label
                                    >
                                    <date-picker
                                        class="input-group"
                                        v-model="date_start"
                                        @input="changeDate()"
                                        type="month"
                                        format="MMMM YYYY"
                                        valueType="YYYY-MM"
                                        :class="{
                                            'is-invalid': errors.date_start,
                                        }"
                                        :disabled-date="disabledDatePicker"
                                        placeholder="Select year"
                                    ></date-picker>
                                    <p
                                        class="text-danger"
                                        v-if="errors.date_start"
                                    >
                                        {{ errors.date_start[0] }}
                                    </p>
                                </div>
                                <div class="form-group col-12 py-0">
                                    <label for="example-text-input"
                                        >Date End</label
                                    >
                                    <date-picker
                                        class="input-group"
                                        v-model="date_end"
                                        @input="changeDate()"
                                        type="month"
                                        format="MMMM YYYY"
                                        valueType="YYYY-MM"
                                        :class="{
                                            'is-invalid': errors.date_end,
                                        }"
                                        :disabled-date="disabledDatePicker"
                                        placeholder="Select year"
                                    ></date-picker>
                                    <p
                                        class="text-danger"
                                        v-if="errors.date_end"
                                    >
                                        {{ errors.date_end[0] }}
                                    </p>
                                </div>
                                <div class="form-group col-12 py-0">
                                    <button
                                        type="button"
                                        class="btn btn-sm btn-info mb-1"
                                        v-ripple="{ center: true }"
                                        style="width: 100%"
                                        @click.prevent="closing()"
                                        :disabled="loadingPage !== 0"
                                    >
                                        <i class="fa fa-archive mr-1"></i> Close
                                        Year
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="block-content block-content-full pb-5"
                    style="padding-top: 80px"
                    v-if="loadingPage == 1"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Loading</span
                        >
                    </center>
                </div>
                <div
                    class="block-content block-content-full pt-0"
                    v-if="loadingPage == 0"
                >
                    <div v-html="journal"></div>
                </div>
            </div>
            <!-- END Submit -->
        </div>
        <!-- END Basic -->
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import moment from "moment";

export default {
    name: "start-new-year",
    data() {
        return {
            loadingPage: 2,
            date_start: moment().startOf("year").format("YYYY-MM"),
            date_end: moment().endOf("year").format("YYYY-MM"),
            journal: "",
            awaitingSearch: false,
        };
    },
    computed: {
        ...mapState(["errors"]),
        ...mapState("forbiddendate", {
            forbiddenDateLast: (state) => state.forbiddenDateLast,
        }),
    },
    created() {
        this.getClosingJournal({
            date_start: moment(this.date_start).format("YYYY-MM"),
            date_end: moment(this.date_end).format("YYYY-MM"),
        })
            .then((response) => {
                this.journal = response;
                this.loadingPage = 0;
            })
            .catch((error) => {
                this.alert(error.message, 2);
                console.log(error);
                this.loadingPage = 0;
            });
        this.getForbiddenDate();
    },
    methods: {
        //VUEX
        ...mapMutations(["CLEAR_ERRORS"]),
        ...mapActions("forbiddendate", ["getForbiddenDate"]),
        ...mapActions("startnewyear", [
            "getClosingJournal",
            "doClosingJournal",
        ]),

        //METHOD
        disabledDatePicker(date) {
            const lastDisableDate = new Date(this.forbiddenDateLast);
            lastDisableDate.setHours(0, 0, 0, 0);
            return date <= lastDisableDate;
        },
        changeDate() {
            this.loadingPage = 1;
            if (!this.awaitingSearch) {
                setTimeout(() => {
                    this.getClosingJournal({
                        date_start: moment(this.date_start).format("YYYY-MM"),
                        date_end: moment(this.date_end).format("YYYY-MM"),
                    })
                        .then((response) => {
                            this.journal = response;
                            this.loadingPage = 0;
                        })
                        .catch((error) => {
                            this.alert(error.message, 2);
                            console.log(error);
                            this.loadingPage = 0;
                        });
                    this.awaitingSearch = false;
                }, 3000);
            }
            this.awaitingSearch = true;
        },
        closing() {
            this.loadingPage = 2;
            this.doClosingJournal({
                date_start: moment(this.date_start).format("YYYY-MM"),
                date_end: moment(this.date_end).format("YYYY-MM"),
            })
                .then((response) => {
                    this.alert("Closing Year is Completed", 1);
                    this.journal = response.data;
                    this.loadingPage = 0;
                })
                .catch((error) => {
                    this.alert(error.message, 2);
                    console.log(error);
                    this.loadingPage = 0;
                });
        },
    },
};
</script>
