<template>
    <main>
        <hero title="Contact Data" prevBreadcrumb="Dashboard" routeBreadcrumb="home" currentBreadcrumb="Contact Data"></hero>

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 1 || !contacts.data"
                style="height:250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity:0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <!-- Dynamic Table Full -->
            <div class="block block-rounded block-mode-loading-oneui" v-else>
                <v-tabs background-color="white" style="border-radius: 8px;">
                    <v-tab
                        v-for="item in items"
                        :key="item"
                        @click="typeChange(item)"
                        v-bind:class="{
                            'v-tab--active': item === type,
                            'v-tab--active--false': item !== type
                        }"
                    >
                        {{ item }}
                    </v-tab>
                </v-tabs>
                <div class="block-content block-content-full pb-0 pt-3">
                    <div class="row">
                        <div
                            class="col-12 col-md-4 col-lg-4 col-xl-6 mb-2 mb-sm-0 pb-0"
                        >
                            <label>Show :</label>
                            <select
                                class="form-control-sm custom-select"
                                style="max-width:115px"
                                v-model="rows"
                            >
                                <option value="10">10 Rows</option>
                                <option value="20">20 Rows</option>
                                <option value="50">50 Rows</option>
                                <option value="100">100 Rows</option>
                                <option value="All">All Rows</option>
                            </select>
                        </div>
                        <div class="col-12 col-md-8 col-lg-8 col-xl-6 pb-0">
                            <input
                                type="text"
                                class="form-control form-control-sm col-12 col-md-6 float-right ml-1 mt-1 search-index"
                                name="example-text-input"
                                placeholder="Search.."
                                v-model="search"
                            />
                            <router-link
                                type="button"
                                class="btn btn-sm btn-info float-right m-1"
                                :to="{ name: 'contact-create' }"
                                tag="button"
                                v-ripple="{ center: true }"
                            >
                                <i class="fa fa-plus mr-1"></i> Add Data
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="block-content block-content-full">
                    <div class="table-responsive">
                        <table class="table  table-vcenter table-hover">
                            <thead style="background-color: whitesmoke;">
                                <tr>
                                    <th class="table-header-relative">No.</th>
                                    <th
                                        v-if="type == 'All Contact'"
                                        class="table-header-relative column-minwidth-100"
                                    >
                                        Type
                                    </th>
                                    <th
                                        class="table-header-relative column-minwidth-150"
                                    >
                                        Contact Name
                                    </th>
                                    <th
                                        class="table-header-relative column-minwidth-150"
                                    >
                                        Address
                                    </th>
                                    <th
                                        class="table-header-relative sort-head column-minwidth-150"
                                    >
                                        Email
                                    </th>
                                    <th
                                        class="table-header-relative sort-head column-minwidth-150"
                                    >
                                        Contact
                                    </th>
                                </tr>
                            </thead>
                            <tbody
                                v-if="contacts.data && contacts.data.length > 0"
                            >
                                <tr v-if="loadingPage == 2">
                                    <td
                                        colspan="6"
                                        v-if="type == 'All Contact'"
                                    >
                                        <grid-loader
                                            class="mx-auto mt-5"
                                            :color="'#70B9EB'"
                                            :size="'10px'"
                                        ></grid-loader>
                                        <center class="mt-2 mb-5">
                                            <span
                                                class="text-center"
                                                style="opacity:0.7"
                                                >Loading</span
                                            >
                                        </center>
                                    </td>
                                    <td colspan="5" v-else>
                                        <grid-loader
                                            class="mx-auto mt-5"
                                            :color="'#70B9EB'"
                                            :size="'10px'"
                                        ></grid-loader>
                                        <center class="mt-2 mb-5">
                                            <span
                                                class="text-center"
                                                style="opacity:0.7"
                                                >Loading</span
                                            >
                                        </center>
                                    </td>
                                </tr>
                                <router-link
                                    v-else
                                    :to="{
                                        name: 'contact-edit',
                                        params: { id: contact.id }
                                    }"
                                    tag="tr"
                                    v-for="(contact, index) in contacts.data"
                                    :key="index"
                                >
                                    <td class="font-size-sm" align="center">
                                        {{ ++index }}
                                    </td>
                                    <td
                                        class="font-size-sm text-center"
                                        v-if="type == 'All Contact'"
                                    >
                                        {{
                                            contact.contact_type == 1
                                                ? "Customer"
                                                : ""
                                        }}
                                        {{
                                            contact.contact_type == 2
                                                ? "Supplier"
                                                : ""
                                        }}
                                    </td>
                                    <td class="font-size-sm">
                                        {{ contact.contact_name }}
                                    </td>
                                    <td class="font-size-sm">
                                        {{ contact.contact_address }}
                                    </td>
                                    <td class="font-size-sm">
                                        {{
                                            contact.contact_email != null
                                                ? contact.contact_email
                                                : "-"
                                        }}
                                    </td>
                                    <td class="font-size-sm">
                                        {{
                                            contact.contact_contactname != null
                                                ? contact.contact_contactname
                                                : "-"
                                        }}
                                        <br />
                                        {{ contact.contact_contactphone }}
                                    </td>
                                </router-link>
                            </tbody>
                            <tbody v-else>
                                <tr class="data-empty">
                                    <td
                                        class="text-center"
                                        colspan="6"
                                        v-if="type == 'All Contact'"
                                        style="opacity:0.8; padding:2.5rem;"
                                    >
                                        <center>
                                            <img
                                                :src="
                                                    '/media/proxima/empty-data.svg'
                                                "
                                                style="height: 100px;"
                                            />
                                        </center>
                                        <br /><span style="opacity:0.6;"
                                            >Data Not Found</span
                                        >
                                    </td>
                                    <td
                                        class="text-center"
                                        colspan="5"
                                        v-else
                                        style="opacity:0.8; padding:2.5rem;"
                                    >
                                        <center>
                                            <img
                                                :src="
                                                    '/media/proxima/empty-data.svg'
                                                "
                                                style="height: 100px;"
                                            />
                                        </center>
                                        <br /><span style="opacity:0.6;"
                                            >Data Not Found</span
                                        >
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p
                            class="text-muted font-size-sm d-inline d-sm-none"
                            style="opacity:0.5;"
                        >
                            Swipe left here to see full column >>
                        </p>
                    </div>

                    <paginate
                        v-model="page"
                        :page-count="this.$store.state.contact.last_page"
                        :prev-text="'Prev'"
                        :next-text="'Next'"
                        :container-class="'pagination mt-3 mt-md-0'"
                        :pageClass="'page-item'"
                        :pageLinkClass="'page-link'"
                        :prevClass="'page-item'"
                        :prevLinkClass="'page-link'"
                        :nextClass="'page-item'"
                        :nextLinkClass="'page-link'"
                    ></paginate>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
    name: "contact",
    data() {
        return {
            search: "",
            awaitingSearch: false,
            rows: 20,
            type: "All Contact",
            items: ["All Contact", "Customer", "Supplier"],
            loadingPage: 1
        };
    },
    created() {
        this.getContact({
            search: this.search,
            rows: this.rows,
            type: this.type
        })
            .then(response => {
                this.loadingPage = 0;
            })
            .catch(error => {
                console.log(error);
                this.loadingPage = 0;
            });
    },
    computed: {
        ...mapState("contact", {
            contacts: state => state.contacts
        }),
        page: {
            get() {
                return this.$store.state.contact.page
                    ? this.$store.state.contact.page
                    : 1;
            },
            set(val) {
                this.$store.commit("contact/SET_PAGE", val);
                this.getContact({
                    search: this.search,
                    rows: this.rows,
                    rowsUpdate: 0,
                    type: this.type
                });
            }
        }
    },
    watch: {
        search() {
            this.loadingPage = 2;
            if (!this.awaitingSearch) {
                setTimeout(() => {
                    this.getContact({
                        search: this.search,
                        rows: this.rows,
                        rowsUpdate: 1,
                        type: this.type
                    })
                        .then(response => {
                            this.loadingPage = 0;
                        })
                        .catch(error => {
                            console.log(error);
                            this.loadingPage = 0;
                        });
                    this.awaitingSearch = false;
                }, 1500);
            }
            this.awaitingSearch = true;
        },
        rows() {
            this.getContact({
                search: this.search,
                rows: this.rows,
                rowsUpdate: 1,
                type: this.type
            })
                .then(response => {
                    this.loadingPage = 0;
                })
                .catch(error => {
                    console.log(error);
                    this.loadingPage = 0;
                });
        }
    },
    methods: {
        ...mapActions("contact", ["getContact"]),
        typeChange(val) {
            this.loadingPage = 2;
            this.type = val;
            this.getContact({
                search: this.search,
                rows: this.rows,
                type: this.type
            })
                .then(response => {
                    this.loadingPage = 0;
                })
                .catch(error => {
                    console.log(error);
                    this.loadingPage = 0;
                });
        }
    }
};
</script>
