<template>
    <main>
        <!-- Hero -->
        <div class="bg-body-light">
            <div class="content content-full">
                <div
                    class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center"
                >
                    <h1 class="flex-sm-fill h3 my-2">
                        Accounting : Outgoing Item
                    </h1>
                    <nav
                        class="flex-sm-00-auto ml-sm-3"
                        aria-label="breadcrumb"
                    >
                        <ol class="breadcrumb breadcrumb-alt">
                            <li class="breadcrumb-item" aria-current="page">
                                <router-link
                                    class="link-fx"
                                    :to="{ name: 'home' }"
                                    >Dashboard</router-link
                                >
                            </li>
                            <li class="breadcrumb-item">Outgoing Item</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
        <!-- END Hero -->

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 1 || !mutations.data"
                style="height: 250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <!-- Dynamic Table Full -->
            <div class="block block-rounded block-mode-loading-oneui" v-else>
                <div class="block-content block-content-full pb-0 pt-3">
                    <div class="row">
                        <div
                            class="col-12 col-md-7 col-lg-7 col-xl-7 mb-2 mb-sm-0 pb-0 px-0 d-md-none d-inline"
                        >
                            <div style="width: 100%">
                                <selectize
                                    class=""
                                    v-model="warehouse"
                                    placeholder="Select Warehouse"
                                    @input="getData()"
                                    :disabled="loadingPage != 0"
                                >
                                    <option value="0">All Warehouse</option>
                                    <option
                                        v-for="warehouse in warehouses"
                                        :value="warehouse.id"
                                        :key="warehouse.id"
                                    >
                                        {{ warehouse.warehouse_name }}
                                    </option>
                                </selectize>
                            </div>
                        </div>
                        <div
                            class="col-12 col-md-7 col-lg-7 col-xl-7 mb-2 mb-sm-0 pb-0 d-md-inline d-none"
                        >
                            <div style="width: 250px">
                                <selectize
                                    class=""
                                    v-model="warehouse"
                                    placeholder="Select Warehouse"
                                    @input="getData()"
                                    :disabled="loadingPage != 0"
                                >
                                    <option value="0">All Warehouse</option>
                                    <option
                                        v-for="warehouse in warehouses"
                                        :value="warehouse.id"
                                        :key="warehouse.id"
                                    >
                                        {{ warehouse.warehouse_name }}
                                    </option>
                                </selectize>
                            </div>
                        </div>
                        <div
                            class="col-12 col-md-5 col-lg-5 col-xl-5 pb-0 pt-0 pt-md-3"
                        >
                            <input
                                type="text"
                                class="form-control form-control col-12 col-md-8 float-right search-index"
                                name="example-text-input"
                                placeholder="Search.."
                                v-model="search"
                            />
                        </div>
                    </div>
                </div>
                <div class="block-content block-content-full py-2">
                    <v-app>
                        <v-data-table
                            :headers="headers"
                            :items="datas"
                            :search="search"
                            :hide-default-footer="true"
                            :items-per-page="50"
                            :mobile-breakpoint="0"
                        >
                            <template
                                v-slot:top="{
                                    pagination,
                                    options,
                                    updateOptions,
                                }"
                            >
                                <div class="row">
                                    <div
                                        class="col-12 col-md-7 mb-2 mb-sm-0 pt-0 pb-2"
                                    >
                                        <v-layout justify-start>
                                            <v-data-footer
                                                :pagination="pagination"
                                                :options="options"
                                                @update:options="updateOptions"
                                                :items-per-page-options="[
                                                    10,
                                                    30,
                                                    50,
                                                    100,
                                                    -1,
                                                ]"
                                                items-per-page-text="Rows :"
                                            />
                                        </v-layout>
                                    </div>
                                    <div
                                        class="col-12 col-md-5 col-lg-5 col-xl-5 pb-0 pt-2"
                                    >
                                        <button
                                            type="button"
                                            class="btn btn-sm btn-alt-secondary float-right m-1 mb-5 mb-md-0"
                                            tag="button"
                                            v-ripple="{ center: true }"
                                            style="vertical-align: middle"
                                            @click="getData()"
                                            :disabled="loadingPage == 2"
                                        >
                                            <i class="fa fa-sync"></i>
                                        </button>
                                        <router-link
                                            type="button"
                                            class="btn btn-sm btn-info float-right btn-adjust-1"
                                            :to="{
                                                name: 'outgoing-item-create',
                                            }"
                                            tag="button"
                                            v-ripple="{ center: true }"
                                        >
                                            <i class="fa fa-plus mr-1"></i>New
                                            Outgoing Item
                                        </router-link>
                                    </div>
                                </div>
                            </template>
                            <template v-slot:body="{ items }">
                                <tbody v-if="loadingPage == 2">
                                    <tr class="data-empty">
                                        <td colspan="7" class="py-2">
                                            <grid-loader
                                                class="mx-auto mt-5 pt-5"
                                                :color="'#70B9EB'"
                                                :size="'10px'"
                                            ></grid-loader>
                                            <center class="mt-2 mb-5 pb-5">
                                                <span
                                                    class="text-center"
                                                    style="opacity: 0.7"
                                                    >Loading</span
                                                >
                                            </center>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody
                                    v-if="items.length > 0 && loadingPage == 0"
                                >
                                    <tr
                                        v-for="(mutation, index) in items"
                                        :key="index"
                                    >
                                        <td
                                            @click="
                                                toDetail(mutation.mutationId)
                                            "
                                            class="text-center font-size-sm"
                                        >
                                            {{ ++index }}
                                        </td>
                                        <td
                                            @click="
                                                toDetail(mutation.mutationId)
                                            "
                                            class="font-size-sm"
                                        >
                                            {{ mutation.mutationDate }}
                                        </td>
                                        <td
                                            @click="
                                                toDetail(mutation.mutationId)
                                            "
                                            class="font-size-sm"
                                        >
                                            {{ mutation.mutationType }}
                                        </td>
                                        <td
                                            @click="
                                                toDetail(mutation.mutationId)
                                            "
                                            class="font-size-sm"
                                        >
                                            {{ mutation.mutationNo }}
                                        </td>
                                        <td
                                            @click="
                                                toDetail(mutation.mutationId)
                                            "
                                            class="font-size-sm"
                                        >
                                            {{ mutation.mutationMemo }}
                                        </td>
                                        <td
                                            @click="
                                                toDetail(mutation.mutationId)
                                            "
                                            class="font-size-sm"
                                        >
                                            {{ mutation.mutationWarehouse }}
                                        </td>
                                        <!-- DROPDOWN AURORA -->
                                        <td align="right">
                                            <div
                                                class="dropdown dropleft push m-0"
                                            >
                                                <button
                                                    type="button"
                                                    class="btn btn-sm btn-alt-secondary btn-transparent btn-rounded text--lighten-4"
                                                    id="dropdown-dropleft"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    <i
                                                        class="fa fa-fw fa-ellipsis-v"
                                                    ></i>
                                                </button>
                                                <div
                                                    class="dropdown-menu dropdown-menu-rounded font-size-sm px-1"
                                                    aria-labelledby="dropdown-dropleft"
                                                >
                                                    <button
                                                        @click="
                                                            toDetail(
                                                                mutation.mutationId
                                                            )
                                                        "
                                                        class="btn btn-sm btn-transparent btn-alt-info dropdown-item-horizontal text-info"
                                                        v-ripple="{
                                                            center: true,
                                                        }"
                                                        style="
                                                            position: inline-block;
                                                        "
                                                    >
                                                        <i
                                                            class="si si-magnifier mr-1"
                                                        ></i>
                                                        <span
                                                            class="d-none d-md-inline"
                                                            >Detail</span
                                                        >
                                                    </button>
                                                    <button
                                                        @click="
                                                            toJournalVoucher(
                                                                mutation.mutationJournalId
                                                            )
                                                        "
                                                        class="btn btn-sm btn-transparent btn-alt-warning dropdown-item-horizontal text-warning"
                                                        v-ripple="{
                                                            center: true,
                                                        }"
                                                        style="
                                                            position: inline-block;
                                                        "
                                                    >
                                                        <i
                                                            class="si si-notebook mr-1"
                                                        ></i>
                                                        <span
                                                            class="d-none d-md-inline"
                                                            >Journal</span
                                                        >
                                                    </button>
                                                    <button
                                                        @click="
                                                            toEdit(
                                                                mutation.mutationId
                                                            )
                                                        "
                                                        class="btn btn-sm btn-transparent btn-alt-success dropdown-item-horizontal text-success"
                                                        v-ripple="{
                                                            center: true,
                                                        }"
                                                        style="
                                                            position: inline-block;
                                                        "
                                                    >
                                                        <i
                                                            class="si si-pencil mr-1"
                                                        ></i>
                                                        <span
                                                            class="d-none d-md-inline"
                                                            >Edit</span
                                                        >
                                                    </button>
                                                    <!-- <button
                                                        class="btn btn-sm btn-transparent btn-alt-danger text-danger dropdown-item-horizontal"
                                                        v-ripple="{
                                                            center: true,
                                                        }"
                                                        @click.prevent="
                                                            deleteData(
                                                                mutation.mutationId
                                                            )
                                                        "
                                                    >
                                                        <i
                                                            class="si si-trash mr-1"
                                                        ></i>
                                                        <span
                                                            class="d-none d-md-inline"
                                                            >Delete</span
                                                        >
                                                    </button> -->
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody
                                    v-if="items.length <= 0 && loadingPage == 0"
                                >
                                    <tr class="data-empty">
                                        <td
                                            class="text-center"
                                            colspan="7"
                                            style="
                                                opacity: 0.8;
                                                padding: 2.5rem;
                                            "
                                        >
                                            <center>
                                                <img
                                                    :src="'/media/proxima/empty-data.svg'"
                                                    style="height: 100px"
                                                />
                                            </center>
                                            <br /><span style="opacity: 0.6"
                                                >Data Not Found</span
                                            >
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-data-table>
                    </v-app>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";

export default {
    name: "outgoing-item",
    data() {
        return {
            search: "",
            awaitingSearch: false,
            rows: "All",
            loadingPage: 1,
            warehouse: "0",
            type: "OutMutation",
            amountExp: 0,
            totalItem: 0,
            headers: [
                { text: "No.", align: "center", sortable: false },
                { text: "Date", align: "start", value: "mutationDate" },
                { text: "Type", align: "start", value: "mutationType" },
                { text: "Mutation No.", align: "start", value: "mutationNo" },
                { text: "Memo", align: "start", value: "mutationMemo" },
                {
                    text: "Warehouse",
                    align: "start",
                    value: "mutationWarehouse",
                },
                { text: "", sortable: false },
            ],
            datas: [],
        };
    },
    created() {
        this.getWarehouseAll();
        this.getData();
        this.$session.remove("item_detail");
    },
    computed: {
        ...mapState("mutation", { mutations: (state) => state.mutations }),
        ...mapState("warehouse", { warehouses: (state) => state.warehouses }),
        ...mapState(["auth"]),
    },
    methods: {
        ...mapActions("mutation", ["getMutation", "removeMutation"]),
        ...mapActions("itemcategory", ["getCategory"]),
        ...mapActions("warehouse", ["getWarehouseAll"]),
        getData() {
            this.loadingPage = 2;
            this.datas = [];
            this.getMutation({
                rows: this.rows,
                type: this.type,
                warehouse: this.warehouse,
            })
                .then(() => {
                    this.mutations.data.forEach((element) => {
                        let type = "";
                        if (element.mutation_type == 2) {
                            type = "Outgoing Item";
                        } else if (element.mutation_type == 4) {
                            type = "Sales";
                        } else {
                            type = "Stock Opname";
                        }
                        this.datas.push({
                            mutationId: element.id,
                            mutationWarehouse: element.warehouse.warehouse_name,
                            mutationDate: moment(element.mutation_date).format(
                                "DD/MM/YYYY  HH:mm"
                            ),
                            mutationNo: element.mutation_no,
                            mutationMemo: element.mutation_memo,
                            mutationType: type,
                            mutationJournalId: element.journal_id,
                        });
                    });
                    this.loadingPage = 0;
                })
                .catch((error) => {
                    error.response.status == 500
                        ? this.alert(error.response.data.message, 2)
                        : "";
                    error.response.status != 422 && error.response.status != 500
                        ? this.alert(error.message, 2)
                        : "";
                    this.loadingPage = 0;
                    this.totalItem = 0;
                });
        },
        toDetail(val) {
            this.$router.push({
                name: "outgoing-item-detail",
                params: { id: val },
            });
        },
        toEdit(val) {
            this.$router.push({
                name: "outgoing-item-edit",
                params: { id: val },
            });
        },
        toJournalVoucher(val) {
            this.$router.push({
                name: "journal-transaction-voucher",
                params: { id: val },
            });
        },
        deleteData(val) {
            this.$swal({
                title: "Are you sure ?",
                text: "Deleted data cannot be recovery",
                icon: "warning",
                buttons: ["Cancel", "Delete"],
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    this.loadingPage = 1;
                    this.removeMutation(val)
                        .then(() => {
                            this.alert("Data has been deleted !", 1);
                            this.getData();
                            this.loadingPage = 0;
                        })
                        .catch((error) => {
                            error.response.status == 500
                                ? this.alert(error.response.data.message, 2)
                                : "";
                            error.response.status != 422 &&
                            error.response.status != 500
                                ? this.alert(error.message, 2)
                                : "";
                            this.loadingPage = 0;
                        });
                }
            });
        },
    },
};
</script>
