var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container"},[_c('div',{staticClass:"hero-static d-flex align-items-center"},[_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"bg-white"},[_c('div',{staticClass:"content content-full"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-8 col-lg-6 col-xl-4 py-4"},[_c('div',{staticClass:"text-center"},[_c('p',{staticClass:"mb-3"},[_c('img',{staticStyle:{"margin-bottom":"5px","margin-right":"2px"},attrs:{"src":'/media/proxima/logotype@1x.png',"height":"45px"}})]),_vm._v(" "),_c('h1',{staticClass:"h4 mb-1"},[_vm._v("Sign In")]),_vm._v(" "),_c('h2',{staticClass:"h6 font-w400 text-muted mb-3"},[_vm._v("\n                                    Sign In to Your Proxima Account\n                                ")]),_vm._v(" "),(
                                        this.$route.params.afterRegister ==
                                            1
                                    )?_c('div',{staticClass:"alert alert-info",attrs:{"role":"alert"}},[_vm._m(0),_vm._v(" "),_c('p',{staticClass:"mb-0"},[_vm._v("\n                                        Registrasi berhasil, silahkan login\n                                        menggunakan akun anda\n                                    ")])]):_vm._e()]),_vm._v(" "),_c('form',{staticClass:"js-validation-signin"},[_c('div',{staticClass:"py-3"},[_c('div',{staticClass:"form-group has-feedback"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.username),expression:"data.username"}],staticClass:"form-control form-control-lg form-control-alt",class:{
                                                'is-invalid':
                                                    _vm.errors.username ||
                                                    _vm.errors.invalid
                                            },staticStyle:{"border-color":"#d5d5d5"},attrs:{"type":"text","placeholder":"Username"},domProps:{"value":(_vm.data.username)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.preventDefault();},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "username", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"glyphicon glyphicon-envelope form-control-feedback"}),_vm._v(" "),(_vm.errors.username)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                            "+_vm._s(_vm.errors.username[0])+"\n                                        ")]):_vm._e()]),_vm._v(" "),(!_vm.passwordHidden)?_c('div',{staticClass:"form-group has-feedback noselect"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.password),expression:"data.password"}],staticClass:"form-control form-control-lg form-control-alt d-inline",class:{
                                                'is-invalid':
                                                    _vm.errors.password ||
                                                    _vm.errors.invalid
                                            },staticStyle:{"border-color":"#d5d5d5","margin-right":"-35px","padding-right":"35px"},attrs:{"type":"text","placeholder":"Password","autocomplete":"off"},domProps:{"value":(_vm.data.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "password", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"display-eye fa fa-eye-slash text-pointer",on:{"click":_vm.hidePassword}}),_vm._v(" "),(_vm.errors.password)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                            "+_vm._s(_vm.errors.password[0])+"\n                                        ")]):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.passwordHidden)?_c('div',{staticClass:"form-group has-feedback noselect"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.password),expression:"data.password"}],staticClass:"form-control form-control-lg form-control-alt d-inline",class:{
                                                'is-invalid':
                                                    _vm.errors.password ||
                                                    _vm.errors.invalid
                                            },staticStyle:{"border-color":"#d5d5d5","margin-right":"-35px","padding-right":"35px"},attrs:{"type":"password","placeholder":"Password","autocomplete":"off"},domProps:{"value":(_vm.data.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "password", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"display-eye fa fa-eye text-pointer",on:{"click":_vm.showPassword}}),_vm._v(" "),(_vm.errors.password)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                            "+_vm._s(_vm.errors.password[0])+"\n                                        ")]):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.errors.invalid)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                        "+_vm._s(_vm.errors.invalid)+"\n                                    ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"d-md-flex align-items-md-center justify-content-md-between"},[_c('div',{staticClass:"custom-control custom-switch"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                        _vm.data.remember_me
                                                    ),expression:"\n                                                        data.remember_me\n                                                    "}],staticClass:"custom-control-input",attrs:{"type":"checkbox","id":"login-remember","autocomplete":"off"},domProps:{"checked":Array.isArray(
                                                        _vm.data.remember_me
                                                    )?_vm._i(
                                                        _vm.data.remember_me
                                                    ,null)>-1:(
                                                        _vm.data.remember_me
                                                    )},on:{"change":function($event){var $$a=
                                                        _vm.data.remember_me
                                                    ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.data, "remember_me", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.data, "remember_me", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.data, "remember_me", $$c)}}}}),_vm._v(" "),_c('label',{staticClass:"custom-control-label font-w400",attrs:{"for":"login-remember"}},[_vm._v("Remember Me")])]),_vm._v(" "),_c('div',{staticClass:"py-2"})])])]),_vm._v(" "),_c('div',{staticClass:"form-group row justify-content-center mb-0"},[_c('div',{staticClass:"col-12"},[(
                                                !_vm.loadingPage ||
                                                    _vm.errors.password ||
                                                    _vm.errors.username ||
                                                    _vm.errors.invalid
                                            )?_c('button',{staticClass:"btn btn-block btn-sm btn-info",attrs:{"type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.postLogin($event)}}},[_c('i',{staticClass:"fa fa-fw fa-sign-in-alt mr-1"}),_vm._v("\n                                            Sign In\n                                        ")]):_c('div',{staticClass:"block-content block-content-full pb-0"},[_c('div',{staticClass:"spinner-border spinner-border-lg text-info mx-auto",staticStyle:{"display":"flex","justify-content":"center"},attrs:{"role":"status"}})])]),_vm._v(" "),_c('div',{staticClass:"col-6 text-center footer-lane-right",staticStyle:{}},[_c('span',[_vm._v("\n                                            Belum Punya Akun ?\n                                            "),_c('br'),_vm._v(" "),_c('router-link',{staticClass:"text-info font-size-sm",attrs:{"to":{
                                                    name: 'register'
                                                }}},[_vm._v("Daftar disini\n                                            ")])],1)]),_vm._v(" "),_vm._m(1)])])])])])]),_vm._v(" "),_vm._m(2)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"alert","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-6 text-center"},[_c('span',[_vm._v("\n                                            Ingin Berlangganan ?\n                                            "),_c('br'),_vm._v(" "),_c('a',{staticClass:"text-info font-size-sm",attrs:{"href":"https://tripasysfo.com/proxima#price"}},[_vm._v("Registrasi disini\n                                            ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"font-size-sm text-center text-muted py-3"},[_c('strong',[_vm._v("Proxima - Aquarius")]),_vm._v(" © Tripasysfo\n                Development\n            ")])}]

export { render, staticRenderFns }