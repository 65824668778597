import Vue from "vue";
import $axios from "../api.js";
import SecureLS from "secure-ls";
var ls = new SecureLS({ isCompression: false, encryptionSecret: "d3v3L0P3r!" });

const state = () => ({
    data: ls.get("_ath_"),
    role: ls.get("_rl_"),
    daylimit: ls.get("_dl_"),
    type: ls.get("_tp_")
});

const mutations = {};

const actions = {
    submit({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .post("/login", payload)
                .then(response => {
                    //KEMUDIAN JIKA RESPONNYA SUKSES
                    if (response.data.status == "success") {
                        if (
                            response.data.daylimit <= 0 &&
                            response.data.type == "Trial"
                        ) {
                            dispatch("DO_LOGOUT_LIMIT", null, { root: true });
                            resolve(response.data);
                        } else {
                            //get token user login
                            localStorage.setItem("_tk__", response.data.token);
                            commit("SET_TOKEN", response.data.token, {
                                root: true
                            });
                            //get data user login
                            ls.set("_ath_", response.data.data);
                            ls.set("_rl_", response.data.role);
                            ls.set("_dl_", response.data.daylimit);
                            ls.set("_tp_", response.data.type);
                            commit(
                                "SET_AUTH",
                                {
                                    data: response.data.data,
                                    role: response.data.role,
                                    daylimit: response.data.daylimit,
                                    type: response.data.type
                                },
                                { root: true }
                            );
                        }
                    } else {
                        commit(
                            "SET_ERRORS",
                            {
                                invalid:
                                    "These credentials do not match our records"
                            },
                            { root: true }
                        );
                    }
                    //JANGAN LUPA UNTUK MELAKUKAN RESOLVE AGAR DIANGGAP SELESAI
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    if (error.response.status == 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                    if (error.response.status == 401) {
                        Vue.$swal({
                            title: "You Account have been suspended",
                            text: "Contact us to Re-Activate your Account",
                            icon: "warning"
                        });
                    }
                });
        });
    },
    clearStorage({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .get("/clear-storage")
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
