<template>
    <main>
        <!-- Hero -->
        <div class="bg-body-light">
            <div class="content content-full">
                <div
                    class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center"
                >
                    <h1 class="flex-sm-fill h3 my-2">
                        Accounting : Inventory Type
                    </h1>
                    <nav
                        class="flex-sm-00-auto ml-sm-3"
                        aria-label="breadcrumb"
                    >
                        <ol class="breadcrumb breadcrumb-alt">
                            <li class="breadcrumb-item" aria-current="page">
                                <router-link
                                    class="link-fx"
                                    :to="{ name: 'home' }"
                                    >Home</router-link
                                >
                            </li>
                            <li class="breadcrumb-item">Inventory Type</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
        <!-- END Hero -->

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 1 || !types.data"
                style="height: 250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <!-- Dynamic Table Full -->
            <div class="block block-rounded block-mode-loading-oneui" v-else>
                <div class="block-content block-content-full pt-3">
                    <v-app>
                        <v-data-table
                            :headers="headers"
                            :items="datas"
                            :search="search"
                            :hide-default-footer="true"
                            :items-per-page="50"
                            :mobile-breakpoint="0"
                        >
                            <template
                                v-slot:top="{
                                    pagination,
                                    options,
                                    updateOptions
                                }"
                            >
                                <div class="row">
                                    <div
                                        class="col-12 col-md-5 mb-2 mb-sm-0 pt-0 pb-2"
                                    >
                                        <v-layout justify-start>
                                            <v-data-footer
                                                :pagination="pagination"
                                                :options="options"
                                                @update:options="updateOptions"
                                                :items-per-page-options="[
                                                    10,
                                                    30,
                                                    50,
                                                    100,
                                                    -1
                                                ]"
                                                items-per-page-text="Rows :"
                                            />
                                        </v-layout>
                                    </div>
                                    <div
                                        class="col-12 col-md-7 col-lg-7 col-xl-7 pb-0 pt-2"
                                    >
                                        <input
                                            type="text"
                                            class="form-control form-control-sm col-12 col-md-6 float-right ml-1 mt-1 search-index"
                                            name="example-text-input"
                                            placeholder="Search.."
                                            v-model="search"
                                        />
                                        <router-link
                                            type="button"
                                            class="btn btn-sm btn-info float-right btn-adjust-1"
                                            :to="{
                                                name: 'item-type-create'
                                            }"
                                            tag="button"
                                            v-ripple="{ center: true }"
                                        >
                                            <i class="fa fa-plus mr-1"></i> New
                                            Type
                                        </router-link>
                                    </div>
                                </div>
                            </template>
                            <template v-slot:body="{ items }">
                                <tbody v-if="loadingPage == 2">
                                    <tr class="data-empty">
                                        <td colspan="5" class="py-2">
                                            <grid-loader
                                                class="mx-auto mt-5 pt-5"
                                                :color="'#70B9EB'"
                                                :size="'10px'"
                                            ></grid-loader>
                                            <center class="mt-2 mb-5 pb-5">
                                                <span
                                                    class="text-center"
                                                    style="opacity: 0.7"
                                                    >Loading</span
                                                >
                                            </center>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody
                                    v-if="items.length > 0 && loadingPage == 0"
                                >
                                    <tr
                                        v-for="(type, index) in items"
                                        :key="index"
                                    >
                                        <td
                                            @click="toEdit(type.typeId)"
                                            class="text-center font-size-sm"
                                        >
                                            {{ ++index }}
                                        </td>
                                        <td
                                            @click="toEdit(type.typeId)"
                                            class="font-size-sm"
                                        >
                                            {{ type.typeName }}
                                        </td>
                                        <td
                                            @click="toEdit(type.typeId)"
                                            class="text-center font-size-sm"
                                        >
                                            <span
                                                class="badge badge-info mr-1"
                                                v-if="type.typeInventory === 1"
                                                >Active</span
                                            >
                                            <span
                                                class="badge badge-secondary mr-1"
                                                v-else
                                                >Inactive</span
                                            >
                                        </td>
                                        <td
                                            @click="toEdit(type.typeId)"
                                            class="text-center font-size-sm"
                                        >
                                            <span
                                                class="badge badge-info mr-1"
                                                v-if="type.typePurchase === 1"
                                                >Active</span
                                            >
                                            <span
                                                class="badge badge-secondary mr-1"
                                                v-else
                                                >Inactive</span
                                            >
                                        </td>
                                        <td
                                            @click="toEdit(type.typeId)"
                                            class="text-center font-size-sm"
                                        >
                                            <span
                                                class="badge badge-info mr-1"
                                                v-if="type.typeSell === 1"
                                                >Active</span
                                            >
                                            <span
                                                class="badge badge-secondary mr-1"
                                                v-else
                                                >Inactive</span
                                            >
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody
                                    v-if="items.length <= 0 && loadingPage == 0"
                                >
                                    <tr class="data-empty">
                                        <td
                                            class="text-center"
                                            colspan="5"
                                            style="
                                                opacity: 0.8;
                                                padding: 2.5rem;
                                            "
                                        >
                                            <center>
                                                <img
                                                    :src="
                                                        '/media/proxima/empty-data.svg'
                                                    "
                                                    style="height: 100px"
                                                />
                                            </center>
                                            <br /><span style="opacity: 0.6"
                                                >Data Not Found</span
                                            >
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-data-table>
                    </v-app>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";

export default {
    name: "item-type",
    data() {
        return {
            search: "",
            awaitingSearch: false,
            rows: "All",
            loadingPage: 1,
            type: "All Type Type",
            headers: [
                { text: "No.", align: "center", sortable: false },
                {
                    text: "Type Name",
                    align: "start",
                    value: "typeName"
                },
                {
                    text: "Inventory Status",
                    align: "center",
                    value: "typeInventory"
                },
                {
                    text: "Purchasing Status",
                    align: "center",
                    value: "typePurchase"
                },
                {
                    text: "Selling Status",
                    align: "center",
                    value: "typeSell"
                }
            ],
            datas: []
        };
    },
    created() {
        this.getData();
        this.$session.remove("item_detail");
    },
    computed: {
        ...mapState("itemtype", {
            types: state => state.types
        }),
        ...mapState(["auth"])
    },
    methods: {
        ...mapActions("itemtype", ["getType", "removeType"]),
        getData() {
            this.loadingPage = 2;
            this.datas = [];
            this.getType({ rows: this.rows, type: this.type })
                .then(() => {
                    this.types.data.forEach(element => {
                        this.datas.push({
                            typeId: element.id,
                            typeName: element.type_name,
                            typeSell: element.is_sell,
                            typePurchase: element.is_purchase,
                            typeInventory: element.is_inventory
                        });
                    });
                    this.loadingPage = 0;
                })
                .catch(error => {
                    error.response.status == 500
                        ? this.alert(error.response.data.message, 2)
                        : "";
                    error.response.status != 422 && error.response.status != 500
                        ? this.alert(error.message, 2)
                        : "";
                    this.loadingPage = 0;
                    this.totalType = 0;
                });
        },
        toEdit(val) {
            this.$router.push({
                name: "item-type-edit",
                params: { id: val }
            });
        },
        toJournalVoucher(val) {
            this.$router.push({
                name: "journal-transaction-voucher",
                params: { id: val }
            });
        },
        deleteData(val) {
            this.$swal({
                title: "Are you sure ?",
                text: "Deleted data cannot be recovery",
                icon: "warning",
                buttons: ["Cancel", "Delete"],
                dangerMode: true
            }).then(willDelete => {
                if (willDelete) {
                    this.loadingPage = 1;
                    this.removeType(val)
                        .then(() => {
                            this.alert("Data has been deleted !", 1);
                            this.getData();
                            this.loadingPage = 0;
                        })
                        .catch(error => {
                            error.response.status == 500
                                ? this.alert(error.response.data.message, 2)
                                : "";
                            error.response.status != 422 &&
                            error.response.status != 500
                                ? this.alert(error.message, 2)
                                : "";
                            this.loadingPage = 0;
                        });
                }
            });
        }
    }
};
</script>
