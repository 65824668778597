var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('hero',{attrs:{"title":"Chart of Account","prevBreadcrumb":"Dashboard","routeBreadcrumb":"home","currentBreadcrumb":"Chart of Account"}}),_vm._v(" "),_c('div',{staticClass:"content"},[(_vm.loadingPage == 1 || !_vm.coas.data)?_c('div',{staticClass:"block block-rounded block-mode-loading-oneui",staticStyle:{"height":"250px"}},[_c('div',{staticClass:"block-content block-content-full pb-0",staticStyle:{"padding-top":"80px"}},[_c('grid-loader',{staticClass:"mx-auto",attrs:{"color":'#70B9EB',"size":'10px'}}),_vm._v(" "),_c('center',{staticClass:"mt-2"},[_c('span',{staticClass:"text-center",staticStyle:{"opacity":"0.7"}},[_vm._v("Loading")])])],1)]):_c('div',{staticClass:"block block-rounded block-mode-loading-oneui"},[_c('v-tabs',{staticStyle:{"border-radius":"8px"},attrs:{"background-color":"white"}},_vm._l((_vm.items),function(item){return _c('v-tab',{key:item,class:{
                        'v-tab--active': item == _vm.type,
                        'v-tab--active--false': item !== _vm.type
                    },attrs:{"disabled":_vm.loadingPage == 2},on:{"click":function($event){return _vm.typeChange(item)}}},[_vm._v("\n                    "+_vm._s(item)+"\n                ")])}),1),_vm._v(" "),_c('div',{staticClass:"block-content block-content-full py-2 "},[_c('v-app',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.datas,"search":_vm.search,"hide-default-footer":true,"items-per-page":-1,"mobile-breakpoint":0},scopedSlots:_vm._u([{key:"top",fn:function(ref){
                            var pagination = ref.pagination;
                            var options = ref.options;
                            var updateOptions = ref.updateOptions;
return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-7 mb-2 mb-sm-0 pt-0 pb-2"},[_c('v-layout',{attrs:{"justify-start":""}},[_c('v-data-footer',{attrs:{"pagination":pagination,"options":options,"items-per-page-options":[
                                                10,
                                                30,
                                                50,
                                                100,
                                                -1
                                            ],"items-per-page-text":"Rows :"},on:{"update:options":updateOptions}})],1)],1),_vm._v(" "),_c('div',{staticClass:"col-12 col-md-5 col-lg-5 col-xl-5 pb-0 pt-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"form-control form-control-sm col-12 col-md-6 float-right ml-1 mt-1 search-index",attrs:{"type":"text","name":"example-text-input","placeholder":"Search.."},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}}),_vm._v(" "),_c('button',{directives:[{name:"ripple",rawName:"v-ripple",value:({ center: true }),expression:"{ center: true }"}],staticClass:"btn btn-sm btn-info float-right btn-adjust-1",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.showCreateCoaModal()}}},[_c('i',{staticClass:"fa fa-plus mr-1"}),_vm._v(" Add\n                                        Data\n                                    ")])])])]}},{key:"body",fn:function(ref){
                                            var items = ref.items;
return [(_vm.loadingPage == 2)?_c('tbody',[_c('tr',{staticClass:"data-empty"},[_c('td',{staticClass:"py-2",attrs:{"colspan":"4"}},[_c('grid-loader',{staticClass:"mx-auto mt-5 pt-5",attrs:{"color":'#70B9EB',"size":'10px'}}),_vm._v(" "),_c('center',{staticClass:"mt-2 mb-5 pb-5"},[_c('span',{staticClass:"text-center",staticStyle:{"opacity":"0.7"}},[_vm._v("Loading")])])],1)])]):_vm._e(),_vm._v(" "),(items.length > 0 && _vm.loadingPage == 0)?_c('tbody',_vm._l((items),function(coa,index){return _c('tr',{key:index},[_c('td',{staticClass:"font-size-sm",on:{"click":function($event){return _vm.showEditCoaModal(coa.accountId)}}},[_vm._l((coa.accountLevel),function(n){return _c('span',{key:n},[(n > 1)?_c('span',[_vm._v(" ")]):_vm._e()])}),_vm._v(" "),_c('span',{style:([
                                                coa.accountRole == 'Header'
                                                    ? {
                                                          'font-weight':
                                                              'bold',
                                                          opacity: '0.9'
                                                      }
                                                    : {}
                                            ])},[_vm._v("\n                                            "+_vm._s(coa.accountNo)+"\n                                        ")])],2),_vm._v(" "),_c('td',{staticClass:"font-size-sm",on:{"click":function($event){return _vm.showEditCoaModal(coa.accountId)}}},[_vm._l((coa.accountLevel),function(n){return _c('span',{key:n},[(n > 1)?_c('span',[_vm._v(" ")]):_vm._e()])}),_vm._v(" "),_c('span',{style:([
                                                coa.accountRole == 'Header'
                                                    ? {
                                                          'font-weight':
                                                              'bold',
                                                          opacity: '0.9'
                                                      }
                                                    : {}
                                            ])},[_vm._v("\n                                            "+_vm._s(coa.accountName)+"\n                                        ")])],2),_vm._v(" "),_c('td',{staticClass:"font-size-sm",on:{"click":function($event){return _vm.showEditCoaModal(coa.accountId)}}},[_vm._l((coa.accountLevel),function(n){return _c('span',{key:n},[(n > 1)?_c('span',[_vm._v(" ")]):_vm._e()])}),_vm._v(" "),_c('span',{style:([
                                                coa.accountRole == 'Header'
                                                    ? {
                                                          'font-weight':
                                                              'bold',
                                                          opacity: '0.9'
                                                      }
                                                    : {}
                                            ])},[_vm._v("\n                                            "+_vm._s(coa.accountType)+"\n                                        ")])],2),_vm._v(" "),_c('td',{staticClass:"font-size-sm",attrs:{"align":"right"},on:{"click":function($event){return _vm.showEditCoaModal(coa.accountId)}}},[_c('span',{style:([
                                                coa.accountRole == 'Header'
                                                    ? {
                                                          'font-weight':
                                                              'bold',
                                                          opacity: '0.9'
                                                      }
                                                    : {}
                                            ])},[_vm._v("\n                                            "+_vm._s(_vm._f("currency")(coa.accountEndingBalance))+"\n                                        ")])])])}),0):_vm._e(),_vm._v(" "),(items.length <= 0 && _vm.loadingPage == 0)?_c('tbody',[_c('tr',{staticClass:"data-empty"},[_c('td',{staticClass:"text-center",staticStyle:{"opacity":"0.8","padding":"2.5rem"},attrs:{"colspan":"7"}},[_c('center',[_c('img',{staticStyle:{"height":"100px"},attrs:{"src":'/media/proxima/empty-data.svg'}})]),_vm._v(" "),_c('br'),_c('span',{staticStyle:{"opacity":"0.6"}},[_vm._v("Data Not Found")])],1)])]):_vm._e()]}}])})],1)],1)],1)]),_vm._v(" "),_c('modal',{attrs:{"name":"create-coa","width":"95%","height":"auto","classes":"modal-proxima  block-rounded block-mode-loading-oneui","adaptive":""},on:{"before-close":function($event){return _vm.clearCoaModal()}}},[_c('CreateCoa')],1),_vm._v(" "),_c('modal',{attrs:{"name":"edit-coa","width":"95%","height":"auto","classes":"modal-proxima  block-rounded block-mode-loading-oneui","adaptive":""},on:{"before-close":function($event){return _vm.clearCoaModal()}}},[_c('EditCoa',{attrs:{"coaId":this.coaId}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }