<template>
    <main>
        <hero title="Chart of Account" prevBreadcrumb="Dashboard" routeBreadcrumb="home" currentBreadcrumb="Chart of Account"></hero>

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 1 || !coas.data"
                style="height:250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity:0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <!-- Dynamic Table Full -->
            <div class="block block-rounded block-mode-loading-oneui" v-else>
                <v-tabs background-color="white" style="border-radius: 8px;">
                    <v-tab
                        v-for="item in items"
                        :key="item"
                        @click="typeChange(item)"
                        :disabled="loadingPage == 2"
                        v-bind:class="{
                            'v-tab--active': item == type,
                            'v-tab--active--false': item !== type
                        }"
                    >
                        {{ item }}
                    </v-tab>
                </v-tabs>
                <div class="block-content block-content-full py-2 ">
                    <v-app>
                        <v-data-table
                            :headers="headers"
                            :items="datas"
                            :search="search"
                            :hide-default-footer="true"
                            :items-per-page="-1"
                            :mobile-breakpoint="0"
                        >
                            <template
                                v-slot:top="{
                                    pagination,
                                    options,
                                    updateOptions
                                }"
                            >
                                <div class="row">
                                    <div
                                        class="col-12 col-md-7 mb-2 mb-sm-0 pt-0 pb-2"
                                    >
                                        <v-layout justify-start>
                                            <v-data-footer
                                                :pagination="pagination"
                                                :options="options"
                                                @update:options="updateOptions"
                                                :items-per-page-options="[
                                                    10,
                                                    30,
                                                    50,
                                                    100,
                                                    -1
                                                ]"
                                                items-per-page-text="Rows :"
                                            />
                                        </v-layout>
                                    </div>
                                    <div
                                        class="col-12 col-md-5 col-lg-5 col-xl-5 pb-0 pt-2"
                                    >
                                        <input
                                            type="text"
                                            class="form-control form-control-sm col-12 col-md-6 float-right ml-1 mt-1 search-index"
                                            name="example-text-input"
                                            placeholder="Search.."
                                            v-model="search"
                                        />
                                        <button
                                            type="button"
                                            class="btn btn-sm btn-info float-right btn-adjust-1"
                                            @click.prevent="
                                                showCreateCoaModal()
                                            "
                                            v-ripple="{ center: true }"
                                        >
                                            <i class="fa fa-plus mr-1"></i> Add
                                            Data
                                        </button>
                                    </div>
                                </div>
                            </template>
                            <template v-slot:body="{ items }">
                                <tbody v-if="loadingPage == 2">
                                    <tr class="data-empty">
                                        <td colspan="4" class="py-2">
                                            <grid-loader
                                                class="mx-auto mt-5 pt-5"
                                                :color="'#70B9EB'"
                                                :size="'10px'"
                                            ></grid-loader>
                                            <center class="mt-2 mb-5 pb-5">
                                                <span
                                                    class="text-center"
                                                    style="opacity:0.7"
                                                    >Loading</span
                                                >
                                            </center>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody
                                    v-if="items.length > 0 && loadingPage == 0"
                                >
                                    <tr
                                        v-for="(coa, index) in items"
                                        :key="index"
                                    >
                                        <td
                                            class="font-size-sm"
                                            @click="
                                                showEditCoaModal(coa.accountId)
                                            "
                                        >
                                            <span
                                                v-for="n in coa.accountLevel"
                                                :key="n"
                                            >
                                                <span v-if="n > 1">&emsp;</span>
                                            </span>
                                            <span
                                                :style="[
                                                    coa.accountRole == 'Header'
                                                        ? {
                                                              'font-weight':
                                                                  'bold',
                                                              opacity: '0.9'
                                                          }
                                                        : {}
                                                ]"
                                            >
                                                {{ coa.accountNo }}
                                            </span>
                                        </td>
                                        <td
                                            class="font-size-sm"
                                            @click="
                                                showEditCoaModal(coa.accountId)
                                            "
                                        >
                                            <span
                                                v-for="n in coa.accountLevel"
                                                :key="n"
                                            >
                                                <span v-if="n > 1">&emsp;</span>
                                            </span>
                                            <span
                                                :style="[
                                                    coa.accountRole == 'Header'
                                                        ? {
                                                              'font-weight':
                                                                  'bold',
                                                              opacity: '0.9'
                                                          }
                                                        : {}
                                                ]"
                                            >
                                                {{ coa.accountName }}
                                            </span>
                                        </td>
                                        <td
                                            class="font-size-sm"
                                            @click="
                                                showEditCoaModal(coa.accountId)
                                            "
                                        >
                                            <span
                                                v-for="n in coa.accountLevel"
                                                :key="n"
                                            >
                                                <span v-if="n > 1">&emsp;</span>
                                            </span>
                                            <span
                                                :style="[
                                                    coa.accountRole == 'Header'
                                                        ? {
                                                              'font-weight':
                                                                  'bold',
                                                              opacity: '0.9'
                                                          }
                                                        : {}
                                                ]"
                                            >
                                                {{ coa.accountType }}
                                            </span>
                                        </td>
                                        <td
                                            class="font-size-sm"
                                            align="right"
                                            @click="
                                                showEditCoaModal(coa.accountId)
                                            "
                                        >
                                            <span
                                                :style="[
                                                    coa.accountRole == 'Header'
                                                        ? {
                                                              'font-weight':
                                                                  'bold',
                                                              opacity: '0.9'
                                                          }
                                                        : {}
                                                ]"
                                            >
                                                {{
                                                    coa.accountEndingBalance
                                                        | currency
                                                }}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody
                                    v-if="items.length <= 0 && loadingPage == 0"
                                >
                                    <tr class="data-empty">
                                        <td
                                            class="text-center"
                                            colspan="7"
                                            style="opacity:0.8; padding:2.5rem;"
                                        >
                                            <center>
                                                <img
                                                    :src="
                                                        '/media/proxima/empty-data.svg'
                                                    "
                                                    style="height: 100px;"
                                                />
                                            </center>
                                            <br /><span style="opacity:0.6;"
                                                >Data Not Found</span
                                            >
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-data-table>
                    </v-app>
                </div>
            </div>
        </div>
        <modal
            name="create-coa"
            width="95%"
            height="auto"
            classes="modal-proxima  block-rounded block-mode-loading-oneui"
            @before-close="clearCoaModal()"
            adaptive
        >
            <CreateCoa></CreateCoa>
        </modal>
        <modal
            name="edit-coa"
            width="95%"
            height="auto"
            classes="modal-proxima  block-rounded block-mode-loading-oneui"
            @before-close="clearCoaModal()"
            adaptive
        >
            <EditCoa :coaId="this.coaId"></EditCoa>
        </modal>
    </main>
</template>

<script>
import { mapActions, mapState } from "vuex";
import CreateCoa from "./../../../components/CreateCoa";
import EditCoa from "./../../../components/EditCoa";

export default {
    name: "coa",
    components: {
        CreateCoa,
        EditCoa
    },
    data() {
        return {
            search: "",
            coaId: "",
            awaitingSearch: false,
            rows: "All",
            loadingPage: 1,
            type: "All Accounts",
            tab: null,
            items: [
                "All Accounts",
                "Asset",
                "Liability",
                "Equity",
                "Income",
                "Cost of Sales",
                "Expense",
                "Other Income",
                "Other Expense"
            ],
            headers: [
                { text: "No. Account", align: "center", sortable: false },
                { text: "Account Name", align: "center", value: "accountName" },
                { text: "Account Type", align: "start", value: "accountType" },
                { text: "Ending Balance", value: "accountEndingBalance" }
            ],
            datas: []
        };
    },
    created() {
        this.getData();
    },
    computed: {
        ...mapState("coa", {
            coas: state => state.coas
        })
    },
    mounted() {
        this.$root.$on("refreshCoa", id => {
            this.getData();
        });
    },
    methods: {
        ...mapActions("coa", ["getCoa"]),
        getData() {
            this.loadingPage = 2;
            this.datas = [];
            this.getCoa({
                rows: this.rows,
                type: this.type
            })
                .then(() => {
                    this.coas.data.forEach(element => {
                        this.datas.push({
                            accountId: element.id,
                            accountNo: element.account_no,
                            accountName: element.account_name,
                            accountType: element.account_type,
                            accountRole: element.account_role,
                            accountLevel: element.account_level,
                            accountEndingBalance: element.ending_balance
                        });
                    });
                    this.loadingPage = 0;
                })
                .catch(error => {
                    error.response.status == 500
                        ? this.alert(error.response.data.message, 2)
                        : "";
                    error.response.status != 422 && error.response.status != 500
                        ? this.alert(error.message, 2)
                        : "";
                    this.loadingPage = 0;
                });
        },
        showCreateCoaModal() {
            this.$modal.show("create-coa");
            document.body.classList.add("modal-open");
        },
        showEditCoaModal(val) {
            this.coaId = val;
            this.$modal.show("edit-coa");
            document.body.classList.add("modal-open");
        },
        closeCreateCoaModal() {
            this.$modal.hide("create-coa");
            document.body.classList.remove("modal-open");
        },
        closeEditCoaModal() {
            this.$modal.hide("edit-coa");
            document.body.classList.remove("modal-open");
        },
        clearCoaModal() {
            document.body.classList.remove("modal-open");
        },
        toEdit(val) {
            this.$router.push({ name: "coa-edit", params: { id: val } });
        },
        typeChange(val) {
            this.loadingPage = 2;
            this.type = val;
            this.getData();
        }
    }
};
</script>
