<template>
    <!-- Header -->
    <header id="page-header">
        <!-- Header Content -->
        <div class="content-header">
            <!-- Left Section -->
            <div class="d-flex align-items-center">
                <!-- Toggle Sidebar -->
                <!-- Layout API, functionality initialized in Template._uiApiLayout()-->
                <button
                    type="button"
                    class="btn btn-sm btn-dual mr-2 d-lg-none"
                    data-toggle="layout"
                    data-action="sidebar_toggle"
                >
                    <i class="fa fa-fw fa-bars"></i>
                </button>
                <!-- END Toggle Sidebar -->

                <!-- Toggle Mini Sidebar -->
                <!-- Layout API, functionality initialized in Template._uiApiLayout()-->
                <button
                    type="button"
                    class="btn btn-sm btn-dual mr-2 d-none d-lg-inline-block"
                    data-toggle="layout"
                    data-action="sidebar_mini_toggle"
                >
                    <i class="fa fa-fw fa-bars"></i>
                </button>
                <!-- END Toggle Mini Sidebar -->

                <!-- Search Form (visible on larger screens) -->
                <span class="d-inline-block d-md-none mb-1">
                    {{ new Date() | moment("ddd, MMM Do YYYY") }}
                </span>
                <span
                    class="d-none d-sm-inline-block font-weight-bold ml-2 mb-1"
                >
                    {{ new Date() | moment("dddd, MMMM Do YYYY") }}
                </span>
                <!-- END Search Form -->
            </div>
            <!-- END Left Section -->

            <!-- Right Section -->
            <div class="d-flex align-items-center">
                <div
                    class="dropdown d-inline-block ml-2"
                    v-if="auth.type == 'Trial'"
                >
                    Proxima Trial :
                    <span class="text-info" v-if="auth.daylimit > 3">
                        {{ auth.daylimit }} days access left
                    </span>
                    <span class="text-info" v-if="auth.daylimit <= 3">
                        {{ auth.daylimit }} days access left
                    </span>
                </div>
                <!-- Notifications Dropdown -->
                <div class="dropdown d-inline-block ml-2">
                    <button
                        type="button"
                        class="btn btn-sm btn-dual"
                        id="page-header-notifications-dropdown"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        @click="getNotificationLatest()"
                    >
                        <i class="far fa-bell"></i>
                    </button>
                    <div
                        class="
                            dropdown-menu dropdown-menu-lg dropdown-menu-right
                            p-0
                            border-0
                            font-size-sm
                        "
                        aria-labelledby="page-header-notifications-dropdown"
                    >
                        <div class="p-2 bg-primary text-center">
                            <h5
                                class="
                                    dropdown-header
                                    text-uppercase text-white
                                "
                            >
                                Latest Notification
                            </h5>
                        </div>
                        <ul class="nav-items mb-0">
                            <li
                                v-for="(notification, index) in notification.notifications
                                    .data"
                                v-bind:key="index"
                            >
                                <span class="text-dark media py-2">
                                    <div class="mr-2 ml-3">
                                        <i
                                            class="
                                                fa
                                                fa-bell
                                                text-primary
                                            "
                                        ></i>
                                    </div>
                                    <div class="media-body pr-2">
                                        <div
                                            class="notif-title font-w600 mb-1"
                                            style="text-transform: capitalize"
                                        >
                                            {{ notification.title }}
                                        </div>
                                        <div
                                            class="notif-content font-sm"
                                            align="justify"
                                        >
                                            {{ notification.content.substr(1, 150) + '...' }}
                                        </div>
                                    </div>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- END Notifications Dropdown -->

                <!-- Activity Dropdown -->
                <div class="dropdown d-inline-block ml-2">
                    <button
                        type="button"
                        class="btn btn-sm btn-dual"
                        id="page-header-notifications-dropdown"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        @click="getActivities()"
                    >
                        <i class="far fa-clock"></i>
                        <!-- <span class="badge badge-info badge-pill">6</span> -->
                    </button>
                    <div
                        class="
                            dropdown-menu dropdown-menu-lg dropdown-menu-right
                            p-0
                            border-0
                            font-size-sm
                        "
                        aria-labelledby="page-header-notifications-dropdown"
                    >
                        <div class="p-2 bg-info text-center">
                            <h5
                                class="
                                    dropdown-header
                                    text-uppercase text-white
                                "
                            >
                                Recent Activity
                            </h5>
                        </div>
                        <ul class="nav-items mb-0">
                            <li
                                v-for="(activity, index) in activity.activities
                                    .data"
                                v-bind:key="index"
                            >
                                <span class="text-dark media py-2">
                                    <div class="mr-2 ml-3">
                                        <i
                                            class="
                                                far
                                                fa-fw fa-file-alt
                                                text-info
                                            "
                                            v-if="activity.event == 'created'"
                                        ></i>
                                        <i
                                            class="
                                                far
                                                fa-fw fa-edit
                                                text-success
                                            "
                                            v-if="activity.event == 'updated'"
                                        ></i>
                                        <i
                                            class="
                                                far
                                                fa-fw fa-trash-alt
                                                text-danger
                                            "
                                            v-if="activity.event == 'deleted'"
                                        ></i>
                                    </div>
                                    <div class="media-body pr-2">
                                        <div
                                            class="font-w600"
                                            style="text-transform: capitalize"
                                        >
                                            {{ activity.event }}
                                            <span v-html="activity.tags"></span>
                                        </div>
                                    </div>
                                </span>
                            </li>
                        </ul>
                        <div class="p-2 border-top">
                            <router-link
                                class="
                                    btn btn-sm btn-light btn-block
                                    text-center
                                "
                                :to="{ name: 'activity' }"
                            >
                                <i class="fa fa-fw fa-arrow-down mr-1"></i> All
                                Activity
                            </router-link>
                        </div>
                    </div>
                </div>
                <!-- END Activity Dropdown -->

                <!-- User Dropdown -->
                <div class="dropdown d-inline-block ml-2">
                    <button
                        type="button"
                        class="btn btn-sm btn-dual"
                        id="page-header-user-dropdown"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <img
                            class="rounded"
                            :src="'/storage/avatar/' + auth.data.photo"
                            @error="errorAvatarUrl"
                            style="
                                width: 20px;
                                height: 20px;
                                object-fit: cover;
                                border-radius: 50% !important;
                            "
                        />
                        <span class="d-none d-sm-inline-block ml-1">{{
                            auth.data.name
                        }}</span>
                        <i
                            class="
                                fa fa-fw fa-angle-down
                                d-none d-sm-inline-block
                            "
                        ></i>
                    </button>
                    <div
                        class="
                            dropdown-menu dropdown-menu-right
                            p-0
                            border-0
                            font-size-sm
                        "
                        aria-labelledby="page-header-user-dropdown"
                    >
                        <div class="p-3 text-center bg-dark">
                            <img
                                class="img-avatar img-avatar50 img-avatar-thumb"
                                :src="'/storage/avatar/' + auth.data.photo"
                                @error="errorAvatarUrl"
                                style="object-fit: cover"
                            />
                        </div>
                        <div class="p-2">
                            <h5 class="dropdown-header text-uppercase">
                                User Options
                            </h5>
                            <router-link
                                class="
                                    dropdown-item
                                    d-flex
                                    align-items-center
                                    justify-content-between
                                "
                                :to="{ name: 'edit-profile' }"
                                tag="a"
                            >
                                <span>Profile</span>
                                <i class="si si-user ml-1"></i>
                            </router-link>
                            <a
                                class="
                                    dropdown-item
                                    d-flex
                                    align-items-center
                                    justify-content-between
                                "
                                @click="logout()"
                                style="cursor: pointer"
                            >
                                <span>Log Out</span>
                                <i class="si si-logout ml-1"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <!-- END User Dropdown -->
            </div>
            <!-- END Right Section -->
        </div>
        <!-- END Header Content -->

        <!-- Header Search -->
        <div id="page-header-search" class="overlay-header bg-white">
            <div class="content-header">
                <form
                    class="w-100"
                    action="be_pages_generic_search.html"
                    method="POST"
                >
                    <div class="input-group input-group-sm">
                        <div class="input-group-prepend">
                            <!-- Layout API, functionality initialized in Template._uiApiLayout() -->
                            <button
                                type="button"
                                class="btn btn-danger"
                                data-toggle="layout"
                                data-action="header_search_off"
                            >
                                <i class="fa fa-fw fa-times-circle"></i>
                            </button>
                        </div>
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Search or hit ESC.."
                            id="page-header-search-input"
                            name="page-header-search-input"
                        />
                    </div>
                </form>
            </div>
        </div>
        <!-- END Header Search -->

        <!-- Header Loader -->
        <!-- Please check out the Loaders page under Components category to see examples of showing/hiding it -->
        <div id="page-header-loader" class="overlay-header bg-white">
            <div class="content-header">
                <div class="w-100 text-center">
                    <i class="fa fa-fw fa-circle-notch fa-spin"></i>
                </div>
            </div>
        </div>
        <!-- END Header Loader -->
    </header>
    <!-- END Header -->
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import SecureLS from "secure-ls";
var ls = new SecureLS({ isCompression: false, encryptionSecret: "d3v3L0P3r!" });

export default {
    data() {
        return {
            avatar: ""
        };
    },
    computed: {
        ...mapState(["auth", "activity", "notification"])
    },
    created() {
        this.getActivities();
        // this.getNotificationLatest();
    },
    methods: {
        //KETIKA TOMBOL LOGOUT DITEKAN, FUNGSI INI DIJALANKAN
        ...mapActions(["CLEAR_STATE"]),
        ...mapActions("notification", ["getNotificationLatest"]),
        ...mapActions("activity", ["getActivities"]),
        ...mapActions("auth", ["clearStorage"]),
        logout() {
            this.$swal({
                title: "Logout from system ?",
                text: "You can go back into the system by re-loggin",
                icon: "warning",
                buttons: ["Cancel", "Logout"],
                dangerMode: true
            }).then(willDelete => {
                if (willDelete) {
                    this.clearStorage();
                    return new Promise((resolve, reject) => {
                        localStorage.removeItem("_tk__"); //MENGHAPUS TOKEN DARI LOCALSTORAGE
                        ls.remove("_ath_"); //MENGHAPUS auth DARI LOCALSTORAGE
                        ls.remove("_wk_spc_"); //MENGHAPUS auth DARI LOCALSTORAGE
                        ls.remove("_rl_"); //MENGHAPUS auth DARI LOCALSTORAGE
                        localStorage.clear();
                        sessionStorage.clear();
                        resolve();
                    }).then(() => {
                        this.CLEAR_STATE();
                        this.$store.state.token = localStorage.getItem("_tk__");
                        this.$store.state.authuser = ls.get("_ath_");
                        this.$store.state.auth.data = ls.get("_ath_");
                        this.$store.state.auth.role = ls.get("_rl_");
                        this.$router.push("/login"); //REDIRECT KE PAGE LOGIN
                    });
                }
            });
        },
        errorAvatarUrl(event) {
            event.target.src = "/media/avatars/avatar0.jpg";
        }
    }
};
</script>