<template>
    <main>
        <hero
            title="Report : Purchase"
            prevBreadcrumb="Report"
            routeBreadcrumb="report"
            currentBreadcrumb="Purchase"
        ></hero>
        
        <!-- Page Content -->
        <div class="content">
            <div class="block mb-2 block-rounded block-mode-loading-oneui">
                <div class="block-header">
                    <h3 class="block-title">Trial Balance</h3>
                    <div class="block-options">
                        <!-- Print Page functionality is initialized in Helpers.print() -->
                        <button
                            type="button"
                            class="btn-block-option text-info"
                            @click="print()"
                        >
                            <i class="si si-printer mr-1"></i>
                            <span class="d-none d-md-inline-block"
                                >Print Document</span
                            >
                        </button>
                        <!-- Print Page functionality is initialized in Helpers.print() -->
                        <button
                            type="button"
                            class="btn-block-option text-success"
                            @click="refresh()"
                        >
                            <i class="si si-reload mr-1"></i>
                            <span class="d-none d-md-inline-block"
                                >Refresh</span
                            >
                        </button>
                    </div>
                </div>
            </div>
            <div class="block bg-transparent mb-2">
                <div class="block-content pt-0 bg-transparent text-right">
                    <input
                        class="form-check-input"
                        style="margin-top:3.5px"
                        type="checkbox"
                        v-model="useHeader"
                    />
                    <label class="form-check-label">Default Header</label>
                </div>
            </div>
            <!--Loading-->
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 2"
                style="height:250px"
            >                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity:0.7"
                            >Generating Report</span
                        >
                    </center>
                </div>
            </div>
            <!-- Basic -->
            <div class="block block-rounded block-mode-loading-oneui" v-else>
                <div class="content content-boxed" id="printMe">
                    <div class="block-content">
                        <div class="p-0 p-md-5" style="min-height:800px">
                            <div class="row pt-0" v-if="useHeader">
                                <div class="col-6 font-size-sm pb-0 pt-0">
                                    <p class="h3 mb-2 mt-3 text-uppercase">
                                        {{
                                            auth.data.workspace
                                                .workspace_company_name
                                        }}
                                    </p>
                                    <p class="mb-0">
                                        {{
                                            auth.data.workspace
                                                .workspace_company_address
                                        }}
                                    </p>
                                    <p class="mb-0">
                                        <span
                                            v-if="
                                                auth.data.workspace
                                                    .workspace_company_province !==
                                                    '' &&
                                                    auth.data.workspace
                                                        .workspace_company_province !==
                                                        null
                                            "
                                        >
                                            {{
                                                auth.data.workspace
                                                    .workspace_company_province
                                            }}
                                        </span>
                                        <span
                                            v-if="
                                                auth.data.workspace
                                                    .workspace_company_postal !==
                                                    '' &&
                                                    auth.data.workspace
                                                        .workspace_company_postal !==
                                                        null
                                            "
                                        >
                                            ,
                                            {{
                                                auth.data.workspace
                                                    .workspace_company_postal
                                            }}
                                        </span>
                                    </p>
                                    <p class="mb-0">
                                        <span
                                            v-if="
                                                auth.data.workspace
                                                    .workspace_company_phone !==
                                                    '' &&
                                                    auth.data.workspace
                                                        .workspace_company_phone !==
                                                        null
                                            "
                                        >
                                            {{
                                                auth.data.workspace
                                                    .workspace_company_phone
                                            }}
                                        </span>
                                        <span
                                            v-if="
                                                auth.data.workspace
                                                    .workspace_company_email !==
                                                    '' &&
                                                    auth.data.workspace
                                                        .workspace_company_email !==
                                                        null
                                            "
                                        >
                                            {{
                                                "| " +
                                                    auth.data.workspace
                                                        .workspace_company_email
                                            }}
                                        </span>
                                    </p>
                                </div>
                                <div
                                    class="col-6 text-right font-size-sm pb-0 pt-0"
                                >
                                    <img
                                        :src="
                                            '/storage/workspace/' +
                                                auth.data.workspace
                                                    .workspace_company_logo
                                        "
                                        alt=""
                                        style="min-heigh:100px; max-height:120px; max-width:180px;"
                                        @error="errorLogoUrl"
                                    />
                                </div>
                            </div>

                            <hr v-if="useHeader"/>

                            <!-- Invoice Info -->
                            <div class="row mb-2">
                                <!-- Company Info -->
                                <div class="col-6 font-size-sm">
                                    <p class="h3 mb-1">Trial Balance</p>
                                    <p class="mb-2">Currency : IDR</p>
                                </div>
                                <!-- END Company Info -->

                                <!-- Client Info -->
                                <div class="col-6 text-right font-size-sm">
                                    <p class="mb-2">
                                        From :
                                        {{
                                            this.$route.params.date_start
                                                | moment("DD/MM/YYYY")
                                        }}
                                    </p>
                                    <p class="mb-2">
                                        To :
                                        {{
                                            this.$route.params.date_end
                                                | moment("DD/MM/YYYY")
                                        }}
                                    </p>
                                </div>
                                <!-- END Client Info -->
                            </div>
                            <!-- END Invoice Info -->

                            <!-- Table -->
                            <div
                                class="table-responsive push"
                                v-if="loadingPage == 2"
                            >
                                <div
                                    class="block-content block-content-full pb-0"
                                    style="padding-top: 80px"
                                >
                                    <grid-loader
                                        class="mx-auto"
                                        :color="'#70B9EB'"
                                        :size="'10px'"
                                    ></grid-loader>
                                    <center class="mt-2 mb-5 pb-2">
                                        <span
                                            class="text-center"
                                            style="opacity:0.7"
                                            >Refresh Report</span
                                        >
                                    </center>
                                </div>
                            </div>
                            <div class="table-responsive push" v-else>
                                <table
                                    class="table table-bordered font-print-md"
                                >
                                    <tr class="bg-body-light font-print-md">
                                        <td
                                            align="center"
                                            style="font-weight: bold;"
                                            colspan="2"
                                        >
                                            Account
                                        </td>
                                        <td
                                            align="center"
                                            style="font-weight: bold;"
                                            colspan="2"
                                        >
                                            Opening Balance
                                        </td>
                                        <td
                                            align="center"
                                            style="font-weight: bold;"
                                            colspan="2"
                                        >
                                            Mutation
                                        </td>
                                        <td
                                            align="center"
                                            style="font-weight: bold;"
                                            colspan="2"
                                        >
                                            Ending Balance
                                        </td>
                                    </tr>
                                    <tr class="bg-body-light font-print-md">
                                        <td
                                            style="font-weight: bold;"
                                            width="100px"
                                        >
                                            No.
                                        </td>
                                        <td style="font-weight: bold;">Name</td>
                                        <td
                                            align="center"
                                            style="font-weight: bold; "
                                        >
                                            Debit
                                        </td>
                                        <td
                                            align="center"
                                            style="font-weight: bold; "
                                        >
                                            Credit
                                        </td>
                                        <td
                                            align="center"
                                            style="font-weight: bold; "
                                        >
                                            Debit
                                        </td>
                                        <td
                                            align="center"
                                            style="font-weight: bold; "
                                        >
                                            Credit
                                        </td>
                                        <td
                                            align="center"
                                            style="font-weight: bold; "
                                        >
                                            Debit
                                        </td>
                                        <td
                                            align="center"
                                            style="font-weight: bold; "
                                        >
                                            Credit
                                        </td>
                                    </tr>
                                    <tr
                                        class="font-size-print-trial-balance"
                                        v-for="coa in trialBalance.data"
                                        :key="coa.index"
                                    >
                                        <td>{{ coa.account_no }}</td>
                                        <td>{{ coa.account_name }}</td>
                                        <td
                                            align="right"
                                            style="white-space: nowrap;"
                                        >
                                            {{
                                                coa.ob_debit
                                                    | currency({ symbol: "" })
                                            }}
                                        </td>
                                        <td
                                            align="right"
                                            style="white-space: nowrap;"
                                        >
                                            {{
                                                coa.ob_credit
                                                    | currency({ symbol: "" })
                                            }}
                                        </td>
                                        <td
                                            align="right"
                                            style="white-space: nowrap;"
                                        >
                                            {{
                                                coa.mutationdebit
                                                    | currency({ symbol: "" })
                                            }}
                                        </td>
                                        <td
                                            align="right"
                                            style="white-space: nowrap;"
                                        >
                                            {{
                                                coa.mutationcredit
                                                    | currency({ symbol: "" })
                                            }}
                                        </td>
                                        <td
                                            align="right"
                                            style="white-space: nowrap;"
                                        >
                                            {{
                                                coa.ebdebit
                                                    | currency({ symbol: "" })
                                            }}
                                        </td>
                                        <td
                                            align="right"
                                            style="white-space: nowrap;"
                                        >
                                            {{
                                                coa.ebcredit
                                                    | currency({ symbol: "" })
                                            }}
                                        </td>
                                    </tr>
                                    <tr
                                        v-if="
                                            trialBalance.data &&
                                                trialBalance.data.length == 0
                                        "
                                    >
                                        <td colspan="6" class="text-center">
                                            No Account
                                        </td>
                                    </tr>
                                    <tr
                                        class="bg-body-light font-size-print-trial-balance"
                                    >
                                        <td
                                            align="center"
                                            colspan="2"
                                            style="font-weight: bold; border-top:"
                                        >
                                            Total
                                        </td>
                                        <td
                                            align="right"
                                            style="font-weight: bold; white-space: nowrap;"
                                        >
                                            {{
                                                totalOpeningBalanceDebit
                                                    | currency({ symbol: "" })
                                            }}
                                        </td>
                                        <td
                                            align="right"
                                            style="font-weight: bold; white-space: nowrap;"
                                        >
                                            {{
                                                totalOpeningBalanceCredit
                                                    | currency({ symbol: "" })
                                            }}
                                        </td>
                                        <td
                                            align="right"
                                            style="font-weight: bold; white-space: nowrap;"
                                        >
                                            {{
                                                totalMutationDebit
                                                    | currency({ symbol: "" })
                                            }}
                                        </td>
                                        <td
                                            align="right"
                                            style="font-weight: bold; white-space: nowrap;"
                                        >
                                            {{
                                                totalMutationCredit
                                                    | currency({ symbol: "" })
                                            }}
                                        </td>
                                        <td
                                            align="right"
                                            style="font-weight: bold; white-space: nowrap;"
                                        >
                                            {{
                                                totalEndingBalanceDebit
                                                    | currency({ symbol: "" })
                                            }}
                                        </td>
                                        <td
                                            align="right"
                                            style="font-weight: bold; white-space: nowrap;"
                                        >
                                            {{
                                                totalEndingBalanceCredit
                                                    | currency({ symbol: "" })
                                            }}
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <!-- END Table -->

                            <hr />

                            <div class="row mt-4 mb-5">
                                <div class="col-6 text-left font-size-sm">
                                    <div class="form-group d-print-none">
                                        <label for="example-text-input"
                                            >Post Note *</label
                                        >
                                        <ckeditor
                                            tag-name="textarea"
                                            :editor="editor"
                                            v-model="docPostNote"
                                            :config="editorConfig"
                                        ></ckeditor>
                                    </div>
                                    <span
                                        class="d-none d-print-inline-block"
                                        v-html="docPostNote"
                                    ></span>
                                </div>
                                <div class="col-6 font-size-sm text-right">
                                    <div
                                        class="form-group text-left d-print-none"
                                    >
                                        <label for="example-text-input"
                                            >Choose Signature *</label
                                        >
                                        <selectize
                                            class="form-control"
                                            placeholder="Choose Signature"
                                            v-model="selectedSignature"
                                            @input="
                                                selectSignature(
                                                    selectedSignature
                                                )
                                            "
                                        >
                                            <option
                                                v-for="signature in signatures.data"
                                                :value="signature.id"
                                                :key="signature.id"
                                                >{{
                                                    signature.signature_name
                                                }}</option
                                            >
                                        </selectize>
                                    </div>
                                    <p
                                        class="mb-0 font-w700 mt-6 ml-9 text-center"
                                        style="border-bottom:1px solid grey"
                                        v-bind:class="{
                                            'd-print-none':
                                                selectedSignature == ''
                                        }"
                                    >
                                        {{ docSignature.signature_name }}
                                    </p>
                                    <p
                                        class="mb-0 font-w700 ml-9 text-center"
                                        v-bind:class="{
                                            'd-print-none':
                                                selectedSignature == ''
                                        }"
                                    >
                                        {{ docSignature.signature_position }}
                                    </p>
                                </div>
                            </div>

                            <hr />
                            <p
                                class="d-none d-print-inline-block"
                                style="opacity:0.6;"
                            >
                                <i> Printed : {{ dateNow }} </i>
                            </p>
                            <!-- END Footer -->
                        </div>
                    </div>
                </div>
                <!-- END Invoice -->
            </div>
        </div>
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import moment from "moment";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
    name: "trial-balance-display",

    data() {
        return {
            loadingPage: 1,
            useHeader: true,
            dateNow: moment().format("MMMM Do YYYY, HH:mm:ss"),
            selectedSignature: "",
            docSignature: {
                signature_name: "[select signature]",
                signature_position: "[select signature]"
            },
            editor: ClassicEditor,
            editorConfig: {
                toolbar: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "bulletedList",
                    "numberedList",
                    "blockQuote",
                    "undo",
                    "redo",
                    "insertTable"
                ]
            },
            docPostNote: "",
            totalOpeningBalanceDebit: 0,
            totalOpeningBalanceCredit: 0,
            totalMutationDebit: 0,
            totalMutationCredit: 0,
            totalEndingBalanceDebit: 0,
            totalEndingBalanceCredit: 0
        };
    },
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState("coa", { coas: state => state.coas.accounts }),
        ...mapState(["auth"]),
        ...mapState("report", { trialBalance: state => state.trialBalance }),
        ...mapState("signature", { signatures: state => state.signatures })
    },
    created() {
        if (
            this.$route.params.date_start &&
            this.$route.params.date_end &&
            this.$route.params.account_start &&
            this.$route.params.account_end
        ) {
            this.generateReportTB({
                date_start: this.$route.params.date_start,
                date_end: this.$route.params.date_end,
                account_end: this.$route.params.account_end,
                account_start: this.$route.params.account_start
            })
                .then(response => {
                    this.calculateArray(this.trialBalance.data);
                    this.loadingPage = 0;
                })
                .catch(error => {
                    console.log(error);
                    this.loadingPage = 0;
                });
            this.getSignatureDocument({ document: "signature_trial_balance" });
        } else {
            this.$router.push({ name: "trial-balance" });
            this.alert("Select Date & Account First", 2);
        }
    },
    methods: {
        ...mapActions("coa", ["getCoaAll"]),
        ...mapActions("report", [
            "pdfReportTB",
            "xlsReportTB",
            "generateReportTB"
        ]),
        ...mapActions("signature", ["getSignatureDocument"]),
        calculateArray(arr) {
            var node, i;
            for (i = 0; i < arr.length; i += 1) {
                node = arr[i];
                this.totalOpeningBalanceDebit =
                    this.totalOpeningBalanceDebit + parseInt(node.ob_debit);
                this.totalOpeningBalanceCredit =
                    this.totalOpeningBalanceCredit + parseInt(node.ob_credit);
                this.totalMutationDebit =
                    this.totalMutationDebit + parseInt(node.mutationdebit);
                this.totalMutationCredit =
                    this.totalMutationCredit + parseInt(node.mutationcredit);
                this.totalEndingBalanceDebit =
                    this.totalEndingBalanceDebit + parseInt(node.ebdebit);
                this.totalEndingBalanceCredit =
                    this.totalEndingBalanceCredit + parseInt(node.ebcredit);
            }
        },
        selectSignature(val) {
            if (val != "") {
                var result = this.signatures.data.find(obj => {
                    return obj.id === parseInt(val);
                });
                this.docSignature.signature_name = result.signature_name;
                this.docSignature.signature_position =
                    result.signature_position;
            } else {
                this.docSignature.signature_name = "[select signature]";
                this.docSignature.signature_position = "[select signature]";
            }
        },
        errorLogoUrl(event) {
            event.target.src = "/media/workspace/no-logo.jpg";
        },
        //METHOD
        print() {
            var browserName = navigator.appName;
            if (browserName == "Safari") {
                var printContents = document.getElementById("printMe")
                    .innerHTML;
                var originalContents = document.body.innerHTML;
                document.body.innerHTML = printContents;
                window.print();
                document.body.innerHTML = originalContents;
            } else {
                this.$htmlToPaper("printMe");
            }
        },
        refresh() {
            this.loadingPage = 2;
            this.totalOpeningBalanceDebit = 0;
            this.totalOpeningBalanceCredit = 0;
            this.totalMutationDebit = 0;
            this.totalMutationCredit = 0;
            this.totalEndingBalanceDebit = 0;
            this.totalEndingBalanceCredit = 0;
            this.generateReportTB({
                date_start: this.$route.params.date_start,
                date_end: this.$route.params.date_end,
                account_end: this.$route.params.account_end,
                account_start: this.$route.params.account_start
            })
                .then(response => {
                    this.calculateArray(this.trialBalance.data);
                    this.loadingPage = 0;
                })
                .catch(error => {
                    console.log(error);
                    this.loadingPage = 0;
                });
        }
    }
};
</script>
