<template>
    <!-- Hero -->
    <div class="bg-body-light">
        <div class="content content-full">
            <div
                class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center"
            >
                <h1 class="flex-sm-fill h3 my-2">{{title}}</h1>
                <nav
                    class="flex-sm-00-auto ml-sm-3"
                    aria-label="breadcrumb"
                >
                    <ol class="breadcrumb breadcrumb-alt">
                        <li class="breadcrumb-item" aria-current="page">
                            <router-link
                                class="link-fx"
                                :to="{ name: routeBreadcrumb }"
                                >{{prevBreadcrumb}}</router-link
                            >
                        </li>
                        <li class="breadcrumb-item">{{currentBreadcrumb}}</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
    <!-- END Hero -->
</template>

<script>
export default {
    name: "hero-component",
    props: ["prevBreadcrumb", "currentBreadcrumb", "title", "routeBreadcrumb"],
};
</script>