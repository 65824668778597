<template>
    <main>
        <hero
            title="Report : Trial Balance Cumulative"
            prevBreadcrumb="Report"
            routeBreadcrumb="report"
            currentBreadcrumb="Trial Balance Cumulative"
        ></hero>

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <div
                class="block col-12 col-md-8 offset-md-2"
                v-if="loadingPage == 1"
                style="height:250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity:0.7"
                            >Generate Report</span
                        >
                    </center>
                </div>
            </div>
            <div
                class="block col-12 col-md-8 offset-md-2"
                v-if="loadingPage == 2"
                style="height:250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity:0.7"
                            >Generate Report</span
                        >
                    </center>
                </div>
            </div>
            <!-- Basic -->
            <div
                class="block col-12 col-md-8 offset-md-2  block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 0"
            >
                <div class="block-content block-content-full">
                    <form
                        method="POST"
                        enctype="multipart/form-data"
                        onsubmit="return false;"
                    >
                        <!--Form-->
                        <div class="row font-size-sm">
                            <div class="col-12 col-md-10 offset-md-1">
                                <div class="row">
                                    <div class="form-group col-6 pb-0 pt-1">
                                        <label for="example-text-input"
                                            >Account Data From :
                                        </label>
                                        <selectize
                                            class="form-control"
                                            placeholder="Choose Account No."
                                            v-model="account_start"
                                            :class="{
                                                'is-invalid-selectize':
                                                    errors.account_start
                                            }"
                                        >
                                            <!-- settings is optional -->
                                            <option
                                                v-for="account in coas"
                                                :value="account.account_no"
                                                :key="account.id"
                                                >{{ account.account_no }}
                                                {{
                                                    account.account_name
                                                }}</option
                                            >
                                        </selectize>
                                        <transition name="slide-fade">
                                            <p
                                                class="text-danger"
                                                v-if="errors.account_start"
                                            >
                                                {{ errors.account_start[0] }}
                                            </p>
                                        </transition>
                                    </div>
                                    <div class="form-group col-6 pb-0 pt-1">
                                        <label for="example-text-input"
                                            >To :</label
                                        >
                                        <selectize
                                            class="form-control"
                                            placeholder="Choose Account No."
                                            v-model="account_end"
                                            :class="{
                                                'is-invalid-selectize':
                                                    errors.account_end
                                            }"
                                        >
                                            <!-- settings is optional -->
                                            <option
                                                v-for="account in coas"
                                                :value="account.account_no"
                                                :key="account.id"
                                                >{{ account.account_no }}
                                                {{
                                                    account.account_name
                                                }}</option
                                            >
                                        </selectize>
                                        <transition name="slide-fade">
                                            <p
                                                class="text-danger"
                                                v-if="errors.account_end"
                                            >
                                                {{ errors.account_end[0] }}
                                            </p>
                                        </transition>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-6 pb-0 pt-1">
                                        <label for="example-text-input"
                                            >Date Range From :</label
                                        >
                                        <date-picker
                                            class="input-group"
                                            v-model="date_start"
                                            format="DD/MM/YYYY"
                                            valueType="YYYY-MM-DD"
                                            placeholder="Choose Date"
                                            :class="{
                                                'is-invalid': errors.date_start
                                            }"
                                        ></date-picker>
                                        <transition name="slide-fade">
                                            <p
                                                class="text-danger"
                                                v-if="errors.date_start"
                                            >
                                                {{ errors.date_start[0] }}
                                            </p>
                                        </transition>
                                    </div>
                                    <div class="form-group col-6 pb-0 pt-1">
                                        <label for="example-text-input">
                                            To :</label
                                        >
                                        <date-picker
                                            class="input-group"
                                            v-model="date_end"
                                            format="DD/MM/YYYY"
                                            valueType="YYYY-MM-DD"
                                            placeholder="Choose Date"
                                            :class="{
                                                'is-invalid': errors.date_end
                                            }"
                                            :default-value="new Date()"
                                        ></date-picker>
                                        <transition name="slide-fade">
                                            <p
                                                class="text-danger"
                                                v-if="errors.date_end"
                                            >
                                                {{ errors.date_end[0] }}
                                            </p>
                                        </transition>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--END Form-->
                        <!-- Submit -->
                        <div class="row items-push">
                            <div class="col-12 text-center">
                                <p class="font-size-sm text-muted">
                                    * Require to be filled, Make sure all data is
                                    correctly filled
                                </p>
                                <router-link
                                    type="button"
                                    class="btn btn-sm btn-info"
                                    :to="{
                                        name:
                                            'trial-balance-cumulative-display',
                                        params: {
                                            date_start: date_start,
                                            date_end: date_end,
                                            account_start: account_start,
                                            account_end: account_end
                                        }
                                    }"
                                    tag="button"
                                    v-ripple="{ center: true }"
                                >
                                    <i class="fa fa-print mr-1"></i> Display
                                    Report
                                </router-link>
                                <!-- <button
                                    type="button"
                                    class="btn btn-sm btn-danger"
                                    v-ripple="{ center: true }"
                                    @click.prevent="pdfReport"
                                >
                                    <i class="fa fa-file-pdf mr-1"></i> Export to PDF
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-sm btn-success"
                                    v-ripple="{ center: true }"
                                    @click.prevent="xlsReport"
                                >
                                    <i class="fa fa-r fa-file-excel mr-1"></i> Export to Excel
                                </button> -->
                            </div>
                        </div>
                        <!-- END Submit -->
                    </form>
                </div>
            </div>
            <!-- END Basic -->
        </div>
        <!-- END Page Content -->
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import moment from "moment";

export default {
    name: "trial-balance",

    data() {
        return {
            loadingPage: 1,
            date_start: moment()
                .startOf("year")
                .format("YYYY-MM-DD"),
            date_end: moment().format("YYYY-MM-DD"),
            account_end: "",
            account_start: "",
            url: "",
            url2: "",
            exporting: ""
        };
    },
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState("coa", { coas: state => state.coas.accounts })
    },
    mounted() {
        this.getCoaAll()
            .then(response => {
                this.account_start = response.id_start.account_no;
                this.account_end = response.id_end.account_no;
                this.loadingPage = 0;
            })
            .catch(error => {
                this.alert(error.message, 2);
                this.loadingPage = 0;
            });
    },
    methods: {
        ...mapActions("coa", ["getCoaAll"]),
        ...mapActions("report", ["pdfReportTB", "xlsReportTB"]),

        //METHOD
        pdfReport() {
            this.loadingPage = 2;
            this.pdfReportTB({
                date_start: this.date_start,
                date_end: this.date_end,
                account_end: this.account_end,
                account_start: this.account_start
            })
                .then(response => {
                    this.loadingPage = 0;
                })
                .catch(error => {
                    console.log(error);
                    this.loadingPage = 0;
                });
        },
        xlsReport() {
            this.loadingPage = 2;
            this.xlsReportTB({
                date_start: this.date_start,
                date_end: this.date_end,
                account_end: this.account_end,
                account_start: this.account_start
            })
                .then(response => {
                    this.loadingPage = 0;
                })
                .catch(error => {
                    console.log(error);
                    this.loadingPage = 0;
                });
        }
    }
};
</script>
