<template>
    <main>
        <hero title="Asset Depreciation" prevBreadcrumb="Dashboard" routeBreadcrumb="home" currentBreadcrumb="Asset Depreciation"></hero>

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 1 || !depreciations.data"
                style="height: 250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <!-- Dynamic Table Full -->
            <div class="block block-rounded block-mode-loading-oneui" v-else>
                <div class="block-content block-content-full pb-0 pt-3">
                    <div class="row">
                        <div
                            class="col-12 col-md-7 col-lg-7 col-xl-7 mb-2 mb-sm-0 pb-0 px-0 d-md-none d-inline"
                        >
                            <label class="col-12 col-md-1 py-0">Date :</label>
                            <date-picker
                                v-model="date_start"
                                type="month"
                                format="MMMM YYYY"
                                valueType="YYYY-MM"
                                placeholder="Datepicker"
                                class="col-12"
                            ></date-picker>
                            <date-picker
                                v-model="date_end"
                                type="month"
                                format="MMMM YYYY"
                                valueType="YYYY-MM"
                                placeholder="Datepicker"
                                class="col-12"
                            ></date-picker>
                            <button
                                type="button"
                                class="btn btn-sm btn-info float-right mb-5 mb-md-0"
                                tag="button"
                                v-ripple="{ center: true }"
                                style="margin-top: 2px;"
                                @click="getData()"
                                :disabled="loadingPage == 2"
                            >
                                <i class="fa fa-calendar"></i> Apply Date
                            </button>
                        </div>
                        <div
                            class="col-12 col-md-7 col-lg-7 col-xl-7 mb-2 mb-sm-0 pb-0 d-md-inline d-none"
                        >
                            <label>Date :</label>
                            <date-picker
                                v-model="date_start"
                                type="month"
                                format="MMMM YYYY"
                                valueType="YYYY-MM"
                                placeholder="Datepicker"
                            ></date-picker>
                            <date-picker
                                v-model="date_end"
                                type="month"
                                format="MMMM YYYY"
                                valueType="YYYY-MM"
                                placeholder="Datepicker"
                            ></date-picker>
                            <button
                                type="button"
                                class="btn btn-sm btn-info ml-1 mb-5 mb-md-1"
                                tag="button"
                                v-ripple="{ center: true }"
                                @click="getData()"
                                :disabled="loadingPage == 2"
                            >
                                <i class="fa fa-calendar mr-1"></i> Apply Date
                            </button>
                        </div>
                        <div
                            class="col-12 col-md-5 col-lg-5 col-xl-5 pb-0 pt-0 pt-md-3"
                        >
                            <input
                                type="text"
                                class="form-control form-control col-12 col-md-8 float-right search-index"
                                name="example-text-input"
                                placeholder="Search.."
                                v-model="search"
                            />
                        </div>
                    </div>
                </div>
                <div class="block-content block-content-full pt-0">
                    <v-app>
                        <v-data-table
                            :headers="headers"
                            :items="datas"
                            :search="search"
                            :hide-default-footer="true"
                            :items-per-page="50"
                            :mobile-breakpoint="0"
                        >
                            <template
                                v-slot:top="{
                                    pagination,
                                    options,
                                    updateOptions,
                                }"
                            >
                                <div class="row">
                                    <div
                                        class="col-12 col-md-5 mb-2 mb-sm-0 pt-0 pb-2"
                                    >
                                        <v-layout justify-start>
                                            <v-data-footer
                                                :pagination="pagination"
                                                :options="options"
                                                @update:options="updateOptions"
                                                :items-per-page-options="[
                                                    10,
                                                    30,
                                                    50,
                                                    100,
                                                    -1,
                                                ]"
                                                items-per-page-text="Rows :"
                                            />
                                        </v-layout>
                                    </div>
                                    <div
                                        class="col-12 col-md-7 col-lg-7 col-xl-7 pb-0 pt-2"
                                    >
                                        <button
                                            type="button"
                                            class="btn btn-sm btn-alt-secondary float-right m-1 mb-5 mb-md-0"
                                            tag="button"
                                            v-ripple="{ center: true }"
                                            style="vertical-align: middle;"
                                            @click="getData()"
                                            :disabled="loadingPage == 2"
                                        >
                                            <i class="fa fa-sync"></i>
                                        </button>
                                        <router-link
                                            type="button"
                                            class="btn btn-sm btn-danger float-right btn-adjust-1"
                                            :to="{
                                                name: 'asset-depreciation-delete',
                                            }"
                                            tag="button"
                                            v-ripple="{ center: true }"
                                            :disabled="loadingPage == 2"
                                        >
                                            <i class="fa fa-trash mr-1"></i> Delete Depreciation
                                        </router-link>
                                        <router-link
                                            type="button"
                                            class="btn btn-sm btn-success float-right btn-adjust-1"
                                            :to="{
                                                name: 'asset-depreciation-create',
                                            }"
                                            tag="button"
                                            v-ripple="{ center: true }"
                                            :disabled="loadingPage == 2"
                                        >
                                            <i class="fa fa-plus mr-1"></i> New Depreciation
                                        </router-link>
                                    </div>
                                </div>
                            </template>
                            <template v-slot:body="{ items }">
                                <tbody v-if="loadingPage == 2">
                                    <tr class="data-empty">
                                        <td colspan="7" class="py-2">
                                            <grid-loader
                                                class="mx-auto mt-5 pt-5"
                                                :color="'#70B9EB'"
                                                :size="'10px'"
                                            ></grid-loader>
                                            <center class="mt-2 mb-5 pb-5">
                                                <span
                                                    class="text-center"
                                                    style="opacity: 0.7"
                                                    >Loading</span
                                                >
                                            </center>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody
                                    v-if="items.length > 0 && loadingPage == 0"
                                >
                                    <tr
                                        v-for="(depreciation, index) in items"
                                        :key="index"
                                    >
                                        <td
                                            @click="toDetail(depreciation.depreciationId)"
                                            class="text-center font-size-sm"
                                        >
                                            {{ ++index }}
                                        </td>
                                        <td
                                            @click="toDetail(depreciation.depreciationId)"
                                            class="font-size-sm text-center"
                                            align="center"
                                        >
                                            {{ depreciation.depreciationPeriod }}
                                        </td>
                                        <td
                                            @click="toDetail(depreciation.depreciationId)"
                                            class="font-size-sm text-left"
                                            style="max-width: 300px"
                                        >
                                            {{ depreciation.depreciationMemo}}
                                        </td>
                                        <td
                                            @click="toDetail(depreciation.depreciationId)"
                                            class="font-size-sm text-right"
                                            style="max-width: 300px"
                                        >
                                            {{ depreciation.depreciationTotal | currency }}
                                        </td>
                                        <!-- DROPDOWN AURORA -->
                                        <td align="right">
                                            <div
                                                class="dropdown dropleft push m-0"
                                            >
                                                <button
                                                    type="button"
                                                    class="btn btn-sm btn-alt-secondary btn-transparent btn-rounded text--lighten-4"
                                                    id="dropdown-dropleft"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    <i
                                                        class="fa fa-fw fa-ellipsis-v"
                                                    ></i>
                                                </button>
                                                <div
                                                    class="dropdown-menu dropdown-menu-rounded font-size-sm px-1"
                                                    aria-labelledby="dropdown-dropleft"
                                                >
                                                    <button
                                                        @click="
                                                            toDetail(
                                                                depreciation.depreciationId
                                                            )
                                                        "
                                                        class="btn btn-sm btn-transparent btn-alt-info dropdown-item-horizontal text-info"
                                                        v-ripple="{
                                                            center: true,
                                                        }"
                                                        style="
                                                            position: inline-block;
                                                        "
                                                    >
                                                        <i
                                                            class="si si-magnifier mr-1"
                                                        ></i>
                                                        <span
                                                            class="d-none d-md-inline"
                                                            >Detail</span
                                                        >
                                                    </button>
                                                    <button
                                                        @click="
                                                            toJournalVoucher(
                                                                depreciation.depreciationJournalId
                                                            )
                                                        "
                                                        class="btn btn-sm btn-transparent btn-alt-warning dropdown-item-horizontal text-warning"
                                                        v-ripple="{
                                                            center: true,
                                                        }"
                                                        style="
                                                            position: inline-block;
                                                        "
                                                    >
                                                        <i
                                                            class="si si-notebook mr-1"
                                                        ></i>
                                                        <span
                                                            class="d-none d-md-inline"
                                                            >Journal</span
                                                        >
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody
                                    v-if="items.length <= 0 && loadingPage == 0"
                                >
                                    <tr class="data-empty">
                                        <td
                                            class="text-center"
                                            colspan="7"
                                            style="
                                                opacity: 0.8;
                                                padding: 2.5rem;
                                            "
                                        >
                                            <center>
                                                <img
                                                    :src="'/media/proxima/empty-data.svg'"
                                                    style="height: 100px"
                                                />
                                            </center>
                                            <br /><span style="opacity: 0.6"
                                                >Data Not Found</span
                                            >
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-data-table>
                    </v-app>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";

export default {
    name: "depreciation-register",
    data() {
        return {
            search: "",
            awaitingSearch: false,
            rows: "All",
            loadingPage: 1,
            date_start: moment().startOf("year").format("YYYY-MM"),
            date_end: moment().endOf("year").format("YYYY-MM"),
            amountExp: 0,
            totalDepreciation: 0,
            headers: [
                { text: "No.", align: "center", sortable: false },
                { text: "Period", align: "center", value: "depreciationPeriod" },
                { text: "Depreciation Memo", align: "center", value: "depreciationMemo" },
                { text: "Depreciation Amount", align: "center", value: "depreciationTotal" },
                { text: "", sortable: false },
            ],
            datas: [],
        };
    },
    created() {
        this.getData();
        this.$session.remove("depreciation_detail");
    },
    computed: {
        ...mapState("depreciation", { depreciations: (state) => state.depreciations }),
        ...mapState(["auth"]),
    },
    methods: {
        ...mapActions("depreciation", ["getDepreciation", "removeDepreciation"]),
        getData() {
            this.loadingPage = 2;
            this.datas = [];
            this.getDepreciation({ 
                rows: this.rows, 
                type: this.type,
                date_start: new Date(this.date_start).toDateString(),
                date_end: new Date(this.date_end).toDateString() 
            })
                .then(() => {
                    this.depreciations.data.forEach((element) => {
                        this.datas.push({
                            depreciationId: element.id,
                            depreciationPeriod: moment(
                                element.depreciation_period
                            ).format("DD/MM/YYYY"),
                            depreciationMemo: element.depreciation_memo,
                            depreciationTotal: element.depreciation_total,
                            depreciationJournalId: element.journal_id,
                        });
                    });
                    this.loadingPage = 0;
                })
                .catch((error) => {
                    error.response.status == 500
                        ? this.alert(error.response.data.message, 2)
                        : "";
                    error.response.status != 422 && error.response.status != 500
                        ? this.alert(error.message, 2)
                        : "";
                    this.loadingPage = 0;
                    this.totalDepreciation = 0;
                });
        },
        toDetail(val) {
            this.$router.push({ name: "asset-depreciation-detail", params: { id: val } });
        },
        toJournalVoucher(val) {
            this.$router.push({
                name: "journal-transaction-voucher",
                params: { id: val },
            });
        },
    },
};
</script>
