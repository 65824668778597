<template>
    <main>
        <!-- Hero -->
        <div class="bg-body-light">
            <div class="content content-full">
                <div
                    class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center"
                >
                    <h1 class="flex-sm-fill h3 my-2">
                        Accounting : New Outgoing Item
                    </h1>
                    <nav
                        class="flex-sm-00-auto ml-sm-3"
                        aria-label="breadcrumb"
                    >
                        <ol class="breadcrumb breadcrumb-alt">
                            <li class="breadcrumb-item" aria-current="page">
                                <router-link
                                    class="link-fx"
                                    :to="{ name: 'outgoing-item' }"
                                    >Outgoing Items</router-link
                                >
                            </li>
                            <li class="breadcrumb-item">New Outgoing Item</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
        <!-- END Hero -->

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 1"
                style="height: 250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 2"
                style="height: 250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Save Data</span
                        >
                    </center>
                </div>
            </div>
            <!-- Basic -->
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 0"
            >
                <div class="block-content block-content-full">
                    <form
                        method="POST"
                        enctype="multipart/form-data"
                        onsubmit="return false;"
                    >
                        <!--Form-->
                        <div class="row font-size-sm">
                            <div class="col-12 py-0">
                                <div class="row">
                                    <div class="col-12 col-lg-6 pt-0">
                                        <label style="opacity: 0.7"
                                            >Mutation Data</label
                                        >
                                        <hr class="mt-2 mb-3" />

                                        <div class="row">
                                            <div
                                                class="form-group col-12 pb-1 pt-0"
                                            >
                                                <label for="example-text-input"
                                                    >Mutation Date *</label
                                                >
                                                <date-picker
                                                    class="input-group"
                                                    v-model="
                                                        mutation.mutation_date
                                                    "
                                                    format="DD/MM/YYYY HH:mm"
                                                    valueType="YYYY-MM-DD HH:mm"
                                                    type="datetime"
                                                    placeholder="Mutation Date"
                                                    :class="{
                                                        'is-invalid':
                                                            errors.mutation_date,
                                                    }"
                                                    :default-value="new Date()"
                                                    :disabled-date="
                                                        disabledDatePicker
                                                    "
                                                    @input="changeDate()"
                                                ></date-picker>
                                                <transition name="slide-fade">
                                                    <p
                                                        class="text-danger"
                                                        v-if="
                                                            errors.mutation_date
                                                        "
                                                    >
                                                        {{
                                                            errors
                                                                .mutation_date[0]
                                                        }}
                                                    </p>
                                                </transition>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div
                                                class="form-group col-12 pb-1 pt-0"
                                            >
                                                <label for="example-text-input"
                                                    >Mutation Number *</label
                                                >
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="Mutation Number"
                                                    v-model="
                                                        mutation.mutation_no
                                                    "
                                                    :class="{
                                                        'is-invalid':
                                                            errors.mutation_no,
                                                    }"
                                                />
                                                <transition name="slide-fade">
                                                    <p
                                                        class="text-danger"
                                                        v-if="
                                                            errors.mutation_no
                                                        "
                                                    >
                                                        {{
                                                            errors
                                                                .mutation_no[0]
                                                        }}
                                                    </p>
                                                </transition>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-6 pt-0">
                                        <label style="opacity: 0.7"
                                            >Mutation Detail</label
                                        >
                                        <hr class="mt-2 mb-3" />
                                        <div class="form-group">
                                            <label for="example-text-input"
                                                >Warehouse *</label
                                            >
                                            <selectize
                                                class="form-control"
                                                placeholder="Choose Wirehouse"
                                                v-model="mutation.warehouse_id"
                                                :class="{
                                                    'is-invalid-selectize':
                                                        errors.warehouse_id,
                                                }"
                                                @input="clearDetail()"
                                            >
                                                <!-- settings is optional -->
                                                <option
                                                    v-for="warehouse in warehouses"
                                                    :value="warehouse.id"
                                                    :key="warehouse.id"
                                                >
                                                    {{
                                                        warehouse.warehouse_name
                                                    }}
                                                </option>
                                            </selectize>
                                            <transition name="slide-fade">
                                                <p
                                                    class="text-danger"
                                                    v-if="errors.warehouse_id"
                                                >
                                                    {{ errors.warehouse_id[0] }}
                                                </p>
                                            </transition>
                                        </div>
                                        <div class="form-group">
                                            <label for="example-text-input"
                                                >Mutation Memo *</label
                                            >
                                            <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Mutation Memo"
                                                v-model="mutation.mutation_memo"
                                                :class="{
                                                    'is-invalid':
                                                        errors.mutation_memo,
                                                }"
                                            />
                                            <transition name="slide-fade">
                                                <p
                                                    class="text-danger"
                                                    v-if="errors.mutation_memo"
                                                >
                                                    {{
                                                        errors.mutation_memo[0]
                                                    }}
                                                </p>
                                            </transition>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div class="table-responsive">
                                        <table
                                            class="table table-vcenter table-borderless mb-0"
                                        >
                                            <thead
                                                style="
                                                    background-color: whitesmoke;
                                                "
                                            >
                                                <tr>
                                                    <th
                                                        class="table-header-relative column-minwidth-250"
                                                    >
                                                        Item
                                                    </th>
                                                    <th
                                                        class="table-header-relative"
                                                        style="width: 100px"
                                                    >
                                                        Qty.
                                                    </th>
                                                    <th
                                                        class="table-header-relative"
                                                        style="width: 100px"
                                                    >
                                                        Unit
                                                    </th>
                                                    <th
                                                        class="table-header-relative column-minwidth-150"
                                                    >
                                                        Unit Price
                                                    </th>
                                                    <th
                                                        class="table-header-relative column-minwidth-150"
                                                    >
                                                        Total
                                                    </th>
                                                    <th
                                                        class="table-header-relative"
                                                        width="50px"
                                                    ></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    v-for="(
                                                        detail, index
                                                    ) in mutation.detail"
                                                    :key="index"
                                                >
                                                    <td
                                                        class="font-size-sm data-detail"
                                                    >
                                                        <selectize
                                                            class="form-control mt-1"
                                                            placeholder="Choose Item"
                                                            v-model="
                                                                detail.item_id
                                                            "
                                                            :class="{
                                                                'is-invalid-selectize':
                                                                    errors[
                                                                        'detail.' +
                                                                            index +
                                                                            '.item_id'
                                                                    ],
                                                            }"
                                                            @input="
                                                                getItemDetail(
                                                                    index,
                                                                    detail.item_id
                                                                )
                                                            "
                                                        >
                                                            <!-- settings is optional -->
                                                            <option
                                                                v-for="(
                                                                    item,
                                                                    indexItem
                                                                ) in items"
                                                                :value="item.id"
                                                                :key="indexItem"
                                                            >
                                                                {{
                                                                    item.item_name
                                                                }}
                                                            </option>
                                                        </selectize>
                                                        <span
                                                            v-if="
                                                                errors[
                                                                    'detail.' +
                                                                        index +
                                                                        '.item_id'
                                                                ]
                                                            "
                                                            class="text-danger data-detail-error"
                                                        >
                                                            {{
                                                                errors[
                                                                    "detail." +
                                                                        index +
                                                                        ".item_id"
                                                                ][0]
                                                            }}
                                                        </span>
                                                    </td>
                                                    <td class="data-detail">
                                                        <input
                                                            type="text"
                                                            class="form-control text-right"
                                                            placeholder="Qty"
                                                            min="0"
                                                            v-model="
                                                                detail.out_qty
                                                            "
                                                            :class="{
                                                                'is-invalid':
                                                                    errors[
                                                                        'detail.' +
                                                                            index +
                                                                            '.out_qty'
                                                                    ],
                                                            }"
                                                            v-on:blur="
                                                                calculateItem(
                                                                    index
                                                                )
                                                            "
                                                        />
                                                        <span
                                                            v-if="
                                                                errors[
                                                                    'detail.' +
                                                                        index +
                                                                        '.out_qty'
                                                                ]
                                                            "
                                                            class="text-danger data-detail-error"
                                                        >
                                                            {{
                                                                errors[
                                                                    "detail." +
                                                                        index +
                                                                        ".out_qty"
                                                                ][0]
                                                            }}
                                                        </span>
                                                        <span
                                                            v-if="
                                                                detail.error_cogs ==
                                                                true
                                                            "
                                                            class="text-danger data-detail-error"
                                                        >
                                                            Qty > Stock
                                                        </span>
                                                    </td>
                                                    <td
                                                        class="font-size-sm data-detail"
                                                    >
                                                        <selectize
                                                            class="form-control mt-1"
                                                            placeholder="-"
                                                            v-model="
                                                                detail.uom_id
                                                            "
                                                            :class="{
                                                                'is-invalid-selectize':
                                                                    errors[
                                                                        'detail.' +
                                                                            index +
                                                                            '.uom_id'
                                                                    ],
                                                            }"
                                                            :disabled="
                                                                detail.item_id ==
                                                                    '' ||
                                                                detail.item_id ==
                                                                    0
                                                            "
                                                        >
                                                            <!-- settings is optional -->
                                                            <option
                                                                v-for="uom in detail.item_uom_list"
                                                                :value="uom.id"
                                                                :key="uom.id"
                                                            >
                                                                <!-- {{
                                                                    uom.uom_name
                                                                }}
                                                                ( -->
                                                                {{
                                                                    uom.uom_code
                                                                }}
                                                                <!-- ) -->
                                                            </option>
                                                        </selectize>
                                                        <span
                                                            v-if="
                                                                errors[
                                                                    'detail.' +
                                                                        index +
                                                                        '.uom_id'
                                                                ]
                                                            "
                                                            class="text-danger data-detail-error"
                                                        >
                                                            {{
                                                                errors[
                                                                    "detail." +
                                                                        index +
                                                                        ".uom_id"
                                                                ][0]
                                                            }}
                                                        </span>
                                                    </td>
                                                    <td
                                                        class="font-size-sm data-detail"
                                                    >
                                                        <money
                                                            class="form-control"
                                                            style="
                                                                text-align: right;
                                                            "
                                                            v-model="
                                                                detail.out_price
                                                            "
                                                            spellcheck="false"
                                                            disabled
                                                            :class="{
                                                                'is-invalid':
                                                                    errors[
                                                                        'detail.' +
                                                                            index +
                                                                            '.out_price'
                                                                    ],
                                                            }"
                                                        ></money>
                                                        <span
                                                            v-if="
                                                                errors[
                                                                    'detail.' +
                                                                        index +
                                                                        '.out_price'
                                                                ]
                                                            "
                                                            class="text-danger data-detail-error"
                                                        >
                                                            {{
                                                                errors[
                                                                    "detail." +
                                                                        index +
                                                                        ".out_price"
                                                                ][0]
                                                            }}
                                                        </span>
                                                    </td>
                                                    <td
                                                        class="font-size-sm data-detail"
                                                    >
                                                        <money
                                                            class="form-control"
                                                            style="
                                                                text-align: right;
                                                            "
                                                            v-model="
                                                                detail.out_amount
                                                            "
                                                            spellcheck="false"
                                                            disabled
                                                            :class="{
                                                                'is-invalid':
                                                                    errors[
                                                                        'detail.' +
                                                                            index +
                                                                            '.out_amount'
                                                                    ],
                                                            }"
                                                        ></money>
                                                        <span
                                                            v-if="
                                                                errors[
                                                                    'detail.' +
                                                                        index +
                                                                        '.out_amount'
                                                                ]
                                                            "
                                                            class="text-danger data-detail-error"
                                                        >
                                                            {{
                                                                errors[
                                                                    "detail." +
                                                                        index +
                                                                        ".out_amount"
                                                                ][0]
                                                            }}
                                                        </span>
                                                    </td>
                                                    <td
                                                        class="font-size-sm"
                                                        align="center"
                                                    >
                                                        <a
                                                            class="btn-icon-only btn-icon-danger"
                                                            v-if="index > 0"
                                                            @click="
                                                                removeDetail(
                                                                    index
                                                                )
                                                            "
                                                        >
                                                            <i
                                                                class="fa fa-trash"
                                                            ></i>
                                                        </a>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        class="font-size-sm pb-0 pt-3"
                                                        colspan="6"
                                                    >
                                                        <a
                                                            class="btn-icon-only icon-succes hover-hr-table"
                                                            @click="addDetail()"
                                                        >
                                                            <i
                                                                class="fa fa-plus"
                                                            ></i>
                                                            New Item Line
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        class="font-size-sm"
                                                        colspan="6"
                                                    >
                                                        <hr
                                                            class="hr-table opacity-25"
                                                        />
                                                        <p
                                                            v-if="
                                                                checkDuplicate
                                                            "
                                                            class="text-danger mb-1"
                                                        >
                                                            There are duplicate
                                                            items, items cannot
                                                            be duplicated in one
                                                            mutation
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        class="font-size-sm"
                                                        colspan="6"
                                                    >
                                                        <div
                                                            class="col-lg-5 float-right"
                                                            style="
                                                                background-color: #e9ecef !important;
                                                                width: 350px;
                                                                padding-top: 10px;
                                                            "
                                                        >
                                                            <strong>
                                                                <p class="mb-1">
                                                                    Grand Total
                                                                    <span
                                                                        class="float-right"
                                                                        >{{
                                                                            mutation.mutation_total
                                                                                | currency
                                                                        }}</span
                                                                    >
                                                                </p>
                                                            </strong>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        align="center"
                                                        colspan="6"
                                                    >
                                                        <div
                                                            class="col-12 text-center mb-0 pb-0 d-none d-sm-inline-block mt-2"
                                                        >
                                                            <p
                                                                class="font-size-sm text-muted mb-0"
                                                            >
                                                                * Required
                                                                filled, Make
                                                                sure all data is
                                                                correctly filled
                                                            </p>
                                                        </div>
                                                        <div
                                                            class="col-12 text-center d-none d-sm-inline-block"
                                                        >
                                                            <button
                                                                v-if="
                                                                    checkDuplicate
                                                                "
                                                                type="button"
                                                                class="btn btn-sm btn-success mb-1"
                                                                v-ripple="{
                                                                    center: true,
                                                                }"
                                                                disabled
                                                            >
                                                                <i
                                                                    class="fa fa-fw fa-check mr-1"
                                                                ></i>
                                                                Save
                                                            </button>
                                                            <button
                                                                v-else
                                                                type="button"
                                                                class="btn btn-sm btn-success mb-1"
                                                                v-ripple="{
                                                                    center: true,
                                                                }"
                                                                @click="
                                                                    saveMutation()
                                                                "
                                                            >
                                                                <i
                                                                    class="fa fa-fw fa-check mr-1"
                                                                ></i>
                                                                Save
                                                            </button>
                                                            <router-link
                                                                type="button"
                                                                class="btn btn-sm btn-alt-light mb-1"
                                                                tag="button"
                                                                v-ripple="{
                                                                    center: true,
                                                                }"
                                                                :to="{
                                                                    name:
                                                                        'outgoing-item',
                                                                }"
                                                            >
                                                                <i
                                                                    class="fa fa-fw fa-times mr-1"
                                                                ></i>
                                                                Close
                                                            </router-link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <p
                                            class="text-muted font-size-sm d-inline d-sm-none"
                                            style="opacity: 0.5"
                                        >
                                            Swipe left here to see full column
                                            >>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--END Form-->
                        <!-- Submit -->
                        <div class="row items-push d-inline d-sm-none">
                            <div class="col-12 text-center mb-0 pb-0">
                                <p class="font-size-sm text-muted mb-0">
                                    * Required filled, Make sure all data is
                                    correctly filled
                                </p>
                            </div>
                            <div class="col-12 text-center">
                                <button
                                    type="button"
                                    class="btn btn-sm btn-info mb-1"
                                    v-ripple="{ center: true }"
                                    @click="printSaveBill()"
                                >
                                    <i class="fa fa-print mr-1"></i> Save &
                                    Print
                                </button>
                                <button
                                    v-if="checkDuplicate"
                                    type="button"
                                    class="btn btn-sm btn-success mb-1"
                                    v-ripple="{ center: true }"
                                    disabled
                                >
                                    <i class="fa fa-fw fa-check mr-1"></i> Save
                                </button>
                                <button
                                    v-else
                                    type="button"
                                    class="btn btn-sm btn-success mb-1"
                                    v-ripple="{ center: true }"
                                    @click="saveMutation()"
                                >
                                    <i class="fa fa-fw fa-check mr-1"></i> Save
                                </button>
                                <router-link
                                    type="button"
                                    class="btn btn-sm btn-alt-light mb-1"
                                    tag="button"
                                    v-ripple="{ center: true }"
                                    :to="{ name: 'outgoing-item' }"
                                >
                                    <i class="fa fa-fw fa-times mr-1"></i> Close
                                </router-link>
                            </div>
                        </div>
                        <!-- END Submit -->
                    </form>
                </div>
            </div>
            <!-- END Basic -->
        </div>
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import moment from "moment";

export default {
    name: "mutation-enter",
    data() {
        return {
            loadingPage: 0,
            disabled: {},
            coaMutations: [],
        };
    },
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState("mutation", { mutation: (state) => state.mutation }),
        ...mapState("warehouse", { warehouses: (state) => state.warehouses }),
        ...mapState("uom", { uoms: (state) => state.uoms }),
        ...mapState("item", { items: (state) => state.items }),
        ...mapState("forbiddendate", {
            forbiddenDateLast: (state) => state.forbiddenDateLast,
        }),
        disableSave() {
            if (this.mutation.bill_due_date < this.mutation.mutation_date) {
                return true;
            }
            if (this.mutation.bill_grand_total == 0) {
                return true;
            }
        },
        checkDuplicate() {
            var valueArr = this.mutation.detail.map(function (item, index) {
                if (
                    item.item_id !== 0 &&
                    item.item_id !== null &&
                    item.item_id !== ""
                ) {
                    return item.item_id;
                } else {
                    return index;
                }
            });
            var isDuplicate = valueArr.some(function (item, idx) {
                return valueArr.indexOf(item) != idx;
            });
            if (isDuplicate == true) {
                return true;
            } else {
                return false;
            }
        },
    },
    created() {
        this.getWarehouseAll();
        this.getItemAll();
        this.getUomAll();
        this.getMutationOutNo();
        this.getForbiddenDate();
        this.getJournalMemo();
    },
    methods: {
        //VUEX
        ...mapMutations("mutation", ["CLEAR_FORM"]),
        ...mapActions("warehouse", ["getWarehouseAll"]),
        ...mapMutations(["CLEAR_ERRORS"]),
        ...mapActions("uom", ["getUomAll"]),
        ...mapActions("item", ["getItemAll", "getCOGSRequest"]),
        ...mapActions("mutation", [
            "getMutationOutNo",
            "submitMutation",
            "printMutation",
        ]),
        ...mapActions("forbiddendate", ["getForbiddenDate"]),
        disabledDatePicker(date) {
            const lastDisableDate = new Date(
                moment(this.forbiddenDateLast).add(1, "days")
            );
            lastDisableDate.setHours(0, 0, 0, 0);
            return date < lastDisableDate;
        },
        clearDetail() {
            this.mutation.detail = [
                {
                    item_id: 0,
                    coa_id: 0,
                    in_qty: 0,
                    in_amount: 0,
                    in_price: 0,
                    out_qty: 0,
                    out_amount: 0,
                    out_price: 0,
                    item_uom_list: [],
                    item_uom_id: 0,
                    error_cogs: false,
                },
            ];
        },
        changeDate() {
            this.getMutationOutNo();
            this.getJournalMemo();
            this.clearDetail();
        },
        getJournalMemo() {
            this.mutation.mutation_memo = "Mutation Out";
        },
        getCOGS(index, val) {
            if (
                this.mutation.detail[index].out_qty != null &&
                this.mutation.detail[index].out_qty != 0 &&
                val != null &&
                val != 0 &&
                this.mutation.warehouse_id != null &&
                this.mutation.warehouse_id != 0
            ) {
                this.mutation.detail[index].error_cogs = false;
                this.mutation.detail[index].out_price = 0;
                this.getCOGSRequest({
                    warehouse: this.mutation.warehouse_id,
                    date: this.mutation.mutation_date,
                    item: val,
                    qty: this.mutation.detail[index].out_qty,
                })
                    .then((response) => {
                        if (response == -1) {
                            this.mutation.detail[index].error_cogs = true;
                            this.mutation.detail[index].out_price = 0;
                            this.mutation.detail[index].out_amount = 0;
                            this.calculate();
                        } else {
                            this.mutation.detail[index].out_price = response;
                            this.mutation.detail[index].out_amount =
                                this.mutation.detail[index].out_qty *
                                this.mutation.detail[index].out_price;
                            this.calculate();
                        }
                        this.loadingPage = 0;
                    })
                    .catch((error) => {
                        error.response.status == 500
                            ? this.alert(error.response.data.message, 2)
                            : "";
                        error.response.status != 422 &&
                        error.response.status != 500
                            ? this.alert(error.message, 2)
                            : "";
                        this.loadingPage = 0;
                        this.totalItem = 0;
                    });
            } else {
                this.mutation.detail[index].error_cogs = false;
                this.mutation.detail[index].out_price = 0;
                this.mutation.detail[index].out_amount = 0;
                this.calculate();
            }
        },
        getItemDetail(index, val) {
            this.getCOGS(index, val);
            this.mutation.detail[index].item_uom_list = [];
            this.mutation.detail[index].uom_id = "";
            if (val != null && val != 0) {
                var item = this.items.find((obj) => {
                    return obj.id === parseInt(val);
                });
                var result = this.uoms.filter((obj) => {
                    return (
                        obj.id === parseInt(item.uom_id)
                        // || obj.uom_conversion_head === parseInt(item.uom_id)
                    );
                });
                this.mutation.detail[index].item_uom_list = result;
                this.mutation.detail[index].uom_id = result[0].id;
            }
            this.calculate();
        },
        addDetail() {
            this.mutation.detail.push({
                item_id: 0,
                coa_id: 0,
                in_qty: 0,
                in_amount: 0,
                in_price: 0,
                out_qty: 0,
                out_amount: 0,
                out_price: 0,
                item_uom_list: [],
                uom_id: 0,
                error_cogs: false,
            });
        },
        removeDetail(index) {
            this.mutation.detail.splice(index, 1);
        },
        calculate() {
            var total = 0;
            this.mutation.detail.forEach((detail) => {
                total += detail.out_amount;
            });
            this.mutation.mutation_total = total;
        },
        calculateItem(index) {
            this.getCOGS(index, this.mutation.detail[index].item_id);
        },
        saveMutation() {
            this.CLEAR_ERRORS();
            this.loadingPage = 2;
            this.mutation.mutation_type = 2;
            this.submitMutation()
                .then((response) => {
                    console.log(response);
                    this.CLEAR_FORM();
                    this.alert("Successfully create Mutation Data ", 1);
                    this.getJournalMemo();

                    this.loadingPage = 0;
                })
                .catch((error) => {
                    error.response.status == 500
                        ? this.alert(error.response.data.message, 2)
                        : "";
                    error.response.status != 422 && error.response.status != 500
                        ? this.alert(error.message, 2)
                        : "";
                    this.loadingPage = 0;
                });
        },
    },
    destroyed() {
        this.CLEAR_FORM();
    },
};
</script>