import Vue from "vue";
import Vuex from "vuex";
import router from "./router";

import auth from "./stores/auth.js";
import user from "./stores/admin/user.js";
import news from "./stores/admin/news.js";
import signature from "./stores/admin/signature.js";
import bank from "./stores/admin/bank.js";
import coa from "./stores/accounting/coa.js";
import journal from "./stores/accounting/journal.js";
import recurringjournal from "./stores/accounting/recurringjournal.js";
import bill from "./stores/accounting/bill.js";
import purchase from "./stores/accounting/purchase.js";
import receivepayment from "./stores/accounting/receivepayment.js";
import paypurchase from "./stores/accounting/paypurchase.js";
import expense from "./stores/accounting/expense.js";
import income from "./stores/accounting/income.js";
import contact from "./stores/datastore/contact.js";
import tax from "./stores/datastore/tax.js";
import asset from "./stores/accounting/asset.js";
import depreciation from "./stores/accounting/depreciation.js";
import assetcategory from "./stores/accounting/assetcategory.js";
import item from "./stores/inventory/item.js";
import itemtype from "./stores/inventory/itemtype.js";
import warehouse from "./stores/datastore/warehouse.js";
import uom from "./stores/datastore/uom.js";
import mutation from "./stores/inventory/mutation.js";
import forbiddendate from "./stores/accounting/forbiddendate.js";
import report from "./stores/accounting/report.js";
import startnewyear from "./stores/accounting/startnewyear.js";
import workspace from "./stores/master/workspace.js";
import profile from "./stores/master/profile.js";
import activity from "./stores/master/activity.js";

import affiliate from "./stores/affiliate.js";

import notification from "./stores/admin/notification.js";

import SecureLS from "secure-ls";
var ls = new SecureLS({ isCompression: false, encryptionSecret: "d3v3L0P3r!" });

Vue.use(Vuex);
Vue.use(require("vue-moment"));

const store = new Vuex.Store({
    modules: {
        auth,
        profile,
        activity,
        user,
        news,
        signature,
        bank,
        coa,
        journal,
        expense,
        income,
        recurringjournal,
        bill,
        purchase,
        receivepayment,
        paypurchase,
        contact,
        tax,
        asset,
        assetcategory,
        depreciation,
        item,
        itemtype,
        warehouse,
        uom,
        mutation,
        workspace,
        forbiddendate,
        report,
        startnewyear,
        affiliate,
        notification
    },
    state: {
        // [#BASE002 --2] Get the token which already state on local storage
        token: localStorage.getItem("_tk__"),
        // END [#BASE002 --2]
        // [#BASE003]
        errors: []
        // END [#BASE003]
    },
    getters: {
        isAuth: state => {
            return state.token != "null" && state.token != null;
        },
        role: state => {
            return state.auth.role;
        },
        type: state => {
            return state.auth.type;
        }
    },
    mutations: {
        // [#BASE002 --2]
        SET_TOKEN(state, payload) {
            state.token = payload;
        },
        SET_AUTH(state, payload) {
            state.auth.data = payload.data;
            state.auth.role = payload.role;
            state.auth.daylimit = payload.daylimit;
            state.auth.type = payload.type;
        },
        // END [#BASE002 --2]
        // [#BASE003]
        SET_ERRORS(state, payload) {
            state.errors = payload;
        },
        CLEAR_ERRORS(state) {
            state.errors = [];
        }
        // END [#BASE003]
    },
    actions: {
        CLEAR_STATE({ commit }) {
            commit("user/CLEAR_DATA");
            commit("news/CLEAR_DATA");
            commit("signature/CLEAR_DATA");
            commit("bank/CLEAR_DATA");
            commit("coa/CLEAR_DATA");
            commit("journal/CLEAR_DATA");
            commit("recurringjournal/CLEAR_DATA");
            commit("contact/CLEAR_DATA");
            commit("bill/CLEAR_DATA");
            commit("coa/CLEAR_DATA");
            commit("contact/CLEAR_DATA");
            commit("expense/CLEAR_DATA");
            commit("income/CLEAR_DATA");
            commit("paypurchase/CLEAR_DATA");
            commit("purchase/CLEAR_DATA");
            commit("receivepayment/CLEAR_DATA");
            commit("recurringjournal/CLEAR_DATA");
        },
        //KALAU AKSES API SUDAH UNAUTHORIZE KARENA LOGIN DI DEVICE LAIN
        DO_LOGOUT({ commit, dispatch, state }) {
            Vue.$swal({
                title: "You have logged out from system",
                text:
                    "This is because you are logged in on another device, or there is a change in your data, re-login to continue session",
                icon: "warning"
            });
            return new Promise((resolve, reject) => {
                localStorage.removeItem("_tk__"); //MENGHAPUS TOKEN DARI LOCALSTORAGE
                ls.remove("_ath_"); //MENGHAPUS auth DARI LOCALSTORAGE
                ls.remove("_wk_spc_"); //MENGHAPUS auth DARI LOCALSTORAGE
                ls.remove("_rl_"); //MENGHAPUS role dari LOCALSTORAGE
                ls.remove("_dl_"); //MENGHAPUS daylimit dari LOCALSTORAGE
                ls.remove("_tp_"); //MENGHAPUS type dari LOCALSTORAGE
                resolve();
            }).then(() => {
                dispatch("CLEAR_STATE");
                //MEMPERBAHARUI STATE TOKEN
                state.token = localStorage.getItem("_tk__");
                state.auth.data = ls.get("_ath_");
                state.auth.role = ls.get("_rl_");
                state.auth.daylimit = ls.get("_dl_");
                state.auth.type = ls.get("_tp_");
                router.push("/login"); //REDIRECT KE PAGE LOGIN
            });
        },
        DO_LOGOUT_LIMIT({ commit, dispatch, state }) {
            Vue.$swal({
                title: "Masa Trial Anda Sudah Habis",
                text:
                    "Untuk menggunakan Proxima secara penuh, silahkan klik Registrasi Berlangganan",
                icon: "warning"
            });
            return new Promise((resolve, reject) => {
                localStorage.removeItem("_tk__"); //MENGHAPUS TOKEN DARI LOCALSTORAGE
                ls.remove("_ath_"); //MENGHAPUS auth DARI LOCALSTORAGE
                ls.remove("_wk_spc_"); //MENGHAPUS auth DARI LOCALSTORAGE
                ls.remove("_rl_"); //MENGHAPUS role dari LOCALSTORAGE
                ls.remove("_dl_"); //MENGHAPUS daylimit dari LOCALSTORAGE
                ls.remove("_tp_"); //MENGHAPUS type dari LOCALSTORAGE
                resolve();
            }).then(() => {
                dispatch("CLEAR_STATE");
                //MEMPERBAHARUI STATE TOKEN
                state.token = localStorage.getItem("_tk__");
                state.auth.data = ls.get("_ath_");
                state.auth.role = ls.get("_rl_");
                state.auth.daylimit = ls.get("_dl_");
                state.auth.type = ls.get("_tp_");
                router.push("/login"); //REDIRECT KE PAGE LOGIN
            });
        }
    }
});

export default store;
