<template>
    <main>
        <hero title="Edit Bank List" prevBreadcrumb="Bank List" routeBreadcrumb="bank" currentBreadcrumb="Edit Bank List"></hero>


        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 1"
                style="height:250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity:0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 2"
                style="height:250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity:0.7"
                            >Save Data</span
                        >
                    </center>
                </div>
            </div>
            <!-- Basic -->
            <div
                class="block col-md-8 offset-md-2 block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 0"
            >
                <div class="block-content block-content-full">
                    <form
                        method="POST"
                        enctype="multipart/form-data"
                        onsubmit="return false;"
                    >
                        <!--Form-->
                        <div class="row push font-size-sm">
                            <div class="col-12 col-md-10 offset-md-1">
                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Bank Name</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Bank Name"
                                        v-model="bank.bank_name"
                                        :class="{
                                            'is-invalid': errors.bank_name
                                        }"
                                    />
                                    <p
                                        class="text-danger"
                                        v-if="errors.bank_name"
                                    >
                                        {{ errors.bank_name[0] }}
                                    </p>
                                </div>
                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Branch of Bank</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Branch of Bank"
                                        v-model="bank.bank_cabang"
                                        :class="{
                                            'is-invalid': errors.bank_cabang
                                        }"
                                    />
                                    <p
                                        class="text-danger"
                                        v-if="errors.bank_cabang"
                                    >
                                        {{ errors.bank_cabang[0] }}
                                    </p>
                                </div>
                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Bank Account No.</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Bank Account No."
                                        v-model="bank.bank_rekening"
                                        @input="mustNumber"
                                        :class="{
                                            'is-invalid': errors.bank_rekening
                                        }"
                                    />
                                    <p
                                        class="text-danger"
                                        v-if="errors.bank_rekening"
                                    >
                                        {{ errors.bank_rekening[0] }}
                                    </p>
                                </div>
                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Bank Account Name</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Bank Account Name"
                                        v-model="bank.bank_rekening_name"
                                        :class="{
                                            'is-invalid':
                                                errors.bank_rekening_name
                                        }"
                                    />
                                    <p
                                        class="text-danger"
                                        v-if="errors.bank_rekening_name"
                                    >
                                        {{ errors.bank_rekening_name[0] }}
                                    </p>
                                </div>
                                <div class="form-group font-size-sm">
                                    <label class="d-block"
                                        >Document Type :</label
                                    >
                                    <div class="row py-1 px-3">
                                        <div
                                            class="col-6 col-md-4 py-2 pr-0 form-check form-check-inline mr-0"
                                        >
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                v-model="
                                                    bank.bank_bill_quotation
                                                "
                                                true-value="1"
                                                false-value="0"
                                            />
                                            <label class="form-check-label"
                                                >Bill Quotation</label
                                            >
                                        </div>
                                        <div
                                            class="col-6 col-md-4 py-2 pr-0 form-check form-check-inline mr-0"
                                        >
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                v-model="bank.bank_bill_order"
                                                true-value="1"
                                                false-value="0"
                                            />
                                            <label class="form-check-label"
                                                >Bill Order</label
                                            >
                                        </div>
                                        <div
                                            class="col-6 col-md-4 py-2 pr-0 form-check form-check-inline mr-0"
                                        >
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                v-model="bank.bank_bill_invoice"
                                                true-value="1"
                                                false-value="0"
                                            />
                                            <label class="form-check-label"
                                                >Bill Invoice</label
                                            >
                                        </div>
                                        <div
                                            class="col-6 col-md-4 py-2 pr-0 form-check form-check-inline mr-0"
                                        >
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                v-model="bank.bank_expense"
                                                true-value="1"
                                                false-value="0"
                                            />
                                            <label class="form-check-label"
                                                >Expense</label
                                            >
                                        </div>
                                        <div
                                            class="col-6 col-md-4 py-2 pr-0 form-check form-check-inline mr-0"
                                        >
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                v-model="bank.bank_income"
                                                true-value="1"
                                                false-value="0"
                                            />
                                            <label class="form-check-label"
                                                >Income</label
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--END Form-->
                        <!-- Submit -->
                        <div class="row items-push">
                            <div class="col-12 text-center">
                                <p class="font-size-sm text-muted">
                                    * Require to be filled, Make sure all data is
                                    correctly filled
                                </p>
                                <button
                                    type="submit"
                                    class="btn btn-sm btn-success"
                                    v-ripple="{ center: true }"
                                    @click.prevent="submitData"
                                >
                                    <i class="fa fa-fw fa-check mr-1"></i>
                                    Update
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-sm btn-danger"
                                    v-ripple="{ center: true }"
                                    @click.prevent="deleteData"
                                >
                                    <i class="fa fa-fw fa-trash mr-1"></i>
                                    Delete
                                </button>
                                <router-link
                                    type="button"
                                    class="btn btn-sm btn-alt-light"
                                    tag="button"
                                    v-ripple="{ center: true }"
                                    :to="{ name: 'bank' }"
                                >
                                    <i class="fa fa-fw fa-times mr-1"></i> Close
                                </router-link>
                            </div>
                        </div>
                        <!-- END Submit -->
                    </form>
                </div>
            </div>
            <!-- END Basic -->
        </div>
        <!-- END Page Content -->
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

export default {
    name: "edit-bank",
    data() {
        return {
            loadingPage: 1
        };
    },
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState("bank", {
            bank: state => state.bank
        })
    },
    mounted() {
        if (this.$route.params.id) {
            this.editBank(this.$route.params.id)
                .then(response => {
                    this.loadingPage = 0;
                })
                .catch(error => {
                    console.log(error);
                    this.loadingPage = 0;
                });
        } else {
            this.$router.push({ name: "bank" });
            this.alert("Data not found, select data first", 2);
        }
    },
    methods: {
        //VUEX
        ...mapMutations("bank", ["CLEAR_FORM"]),
        ...mapActions("bank", ["editBank", "updateBank", "removeBank"]),

        mustNumber() {
            // Only for postfix account no. to prevent user input alphabet
            this.bank.bank_rekening = this.bank.bank_rekening.replace(
                /[^0-9]/g,
                ""
            );
        },
        submitData() {
            this.loadingPage = 2;
            this.updateBank(this.$route.params.id)
                .then(() => {
                    this.$router.push({ name: "bank" });
                    this.alert("Successfully Edit Bank Data", 1);
                    this.loadingPage = 0;
                })
                .catch(error => {
                    console.log(error);
                    this.loadingPage = 0;
                });
        },
        deleteData() {
            //AKAN MENAMPILKAN JENDELA KONFIRMASI
            this.$swal({
                title: "Are you sure ?",
                text: "Deleted data cannot be recovery",
                icon: "warning",
                buttons: ["Cancel", "Delete"],
                dangerMode: true
            }).then(willDelete => {
                if (willDelete) {
                    this.removeBank(this.$route.params.id)
                        .then(() => {
                            this.$router.push({ name: "bank" });
                            this.alert("Data has been deleted !", 1);
                            this.loadingPage = 0;
                        })
                        .catch(error => {
                            console.log(error);
                            this.loadingPage = 0;
                        });
                }
            });
        }
    },
    destroyed() {
        //FORM DI BERSIHKAN
        this.CLEAR_FORM();
    }
};
</script>
