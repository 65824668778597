<template>
    <main>
        <!-- Hero -->
        <div class="bg-body-light">
            <div class="content content-full">
                <div
                    class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center"
                >
                    <h1 class="flex-sm-fill h3 my-2">Edit Profile</h1>
                    <nav
                        class="flex-sm-00-auto ml-sm-3"
                        aria-label="breadcrumb"
                    >
                        <ol class="breadcrumb breadcrumb-alt">
                            <li class="breadcrumb-item" aria-current="page">
                                <router-link
                                    class="link-fx"
                                    :to="{ name: 'home' }"
                                    >Dashboard</router-link
                                >
                            </li>
                            <li class="breadcrumb-item">Edit Profile</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
        <!-- END Hero -->

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 1"
                style="height:250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity:0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 2"
                style="height:250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <div
                        class="spinner-border spinner-border-lg text-info mx-auto"
                        role="status"
                        style="display: flex;
                        justify-content: center;"
                    ></div>
                    <center class="mt-2">
                        <span class="text-center" style="opacity:0.7"
                            >Update Data</span
                        >
                    </center>
                </div>
            </div>
            <!-- Basic -->
            <div
                class="block col-12 col-md-8 offset-md-2 block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 0"
            >
                <div class="block-content block-content-full">
                    <form
                        method="POST"
                        enctype="multipart/form-data"
                        onsubmit="return false;"
                    >
                        <!--Form-->
                        <div class="col-12">
                            <div class="form-group">
                                <label for="example-text-input">Fullname</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Input Fullname"
                                    v-model="dataProfile.name"
                                    :class="{ 'is-invalid': errors.name }"
                                />
                                <p class="text-danger" v-if="errors.name">
                                    {{ errors.name[0] }}
                                </p>
                            </div>
                            <div class="form-group">
                                <label for="example-text-input mb-0"
                                    >Username</label
                                >
                                <p
                                    class="text-secondary mb-1"
                                    v-if="auth.role.includes('masterAccount')"
                                >
                                    Master User can't change Username
                                </p>
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Username"
                                    v-model="dataProfile.username"
                                    :disabled="
                                        auth.role.includes('masterAccount')
                                    "
                                    @keydown.space.prevent
                                    @input="clearSpace()"
                                    :class="{ 'is-invalid': errors.username }"
                                />
                                <p class="text-danger" v-if="errors.username">
                                    {{ errors.username[0] }}
                                </p>
                            </div>
                            <div class="form-group">
                                <label for="example-email-input">Email</label>
                                <input
                                    type="email"
                                    class="form-control"
                                    placeholder="Input Emai"
                                    v-model="dataProfile.email"
                                    :class="{ 'is-invalid': errors.email }"
                                />
                                <p class="text-danger" v-if="errors.email">
                                    {{ errors.email[0] }}
                                </p>
                            </div>
                            <div class="form-group">
                                <label for="example-password-input"
                                    >Phone Number</label
                                >
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Input Phone Number"
                                    v-model="dataProfile.phone"
                                    :class="{ 'is-invalid': errors.phone }"
                                />
                                <p class="text-danger" v-if="errors.phone">
                                    {{ errors.phone[0] }}
                                </p>
                            </div>
                            <div class="form-group" v-if="changePhotoVal">
                                <label class="d-block" for="user-photo"
                                    >Photo</label
                                >
                                <input
                                    type="file"
                                    id="user-photo"
                                    @change="uploadImage"
                                    :class="{ 'is-invalid': errors.photo }"
                                />
                                <p class="text-danger" v-if="errors.photo">
                                    {{ errors.photo[0] }}
                                </p>
                            </div>
                            <div
                                class="form-group"
                                v-if="!changePhotoVal && dataProfile.photo"
                            >
                                <img
                                    :src="
                                        '/storage/avatar/' + dataProfile.photo
                                    "
                                    alt=""
                                    class="img-thumbnail"
                                    @error="errorAvatarUrl"
                                    style="width:200px;height:200px;object-fit:cover;"
                                />
                            </div>
                            <div class="form-group" v-if="resetPasswordVal">
                                <label for="example-password-input"
                                    >Password</label
                                >
                                <input
                                    type="password"
                                    class="form-control"
                                    placeholder="Input Password"
                                    v-model="dataProfile.password"
                                    :class="{ 'is-invalid': errors.password }"
                                />
                                <p class="text-danger" v-if="errors.password">
                                    {{ errors.password[0] }}
                                </p>
                            </div>
                            <div class="form-group" v-if="resetPasswordVal">
                                <label for="example-password-input"
                                    >Re-type Pasword</label
                                >
                                <input
                                    type="password"
                                    class="form-control"
                                    name="example-password-input"
                                    placeholder="Re-type Pasword"
                                    v-model="dataProfile.password_confirmation"
                                    :class="{
                                        'is-invalid':
                                            errors.password_confirmation
                                    }"
                                />
                                <p
                                    class="text-danger"
                                    v-if="errors.password_confirmation"
                                >
                                    {{ errors.password_confirmation[0] }}
                                </p>
                            </div>
                            <div class="form-group mt-4">
                                <button
                                    type="button"
                                    class="btn btn-sm btn-outline-info btn-icon"
                                    v-ripple="{ center: true }"
                                    :class="{ active: changePhotoVal }"
                                    @click="changePhoto"
                                >
                                    Change Photo
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-sm btn-outline-info btn-icon ml-1"
                                    v-ripple="{ center: true }"
                                    :class="{ active: resetPasswordVal }"
                                    @click="resetPassword"
                                >
                                    Reset Password
                                </button>
                            </div>
                        </div>
                        <!--END Form-->
                        <!-- Submit -->
                        <div class="row items-push">
                            <div class="col-12 text-center">
                                <p
                                    class="font-size-sm text-muted"
                                >* Require to be filled, Make sure all data is correctly filled</p>
                                <button
                                    type="submit"
                                    class="btn btn-sm btn-success"
                                    v-ripple="{ center: true }"
                                    @click.prevent="submitData"
                                >
                                    <i class="fa fa-fw fa-check mr-1"></i>
                                    Update
                                </button>
                                <router-link
                                    type="button"
                                    class="btn btn-sm btn-alt-light"
                                    tag="button"
                                    v-ripple="{ center: true }"
                                    :to="{ name: 'home' }"
                                >
                                    <i class="fa fa-fw fa-times mr-1"></i> Close
                                </router-link>
                            </div>
                        </div>
                        <!-- END Submit -->
                    </form>
                </div>
            </div>
            <!-- END Basic -->
        </div>
        <!-- END Page Content -->
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

export default {
    name: "edit-profile",
    data() {
        return {
            loadingPage: 1,
            resetPasswordVal: false,
            changePhotoVal: false,
            dataProfile: []
        };
    },
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState(["auth"])
    },
    created() {
        this.editProfile()
            .then(response => {
                this.dataProfile = response.data;
                this.loadingPage = 0;
            })
            .catch(error => {
                console.log(error);
                this.loadingPage = 0;
            });
    },
    methods: {
        //VUEX
        ...mapMutations("user", ["CLEAR_FORM"]),
        ...mapActions("profile", ["updateProfile", "editProfile"]),

        //METHOD
        errorAvatarUrl(event) {
            event.target.src = "/media/avatars/avatar0.jpg";
        },
        clearSpace() {
            this.dataProfile.username = this.dataProfile.username.replace(
                /\s/g,
                ""
            );
        },
        uploadImage(e) {
            let file = e.target.files[0];
            let reader = new FileReader();
            reader.onloadend = file => {
                this.dataProfile.photo = reader.result;
            };
            reader.readAsDataURL(file);
        },
        resetPassword() {
            this.resetPasswordVal = !this.resetPasswordVal;
        },
        changePhoto() {
            this.changePhotoVal = !this.changePhotoVal;
        },
        submitData() {
            this.loadingPage = 2;
            this.updateProfile(this.dataProfile)
                .then(() => {
                    this.alert("Successfully Update Profile", 1);
                    this.editProfile()
                        .then(response => {
                            this.dataProfile = response.data;
                            (this.resetPasswordVal = false),
                                (this.changePhotoVal = false),
                                (this.loadingPage = 0);
                        })
                        .catch(error => {
                            console.log(error);
                            this.loadingPage = 0;
                        });
                })
                .catch(error => {
                    console.log(error);
                    this.loadingPage = 0;
                });
        }
    },
    destroyed() {
        //FORM DI BERSIHKAN
        this.CLEAR_FORM();
    }
};
</script>
