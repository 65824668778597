<template>
    <main>
        <hero
            v-if="type == 'Quotation'"
            title="Enter Purchase Quotation"
            prevBreadcrumb="Dashboard"
            routeBreadcrumb="home"
            currentBreadcrumb="Enter Purchase Quotation"
        ></hero>
        <hero
            v-if="type == 'Order'"
            title="Enter Purchase Order"
            prevBreadcrumb="Dashboard"
            routeBreadcrumb="home"
            currentBreadcrumb="Enter Purchase Order"
        ></hero>
        <hero
            v-if="type == 'Invoice'"
            title="Enter Purchase Invoice"
            prevBreadcrumb="Dashboard"
            routeBreadcrumb="home"
            currentBreadcrumb="Enter Purchase Invoice"
        ></hero>

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 1"
                style="height:250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity:0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-else-if="loadingPage == 2"
                style="height:250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity:0.7"
                            >Save Data</span
                        >
                    </center>
                </div>
            </div>
            <!-- Basic -->
            <div class="block block-rounded block-mode-loading-oneui" v-else>
                <v-tabs
                    background-color="white"
                    style="border-bottom: 1px solid #ebebeb;border-radius: 8px;"
                >
                    <v-tab
                        v-for="label in labels"
                        :key="label"
                        @click="changeType(label)"
                        v-bind:class="{
                            'v-tab--active': label == type,
                            'v-tab--active--false': label !== type
                        }"
                    >
                        {{ label }}
                    </v-tab>
                </v-tabs>
                <form
                    method="POST"
                    enctype="multipart/form-data"
                    onsubmit="return false;"
                >
                    <div class="block-content block-content-full">
                        <!--Form-->
                        <div class="row font-size-sm">
                            <div class="col-12 py-0">
                                <!-- BIIL INFORMATION -->
                                <div class="row">
                                    <div class="col-12 col-lg-6 py-0">
                                        <label class="mb-0" style="opacity:0.7"
                                            >GENERAL</label
                                        >
                                        <hr class="mt-2 mb-3" />
                                        <div class="row">
                                            <div class="col-7 pb-1 pt-0">
                                                <div class="form-group mb-1">
                                                    <label
                                                        for="example-text-input"
                                                        >Supplier Name*</label
                                                    >
                                                    <selectize
                                                        class="form-control mb-1"
                                                        placeholder="Customer Name"
                                                        v-model="
                                                            purchase.bill_contact_id
                                                        "
                                                        :class="{
                                                            'is-invalid-selectize':
                                                                errors.bill_contact_id
                                                        }"
                                                        @input="
                                                            getContactDetail(
                                                                purchase.bill_contact_id
                                                            )
                                                        "
                                                    >
                                                        <!-- settings is optional -->
                                                        <option
                                                            v-for="contact in contacts"
                                                            :value="contact.id"
                                                            :key="contact.id"
                                                            >{{
                                                                contact.contact_name
                                                            }}</option
                                                        >
                                                    </selectize>
                                                    <transition
                                                        name="slide-fade"
                                                    >
                                                        <p
                                                            v-if="
                                                                errors.bill_contact_id
                                                            "
                                                            class="text-danger pb-0 mb-1"
                                                        >
                                                            {{
                                                                errors
                                                                    .bill_contact_id[0]
                                                            }}
                                                        </p>
                                                    </transition>
                                                </div>
                                            </div>
                                            <div class="col-5 pb-1 pt-0">
                                                <div class="form-group mb-1">
                                                    <label
                                                        for="example-text-input"
                                                        >Beginning
                                                        Balance</label
                                                    >
                                                    <money
                                                        class="form-control"
                                                        style="text-align: right;"
                                                        v-model="
                                                            purchase.bill_contact_beginning_balance
                                                        "
                                                        spellcheck="false"
                                                        disabled
                                                    ></money>
                                                    <transition
                                                        name="slide-fade"
                                                    >
                                                        <p
                                                            class="text-danger pb-0 mb-1"
                                                            v-if="
                                                                errors.bill_contact_beginning_balance
                                                            "
                                                        >
                                                            {{
                                                                errors
                                                                    .bill_contact_beginning_balance[0]
                                                            }}
                                                        </p>
                                                    </transition>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 py-1">
                                                <div class="form-group">
                                                    <label
                                                        for="example-text-input"
                                                        >Customer Address
                                                        *</label
                                                    >
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        placeholder="Customer Address"
                                                        v-model="
                                                            purchase.bill_shipaddress
                                                        "
                                                        :class="{
                                                            'is-invalid':
                                                                errors.bill_shipaddress
                                                        }"
                                                    />
                                                    <transition
                                                        name="slide-fade"
                                                    >
                                                        <p
                                                            class="text-danger pb-0 mb-1"
                                                            v-if="
                                                                errors.bill_shipaddress
                                                            "
                                                        >
                                                            {{
                                                                errors
                                                                    .bill_shipaddress[0]
                                                            }}
                                                        </p>
                                                    </transition>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-6 py-0">
                                        <label class="mb-0" style="opacity:0.7"
                                            >BILL INFORMATION</label
                                        >
                                        <hr class="mt-2 mb-3" />

                                        <div class="row">
                                            <div class="col-6 pb-1 pt-0">
                                                <div class="form-group mb-2">
                                                    <label
                                                        for="example-text-input"
                                                        >Issued On *</label
                                                    >
                                                    <date-picker
                                                        class="input-group"
                                                        v-model="
                                                            purchase.bill_date
                                                        "
                                                        format="DD/MM/YYYY"
                                                        valueType="YYYY-MM-DD"
                                                        placeholder="Issued On"
                                                        :class="{
                                                            'is-invalid':
                                                                errors.bill_date
                                                        }"
                                                        :default-value="
                                                            new Date()
                                                        "
                                                        :disabled-date="
                                                            disabledDatePicker
                                                        "
                                                        @input="changeDate()"
                                                    ></date-picker>
                                                    <transition
                                                        name="slide-fade"
                                                    >
                                                        <p
                                                            class="text-danger pb-0 mb-1"
                                                            v-if="
                                                                errors.bill_date
                                                            "
                                                        >
                                                            {{
                                                                errors
                                                                    .bill_date[0]
                                                            }}
                                                        </p>
                                                    </transition>
                                                </div>
                                            </div>
                                            <div class="col-6 pb-1 pt-0">
                                                <div class="form-group mb-2">
                                                    <label
                                                        for="example-text-input"
                                                        v-if="
                                                            this.type !=
                                                                'Invoice'
                                                        "
                                                        >Due On
                                                        (Optional)</label
                                                    >
                                                    <label
                                                        for="example-text-input"
                                                        v-else
                                                        >Due On *
                                                    </label>
                                                    <date-picker
                                                        class="input-group"
                                                        v-model="
                                                            purchase.bill_due_date
                                                        "
                                                        format="DD/MM/YYYY"
                                                        valueType="YYYY-MM-DD"
                                                        placeholder="Due On"
                                                        :class="{
                                                            'is-invalid':
                                                                errors.bill_due_date
                                                        }"
                                                        :default-value="
                                                            new Date()
                                                        "
                                                        :disabled-date="
                                                            disabledDatePicker
                                                        "
                                                    ></date-picker>
                                                    <transition
                                                        name="slide-fade"
                                                    >
                                                        <p
                                                            class="text-danger pb-0 mb-1"
                                                            v-if="
                                                                errors.bill_due_date
                                                            "
                                                        >
                                                            {{
                                                                errors
                                                                    .bill_due_date[0]
                                                            }}
                                                        </p>
                                                        <p
                                                            class="text-danger pb-0 mb-0"
                                                            v-if="
                                                                purchase.bill_due_date !=
                                                                    null &&
                                                                    purchase.bill_due_date !=
                                                                        '' &&
                                                                    purchase.bill_due_date <
                                                                        purchase.bill_date
                                                            "
                                                        >
                                                            Bills Due Date lower
                                                            than Bills Date
                                                        </p>
                                                    </transition>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6 py-1">
                                                <div
                                                    class="form-group"
                                                    v-if="type == 'Quotation'"
                                                >
                                                    <label
                                                        for="example-text-input"
                                                        >Purchase Quotation
                                                        Number *</label
                                                    >
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        placeholder="Purchase Quotation Number"
                                                        v-model="
                                                            purchase.bill_quotation_number
                                                        "
                                                        :class="{
                                                            'is-invalid':
                                                                errors.bill_number
                                                        }"
                                                    />
                                                    <transition
                                                        name="slide-fade"
                                                    >
                                                        <p
                                                            class="text-danger pb-0 mb-1"
                                                            v-if="
                                                                errors.bill_number
                                                            "
                                                        >
                                                            {{
                                                                errors
                                                                    .bill_number[0]
                                                            }}
                                                        </p>
                                                    </transition>
                                                </div>
                                                <div
                                                    class="form-group"
                                                    v-if="type == 'Order'"
                                                >
                                                    <label
                                                        for="example-text-input"
                                                        >Purchase Order Number
                                                        *</label
                                                    >
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        placeholder="Purchase Order Number"
                                                        v-model="
                                                            purchase.bill_order_number
                                                        "
                                                        :class="{
                                                            'is-invalid':
                                                                errors.bill_number
                                                        }"
                                                    />
                                                    <transition
                                                        name="slide-fade"
                                                    >
                                                        <p
                                                            class="text-danger pb-0 mb-1"
                                                            v-if="
                                                                errors.bill_number
                                                            "
                                                        >
                                                            {{
                                                                errors
                                                                    .bill_number[0]
                                                            }}
                                                        </p>
                                                    </transition>
                                                </div>
                                                <div
                                                    class="form-group"
                                                    v-if="type == 'Invoice'"
                                                >
                                                    <label
                                                        for="example-text-input"
                                                        >Purchase Invoice Number
                                                        *</label
                                                    >
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        placeholder="Purchase Invoice Number"
                                                        v-model="
                                                            purchase.bill_invoice_number
                                                        "
                                                        :class="{
                                                            'is-invalid':
                                                                errors.bill_number
                                                        }"
                                                    />
                                                    <transition
                                                        name="slide-fade"
                                                    >
                                                        <p
                                                            class="text-danger pb-0 mb-1"
                                                            v-if="
                                                                errors.bill_number
                                                            "
                                                        >
                                                            {{
                                                                errors
                                                                    .bill_number[0]
                                                            }}
                                                        </p>
                                                    </transition>
                                                </div>
                                            </div>
                                            <div
                                                class="col-6 py-1"
                                                v-if="type == 'Invoice'"
                                            >
                                                <div class="form-group mb-3">
                                                    <label
                                                        for="example-text-input"
                                                        >Order Number
                                                        (optional)</label
                                                    >
                                                    <selectize
                                                        class="form-control mb-1"
                                                        placeholder="Order Data"
                                                        v-model="
                                                            purchase.bill_reference_id
                                                        "
                                                        :class="{
                                                            'is-invalid-selectize':
                                                                errors.bill_reference_id
                                                        }"
                                                        :disabled="
                                                            purchase
                                                                .bill_reference_list
                                                                .length == 0
                                                        "
                                                        @input="
                                                            setOrderToInvoice(
                                                                purchase.bill_reference_id
                                                            )
                                                        "
                                                    >
                                                        <!-- settings is optional -->
                                                        <option
                                                            v-for="ref in purchase.bill_reference_list"
                                                            :value="ref.id"
                                                            :key="ref.id"
                                                            >Order
                                                            {{
                                                                ref.bill_order_number
                                                            }}</option
                                                        >
                                                    </selectize>
                                                    <transition
                                                        name="slide-fade"
                                                    >
                                                        <p
                                                            v-if="
                                                                errors.bill_reference_id
                                                            "
                                                            class="text-danger pb-0 mb-1"
                                                        >
                                                            {{
                                                                errors
                                                                    .bill_reference_id[0]
                                                            }}
                                                        </p>
                                                    </transition>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="row"
                                            v-if="type == 'Invoice'"
                                        >
                                            <div class="col-6 pb-1 pt-0">
                                                <transition name="slide-fade">
                                                    <div
                                                        class="form-group"
                                                        v-if="type == 'Invoice'"
                                                    >
                                                        <label
                                                            for="example-text-input"
                                                            >Account for
                                                            Tracking Payable
                                                            *</label
                                                        >
                                                        <selectize
                                                            class="form-control"
                                                            placeholder="Choose Account No."
                                                            v-model="
                                                                purchase.bill_coa_id
                                                            "
                                                            :class="{
                                                                'is-invalid-selectize':
                                                                    errors.bill_coa_id
                                                            }"
                                                        >
                                                            <!-- settings is optional -->
                                                            <option
                                                                v-for="account in payableAccounts"
                                                                :value="
                                                                    account.id
                                                                "
                                                                :key="
                                                                    account.id
                                                                "
                                                            >
                                                                {{
                                                                    account.account_no
                                                                }}
                                                                {{
                                                                    account.account_name
                                                                }}
                                                            </option>
                                                        </selectize>
                                                        <transition
                                                            name="slide-fade"
                                                        >
                                                            <p
                                                                class="text-danger"
                                                                v-if="
                                                                    errors.bill_coa_id
                                                                "
                                                            >
                                                                {{
                                                                    errors
                                                                        .bill_coa_id[0]
                                                                }}
                                                            </p>
                                                        </transition>
                                                    </div>
                                                </transition>
                                            </div>
                                            <div class="col-6 pt-0">
                                                <transition name="slide-fade">
                                                    <div class="form-group">
                                                        <label
                                                            for="example-text-input"
                                                            >Journal Memo
                                                            *</label
                                                        >
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            placeholder="Journal Memo"
                                                            v-model="
                                                                purchase.bill_journal_memo
                                                            "
                                                            :class="{
                                                                'is-invalid':
                                                                    errors.bill_journal_memo
                                                            }"
                                                        />
                                                        <transition
                                                            name="slide-fade"
                                                        >
                                                            <p
                                                                class="text-danger pb-0 mb-1"
                                                                v-if="
                                                                    errors.bill_journal_memo
                                                                "
                                                            >
                                                                {{
                                                                    errors
                                                                        .bill_journal_memo[0]
                                                                }}
                                                            </p>
                                                        </transition>
                                                    </div>
                                                </transition>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr class="mt-3 mb-4 border-dash" />
                                <!-- ASSOCIATE TRANSACTION -->
                                <template
                                    v-if="
                                        this.purchase
                                            .bill_transaction_from_order
                                            .length > 0
                                    "
                                >
                                    <div class="row py-2 text-muted px-2">
                                        <label class="mb-0" style="opacity:0.7"
                                            >ASSOCIATED TO ORDER NO.
                                            {{ associatedRefNo }}</label
                                        >
                                    </div>
                                    <CreateAssociateTransaction></CreateAssociateTransaction>
                                    <div class="row py-2 text-muted px-2">
                                        <label class="mb-0" style="opacity:0.7"
                                            >OTHER TRANSACTION</label
                                        >
                                    </div>
                                </template>

                                <!-- CURRENT TRANSACTION-->
                                <CreateTransaction></CreateTransaction>
                            </div>
                        </div>
                        <!--END Form-->
                        <div class="row px-2">
                            <div
                                class="col-12 col-md-4 offset-md-8"
                                style="
                                    background-color: #e9ecef !important;
                                    width: 350px;
                                    padding-top: 10px;
                                "
                            >
                                <strong>
                                    <p class="mb-2 font-w700">
                                        Subtotal
                                        <span class="float-right">{{
                                            purchase.bill_sub_total | currency
                                        }}</span>
                                    </p>
                                </strong>
                                <p class="mb-2">
                                    Tax Total
                                    <small class="float-right">{{
                                        purchase.bill_tax_total | currency
                                    }}</small>
                                </p>
                                <strong>
                                    <p class="mb-2 font-w700">
                                        Grand Total
                                        <span class="float-right">{{
                                            purchase.bill_grand_total | currency
                                        }}</span>
                                    </p>
                                </strong>
                            </div>
                        </div>
                    </div>
                    <div
                        class="block-footer px-2"
                        style="background-color:#F9F9F9"
                    >
                        <!-- Submit -->
                        <div class="row items-push">
                            <div class="col-12 text-right mb-2">
                                <router-link
                                    type="button"
                                    class="btn btn-sm btn-alt-light mb-1 mr-1"
                                    tag="button"
                                    v-ripple="{ center: true }"
                                    :to="{ name: 'home' }"
                                >
                                    <i class="fa fa-fw fa-times mr-1"></i>
                                    Cancel
                                </router-link>
                                <button
                                    type="button"
                                    class="btn btn-sm btn-info mb-1"
                                    v-ripple="{ center: true }"
                                    :disabled="
                                        purchase.bill_due_date != '' &&
                                            purchase.bill_due_date <
                                                purchase.bill_date
                                    "
                                    @click="review()"
                                >
                                    <i class="fa fa-fw fa-file-alt mr-1"></i>
                                    Review
                                </button>
                            </div>
                        </div>
                        <!-- END Submit -->
                    </div>
                </form>
            </div>
            <!-- END Basic -->
        </div>

        <modal
            name="review-purchase"
            width="95%"
            classes="modal-proxima-lg block-rounded block-mode-loading-oneui"
            height="auto"
            @before-close="clearModal()"
            adaptive
        >
            <Review :data="this.purchase" :type="'submit'"></Review>
        </modal>
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import moment from "moment";
import Review from "./component/Review.vue";
import CreateAssociateTransaction from "./component/CreateAssociateTransaction.vue";
import CreateTransaction from "./component/CreateTransaction.vue";

export default {
    name: "purchase-enter",
    components: {
        Review,
        CreateAssociateTransaction,
        CreateTransaction
    },
    data() {
        return {
            loadingPage: 0,
            type:
                this.$route.params.type != null
                    ? this.$route.params.type
                    : "Quotation",
            labels: ["Quotation", "Order", "Invoice"],
            disabled: {}
        };
    },
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState("purchase", { purchase: state => state.purchase }),
        ...mapState("coa", { coas: state => state.coas.accounts }),
        ...mapState("coa", { coalink: state => state.coalink }),
        ...mapState("contact", { contacts: state => state.contacts }),
        ...mapState("item", { items: state => state.items }),
        ...mapState("uom", { uoms: state => state.uoms }),
        ...mapState("tax", { taxs: state => state.taxs }),
        ...mapState("forbiddendate", {
            forbiddenDateLast: state => state.forbiddenDateLast
        }),
        disableSave() {
            if (this.purchase.bill_due_date < this.purchase.bill_date) {
                return true;
            }
            if (this.purchase.bill_grand_total == 0) {
                return true;
            }
        },
        payableAccounts: function() {
            if (this.coas) {
                return this.coas.filter(function(coa) {
                    return (
                        coa.account_type === "Account Payable" ||
                        coa.account_type === "Bank" ||
                        coa.account_type === "Other Liability" ||
                        coa.account_type === "Other Current Liability" ||
                        coa.account_type === "Long Term Liability"
                    );
                });
            }
        },
        transactionAccounts: function() {
            if (this.coas) {
                return this.coas.filter(function(coa) {
                    return (
                        coa.account_type === "Expense" ||
                        coa.account_type === "Other Expense" ||
                        coa.account_type === "Other Current Asset" ||
                        coa.account_type === "Other Asset" ||
                        coa.account_type === "Fixed Asset" ||
                        coa.account_type === "Cost of Sales"
                    );
                });
            }
        },
        associatedRefNo: function() {
            if (this.type == "Invoice") {
                if (
                    this.purchase.bill_reference_list.length > 0 &&
                    this.purchase.bill_reference_id != 0 &&
                    this.purchase.bill_reference_id != ""
                ) {
                    const result = this.purchase.bill_reference_list.find(
                        ({ id }) => id == this.purchase.bill_reference_id
                    );
                    return result.bill_order_number;
                }
                return null;
            }
        }
    },
    created() {
        this.getContactSupplier();
        this.getCoaAll();
        this.getTaxAllPurchases();
        this.getItemAll({ type: "Purchase" });
        this.getForbiddenDate();
        this.getPurchaseNo();
        if (this.type == "Quotation") {
            this.purchase.bill_status = 1;
        }
        if (this.type == "Order") {
            this.purchase.bill_status = 2;
        }
        if (this.type == "Invoice") {
            this.getCoaLink().then(response => {
                this.purchase.bill_status = 3;
                this.purchase.bill_coa_id = this.coalink.tracking_payable;
            });
            this.getJournalMemo();
        }
    },
    methods: {
        //VUEX
        ...mapMutations("purchase", ["CLEAR_FORM"]),
        ...mapMutations(["CLEAR_ERRORS"]),
        ...mapActions("coa", ["getCoaAll", "getCoaLink"]),
        ...mapActions("journal", ["getJournalNo", "submitJournal"]),
        ...mapActions("purchase", ["getOrderContact", "setOrder"]),
        ...mapActions("recurringjournal", ["useRecurring"]),
        ...mapActions("contact", ["getContactSupplier"]),
        ...mapActions("item", ["getItemAll"]),
        ...mapActions("uom", ["getUom"]),
        ...mapActions("contact", ["getContactSupplier"]),
        ...mapActions("tax", ["getTaxAllPurchases"]),
        ...mapActions("purchase", [
            "getQuotationNo",
            "getOrderNo",
            "getInvoiceNo",
            "submitQuotation",
            "submitOrder",
            "submitInvoice",
            "reviewSubmitPurchase",
            "printBill"
        ]),
        ...mapActions("forbiddendate", ["getForbiddenDate"]),
        //METHOD
        disabledDatePicker(date) {
            const lastDisableDate = new Date(
                moment(this.forbiddenDateLast).add(1, "days")
            );
            lastDisableDate.setHours(0, 0, 0, 0);
            return date < lastDisableDate;
        },
        clearTransaction() {
            this.purchase.bill_transaction_from_order = [];
            // this.purchase.bill_transaction = [
            //     {
            //         transaction_format: 0,
            //         transaction_tax_id: "",
            //         transaction_tax_rate: 0,
            //         transaction_coa_id: "",
            //         transaction_description: "",
            //         transaction_amount: 0,
            //         transaction_qty: 0,
            //         transaction_price: 0,
            //         item_id: 0,
            //         transaction_done_qty: 0,
            //         uom_id: 0,
            //         ref_order_tr_id: 0
            //     }
            // ];
        },
        clearModal() {
            document.body.classList.remove("modal-open");
            this.purchase.journal = {};
        },
        changeType(val) {
            this.type = val;
            if (val == "Quotation") {
                this.getQuotationNo();
                this.purchase.bill_transaction_from_order = [];
                this.purchase.bill_status = 1;
            } else if (val == "Order") {
                this.getOrderNo();
                this.purchase.bill_transaction_from_order = [];
                this.purchase.bill_status = 2;
            } else {
                this.getCoaLink().then(response => {
                    this.purchase.bill_coa_id = this.coalink.tracking_payable;
                });
                this.purchase.bill_reference_id = 0;
                this.purchase.bill_reference_list = [];
                this.purchase.bill_status = 3;
                if (this.purchase.bill_contact_id != 0) {
                    this.getOrderContact(this.purchase.bill_contact_id)
                        .then(response => {
                            this.purchase.bill_reference_list = response;
                            this.loadingPage = 0;
                        })
                        .catch(error => {
                            console.log(error);
                            this.loadingPage = 0;
                        });
                }

                this.getInvoiceNo();
                this.getJournalMemo();
            }
        },
        changeDate() {
            if (this.purchase.bill_date !== "") {
                this.getPurchaseNo();
            }
        },
        getPurchaseNo() {
            this.type == "Quotation"
                ? this.getQuotationNo()
                      .then(response => {
                          this.purchase.bill_number = response;
                      })
                      .catch(error => {
                          console.log(error);
                      })
                : "";
            this.type == "Order"
                ? this.getOrderNo()
                      .then(response => {
                          this.purchase.bill_number = response;
                      })
                      .catch(error => {
                          console.log(error);
                      })
                : "";
            this.type == "Invoice"
                ? this.getInvoiceNo()
                      .then(response => {
                          this.purchase.bill_number = response;
                      })
                      .catch(error => {
                          console.log(error);
                      })
                : "";
        },
        setOrderToInvoice(val) {
            this.setOrder(val)
                .then(response => {
                    this.purchase.bill_transaction_from_order = [];
                    this.purchase.bill_transaction = [];
                    response.data.transaction.forEach(element => {
                        if (
                            element.transaction_qty !=
                            element.transaction_done_qty
                        ) {
                            let itemSelected = this.items.find(obj => {
                                return obj.id == element.item_id;
                            });
                            let data = {
                                transaction_format:
                                    element.transaction_format ?? 0,
                                transaction_tax_id:
                                    element.transaction_tax_id ?? "",
                                transaction_tax_rate:
                                    element.transaction_tax_rate ?? 0,
                                transaction_coa_id:
                                    element.transaction_coa_id ?? "",
                                transaction_description:
                                    element.transaction_description ?? "",
                                transaction_amount:
                                    element.transaction_qyt *
                                        element.transaction_price ?? 0,
                                transaction_amount_base:
                                    element.transaction_amount ?? 0,
                                transaction_qty: element.transaction_qty ?? 0,
                                transaction_price:
                                    element.transaction_price ?? 0,
                                item_id: element.item_id ?? 0,
                                item_name:
                                    element.item_id != 0 &&
                                    element.transaction_format != 2
                                        ? itemSelected.item_name
                                        : "",
                                transaction_done_qty:
                                    element.transaction_done_qty ?? 0,
                                uom_list:
                                    element.item_id != 0 &&
                                    element.transaction_format != 2
                                        ? itemSelected.uom_list
                                        : [],
                                uom_id:
                                    element.item_id != 0 &&
                                    element.transaction_format != 2
                                        ? element.uom_id
                                        : 0,
                                //get coa for inventory coa for mutation if tracked
                                inventory_coa_id:
                                    itemSelected &&
                                    itemSelected.is_inventory == 1 &&
                                    element.transaction_format != 2
                                        ? itemSelected.inventory_coa_id
                                        : 0,
                                cogs_coa_id:
                                    itemSelected &&
                                    itemSelected.is_inventory == 1 &&
                                    element.transaction_format != 2
                                        ? itemSelected.expense_coa_id
                                        : 0,
                                //////////////////////////////////////////////////
                                ref_order_tr_id: element.id ?? 0
                            };
                            this.purchase.bill_transaction_from_order.push(
                                data
                            );
                        }
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        },
        //GET DATA ADDRESS & BEGINING BALANCE CUSTOMER
        getContactDetail(val) {
            if (
                this.purchase.bill_contact_id == "" ||
                this.purchase.bill_contact_id == 0
            ) {
                this.purchase.contact = "";
                this.purchase.bill_shipaddress = "";
                this.purchase.bill_contact_beginning_balance = "";
            } else {
                var result = this.contacts.find(obj => {
                    return obj.id === parseInt(val);
                });
                this.purchase.contact = result;
                this.purchase.bill_shipaddress = result.contact_address;
                this.purchase.bill_contact_beginning_balance =
                    result.contact_begining_balance;
            }
            if (this.type == "Invoice") {
                this.purchase.bill_reference_id = 0;
                this.getOrderContact(val)
                    .then(response => {
                        this.purchase.bill_reference_list = response;
                        this.loadingPage = 0;
                    })
                    .catch(error => {
                        console.log(error);
                        this.loadingPage = 0;
                    });
            }
            this.clearTransaction();
            this.getJournalMemo();
        },
        //GET JOURNAL MEMO
        getJournalMemo() {
            if (
                this.purchase.bill_contact_id == "" ||
                this.purchase.bill_contact_id == 0
            ) {
                let newJournalMemo = "Sales; ";
                this.purchase.bill_journal_memo = newJournalMemo;
            } else {
                var result = this.contacts.find(obj => {
                    return obj.id === parseInt(this.purchase.bill_contact_id);
                });
                let newJournalMemo = "Sales; " + result.contact_name;
                this.purchase.bill_journal_memo = newJournalMemo;
            }
        },
        review() {
            this.CLEAR_ERRORS();
            this.loadingPage = 1;
            this.type == "Quotation"
                ? (this.purchase.bill_number = this.purchase.bill_quotation_number)
                : "";
            this.type == "Order"
                ? (this.purchase.bill_number = this.purchase.bill_order_number)
                : "";
            this.type == "Invoice"
                ? (this.purchase.bill_number = this.purchase.bill_invoice_number)
                : "";
            this.reviewSubmitPurchase()
                .then(response => {
                    this.purchase.journal =
                        this.purchase.bill_status == 3 ? response.journal : {};
                    this.$modal.show("review-purchase");
                    document.body.classList.add("modal-open");
                    this.loadingPage = 0;
                })
                .catch(error => {
                    error.response.status == 500
                        ? this.alert(error.response.data.message, 2)
                        : "";
                    error.response.status != 422 && error.response.status != 500
                        ? this.alert(error.message, 2)
                        : "";
                    this.loadingPage = 0;
                });
        },
        saveBill() {
            this.CLEAR_ERRORS();
            this.loadingPage = 2;
            if (this.type == "Quotation") {
                this.purchase.bill_number = this.purchase.bill_quotation_number;
                this.purchase.bill_status = 1;
                this.submitQuotation()
                    .then(response => {
                        this.CLEAR_FORM();
                        this.type = "Quotation";
                        this.alert(
                            "Successfully create Purchase Quotation Data ",
                            1
                        );
                        this.loadingPage = 0;
                    })
                    .catch(error => {
                        error.response.status == 500
                            ? this.alert(error.response.data.message, 2)
                            : "";
                        error.response.status != 422 &&
                        error.response.status != 500
                            ? this.alert(error.message, 2)
                            : "";
                        this.loadingPage = 0;
                    });
            } else if (this.type == "Order") {
                this.purchase.bill_number = this.purchase.bill_order_number;
                this.purchase.bill_status = 2;
                this.submitOrder()
                    .then(response => {
                        this.CLEAR_FORM();
                        this.type = "Order";
                        this.alert(
                            "Successfully create Purchase Order Data ",
                            1
                        );
                        this.loadingPage = 0;
                    })
                    .catch(error => {
                        error.response.status == 500
                            ? this.alert(error.response.data.message, 2)
                            : "";
                        error.response.status != 422 &&
                        error.response.status != 500
                            ? this.alert(error.message, 2)
                            : "";
                        this.loadingPage = 0;
                    });
            } else if (this.type == "Invoice") {
                this.purchase.bill_number = this.purchase.bill_invoice_number;
                this.purchase.bill_status = 3;
                this.submitInvoice()
                    .then(response => {
                        this.CLEAR_FORM();
                        this.type = "Invoice";
                        this.alert(
                            "Successfully create Purchase Invoice Data ",
                            1
                        );
                        this.loadingPage = 0;
                    })
                    .catch(error => {
                        error.response.status == 500
                            ? this.alert(error.response.data.message, 2)
                            : "";
                        error.response.status != 422 &&
                        error.response.status != 500
                            ? this.alert(error.message, 2)
                            : "";
                        this.loadingPage = 0;
                    });
            } else {
            }
        }
    },
    destroyed() {
        this.CLEAR_FORM();
    }
};
</script>
