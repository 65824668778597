var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-xs",attrs:{"id":"tracked-item"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 font-weight-bold mb-0 py-2",staticStyle:{"font-size":"15px"}},[_vm._v("\n            "+_vm._s(_vm.tracked == 1 ? "Tracked Inventory" : "Untracked Inventory")+"\n        ")])]),_vm._v(" "),_vm._l((_vm.data),function(item,index){return _c('div',{key:index,staticClass:"row-report"},[_c('div',{staticClass:"col-report-sm"},[_c('p',{staticClass:"m-0"},[_vm._v("\n                "+_vm._s(item.item_code)+"\n            ")])]),_vm._v(" "),_c('div',{staticClass:"col-report-lg"},[_c('p',{staticClass:"m-0"},[_vm._v(_vm._s(item.item_name))])]),_vm._v(" "),(_vm.type == 1)?_c('div',{staticClass:"col-report"},[(item.amountOpening >= 0)?_c('p',{staticClass:"m-0 text-right"},[_vm._v("\n                "+_vm._s(_vm._f("currency")(item.amountOpening != 0
                        ? item.amountOpening
                        : 0))+"\n            ")]):_vm._e(),_vm._v(" "),(item.amountOpening < 0)?_c('p',{staticClass:"m-0 text-right"},[_vm._v("\n                ("+_vm._s(_vm._f("currency")(item.amountOpening != 0
                        ? Math.abs(item.amountOpening)
                        : 0))+")\n            ")]):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.type == 2)?_c('div',{staticClass:"col-report-sm"},[(item.qtyOpening >= 0)?_c('p',{staticClass:"m-0 text-right"},[_vm._v("\n                "+_vm._s(item.qtyOpening != 0 ? item.qtyOpening : "-")+"\n            ")]):_vm._e(),_vm._v(" "),(item.qtyOpening < 0)?_c('p',{staticClass:"m-0 text-right"},[_vm._v("\n                ("+_vm._s(item.qtyOpening != 0 ? Math.abs(item.qtyOpening) : "-")+")\n            ")]):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.type == 2)?_c('div',{staticClass:"col-report-sm"},[(item.amountOpening >= 0)?_c('p',{staticClass:"m-0 text-right"},[_vm._v("\n                "+_vm._s(_vm._f("currency")(item.amountOpening != 0
                        ? item.amountOpening
                        : 0))+"\n            ")]):_vm._e(),_vm._v(" "),(item.amountOpening < 0)?_c('p',{staticClass:"m-0 text-right"},[_vm._v("\n                ("+_vm._s(_vm._f("currency")(item.amountOpening != 0
                        ? Math.abs(item.amountOpening)
                        : 0))+")\n            ")]):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.type == 2)?_c('div',{staticClass:"col-report-sm"},[_c('p',{staticClass:"m-0 text-right"},[_vm._v("\n                "+_vm._s(item.qtyPurchased != 0 ? item.qtyPurchased : "-")+"\n            ")])]):_vm._e(),_vm._v(" "),(_vm.type == 2)?_c('div',{staticClass:"col-report-sm"},[_c('p',{staticClass:"m-0 text-right"},[_vm._v("\n                "+_vm._s(item.qtySold != 0 ? "(" + item.qtySold + ")" : "-")+"\n            ")])]):_vm._e(),_vm._v(" "),(_vm.type == 2)?_c('div',{staticClass:"col-report-sm"},[(item.qtyAdjusted >= 0)?_c('p',{staticClass:"m-0 text-right"},[_vm._v("\n                "+_vm._s(item.qtyAdjusted != 0 ? item.qtyAdjusted : "-")+"\n            ")]):_vm._e(),_vm._v(" "),(item.qtyAdjusted < 0)?_c('p',{staticClass:"m-0 text-right"},[_vm._v("\n                ("+_vm._s(item.qtyAdjusted != 0
                        ? Math.abs(item.qtyAdjusted)
                        : "-")+")\n            ")]):_vm._e()]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"col-report"},[_c('p',{staticClass:"m-0 text-right"},[_vm._v("\n                "+_vm._s(_vm._f("currency")(Math.abs(item.amountPurchased)))+"\n            ")])]),_vm._v(" "),_c('div',{staticClass:"col-report"},[_c('p',{staticClass:"m-0 text-right"},[_vm._v("\n                ("+_vm._s(_vm._f("currency")(Math.abs(item.amountMutationSold)))+")\n            ")])]),_vm._v(" "),_c('div',{staticClass:"col-report"},[_c('p',{staticClass:"m-0 text-right"},[_vm._v("\n                "+_vm._s(_vm._f("currency")(Math.abs(item.amountAdjusted)))+"\n            ")])]),_vm._v(" "),(_vm.type == 2)?_c('div',{staticClass:"col-report"},[_c('p',{staticClass:"m-0 text-right"},[_vm._v("\n                "+_vm._s(_vm._f("currency")(Math.abs(item.amountSold)))+"\n            ")])]):_vm._e(),_vm._v(" "),(_vm.type == 2)?_c('div',{staticClass:"col-report"},[_c('p',{staticClass:"m-0 text-right"},[_vm._v("\n                "+_vm._s(_vm._f("currency")(item.netProfit))+"\n            ")])]):_vm._e(),_vm._v(" "),(_vm.type == 2)?_c('div',{staticClass:"col-report-2sm"},[_c('p',{staticClass:"m-0 text-right"},[_vm._v("\n                "+_vm._s(Math.abs(item.marginProfit))+"%\n            ")])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"col-report"},[(item.stockOnDate >= 0)?_c('p',{staticClass:"m-0 text-right"},[_vm._v("\n                "+_vm._s(item.stockOnDate != 0 ? item.stockOnDate : "-")+"\n            ")]):_vm._e(),_vm._v(" "),(item.stockOnDate < 0)?_c('p',{staticClass:"m-0 text-right"},[_vm._v("\n                ("+_vm._s(item.stockOnDate != 0
                        ? Math.abs(item.stockOnDate)
                        : "-")+")\n            ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"col-report-sm"},[(item.valOnDate >= 0)?_c('p',{staticClass:"m-0 text-right"},[_vm._v("\n                "+_vm._s(_vm._f("currency")(item.valOnDate != 0 ? item.valOnDate : 0))+"\n            ")]):_vm._e(),_vm._v(" "),(item.valOnDate < 0)?_c('p',{staticClass:"m-0 text-right"},[_vm._v("\n                ("+_vm._s(_vm._f("currency")(item.valOnDate != 0
                        ? Math.abs(item.valOnDate)
                        : 0))+")\n            ")]):_vm._e()])])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }