<template>
    <main>
        <hero title="User Guides" prevBreadcrumb="Dashboard" routeBreadcrumb="home" currentBreadcrumb="User Guides"></hero>

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 2"
                style="height:250px"
            >                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity:0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 2"
                style="height:250px"
            >                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <div
                        class="spinner-border spinner-border-lg text-info mx-auto"
                        role="status"
                        style="display: flex;
                        justify-content: center;"
                    ></div>
                    <center class="mt-2">
                        <span class="text-center" style="opacity:0.7"
                            >Update Data</span
                        >
                    </center>
                </div>
            </div>
            <!-- Basic -->
            <div
                class="block col-12 col-md-8 offset-md-2  block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 0"
            >
                <div class="block-content block-content-full text-center">
                    <p>
                        This user guides explains the many ways in which to use
                        the site in order <br />
                        to get the most out of our vast resource. <br />
                        You can download it below here
                    </p>
                    <img
                        class="mb-4"
                        :src="'/media/proxima/manual-book.svg'"
                        alt=""
                        style="width:100%; max-width:400px;"
                    />
                    <br />
                    <a
                        type="button"
                        class="btn btn-info btn-icon"
                        style="margin: 15px 0px 20px 0px"
                        href="/download/Proxima Manual Book_2.pdf"
                        target="_blank"
                    >
                        Download User Guides
                        <i class="entypo-down"></i>
                    </a>
                    <p>
                        <b> Instruction : </b> This User Guide is bundled on pdf
                        format, <br />
                        to open User Guide file you should use a pdf reader
                    </p>
                    <p>
                        For all further questions, please get in touch with us
                        by <b> Help : Contact Us</b> menu
                    </p>
                </div>
            </div>
            <!-- END Basic -->
        </div>
        <!-- END Page Content -->
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import axios from "axios";

export default {
    name: "edit-profile",
    data() {
        return {
            loadingPage: 0,
            item: {
                url: "",
                label: ""
            }
        };
    },
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState(["auth"])
    },
    methods: {
        downloadItem({ url, label }) {
            Axios.get(url, { responseType: "blob" })
                .then(response => {
                    const blob = new Blob([response.data], {
                        type: "application/pdf"
                    });
                    const link = document.createElement("a");
                    link.href = URL.createObjectURL(blob);
                    link.download = label;
                    link.click();
                    URL.revokeObjectURL(link.href);
                })
                .catch(console.error);
        }
    }
};
</script>
