import $axios from "../../api.js";

const state = () => ({
    recurringjournals: [],
    recurringjournal: {
        recurring_name: "",
        recurring_detail: []
    },

    page: 1, //UNTUK MENCATAT PAGE PAGINATE YANG SEDANG DIAKSES
    last_page: 1,
    search: ""
});

const mutations = {
    //MEMASUKKAN DATA KE STATE NEWS
    ASSIGN_DATA(state, payload) {
        state.recurringjournals = payload;
        state.last_page = payload.meta.last_page;
        state.page = payload.meta.current_page;
    },
    //MENGUBAH DATA STATE PAGE
    SET_PAGE(state, payload) {
        state.page = payload;
    },
    //MENGUBAH DATA STATE NEWS
    ASSIGN_FORM(state, payload) {
        state.recurringjournal = {
            recurring_name: payload.recurring_name,
            recurring_detail: payload.recurring_detail
        };
    },
    //ME-RESET STATE NEWS MENJADI KOSONG
    CLEAR_FORM(state) {
        state.recurringjournal = {
            recurring_name: "",
            recurring_detail: []
        };
    },
    //ME-RESET STATE NEWS MENJADI KOSONG
    CLEAR_DATA(state) {
        state.recurringjournals = [];
    }
};

const actions = {
    getRecurring({ dispatch, commit, state }, payload) {
        return new Promise((resolve, reject) => {
            let search =
                typeof payload.search != "undefined" ? payload.search : "";
            // let date_start = payload.date_start != 'undefined' ? payload.date_start : null;
            // let date_end = payload.date_end != 'undefined' ? payload.date_end: null;
            let rows = payload.rows;
            let page =
                typeof payload.rows != "All" && payload.rowsUpdate != 1
                    ? state.page
                    : "1";
            $axios
                .get(
                    `/recurring-journal?page=${page}&search=${search}&rows=${rows}`
                )
                .then(response => {
                    commit("ASSIGN_DATA", response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                    reject(error);
                });
        });
    },
    useRecurring({ commit, state }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .get(`/recurring-journal/${payload}/edit`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    //MENGHAPUS DATA
    removeRecurring({ commit, state }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .delete(`/recurring-journal/${payload}`)
                .then(response => {
                    commit("CLEAR_FORM");
                    resolve(response.data);
                })
                .catch(error => {
                    if (error.response) {
                        console.log(error);
                    }
                });
        });
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
