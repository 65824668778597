var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('hero',{attrs:{"title":"Edit Purchase","prevBreadcrumb":"Purchase Register","routeBreadcrumb":"purchase-register","currentBreadcrumb":"Edit Purchase"}}),_vm._v(" "),_c('div',{staticClass:"content"},[(_vm.loadingPage == 1)?_c('div',{staticClass:"block block-rounded block-mode-loading-oneui",staticStyle:{"height":"250px"}},[_c('div',{staticClass:"block-content block-content-full pb-0",staticStyle:{"padding-top":"80px"}},[_c('grid-loader',{staticClass:"mx-auto",attrs:{"color":'#70B9EB',"size":'10px'}}),_vm._v(" "),_c('center',{staticClass:"mt-2"},[_c('span',{staticClass:"text-center",staticStyle:{"opacity":"0.7"}},[_vm._v("Loading")])])],1)]):_vm._e(),_vm._v(" "),(_vm.loadingPage == 2)?_c('div',{staticClass:"block block-rounded block-mode-loading-oneui",staticStyle:{"height":"250px"}},[_c('div',{staticClass:"block-content block-content-full pb-0",staticStyle:{"padding-top":"80px"}},[_c('grid-loader',{staticClass:"mx-auto",attrs:{"color":'#70B9EB',"size":'10px'}}),_vm._v(" "),_c('center',{staticClass:"mt-2"},[_c('span',{staticClass:"text-center",staticStyle:{"opacity":"0.7"}},[_vm._v("Save Data")])])],1)]):_vm._e(),_vm._v(" "),(_vm.loadingPage == 0)?_c('div',{staticClass:"block block-rounded block-mode-loading-oneui"},[_c('v-tabs',{staticStyle:{"border-bottom":"1px solid #ebebeb"},attrs:{"background-color":"white"}},_vm._l((_vm.labels),function(label){return _c('v-tab',{key:label,class:{
                        'v-tab--active': label == _vm.type,
                        'v-tab--active--false': label !== _vm.type
                    },on:{"click":function($event){return _vm.changeType(label)}}},[_vm._v("\n                    "+_vm._s(label)+"\n                ")])}),1),_vm._v(" "),_c('Notification'),_vm._v(" "),_c('form',{attrs:{"method":"POST","enctype":"multipart/form-data","onsubmit":"return false;"}},[_c('div',{staticClass:"block-content block-content-full"},[_c('div',{staticClass:"row font-size-sm"},[_c('div',{staticClass:"col-12 py-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-6 py-0"},[_c('label',{staticClass:"mb-0",staticStyle:{"opacity":"0.7"}},[_vm._v("GENERAL")]),_vm._v(" "),_c('hr',{staticClass:"mt-2 mb-3"}),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-7 pb-1 pt-0"},[_c('div',{staticClass:"form-group mb-1"},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Supplier Name*")]),_vm._v(" "),_c('selectize',{staticClass:"form-control mb-1",class:{
                                                        'is-invalid-selectize':
                                                            _vm.errors.bill_contact_id
                                                    },attrs:{"placeholder":"Supplier Name","disabled":_vm.purchase.bill_paid_total !=
                                                            0 ||
                                                            _vm.purchase.forbidden ==
                                                                true ||
                                                            _vm.purchase.bill_status ==
                                                                5 ||
                                                            _vm.purchase.bill_status ==
                                                                4},on:{"input":function($event){return _vm.getContactDetail(
                                                            _vm.purchase.bill_contact_id
                                                        )}},model:{value:(
                                                        _vm.purchase.bill_contact_id
                                                    ),callback:function ($$v) {_vm.$set(_vm.purchase, "bill_contact_id", $$v)},expression:"\n                                                        purchase.bill_contact_id\n                                                    "}},_vm._l((_vm.contacts),function(contact){return _c('option',{key:contact.id,domProps:{"value":contact.id}},[_vm._v(_vm._s(contact.contact_name))])}),0),_vm._v(" "),_c('transition',{attrs:{"name":"slide-fade"}},[(
                                                            _vm.errors.bill_contact_id
                                                        )?_c('p',{staticClass:"text-danger pb-0 mb-1"},[_vm._v("\n                                                        "+_vm._s(_vm.errors
                                                                .bill_contact_id[0])+"\n                                                    ")]):_vm._e()])],1)]),_vm._v(" "),_c('div',{staticClass:"col-5 pb-1 pt-0"},[_c('div',{staticClass:"form-group mb-1"},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Beginning\n                                                    Balance")]),_vm._v(" "),_c('money',{staticClass:"form-control",staticStyle:{"text-align":"right"},attrs:{"spellcheck":"false","disabled":""},model:{value:(
                                                        _vm.purchase.bill_contact_beginning_balance
                                                    ),callback:function ($$v) {_vm.$set(_vm.purchase, "bill_contact_beginning_balance", $$v)},expression:"\n                                                        purchase.bill_contact_beginning_balance\n                                                    "}}),_vm._v(" "),_c('transition',{attrs:{"name":"slide-fade"}},[(
                                                            _vm.errors.bill_contact_beginning_balance
                                                        )?_c('p',{staticClass:"text-danger pb-0 mb-1"},[_vm._v("\n                                                        "+_vm._s(_vm.errors
                                                                .bill_contact_beginning_balance[0])+"\n                                                    ")]):_vm._e()])],1)])]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 py-1"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Supplier Address\n                                                    *")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                        _vm.purchase.bill_shipaddress
                                                    ),expression:"\n                                                        purchase.bill_shipaddress\n                                                    "}],staticClass:"form-control",class:{
                                                        'is-invalid':
                                                            _vm.errors.bill_shipaddress
                                                    },attrs:{"type":"text","placeholder":"Supplier Address","disabled":_vm.purchase.bill_paid_total !=
                                                            0 ||
                                                            _vm.purchase.forbidden ==
                                                                true ||
                                                            _vm.purchase.bill_status ==
                                                                5 ||
                                                            _vm.purchase.bill_status ==
                                                                4},domProps:{"value":(
                                                        _vm.purchase.bill_shipaddress
                                                    )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.purchase, "bill_shipaddress", $event.target.value)}}}),_vm._v(" "),_c('transition',{attrs:{"name":"slide-fade"}},[(
                                                            _vm.errors.bill_shipaddress
                                                        )?_c('p',{staticClass:"text-danger pb-0 mb-1"},[_vm._v("\n                                                        "+_vm._s(_vm.errors
                                                                .bill_shipaddress[0])+"\n                                                    ")]):_vm._e()])],1)])])]),_vm._v(" "),_c('div',{staticClass:"col-12 col-lg-6 py-0"},[_c('label',{staticClass:"mb-0",staticStyle:{"opacity":"0.7"}},[_vm._v("BILL INFORMATION")]),_vm._v(" "),_c('hr',{staticClass:"mt-2 mb-3"}),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 pb-1 pt-0"},[_c('div',{staticClass:"form-group mb-2"},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Issued On *")]),_vm._v(" "),_c('date-picker',{staticClass:"input-group",class:{
                                                        'is-invalid':
                                                            _vm.errors.bill_date
                                                    },attrs:{"format":"DD/MM/YYYY","valueType":"YYYY-MM-DD","placeholder":"Issued On","default-value":new Date(),"disabled-date":_vm.disabledDatePicker,"disabled":_vm.purchase.bill_status ==
                                                            4 ||
                                                            _vm.purchase
                                                                .reference_invoice
                                                                .length >
                                                                0 ||
                                                            _vm.purchase.bill_paid_total !=
                                                                0 ||
                                                            _vm.purchase.forbidden ==
                                                                true ||
                                                            _vm.purchase.bill_status ==
                                                                5},on:{"input":function($event){return _vm.changeDate()}},model:{value:(
                                                        _vm.purchase.bill_date
                                                    ),callback:function ($$v) {_vm.$set(_vm.purchase, "bill_date", $$v)},expression:"\n                                                        purchase.bill_date\n                                                    "}}),_vm._v(" "),_c('transition',{attrs:{"name":"slide-fade"}},[(
                                                            _vm.errors.bill_date
                                                        )?_c('p',{staticClass:"text-danger pb-0 mb-1"},[_vm._v("\n                                                        "+_vm._s(_vm.errors
                                                                .bill_date[0])+"\n                                                    ")]):_vm._e()])],1)]),_vm._v(" "),_c('div',{staticClass:"col-6 pb-1 pt-0"},[_c('div',{staticClass:"form-group mb-2"},[(
                                                        this.type !=
                                                            'Invoice'
                                                    )?_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Due On\n                                                    (Optional)")]):_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Due On *\n                                                ")]),_vm._v(" "),_c('date-picker',{staticClass:"input-group",class:{
                                                        'is-invalid':
                                                            _vm.errors.bill_due_date
                                                    },attrs:{"format":"DD/MM/YYYY","valueType":"YYYY-MM-DD","placeholder":"Due On","default-value":new Date(),"disabled-date":_vm.disabledDatePicker,"disabled":_vm.purchase.bill_status ==
                                                            4 ||
                                                            _vm.purchase
                                                                .reference_invoice
                                                                .length >
                                                                0 ||
                                                            _vm.purchase.bill_paid_total !=
                                                                0 ||
                                                            _vm.purchase.forbidden ==
                                                                true ||
                                                            _vm.purchase.bill_status ==
                                                                5},model:{value:(
                                                        _vm.purchase.bill_due_date
                                                    ),callback:function ($$v) {_vm.$set(_vm.purchase, "bill_due_date", $$v)},expression:"\n                                                        purchase.bill_due_date\n                                                    "}}),_vm._v(" "),_c('transition',{attrs:{"name":"slide-fade"}},[(
                                                            _vm.errors.bill_due_date
                                                        )?_c('p',{staticClass:"text-danger pb-0 mb-1"},[_vm._v("\n                                                        "+_vm._s(_vm.errors
                                                                .bill_due_date[0])+"\n                                                    ")]):_vm._e(),_vm._v(" "),(
                                                            _vm.purchase.bill_due_date !=
                                                                null &&
                                                                _vm.purchase.bill_due_date !=
                                                                    '' &&
                                                                _vm.purchase.bill_due_date <
                                                                    _vm.purchase.bill_date
                                                        )?_c('p',{staticClass:"text-danger pb-0 mb-0"},[_vm._v("\n                                                        Purchases Due Date\n                                                        lower than Purchases\n                                                        Date\n                                                    ")]):_vm._e()])],1)])]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 py-1"},[(_vm.type == 'Quotation')?_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Purchase Quotation\n                                                    Number *")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                        _vm.purchase.bill_quotation_number
                                                    ),expression:"\n                                                        purchase.bill_quotation_number\n                                                    "}],staticClass:"form-control",class:{
                                                        'is-invalid':
                                                            _vm.errors.bill_number
                                                    },attrs:{"type":"text","placeholder":"Purchase Quotation Number","disabled":_vm.purchase.bill_paid_total !=
                                                            0 ||
                                                            _vm.purchase.forbidden ==
                                                                true ||
                                                            _vm.purchase.bill_status ==
                                                                5 ||
                                                            _vm.purchase.bill_status ==
                                                                4},domProps:{"value":(
                                                        _vm.purchase.bill_quotation_number
                                                    )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.purchase, "bill_quotation_number", $event.target.value)}}}),_vm._v(" "),_c('transition',{attrs:{"name":"slide-fade"}},[(
                                                            _vm.errors.bill_number
                                                        )?_c('p',{staticClass:"text-danger pb-0 mb-1"},[_vm._v("\n                                                        "+_vm._s(_vm.errors
                                                                .bill_number[0])+"\n                                                    ")]):_vm._e()])],1):_vm._e(),_vm._v(" "),(_vm.type == 'Order')?_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Purchase Order Number\n                                                    *")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                        _vm.purchase.bill_order_number
                                                    ),expression:"\n                                                        purchase.bill_order_number\n                                                    "}],staticClass:"form-control",class:{
                                                        'is-invalid':
                                                            _vm.errors.bill_number
                                                    },attrs:{"type":"text","placeholder":"Purchase Order Number","disabled":_vm.purchase.bill_paid_total !=
                                                            0 ||
                                                            _vm.purchase.forbidden ==
                                                                true ||
                                                            _vm.purchase.bill_status ==
                                                                5 ||
                                                            _vm.purchase.bill_status ==
                                                                4},domProps:{"value":(
                                                        _vm.purchase.bill_order_number
                                                    )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.purchase, "bill_order_number", $event.target.value)}}}),_vm._v(" "),_c('transition',{attrs:{"name":"slide-fade"}},[(
                                                            _vm.errors.bill_number
                                                        )?_c('p',{staticClass:"text-danger pb-0 mb-1"},[_vm._v("\n                                                        "+_vm._s(_vm.errors
                                                                .bill_number[0])+"\n                                                    ")]):_vm._e()])],1):_vm._e(),_vm._v(" "),(_vm.type == 'Invoice')?_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Purchase Invoice Number\n                                                    *")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                        _vm.purchase.bill_invoice_number
                                                    ),expression:"\n                                                        purchase.bill_invoice_number\n                                                    "}],staticClass:"form-control",class:{
                                                        'is-invalid':
                                                            _vm.errors.bill_number
                                                    },attrs:{"type":"text","placeholder":"Purchase Invoice Number","disabled":_vm.purchase.bill_paid_total !=
                                                            0 ||
                                                            _vm.purchase.forbidden ==
                                                                true ||
                                                            _vm.purchase.bill_status ==
                                                                5 ||
                                                            _vm.purchase.bill_status ==
                                                                4},domProps:{"value":(
                                                        _vm.purchase.bill_invoice_number
                                                    )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.purchase, "bill_invoice_number", $event.target.value)}}}),_vm._v(" "),_c('transition',{attrs:{"name":"slide-fade"}},[(
                                                            _vm.errors.bill_number
                                                        )?_c('p',{staticClass:"text-danger pb-0 mb-1"},[_vm._v("\n                                                        "+_vm._s(_vm.errors
                                                                .bill_number[0])+"\n                                                    ")]):_vm._e()])],1):_vm._e()]),_vm._v(" "),(
                                                _vm.type == 'Invoice' &&
                                                    _vm.purchase.reference_order
                                            )?_c('div',{staticClass:"col-6 py-1"},[_c('div',{staticClass:"form-group mb-3"},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Order Number\n                                                ")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                        _vm.purchase
                                                            .reference_order
                                                            .bill_number
                                                    ),expression:"\n                                                        purchase\n                                                            .reference_order\n                                                            .bill_number\n                                                    "}],staticClass:"form-control",class:{
                                                        'is-invalid':
                                                            _vm.errors.reference_order
                                                    },attrs:{"type":"text","placeholder":"Purchase Invoice Number","disabled":""},domProps:{"value":(
                                                        _vm.purchase
                                                            .reference_order
                                                            .bill_number
                                                    )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.purchase
                                                            .reference_order
                                                            , "bill_number", $event.target.value)}}})])]):_vm._e()]),_vm._v(" "),(_vm.type == 'Invoice')?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 pb-1 pt-0"},[_c('transition',{attrs:{"name":"slide-fade"}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Account for\n                                                        Tracking Payable\n                                                        *")]),_vm._v(" "),_c('selectize',{staticClass:"form-control",class:{
                                                            'is-invalid-selectize':
                                                                _vm.errors.bill_coa_id
                                                        },attrs:{"placeholder":"Choose Account No.","disabled":_vm.purchase.bill_paid_total !=
                                                                0 ||
                                                                _vm.purchase.forbidden ==
                                                                    true ||
                                                                _vm.purchase.bill_status ==
                                                                    5 ||
                                                                _vm.purchase.bill_status ==
                                                                    4},model:{value:(
                                                            _vm.purchase.bill_coa_id
                                                        ),callback:function ($$v) {_vm.$set(_vm.purchase, "bill_coa_id", $$v)},expression:"\n                                                            purchase.bill_coa_id\n                                                        "}},_vm._l((_vm.payableAccounts),function(account){return _c('option',{key:account.id,domProps:{"value":account.id}},[_vm._v("\n                                                            "+_vm._s(account.account_no)+"\n                                                            "+_vm._s(account.account_name)+"\n                                                        ")])}),0),_vm._v(" "),_c('transition',{attrs:{"name":"slide-fade"}},[(
                                                                _vm.errors.bill_coa_id
                                                            )?_c('p',{staticClass:"text-danger pb-0 mb-1"},[_vm._v("\n                                                            "+_vm._s(_vm.errors
                                                                    .bill_coa_id[0])+"\n                                                        ")]):_vm._e()])],1)])],1),_vm._v(" "),_c('div',{staticClass:"col-6 pt-0"},[_c('transition',{attrs:{"name":"slide-fade"}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Journal Memo\n                                                        *")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                            _vm.purchase.bill_journal_memo
                                                        ),expression:"\n                                                            purchase.bill_journal_memo\n                                                        "}],staticClass:"form-control",class:{
                                                            'is-invalid':
                                                                _vm.errors.bill_journal_memo
                                                        },attrs:{"type":"text","placeholder":"Journal Memo","disabled":_vm.purchase.bill_paid_total !=
                                                                0 ||
                                                                _vm.purchase.forbidden ==
                                                                    true ||
                                                                _vm.purchase.bill_status ==
                                                                    5 ||
                                                                _vm.purchase.bill_status ==
                                                                    4},domProps:{"value":(
                                                            _vm.purchase.bill_journal_memo
                                                        )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.purchase, "bill_journal_memo", $event.target.value)}}}),_vm._v(" "),_c('transition',{attrs:{"name":"slide-fade"}},[(
                                                                _vm.errors.bill_journal_memo
                                                            )?_c('p',{staticClass:"text-danger pb-0 mb-1"},[_vm._v("\n                                                            "+_vm._s(_vm.errors
                                                                    .bill_journal_memo[0])+"\n                                                        ")]):_vm._e()])],1)])],1)]):_vm._e()])]),_vm._v(" "),_c('hr',{staticClass:"mt-3 mb-4 border-dash"}),_vm._v(" "),(
                                    this.purchase
                                        .bill_transaction_from_order
                                        .length > 0
                                )?[_c('div',{staticClass:"row py-2 text-muted px-2"},[_c('label',{staticClass:"mb-0",staticStyle:{"opacity":"0.7"}},[_vm._v("ASSOCIATED TO ORDER NO.\n                                        "+_vm._s(_vm.associatedRefNo))])]),_vm._v(" "),_c('EditAssociateTransaction'),_vm._v(" "),_vm._m(0)]:_vm._e(),_vm._v(" "),_c('EditTransaction')],2)]),_vm._v(" "),_c('div',{staticClass:"row px-2"},[_c('div',{staticClass:"col-12 col-md-4 offset-md-8",staticStyle:{"background-color":"#e9ecef !important","width":"350px","padding-top":"10px"}},[_c('strong',[_c('p',{staticClass:"mb-2 font-w700"},[_vm._v("\n                                    Subtotal\n                                    "),_c('span',{staticClass:"float-right"},[_vm._v(_vm._s(_vm._f("currency")(_vm.purchase.bill_sub_total)))])])]),_vm._v(" "),_c('p',{staticClass:"mb-2"},[_vm._v("\n                                Tax Total\n                                "),_c('small',{staticClass:"float-right"},[_vm._v(_vm._s(_vm._f("currency")(_vm.purchase.bill_tax_total)))])]),_vm._v(" "),_c('strong',[_c('p',{staticClass:"mb-2 font-w700"},[_vm._v("\n                                    Grand Total\n                                    "),_c('span',{staticClass:"float-right"},[_vm._v(_vm._s(_vm._f("currency")(_vm.purchase.bill_grand_total)))])])])])])]),_vm._v(" "),_c('div',{staticClass:"block-footer px-2",staticStyle:{"background-color":"#F9F9F9"}},[_c('div',{staticClass:"row items-push"},[_c('div',{staticClass:"col-12 text-right mb-2"},[_c('router-link',{directives:[{name:"ripple",rawName:"v-ripple",value:({ center: true }),expression:"{ center: true }"}],staticClass:"btn btn-sm btn-alt-light mb-1 mr-1",attrs:{"type":"button","tag":"button","to":{ name: 'purchase-register' }}},[_c('i',{staticClass:"fa fa-fw fa-times mr-1"}),_vm._v("\n                                Cancel\n                            ")]),_vm._v(" "),(
                                    !_vm.forbiddenData &&
                                        _vm.purchase.bill_paid_total == 0
                                )?_c('button',{directives:[{name:"ripple",rawName:"v-ripple",value:({ center: true }),expression:"{ center: true }"}],staticClass:"btn btn-sm btn-info mb-1 mr-1",attrs:{"type":"button","disabled":(_vm.purchase.bill_due_date != '' &&
                                        _vm.purchase.bill_due_date <
                                            _vm.purchase.bill_date) ||
                                        _vm.purchase.bill_status == 4 ||
                                        _vm.purchase.reference_invoice.length >
                                            0 ||
                                        _vm.purchase.bill_status == 5},on:{"click":function($event){return _vm.review()}}},[_c('i',{staticClass:"fa fa-fw fa-file-alt mr-1"}),_vm._v("\n                                Review\n                            ")]):_vm._e(),_vm._v(" "),(
                                    !_vm.forbiddenData &&
                                        _vm.purchase.bill_paid_total == 0
                                )?_c('button',{directives:[{name:"ripple",rawName:"v-ripple",value:({ center: true }),expression:"{ center: true }"}],staticClass:"btn btn-sm btn-danger mb-1",attrs:{"type":"button","disabled":_vm.purchase.bill_status == 4 ||
                                        _vm.purchase.reference_invoice.length >
                                            0 ||
                                        _vm.purchase.bill_status == 5},on:{"click":function($event){return _vm.deleteData()}}},[_c('i',{staticClass:"fa fa-fw fa-trash mr-1"}),_vm._v("\n                                Delete\n                            ")]):_vm._e()],1)])])])],1):_vm._e()]),_vm._v(" "),_c('modal',{attrs:{"name":"review-purchase","width":"95%","classes":"modal-proxima-lg block-rounded block-mode-loading-oneui","height":"auto","adaptive":""},on:{"before-close":function($event){return _vm.clearModal()}}},[_c('Review',{attrs:{"data":this.purchase,"type":'update'}})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row py-2 text-muted px-2"},[_c('label',{staticClass:"mb-0",staticStyle:{"opacity":"0.7"}},[_vm._v("OTHER TRANSACTION")])])}]

export { render, staticRenderFns }