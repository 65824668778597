<template>
    <main>
        <hero title="Create Warehouse" prevBreadcrumb="Warehouse Data" routeBreadcrumb="warehouse" currentBreadcrumb="Create Warehouse"></hero>

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <div
                class="block col-12 col-md-8 offset-md-2 block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 1"
                style="height: 250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <div
                class="block col-12 col-md-8 offset-md-2 block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 2"
                style="height: 250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Save Data</span
                        >
                    </center>
                </div>
            </div>
            <!-- Basic -->
            <div
                class="block col-12 col-md-8 offset-md-2  block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 0"
            >
                <div class="block-content block-content-full">
                    <form
                        method="POST"
                        enctype="multipart/form-data"
                        onsubmit="return false;"
                    >
                        <!--Form-->
                        <div class="row font-size-sm">
                            <div class="col-12 col-md-10 offset-md-1">
                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Warehouse Name *</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Warehouse Name"
                                        v-model="warehouse.warehouse_name"
                                        :class="{
                                            'is-invalid': errors.warehouse_name,
                                        }"
                                    />
                                    <transition name="slide-fade">
                                        <p
                                            class="text-danger"
                                            v-if="errors.warehouse_name"
                                        >
                                            {{ errors.warehouse_name[0] }}
                                        </p>
                                    </transition>
                                </div>
                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Warehouse Location *</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Warehouse Location"
                                        v-model="warehouse.warehouse_location"
                                        :class="{
                                            'is-invalid': errors.warehouse_location,
                                        }"
                                    />
                                    <transition name="slide-fade">
                                        <p
                                            class="text-danger"
                                            v-if="errors.warehouse_location"
                                        >
                                            {{ errors.warehouse_location[0] }}
                                        </p>
                                    </transition>
                                </div>
                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Workspace Status</label
                                    >
                                    <selectize
                                        class="form-control mt-1"
                                        placeholder="Choose Workspace Status"
                                        v-model="warehouse.warehouse_status"
                                        :class="{
                                            'is-invalid-selectize':
                                                errors.warehouse_status,
                                        }"
                                    >
                                        <option value="Active">
                                            Active
                                        </option>
                                        <option value="Inactive">
                                            Inactive
                                        </option>
                                        <!-- <option value="double">Double Declining</option> -->
                                    </selectize>
                                    <transition name="slide-fade">
                                        <p
                                            class="text-danger"
                                            v-if="errors.warehouse_status"
                                        >
                                            {{ errors.warehouse_status[0] }}
                                        </p>
                                    </transition>
                                </div>
                            </div>
                        </div>
                        <!--END Form-->
                        <!-- Submit -->
                        <div class="row items-push">
                            <div class="col-12 text-center">
                                <p class="font-size-sm text-muted">
                                    * Required filled, Make sure all data is
                                    correctly filled
                                </p>
                                <button
                                    type="submit"
                                    class="btn btn-sm btn-success"
                                    v-ripple="{ center: true }"
                                    @click.prevent="submit"
                                >
                                    <i class="fa fa-fw fa-check mr-1"></i> Save
                                </button>
                                <router-link
                                    type="button"
                                    class="btn btn-sm btn-alt-light"
                                    tag="button"
                                    v-ripple="{ center: true }"
                                    :to="{ name: 'warehouse' }"
                                >
                                    <i class="fa fa-fw fa-times mr-1"></i> Close
                                </router-link>
                            </div>
                        </div>
                        <!-- END Submit -->
                    </form>
                </div>
            </div>
            <!-- END Basic -->
        </div>
        <!-- END Page Content -->
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

export default {
    name: "create-warehouse",

    data() {
        return {
            loadingPage: 0,
        };
    },
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState("coa", { coas: (state) => state.coas.accounts }),
        ...mapState("warehouse", {
            warehouse: (state) => state.warehouse,
        }),
    },
    methods: {
        //VUEX
        ...mapMutations("warehouse", ["CLEAR_FORM"]),
        ...mapActions("warehouse", ["submitWarehouse"]),
        submit() {
            this.loadingPage = 2;
            this.submitWarehouse()
                .then((response) => {
                    this.$router.push({ name: "warehouse" });
                    this.alert("Successfully create Warehouse Data ", 1);
                    this.loadingPage = 0;
                })
                .catch((error) => {
                    error.response.status != 422
                        ? this.alert(error.message, 2)
                        : "";
                    this.loadingPage = 0;
                });
        },
    },
    destroyed() {
        //FORM DI BERSIHKAN
        this.CLEAR_FORM();
    },
};
</script>
