import $axios from "../../api.js";

const state = () => ({
    types: [],

    type: {
        type_id: 0,
        type_name: "",
        inventory_coa_id: 0,
        expense_coa_id: 0,
        income_coa_id: 0,
        is_sell: 0,
        is_purchase: 0,
        is_inventory: 0,
        sell_tax_id: 0,
        purchase_tax_id: 0,
        user_id: 0,
        asset_data: []
    },
    page: 1, //UNTUK MENCATAT PAGE PAGINATE YANG SEDANG DIAKSES
    last_page: 1,
    search: ""
});

const mutations = {
    ASSIGN_DATA(state, payload) {
        state.types = payload;
        state.last_page = payload.meta.last_page;
        state.page = payload.meta.current_page;
    },
    SET_PAGE(state, payload) {
        state.page = payload;
    },
    ASSIGN_FORM(state, payload) {
        state.type = {
            type_id: payload.id,
            type_name: payload.type_name,
            inventory_coa_id: payload.inventory_coa_id,
            expense_coa_id: payload.expense_coa_id,
            income_coa_id: payload.income_coa_id,
            is_sell: payload.is_sell,
            is_purchase: payload.is_purchase,
            is_inventory: payload.is_inventory,
            sell_tax_id: payload.sell_tax_id,
            purchase_tax_id: payload.purchase_tax_id,
            user_id: payload.user_id,
            asset_data: payload.assetData
        };
    },
    //ME-RESET STATE NEWS MENJADI KOSONG
    CLEAR_FORM(state) {
        state.type = {
            type_id: 0,
            type_name: "",
            inventory_coa_id: 0,
            expense_coa_id: 0,
            income_coa_id: 0,
            is_sell: 1,
            is_purchase: 1,
            is_inventory: 1,
            user_id: 0,
            asset_data: []
        };
    },
    CLEAR_DATA(state) {
        state.types = [];
    }
};

const actions = {
    getType({ dispatch, commit, state }, payload) {
        let search = typeof payload.search != "undefined" ? payload.search : "";
        let rows = payload.rows;
        let page =
            typeof payload.rows != "All" && payload.rowsUpdate != 1
                ? state.page
                : "1";

        return new Promise((resolve, reject) => {
            $axios
                .get(`/item-type?page=${page}&search=${search}&rows=${rows}`)
                .then(response => {
                    commit("ASSIGN_DATA", response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                    reject(error);
                });
        });
    },
    getTypeAll({ dispatch, state }) {
        $axios
            .get(`/item-type/retrive-all-data`)
            .then(response => {
                state.types = response.data;
                this.loading = false;
            })
            .catch(error => {
                error.response.status === 401
                    ? dispatch("DO_LOGOUT", null, { root: true })
                    : "";
                reject(error);
            });
    },
    submitType({ dispatch, commit, state }) {
        return new Promise((resolve, reject) => {
            $axios
                .post(`/item-type`, state.type)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                });
        });
    },
    editType({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.get(`/item-type/${payload}/edit`).then(response => {
                commit("ASSIGN_FORM", response.data);
                resolve(response.data);
            });
        });
    },
    updateType({ state, commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .put(`item-type/${payload}`, state.type)
                .then(response => {
                    //FORM DIBERSIHKAN
                    commit("CLEAR_FORM");
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                });
        });
    },
    //MENGHAPUS DATA
    removeType({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .delete(`/item-type/${payload}`)
                .then(response => {
                    commit("CLEAR_FORM");
                    resolve(response.data);
                })
                .catch(error => {
                    if (error.response) {
                        commit("SET_ERRORS", error.response.data, {
                            root: true
                        });
                    }
                    reject(error);
                });
        });
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
