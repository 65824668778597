<template>
    <main>
        <!-- Hero -->
        <div class="bg-body-light">
            <div class="content content-full">
                <div
                    class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center"
                >
                    <h1 class="flex-sm-fill h3 my-2">Accounting : Asset</h1>
                    <nav
                        class="flex-sm-00-auto ml-sm-3"
                        aria-label="breadcrumb"
                    >
                        <ol class="breadcrumb breadcrumb-alt">
                            <li class="breadcrumb-item" aria-current="page">
                                <router-link
                                    class="link-fx"
                                    :to="{ name: 'asset' }"
                                    >Asset Data</router-link
                                >
                            </li>
                            <li class="breadcrumb-item">Document Asset</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
        <!-- END Hero -->

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <!-- Invoice -->
            <div class="block mb-2 block-rounded block-mode-loading-oneui">
                <div class="block-header">
                    <h3 class="block-title">Asset {{ asset.asset_code }}</h3>
                    <div class="block-options">
                        <!-- <router-link
                            :to="{
                                name: 'journal-transaction-voucher',
                                params: { id: asset.journal.id },
                            }"
                            tag="button"
                            type="button"
                            class="btn-block-option text-warning"
                        >
                            <i class="si si-notebook mr-1"></i>
                            <span class="d-none d-md-inline-block">
                                Journal Voucher
                            </span>
                        </router-link> -->
                        <router-link
                            :to="{
                                name: 'asset-edit',
                                params: { id: asset.asset_id },
                            }"
                            tag="button"
                            type="button"
                            class="btn-block-option text-success"
                        >
                            <i class="si si-pencil mr-1"></i>
                            <span class="d-none d-md-inline-block">
                                Edit Data
                            </span>
                        </router-link>
                        <!-- Print Page functionality is initialized in Helpers.print() -->
                        <button
                            type="button"
                            class="btn-block-option text-info"
                            @click="print()"
                        >
                            <i class="si si-printer mr-1"></i>
                            <span class="d-none d-md-inline-block"
                                >Print Document</span
                            >
                        </button>
                    </div>
                </div>
            </div>
            <div class="block bg-transparent mb-2">
                <div class="block-content pt-0 bg-transparent text-right">
                    <input
                        class="form-check-input"
                        style="margin-top: 3.5px"
                        type="checkbox"
                        v-model="useHeader"
                    />
                    <label class="form-check-label">Default Header</label>
                </div>
            </div>
            <!--Loading-->
            <div class="block" v-if="loadingPage == 1" style="height: 250px">
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <!-- Basic -->
            <div class="block block-rounded block-mode-loading-oneui" v-else>
                <div class="content content-boxed" id="printMe">
                    <div class="block-content">
                        <div class="p-0 p-md-5" style="min-height: 800px">
                            <div class="row pt-0" v-if="useHeader">
                                <div class="col-6 font-size-sm pb-0 pt-0">
                                    <p class="h3 mb-2 mt-3 text-uppercase">
                                        {{
                                            auth.data.workspace
                                                .workspace_company_name
                                        }}
                                    </p>
                                    <p class="mb-0">
                                        {{
                                            auth.data.workspace
                                                .workspace_company_address
                                        }}
                                    </p>
                                    <p class="mb-0">
                                        <span
                                            v-if="
                                                auth.data.workspace
                                                    .workspace_company_province !==
                                                    '' &&
                                                auth.data.workspace
                                                    .workspace_company_province !==
                                                    null
                                            "
                                        >
                                            {{
                                                auth.data.workspace
                                                    .workspace_company_province
                                            }}
                                        </span>
                                        <span
                                            v-if="
                                                auth.data.workspace
                                                    .workspace_company_postal !==
                                                    '' &&
                                                auth.data.workspace
                                                    .workspace_company_postal !==
                                                    null
                                            "
                                        >
                                            ,
                                            {{
                                                auth.data.workspace
                                                    .workspace_company_postal
                                            }}
                                        </span>
                                    </p>
                                    <p class="mb-0">
                                        <span
                                            v-if="
                                                auth.data.workspace
                                                    .workspace_company_phone !==
                                                    '' &&
                                                auth.data.workspace
                                                    .workspace_company_phone !==
                                                    null
                                            "
                                        >
                                            {{
                                                auth.data.workspace
                                                    .workspace_company_phone
                                            }}
                                        </span>
                                        <span
                                            v-if="
                                                auth.data.workspace
                                                    .workspace_company_email !==
                                                    '' &&
                                                auth.data.workspace
                                                    .workspace_company_email !==
                                                    null
                                            "
                                        >
                                            {{
                                                "| " +
                                                auth.data.workspace
                                                    .workspace_company_email
                                            }}
                                        </span>
                                    </p>
                                </div>
                                <div
                                    class="col-6 text-right font-size-sm pb-0 pt-0"
                                >
                                    <img
                                        :src="
                                            '/storage/workspace/' +
                                            auth.data.workspace
                                                .workspace_company_logo
                                        "
                                        alt=""
                                        style="
                                            min-heigh: 100px;
                                            max-height: 120px;
                                            max-width: 180px;
                                        "
                                        @error="errorLogoUrl"
                                    />
                                </div>
                            </div>
                            <hr class="mb-3" v-if="useHeader" />
                            <div class="row mt-3">
                                <p
                                    class="h2 mx-auto text-uppercase text-center"
                                >
                                    Asset
                                </p>
                            </div>

                            <!--Form-->
                            <div class="row font-size-sm">
                                <div class="col-12 py-0">
                                    <div class="row pt-2">
                                        <div class="col-6 pt-0">
                                            <div class="form-group mt-3">
                                                <label for="example-text-input"
                                                    >Asset Category</label
                                                >
                                                <p class="mb-0">
                                                    {{
                                                        asset.category
                                                            .category_name
                                                    }}
                                                </p>
                                                <hr class="my-0">
                                            </div>
                                            <div class="form-group">
                                                <label for="example-text-input"
                                                    >Asset Name</label
                                                >
                                                <p class="mb-0">
                                                    {{ asset.asset_name }}
                                                </p>
                                                <hr class="my-0">
                                            </div>
                                            <div class="form-group">
                                                <label for="example-text-input"
                                                    >Asset Code</label
                                                >
                                                <p class="mb-0">
                                                    {{ asset.asset_code }}
                                                </p>
                                                <hr class="my-0">
                                            </div>
                                            <div class="form-group">
                                                <label for="example-text-input"
                                                    >Asset Status</label
                                                >
                                                <p class="mb-0" v-if="asset.asset_status == 1">
                                                    Active
                                                </p>
                                                <p class="mb-0" v-else>
                                                    Inactive
                                                </p>
                                                <hr class="my-0">
                                            </div>
                                        </div>
                                        <div class="col-6 pt-0">
                                            <div class="form-group mt-3">
                                                <label for="example-text-input"
                                                    >Acquisition Date</label
                                                >
                                                <p class="mb-0">
                                                    {{
                                                        asset.asset_acquisition_date
                                                            | moment(
                                                                "DD/MM/YYYY"
                                                            )
                                                    }}
                                                </p>
                                                <hr class="my-0">
                                            </div>
                                            <div class="form-group">
                                                <label for="example-text-input"
                                                    >Acquired Value</label
                                                >
                                                <p class="mb-0 text-right">
                                                    {{
                                                        asset.asset_acquisition_value
                                                            | currency
                                                    }}
                                                </p>
                                                <hr class="my-0">
                                            </div>
                                            <div class="form-group">
                                                <label for="example-text-input"
                                                    >Residual Value</label
                                                >
                                                <p class="mb-0 text-right">
                                                    {{
                                                        asset.asset_residual_value
                                                            | currency
                                                    }}
                                                </p>
                                                <hr class="my-0">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--END Form-->
                            <label class="my-2 form-group"
                                >Depreciation Data</label
                            >
                            <!-- Table -->
                            <div class="table-responsive push">
                                <table class="table table-bordered">
                                    <thead class="bg-body-light">
                                        <tr>
                                            <th
                                                class="text-center"
                                                style="width: 60px"
                                            >
                                                No.
                                            </th>
                                            <th class="text-center">Period</th>
                                            <th class="text-center">
                                                Depreciation Memo
                                            </th>
                                            <th class="text-center">
                                                Depreciation Amount
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class="font-print-md" 
                                        v-if="asset.depreciationDetail.length > 0"
                                    >
                                        <tr
                                            v-for="(
                                                depreciationDetail, index
                                            ) in asset.depreciationDetail"
                                            :key="index"
                                        >
                                            <td class="text-center">
                                                {{ ++index }}
                                            </td>
                                            <td class="text-center">
                                                <p
                                                    class="font-w600 mb-1 font-size-print"
                                                >
                                                    {{
                                                        depreciationDetail
                                                            .depreciation
                                                            .depreciation_period
                                                            | moment(
                                                                "DD/MM/YYYY "
                                                            )
                                                    }}
                                                </p>
                                            </td>
                                            <td class="text-left">
                                                {{
                                                    depreciationDetail
                                                        .depreciation
                                                        .depreciation_memo
                                                }}
                                            </td>
                                            <td class="text-right">
                                                {{
                                                    depreciationDetail.depreciation_amount
                                                        | currency
                                                }}
                                            </td>
                                        </tr>
                                        <tr class="bg-body-light">
                                            <td class="text-center" colspan="3">
                                                <strong>TOTAL DEPRECIATION</strong>
                                            </td>
                                            <td class="text-right">
                                                <strong>{{ asset.accumulated_depreciation | currency }}</strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                            <tr>
                                                <td colspan="5">
                                                    <center
                                                        style="
                                                            padding-top: 15px;
                                                            padding-bottom: 15px;
                                                            color: #cccccc;
                                                        "
                                                    >
                                                        No Depreciation Data
                                                    </center>
                                                </td>
                                            </tr>
                                        </tbody>
                                </table>
                            </div>
                            <!-- END Table -->
                            <!-- <p class="text-center" style="opacity: 0.5">
                                No Depreciation Data
                            </p> -->
                            <hr class="mt-3" />
                            <div class="row mt-3 mb-5">
                                <div
                                    class="col-6 text-left font-size-sm pt-5 mt-2"
                                >
                                    <div class="form-group d-print-none">
                                        <label for="example-text-input"
                                            >Post Note</label
                                        >
                                        <ckeditor
                                            tag-name="textarea"
                                            :editor="editor"
                                            v-model="docPostNote"
                                            :config="editorConfig"
                                        ></ckeditor>
                                    </div>
                                    <span
                                        class="d-none d-print-inline-block"
                                        v-html="docPostNote"
                                    ></span>
                                </div>
                                <div
                                    class="col-6 font-size-sm text-right pt-5 mt-2"
                                >
                                    <div
                                        class="form-group text-left d-print-none"
                                    >
                                        <label for="example-text-input"
                                            >Choose Signature</label
                                        >
                                        <selectize
                                            class="form-control"
                                            placeholder="Choose Signature"
                                            v-model="selectedSignature"
                                            @input="
                                                selectSignature(
                                                    selectedSignature
                                                )
                                            "
                                        >
                                            <option
                                                v-for="signature in signatures.data"
                                                :value="signature.id"
                                                :key="signature.id"
                                            >
                                                {{ signature.signature_name }}
                                            </option>
                                        </selectize>
                                    </div>
                                    <p
                                        class="mb-0 font-w700 mt-6 ml-9 text-center"
                                        style="border-bottom: 1px solid grey"
                                        v-bind:class="{
                                            'd-print-none':
                                                selectedSignature == '',
                                        }"
                                    >
                                        {{ docSignature.signature_name }}
                                    </p>
                                    <p
                                        class="mb-0 font-w700 ml-9 text-center"
                                        v-bind:class="{
                                            'd-print-none':
                                                selectedSignature == '',
                                        }"
                                    >
                                        {{ docSignature.signature_position }}
                                    </p>
                                </div>
                            </div>

                            <hr />
                            <p
                                class="d-none d-print-inline-block"
                                style="opacity: 0.6"
                            >
                                <i> Printed : {{ dateNow }} </i>
                            </p>
                            <!-- END Footer -->
                        </div>
                    </div>
                </div>
                <!-- END Invoice -->
            </div>
            <!-- END Basic -->
        </div>
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import moment from "moment";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
    name: "detail-asset",
    data() {
        return {
            loadingPage: 1,
            items: [],
            disabled: {},
            coaBills: [],
            useHeader: true,
            forbiddenData: false,
            dateNow: moment().format("MMMM Do YYYY, HH:mm:ss"),
            selectedSignature: "",
            docSignature: {
                signature_name: "[select signature]",
                signature_position: "[select signature]",
                signature_text: "",
            },
            selectedSignature2: "",
            docSignature2: {
                signature_name: "[select signature]",
                signature_position: "[select signature]",
                signature_text: "",
            },
            selectedBank: "",
            docBank: {
                bank_name: "[select bank]",
                bank_cabang: "[select bank]",
                bank_rekening: "[select bank]",
                bank_rekening_name: "[select bank]",
            },
            editor: ClassicEditor,
            editorConfig: {
                toolbar: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "bulletedList",
                    "numberedList",
                    "blockQuote",
                    "undo",
                    "redo",
                    "insertTable",
                ],
            },
            docPostNote: "",
        };
    },
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState(["auth"]),
        ...mapState("asset", { asset: (state) => state.asset }),
        ...mapState("assetcategory", {
            categories: (state) => state.categories,
        }),
        ...mapState("signature", { signatures: (state) => state.signatures }),
        ...mapState("bank", { banks: (state) => state.banks }),
    },
    created() {
        if (this.$route.params.id) {
            this.editAsset(this.$route.params.id)
                .then((response) => {
                    this.$session.set("asset_detail", this.$route.params.id);
                    this.loadingPage = 0;
                })
                .catch((error) => {
                    console.log(error);
                    this.loadingPage = 0;
                });
        } else if (this.$session.has("asset_detail")) {
            this.editAsset(this.$session.get("asset_detail"))
                .then((response) => {
                    this.loadingPage = 0;
                })
                .catch((error) => {
                    console.log(error);
                    this.loadingPage = 0;
                });
        } else {
            this.$router.push({ name: "asset" });
            this.alert("Data not found, select data first", 2);
        }
        this.getSignatureAndBank();
    },
    methods: {
        //VUEX
        ...mapMutations("asset", ["CLEAR_FORM"]),
        ...mapMutations(["CLEAR_ERRORS"]),
        ...mapActions("asset", [
            "editAsset",
            "getQuotationNo",
            "getOrderNo",
            "getInvoiceNo",
            "updateAsset",
            "removeAsset",
            "printAsset",
        ]),
        ...mapActions("signature", ["getSignatureDocument"]),
        ...mapActions("bank", ["getBankDocument"]),
        getSignatureAndBank() {
            this.getSignatureDocument({ document: "signature_asset" })
                .then((response) => {
                    this.loadingPage = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.loadingPage = false;
                });
        },
        selectSignature(val) {
            if (val != "") {
                var result = this.signatures.data.find((obj) => {
                    return obj.id === parseInt(val);
                });
                this.docSignature.signature_name = result.signature_name;
                this.docSignature.signature_position =
                    result.signature_position;
            } else {
                this.docSignature.signature_name = "[select signature]";
                this.docSignature.signature_position = "[select signature]";
            }
        },
        errorLogoUrl(event) {
            event.target.src = "/media/workspace/no-logo.jpg";
        },
        print() {
            var browserName = navigator.appName;
            if (browserName == "Safari") {
                var printContents = document.getElementById("printMe")
                    .innerHTML;
                var originalContents = document.body.innerHTML;
                document.body.innerHTML = printContents;
                window.print();
                document.body.innerHTML = originalContents;
            } else {
                this.$htmlToPaper("printMe");
            }
        },
    },
    destroyed() {
        this.CLEAR_FORM();
    },
};
</script>
