<template>
    <main>
        <!-- Hero -->
        <div class="bg-body-light">
            <div class="content content-full">
                <div
                    class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center"
                >
                    <h1 class="flex-sm-fill h3 my-2">
                        Administrator : Workspace Panel
                    </h1>
                    <nav
                        class="flex-sm-00-auto ml-sm-3"
                        aria-label="breadcrumb"
                    >
                        <ol class="breadcrumb breadcrumb-alt">
                            <li class="breadcrumb-item" aria-current="page">
                                <router-link
                                    class="link-fx"
                                    :to="{ name: 'home' }"
                                    >Dashboard</router-link
                                >
                            </li>
                            <li class="breadcrumb-item">Workspace Panel</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
        <!-- END Hero -->
        <!-- Content -->
        <div class="content">
            <!-- workspace -->
            <div class="row">
                <div
                    v-for="(workspace, index) in workspaces"
                    v-bind:key="index"
                    class="col-6 col-md-4 col-lg-6 col-xl-3 workspace-tile"
                >
                    <a
                        class="block block-rounded block-link-pop mb-2"
                        @click="doChangeWorkspace(workspace.workspace_token)"
                    >
                        <div
                            class="block-content block-content-full"
                            v-bind:style="
                                workspace.workspace_company_banner != null &&
                                workspace.workspace_company_banner !== 'empty'
                                    ? {
                                          'background-image':
                                              'linear-gradient(rgba(62,77,82,.5), rgba(62,77,82,.5)),url(/storage/workspace/' +
                                              workspace.workspace_company_banner +
                                              ')',
                                          'background-size': 'cover',
                                          'background-position': 'center'
                                      }
                                    : {
                                          'background-image':
                                              'linear-gradient(rgba(62,77,82,.5), rgba(62,77,82,.5)),url(/media/photos/photo26@2x.jpg)',
                                          'background-size': 'cover',
                                          'background-position': 'center'
                                      }
                            "
                        >
                            <div
                                class="font-size-sm text-white workspace-tile-title"
                            >
                                {{ workspace.workspace_name }}
                                <i
                                    class="fa fa-fw fa-check-circle text-info ml-2"
                                    v-if="
                                        workspace.workspace_token ==
                                            auth.data.workspace_token
                                    "
                                ></i>
                            </div>
                        </div>
                    </a>
                </div>
                <div
                    class="col-6 col-md-4 col-lg-6 col-xl-3 workspace-tile"
                    v-if="auth.data.membership_token"
                >
                    <router-link
                        tag="a"
                        class="block block-rounded block-link-pop"
                        :to="{ name: 'workspace-create' }"
                    >
                        <div class="block-content block-content-full">
                            <div
                                class="font-size-sm font-w600 text-uppercase text-muted workspace-tile-title"
                            >
                                <i class="fa fa-plus mr-1"></i> New Workspace
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
            <!-- END workspace -->
            <hr />
            <!-- WHHOLE FORM -->
            <div class="row mt-5">
                <div
                    class="block col-12 col-md-8 offset-md-2 block-rounded block-mode-loading-oneui"
                    v-if="loadingPage == 1"
                    style="height:250px"
                >
                    <div
                        class="block-content block-content-full pb-0"
                        style="padding-top: 80px"
                    >
                        <grid-loader
                            class="mx-auto"
                            :color="'#70B9EB'"
                            :size="'10px'"
                        ></grid-loader>
                        <center class="mt-2 mb-4">
                            <span class="text-center" style="opacity:0.7"
                                >Loading</span
                            >
                        </center>
                    </div>
                </div>
                <div
                    class="block col-12 col-md-8 offset-md-2 block-rounded block-mode-loading-oneui"
                    v-if="loadingPage == 2"
                    style="height:250px"
                >
                    <div
                        class="block-content block-content-full pb-0"
                        style="padding-top: 80px"
                    >
                        <grid-loader
                            class="mx-auto"
                            :color="'#70B9EB'"
                            :size="'10px'"
                        ></grid-loader>
                        <center class="mt-2 mb-4">
                            <span class="text-center" style="opacity:0.7"
                                >Save Data</span
                            >
                        </center>
                    </div>
                </div>
                <!-- Latest FORM -->
                <div
                    class="block col-12 col-md-8 offset-md-2 p-0 block-rounded block-mode-loading-oneui"
                    v-if="loadingPage == 0"
                >
                    <v-tabs background-color="white">
                        <v-tab
                            v-for="item in items"
                            :key="item"
                            @click="typeChange(item)"
                            v-bind:class="{
                                'v-tab--active': item == type,
                                'v-tab--active--false': item !== type
                            }"
                        >
                            {{ item }}
                        </v-tab>
                    </v-tabs>
                    <div
                        class="block-content block-content-full"
                        v-if="type == 'General'"
                    >
                        <form
                            method="POST"
                            enctype="multipart/form-data"
                            onsubmit="return false;"
                        >
                            <!--Form-->
                            <div class="row push font-size-sm">
                                <div class="col-12 col-md-10 offset-md-1">
                                    <div class="form-group">
                                        <label for="example-text-input"
                                            >Workspace Name</label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Workspace Name"
                                            v-model="workspace.workspace_name"
                                            :class="{
                                                'is-invalid':
                                                    errors.workspace_name
                                            }"
                                        />
                                        <p
                                            class="text-danger"
                                            v-if="errors.workspace_name"
                                        >
                                            {{ errors.workspace_name[0] }}
                                        </p>
                                    </div>
                                    <div class="form-group">
                                        <label for="example-text-input"
                                            >Company Name</label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Company Name"
                                            v-model="workspace.workspace_company_name"
                                            :class="{ 'is-invalid': errors.workspace_company_name }"
                                            disabled
                                        />
                                        <p
                                            class="text-danger"
                                            v-if="errors.workspace_company_name"
                                        >
                                            {{
                                                errors.workspace_company_name[0]
                                            }}
                                        </p>
                                    </div>
                                    <div class="form-group">
                                        <label for="example-text-input"
                                            >Company Address</label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Company Address"
                                            v-model="
                                                workspace.workspace_company_address
                                            "
                                            :class="{
                                                'is-invalid':
                                                    errors.workspace_company_address
                                            }"
                                        />
                                        <p
                                            class="text-danger"
                                            v-if="
                                                errors.workspace_company_address
                                            "
                                        >
                                            {{
                                                errors
                                                    .workspace_company_address[0]
                                            }}
                                        </p>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col-6 pt-0">
                                            <label for="example-text-input"
                                                >Company Province</label
                                            >
                                            <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Company Province"
                                                v-model="
                                                    workspace.workspace_company_province
                                                "
                                                :class="{
                                                    'is-invalid':
                                                        errors.workspace_company_province
                                                }"
                                            />
                                            <p
                                                class="text-danger"
                                                v-if="
                                                    errors.workspace_company_province
                                                "
                                            >
                                                {{
                                                    errors
                                                        .workspace_company_province[0]
                                                }}
                                            </p>
                                        </div>
                                        <div class="form-group col-6 py-0">
                                            <label for="example-text-input"
                                                >Company Postal Code</label
                                            >
                                            <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Company Postal Code"
                                                v-model="
                                                    workspace.workspace_company_postal
                                                "
                                                :class="{
                                                    'is-invalid':
                                                        errors.workspace_company_postal
                                                }"
                                            />
                                            <p
                                                class="text-danger"
                                                v-if="
                                                    errors.workspace_company_postal
                                                "
                                            >
                                                {{
                                                    errors
                                                        .workspace_company_postal[0]
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col-6 pt-0">
                                            <label for="example-text-input"
                                                >Company Phone Number</label
                                            >
                                            <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Company Phone Number"
                                                v-model="
                                                    workspace.workspace_company_phone
                                                "
                                                :class="{
                                                    'is-invalid':
                                                        errors.workspace_company_phone
                                                }"
                                            />
                                            <p
                                                class="text-danger"
                                                v-if="
                                                    errors.workspace_company_phone
                                                "
                                            >
                                                {{
                                                    errors
                                                        .workspace_company_phone[0]
                                                }}
                                            </p>
                                        </div>
                                        <div class="form-group col-6 py-0">
                                            <label for="example-text-input"
                                                >Company Email</label
                                            >
                                            <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Company Email"
                                                v-model="
                                                    workspace.workspace_company_email
                                                "
                                                :class="{
                                                    'is-invalid':
                                                        errors.workspace_company_email
                                                }"
                                            />
                                            <p
                                                class="text-danger"
                                                v-if="
                                                    errors.workspace_company_email
                                                "
                                            >
                                                {{
                                                    errors
                                                        .workspace_company_email[0]
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-12 col-lg-3 py-0">
                                            <label
                                                class="d-block"
                                                for="user-photo"
                                                >Company Logo</label
                                            >
                                            <img
                                                v-if="
                                                    !changeLogo &&
                                                        workspace.workspace_company_logo
                                                "
                                                :src="
                                                    '/storage/workspace/' +
                                                        workspace.workspace_company_logo
                                                "
                                                alt=""
                                                class="img-thumbnail"
                                                @error="errorLogoUrl"
                                                style="width:100px;height:100px;object-fit: cover;"
                                            />
                                            <p class="mb-0" v-if="!changeLogo">
                                                <a
                                                    class="btn-icon-only icon-succes hover-hr-table"
                                                    @click="removeLogo()"
                                                >
                                                    Change File
                                                </a>
                                            </p>
                                            <input
                                                type="file"
                                                v-if="changeLogo"
                                                @change="uploadLogo"
                                                class="mt-1"
                                                :class="{
                                                    'is-invalid':
                                                        errors.workspace_company_logo
                                                }"
                                            />
                                            <p
                                                v-if="
                                                    errors.workspace_company_logo
                                                "
                                                class="text-danger"
                                            >
                                                {{
                                                    errors
                                                        .workspace_company_logo[0]
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="d-block" for="user-photo"
                                            >Company Banner</label
                                        >
                                        <img
                                            v-if="
                                                !changeBanner &&
                                                    workspace.workspace_company_banner
                                            "
                                            :src="
                                                '/storage/workspace/' +
                                                    workspace.workspace_company_banner
                                            "
                                            alt=""
                                            class="img-thumbnail"
                                            @error="errorBannerUrl"
                                            style="width:100%;height:100px;object-fit: cover;"
                                        />
                                        <p class="mb-0" v-if="!changeBanner">
                                            <a
                                                class="btn-icon-only icon-succes hover-hr-table"
                                                @click="removeBanner()"
                                            >
                                                Change File
                                            </a>
                                        </p>
                                        <input
                                            type="file"
                                            v-if="changeBanner"
                                            @change="uploadBanner"
                                            class="mt-1"
                                            :class="{
                                                'is-invalid':
                                                    errors.workspace_company_banner
                                            }"
                                        />
                                        <p
                                            v-if="
                                                errors.workspace_company_banner
                                            "
                                            class="text-danger"
                                        >
                                            {{
                                                errors
                                                    .workspace_company_banner[0]
                                            }}
                                        </p>
                                    </div>
                                    <div class="form-group d-lg-none">
                                        <label for="example-text-input"
                                            >About Company Logo & Company
                                            Banner</label
                                        >
                                        <ol class="ml-3">
                                            <li>
                                                The supported formats for
                                                Company Logo and Company Banner
                                                are .JPG, .JPEG, or .PNG
                                            </li>
                                            <li>
                                                Company Logo will be used in
                                                Report, Invoice, Billing, and
                                                other printed documents
                                            </li>
                                            <!-- <li>It is prohibited to upload images / logos that contain SARA, insult the national symbol, and others that violate the rules</li> -->
                                            <!-- <li>Using images that contain copyright is not our responsibility</li> -->
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <!--END Form-->
                            <!-- Submit -->
                            <div class="row items-push">
                                <div class="col-12 text-center">
                                    <p
                                        class="font-size-sm text-muted"
                                    >* Require to be filled, Make sure all data is correctly filled</p>
                                    <button
                                        type="submit"
                                        class="btn btn-sm btn-success"
                                        v-ripple="{ center: true }"
                                        @click.prevent="updateData"
                                    >
                                        <i class="fa fa-fw fa-check mr-1"></i>
                                        Update Workspace
                                    </button>
                                    <button
                                        type="button"
                                        class="btn btn-sm btn-danger"
                                        v-ripple="{ center: true }"
                                        @click.prevent="
                                            deleteData(workspace.workspace_name)
                                        "
                                        v-if="workspace.workspace_level !== 1"
                                    >
                                        <i class="fa fa-fw fa-trash mr-1"></i>
                                        Delete Workspace
                                    </button>
                                    <router-link
                                        type="button"
                                        class="btn btn-sm btn-alt-light"
                                        tag="button"
                                        v-ripple="{ center: true }"
                                        :to="{ name: 'home' }"
                                    >
                                        <i class="fa fa-fw fa-times mr-1"></i>
                                        Close
                                    </router-link>
                                </div>
                            </div>
                            <!-- END Submit -->
                        </form>
                    </div>
                    <div
                        class="block-content block-content-full"
                        v-if="type == 'User Access'"
                    >
                        <!--Form-->
                        <div class="row push font-size-sm mx-2">
                            <table class="table table-bordered table-vcenter ">
                                <thead style="background-color: whitesmoke;">
                                    <tr>
                                        <th
                                            class="table-header-relative text-center"
                                            style="width: 80px;"
                                        >
                                            No.
                                        </th>
                                        <th class="table-header-relative">
                                            Name
                                        </th>
                                        <th class="table-header-relative">
                                            Role
                                        </th>
                                        <th class="table-header-relative"></th>
                                    </tr>
                                </thead>
                                <tbody
                                    v-if="
                                        workspacedetail &&
                                            workspacedetail.length > 0
                                    "
                                >
                                    <tr
                                        v-for="(detail,
                                        index) in workspacedetail"
                                        :key="index"
                                    >
                                        <td class="text-center font-size-sm">
                                            {{ index + 1 }}
                                        </td>
                                        <td
                                            class="font-size-sm"
                                            v-if="detail.created_at"
                                        >
                                            {{ detail.user.name }} ({{
                                                detail.user.username
                                            }})
                                        </td>
                                        <td
                                            class="font-size-sm"
                                            v-if="!detail.created_at"
                                        >
                                            {{ detail.user.name }} ({{
                                                detail.user.username
                                            }})
                                            <span
                                                class="badge badge-success mr-1"
                                                >New</span
                                            >
                                        </td>
                                        <td class="font-size-sm">
                                            <span
                                                v-for="(rolelist,
                                                index) in detail.workspace_role"
                                                :key="index"
                                            >
                                                <span
                                                    class="badge badge-info mr-1"
                                                    >{{
                                                        rolelist.role.role_name
                                                    }}</span
                                                >
                                            </span>
                                        </td>
                                        <td class="font-size-sm text-center">
                                            <button
                                                class="btn-icon-only btn-icon-danger"
                                                @click="
                                                    deleteUser(
                                                        detail.user.username
                                                    )
                                                "
                                            >
                                                <i class="fa fa-trash"></i>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            class="font-size-sm text-right"
                                            colspan="4"
                                        >
                                            <a
                                                class="btn-icon-only icon-succes hover-hr-table"
                                                @click="inviteUser()"
                                            >
                                                <i class="fa fa-plus mr-1"></i>
                                                Add User to Workspace
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr class="data-empty">
                                        <td
                                            class="text-center"
                                            colspan="6"
                                            style="opacity:0.8; padding:2.5rem;"
                                        >
                                            <i class="fa fa-search fa-1x"></i>
                                            <br />
                                            Data Not Found
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            class="font-size-sm text-right"
                                            colspan="4"
                                        >
                                            <a
                                                class="btn-icon-only icon-succes hover-hr-table"
                                                @click="inviteUser()"
                                            >
                                                <i class="fa fa-plus mr-1"></i>
                                                Add User to Workspace
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!--END Form-->
                        <!-- Submit -->
                        <div class="row items-push">
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Attention</label
                                    >
                                    <ol class="ml-3">
                                        <li>
                                            This page is a page to manage user
                                            access to the workspace
                                        </li>
                                        <li>
                                            You can only add user which already
                                            registered in the selected workspace
                                        </li>
                                        <li>
                                            Make sure the access privileges are
                                            given correctly
                                        </li>
                                        <li>
                                            Adding a user to a new workspace
                                            does not change the user's login
                                            data
                                        </li>
                                        <li>
                                            Removing user access can delete user
                                            data in the workspace
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <!-- END Submit -->
                    </div>
                </div>
                <!-- END FORM -->
            </div>
            <!-- END WHHOLE FORM -->
        </div>
        <!-- END Content -->
        <!-- ASSIGN WORKSPACE TO USER -->
        <modal name="assign-workspace" width="1000" height="auto" adaptive>
            <AssignWorkspace></AssignWorkspace>
        </modal>
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import AssignWorkspace from "./../../../components/AssignWorkspace";

export default {
    name: "workspace-panel",
    components: {
        AssignWorkspace
    },
    data() {
        return {
            search: "",
            changeLogo: false,
            changeBanner: false,
            loadingPage: 1,
            workspace: [],
            workspacedetail: [],
            type: "General",
            tab: null,
            items: ["General", "User Access"]
        };
    },
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState(["auth"]),
        ...mapMutations("workspace", ["CLEAR_FORM"]),
        ...mapState("workspace", { workspaces: state => state.workspaces })
    },
    mounted() {
        this.getWorkspaces();
        this.getWorkspace(this.auth.data.workspace_token)
            .then(response => {
                this.workspace = response.data;
                this.workspacedetail = response.detail;
                this.loadingPage = 0;
            })
            .catch(error => {
                console.log(error);
                this.loadingPage = 0;
            });
        this.$root.$on("newUser", value => {
            this.loadingPage = 1;
            this.getWorkspace(this.auth.data.workspace_token)
                .then(response => {
                    this.workspace = response.data;
                    this.workspacedetail = response.detail;
                    this.loadingPage = 0;
                })
                .catch(error => {
                    console.log(error);
                    this.loadingPage = 0;
                });
        });
    },
    methods: {
        ...mapActions("workspace", [
            "changeWorkspace",
            "getWorkspaces",
            "getWorkspace",
            "deleteUserWorkspace",
            "updateWorkspace",
            "deleteWorkspace"
        ]),
        //SWALL
        alert(text, tipe) {
            if (tipe === 1) {
                this.$swal({
                    title: "Succes",
                    text: text,
                    icon: "success"
                });
            } else if (tipe === 2) {
                this.$swal({
                    title: "Something Wrong",
                    text: text,
                    icon: "error"
                });
            } else if (tipe === 3) {
                this.$swal({
                    title: "Are you sure ?",
                    text: text,
                    icon: "warning",
                    buttons: ["Cancel", "Delete"],
                    dangerMode: true
                }).then(willDelete => {
                    if (willDelete) {
                        this.alert("Data has been deleted !", 1);
                    }
                });
            } else if (tipe === 4) {
                this.$swal({
                    title: "Are you sure ?",
                    text: text,
                    icon: "warning",
                    buttons: ["Cancel", "Update"],
                    dangerMode: false
                }).then(willDelete => {
                    if (willDelete) {
                        this.alert("Data has been deleted !", 1);
                    }
                });
            }
        },
        //METHOD
        typeChange(val) {
            this.type = val;
        },
        inviteUser() {
            this.$modal.show("assign-workspace");
        },
        deleteUser(val) {
            this.$swal({
                title: "Are you sure want to delete this User ?",
                icon: "warning",
                buttons: ["Cancel", "Delete"],
                dangerMode: true
            }).then(willChange => {
                if (willChange) {
                    this.deleteUserWorkspace({ username: val }).then(() => {
                        this.loadingPage = 1;
                        this.getWorkspace(this.auth.data.workspace_token)
                            .then(response => {
                                this.workspace = response.data;
                                this.workspacedetail = response.detail;
                                this.loadingPage = 0;
                            })
                            .catch(error => {
                                this.loadingPage = 0;
                            });
                        this.$swal({
                            title: "Succes",
                            text: "User Deleted",
                            icon: "success"
                        });
                    });
                }
            });
        },
        doChangeWorkspace(val) {
            this.$swal({
                title: "Are you sure want to change Workspace ?",
                icon: "warning",
                buttons: ["Cancel", "Change"]
            }).then(willChange => {
                if (willChange) {
                    this.auth.data.workspace_token = val;
                    this.changeWorkspace(val).then(() => {
                        this.loadingPage = 1;
                        this.getWorkspace(this.auth.data.workspace_token)
                            .then(response => {
                                this.workspace = response.data;
                                this.workspacedetail = response.detail;
                                this.loadingPage = 0;
                            })
                            .catch(error => {
                                this.loadingPage = 0;
                            });
                        this.$swal({
                            title: "Succes",
                            text: "Workspace Changed",
                            icon: "success"
                        });
                    });
                }
            });
        },
        removeLogo() {
            this.workspace.workspace_company_logo = "";
            this.changeLogo = true;
        },
        uploadLogo(e) {
            let file = e.target.files[0];
            let reader = new FileReader();
            reader.onloadend = file => {
                this.workspace.workspace_company_logo = reader.result;
            };
            reader.readAsDataURL(file);
        },
        removeBanner() {
            this.workspace.workspace_company_banner = "";
            this.changeBanner = true;
        },
        uploadBanner(e) {
            let file = e.target.files[0];
            let reader = new FileReader();
            reader.onloadend = file => {
                this.workspace.workspace_company_banner = reader.result;
            };
            reader.readAsDataURL(file);
        },
        errorLogoUrl(event) {
            event.target.src = "/media/workspace/no-logo.jpg";
        },
        errorBannerUrl(event) {
            event.target.src = "/media/photos/photo26@2x.jpg";
        },
        updateData() {
            this.loadingPage = 2;
            this.updateWorkspace(this.workspace)
                .then(() => {
                    this.alert("Successfully update Workspace Data ", 1);
                    this.getWorkspaces();
                    this.getWorkspace(this.auth.data.workspace_token)
                        .then(response => {
                            this.workspace = response.data;
                            this.workspacedetail = response.detail;
                            this.changeLogo = false;
                            this.changeBanner = false;
                            this.loadingPage = 0;
                        })
                        .catch(error => {
                            console.log(error);
                            this.loadingPage = 0;
                        });
                    this.loadingPage = 0;
                })
                .catch(error => {
                    error.response.status != 422
                        ? this.alert(error.message, 2)
                        : "";
                    this.loadingPage = 0;
                    console.log(error);
                });
        },
        deleteData(workspace) {
            //AKAN MENAMPILKAN JENDELA KONFIRMASI
            this.$swal({
                title:
                    "Are you sure want to delete Workspace " + workspace + " ?",
                text:
                    "Deleted data cannot be recovery, users will not be able to access the workspace again",
                icon: "warning",
                buttons: ["Cancel", "Delete"],
                dangerMode: true
            }).then(willDelete => {
                if (willDelete) {
                    this.loadingPage = 1;
                    this.deleteWorkspace(this.workspace)
                        .then(response => {
                            this.alert("Data has been deleted !", 1);
                            this.getWorkspaces();
                            this.changeWorkspace(
                                response.workspaceLevelFirst
                            ).then(() => {
                                this.getWorkspace(
                                    this.auth.data.workspace_token
                                )
                                    .then(response => {
                                        this.workspace = response.data;
                                        this.workspacedetail = response.detail;
                                        this.changeLogo = false;
                                        this.changeBanner = false;
                                        this.loadingPage = 0;
                                    })
                                    .catch(error => {
                                        this.loadingPage = 0;
                                    });
                            });
                        })
                        .catch(error => {
                            console.log(error);
                            this.loadingPage = 0;
                        });
                }
            });
        }
    }
};
</script>
