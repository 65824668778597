<template>
    <main>
        <!-- Hero -->
        <div class="bg-body-light">
            <div class="content content-full">
                <div
                    class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center"
                >
                    <h1 class="flex-sm-fill h3 my-2">
                        Administrator : Activity History
                    </h1>
                    <nav
                        class="flex-sm-00-auto ml-sm-3"
                        aria-label="breadcrumb"
                    >
                        <ol class="breadcrumb breadcrumb-alt">
                            <li class="breadcrumb-item" aria-current="page">
                                <router-link
                                    class="link-fx"
                                    :to="{ name: 'home' }"
                                    >Dashboard</router-link
                                >
                            </li>
                            <li class="breadcrumb-item">Last Activity</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
        <!-- END Hero -->

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 1 || !datas"
                style="height:250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity:0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <!-- Dynamic Table Full -->
            <div class="block block-rounded block-mode-loading-oneui" v-else>
                <div class="block-content block-content-full pb-0 pt-3">
                    <div class="row">
                        <div
                            class="col-12 col-md-7 col-lg-7 col-xl-7 mb-2 mb-sm-0 pb-0 px-0 d-md-none d-inline"
                        >
                            <label class="col-12 col-md-1 py-0">Date :</label>
                            <date-picker
                                v-model="date_start"
                                type="date"
                                format="DD/MM/YYYY"
                                valueType="YYYY-MM-DD"
                                placeholder="Datepicker"
                                class="col-12"
                            ></date-picker>
                            <date-picker
                                v-model="date_end"
                                type="date"
                                format="DD/MM/YYYY"
                                valueType="YYYY-MM-DD"
                                placeholder="Datepicker"
                                class="col-12"
                            ></date-picker>
                            <button
                                type="button"
                                class="btn btn-sm btn-info float-right mb-5 mb-md-0"
                                tag="button"
                                v-ripple="{ center: true }"
                                style="margin-top: 2px;"
                                @click="getData()"
                                :disabled="loadingPage == 2"
                            >
                                <i class="fa fa-calendar"></i> Apply Date
                            </button>
                        </div>
                        <div
                            class="col-12 col-md-7 col-lg-7 col-xl-7 mb-2 mb-sm-0 pb-0 d-md-inline d-none"
                        >
                            <label>Date :</label>
                            <date-picker
                                v-model="date_start"
                                type="date"
                                format="DD/MM/YYYY"
                                valueType="YYYY-MM-DD"
                                placeholder="Datepicker"
                            ></date-picker>
                            <date-picker
                                v-model="date_end"
                                type="date"
                                format="DD/MM/YYYY"
                                valueType="YYYY-MM-DD"
                                placeholder="Datepicker"
                            ></date-picker>
                            <button
                                type="button"
                                class="btn btn-sm btn-info ml-1 mb-5 mb-md-1"
                                tag="button"
                                v-ripple="{ center: true }"
                                @click="getData()"
                                :disabled="loadingPage == 2"
                            >
                                <i class="fa fa-calendar mr-1"></i> Apply Date
                            </button>
                        </div>
                        <div
                            class="col-12 col-md-5 col-lg-5 col-xl-5 pb-0 pt-0 pt-md-3"
                        >
                            <input
                                type="text"
                                class="form-control form-control col-12 col-md-8 float-right search-index"
                                name="example-text-input"
                                placeholder="Search.."
                                v-model="search"
                            />
                        </div>
                    </div>
                </div>
                <div class="block-content block-content-full py-2">
                    <v-app>
                        <v-data-table
                            :headers="headers"
                            :items="datas"
                            :search="search"
                            :hide-default-footer="true"
                            :items-per-page="50"
                            :mobile-breakpoint="0"
                            style="background-color:white; cursor:default;"
                        >
                            <template
                                v-slot:top="{
                                    pagination,
                                    options,
                                    updateOptions
                                }"
                            >
                                <div class="row">
                                    <div
                                        class="col-12 col-md-7 mb-2 mb-sm-0 pt-0 pb-2"
                                    >
                                        <v-layout justify-start>
                                            <v-data-footer
                                                :pagination="pagination"
                                                :options="options"
                                                @update:options="updateOptions"
                                                :items-per-page-options="[
                                                    10,
                                                    30,
                                                    50,
                                                    100,
                                                    -1
                                                ]"
                                                items-per-page-text="Rows :"
                                            />
                                        </v-layout>
                                    </div>
                                    <div
                                        class="col-12 col-md-5 col-lg-5 col-xl-5 pb-0 pt-2"
                                    >
                                        <button
                                            type="button"
                                            class="btn btn-sm btn-alt-secondary float-right m-1 mb-5 mb-md-0"
                                            tag="button"
                                            v-ripple="{ center: true }"
                                            style="vertical-align: middle;"
                                            @click="getData()"
                                            :disabled="loadingPage == 2"
                                        >
                                            <i class="fa fa-sync"></i>
                                        </button>
                                    </div>
                                </div>
                            </template>
                            <template v-slot:body="{ items }">
                                <tbody v-if="loadingPage == 2">
                                    <tr class="data-empty">
                                        <td colspan="5" class="py-2">
                                            <grid-loader
                                                class="mx-auto mt-5 pt-5"
                                                :color="'#70B9EB'"
                                                :size="'10px'"
                                            ></grid-loader>
                                            <center class="mt-2 mb-5 pb-5">
                                                <span
                                                    class="text-center"
                                                    style="opacity:0.7"
                                                    >Loading</span
                                                >
                                            </center>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody
                                    v-if="items.length > 0 && loadingPage == 0"
                                    style="background-color:white; cursor:default;"
                                >
                                    <tr
                                        v-for="(activity, index) in items"
                                        :key="index"
                                        style="background-color:white; cursor:default;"
                                    >
                                        <td
                                            class="text-center"
                                            style="background-color:white; cursor:default;"
                                        >
                                            {{ ++index }}
                                        </td>
                                        <td
                                            class="text-center"
                                            style="background-color:white; cursor:default;"
                                        >
                                            {{ activity.activityDate }}
                                        </td>
                                        <td
                                            class="text-center"
                                            style="background-color:white; cursor:default;"
                                        >
                                            <span
                                                style="text-transform:capitalize"
                                            >
                                                <i
                                                    class="far fa-fw fa-file-alt text-info"
                                                    v-if="
                                                        activity.activityEvent ==
                                                            'created'
                                                    "
                                                ></i>
                                                <i
                                                    class="far fa-fw fa-edit text-success"
                                                    v-if="
                                                        activity.activityEvent ==
                                                            'updated'
                                                    "
                                                ></i>
                                                <i
                                                    class="far fa-fw fa-trash-alt text-danger"
                                                    v-if="
                                                        activity.activityEvent ==
                                                            'deleted'
                                                    "
                                                ></i>
                                                {{ activity.activityEvent }}
                                            </span>
                                        </td>
                                        <td
                                            class="text-left"
                                            style="background-color:white; cursor:default;"
                                        >
                                            <span
                                                v-html="activity.activityDetail"
                                            ></span>
                                        </td>
                                        <!-- <td width="35%" style="background-color:white; cursor:default;word-break: break-word;font-size:10px;">
                                            {{activity.activityData}}
                                        </td> -->
                                        <!-- DROPDOWN AURORA -->
                                        <!-- <td align="right">
                                            <div class="dropdown dropleft push m-0">
                                                <button type="button" class="btn btn-sm btn-alt-secondary btn-transparent btn-rounded text--lighten-4" id="dropdown-dropleft" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i class="fa fa-fw fa-ellipsis-v"></i>
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-rounded  px-1" aria-labelledby="dropdown-dropleft">
                                                    <button
                                                        class="btn btn-sm btn-transparent btn-alt-danger text-danger dropdown-item-horizontal"
                                                        v-ripple="{ center: true }"
                                                        @click.prevent="deleteData(activity.activityId)"
                                                    >
                                                        <i class="si si-trash mr-1"></i>
                                                        <span class="d-none d-md-inline">Delete</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </td> -->
                                    </tr>
                                </tbody>
                                <tbody
                                    v-if="items.length <= 0 && loadingPage == 0"
                                >
                                    <tr class="data-empty">
                                        <td
                                            class="text-center"
                                            colspan="7"
                                            style="opacity:0.8; padding:2.5rem;"
                                        >
                                            <center>
                                                <img
                                                    :src="
                                                        '/media/proxima/empty-data.svg'
                                                    "
                                                    style="height: 100px;"
                                                />
                                            </center>
                                            <br /><span style="opacity:0.6;"
                                                >Data Not Found</span
                                            >
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-data-table>
                    </v-app>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import moment from "moment";

export default {
    name: "activity-register",
    data() {
        return {
            search: "",
            awaitingSearch: false,
            rows: "All",
            loadingPage: 1,
            date_start: moment()
                .startOf("month")
                .format("YYYY-MM-DD"),
            date_end: moment().format("YYYY-MM-DD"),
            amountQuo: 0,
            amountOrder: 0,
            amountOpen: 0,
            dueOpen: 0,
            amountClosed: 0,
            headers: [
                { text: "No.", align: "center", sortable: false },
                { text: "Date", align: "center", value: "activityDate" },
                { text: "Activity", align: "center", value: "activityEvent" },
                { text: "Detail", align: "center", value: "activityDetail" }
                // { text: 'Data', align:'center', value:'activityData', sortable: false},
            ],
            datas: []
        };
    },
    created() {
        this.getData();
    },
    computed: {
        ...mapState("activity", { allActivities: state => state.allActivities })
    },
    methods: {
        //VUEX
        ...mapActions("activity", ["getAllActivities"]),
        ...mapMutations("activity", ["CLEAR_DATA_ALL"]),
        //METHOD
        typeChange(val) {
            this.loadingPage = 2;
            this.type = val;
            this.getData();
        },
        getData() {
            this.loadingPage = 2;
            this.datas = [];
            this.getAllActivities({
                date_start: new Date(this.date_start).toDateString(),
                date_end: new Date(this.date_end).toDateString()
            })
                .then(() => {
                    this.allActivities.data.forEach(element => {
                        let values = "";
                        // if(element.event == 'deleted'){
                        //     values = JSON.parse(element.old_values)
                        // }else{
                        //     values = JSON.parse(element.new_values)
                        // }
                        this.datas.push({
                            activityId: element.id,
                            activityEvent: element.event,
                            activityDate: moment(element.created_at).format(
                                "DD/MM/YYYY HH:MM"
                            ),
                            activityDetail: element.tags
                            // activityData : values
                        });
                    });
                    this.loadingPage = 0;
                })
                .catch(error => {
                    error.response.status == 500
                        ? this.alert(error.response.data.message, 2)
                        : "";
                    error.response.status != 422 && error.response.status != 500
                        ? this.alert(error.message, 2)
                        : "";
                    this.loadingPage = 0;
                });
        }
    },
    destroyed() {
        //FORM DI BERSIHKAN
        this.CLEAR_DATA_ALL();
    }
};
</script>
