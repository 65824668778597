<template>
    <div
        id="page-container"
        v-bind:class="{
            'sidebar-o side-scroll page-header-fixed sidebar-dark enable-page-overlay wrapper': isAuth
        }"
    >
        <app-sideoverlay v-if="isAuth" />
        <app-sidemenu v-if="isAuth" />

        <app-header v-if="isAuth" />

        <!-- Main Container -->
        <main id="main-container">
            <transition
                name="fade"
                mode="out-in"
                @beforeLeave="beforeLeave"
                @enter="enter"
                @afterEnter="afterEnter"
            >
                <router-view></router-view>
            </transition>
        </main>
        <!-- END Main Container -->

        <app-footer v-if="isAuth" />
    </div>
</template>

<script>
import Header from "./Header.vue";
import Sidebar from "./Sidebar.vue";
import SideOverlay from "./SideOverlay.vue";
import Footer from "./Footer.vue";
import { mapState, mapGetters } from "vuex";

export default {
    data() {
        return {
            prevHeight: 0
        };
    },
    computed: {
        ...mapState(["_tk__"]),
        ...mapGetters(["isAuth"])
    },
    components: {
        "app-header": Header,
        "app-sidemenu": Sidebar,
        "app-sideoverlay": SideOverlay,
        "app-footer": Footer
    },
    methods: {
        beforeLeave(element) {
            this.prevHeight = getComputedStyle(element).height;
        },
        enter(element) {
            const { height } = getComputedStyle(element);

            element.style.height = this.prevHeight;

            setTimeout(() => {
                element.style.height = height;
            });
        },
        afterEnter(element) {
            element.style.height = "auto";
        }
    }
};
</script>
