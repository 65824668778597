<template>
    <main>
        <hero title="Edit Asset" prevBreadcrumb="Asset Data" routeBreadcrumb="asset" currentBreadcrumb="Edit Asset"></hero>

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <div class="block" v-if="loadingPage == 1" style="height: 250px">
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <div class="block" v-if="loadingPage == 2" style="height: 250px">
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Save Data</span
                        >
                    </center>
                </div>
            </div>
            <!-- Basic -->
            <div class="block" v-if="loadingPage == 0">
                <div class="block mb-0" v-if="forbiddenData">
                    <div
                        class="alert alert-info d-flex align-items-center mb-0"
                        role="alert"
                    >
                        <div class="flex-00-auto">
                            <i class="fa fa-fw fa-info-circle"></i>
                        </div>
                        <div class="flex-fill ml-3">
                            <p class="mb-0">
                                This asset data is
                                <b> included into closing date of year </b>, you
                                can't edit or delete this data
                            </p>
                        </div>
                    </div>
                </div>
                <div class="block-content block-content-full">
                    <form
                        method="POST"
                        enctype="multipart/form-data"
                        onsubmit="return false;"
                    >
                        <!--Form-->
                        <div class="row font-size-sm">
                            <div class="col-12 py-0">
                                <div class="row">
                                    <div class="col-12 col-lg-6 pt-0">
                                        <label style="opacity: 0.7"
                                            >Asset Data</label
                                        >
                                        <hr class="mt-2 mb-3" />
                                        <div class="form-group">
                                            <label for="example-text-input"
                                                >Asset Category *</label
                                            >
                                            <selectize
                                                class="form-control"
                                                placeholder="Choose Category"
                                                v-model="asset.category_id"
                                                :class="{
                                                    'is-invalid-selectize':
                                                        errors.category_id,
                                                }"
                                            >
                                                <!-- settings is optional -->
                                                <option
                                                    v-for="category in categories"
                                                    :value="category.id"
                                                    :key="category.id"
                                                >
                                                    {{ category.category_name }}
                                                </option>
                                            </selectize>
                                            <transition name="slide-fade">
                                                <p
                                                    class="text-danger"
                                                    v-if="errors.category_id"
                                                >
                                                    {{ errors.category_id[0] }}
                                                </p>
                                            </transition>
                                        </div>
                                        <div class="form-group">
                                            <label for="example-text-input"
                                                >Asset Name *</label
                                            >
                                            <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Asset Name"
                                                v-model="asset.asset_name"
                                                :class="{
                                                    'is-invalid':
                                                        errors.asset_name,
                                                }"
                                            />
                                            <transition name="slide-fade">
                                                <p
                                                    class="text-danger"
                                                    v-if="errors.asset_name"
                                                >
                                                    {{ errors.asset_name[0] }}
                                                </p>
                                            </transition>
                                        </div>
                                        <transition name="slide-fade">
                                            <div class="form-group">
                                                <label for="example-text-input"
                                                    >Asset Code *</label
                                                >
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="Asset Code"
                                                    v-model="asset.asset_code"
                                                    :class="{
                                                        'is-invalid':
                                                            errors.asset_code,
                                                    }"
                                                />
                                                <transition name="slide-fade">
                                                    <p
                                                        class="text-danger"
                                                        v-if="errors.asset_code"
                                                    >
                                                        {{
                                                            errors.asset_code[0]
                                                        }}
                                                    </p>
                                                </transition>
                                            </div>
                                        </transition>
                                    </div>
                                    <div class="col-12 col-lg-6 pt-0">
                                        <label style="opacity: 0.7"
                                            >Acquisition Detail</label
                                        >
                                        <hr class="mt-2 mb-3" />

                                        <div class="row">
                                            <div
                                                class="form-group col-12 pb-1 pt-0"
                                            >
                                                <label for="example-text-input"
                                                    >Acquisition Date *</label
                                                >
                                                <date-picker
                                                    class="input-group"
                                                    v-model="
                                                        asset.asset_acquisition_date
                                                    "
                                                    format="DD/MM/YYYY"
                                                    valueType="YYYY-MM-DD"
                                                    placeholder="Acquisition Date"
                                                    :class="{
                                                        'is-invalid':
                                                            errors.asset_acquisition_date,
                                                    }"
                                                    :default-value="new Date()"
                                                    :disabled-date="
                                                        disabledDatePicker
                                                    "
                                                ></date-picker>
                                                <transition name="slide-fade">
                                                    <p
                                                        class="text-danger"
                                                        v-if="
                                                            errors.asset_acquisition_date
                                                        "
                                                    >
                                                        {{
                                                            errors
                                                                .asset_acquisition_date[0]
                                                        }}
                                                    </p>
                                                </transition>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div
                                                class="form-group col-6 pb-0 pt-0"
                                            >
                                                <label for="example-text-input"
                                                    >Acquired Value *</label
                                                >
                                                <money
                                                    class="form-control"
                                                    style="text-align: right"
                                                    spellcheck="false"
                                                    v-model="
                                                        asset.asset_acquisition_value
                                                    "
                                                    :class="{
                                                        'is-invalid':
                                                            errors.asset_acquisition_value,
                                                    }"
                                                ></money>
                                                <transition name="slide-fade">
                                                    <p
                                                        class="text-danger"
                                                        v-if="
                                                            this.asset.asset_acquisition_value < 0                                                        "
                                                    >
                                                        Residual Value cannot be negative
                                                    </p>
                                                </transition>
                                                <transition name="slide-fade">
                                                    <p
                                                        class="text-danger"
                                                        v-if="
                                                            errors.asset_acquisition_value
                                                        "
                                                    >
                                                        {{
                                                            errors
                                                                .asset_acquisition_value[0]
                                                        }}
                                                    </p>
                                                </transition>
                                            </div>
                                            <div
                                                class="form-group col-6 pb-0 pt-0"
                                            >
                                                <label for="example-text-input"
                                                    >Residual Value *</label
                                                >
                                                <money
                                                    class="form-control"
                                                    style="text-align: right"
                                                    spellcheck="false"
                                                    v-model="
                                                        asset.asset_residual_value
                                                    "
                                                    :class="{
                                                        'is-invalid':
                                                            errors.asset_residual_value,
                                                    }"
                                                ></money>
                                                <transition name="slide-fade">
                                                    <p
                                                        class="text-danger"
                                                        v-if="
                                                            this.asset.asset_acquisition_value > 0 && this.asset.asset_residual_value > this.asset.asset_acquisition_value
                                                        "
                                                    >
                                                        Residual Value cannot be greater than Acquisition Value
                                                    </p>
                                                </transition>
                                                <transition name="slide-fade">
                                                    <p
                                                        class="text-danger"
                                                        v-if="
                                                            this.asset.asset_residual_value < 0                                                        "
                                                    >
                                                        Residual Value cannot be negative
                                                    </p>
                                                </transition>
                                                <transition name="slide-fade">
                                                    <p
                                                        class="text-danger"
                                                        v-if="
                                                            errors.asset_residual_value
                                                        "
                                                    >
                                                        {{
                                                            errors
                                                                .asset_residual_value[0]
                                                        }}
                                                    </p>
                                                </transition>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div
                                                class="form-group col-12 pb-1 pt-0"
                                            >
                                                <label for="example-text-input"
                                                    >Asset Status *</label
                                                >
                                                <selectize
                                                    class="form-control"
                                                    placeholder="Membership Plan"
                                                    v-model="asset.asset_status"
                                                    :class="{
                                                        'is-invalid-selectize':
                                                            errors.asset_status,
                                                    }"
                                                >
                                                    <!-- settings is optional -->
                                                    <option value="1">Active</option>
                                                    <option value="0">Inactive</option>
                                                </selectize>
                                                <transition name="slide-fade">
                                                    <p
                                                        class="text-danger"
                                                        v-if="errors.asset_status"
                                                    >
                                                        {{ errors.asset_status[0] }}
                                                    </p>
                                                </transition>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--END Form-->
                        <!-- Submit -->
                        <div class="row items-push">
                            <div class="col-12 text-center mb-0 pb-0">
                                <p class="font-size-sm text-muted mb-0">
                                    * Required filled, Make sure all data is
                                    correctly filled
                                </p>
                            </div>
                            <div class="col-12 text-center">
                                <button
                                    type="button"
                                    v-if="!forbiddenData"
                                    :disabled="disableSave"
                                    class="btn btn-sm btn-success"
                                    v-ripple="{ center: true }"
                                    @click.prevent="submitData"
                                >
                                    <i class="fa fa-fw fa-check mr-1"></i>
                                    Update
                                </button>
                                <button
                                    type="button"
                                    v-if="!forbiddenData"
                                    :disabled="disableSave"
                                    class="btn btn-sm btn-danger"
                                    v-ripple="{ center: true }"
                                    @click.prevent="deleteData"
                                >
                                    <i class="fa fa-fw fa-trash mr-1"></i>
                                    Delete
                                </button>
                                <router-link
                                    type="button"
                                    class="btn btn-sm btn-alt-light"
                                    tag="button"
                                    v-ripple="{ center: true }"
                                    :to="{ name: 'asset' }"
                                >
                                    <i class="fa fa-fw fa-times mr-1"></i> Close
                                </router-link>
                            </div>
                        </div>
                        <!-- END Submit -->
                        <!-- <span class="opacity-25">Created by {{asset.created_by}} at {{asset.created_at | moment("HH:mm DD/MM/YYYY")}}</span> -->
                    </form>
                </div>
            </div>
            <!-- END Basic -->
        </div>
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import moment from "moment";

export default {
    name: "edit-bills",
    data() {
        return {
            loadingPage: 1,
            items: [],
            disabled: {},
            forbiddenData: false,
        };
    },
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState("asset", { asset: (state) => state.asset }),
        ...mapState("assetcategory", {
            categories: (state) => state.categories,
        }),
        ...mapState("forbiddendate", {
            forbiddenDateLast: (state) => state.forbiddenDateLast,
        }),
        disableSave() {
            if (this.asset.asset_name === "") {
                return true;
            } else if (this.asset.asset_category_id === "") {
                return true;
            } else if (this.asset.accet_code === "") {
                return true;
            } else if (this.asset.asset_acquisition_date === "") {
                return true;
            } else if (this.asset.asset_acquisition_date == null) {
                return true;
            } else if (this.asset.asset_acquisition_value == 0) {
                return true;
            } else if (this.asset.asset_residual_value > this.asset.asset_acquisition_value) {
                return true;
            } else if (this.asset.asset_residual_value < 0) {
                return true;
            } else if (this.asset.asset_acquisition_value < 0) {
                return true;
            } else {
                return false;
            }
        },
    },
    created() {
        this.getCategoryAll();
        this.getForbiddenDate();
        if (this.$route.params.id) {
            this.editAsset(this.$route.params.id)
                .then((response) => {
                    // this.getContactDetail(response.data.bill_contact_id);
                    this.loadingPage = 0;
                })
                .catch((error) => {
                    console.log(error);
                    this.loadingPage = 0;
                });
        } else {
            this.$router.push({ name: "asset" });
            this.alert("Data not found, select data first", 2);
        }
    },
    methods: {
        //VUEX
        ...mapMutations("asset", ["CLEAR_FORM"]),
        ...mapActions("assetcategory", ["getCategoryAll"]),
        ...mapMutations(["CLEAR_ERRORS"]),
        ...mapActions("asset", [
            "editAsset",
            "getAssetNo",
            "updateAsset",
            "removeAsset",
            "printAsset",
        ]),
        ...mapActions("forbiddendate", ["getForbiddenDate"]),

        //METHOD
        disabledDatePicker(date) {
            const lastDisableDate = new Date(
                moment(this.forbiddenDateLast).add(1, "days")
            );
            lastDisableDate.setHours(0, 0, 0, 0);
            return date < lastDisableDate;
        },
        changeDate() {
            this.getAssetNo();
            // this.getJournalMemo();
        },
        //GET DATA TAX RATE
        getTaxDetail(index, val) {
            var result = this.taxs.find((obj) => {
                return obj.id === parseInt(val);
            });
            this.asset.bill_transaction[index].transaction_tax_rate =
                result.tax_rate;
            //RE CALCULATE BILLS
            this.calculate();
        },
        //GET JOURNAL MEMO
        getJournalMemo() {
            if (this.asset.journal == null) {
                if (
                    this.asset.bill_contact_id == "" ||
                    this.asset.bill_contact_id == 0
                ) {
                    let newJournalMemo = "Asset; ";
                    this.asset.bill_journal_memo = newJournalMemo;
                } else {
                    var result = this.contacts.find((obj) => {
                        return obj.id === parseInt(this.asset.bill_contact_id);
                    });
                    let newJournalMemo = "Asset; " + result.contact_name;
                    this.asset.bill_journal_memo = newJournalMemo;
                }
            } else {
                this.asset.bill_journal_memo = this.asset.journal.journal_description;
            }
        },
        addDetail() {
            this.asset.bill_transaction.push({
                id: 0,
                transaction_tax_id: "",
                transaction_tax_rate: 0,
                transaction_coa_id: "",
                transaction_description: "",
                transaction_amount: 0,
            });
        },
        removeDetail(index, transaction_id) {
            this.asset.bill_transaction.splice(index, 1);
            this.asset.bill_transaction_delete.push(transaction_id);
        },
        calculate() {
            var sub = 0;
            var tax = 0;
            this.asset.bill_transaction.forEach((transaction) => {
                sub += transaction.transaction_amount;
            });

            this.asset.bill_transaction.forEach((transaction) => {
                if (isNaN(transaction.transaction_tax_rate)) {
                } else {
                    tax +=
                        transaction.transaction_amount *
                        (transaction.transaction_tax_rate / 100);
                }
            });

            this.asset.bill_sub_total = sub.toFixed(0);
            this.asset.bill_tax_total = tax.toFixed(0);
            this.asset.bill_grand_total = (sub + tax).toFixed(0);
        },
        submitData() {
            this.loadingPage = 2;
            this.CLEAR_ERRORS();
            this.asset.bill_status = 3;
            this.updateAsset(this.$route.params.id)
                .then((response) => {
                    this.CLEAR_FORM();
                    this.alert("Successfully create Asset Data ", 1);
                    this.loadingPage = 0;
                    this.$router.push({ name: "asset" });
                })
                .catch((error) => {
                    error.response.status == 500
                        ? this.alert(error.response.data.message, 2)
                        : "";
                    error.response.status != 422 && error.response.status != 500
                        ? this.alert(error.message, 2)
                        : "";
                    this.loadingPage = 0;
                });
        },
        deleteData() {
            this.$swal({
                title: "Are you sure ?",
                text: "Deleted data cannot be recovery",
                icon: "warning",
                buttons: ["Cancel", "Delete"],
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    this.loadingPage = 1;
                    this.removeAsset(this.$route.params.id)
                        .then(() => {
                            this.alert("Data has been deleted !", 1);
                            this.loadingPage = 0;
                            this.$router.push({ name: "asset" });
                        })
                        .catch((error) => {
                            error.response.status == 500
                                ? this.alert(error.response.data.message, 2)
                                : "";
                            error.response.status != 422 &&
                            error.response.status != 500
                                ? this.alert(error.message, 2)
                                : "";
                            this.loadingPage = 0;
                        });
                }
            });
        },
    },
    destroyed() {
        this.CLEAR_FORM();
    },
};
</script>
