<template>
<span>
        <div
        class="block mb-0"
        v-if="bill.forbidden == true"
    >
        <div
            class="alert alert-info d-flex align-items-center mb-0"
            role="alert"
        >
            <div class="flex-00-auto">
                <i class="fa fa-fw fa-info-circle"></i>
            </div>
            <div class="flex-fill ml-3">
                <p class="mb-0">
                    This invoice is
                    <b> included into closing date of year </b>, you
                    can't edit or delete this data
                </p>
            </div>
        </div>
    </div>
    <div
        class="block mb-0"
        v-if="(bill.status == 3 || bill.status == 4) && (bill.forbidden == true || bill.bill_paid_total != 0)"
    >
        <div
            class="alert alert-info d-flex align-items-center mb-0"
            role="alert"
        >
            <div class="flex-00-auto">
                <i class="fa fa-fw fa-info-circle"></i>
            </div>
            <div class="flex-fill ml-3">
                <p class="mb-0">
                    This invoice on <b> process of payment </b> or
                    <b> included into closing date of year </b>, you
                    can't edit or delete this data
                </p>
            </div>
        </div>
    </div>
    <div
        class="block mb-0"
        v-if="bill.reference_invoice.length > 0"
    >
        <div
            class="alert alert-info d-flex align-items-center mb-0"
            role="alert"
        >
            <div class="flex-00-auto">
                <i class="fa fa-fw fa-info-circle"></i>
            </div>
            <div class="flex-fill ml-3">
                <p class="mb-0">
                    This order on <b> process of invoicing </b>, you
                    can't edit or delete this data
                </p>
            </div>
        </div>
    </div>
</span>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

export default {
    name: "notification-component",
    computed: {
        ...mapState("bill", { bill: state => state.bill }),
    },
}

</script>