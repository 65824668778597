<template>
    <main>
        <hero title="Edit Contact" prevBreadcrumb="Contact Data" routeBreadcrumb="contact" currentBreadcrumb="Edit Contact"></hero>

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 1"
                style="height:250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 2"
                style="height:250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Save Data</span
                        >
                    </center>
                </div>
            </div>
            <!-- Basic -->
            <div
                class="block col-12 col-md-8 offset-md-2 block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 0"
            >
                <div class="block-content block-content-full">
                    <form
                        method="POST"
                        enctype="multipart/form-data"
                        onsubmit="return false;"
                    >
                        <!--Form-->
                        <div class="row font-size-sm">
                            <div class="col-12 col-md-10 offset-md-1">
                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Contact Type *</label
                                    >
                                    <selectize
                                        class="form-control"
                                        placeholder="Choose Membership"
                                        v-model="contact.contact_type"
                                        :class="{
                                            'is-invalid-selectize':
                                                errors.contact_type,
                                        }"
                                    >
                                        <!-- settings is optional -->
                                        <option value="1">Customer</option>
                                        <option value="2">Supplier</option>
                                    </selectize>
                                    <p
                                        v-if="errors.contact_type"
                                        class="text-danger"
                                    >
                                        {{ errors.contact_type[0] }}
                                    </p>
                                </div>
                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Contact Name *</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Contact Name"
                                        v-model="contact.contact_name"
                                        :class="{
                                            'is-invalid': errors.contact_name,
                                        }"
                                    />
                                    <transition name="slide-fade">
                                        <p
                                            class="text-danger"
                                            v-if="errors.contact_name"
                                        >
                                            {{ errors.contact_name[0] }}
                                        </p>
                                    </transition>
                                </div>
                                <div class="form-group mb-2">
                                    <label for="example-text-input"
                                        >Address *</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Address"
                                        v-model="contact.contact_address"
                                        :class="{
                                            'is-invalid':
                                                errors.contact_address,
                                        }"
                                    />
                                    <transition name="slide-fade">
                                        <p
                                            class="text-danger"
                                            v-if="errors.contact_address"
                                        >
                                            {{ errors.contact_address[0] }}
                                        </p>
                                    </transition>
                                </div>
                                <div class="row">
                                    <div
                                        class="form-group col-12 col-md-6 mb-0 mb-md-3"
                                    >
                                        <label for="example-text-input"
                                            >Province</label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Province"
                                            v-model="contact.contact_province"
                                            :class="{
                                                'is-invalid':
                                                    errors.contact_province,
                                            }"
                                        />
                                        <transition name="slide-fade">
                                            <p
                                                class="text-danger"
                                                v-if="errors.contact_province"
                                            >
                                                {{ errors.contact_province[0] }}
                                            </p>
                                        </transition>
                                    </div>
                                    <div
                                        class="form-group col-12 col-md-6 mb-3"
                                    >
                                        <label for="example-text-input"
                                            >Postal Code</label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Postal Code"
                                            v-model="contact.contact_postalcode"
                                            :class="{
                                                'is-invalid':
                                                    errors.contact_postalcode,
                                            }"
                                            @keypress="isNumber($event)"
                                        />
                                        <transition name="slide-fade">
                                            <p
                                                class="text-danger"
                                                v-if="errors.contact_postalcode"
                                            >
                                                {{
                                                    errors.contact_postalcode[0]
                                                }}
                                            </p>
                                        </transition>
                                    </div>
                                </div>
                                <div class="form-group mb-3">
                                    <label for="example-text-input"
                                        >Email</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Email"
                                        v-model="contact.contact_email"
                                        :class="{
                                            'is-invalid': errors.contact_email,
                                        }"
                                    />
                                    <p
                                        class="text-danger"
                                        v-if="errors.contact_email"
                                    >
                                        {{ errors.contact_email[0] }}
                                    </p>
                                </div>
                                <div class="row">
                                    <div
                                        class="form-group col-12 col-md-6 mb-1"
                                    >
                                        <label for="example-text-input"
                                            >Contact Name</label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Contact Name"
                                            v-model="
                                                contact.contact_contactname
                                            "
                                            :class="{
                                                'is-invalid':
                                                    errors.contact_contactname,
                                            }"
                                        />
                                        <p
                                            class="text-danger"
                                            v-if="errors.contact_contactname"
                                        >
                                            {{ errors.contact_contactname[0] }}
                                        </p>
                                    </div>
                                    <div
                                        class="form-group col-12 col-md-6 mb-1"
                                    >
                                        <label for="example-text-input"
                                            >Contact Phone</label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Contact Phone"
                                            v-model="
                                                contact.contact_contactphone
                                            "
                                            :class="{
                                                'is-invalid':
                                                    errors.contact_contactphone,
                                            }"
                                            @keypress="isNumber($event)"
                                        />
                                        <p
                                            class="text-danger"
                                            v-if="errors.contact_contactphone"
                                        >
                                            {{ errors.contact_contactphone[0] }}
                                        </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div
                                        class="form-group col-12 col-md-6 mb-0 mb-md-3"
                                    >
                                        <label for="example-text-input"
                                            >Contact Name 2</label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Contact Name 2"
                                            v-model="
                                                contact.contact_contactname2
                                            "
                                            :class="{
                                                'is-invalid':
                                                    errors.contact_contactname2,
                                            }"
                                        />
                                        <p
                                            class="text-danger"
                                            v-if="errors.contact_contactname2"
                                        >
                                            {{ errors.contact_contactname2[0] }}
                                        </p>
                                    </div>
                                    <div class="form-group col-12 col-md-6">
                                        <label for="example-text-input"
                                            >Contact Phone 2</label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Contact Phone 2"
                                            v-model="
                                                contact.contact_contactphone2
                                            "
                                            :class="{
                                                'is-invalid':
                                                    errors.contact_contactphone2,
                                            }"
                                            @keypress="isNumber($event)"
                                        />
                                        <p
                                            class="text-danger"
                                            v-if="errors.contact_contactphone2"
                                        >
                                            {{
                                                errors.contact_contactphone2[0]
                                            }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--END Form-->
                        <!-- Submit -->
                        <div class="row items-push">
                            <div class="col-12 text-center">
                                <p class="font-size-sm text-muted">
                                    * Require to be filled, Make sure all data is
                                    correctly filled
                                </p>
                                <button
                                    type="submit"
                                    class="btn btn-sm btn-success"
                                    v-ripple="{ center: true }"
                                    @click.prevent="submitData"
                                >
                                    <i class="fa fa-fw fa-check mr-1"></i>
                                    Update
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-sm btn-danger"
                                    v-ripple="{ center: true }"
                                    @click.prevent="deleteData"
                                >
                                    <i class="fa fa-fw fa-trash mr-1"></i>
                                    Delete
                                </button>
                                <router-link
                                    type="button"
                                    class="btn btn-sm btn-alt-light"
                                    tag="button"
                                    v-ripple="{ center: true }"
                                    :to="{ name: 'contact' }"
                                >
                                    <i class="fa fa-fw fa-times mr-1"></i> Close
                                </router-link>
                            </div>
                        </div>
                        <!-- END Submit -->
                    </form>
                </div>
            </div>
            <!-- END Basic -->
        </div>
        <!-- END Page Content -->
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

export default {
    name: "edit-contact",
    data() {
        return {
            loadingPage: 1,
        };
    },
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState("contact", {
            contact: (state) => state.contact,
        }),
    },
    mounted() {
        if (this.$route.params.id) {
            this.editContact(this.$route.params.id)
                .then((response) => {
                    this.loadingPage = 0;
                })
                .catch((error) => {
                    error.response.status != 422
                        ? this.alert(error.message, 2)
                        : "";
                    this.loadingPage = 0;
                });
        } else {
            this.$router.push({ name: "contact" });
            this.alert("Data not found, select data first", 2);
        }
    },
    methods: {
        //VUEX
        ...mapMutations(["CLEAR_ERRORS"]),
        ...mapMutations("contact", ["CLEAR_FORM"]),
        ...mapActions("contact", [
            "editContact",
            "updateContact",
            "removeContact",
        ]),

        isNumber: function (evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46
            ) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        submitData() {
            this.loadingPage = 2;
            this.CLEAR_ERRORS();
            this.updateContact(this.$route.params.id)
                .then(() => {
                    this.$router.push({ name: "contact" });
                    this.alert("Successfully Edit Contact Data", 1);
                    this.loadingPage = 0;
                })
                .catch((error) => {
                    error.response.status != 422
                        ? this.alert(error.message, 2)
                        : "";
                    this.loadingPage = 0;
                });
        },
        deleteData() {
            //AKAN MENAMPILKAN JENDELA KONFIRMASI
            this.$swal({
                title: "Are you sure ?",
                text: "Deleted data cannot be recovery",
                icon: "warning",
                buttons: ["Cancel", "Delete"],
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    this.removeContact(this.$route.params.id)
                        .then(() => {
                            this.$router.push({ name: "contact" });
                            this.alert("Data has been deleted !", 1);
                            this.loadingPage = 0;
                        })
                        .catch((error) => {
                            error.response.status == 500
                                ? this.alert(error.response.data.message, 2)
                                : "";
                            error.response.status != 422 &&
                            error.response.status != 500
                                ? this.alert(error.message, 2)
                                : "";
                            this.loadingPage = 0;
                        });
                }
            });
        },
    },
    destroyed() {
        //FORM DI BERSIHKAN
        this.CLEAR_FORM();
        this.CLEAR_ERRORS();
    },
};
</script>
