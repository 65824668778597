<template>
    <!-- Page Content -->
    <div class="content p-0">
        <!--Loading-->
        <div class="block mb-0" v-if="loadingPage == 1" style="height:200px">
            <div
                class="block-content block-content-full pb-0"
                style="padding-top: 80px"
            >
                <grid-loader
                    class="mx-auto"
                    :color="'#70B9EB'"
                    :size="'10px'"
                ></grid-loader>
                <center class="mt-2">
                    <span class="text-center" style="opacity:0.7">Loading</span>
                </center>
            </div>
        </div>
        <div class="block mb-0" v-if="loadingPage == 2" style="height:200px">
            <div
                class="block-content block-content-full pb-0"
                style="padding-top: 80px"
            >
                <div
                    class="spinner-border spinner-border-lg text-info mx-auto"
                    role="status"
                    style="display: flex;
                    justify-content: center;"
                ></div>
                <center class="mt-2">
                    <span class="text-center" style="opacity:0.7"
                        >Save Data</span
                    >
                </center>
            </div>
        </div>
        <!-- Basic -->
        <div class="block mb-0" v-if="loadingPage == 0">
            <form
                method="POST"
                enctype="multipart/form-data"
                onsubmit="return false;"
            >
                <div class="block-header py-3 bg-gray">
                    <div class="row">
                        <div class="col-12 py-0">
                            <p class="mb-1 font-weight-bold" v-if="!showReview">
                                ADJUST ITEM
                            </p>
                            <p class="mb-1  font-weight-bold" v-if="showReview">
                                REVIEW ADJUSTMENT
                            </p>
                            <h4 class="block-title">
                                {{ this.item.item_name }}
                            </h4>
                        </div>
                    </div>
                </div>
                <div class="block-content block-content-full px-0">
                    <!-- FORM SECTION ------------------------------>
                    <template v-if="!showReview">
                        <!-- Indicator -->
                        <div class="row px-3">
                            <div class="col-12 py-1">
                                <span class="pill-item-code">Current</span>
                            </div>
                            <div class="col-4 py-0 text-center">
                                <div class="form-group border-r-1 pr-3 mb-1">
                                    <p>
                                        Quantity on Hand
                                        <i
                                            class="si si-info"
                                            data-toggle="popover"
                                            data-placement="top"
                                            title="Quantity on Hand"
                                            data-content="This is example content. You can put a description or more info here."
                                        ></i>
                                    </p>
                                    <p
                                        class="pb-3 font-weight-bolder font-size-h5 mb-2"
                                    >
                                        {{ this.item.item_qty }}
                                    </p>
                                </div>
                            </div>
                            <div class="col-4 py-0 text-center">
                                <div class="form-group border-r-1 pr-3 mb-1">
                                    <p>Average Cost</p>
                                    <p
                                        class="mb-0 font-weight-bolder font-size-h5"
                                    >
                                        {{
                                            (this.item.item_value /
                                                this.item.item_qty)
                                                | currency
                                        }}
                                    </p>
                                </div>
                            </div>
                            <div class="col-4 py-0 text-center">
                                <div class="form-group border-r-1 pr-3 mb-1">
                                    <p>Total Value</p>
                                    <p
                                        class="mb-0 font-weight-bolder font-size-h5"
                                    >
                                        {{ this.item.item_value | currency }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!-- END Indicator -->
                        <hr class="my-0 mx-3" />
                        <!--Form-->
                        <div class="row font-size-sm">
                            <div class="col-12 content pt-2">
                                <div class="row">
                                    <div class="col-6 pt-1">
                                        <div class="form-group mb-0">
                                            <label for="example-text-input"
                                                >Type</label
                                            >
                                            <selectize
                                                class="form-control"
                                                placeholder="Adjustment Type"
                                                v-model="mutation.mutation_type"
                                                @input="setMutationType()"
                                                :class="{
                                                    'is-invalid-selectize':
                                                        errors.mutation_type
                                                }"
                                            >
                                                <!-- settings is optional -->
                                                <option value="1"
                                                    >Increase Quantity</option
                                                >
                                                <option value="2"
                                                    >Decrease Quantity</option
                                                >
                                            </selectize>
                                            <transition name="slide-fade">
                                                <p
                                                    class="text-danger"
                                                    v-if="errors.mutation_type"
                                                >
                                                    {{
                                                        errors.mutation_type[0]
                                                    }}
                                                </p>
                                            </transition>
                                        </div>
                                    </div>
                                    <div class="col-6 pt-1">
                                        <div class="form-group mb-0">
                                            <label for="example-text-input"
                                                >Date</label
                                            >
                                            <date-picker
                                                class="input-group"
                                                v-model="mutation.mutation_date"
                                                format="DD/MM/YYYY"
                                                valueType="YYYY-MM-DD"
                                                placeholder="Bill Date"
                                                :class="{
                                                    'is-invalid':
                                                        errors.mutation_date
                                                }"
                                                :default-value="new Date()"
                                            ></date-picker>
                                            <transition name="slide-fade">
                                                <p
                                                    class="text-danger"
                                                    v-if="errors.mutation_date"
                                                >
                                                    {{
                                                        errors.mutation_date[0]
                                                    }}
                                                </p>
                                            </transition>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="row"
                                    v-if="mutation.mutation_type == '1'"
                                >
                                    <div class="col-4 pt-1">
                                        <div class="form-group mb-0">
                                            <label for="example-text-input"
                                                >Increase by</label
                                            >
                                            <numeric
                                                class="form-control"
                                                placeholder="Increase by"
                                                v-model.number="
                                                    mutation.detail[0].in_qty
                                                "
                                                :class="{
                                                    'is-invalid':
                                                        errors[
                                                            'detail.0.in_qty'
                                                        ]
                                                }"
                                                separator="."
                                                :precision="2"
                                                @input="calculateInQty('inQty')"
                                            />
                                            <transition name="slide-fade">
                                                <span
                                                    v-if="
                                                        errors[
                                                            'detail.0.in_qty'
                                                        ]
                                                    "
                                                    class="text-danger "
                                                >
                                                    {{
                                                        errors[
                                                            "detail.0.in_qty"
                                                        ][0]
                                                    }}
                                                </span>
                                            </transition>
                                        </div>
                                    </div>
                                    <div class="col-4 pt-1">
                                        <div class="form-group mb-0">
                                            <label for="example-text-input"
                                                >New Quantity on Hand</label
                                            >
                                            <numeric
                                                class="form-control"
                                                placeholder="Increase by"
                                                v-model.number="
                                                    mutation.detail[0].in_qoh
                                                "
                                                :class="{
                                                    'is-invalid':
                                                        errors[
                                                            'detail.0.in_qoh'
                                                        ] ||
                                                        (this.mutation.detail[0]
                                                            .in_qoh != 0 &&
                                                            this.mutation
                                                                .detail[0]
                                                                .in_qoh <
                                                                this.item
                                                                    .item_qty)
                                                }"
                                                @input="calculateInQty('inQoh')"
                                                separator="."
                                                :precision="2"
                                            />
                                            <transition name="slide-fade">
                                                <span
                                                    v-if="
                                                        errors[
                                                            'detail.0.in_qoh'
                                                        ]
                                                    "
                                                    class="text-danger"
                                                >
                                                    {{
                                                        errors[
                                                            "detail.0.in_qoh"
                                                        ][0]
                                                    }}
                                                </span>
                                                <span
                                                    v-if="
                                                        this.mutation.detail[0]
                                                            .in_qoh != 0 &&
                                                            this.mutation
                                                                .detail[0]
                                                                .in_qoh <
                                                                this.item
                                                                    .item_qty
                                                    "
                                                    class="text-danger"
                                                >
                                                    New Quantity on Hand cannot
                                                    be less than Current
                                                    Quantity on Hand
                                                </span>
                                            </transition>
                                        </div>
                                    </div>
                                    <div class="col-4 pt-1">
                                        <div class="form-group mb-0">
                                            <label for="example-text-input"
                                                >Cost per Item</label
                                            >
                                            <money
                                                class="form-control"
                                                style="text-align: right;"
                                                v-model="
                                                    mutation.detail[0].in_price
                                                "
                                                spellcheck="false"
                                                :class="{
                                                    'is-invalid':
                                                        errors[
                                                            'detail.0.in_price'
                                                        ]
                                                }"
                                            ></money>
                                            <transition name="slide-fade">
                                                <span
                                                    v-if="
                                                        errors[
                                                            'detail.0.in_price'
                                                        ]
                                                    "
                                                    class="text-danger "
                                                >
                                                    {{
                                                        errors[
                                                            "detail.0.in_price"
                                                        ][0]
                                                    }}
                                                </span>
                                            </transition>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="row"
                                    v-if="mutation.mutation_type == '2'"
                                >
                                    <div class="col-6 pt-1">
                                        <div class="form-group mb-0">
                                            <label for="example-text-input"
                                                >Decrease By</label
                                            >
                                            <numeric
                                                class="form-control"
                                                placeholder="Increase by"
                                                v-model.number="
                                                    mutation.detail[0].out_qty
                                                "
                                                :class="{
                                                    'is-invalid':
                                                        errors[
                                                            'detail.0.out_qty'
                                                        ]
                                                }"
                                                separator="."
                                                :precision="2"
                                                @input="
                                                    calculateOutQty('outQty')
                                                "
                                                v-on:blur="
                                                    getCOGS(
                                                        0,
                                                        mutation.detail[0]
                                                            .item_id
                                                    )
                                                "
                                            />
                                            <transition name="slide-fade">
                                                <span
                                                    v-if="
                                                        errors[
                                                            'detail.0.out_qty'
                                                        ]
                                                    "
                                                    class="text-danger "
                                                >
                                                    {{
                                                        errors[
                                                            "detail.0.out_qty"
                                                        ][0]
                                                    }}
                                                </span>
                                                <span
                                                    v-if="
                                                        this.mutation.detail[0]
                                                            .out_qty >
                                                            this.item.item_qty
                                                    "
                                                    class="text-danger"
                                                >
                                                    Decrease By cannot be more
                                                    than Current Quantity on
                                                    Hand
                                                </span>
                                            </transition>
                                        </div>
                                    </div>
                                    <div class="col-6 pt-1">
                                        <div class="form-group mb-0">
                                            <label for="example-text-input"
                                                >New Quantity on Hand</label
                                            >
                                            <input
                                                type="text"
                                                class="form-control"
                                                placeholder="New Quantity"
                                                v-model.number="
                                                    mutation.detail[0].out_qoh
                                                "
                                                :class="{
                                                    'is-invalid':
                                                        errors[
                                                            'detail.0.out_qoh'
                                                        ]
                                                }"
                                                @input="
                                                    calculateOutQty('outQoh')
                                                "
                                                @keypress="onlyNumber"
                                                v-on:blur="
                                                    getCOGS(
                                                        0,
                                                        mutation.detail[0]
                                                            .item_id
                                                    )
                                                "
                                            />
                                            <transition name="slide-fade">
                                                <span
                                                    v-if="
                                                        errors[
                                                            'detail.0.out_qoh'
                                                        ]
                                                    "
                                                    class="text-danger "
                                                >
                                                    {{
                                                        errors[
                                                            "detail.0.out_qoh"
                                                        ][0]
                                                    }}
                                                </span>
                                                <span
                                                    v-if="
                                                        this.mutation.detail[0]
                                                            .out_qoh >
                                                            this.item.item_qty
                                                    "
                                                    class="text-danger"
                                                >
                                                    New Quantity on Hand cannot
                                                    be more than Current
                                                    Quantity on Hand
                                                </span>
                                            </transition>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-if="this.item.is_inventory == 1">
                                    <div class="col-12 pt-1">
                                        <div class="form-group mb-0" >
                                            <label for="example-text-input"
                                                >Adjustment Account</label
                                            >
                                            <selectize
                                                class="form-control mt-1"
                                                placeholder="Adjustment Account"
                                                v-model="
                                                    mutation.detail[0].coa_id
                                                "
                                                :class="{
                                                    'is-invalid-selectize':
                                                        errors[
                                                            'detail.0.coa_id'
                                                        ]
                                                }"
                                            >
                                                <!-- settings is optional -->
                                                <option
                                                    v-for="account in transactionAccounts"
                                                    :value="account.id"
                                                    :key="account.id"
                                                >
                                                    {{ account.account_no }}
                                                    {{ account.account_name }}
                                                </option>
                                            </selectize>
                                            <span
                                                v-if="
                                                    this.item.is_inventory == 0
                                                "
                                                class="text-muted-high"
                                            >
                                                Item set as
                                                <b>Untracked Item</b>,
                                                Adjustment item will create no
                                                Journal Mutation
                                            </span>
                                            <transition name="slide-fade">
                                                <span
                                                    v-if="
                                                        errors[
                                                            'detail.0.coa_id'
                                                        ]
                                                    "
                                                    class="text-danger "
                                                >
                                                    {{
                                                        errors[
                                                            "detail.0.coa_id"
                                                        ][0]
                                                    }}
                                                </span>
                                            </transition>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 pt-2">
                                        <div class="form-group mb-1">
                                            <label for="example-text-input"
                                                >Memo</label
                                            >
                                            <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Mutation Memo"
                                                v-model="mutation.mutation_memo"
                                                :class="{
                                                    'is-invalid':
                                                        errors.mutation_memo
                                                }"
                                            />
                                            <transition name="slide-fade">
                                                <p
                                                    class="text-danger"
                                                    v-if="errors.mutation_memo"
                                                >
                                                    {{
                                                        errors.mutation_memo[0]
                                                    }}
                                                </p>
                                            </transition>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--END Form-->
                    </template>

                    <!-- REVIEW SECTION --------------------------->
                    <template v-if="showReview">
                        <div class="row mb-1 mx-2">
                            <div class="col-12">
                                <div class="information-panel">
                                    <p class="my-1">
                                        <i class="fa fa-info-circle mr-1"></i
                                        ><b>Information</b>
                                    </p>
                                    <p class="mb-2">
                                        Make sure the data you've entered is
                                        correct
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!-- Indicator -->
                        <div class="row px-3">
                            <div class="col-12 py-1">
                                <span class="pill-item-code">New</span>
                            </div>
                            <div class="col-4 py-0 text-center">
                                <div class="form-group border-r-1 pr-3 mb-1">
                                    <p>
                                        Quantity on Hand
                                        <i
                                            class="si si-info"
                                            data-toggle="popover"
                                            data-placement="top"
                                            title="Quantity on Hand"
                                            data-content="This is example content. You can put a description or more info here."
                                        ></i>
                                    </p>
                                    <p
                                        class="font-weight-bolder font-size-h5 mb-0"
                                    >
                                        {{ reviewStock }}
                                    </p>
                                    <p class="mb-2 text-muted font-small">
                                        {{
                                            this.mutation.mutation_type == 1 ||
                                            this.mutation.mutation_type == 3 ||
                                            this.mutation.mutation_type == 5
                                                ? "+"
                                                : "-"
                                        }}
                                        {{
                                            this.mutation.mutation_type == 1 ||
                                            this.mutation.mutation_type == 3 ||
                                            this.mutation.mutation_type == 5
                                                ? Math.abs(
                                                      reviewStock -
                                                          this.item.item_qty
                                                  )
                                                : Math.abs(
                                                      this.item.item_qty -
                                                          reviewStock
                                                  )
                                        }}
                                        item per
                                        {{
                                            this.mutation.mutation_type == 1 ||
                                            this.mutation.mutation_type == 3 ||
                                            this.mutation.mutation_type == 5
                                                ? this.mutation.detail[0]
                                                      .in_price
                                                : this.mutation.detail[0]
                                                      .out_price | currency
                                        }}
                                    </p>
                                </div>
                            </div>
                            <div class="col-4 py-0 text-center">
                                <div class="form-group border-r-1 pr-3 mb-1">
                                    <p>Average Cost</p>
                                    <p
                                        class="font-weight-bolder font-size-h5 mb-0"
                                    >
                                        {{
                                            (reviewValue / reviewStock)
                                                | currency
                                        }}
                                    </p>
                                    <p class="mb-2 text-muted font-small">
                                        {{
                                            reviewValue / reviewStock >
                                            this.item.item_value /
                                                this.item.item_qty
                                                ? "+"
                                                : "-"
                                        }}
                                        {{
                                            this.mutation.mutation_type == 1 ||
                                            this.mutation.mutation_type == 3 ||
                                            this.mutation.mutation_type == 5
                                                ? Math.abs(
                                                      reviewValue /
                                                          reviewStock -
                                                          this.item.item_value /
                                                              this.item.item_qty
                                                  )
                                                : Math.abs(
                                                      this.item.item_value /
                                                          this.item.item_qty -
                                                          reviewValue /
                                                              reviewStock
                                                  ) | currency
                                        }}
                                    </p>
                                </div>
                            </div>
                            <div class="col-4 py-0 text-center">
                                <div class="form-group border-r-1 pr-3 mb-1">
                                    <p>Total Value</p>
                                    <p
                                        class="font-weight-bolder font-size-h5 mb-0"
                                    >
                                        {{ reviewValue | currency }}
                                    </p>
                                    <p class="mb-2 text-muted font-small">
                                        {{
                                            reviewValue > this.item.item_value
                                                ? "+"
                                                : "-"
                                        }}
                                        {{
                                            this.mutation.mutation_type == 1 ||
                                            this.mutation.mutation_type == 3 ||
                                            this.mutation.mutation_type == 5
                                                ? Math.abs(
                                                      reviewValue -
                                                          this.item.item_value
                                                  )
                                                : Math.abs(
                                                      this.item.item_value -
                                                          reviewValue
                                                  ) | currency
                                        }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!-- END Indicator -->
                        <hr class="my-0 mx-3" />
                        <!--Form-->
                        <div class="row font-size-sm">
                            <div class="col-12 content pt-2">
                                <div class="row">
                                    <div class="col-6 pt-1">
                                        <div class="form-group mb-0">
                                            <label for="example-text-input"
                                                >Date</label
                                            >
                                            <p class="mb-0">
                                                {{
                                                    this.mutation.mutation_date
                                                        | moment("DD/MM/YYYY")
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="row"
                                >
                                    <div class="col-12 pt-2">
                                        <div class="form-group mb-1">
                                            <label for="example-text-input"
                                                >Memo</label
                                            >
                                            <p class="mb-0">
                                                {{
                                                    this.mutation.mutation_memo
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="row"
                                    v-if="this.item.is_inventory == 1"
                                >
                                    <div class="col-12">
                                        <table class="table table-bordered">
                                            <thead class="bg-body-light">
                                                <tr>
                                                    <th
                                                        class="text-center text-uppercase"
                                                    >
                                                        Account No.
                                                    </th>
                                                    <th
                                                        class="text-center text-uppercase"
                                                    >
                                                        Debit
                                                    </th>
                                                    <th
                                                        class="text-center text-uppercase"
                                                        style="width: 20%"
                                                    >
                                                        Credit
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <p class="mb-1">
                                                            {{
                                                                this.item
                                                                    .coa_inventory
                                                                    .account_no
                                                            }}
                                                            {{
                                                                this.item
                                                                    .coa_inventory
                                                                    .account_name
                                                            }}
                                                        </p>
                                                    </td>
                                                    <td class="text-right">
                                                        <p class="mb-1">
                                                            {{
                                                                Math.abs(
                                                                    reviewValue -
                                                                        this
                                                                            .item
                                                                            .item_value
                                                                ) | currency
                                                            }}
                                                        </p>
                                                    </td>
                                                    <td class="text-right">
                                                        <p class="mb-1">
                                                            {{ 0 | currency }}
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p class="mb-1">
                                                            {{
                                                                reviewTransactionCOA
                                                            }}
                                                        </p>
                                                    </td>
                                                    <td class="text-right">
                                                        <p class="mb-1">
                                                            {{ 0 | currency }}
                                                        </p>
                                                    </td>
                                                    <td class="text-right">
                                                        <p class="mb-1">
                                                            {{
                                                                Math.abs(
                                                                    reviewValue -
                                                                        this
                                                                            .item
                                                                            .item_value
                                                                ) | currency
                                                            }}
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        class="font-w700 text-uppercase text-center"
                                                    >
                                                        Total
                                                    </td>
                                                    <td class="text-right">
                                                        <p
                                                            class="font-w700 mb-1"
                                                        >
                                                            {{
                                                                Math.abs(
                                                                    reviewValue -
                                                                        this
                                                                            .item
                                                                            .item_value
                                                                ) | currency
                                                            }}
                                                        </p>
                                                    </td>

                                                    <td class="text-right">
                                                        <p
                                                            class="font-w700 mb-1"
                                                        >
                                                            {{
                                                                Math.abs(
                                                                    reviewValue -
                                                                        this
                                                                            .item
                                                                            .item_value
                                                                ) | currency
                                                            }}
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div
                                    class="row"
                                    v-else
                                >
                                    <div class="col-12">
                                        <hr>
                                        <p class="text-muted-high text-center">
                                            Item set as <b>Untracked Item</b>,
                                            Adjustment item will create no
                                            Journal Mutation
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--END Form-->
                    </template>
                </div>
                <div class="block-footer border-t-1">
                    <!-- Submit -->
                    <div class="footer text-right p-3">
                        <template v-if="!showReview">
                            <button
                                type="button"
                                class="btn btn-sm btn-alt-secondary mr-1"
                                tag="button"
                                @click.prevent="close"
                            >
                                <i class="fa fa-fw fa-times mr-1"></i> Close
                            </button>
                            <button
                                type="submit"
                                class="btn btn-sm btn-info"
                                v-ripple="{ center: true }"
                                @click.prevent="review"
                            >
                                <i class="fa fa-fw fa-file-alt mr-1"></i>
                                Review Adjustment
                            </button>
                        </template>
                        <template v-if="showReview">
                            <button
                                type="button"
                                class="btn btn-sm btn-transparent mr-1"
                                tag="button"
                                @click.prevent="review"
                            >
                                <i class="fa fa-fw fa-undo mr-1"></i> Back
                            </button>
                            <button
                                type="submit"
                                class="btn btn-sm btn-info"
                                v-ripple="{ center: true }"
                                @click.prevent="submit"
                            >
                                <i class="fa fa-fw fa-check mr-1"></i> Post
                                Adjustment
                            </button>
                        </template>
                    </div>
                    <!-- END Submit -->
                </div>
            </form>
        </div>
        <!-- END Basic -->
    </div>
    <!-- END Page Content -->
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

export default {
    name: "adjustment",
    props: ["itemId", "warehouseId"],
    data() {
        return {
            loadingPage: 0,
            prefix: "1-",
            showReview: false,
            disableQty: null,
            reviewStock: 0,
            reviewValue: 0,
            reviewOnOrder: 0,
            reviewTransactionCOA: 0
        };
    },
    created() {
        this.getCoaAll();
        this.setMutationType();
        this.mutation.detail[0].item_id = this.itemId;
    },
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState("coa", { coas: state => state.coas.accounts }),
        ...mapState("mutation", { mutation: state => state.mutation }),
        ...mapState("item", { item: state => state.item }),
        transactionAccounts: function() {
            if (this.coas) {
                return this.coas.filter(function(coa) {
                    return (
                        coa.account_type === "Expense" ||
                        coa.account_type === "Other Expense"
                    );
                });
            }
        }
    },
    methods: {
        //VUEX
        ...mapMutations(["CLEAR_ERRORS"]),
        ...mapMutations("mutation", ["CLEAR_FORM"]),
        ...mapActions("item", ["getCOGSRequest"]),
        ...mapActions("coa", ["getCoaAll"]),
        ...mapActions("mutation", [
            "submitMutation",
            "reviewMutation",
            "printMutation"
        ]),
        //METHOD
        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                // 46 is dot
                $event.preventDefault();
            }
        },
        setMutationType() {
            if (
                this.mutation.mutation_type == 1 ||
                this.mutation.mutation_type == 3 ||
                this.mutation.mutation_type == 5
            ) {
                this.mutation.detail[0].out_qty = 0;
                this.mutation.detail[0].out_qoh = 0;
                this.mutation.detail[0].out_ammount = 0;
            } else {
                this.mutation.detail[0].in_qty = 0;
                this.mutation.detail[0].in_qoh = 0;
                this.mutation.detail[0].in_ammount = 0;
            }
        },
        getCOGS(index, val) {
            if (
                this.mutation.detail[index].out_qty != null &&
                this.mutation.detail[index].out_qty != 0 &&
                val != null &&
                val != 0
            ) {
                this.mutation.detail[index].error_cogs = false;
                this.mutation.detail[index].out_price = 0;
                this.getCOGSRequest({
                    warehouse: this.mutation.warehouse_id,
                    date: this.mutation.mutation_date,
                    item: val,
                    qty: this.mutation.detail[index].out_qty
                })
                    .then(response => {
                        if (response == -1) {
                            this.mutation.detail[index].error_cogs = true;
                            this.mutation.detail[index].out_price = 0;
                            this.mutation.detail[index].out_amount = 0;
                            this.calculate();
                        } else {
                            this.mutation.detail[index].out_price = response;
                            this.mutation.detail[index].out_amount =
                                this.mutation.detail[index].out_qty *
                                this.mutation.detail[index].out_price;
                            this.calculate();
                        }
                        this.loadingPage = 0;
                    })
                    .catch(error => {
                        error.response.status == 500
                            ? this.alert(error.response.data.message, 2)
                            : "";
                        error.response.status != 422 &&
                        error.response.status != 500
                            ? this.alert(error.message, 2)
                            : "";
                        this.loadingPage = 0;
                        this.totalItem = 0;
                    });
            } else {
                this.mutation.detail[index].error_cogs = false;
                this.mutation.detail[index].out_price = 0;
                this.mutation.detail[index].out_amount = 0;
                this.calculate();
            }
        },
        calculateInQty(val) {
            this.disableQty = val;
            if (val == "inQty") {
                this.mutation.detail[0].in_qoh = 0;
                if (this.mutation.detail[0].in_qty != 0) {
                    this.mutation.detail[0].in_qoh =
                        this.item.item_qty + this.mutation.detail[0].in_qty;
                }
            }
            if (val == "inQoh") {
                this.mutation.detail[0].in_qty = 0;
                if (
                    this.mutation.detail[0].in_qoh != 0 &&
                    this.mutation.detail[0].in_qoh > this.item.item_qty
                ) {
                    this.mutation.detail[0].in_qty =
                        this.mutation.detail[0].in_qoh - this.item.item_qty;
                }
            }
        },
        calculateOutQty(val) {
            this.disableQty = val;
            if (val == "outQty") {
                this.mutation.detail[0].out_qoh = 0;
                if (this.mutation.detail[0].out_qty > 0) {
                    this.mutation.detail[0].out_qoh =
                        this.item.item_qty - this.mutation.detail[0].out_qty;
                }
            }
            if (val == "outQoh") {
                this.mutation.detail[0].out_qty = 0;
                if (
                    this.mutation.detail[0].out_qoh >= 0 &&
                    this.mutation.detail[0].out_qoh < this.item.item_qty
                ) {
                    this.mutation.detail[0].out_qty =
                        this.item.item_qty - this.mutation.detail[0].out_qoh;
                }
            }
        },
        calculate() {
            var total = 0;
            this.mutation.detail.forEach(detail => {
                total += detail.out_amount;
            });
            this.mutation.mutation_total = total;
        },
        changeDate() {
            this.getMutationNo();
        },
        review() {
            this.CLEAR_ERRORS();
            this.loadingPage = 1;
            this.reviewMutation()
                .then(response => {
                    this.showReview = !this.showReview;
                    this.reviewStock = response.review_stock;
                    this.reviewValue = response.review_value;
                    this.reviewTransactionCOA = response.review_transaction_coa;
                    this.loadingPage = 0;
                })
                .catch(error => {
                    error.response.status == 500
                        ? this.alert(error.response.data.message, 2)
                        : "";
                    error.response.status != 422 && error.response.status != 500
                        ? this.alert(error.message, 2)
                        : "";
                    this.loadingPage = 0;
                });
        },
        submit() {
            this.CLEAR_ERRORS();
            this.loadingPage = 2;
            this.submitMutation()
                .then(response => {
                    this.$root.$emit("refreshItemDetail");
                    this.$modal.hide("adjustment-item");
                    this.alert("Successfully create Mutation Data ", 1);
                    this.CLEAR_FORM();
                    this.showReview = !this.showReview;
                    this.loadingPage = 0;
                })
                .catch(error => {
                    error.response.status == 500
                        ? this.alert(error.response.data.message, 2)
                        : "";
                    error.response.status != 422 && error.response.status != 500
                        ? this.alert(error.message, 2)
                        : "";
                    this.loadingPage = 0;
                });
        },
        close() {
            this.$modal.hide("adjustment-item");
            document.body.classList.remove("modal-open");
        }
    },
    destroyed() {
        //FORM DI BERSIHKAN
        this.CLEAR_FORM();
        this.CLEAR_ERRORS();
    }
};
</script>
