<template>
    <v-app style="background-color: #f8f8f8">
        <v-main>
            <!-- Hero -->
            <div
                class="bg-image overflow-hidden"
                v-bind:style="{ 'background-color': '#f8f8f8!important' }"
            >
                <div class="content content-narrow content-full pb-0">
                    <div
                        class="d-flex flex-sm-row justify-content-between align-items-center mt-5 mb-2 text-sm-left"
                    >
                        <div class="flex-sm-fill">
                            <h2
                                class="font-w600 text-dark-75 mb-0 text-left"
                                data-toggle="appear"
                            >
                                {{ auth.data.workspace.workspace_company_name }}
                            </h2>
                            <h2
                                class="h4 font-w400 text-dark-75 mb-0 text-left"
                                data-toggle="appear"
                                data-timeout="250"
                            >
                                Welcome {{ auth.data.name }}
                            </h2>
                        </div>
                        <div class="flex-sm-00-auto mt-3 mt-sm-0 ml-sm-3">
                            <div class="dropdown dropleft push">
                                <button
                                    type="button"
                                    class="btn btn-info font-size-sm text-white d-md-inline d-none"
                                    id="dropdown-dropleft"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    Create New
                                    <i class="fa fa-plus ml-1"></i>
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-info btn-rounded font-size-sm text-white d-inline d-md-none"
                                    style="padding: 5px 9px"
                                    id="dropdown-dropleft"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    <i class="fa fa-plus"></i>
                                </button>
                                <div
                                    class="dropdown-menu dropdown-menu-rounded font-size-sm px-1"
                                    aria-labelledby="dropdown-dropleft"
                                >
                                    <router-link
                                        :to="{ name: 'journal-record' }"
                                        class="btn btn-sm btn-transparent btn-alt-info dropdown-item-horizontal text-info"
                                        v-ripple="{ center: true }"
                                        style="position: inline-block"
                                        tag="button"
                                    >
                                        <i class="fa fa-book mr-1"></i>
                                        <span class="d-none d-md-inline"
                                            >Journal</span
                                        >
                                    </router-link>
                                    <router-link
                                        :to="{ name: 'bill-enter' }"
                                        class="btn btn-sm btn-transparent btn-alt-info dropdown-item-horizontal text-info"
                                        v-ripple="{ center: true }"
                                        style="position: inline-block"
                                    >
                                        <i
                                            class="fa fa-file-invoice-dollar mr-1"
                                        ></i>
                                        <span class="d-none d-md-inline"
                                            >Bill</span
                                        >
                                    </router-link>
                                    <router-link
                                        :to="{ name: 'purchase-enter' }"
                                        class="btn btn-sm btn-transparent btn-alt-info dropdown-item-horizontal text-info"
                                        v-ripple="{ center: true }"
                                        style="position: inline-block"
                                        tag="button"
                                    >
                                        <i class="fa fa-wallet mr-1"></i>
                                        <span class="d-none d-md-inline"
                                            >Purchase</span
                                        >
                                    </router-link>
                                    <router-link
                                        :to="{ name: 'income-create' }"
                                        class="btn btn-sm btn-transparent btn-alt-info text-info dropdown-item-horizontal"
                                        v-ripple="{ center: true }"
                                        tag="button"
                                    >
                                        <i class="fa fa-sign-in-alt mr-1"></i>
                                        <span class="d-none d-md-inline"
                                            >Income</span
                                        >
                                    </router-link>
                                    <router-link
                                        :to="{ name: 'expense-create' }"
                                        class="btn btn-sm btn-transparent btn-alt-info text-info dropdown-item-horizontal"
                                        v-ripple="{ center: true }"
                                        tag="button"
                                    >
                                        <i class="fa fa-sign-out-alt mr-1"></i>
                                        <span class="d-none d-md-inline"
                                            >Expense</span
                                        >
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- END Hero -->

            <!-- Page Content -->
            <div class="content content-narrow">
                <!-- workspace -->
                <div class="row" v-if="workspace.workspaces.length > 1">
                    <div
                        v-for="(workspace, index) in workspace.workspaces"
                        v-bind:key="index"
                        class="col-6 col-md-3 col-lg-3 col-xl-3 workspace-tile workspace-tile-dashboard"
                    >
                        <a
                            class="block block-rounded block-link-pop mb-0 mb-md-2"
                            @click="
                                doChangeWorkspace(workspace.workspace_token)
                            "
                        >
                            <div
                                class="block-content block-content-full block-workspace"
                                v-bind:style="
                                    workspace.workspace_company_banner !=
                                        null &&
                                    workspace.workspace_company_banner !==
                                        'empty'
                                        ? {
                                              'background-image':
                                                  'linear-gradient(rgba(62,77,82,.5), rgba(62,77,82,.5)),url(/storage/workspace/' +
                                                  workspace.workspace_company_banner +
                                                  ')',
                                              'background-size': 'cover',
                                              'background-position': 'center',
                                          }
                                        : {
                                              'background-image':
                                                  'linear-gradient(rgba(62,77,82,.5), rgba(62,77,82,.5)),url(/media/photos/photo26@2x.jpg)',
                                              'background-size': 'cover',
                                              'background-position': 'center',
                                          }
                                "
                            >
                                <div
                                    class="font-size-sm text-white workspace-tile-title"
                                >
                                    {{ workspace.workspace_name }}
                                    <i
                                        class="fa fa-fw fa-check-circle text-info ml-2"
                                        v-if="
                                            workspace.workspace_token ==
                                            auth.data.workspace_token
                                        "
                                    ></i>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <!-- END workspace -->

                <hr />

                <!-- Dashboard Charts -->
                <div class="row">
                    <div class="col-lg-6 pb-0 pb-md-2">
                        <div
                            class="row invoice-list"
                            style="
                                padding-right: 10px !important;
                                padding-left: 12px !important;
                            "
                        >
                            <!-- IF HAVE ACCESS -->
                            <template
                                v-if="
                                    auth.role.includes('masterAccount') ||
                                    auth.role.includes('devAccount') ||
                                    auth.role.includes('accChief') ||
                                    auth.role.includes('accStakeholder') ||
                                    auth.role.includes('accStaffBill') ||
                                    auth.role.includes('accStaffPurchase')
                                "
                            >
                                <!-- IF THERE IS DUE BILLS -->
                                <div
                                    v-for="bill in dueDateBillData"
                                    :key="bill.index"
                                    class="col-12 py-2 px-3 alert alert-light alert-dismissable block-mode-loading-oneui"
                                    style="border-radius: 5px"
                                    role="alert"
                                >
                                    <button
                                        type="button"
                                        class="btn-sm close"
                                        data-dismiss="alert"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    <p class="mb-0">
                                        <span
                                            class="badge badge-warning mr-1"
                                            v-if="
                                                new Date(bill.bill_due_date) >
                                                new Date(dateNow)
                                            "
                                            >Due Soon</span
                                        >
                                        <span
                                            class="badge badge-danger mr-1"
                                            v-else-if="
                                                new Date(bill.bill_due_date) <
                                                new Date(dateNow)
                                            "
                                            >Overdue</span
                                        >
                                        <span
                                            class="badge badge-info mr-1"
                                            v-else
                                            >Due Today</span
                                        >
                                        <span v-if="bill.bill_type == 'Sales'">
                                            Bill Invoice No.
                                            <router-link
                                                :to="{
                                                    name: 'bill-detail',
                                                    params: { id: bill.id },
                                                }"
                                                class="alert-link"
                                                tag="a"
                                            >
                                                {{ bill.bill_invoice_number }}
                                            </router-link>
                                        </span>
                                        <span
                                            v-if="
                                                bill.bill_type == 'Purchases'
                                            "
                                        >
                                            Purchase Invoice No.
                                            <router-link
                                                :to="{
                                                    name: 'purchase-detail',
                                                    params: { id: bill.id },
                                                }"
                                                class="alert-link"
                                                tag="a"
                                            >
                                                {{ bill.bill_invoice_number }}
                                            </router-link>
                                        </span>
                                        <span>
                                            at
                                            {{
                                                bill.bill_due_date
                                                    | moment("DD/MM/YYYY")
                                            }}
                                        </span>
                                    </p>
                                </div>
                                <!-- IF THERE IS NO DUE BILLS -->
                                <div
                                    v-if="dueDateBillData.length == 0"
                                    class="col-12 py-2 px-3 alert alert-secondary alert-empty alert-dismissable"
                                    role="alert"
                                >
                                    <button
                                        type="button"
                                        class="btn-sm close"
                                        data-dismiss="alert"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    <p class="mb-0">
                                        No Overdue/Due Later Invoice in a Week
                                    </p>
                                </div>
                                <div
                                    v-if="dueDateBillData.length == 0"
                                    class="col-12 py-2 px-3 alert alert-secondary alert-empty alert-dismissable"
                                    role="alert"
                                >
                                    <button
                                        type="button"
                                        class="btn-sm close"
                                        data-dismiss="alert"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    <p class="mb-0">
                                        No Overdue/Due Later Invoice in a Week
                                    </p>
                                </div>
                                <div
                                    v-if="dueDateBillData.length == 0"
                                    class="col-12 py-2 px-3 alert alert-secondary alert-empty alert-dismissable"
                                    role="alert"
                                >
                                    <button
                                        type="button"
                                        class="btn-sm close"
                                        data-dismiss="alert"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    <p class="mb-0">
                                        No Overdue/Due Later Invoice in a Week
                                    </p>
                                </div>
                            </template>
                            <!-- IF HAVE NO ACCESS -->
                            <template v-else>
                                <div
                                    class="col-12 py-2 px-3 alert alert-secondary alert-empty alert-dismissable"
                                    role="alert"
                                >
                                    <button
                                        type="button"
                                        class="btn-sm close"
                                        data-dismiss="alert"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    <p class="mb-0">
                                        Overdue/Due Later Invoice Hidden
                                    </p>
                                </div>
                                <div
                                    class="col-12 py-2 px-3 alert alert-secondary alert-empty alert-dismissable"
                                    role="alert"
                                >
                                    <button
                                        type="button"
                                        class="btn-sm close"
                                        data-dismiss="alert"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    <p class="mb-0">
                                        Overdue/Due Later Invoice Hidden
                                    </p>
                                </div>
                                <div
                                    class="col-12 py-2 px-3 alert alert-secondary alert-empty alert-dismissable"
                                    role="alert"
                                >
                                    <button
                                        type="button"
                                        class="btn-sm close"
                                        data-dismiss="alert"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    <p class="mb-0">
                                        Overdue/Due Later Invoice Hidden
                                    </p>
                                </div>
                            </template>
                        </div>
                        <div class="row">
                            <div class="col-6 pb-0">
                                <!-- IF HAVE ACCESS -->
                                <template
                                    v-if="
                                        auth.role.includes('masterAccount') ||
                                        auth.role.includes('devAccount') ||
                                        auth.role.includes('accChief') ||
                                        auth.role.includes('accStakeholder') ||
                                        auth.role.includes(
                                            'accStaffPettyCash'
                                        ) ||
                                        auth.role.includes('accStaffBanking')
                                    "
                                >
                                    <div
                                        class="block block-rounded block-link-shadow text-center block-mode-loading-oneui"
                                    >
                                        <div
                                            class="block-content block-content-full"
                                        >
                                            <div class="font-size-h5 text-info">
                                                <animated-number
                                                    :value="amountInc"
                                                    :formatValue="formatToPrice"
                                                    :duration="1500"
                                                />
                                                <!-- {{ amountInc | currency }} -->
                                            </div>
                                        </div>
                                        <div
                                            class="block-content block-rounded py-2 bg-body-light"
                                        >
                                            <p
                                                class="font-w600 font-size-sm text-muted mb-0"
                                            >
                                                Income this Week
                                            </p>
                                        </div>
                                    </div>
                                </template>
                                <!-- IF NO ACCESS -->
                                <div
                                    v-else
                                    class="block block-rounded block-link-shadow text-center block-mode-loading-oneui"
                                >
                                    <div
                                        class="block-content block-content-full"
                                    >
                                        <center>
                                            <img
                                                :src="'/media/proxima/no-privileges-expense-income.svg'"
                                                style="height: 50px"
                                            />
                                        </center>
                                        <!-- <center class="mt-2 mb-2">
                                            <span
                                                class="text-center font-w700"
                                                style="opacity:0.3"
                                                >No Access Rights</span
                                            >
                                        </center> -->
                                    </div>
                                    <div
                                        class="block-content block-rounded py-2 bg-body-light"
                                    >
                                        <p
                                            class="font-w600 font-size-sm text-muted mb-0"
                                        >
                                            Income this Week
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 pb-0">
                                <!-- IF HAVE ACCESS -->
                                <template
                                    v-if="
                                        auth.role.includes('masterAccount') ||
                                        auth.role.includes('devAccount') ||
                                        auth.role.includes('accChief') ||
                                        auth.role.includes('accStakeholder') ||
                                        auth.role.includes(
                                            'accStaffPettyCash'
                                        ) ||
                                        auth.role.includes('accStaffBanking')
                                    "
                                >
                                    <div
                                        class="block block-rounded block-link-shadow text-center block-mode-loading-oneui"
                                    >
                                        <div
                                            class="block-content block-content-full"
                                        >
                                            <div class="font-size-h5 text-info">
                                                <animated-number
                                                    :value="amountExp"
                                                    :formatValue="formatToPrice"
                                                    :duration="1500"
                                                />
                                                <!-- {{ amountExp | currency }} -->
                                            </div>
                                        </div>
                                        <div
                                            class="block-content block-rounded py-2 bg-body-light"
                                        >
                                            <p
                                                class="font-w600 font-size-sm text-muted mb-0"
                                            >
                                                Expense this Week
                                            </p>
                                        </div>
                                    </div>
                                </template>
                                <!-- IF NO ACCESS -->
                                <div
                                    v-else
                                    class="block block-rounded block-link-shadow text-center block-mode-loading-oneui"
                                >
                                    <div
                                        class="block-content block-content-full"
                                    >
                                        <center>
                                            <img
                                                :src="'/media/proxima/no-privileges-expense-income.svg'"
                                                style="height: 50px"
                                            />
                                        </center>
                                        <!-- <center class="mt-2 mb-2">
                                            <span
                                                class="text-center font-w700"
                                                style="opacity:0.3"
                                                >No Access Rights</span
                                            >
                                        </center> -->
                                    </div>
                                    <div
                                        class="block-content block-rounded py-2 bg-body-light"
                                    >
                                        <p
                                            class="font-w600 font-size-sm text-muted mb-0"
                                        >
                                            Expense this Week
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="block block-rounded block-mode-loading-oneui"
                        >
                            <div
                                class="block-header block-rounded block-header-default"
                            >
                                <h3 class="block-title">Profit/Loss</h3>
                                <div class="block-options">
                                    <button
                                        type="button"
                                        class="btn-block-option"
                                        @click.stop="refreshProfitLoss()"
                                        :disabled="loadingProfitLoss"
                                    >
                                        <i class="si si-refresh"></i>
                                    </button>
                                </div>
                            </div>
                            <div
                                class="block-content block-content-full text-center pt-0"
                            >
                                <div class="py-3">
                                    <!-- DATA EMPTY -->
                                    <div
                                        class="block-content block-content-full py-0"
                                        v-if="emptyProfitLoss"
                                    >
                                        <center>
                                            <img
                                                :src="'/media/proxima/empty-graph.svg'"
                                                style="height: 100px"
                                            />
                                        </center>
                                        <center class="mt-2">
                                            <span
                                                class="text-center font-w700"
                                                style="opacity: 0.3"
                                                >Data Empty</span
                                            >
                                        </center>
                                    </div>
                                    <!-- MAINTENANCE -->
                                    <div
                                        class="block-content block-content-full py-0"
                                        v-if="maintenanceProfitLoss"
                                    >
                                        <center>
                                            <img
                                                :src="'/media/proxima/under-maintenance.svg'"
                                                style="height: 100px"
                                            />
                                        </center>
                                        <center class="mt-2 mb-5">
                                            <span
                                                class="text-center font-w700"
                                                style="opacity: 0.3"
                                                >Under Maintenance</span
                                            >
                                        </center>
                                    </div>
                                    <!-- IF HAVE ACCESS -->
                                    <template
                                        v-if="
                                            auth.role.includes(
                                                'masterAccount'
                                            ) ||
                                            auth.role.includes('devAccount') ||
                                            auth.role.includes('accChief') ||
                                            auth.role.includes(
                                                'accStakeholder'
                                            ) ||
                                            auth.role.includes('accStaffReport')
                                        "
                                    >
                                        <div
                                            class="block-content block-content-full py-0"
                                            v-if="loadingProfitLoss"
                                        >
                                            <grid-loader
                                                class="mx-auto"
                                                :color="'#70B9EB'"
                                                :size="'10px'"
                                            ></grid-loader>
                                            <center class="mt-2">
                                                <span
                                                    class="text-center"
                                                    style="opacity: 0.7"
                                                    >Get Data</span
                                                >
                                            </center>
                                        </div>
                                        <chart-earning
                                            @loadingProfitLossChild="
                                                changeLoadingProfitLoss()
                                            "
                                            @emptyProfitLossChild="
                                                emptyLoadingProfitLoss()
                                            "
                                            :key="chartProfitLossKey"
                                        />
                                    </template>
                                    <!-- IF HAVE NO ACCESS -->
                                    <div
                                        v-else
                                        class="block-content block-content-full py-0"
                                    >
                                        <center>
                                            <img
                                                :src="'/media/proxima/no-privileges-profitloss.svg'"
                                                style="height: 100px"
                                            />
                                        </center>
                                        <center class="mt-2 mb-5">
                                            <span
                                                class="text-center font-w700"
                                                style="opacity: 0.3"
                                                >No Access Rights</span
                                            >
                                        </center>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 pb-0 pb-md-2">
                        <div class="row text-center">
                            <!-- <div class="col-12 pt-0 ">
                                <p class="text-center m-0 text-secondary">
                                    Balance Sheet
                                </p>
                            </div> -->
                            <div class="col-12 col-md-6 py-0 pr-md-2">
                                <!-- IF HAVE ACCESS -->
                                <template
                                    v-if="
                                        auth.role.includes('masterAccount') ||
                                        auth.role.includes('devAccount') ||
                                        auth.role.includes('accChief') ||
                                        auth.role.includes('accStakeholder') ||
                                        auth.role.includes('accStaffAccount')
                                    "
                                >
                                    <div
                                        class="block block-rounded block-link-shadow text-center block-mode-loading-oneui mb-3"
                                    >
                                        <div
                                            class="block-content block-content-full pt-3 pb-1"
                                        >
                                            <div
                                                class="font-size-h5 text-success"
                                            >
                                                <animated-number
                                                    :value="totalAssets"
                                                    :formatValue="formatToPrice"
                                                    :duration="1500"
                                                />
                                                <!-- {{ totalAssets | currency }} -->
                                            </div>
                                        </div>
                                        <div class="block-content pt-1 pb-3">
                                            <p
                                                class="font-w600 font-size-sm text-muted mb-0"
                                            >
                                                Assets
                                            </p>
                                        </div>
                                    </div>
                                </template>
                                <!-- IF NO ACCESS -->
                                <div
                                    v-else
                                    class="block block-rounded block-link-shadow text-center block-mode-loading-oneui mb-3"
                                >
                                    <div
                                        class="block-content block-content-full pt-3 pb-1"
                                    >
                                        <center>
                                            <img
                                                :src="'/media/proxima/no-privileges-assets.svg'"
                                                style="height: 50px"
                                            />
                                        </center>
                                    </div>
                                    <div class="block-content pt-1 pb-3">
                                        <p
                                            class="font-w600 font-size-sm text-muted mb-0"
                                        >
                                            Assets
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 py-0 pl-md-2">
                                <!-- IF HAVE ACCESS -->
                                <template
                                    v-if="
                                        auth.role.includes('masterAccount') ||
                                        auth.role.includes('devAccount') ||
                                        auth.role.includes('accChief') ||
                                        auth.role.includes('accStakeholder') ||
                                        auth.role.includes('accStaffAccount')
                                    "
                                >
                                    <div
                                        class="block block-rounded block-link-shadow text-center block-mode-loading-oneui mb-3"
                                    >
                                        <div
                                            class="block-content block-content-full pt-3 pb-1"
                                        >
                                            <div
                                                class="font-size-h5 text-warning"
                                            >
                                                <animated-number
                                                    :value="totalLiabilities"
                                                    :formatValue="formatToPrice"
                                                    :duration="1500"
                                                />
                                                <!-- {{
                                                    totalLiabilities | currency
                                                }} -->
                                            </div>
                                        </div>
                                        <div class="block-content pt-1 pb-3">
                                            <p
                                                class="font-w600 font-size-sm text-muted mb-0"
                                            >
                                                Liabilities
                                            </p>
                                        </div>
                                    </div>
                                </template>
                                <!-- IF NO ACCESS -->
                                <div
                                    v-else
                                    class="block block-rounded block-link-shadow text-center block-mode-loading-oneui mb-3"
                                >
                                    <div
                                        class="block-content block-content-full pt-3 pb-1"
                                    >
                                        <center>
                                            <img
                                                :src="'/media/proxima/no-privileges-liabilities.svg'"
                                                style="height: 50px"
                                            />
                                        </center>
                                    </div>
                                    <div class="block-content pt-1 pb-3">
                                        <p
                                            class="font-w600 font-size-sm text-muted mb-0"
                                        >
                                            Liabilities
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 py-0">
                                <!-- IF HAVE ACCESS -->
                                <template
                                    v-if="
                                        auth.role.includes('masterAccount') ||
                                        auth.role.includes('devAccount') ||
                                        auth.role.includes('accChief') ||
                                        auth.role.includes('accStakeholder') ||
                                        auth.role.includes('accStaffAccount')
                                    "
                                >
                                    <div
                                        class="block block-rounded block-link-shadow text-center block-mode-loading-oneui"
                                    >
                                        <div
                                            class="block-content block-content-full pt-3 pb-1"
                                        >
                                            <div
                                                class="font-size-h5 text-violet"
                                            >
                                                <animated-number
                                                    :value="totalEquity"
                                                    :formatValue="formatToPrice"
                                                    :duration="1500"
                                                />
                                            </div>
                                        </div>
                                        <div class="block-content pt-1 pb-3">
                                            <p
                                                class="font-w600 font-size-sm text-muted mb-0"
                                            >
                                                Equity
                                            </p>
                                        </div>
                                    </div>
                                </template>
                                <!-- IF NO ACCESS -->
                                <div
                                    v-else
                                    class="block block-rounded block-link-shadow text-center block-mode-loading-oneui"
                                >
                                    <div
                                        class="block-content block-content-full pt-3 pb-1"
                                    >
                                        <center>
                                            <img
                                                :src="'/media/proxima/no-privileges-equity.svg'"
                                                style="height: 50px"
                                            />
                                        </center>
                                    </div>
                                    <div class="block-content py-3">
                                        <p
                                            class="font-w600 font-size-sm text-muted mb-0"
                                        >
                                            Equity
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="block block-rounded block-mode-loading-oneui"
                        >
                            <div
                                class="block-header block-rounded block-header-default"
                            >
                                <h3 class="block-title">Sales</h3>
                                <div class="block-options">
                                    <button
                                        type="button"
                                        class="btn-block-option"
                                        @click.stop="refreshSales()"
                                        :disabled="loadingSales"
                                    >
                                        <i class="si si-refresh"></i>
                                    </button>
                                </div>
                            </div>
                            <div
                                class="block-content block-content-full text-center pt-0"
                            >
                                <div class="py-3">
                                    <!-- DATA EMPTY -->
                                    <div
                                        class="block-content block-content-full py-0"
                                        v-if="emptySales"
                                    >
                                        <center>
                                            <img
                                                :src="'/media/proxima/empty-graph.svg'"
                                                style="height: 100px"
                                            />
                                        </center>
                                        <center class="mt-2">
                                            <span
                                                class="text-center font-w700"
                                                style="opacity: 0.3"
                                                >Data Empty</span
                                            >
                                        </center>
                                    </div>
                                    <!-- IF HAVE ACCESS -->
                                    <template
                                        v-if="
                                            auth.role.includes(
                                                'masterAccount'
                                            ) ||
                                            auth.role.includes('devAccount') ||
                                            auth.role.includes('accChief') ||
                                            auth.role.includes(
                                                'accStakeholder'
                                            ) ||
                                            auth.role.includes('accStaffBill')
                                        "
                                    >
                                        <div
                                            class="block-content block-content-full py-0"
                                            v-if="loadingSales"
                                        >
                                            <grid-loader
                                                class="mx-auto"
                                                :color="'#70B9EB'"
                                                :size="'10px'"
                                            ></grid-loader>
                                            <center class="mt-2">
                                                <span
                                                    class="text-center"
                                                    style="opacity: 0.7"
                                                    >Get Data</span
                                                >
                                            </center>
                                        </div>
                                        <chart-sales
                                            @loadingSalesChild="
                                                changeLoadingSales()
                                            "
                                            @emptySalesChild="
                                                emptyLoadingSales()
                                            "
                                            :key="chartSalesKey"
                                        />
                                    </template>
                                    <!-- IF HAVE NO ACCESS -->
                                    <div
                                        v-else
                                        class="block-content block-content-full py-0"
                                    >
                                        <center>
                                            <img
                                                :src="'/media/proxima/no-privileges-chart.svg'"
                                                style="height: 100px"
                                            />
                                        </center>
                                        <center class="mt-2 mb-5">
                                            <span
                                                class="text-center font-w700"
                                                style="opacity: 0.3"
                                                >No Access Rights</span
                                            >
                                        </center>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="block block-rounded block-mode-loading-oneui"
                        >
                            <div
                                class="block-header block-rounded block-header-default"
                            >
                                <h3 class="block-title">Purchases</h3>
                                <div class="block-options">
                                    <button
                                        type="button"
                                        class="btn-block-option"
                                        @click.stop="refreshPurchase()"
                                        :disabled="loadingPurchase"
                                    >
                                        <i class="si si-refresh"></i>
                                    </button>
                                </div>
                            </div>
                            <div
                                class="block-content block-content-full text-center pt-0"
                            >
                                <!-- DATA EMPTY -->
                                <div
                                    class="block-content block-content-full py-0"
                                    v-if="emptyPurchase"
                                >
                                    <center>
                                        <img
                                            :src="'/media/proxima/empty-graph.svg'"
                                            style="height: 100px"
                                        />
                                    </center>
                                    <center class="mt-2">
                                        <span
                                            class="text-center font-w700"
                                            style="opacity: 0.3"
                                            >Data Empty</span
                                        >
                                    </center>
                                </div>
                                <div class="py-3">
                                    <!-- IF HAVE ACCESS -->
                                    <template
                                        v-if="
                                            auth.role.includes(
                                                'masterAccount'
                                            ) ||
                                            auth.role.includes('devAccount') ||
                                            auth.role.includes('accChief') ||
                                            auth.role.includes(
                                                'accStakeholder'
                                            ) ||
                                            auth.role.includes(
                                                'accStaffPurchase'
                                            )
                                        "
                                    >
                                        <div
                                            class="block-content block-content-full py-0"
                                            v-if="loadingPurchase"
                                        >
                                            <grid-loader
                                                class="mx-auto"
                                                :color="'#70B9EB'"
                                                :size="'10px'"
                                            ></grid-loader>
                                            <center class="mt-2">
                                                <span
                                                    class="text-center"
                                                    style="opacity: 0.7"
                                                    >Get Data</span
                                                >
                                            </center>
                                        </div>
                                        <chart-purchases
                                            @loadingPurchaseChild="
                                                changeLoadingPurchase()
                                            "
                                            @emptyPurchaseChild="
                                                emptyLoadingPurchase()
                                            "
                                            :key="chartPurchaseKey"
                                        />
                                    </template>
                                    <!-- IF HAVE NO ACCESS -->
                                    <div
                                        v-else
                                        class="block-content block-content-full py-0"
                                    >
                                        <center>
                                            <img
                                                :src="'/media/proxima/no-privileges-chart.svg'"
                                                style="height: 100px"
                                            />
                                        </center>
                                        <center class="mt-2 mb-5">
                                            <span
                                                class="text-center font-w700"
                                                style="opacity: 0.3"
                                                >No Access Rights</span
                                            >
                                        </center>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- END Dashboard Charts -->
            </div>
            <!-- END Page Content -->
        </v-main>
    </v-app>
</template>

<script>
import SalesChart from "./../components/ChartDashboardSales";
import PurchasesChart from "./../components/ChartDashboardPurchases";
import EarningChart from "./../components/ChartDashboardEarning";
import Chart from "./../../../public/js/plugins/chart.js/Chart.bundle.min.js";
import { mapActions, mapState, mapMutations } from "vuex";
import moment from "moment";

export default {
    components: {
        "chart-sales": SalesChart,
        "chart-purchases": PurchasesChart,
        "chart-earning": EarningChart,
    },
    data() {
        return {
            search: "",
            amountInc: 0,
            amountExp: 0,
            totalAssets: 0,
            totalLiabilities: 0,
            totalEquity: 0,
            dueDateBillData: [],
            loadingPurchase: true,
            emptyPurchase: false,
            chartPurchaseKey: 0,
            loadingSales: true,
            emptySales: false,
            chartSalesKey: 0,
            loadingProfitLoss: true,
            emptyProfitLoss: false,
            chartProfitLossKey: 0,
            maintenanceProfitLoss: false,
            dateNow: moment().format("YYYY-MM-DD"),
        };
    },
    computed: {
        ...mapState(["auth", "workspace"]),
    },
    created() {
        if (
            this.auth.role.includes("masterAccount") ||
            this.auth.role.includes("devAccount") ||
            this.auth.role.includes("accChief") ||
            this.auth.role.includes("accStakeholder") ||
            this.auth.role.includes("accStaffBill") ||
            this.auth.role.includes("accStaffPurchase")
        ) {
            this.dueDateBillInvoice().then((response) => {
                this.dueDateBillData = response;
            });
        }
        if (
            this.auth.role.includes("masterAccount") ||
            this.auth.role.includes("devAccount") ||
            this.auth.role.includes("accChief") ||
            this.auth.role.includes("accStakeholder") ||
            this.auth.role.includes("accStaffBanking")
        ) {
            this.getIncomeAmountWeekly().then((response) => {
                this.amountInc = response;
            });
            this.getExpenseAmountWeekly().then((response) => {
                this.amountExp = response;
            });
        } else if (this.auth.role.includes("accStaffPettyCash")) {
            this.getIncomeAmountWeeklyProject().then((response) => {
                this.amountInc = response;
            });
            this.getExpenseAmountWeeklyProject().then((response) => {
                this.amountExp = response;
            });
        }
        if (
            this.auth.role.includes("masterAccount") ||
            this.auth.role.includes("devAccount") ||
            this.auth.role.includes("accChief") ||
            this.auth.role.includes("accStakeholder") ||
            this.auth.role.includes("accStaffAccount")
        ) {
            this.getBalanceSheet();
        }
        this.getWorkspaces();
        this.getActivities();
    },
    methods: {
        ...mapActions("workspace", ["changeWorkspace", "getWorkspaces"]),
        ...mapActions("expense", [
            "getExpenseAmountWeekly",
            "getExpenseAmountWeeklyProject",
        ]),
        ...mapActions("income", [
            "getIncomeAmountWeekly",
            "getIncomeAmountWeeklyProject",
        ]),
        ...mapActions("activity", ["getActivities"]),
        ...mapActions("bill", ["dueDateBillInvoice"]),
        ...mapActions("journal", ["generateDataBalanceSheet"]),
        changeLoadingSales(value) {
            this.loadingSales = value;
        },
        emptyLoadingSales() {
            this.emptySales = true;
            this.loadingSales = false;
        },
        changeLoadingPurchase(value) {
            this.loadingPurchase = value;
        },
        emptyLoadingPurchase() {
            this.emptyPurchase = true;
            this.loadingPurchase = false;
        },
        changeLoadingProfitLoss(value) {
            this.loadingProfitLoss = value;
        },
        emptyLoadingProfitLoss() {
            this.emptyProfitLoss = true;
            this.loadingProfitLoss = false;
        },
        refreshSales() {
            this.loadingSales = true;
            this.emptySales = false;
            this.chartSalesKey += 1;
        },
        refreshPurchase() {
            this.loadingPurchase = true;
            this.emptyPurchase = false;
            this.chartPurchaseKey += 1;
        },
        refreshProfitLoss() {
            this.loadingProfitLoss = true;
            this.emptyProfitLoss = false;
            this.chartProfitLossKey += 1;
        },
        formatToPrice(value) {
            let val = (value / 1).toFixed(0).replace(".", ",");
            return `Rp` + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        getBalanceSheet() {
            this.generateDataBalanceSheet()
                .then((response) => {
                    this.totalAssets = response.data.assetTotal;
                    this.totalLiabilities = response.data.liabilityTotal;
                    this.totalEquity = response.data.equityTotal;
                })
                .catch((error) => {
                    console.log(error);
                    this.totalAssets = 0;
                    this.totalLiabilities = 0;
                    this.totalEquity = 0;
                });
        },
        doChangeWorkspace(val) {
            this.$swal({
                title: "Are you sure want to change Workspace ?",
                icon: "warning",
                buttons: ["Cancel", "Change"],
            }).then((willChange) => {
                if (willChange) {
                    this.auth.data.workspace_token = val;
                    this.changeWorkspace(val).then(() => {
                        this.getIncomeAmountWeekly().then((response) => {
                            this.amountInc = response;
                        });
                        this.getExpenseAmountWeekly().then((response) => {
                            this.amountExp = response;
                        });
                        this.dueDateBillInvoice().then((response) => {
                            this.dueDateBillData = response;
                        });
                        this.$swal({
                            title: "Succes",
                            text: "Workspace Changed",
                            icon: "success",
                        });
                        this.refreshSales();
                        this.refreshPurchase();
                        this.refreshProfitLoss();
                    });
                }
            });
        },
    },
};
</script>
