<template>
    <main>
        <hero title="Tax Data" prevBreadcrumb="Dashboard" routeBreadcrumb="home" currentBreadcrumb="Tax Data"></hero>

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 1 || !taxs.data"
                style="height:250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity:0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <!-- Dynamic Table Full -->
            <div class="block block-rounded block-mode-loading-oneui" v-else>
                <div class="block-content block-content-full pb-0 pt-3">
                    <div class="row">
                        <div
                            class="col-12 col-md-4 col-lg-4 col-xl-6 mb-2 mb-sm-0 pb-0"
                        >
                            <label>Show :</label>
                            <select
                                class="form-control-sm custom-select"
                                style="max-width:115px"
                                v-model="rows"
                            >
                                <option value="10">10 Rows</option>
                                <option value="20">20 Rows</option>
                                <option value="50">50 Rows</option>
                                <option value="100">100 Rows</option>
                                <option value="All">All Rows</option>
                            </select>
                        </div>
                        <div class="col-12 col-md-8 col-lg-8 col-xl-6 pb-0">
                            <input
                                type="text"
                                class="form-control form-control-sm col-12 col-md-6 float-right ml-1 mt-1 search-index"
                                name="example-text-input"
                                placeholder="Search.."
                                v-model="search"
                            />
                            <router-link
                                type="button"
                                class="btn btn-sm btn-info float-right m-1"
                                :to="{ name: 'tax-create' }"
                                tag="button"
                                v-ripple="{ center: true }"
                            >
                                <i class="fa fa-plus mr-1"></i> Add Data
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="block-content block-content-full">
                    <div class="table-responsive">
                        <table
                            class="table table-border table-vcenter table-hover"
                        >
                            <thead style="background-color: whitesmoke;">
                                <tr>
                                    <th class="table-header-relative">No.</th>
                                    <th
                                        class="table-header-relative column-minwidth-150"
                                    >
                                        Tax Code
                                    </th>
                                    <th
                                        class="table-header-relative sort-head column-minwidth-150"
                                    >
                                        Tax Name
                                    </th>
                                    <th
                                        class="table-header-relative sort-head column-minwidth-150"
                                    >
                                        Tax Rate
                                    </th>
                                </tr>
                            </thead>
                            <tbody v-if="taxs.data && taxs.data.length > 0">
                                <tr v-if="loadingPage == 2">
                                    <td colspan="5">
                                        <grid-loader
                                            class="mx-auto mt-5"
                                            :color="'#70B9EB'"
                                            :size="'10px'"
                                        ></grid-loader>
                                        <center class="mt-2 mb-5">
                                            <span
                                                class="text-center"
                                                style="opacity:0.7"
                                                >Loading</span
                                            >
                                        </center>
                                    </td>
                                </tr>
                                <router-link
                                    v-else
                                    :to="{
                                        name: 'tax-edit',
                                        params: { id: tax.id }
                                    }"
                                    tag="tr"
                                    v-for="(tax, index) in taxs.data"
                                    :key="index"
                                >
                                    <td class="font-size-sm" align="center">
                                        {{ ++index }}
                                    </td>
                                    <td class="font-size-sm">
                                        {{ tax.tax_code }}
                                    </td>
                                    <td class="font-size-sm">
                                        {{ tax.tax_name }}
                                    </td>
                                    <td class="font-size-sm" align="center">
                                        {{ tax.tax_rate }} %
                                    </td>
                                </router-link>
                            </tbody>
                            <tbody v-else>
                                <tr class="data-empty">
                                    <td
                                        class="text-center"
                                        colspan="5"
                                        style="opacity:0.8; padding:2.5rem;"
                                    >
                                        <center>
                                            <img
                                                :src="
                                                    '/media/proxima/empty-data.svg'
                                                "
                                                style="height: 100px;"
                                            />
                                        </center>
                                        <br /><span style="opacity:0.6;"
                                            >Data Not Found</span
                                        >
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p
                            class="text-muted font-size-sm d-inline d-sm-none"
                            style="opacity:0.5;"
                        >
                            Swipe left here to see full column >>
                        </p>
                    </div>

                    <paginate
                        v-model="page"
                        :page-count="this.$store.state.tax.last_page"
                        :prev-text="'Prev'"
                        :next-text="'Next'"
                        :container-class="'pagination mt-3 mt-md-0'"
                        :pageClass="'page-item'"
                        :pageLinkClass="'page-link'"
                        :prevClass="'page-item'"
                        :prevLinkClass="'page-link'"
                        :nextClass="'page-item'"
                        :nextLinkClass="'page-link'"
                    ></paginate>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
    name: "tax",
    data() {
        return {
            search: "",
            awaitingSearch: false,
            rows: 20,
            loadingPage: 1
        };
    },
    created() {
        this.getTax({ search: this.search, rows: this.rows })
            .then(response => {
                this.loadingPage = 0;
            })
            .catch(error => {
                console.log(error);
                this.loadingPage = 0;
            });
    },
    computed: {
        ...mapState("tax", {
            taxs: state => state.taxs
        }),
        page: {
            get() {
                return this.$store.state.contact.page
                    ? this.$store.state.contact.page
                    : 1;
            },
            set(val) {
                this.$store.commit("tax/SET_PAGE", val);
                this.getTax({
                    search: this.search,
                    rows: this.rows,
                    rowsUpdate: 0
                });
            }
        }
    },
    watch: {
        search() {
            this.loadingPage = 2;
            if (!this.awaitingSearch) {
                setTimeout(() => {
                    this.getTax({
                        search: this.search,
                        rows: this.rows,
                        rowsUpdate: 1
                    })
                        .then(response => {
                            this.loadingPage = 0;
                        })
                        .catch(error => {
                            console.log(error);
                            this.loadingPage = 0;
                        });
                    this.awaitingSearch = false;
                }, 1500);
            }
            this.awaitingSearch = true;
        },
        rows() {
            this.getTax({
                search: this.search,
                rows: this.rows,
                rowsUpdate: 1
            });
        }
    },
    methods: {
        ...mapActions("tax", ["getTax"])
    }
};
</script>
