<template>
    <main>
        <!-- Hero -->
        <div class="bg-body-light">
            <div class="content content-full">
                <div
                    class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center"
                >
                    <h1 class="flex-sm-fill h3 my-2">
                        Accounting : Balance Sheet Report
                    </h1>
                    <nav
                        class="flex-sm-00-auto ml-sm-3"
                        aria-label="breadcrumb"
                    >
                        <ol class="breadcrumb breadcrumb-alt">
                            <li class="breadcrumb-item" aria-current="page">
                                <router-link
                                    class="link-fx"
                                    :to="{ name: 'balance-sheet' }"
                                    >Balance Sheet</router-link
                                >
                            </li>
                            <li class="breadcrumb-item">
                                Balance Sheet Report
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
        <!-- END Hero -->

        <!-- Page Content -->
        <div class="content">
            <div class="block mb-2 block-rounded block-mode-loading-oneui">
                <div class="block-header">
                    <h3 class="block-title">Balance Sheet</h3>
                    <div class="block-options">
                        <!-- Print Page functionality is initialized in Helpers.print() -->
                        <button
                            type="button"
                            class="btn-block-option text-info"
                            @click="print()"
                        >
                            <i class="si si-printer mr-1"></i>
                            <span class="d-none d-md-inline-block"
                                >Print Document</span
                            >
                        </button>
                        <button
                            type="button"
                            class="btn-block-option text-success"
                            @click="refresh()"
                        >
                            <i class="si si-reload mr-1"></i>
                            <span class="d-none d-md-inline-block"
                                >Refresh</span
                            >
                        </button>
                    </div>
                </div>
            </div>
            <div class="block bg-transparent mb-2">
                <div class="block-content pt-0 bg-transparent text-right">
                    <input
                        class="form-check-input"
                        style="margin-top:3.5px"
                        type="checkbox"
                        v-model="useHeader"
                    />
                    <label class="form-check-label">Default Header</label>
                </div>
            </div>
            <!--Loading-->
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 2"
                style="height:250px"
            >                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity:0.7"
                            >Generating Report</span
                        >
                    </center>
                </div>
            </div>
            <!-- Basic -->
            <div class="block block-rounded block-mode-loading-oneui" v-else>
                <div class="content content-boxed" id="printMe">
                    <div class="block-content">
                        <div class="p-0 p-md-5" style="min-height:800px">
                            <div class="row pt-0" v-if="useHeader">
                                <div class="col-6 font-size-sm pb-0 pt-0">
                                    <p class="h3 mb-2 mt-3 text-uppercase">
                                        {{
                                            auth.data.workspace
                                                .workspace_company_name
                                        }}
                                    </p>
                                    <p class="mb-0">
                                        {{
                                            auth.data.workspace
                                                .workspace_company_address
                                        }}
                                    </p>
                                    <p class="mb-0">
                                        <span
                                            v-if="
                                                auth.data.workspace
                                                    .workspace_company_province !==
                                                    '' &&
                                                    auth.data.workspace
                                                        .workspace_company_province !==
                                                        null
                                            "
                                        >
                                            {{
                                                auth.data.workspace
                                                    .workspace_company_province
                                            }}
                                        </span>
                                        <span
                                            v-if="
                                                auth.data.workspace
                                                    .workspace_company_postal !==
                                                    '' &&
                                                    auth.data.workspace
                                                        .workspace_company_postal !==
                                                        null
                                            "
                                        >
                                            ,
                                            {{
                                                auth.data.workspace
                                                    .workspace_company_postal
                                            }}
                                        </span>
                                    </p>
                                    <p class="mb-0">
                                        <span
                                            v-if="
                                                auth.data.workspace
                                                    .workspace_company_phone !==
                                                    '' &&
                                                    auth.data.workspace
                                                        .workspace_company_phone !==
                                                        null
                                            "
                                        >
                                            {{
                                                auth.data.workspace
                                                    .workspace_company_phone
                                            }}
                                        </span>
                                        <span
                                            v-if="
                                                auth.data.workspace
                                                    .workspace_company_email !==
                                                    '' &&
                                                    auth.data.workspace
                                                        .workspace_company_email !==
                                                        null
                                            "
                                        >
                                            {{
                                                "| " +
                                                    auth.data.workspace
                                                        .workspace_company_email
                                            }}
                                        </span>
                                    </p>
                                </div>
                                <div
                                    class="col-6 text-right font-size-sm pb-0 pt-0"
                                >
                                    <img
                                        :src="
                                            '/storage/workspace/' +
                                                auth.data.workspace
                                                    .workspace_company_logo
                                        "
                                        alt=""
                                        style="min-heigh:100px; max-height:120px; max-width:180px;"
                                        @error="errorLogoUrl"
                                    />
                                </div>
                            </div>

                            <hr v-if="useHeader"/>

                            <!-- Invoice Info -->
                            <div class="row mb-2">
                                <!-- Company Info -->
                                <div class="col-6 font-size-sm">
                                    <p class="h3 mb-1">Balance Sheet</p>
                                    <p class="mb-2">Currency : IDR</p>
                                </div>
                                <!-- END Company Info -->

                                <!-- Client Info -->
                                <div class="col-6 text-right font-size-sm">
                                    <p class="mb-2" v-if="this.$route.params.type == 1">
                                        On Date :
                                        {{
                                            this.$route.params.date_end
                                                | moment("DD/MM/YYYY")
                                        }}
                                    </p>
                                    <p class="mb-2" v-if="this.$route.params.type == 2">
                                        From :
                                        {{
                                            this.$route.params.date_start
                                                | moment("DD/MM/YYYY")
                                        }}
                                    </p>
                                    <p class="mb-2" v-if="this.$route.params.type == 2">
                                        To :
                                        {{
                                            this.$route.params.date_end
                                                | moment("DD/MM/YYYY")
                                        }}
                                    </p>
                                </div>
                                <!-- END Client Info -->
                            </div>
                            <!-- END Invoice Info -->

                            <hr />

                            <div
                                class="table-responsive push"
                                v-if="loadingPage == 2"
                            >
                                <div
                                    class="block-content block-content-full pb-0"
                                    style="padding-top: 80px"
                                >
                                    <grid-loader
                                        class="mx-auto"
                                        :color="'#70B9EB'"
                                        :size="'10px'"
                                    ></grid-loader>
                                    <center class="mt-2 mb-5 pb-2">
                                        <span
                                            class="text-center"
                                            style="opacity:0.7"
                                            >Refresh Report</span
                                        >
                                    </center>
                                </div>
                            </div>
                            <!-- Table -->
                            <div
                                class="table-responsive push mb-4"
                                v-if="datas && loadingPage == 0"
                            >
                                <table
                                    class="table table-borderless table-proxima font-size-print-table"
                                    style="font-size:13px;"
                                >
                                    <template v-for="level1 in datas">
                                        <tr
                                            class="bg-body-light p-1"
                                            :key="level1.index"
                                        >
                                            <td
                                                colspan="2"
                                                class="font-w700"
                                                style="padding-top: 2px; padding-bottom: 2px;"
                                            >
                                                {{ level1.account_name }}
                                            </td>
                                            <td
                                                style="padding-top: 2px; padding-bottom: 2px;"
                                            ></td>
                                            <td
                                                style="padding-top: 2px; padding-bottom: 2px;"
                                            ></td>
                                            <td
                                                style="padding-top: 2px; padding-bottom: 2px;"
                                            ></td>
                                            <td
                                                style="padding-top: 2px; padding-bottom: 2px;"
                                            ></td>
                                            <td
                                                style="padding-top: 2px; padding-bottom: 2px;"
                                            ></td>
                                            <td
                                                class="font-w700 p-1"
                                                style="text-align: right; padding-top: 2px; padding-bottom: 2px; white-space: nowrap;"
                                                v-if="
                                                    level1.account_type ==
                                                        'Equity'
                                                "
                                            >
                                                <span
                                                    v-if="
                                                        level1.children
                                                            .length == 0 ||
                                                            balanceSheet.level ==
                                                                1
                                                    "
                                                    style="border-bottom:2px solid gray;"
                                                >
                                                    {{
                                                        level1.ending_balance
                                                            | currency
                                                    }}
                                                </span>
                                            </td>
                                            <td
                                                class="font-w700 p-1"
                                                style="text-align: right; padding-top: 2px; padding-bottom: 2px; white-space: nowrap;"
                                                v-else
                                            >
                                                <span
                                                    v-if="
                                                        level1.children
                                                            .length == 0 ||
                                                            balanceSheet.level ==
                                                                1
                                                    "
                                                >
                                                    {{
                                                        level1.ending_balance
                                                            | currency
                                                    }}
                                                </span>
                                            </td>
                                        </tr>
                                        <template
                                            v-for="level2 in level1.children"
                                        >
                                            <tr
                                                class="py-1"
                                                :key="level2.index"
                                                v-if="
                                                    balanceSheet.level >= 2 &&
                                                        level2.ending_balance !=
                                                            0
                                                "
                                            >
                                                <td
                                                    style="padding-top: 2px; padding-bottom: 2px;"
                                                ></td>
                                                <td
                                                    class="padding-top: 2px; padding-bottom: 2px;"
                                                    style="padding-top: 2px; padding-bottom: 2px;"
                                                >
                                                    {{ level2.account_name }}
                                                </td>
                                                <td
                                                    style="padding-top: 2px; padding-bottom: 2px;"
                                                ></td>
                                                <td
                                                    style="padding-top: 2px; padding-bottom: 2px;"
                                                ></td>
                                                <td
                                                    style="padding-top: 2px; padding-bottom: 2px;"
                                                ></td>
                                                <td
                                                    style="padding-top: 2px; padding-bottom: 2px;"
                                                ></td>
                                                <td
                                                    style="text-align: right; padding-top: 2px; padding-bottom: 2px; white-space: nowrap;"
                                                >
                                                    <span
                                                        v-if="
                                                            level2.children
                                                                .length == 0 ||
                                                                balanceSheet.level ==
                                                                    2
                                                        "
                                                    >
                                                        {{
                                                            level2.ending_balance
                                                                | currency
                                                        }}
                                                    </span>
                                                </td>
                                                <td
                                                    style="padding-top: 2px; padding-bottom: 2px;"
                                                ></td>
                                            </tr>
                                            <template
                                                v-for="level3 in level2.children"
                                            >
                                                <tr
                                                    class="py-1"
                                                    :key="level3.index"
                                                    v-if="
                                                        balanceSheet.level >=
                                                            3 &&
                                                            level3.ending_balance !=
                                                                0
                                                    "
                                                >
                                                    <td
                                                        style="padding-top: 2px; padding-bottom: 2px;"
                                                    ></td>
                                                    <td
                                                        style="padding-top: 2px; padding-bottom: 2px;"
                                                    ></td>
                                                    <td
                                                        class="padding-top: 2px; padding-bottom: 2px;"
                                                        style="padding-top: 2px; padding-bottom: 2px;"
                                                    >
                                                        {{
                                                            level3.account_name
                                                        }}
                                                    </td>
                                                    <td
                                                        style="padding-top: 2px; padding-bottom: 2px;"
                                                    ></td>
                                                    <td
                                                        style="padding-top: 2px; padding-bottom: 2px;"
                                                    ></td>
                                                    <td
                                                        style="text-align: right; padding-top: 2px; padding-bottom: 2px; white-space: nowrap;"
                                                    >
                                                        <span
                                                            v-if="
                                                                level3.children
                                                                    .length ==
                                                                    0 ||
                                                                    balanceSheet.level ==
                                                                        3
                                                            "
                                                        >
                                                            {{
                                                                level3.ending_balance
                                                                    | currency
                                                            }}
                                                        </span>
                                                    </td>
                                                    <td
                                                        style="padding-top: 2px; padding-bottom: 2px;"
                                                    ></td>
                                                    <td
                                                        style="padding-top: 2px; padding-bottom: 2px;"
                                                    ></td>
                                                </tr>
                                                <template
                                                    v-for="level4 in level3.children"
                                                >
                                                    <tr
                                                        class="py-1"
                                                        :key="level4.index"
                                                        v-if="
                                                            balanceSheet.level >=
                                                                4 &&
                                                                level4.ending_balance !==
                                                                    0
                                                        "
                                                    >
                                                        <td
                                                            style="padding-top: 2px; padding-bottom: 2px;"
                                                        ></td>
                                                        <td
                                                            style="padding-top: 2px; padding-bottom: 2px;"
                                                        ></td>
                                                        <td
                                                            style="padding-top: 2px; padding-bottom: 2px;"
                                                        ></td>
                                                        <td
                                                            class="padding-top: 2px; padding-bottom: 2px;"
                                                            style="padding-top: 2px; padding-bottom: 2px;"
                                                        >
                                                            {{
                                                                level4.account_name
                                                            }}
                                                        </td>
                                                        <td
                                                            style="text-align: right; padding-top: 2px; padding-bottom: 2px; white-space: nowrap;"
                                                        >
                                                            <span
                                                                v-if="
                                                                    level4
                                                                        .children
                                                                        .length ==
                                                                        0 ||
                                                                        balanceSheet.level ==
                                                                            4
                                                                "
                                                            >
                                                                {{
                                                                    level4.ending_balance
                                                                        | currency
                                                                }}
                                                            </span>
                                                        </td>
                                                        <td
                                                            style="padding-top: 2px; padding-bottom: 2px;"
                                                        ></td>
                                                        <td
                                                            style="padding-top: 2px; padding-bottom: 2px;"
                                                        ></td>
                                                        <td
                                                            style="padding-top: 2px; padding-bottom: 2px;"
                                                        ></td>
                                                    </tr>
                                                </template>
                                                <!-- Total Level 3 -->
                                                <tr
                                                    class="py-1"
                                                    :key="level3.index"
                                                    v-if="
                                                        level3.children.length >
                                                            0 &&
                                                            balanceSheet.level >
                                                                3 &&
                                                            level2.ending_balance !=
                                                                0
                                                    "
                                                >
                                                    <td
                                                        style="padding-top: 2px; padding-bottom: 2px;"
                                                    ></td>
                                                    <td
                                                        style="padding-top: 2px; padding-bottom: 2px;"
                                                    ></td>
                                                    <td
                                                        style="padding-top: 2px; padding-bottom: 2px;"
                                                    >
                                                        TOTAL
                                                        {{
                                                            level3.account_name
                                                        }}
                                                    </td>
                                                    <td
                                                        style="padding-top: 2px; padding-bottom: 2px;"
                                                    ></td>
                                                    <td
                                                        style="padding-top: 2px; padding-bottom: 2px;"
                                                    ></td>
                                                    <td
                                                        style="text-align: right; padding-top: 2px; padding-bottom: 2px; white-space: nowrap;"
                                                    >
                                                        <span
                                                            style="border-bottom:1px solid gray;"
                                                        >
                                                            {{
                                                                level3.ending_balance
                                                                    | currency
                                                            }}
                                                        </span>
                                                    </td>
                                                    <td
                                                        style="padding-top: 2px; padding-bottom: 2px;"
                                                    ></td>
                                                    <td
                                                        style="padding-top: 2px; padding-bottom: 2px;"
                                                    ></td>
                                                </tr>
                                            </template>
                                            <!-- Total Level 2 -->
                                            <tr
                                                class="py-1"
                                                :key="level2.index"
                                                v-if="
                                                    level2.children.length >
                                                        0 &&
                                                        balanceSheet.level >
                                                            2 &&
                                                        level2.ending_balance !=
                                                            0
                                                "
                                            >
                                                <td
                                                    style="padding-top: 2px; padding-bottom: 2px;"
                                                ></td>
                                                <td
                                                    style="padding-top: 2px; padding-bottom: 2px;"
                                                >
                                                    TOTAL
                                                    {{ level2.account_name }}
                                                </td>
                                                <td
                                                    style="padding-top: 2px; padding-bottom: 2px;"
                                                ></td>
                                                <td
                                                    style="padding-top: 2px; padding-bottom: 2px;"
                                                ></td>
                                                <td
                                                    style="padding-top: 2px; padding-bottom: 2px;"
                                                ></td>
                                                <td
                                                    style="padding-top: 2px; padding-bottom: 2px;"
                                                ></td>
                                                <td
                                                    style="text-align: right; padding-top: 2px; padding-bottom: 2px; white-space: nowrap;"
                                                >
                                                    <span
                                                        style="border-bottom:1px solid gray;"
                                                    >
                                                        {{
                                                            level2.ending_balance
                                                                | currency
                                                        }}
                                                    </span>
                                                </td>
                                                <td
                                                    style="padding-top: 2px; padding-bottom: 2px;"
                                                ></td>
                                            </tr>
                                        </template>
                                        <!-- Total Level 1 -->
                                        <tr
                                            class="p-1"
                                            :key="level1.index"
                                            v-if="
                                                level1.children.length > 0 &&
                                                    balanceSheet.level > 1
                                            "
                                        >
                                            <td
                                                class="font-w700"
                                                style="padding-top: 2px; padding-bottom: 2px;"
                                            >
                                                TOTAL {{ level1.account_name }}
                                            </td>
                                            <td
                                                style="padding-top: 2px; padding-bottom: 2px;"
                                            ></td>
                                            <td
                                                style="padding-top: 2px; padding-bottom: 2px;"
                                            ></td>
                                            <td
                                                style="padding-top: 2px; padding-bottom: 2px;"
                                            ></td>
                                            <td
                                                style="padding-top: 2px; padding-bottom: 2px;"
                                            ></td>
                                            <td
                                                style="padding-top: 2px; padding-bottom: 2px;"
                                            ></td>
                                            <td
                                                style="padding-top: 2px; padding-bottom: 2px;"
                                            ></td>
                                            <td
                                                class="font-w700"
                                                style="text-align: right; padding-top: 2px; padding-bottom: 2px; white-space: nowrap;"
                                            >
                                                <span
                                                    style="border-bottom:2px solid gray;"
                                                >
                                                    {{
                                                        level1.ending_balance
                                                            | currency
                                                    }}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr class="py-1" :key="level1.index">
                                            <td
                                                colspan="8"
                                                style="padding-top: 2px; padding-bottom: 2px;"
                                            >
                                                &nbsp;
                                            </td>
                                        </tr>
                                        <!-- Gross Profit -->
                                        <tr
                                            class="bg-body-light p-1"
                                            style="border-bottom:1px solid #f9f9f9 ;"
                                            :key="level1.index"
                                            v-if="
                                                level1.account_type ==
                                                    'Liability'
                                            "
                                        >
                                            <td
                                                class="font-w700"
                                                style="padding-top: 2px; padding-bottom: 2px;"
                                            >
                                                NET HARTA
                                            </td>
                                            <td
                                                style="padding-top: 2px; padding-bottom: 2px;"
                                            ></td>
                                            <td
                                                style="padding-top: 2px; padding-bottom: 2px;"
                                            ></td>
                                            <td
                                                style="padding-top: 2px; padding-bottom: 2px;"
                                            ></td>
                                            <td
                                                style="padding-top: 2px; padding-bottom: 2px;"
                                            ></td>
                                            <td
                                                style="padding-top: 2px; padding-bottom: 2px;"
                                            ></td>
                                            <td
                                                style="padding-top: 2px; padding-bottom: 2px;"
                                            ></td>
                                            <td
                                                class="font-w700 p-1"
                                                style="text-align: right; padding-top: 2px; padding-bottom: 2px; white-space: nowrap;"
                                            >
                                                <span
                                                    style="border-bottom:2px solid gray;"
                                                >
                                                    {{ netAsset | currency }}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr
                                            class="py-1"
                                            :key="level1.index"
                                            v-if="
                                                level1.account_type ==
                                                    'Liability'
                                            "
                                        >
                                            <td
                                                colspan="8"
                                                style="padding-top: 2px; padding-bottom: 2px;"
                                            >
                                                &nbsp;
                                            </td>
                                        </tr>
                                    </template>
                                </table>
                            </div>
                            <!-- END Table -->
                            <hr />

                            <div class="row mt-4 mb-5">
                                <div class="col-6 text-left font-size-sm">
                                    <div class="form-group d-print-none">
                                        <label for="example-text-input"
                                            >Post Note *</label
                                        >
                                        <ckeditor
                                            tag-name="textarea"
                                            :editor="editor"
                                            v-model="docPostNote"
                                            :config="editorConfig"
                                        ></ckeditor>
                                    </div>
                                    <span
                                        class="d-none d-print-inline-block"
                                        v-html="docPostNote"
                                    ></span>
                                </div>
                                <div class="col-6 font-size-sm text-right">
                                    <div
                                        class="form-group text-left d-print-none"
                                    >
                                        <label for="example-text-input"
                                            >Choose Signature *</label
                                        >
                                        <selectize
                                            class="form-control"
                                            placeholder="Choose Signature"
                                            v-model="selectedSignature"
                                            @input="
                                                selectSignature(
                                                    selectedSignature
                                                )
                                            "
                                        >
                                            <option
                                                v-for="signature in signatures.data"
                                                :value="signature.id"
                                                :key="signature.id"
                                                >{{
                                                    signature.signature_name
                                                }}</option
                                            >
                                        </selectize>
                                    </div>
                                    <p
                                        class="mb-0 font-w700 mt-6 ml-9 text-center"
                                        style="border-bottom:1px solid grey"
                                        v-bind:class="{
                                            'd-print-none':
                                                selectedSignature == ''
                                        }"
                                    >
                                        {{ docSignature.signature_name }}
                                    </p>
                                    <p
                                        class="mb-0 font-w700 ml-9 text-center"
                                        v-bind:class="{
                                            'd-print-none':
                                                selectedSignature == ''
                                        }"
                                    >
                                        {{ docSignature.signature_position }}
                                    </p>
                                </div>
                            </div>

                            <hr />
                            <p
                                class="d-none d-print-inline-block"
                                style="opacity:0.6;"
                            >
                                <i> Printed : {{ dateNow }} </i>
                            </p>
                            <!-- END Footer -->
                        </div>
                    </div>
                </div>
                <!-- END Invoice -->
            </div>
        </div>
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import moment from "moment";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
    name: "balance-sheet-display",

    data() {
        return {
            loadingPage: 1,
            useHeader: true,
            dateNow: moment().format("MMMM Do YYYY, HH:mm:ss"),
            selectedSignature: "",
            assetTotal: 0,
            liabilityTotal: 0,
            modalTotal: 0,
            netAsset: 0,
            docSignature: {
                signature_name: "[select signature]",
                signature_position: "[select signature]"
            },
            editor: ClassicEditor,
            editorConfig: {
                toolbar: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "bulletedList",
                    "numberedList",
                    "blockQuote",
                    "undo",
                    "redo",
                    "insertTable"
                ]
            },
            docPostNote: "",
            datas: {}
        };
    },
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState("coa", { coas: state => state.coas.accounts }),
        ...mapState(["auth"]),
        ...mapState("report", { balanceSheet: state => state.balanceSheet }),
        ...mapState("signature", { signatures: state => state.signatures })
    },
    mounted() {
        if (
            this.$route.params.date_start &&
            this.$route.params.date_end &&
            this.$route.params.account_level && 
            this.$route.params.type
        ) {
            this.generateReportBS({
                date_start: this.$route.params.date_start,
                date_end: this.$route.params.date_end,
                account_level: this.$route.params.account_level,
                type: this.$route.params.type
            })
                .then(response => {
                    this.convertArray(this.balanceSheet.data);
                    this.loadingPage = 0;
                })
                .catch(error => {
                    console.log(error);
                    this.loadingPage = 0;
                });
            this.getSignatureDocument({ document: "signature_balance_sheet" });
        } else {
            this.$router.push({ name: "balance-sheet" });
            this.alert("Select Type, Date & Account First", 2);
        }
    },
    methods: {
        ...mapActions("coa", ["getCoaAll"]),
        ...mapActions("report", [
            "pdfReportGL",
            "xlsReportGL",
            "generateReportBS"
        ]),
        ...mapMutations("report", ["CLEAR_PL"]),
        ...mapActions("signature", ["getSignatureDocument"]),
        convertArray(arr) {
            var map = {},
                node,
                roots = [],
                i;
            for (i = 0; i < arr.length; i += 1) {
                map[arr[i].id] = i; // initialize the map
                arr[i].children = []; // initialize the children
            }

            for (i = 0; i < arr.length; i += 1) {
                node = arr[i];
                if (node.account_head !== null) {
                    // if you have dangling branches check that map[node.parentId] exists
                    arr[map[node.account_head]].children.push(node);
                } else {
                    roots.push(node);
                    if (node.account_type == "Asset") {
                        this.assetTotal =
                            this.assetTotal + parseInt(node.ending_balance);
                    }
                    if (node.account_type == "Liability") {
                        this.liabilityTotal =
                            this.liabilityTotal + parseInt(node.ending_balance);
                    }
                    if (node.account_type == "Equity") {
                        this.equityTotal =
                            this.equityTotal + parseInt(node.ending_balance);
                    }
                }
            }
            this.netAsset = this.assetTotal - this.liabilityTotal;
            this.datas = roots;
        },

        selectSignature(val) {
            if (val != "") {
                var result = this.signatures.data.find(obj => {
                    return obj.id === parseInt(val);
                });
                this.docSignature.signature_name = result.signature_name;
                this.docSignature.signature_position =
                    result.signature_position;
            } else {
                this.docSignature.signature_name = "[select signature]";
                this.docSignature.signature_position = "[select signature]";
            }
        },
        errorLogoUrl(event) {
            event.target.src = "/media/workspace/no-logo.jpg";
        },
        //METHOD
        print() {
            var browserName = navigator.appName;
            if (browserName == "Safari") {
                var printContents = document.getElementById("printMe")
                    .innerHTML;
                var originalContents = document.body.innerHTML;
                document.body.innerHTML = printContents;
                window.print();
                document.body.innerHTML = originalContents;
            } else {
                this.$htmlToPaper("printMe");
            }
        },
        refresh() {
            this.loadingPage = 2;
            this.datas = {};
            (this.assetTotal = 0),
                (this.liabilityTotal = 0),
                (this.modalTotal = 0),
                (this.netAsset = 0),
                this.generateReportBS({
                    date_start: this.$route.params.date_start,
                    date_end: this.$route.params.date_end,
                    account_level: this.$route.params.account_level,
                    type: this.$route.params.type
                })
                    .then(response => {
                        this.convertArray(this.balanceSheet.data);
                        this.loadingPage = 0;
                    })
                    .catch(error => {
                        console.log(error);
                        this.loadingPage = 0;
                    });
        }
    }
};
</script>
