<template>
    <main>
        <hero
            title="Edit Purchase"
            prevBreadcrumb="Purchase Register"
            routeBreadcrumb="purchase-register"
            currentBreadcrumb="Edit Purchase"
        ></hero>

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 1"
                style="height:250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity:0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 2"
                style="height:250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity:0.7"
                            >Save Data</span
                        >
                    </center>
                </div>
            </div>
            <!-- Basic -->
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 0"
            >
                <v-tabs
                    background-color="white"
                    style="border-bottom: 1px solid #ebebeb;"
                >
                    <v-tab
                        v-for="label in labels"
                        :key="label"
                        @click="changeType(label)"
                        v-bind:class="{
                            'v-tab--active': label == type,
                            'v-tab--active--false': label !== type
                        }"
                    >
                        {{ label }}
                    </v-tab>
                </v-tabs>
                <Notification></Notification>
                <form
                    method="POST"
                    enctype="multipart/form-data"
                    onsubmit="return false;"
                >
                    <div class="block-content block-content-full">
                        <!--Form-->
                        <div class="row font-size-sm">
                            <div class="col-12 py-0">
                                <!-- BIIL INFORMATION -->
                                <div class="row">
                                    <div class="col-12 col-lg-6 py-0">
                                        <label class="mb-0" style="opacity:0.7"
                                            >GENERAL</label
                                        >
                                        <hr class="mt-2 mb-3" />
                                        <div class="row">
                                            <div class="col-7 pb-1 pt-0">
                                                <div class="form-group mb-1">
                                                    <label
                                                        for="example-text-input"
                                                        >Supplier Name*</label
                                                    >
                                                    <selectize
                                                        class="form-control mb-1"
                                                        placeholder="Supplier Name"
                                                        v-model="
                                                            purchase.bill_contact_id
                                                        "
                                                        :class="{
                                                            'is-invalid-selectize':
                                                                errors.bill_contact_id
                                                        }"
                                                        :disabled="
                                                            purchase.bill_paid_total !=
                                                                0 ||
                                                                purchase.forbidden ==
                                                                    true ||
                                                                purchase.bill_status ==
                                                                    5 ||
                                                                purchase.bill_status ==
                                                                    4
                                                        "
                                                        @input="
                                                            getContactDetail(
                                                                purchase.bill_contact_id
                                                            )
                                                        "
                                                    >
                                                        <!-- settings is optional -->
                                                        <option
                                                            v-for="contact in contacts"
                                                            :value="contact.id"
                                                            :key="contact.id"
                                                            >{{
                                                                contact.contact_name
                                                            }}</option
                                                        >
                                                    </selectize>
                                                    <transition
                                                        name="slide-fade"
                                                    >
                                                        <p
                                                            v-if="
                                                                errors.bill_contact_id
                                                            "
                                                            class="text-danger pb-0 mb-1"
                                                        >
                                                            {{
                                                                errors
                                                                    .bill_contact_id[0]
                                                            }}
                                                        </p>
                                                    </transition>
                                                </div>
                                            </div>
                                            <div class="col-5 pb-1 pt-0">
                                                <div class="form-group mb-1">
                                                    <label
                                                        for="example-text-input"
                                                        >Beginning
                                                        Balance</label
                                                    >
                                                    <money
                                                        class="form-control"
                                                        style="text-align: right;"
                                                        v-model="
                                                            purchase.bill_contact_beginning_balance
                                                        "
                                                        spellcheck="false"
                                                        disabled
                                                    ></money>
                                                    <transition
                                                        name="slide-fade"
                                                    >
                                                        <p
                                                            class="text-danger pb-0 mb-1"
                                                            v-if="
                                                                errors.bill_contact_beginning_balance
                                                            "
                                                        >
                                                            {{
                                                                errors
                                                                    .bill_contact_beginning_balance[0]
                                                            }}
                                                        </p>
                                                    </transition>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 py-1">
                                                <div class="form-group">
                                                    <label
                                                        for="example-text-input"
                                                        >Supplier Address
                                                        *</label
                                                    >
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        placeholder="Supplier Address"
                                                        v-model="
                                                            purchase.bill_shipaddress
                                                        "
                                                        :class="{
                                                            'is-invalid':
                                                                errors.bill_shipaddress
                                                        }"
                                                        :disabled="
                                                            purchase.bill_paid_total !=
                                                                0 ||
                                                                purchase.forbidden ==
                                                                    true ||
                                                                purchase.bill_status ==
                                                                    5 ||
                                                                purchase.bill_status ==
                                                                    4
                                                        "
                                                    />
                                                    <transition
                                                        name="slide-fade"
                                                    >
                                                        <p
                                                            class="text-danger pb-0 mb-1"
                                                            v-if="
                                                                errors.bill_shipaddress
                                                            "
                                                        >
                                                            {{
                                                                errors
                                                                    .bill_shipaddress[0]
                                                            }}
                                                        </p>
                                                    </transition>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-6 py-0">
                                        <label class="mb-0" style="opacity:0.7"
                                            >BILL INFORMATION</label
                                        >
                                        <hr class="mt-2 mb-3" />

                                        <div class="row">
                                            <div class="col-6 pb-1 pt-0">
                                                <div class="form-group mb-2">
                                                    <label
                                                        for="example-text-input"
                                                        >Issued On *</label
                                                    >
                                                    <date-picker
                                                        class="input-group"
                                                        v-model="
                                                            purchase.bill_date
                                                        "
                                                        format="DD/MM/YYYY"
                                                        valueType="YYYY-MM-DD"
                                                        placeholder="Issued On"
                                                        :class="{
                                                            'is-invalid':
                                                                errors.bill_date
                                                        }"
                                                        :default-value="
                                                            new Date()
                                                        "
                                                        :disabled-date="
                                                            disabledDatePicker
                                                        "
                                                        :disabled="
                                                            purchase.bill_status ==
                                                                4 ||
                                                                purchase
                                                                    .reference_invoice
                                                                    .length >
                                                                    0 ||
                                                                purchase.bill_paid_total !=
                                                                    0 ||
                                                                purchase.forbidden ==
                                                                    true ||
                                                                purchase.bill_status ==
                                                                    5
                                                        "
                                                        @input="changeDate()"
                                                    ></date-picker>
                                                    <transition
                                                        name="slide-fade"
                                                    >
                                                        <p
                                                            class="text-danger pb-0 mb-1"
                                                            v-if="
                                                                errors.bill_date
                                                            "
                                                        >
                                                            {{
                                                                errors
                                                                    .bill_date[0]
                                                            }}
                                                        </p>
                                                    </transition>
                                                </div>
                                            </div>
                                            <div class="col-6 pb-1 pt-0">
                                                <div class="form-group mb-2">
                                                    <label
                                                        for="example-text-input"
                                                        v-if="
                                                            this.type !=
                                                                'Invoice'
                                                        "
                                                        >Due On
                                                        (Optional)</label
                                                    >
                                                    <label
                                                        for="example-text-input"
                                                        v-else
                                                        >Due On *
                                                    </label>
                                                    <date-picker
                                                        class="input-group"
                                                        v-model="
                                                            purchase.bill_due_date
                                                        "
                                                        format="DD/MM/YYYY"
                                                        valueType="YYYY-MM-DD"
                                                        placeholder="Due On"
                                                        :class="{
                                                            'is-invalid':
                                                                errors.bill_due_date
                                                        }"
                                                        :default-value="
                                                            new Date()
                                                        "
                                                        :disabled-date="
                                                            disabledDatePicker
                                                        "
                                                        :disabled="
                                                            purchase.bill_status ==
                                                                4 ||
                                                                purchase
                                                                    .reference_invoice
                                                                    .length >
                                                                    0 ||
                                                                purchase.bill_paid_total !=
                                                                    0 ||
                                                                purchase.forbidden ==
                                                                    true ||
                                                                purchase.bill_status ==
                                                                    5
                                                        "
                                                    ></date-picker>
                                                    <transition
                                                        name="slide-fade"
                                                    >
                                                        <p
                                                            class="text-danger pb-0 mb-1"
                                                            v-if="
                                                                errors.bill_due_date
                                                            "
                                                        >
                                                            {{
                                                                errors
                                                                    .bill_due_date[0]
                                                            }}
                                                        </p>
                                                        <p
                                                            class="text-danger pb-0 mb-0"
                                                            v-if="
                                                                purchase.bill_due_date !=
                                                                    null &&
                                                                    purchase.bill_due_date !=
                                                                        '' &&
                                                                    purchase.bill_due_date <
                                                                        purchase.bill_date
                                                            "
                                                        >
                                                            Purchases Due Date
                                                            lower than Purchases
                                                            Date
                                                        </p>
                                                    </transition>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6 py-1">
                                                <div
                                                    class="form-group"
                                                    v-if="type == 'Quotation'"
                                                >
                                                    <label
                                                        for="example-text-input"
                                                        >Purchase Quotation
                                                        Number *</label
                                                    >
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        placeholder="Purchase Quotation Number"
                                                        v-model="
                                                            purchase.bill_quotation_number
                                                        "
                                                        :class="{
                                                            'is-invalid':
                                                                errors.bill_number
                                                        }"
                                                        :disabled="
                                                            purchase.bill_paid_total !=
                                                                0 ||
                                                                purchase.forbidden ==
                                                                    true ||
                                                                purchase.bill_status ==
                                                                    5 ||
                                                                purchase.bill_status ==
                                                                    4
                                                        "
                                                    />
                                                    <transition
                                                        name="slide-fade"
                                                    >
                                                        <p
                                                            class="text-danger pb-0 mb-1"
                                                            v-if="
                                                                errors.bill_number
                                                            "
                                                        >
                                                            {{
                                                                errors
                                                                    .bill_number[0]
                                                            }}
                                                        </p>
                                                    </transition>
                                                </div>
                                                <div
                                                    class="form-group"
                                                    v-if="type == 'Order'"
                                                >
                                                    <label
                                                        for="example-text-input"
                                                        >Purchase Order Number
                                                        *</label
                                                    >
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        placeholder="Purchase Order Number"
                                                        v-model="
                                                            purchase.bill_order_number
                                                        "
                                                        :class="{
                                                            'is-invalid':
                                                                errors.bill_number
                                                        }"
                                                        :disabled="
                                                            purchase.bill_paid_total !=
                                                                0 ||
                                                                purchase.forbidden ==
                                                                    true ||
                                                                purchase.bill_status ==
                                                                    5 ||
                                                                purchase.bill_status ==
                                                                    4
                                                        "
                                                    />
                                                    <transition
                                                        name="slide-fade"
                                                    >
                                                        <p
                                                            class="text-danger pb-0 mb-1"
                                                            v-if="
                                                                errors.bill_number
                                                            "
                                                        >
                                                            {{
                                                                errors
                                                                    .bill_number[0]
                                                            }}
                                                        </p>
                                                    </transition>
                                                </div>
                                                <div
                                                    class="form-group"
                                                    v-if="type == 'Invoice'"
                                                >
                                                    <label
                                                        for="example-text-input"
                                                        >Purchase Invoice Number
                                                        *</label
                                                    >
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        placeholder="Purchase Invoice Number"
                                                        v-model="
                                                            purchase.bill_invoice_number
                                                        "
                                                        :class="{
                                                            'is-invalid':
                                                                errors.bill_number
                                                        }"
                                                        :disabled="
                                                            purchase.bill_paid_total !=
                                                                0 ||
                                                                purchase.forbidden ==
                                                                    true ||
                                                                purchase.bill_status ==
                                                                    5 ||
                                                                purchase.bill_status ==
                                                                    4
                                                        "
                                                    />
                                                    <transition
                                                        name="slide-fade"
                                                    >
                                                        <p
                                                            class="text-danger pb-0 mb-1"
                                                            v-if="
                                                                errors.bill_number
                                                            "
                                                        >
                                                            {{
                                                                errors
                                                                    .bill_number[0]
                                                            }}
                                                        </p>
                                                    </transition>
                                                </div>
                                            </div>
                                            <div
                                                class="col-6 py-1"
                                                v-if="
                                                    type == 'Invoice' &&
                                                        purchase.reference_order
                                                "
                                            >
                                                <div class="form-group mb-3">
                                                    <label
                                                        for="example-text-input"
                                                        >Order Number
                                                    </label>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        placeholder="Purchase Invoice Number"
                                                        v-model="
                                                            purchase
                                                                .reference_order
                                                                .bill_number
                                                        "
                                                        :class="{
                                                            'is-invalid':
                                                                errors.reference_order
                                                        }"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="row"
                                            v-if="type == 'Invoice'"
                                        >
                                            <div class="col-6 pb-1 pt-0">
                                                <transition name="slide-fade">
                                                    <div class="form-group">
                                                        <label
                                                            for="example-text-input"
                                                            >Account for
                                                            Tracking Payable
                                                            *</label
                                                        >
                                                        <selectize
                                                            class="form-control"
                                                            placeholder="Choose Account No."
                                                            v-model="
                                                                purchase.bill_coa_id
                                                            "
                                                            :class="{
                                                                'is-invalid-selectize':
                                                                    errors.bill_coa_id
                                                            }"
                                                            :disabled="
                                                                purchase.bill_paid_total !=
                                                                    0 ||
                                                                    purchase.forbidden ==
                                                                        true ||
                                                                    purchase.bill_status ==
                                                                        5 ||
                                                                    purchase.bill_status ==
                                                                        4
                                                            "
                                                        >
                                                            <!-- settings is optional -->
                                                            <option
                                                                v-for="account in payableAccounts"
                                                                :value="
                                                                    account.id
                                                                "
                                                                :key="
                                                                    account.id
                                                                "
                                                            >
                                                                {{
                                                                    account.account_no
                                                                }}
                                                                {{
                                                                    account.account_name
                                                                }}
                                                            </option>
                                                        </selectize>
                                                        <transition
                                                            name="slide-fade"
                                                        >
                                                            <p
                                                                class="text-danger pb-0 mb-1"
                                                                v-if="
                                                                    errors.bill_coa_id
                                                                "
                                                            >
                                                                {{
                                                                    errors
                                                                        .bill_coa_id[0]
                                                                }}
                                                            </p>
                                                        </transition>
                                                    </div>
                                                </transition>
                                            </div>
                                            <div class="col-6 pt-0">
                                                <transition name="slide-fade">
                                                    <div class="form-group">
                                                        <label
                                                            for="example-text-input"
                                                            >Journal Memo
                                                            *</label
                                                        >
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            placeholder="Journal Memo"
                                                            v-model="
                                                                purchase.bill_journal_memo
                                                            "
                                                            :class="{
                                                                'is-invalid':
                                                                    errors.bill_journal_memo
                                                            }"
                                                            :disabled="
                                                                purchase.bill_paid_total !=
                                                                    0 ||
                                                                    purchase.forbidden ==
                                                                        true ||
                                                                    purchase.bill_status ==
                                                                        5 ||
                                                                    purchase.bill_status ==
                                                                        4
                                                            "
                                                        />
                                                        <transition
                                                            name="slide-fade"
                                                        >
                                                            <p
                                                                class="text-danger pb-0 mb-1"
                                                                v-if="
                                                                    errors.bill_journal_memo
                                                                "
                                                            >
                                                                {{
                                                                    errors
                                                                        .bill_journal_memo[0]
                                                                }}
                                                            </p>
                                                        </transition>
                                                    </div>
                                                </transition>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr class="mt-3 mb-4 border-dash" />
                                <!-- ASSOCIATE TRANSACTION -->
                                <template
                                    v-if="
                                        this.purchase
                                            .bill_transaction_from_order
                                            .length > 0
                                    "
                                >
                                    <div class="row py-2 text-muted px-2">
                                        <label class="mb-0" style="opacity:0.7"
                                            >ASSOCIATED TO ORDER NO.
                                            {{ associatedRefNo }}</label
                                        >
                                    </div>
                                    <EditAssociateTransaction></EditAssociateTransaction>
                                    <div class="row py-2 text-muted px-2">
                                        <label class="mb-0" style="opacity:0.7"
                                            >OTHER TRANSACTION</label
                                        >
                                    </div>
                                </template>

                                <!-- CURRENT TRANSACTION-->
                                <EditTransaction></EditTransaction>
                            </div>
                        </div>
                        <!--END Form-->
                        <div class="row px-2">
                            <div
                                class="col-12 col-md-4 offset-md-8"
                                style="
                                    background-color: #e9ecef !important;
                                    width: 350px;
                                    padding-top: 10px;
                                "
                            >
                                <strong>
                                    <p class="mb-2 font-w700">
                                        Subtotal
                                        <span class="float-right">{{
                                            purchase.bill_sub_total | currency
                                        }}</span>
                                    </p>
                                </strong>
                                <p class="mb-2">
                                    Tax Total
                                    <small class="float-right">{{
                                        purchase.bill_tax_total | currency
                                    }}</small>
                                </p>
                                <strong>
                                    <p class="mb-2 font-w700">
                                        Grand Total
                                        <span class="float-right">{{
                                            purchase.bill_grand_total | currency
                                        }}</span>
                                    </p>
                                </strong>
                            </div>
                        </div>
                    </div>
                    <div
                        class="block-footer px-2"
                        style="background-color:#F9F9F9"
                    >
                        <!-- Submit -->
                        <div class="row items-push">
                            <div class="col-12 text-right mb-2">
                                <router-link
                                    type="button"
                                    class="btn btn-sm btn-alt-light mb-1 mr-1"
                                    tag="button"
                                    v-ripple="{ center: true }"
                                    :to="{ name: 'purchase-register' }"
                                >
                                    <i class="fa fa-fw fa-times mr-1"></i>
                                    Cancel
                                </router-link>
                                <button
                                    type="button"
                                    v-if="
                                        !forbiddenData &&
                                            purchase.bill_paid_total == 0
                                    "
                                    class="btn btn-sm btn-info mb-1 mr-1"
                                    v-ripple="{ center: true }"
                                    :disabled="
                                        (purchase.bill_due_date != '' &&
                                            purchase.bill_due_date <
                                                purchase.bill_date) ||
                                            purchase.bill_status == 4 ||
                                            purchase.reference_invoice.length >
                                                0 ||
                                            purchase.bill_status == 5
                                    "
                                    @click="review()"
                                >
                                    <i class="fa fa-fw fa-file-alt mr-1"></i>
                                    Review
                                </button>
                                <button
                                    type="button"
                                    v-if="
                                        !forbiddenData &&
                                            purchase.bill_paid_total == 0
                                    "
                                    :disabled="
                                        purchase.bill_status == 4 ||
                                            purchase.reference_invoice.length >
                                                0 ||
                                            purchase.bill_status == 5
                                    "
                                    class="btn btn-sm btn-danger mb-1"
                                    v-ripple="{ center: true }"
                                    @click="deleteData()"
                                >
                                    <i class="fa fa-fw fa-trash mr-1"></i>
                                    Delete
                                </button>
                            </div>
                        </div>
                        <!-- END Submit -->
                    </div>
                </form>
            </div>
            <!-- END Basic -->
        </div>

        <modal
            name="review-purchase"
            width="95%"
            classes="modal-proxima-lg block-rounded block-mode-loading-oneui"
            height="auto"
            @before-close="clearModal()"
            adaptive
        >
            <Review :data="this.purchase" :type="'update'"></Review>
        </modal>
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import moment from "moment";
import Review from "./component/Review.vue";
import EditAssociateTransaction from "./component/EditAssociateTransaction.vue";
import EditTransaction from "./component/EditTransaction.vue";
import Notification from "./component/Notification.vue";

export default {
    name: "edit-bills",
    components: {
        Review,
        EditAssociateTransaction,
        EditTransaction,
        Notification
    },
    data() {
        return {
            loadingPage: 1,
            type: "Quotation",
            labels: ["Quotation", "Order", "Invoice"],
            disabled: {},
            coaPurchases: [],
            forbiddenData: false,
            visible: false
        };
    },
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState("purchase", { purchase: state => state.purchase }),
        ...mapState("coa", { coas: state => state.coas.accounts }),
        ...mapState("coa", { coalink: state => state.coalink }),
        ...mapState("contact", { contacts: state => state.contacts }),
        ...mapState("item", { items: state => state.items }),
        ...mapState("tax", { taxs: state => state.taxs }),
        ...mapState("forbiddendate", {
            forbiddenDateLast: state => state.forbiddenDateLast
        }),
        disableSave() {
            if (this.purchase.bill_due_date < this.purchase.bill_date) {
                return true;
            }
            if (this.purchase.bill_grand_total == 0) {
                return true;
            }
        },
        payableAccounts: function() {
            if (this.coas) {
                return this.coas.filter(function(coa) {
                    return (
                        coa.account_type === "Account Payable" ||
                        coa.account_type === "Bank" ||
                        coa.account_type === "Other Liability" ||
                        coa.account_type === "Other Current Liability" ||
                        coa.account_type === "Long Term Liability"
                    );
                });
            }
        },
        transactionAccounts: function() {
            if (this.coas) {
                return this.coas.filter(function(coa) {
                    return (
                        coa.account_type === "Expense" ||
                        coa.account_type === "Other Expense" ||
                        coa.account_type === "Other Current Asset" ||
                        coa.account_type === "Other Asset" ||
                        coa.account_type === "Fixed Asset" ||
                        coa.account_type === "Cost of Sales"
                    );
                });
            }
        },
        associatedRefNo: function() {
            if (this.type == "Invoice") {
                if (
                    this.purchase.bill_reference_list.length > 0 &&
                    this.purchase.bill_reference_id != 0 &&
                    this.purchase.bill_reference_id != ""
                ) {
                    const result = this.purchase.bill_reference_list.find(
                        ({ id }) => id == this.purchase.bill_reference_id
                    );
                    return result.bill_order_number;
                }
                return null;
            }
        }
    },
    created() {
        this.getCoaAll();
        this.getTaxAllPurchases();
        this.getItemAll({ type: "Purchase" });
        if (this.$route.params.id) {
            this.editPurchase(this.$route.params.id)
                .then(response => {
                    response.locked == true
                        ? (this.forbiddenData = true)
                        : (this.forbiddenData = false);
                    response.data.bill_date < this.forbiddenDateLast
                        ? (this.forbiddenData = true)
                        : (this.forbiddenData = false);
                    var self = this; //For Handling External Variable
                    response.data.transaction.forEach(function(element, index) {
                        let itemSelected = self.items.find(obj => {
                            return obj.id == element.item_id;
                        });
                        self.purchase.bill_transaction[index].uom_list =
                            element.item_id != 0 ? itemSelected.uom_list : [];
                        self.purchase.bill_transaction[index].item_name =
                            element.item_id != 0 ? itemSelected.item_name : "";
                    });
                    if (response.data.bill_status == 1) {
                        this.type = "Quotation";
                        this.labels = ["Quotation"];
                    } else if (response.data.bill_status == 2) {
                        this.type = "Order";
                        this.labels = ["Order"];
                    } else if (
                        response.data.bill_status == 3 ||
                        response.data.bill_status == 4
                    ) {
                        this.type = "Invoice";
                        this.labels = ["Invoice"];
                    }
                    this.loadingPage = 0;
                })
                .catch(error => {
                    console.log(error);
                    this.loadingPage = 0;
                    if (error.response.status == 500) {
                        this.$router.push({
                            name: "purchase-register",
                            params: { reloadData: true }
                        });
                        this.alert(error.response.data.message, 2);
                    }
                });
            if (
                this.purchase.bill_status != 4 &&
                this.purchase.bill_status != 5
            ) {
                this.getContactSupplier();
            } else {
                this.getContactSupplierList();
            }
        } else {
            this.$router.push({ name: "purchase-register" });
            this.alert("Data not found, select data first", 2);
        }
        this.getForbiddenDate();
    },
    methods: {
        //VUEX
        ...mapMutations("purchase", ["CLEAR_FORM"]),
        ...mapActions("coa", ["getCoaAll", "getCoaLink"]),
        ...mapMutations(["CLEAR_ERRORS"]),
        ...mapActions("journal", ["getJournalNo", "submitJournal"]),
        ...mapActions("recurringjournal", ["useRecurring"]),
        ...mapActions("contact", ["getContactSupplier"]),
        ...mapActions("item", ["getItemAll"]),
        ...mapActions("tax", ["getTaxAllPurchases"]),
        ...mapActions("purchase", [
            "editPurchase",
            "getQuotationNo",
            "getOrderNo",
            "getInvoiceNo",
            "updatePurchase",
            "removePurchase",
            "reviewUpdatePurchase",
            "printPurchase"
        ]),
        ...mapActions("forbiddendate", ["getForbiddenDate"]),
        //METHOD
        clearModal() {
            document.body.classList.remove("modal-open");
            this.purchase.journal = {};
        },
        changeType(typeStatus) {
            this.$swal({
                title: "Are you sure want to change this purchase ?",
                text: "This action cannot be undoned",
                icon: "warning",
                buttons: true
            }).then(set => {
                if (set) {
                    this.type = typeStatus;
                    if (typeStatus == "Quotation") {
                        this.getQuotationNo();
                    } else if (typeStatus == "Order") {
                        this.getOrderNo();
                    } else {
                        this.getCoaLink().then(response => {
                            this.purchase.bill_coa_id = this.coalink.tracking_receiveable;
                        });
                        this.getInvoiceNo();
                        this.getJournalMemo();
                    }
                }
            });
        },
        disabledDatePicker(date) {
            const lastDisableDate = new Date(
                moment(this.forbiddenDateLast).add(1, "days")
            );
            lastDisableDate.setHours(0, 0, 0, 0);
            return date < lastDisableDate;
        },
        changeDate() {
            if (this.purchase.bill_date !== "") {
                this.type == "Quotation" ? this.getQuotationNo() : "";
                this.type == "Order" ? this.getOrderNo() : "";
                this.type == "Invoice" ? this.getInvoiceNo() : "";
                // this.type == 'Invoice' ? this.getJournalMemo() :'';
            }
        },
        //GET DATA ADDRESS & BEGINING BALANCE CUSTOMER
        getContactDetail(val) {
            if (
                this.purchase.bill_contact_id == "" ||
                this.purchase.bill_contact_id == 0
            ) {
                this.purchase.bill_shipaddress = "";
                this.purchase.bill_contact_beginning_balance = "";
            } else {
                var result = this.contacts.find(obj => {
                    return obj.id === parseInt(val);
                });
                this.purchase.bill_shipaddress = result.contact_address;
                this.purchase.bill_contact_beginning_balance =
                    result.contact_begining_balance;
            }
            this.getJournalMemo();
        },
        //GET DATA TAX RATE
        getTaxDetail(index, val) {
            var result = this.taxs.find(obj => {
                return obj.id === parseInt(val);
            });
            this.purchase.bill_transaction[index].transaction_tax_rate =
                result.tax_rate;
            //RE CALCULATE BILLS
            this.calculate();
        },
        //GET JOURNAL MEMO
        getJournalMemo() {
            if (this.purchase.journal == null) {
                if (
                    this.purchase.bill_contact_id == "" ||
                    this.purchase.bill_contact_id == 0
                ) {
                    let newJournalMemo = "Sales; ";
                    this.purchase.bill_journal_memo = newJournalMemo;
                } else {
                    var result = this.contacts.find(obj => {
                        return (
                            obj.id === parseInt(this.purchase.bill_contact_id)
                        );
                    });
                    let newJournalMemo = "Sales; " + result.contact_name;
                    this.purchase.bill_journal_memo = newJournalMemo;
                }
            } else {
                this.purchase.bill_journal_memo = this.purchase.journal.journal_description;
            }
        },
        addDetail() {
            this.purchase.bill_transaction.push({
                id: 0,
                transaction_tax_id: "",
                transaction_tax_rate: 0,
                transaction_coa_id: "",
                transaction_description: "",
                transaction_amount: 0
            });
        },
        removeDetail(index, transaction_id) {
            this.purchase.bill_transaction.splice(index, 1);
            this.purchase.bill_transaction_delete.push(transaction_id);
        },
        calculate() {
            var sub = 0;
            var tax = 0;
            this.purchase.bill_transaction.forEach(transaction => {
                sub += transaction.transaction_amount;
            });

            this.purchase.bill_transaction.forEach(transaction => {
                if (isNaN(transaction.transaction_tax_rate)) {
                } else {
                    let taxTotal =
                        (transaction.transaction_amount *
                            transaction.transaction_tax_rate) /
                        100;
                    tax = tax + parseInt(taxTotal.toFixed(0));
                }
            });

            this.purchase.bill_sub_total = sub.toFixed(0);
            this.purchase.bill_tax_total = tax.toFixed(0);
            this.purchase.bill_grand_total = (sub + tax).toFixed(0);
        },
        review() {
            this.CLEAR_ERRORS();
            this.loadingPage = 1;
            this.type == "Quotation"
                ? (this.purchase.bill_number = this.purchase.bill_quotation_number)
                : "";
            this.type == "Order"
                ? (this.purchase.bill_number = this.purchase.bill_order_number)
                : "";
            this.type == "Invoice"
                ? (this.purchase.bill_number = this.purchase.bill_invoice_number)
                : "";
            this.reviewUpdatePurchase()
                .then(response => {
                    this.purchase.journal =
                        this.purchase.bill_status == 3 ? response.journal : {};
                    this.$modal.show("review-purchase");
                    document.body.classList.add("modal-open");
                    this.loadingPage = 0;
                })
                .catch(error => {
                    error.response.status == 500
                        ? this.alert(error.response.data.message, 2)
                        : "";
                    error.response.status != 422 && error.response.status != 500
                        ? this.alert(error.message, 2)
                        : "";
                    this.loadingPage = 0;
                });
        },
        print() {
            this.loadingPage = 1;
            let bills_no = "";
            this.type == "Quotation"
                ? (bills_no = this.purchase.bill_quotation_number)
                : "";
            this.type == "Order"
                ? (bills_no = this.purchase.bill_order_number)
                : "";
            this.type == "Invoice"
                ? (bills_no = this.purchase.bill_invoice_number)
                : "";
            this.printPurchase({
                bills_id: this.$route.params.id,
                type: this.type,
                bills_no: bills_no
            })
                .then(response => {
                    this.loadingPage = 0;
                })
                .catch(error => {
                    error.response.status != 422
                        ? this.alert(error.message, 2)
                        : "";
                    this.loadingPage = 0;
                });
        },
        deleteData() {
            this.$swal({
                title: "Are you sure ?",
                text: "Deleted data cannot be recovery",
                icon: "warning",
                buttons: ["Cancel", "Delete"],
                dangerMode: true
            }).then(willDelete => {
                if (willDelete) {
                    this.loadingPage = 1;
                    this.removePurchase(this.$route.params.id)
                        .then(() => {
                            this.alert("Data has been deleted !", 1);
                            this.loadingPage = 0;
                            this.$router.push({
                                name: "purchase-register",
                                params: { reloadData: true }
                            });
                        })
                        .catch(error => {
                            error.response.status == 500
                                ? this.alert(error.response.data.message, 2)
                                : "";
                            error.response.status != 422 &&
                            error.response.status != 500
                                ? this.alert(error.message, 2)
                                : "";
                            this.loadingPage = 0;
                        });
                }
            });
        }
    },
    destroyed() {
        this.CLEAR_FORM();
    }
};
</script>