import $axios from "../../api.js";

const state = () => ({
    contacts: [],

    contact: {
        contact_id: 0,
        contact_type: 1,
        contact_name: "",
        contact_address: "",
        contact_province: "",
        contact_postalcode: "",
        contact_email: "",
        contact_contactname: "",
        contact_contactphone: "",
        contact_contactname2: "",
        contact_contactphone2: ""
    },
    page: 1, //UNTUK MENCATAT PAGE PAGINATE YANG SEDANG DIAKSES
    last_page: 1,
    search: ""
});

const mutations = {
    ASSIGN_DATA(state, payload) {
        state.contacts = payload;
        state.last_page = payload.meta.last_page;
        state.page = payload.meta.current_page;
    },
    SET_PAGE(state, payload) {
        state.page = payload;
    },
    ASSIGN_FORM(state, payload) {
        state.contact = {
            contact_id: payload.id,
            contact_type: payload.contact_type,
            contact_name: payload.contact_name,
            contact_address: payload.contact_address,
            contact_province: payload.contact_province,
            contact_postalcode: payload.contact_postalcode,
            contact_email: payload.contact_email,
            contact_contactname: payload.contact_contactname,
            contact_contactphone: payload.contact_contactphone,
            contact_contactname2: payload.contact_contactname2,
            contact_contactphone2: payload.contact_contactphone2
        };
    },
    //ME-RESET STATE NEWS MENJADI KOSONG
    CLEAR_FORM(state) {
        state.contact = {
            contact_id: 0,
            contact_type: 1,
            contact_name: "",
            contact_address: "",
            contact_province: "",
            contact_postalcode: "",
            contact_email: "",
            contact_contactname: "",
            contact_contactphone: "",
            contact_contactname2: "",
            contact_contactphone2: ""
        };
    },
    CLEAR_DATA(state) {
        state.contacts = [];
    }
};

const actions = {
    getContact({ dispatch, commit, state }, payload) {
        let search = typeof payload.search != "undefined" ? payload.search : "";
        let type = payload.type;
        let rows = payload.rows;
        let page =
            typeof payload.rows != "All" && payload.rowsUpdate != 1
                ? state.page
                : "1";

        return new Promise((resolve, reject) => {
            $axios
                .get(
                    `/contact?page=${page}&search=${search}&rows=${rows}&type=${type}`
                )
                .then(response => {
                    commit("ASSIGN_DATA", response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                    reject(error);
                });
        });
    },
    getContactCustomer({ state }) {
        $axios
            .get(`/contact/customer`)
            .then(response => {
                state.contacts = response.data;
                this.loading = false;
            })
            .catch(error => {
                error.response.status === 401
                    ? dispatch("DO_LOGOUT", null, { root: true })
                    : "";
                reject(error);
            });
    },
    getContactSupplier({ state }) {
        $axios
            .get(`/contact/supplier`)
            .then(response => {
                state.contacts = response.data;
                this.loading = false;
            })
            .catch(error => {
                error.response.status === 401
                    ? dispatch("DO_LOGOUT", null, { root: true })
                    : "";
                reject(error);
            });
    },
    getContactCustomerList({ state }) {
        $axios
            .get(`/contact/customer-list`)
            .then(response => {
                state.contacts = response.data;
                this.loading = false;
            })
            .catch(error => {
                error.response.status === 401
                    ? dispatch("DO_LOGOUT", null, { root: true })
                    : "";
                reject(error);
            });
    },
    getContactSupplierList({ state }) {
        $axios
            .get(`/contact/supplier-list`)
            .then(response => {
                state.contacts = response.data;
                this.loading = false;
            })
            .catch(error => {
                error.response.status === 401
                    ? dispatch("DO_LOGOUT", null, { root: true })
                    : "";
                reject(error);
            });
    },
    getContactAll({ state }) {
        $axios
            .get(`/contact/retrive-all-data`)
            .then(response => {
                state.contacts = response.data;
                this.loading = false;
            })
            .catch(error => {
                error.response.status === 401
                    ? dispatch("DO_LOGOUT", null, { root: true })
                    : "";
                reject(error);
            });
    },
    submitContact({ dispatch, commit, state }) {
        return new Promise((resolve, reject) => {
            $axios
                .post(`/contact`, state.contact)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                });
        });
    },
    editContact({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.get(`/contact/${payload}/edit`).then(response => {
                commit("ASSIGN_FORM", response.data);
                resolve(response.data);
            });
        });
    },
    updateContact({ state, commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .put(`/contact/${payload}`, state.contact)
                .then(response => {
                    //FORM DIBERSIHKAN
                    commit("CLEAR_FORM");
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                });
        });
    },
    //MENGHAPUS DATA
    removeContact({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .delete(`/contact/${payload}`)
                .then(response => {
                    commit("CLEAR_FORM");
                    resolve(response.data);
                })
                .catch(error => {
                    if (error.response) {
                        commit("SET_ERRORS", error.response.data, {
                            root: true
                        });
                    }
                    reject(error);
                });
        });
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
