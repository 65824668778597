<template>
    <main>
        <hero title="Create Income" prevBreadcrumb="Incomes" routeBreadcrumb="income" currentBreadcrumb="Create Income"></hero>

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 1"
                style="height:250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity:0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 2"
                style="height:250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity:0.7"
                            >Save Data</span
                        >
                    </center>
                </div>
            </div>
            <!-- Basic -->
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 0"
            >
                <div class="block-content block-content-full">
                    <form
                        method="POST"
                        enctype="multipart/form-data"
                        onsubmit="return false;"
                    >
                        <!--Form-->
                        <div class="row font-size-sm">
                            <div class="col-12 py-0">
                                <div class="row">
                                    <div class="col-12 col-lg-6 pt-0">
                                        <label style="opacity:0.7"
                                            >Income Information</label
                                        >
                                        <hr class="mt-2 mb-3" />
                                        <div class="form-group">
                                            <label for="example-text-input"
                                                >Deposit to Account *</label
                                            >
                                            <selectize
                                                class="form-control"
                                                placeholder="Choose Account No."
                                                v-model="
                                                    income.bill_coa_id
                                                "
                                                :class="{
                                                    'is-invalid-selectize':
                                                        errors.bill_coa_id
                                                }"
                                            >
                                                <!-- settings is optional -->
                                                <option
                                                    v-for="account in bankAccounts"
                                                    :value="account.id"
                                                    :key="account.id"
                                                    >{{ account.account_no }}
                                                    {{
                                                        account.account_name
                                                    }}</option
                                                >
                                            </selectize>
                                            <transition name="slide-fade">
                                                <p
                                                    class="text-danger"
                                                    v-if="
                                                        errors.bill_coa_id
                                                    "
                                                >
                                                    {{
                                                        errors
                                                            .bill_coa_id[0]
                                                    }}
                                                </p>
                                            </transition>
                                        </div>
                                        <div class="form-group">
                                            <label for="example-text-input"
                                                >Name Contact *</label
                                            >
                                            <selectize
                                                class="form-control"
                                                placeholder="Contact Name"
                                                v-model="
                                                    income.bill_contact_id
                                                "
                                                :class="{
                                                    'is-invalid-selectize':
                                                        errors.bill_contact_id
                                                }"
                                                @input="getJournalMemo()"
                                            >
                                                <!-- settings is optional -->
                                                <option
                                                    v-for="contact in contacts"
                                                    :value="contact.id"
                                                    :key="contact.id"
                                                    >{{
                                                        contact.contact_type ==
                                                        1
                                                            ? "Customer"
                                                            : "Supplier"
                                                    }}
                                                    | {{ contact.contact_name }}
                                                </option>
                                            </selectize>
                                            <p
                                                v-if="errors.bill_contact_id"
                                                class="text-danger"
                                            >
                                                {{ errors.bill_contact_id[0] }}
                                            </p>
                                        </div>
                                        <!-- <div class="form-group">
                                            <label for="example-text-input">Beginning Balance</label>
                                            <money
                                                class="form-control"
                                                style="text-align: right;"
                                                v-model="income.bill_contact_beginning_balance"
                                                spellcheck="false"
                                                disabled
                                            ></money>
                                            <transition name="slide-fade">
                                                <p
                                                    class="text-danger"
                                                    v-if="errors.bill_contact_beginning_balance"
                                                >{{ errors.bill_contact_beginning_balance[0] }}</p>
                                            </transition>
                                        </div> -->
                                    </div>
                                    <div class="col-12 col-lg-6 pt-0">
                                        <label style="opacity:0.7"
                                            >Income Detail</label
                                        >
                                        <hr class="mt-2 mb-3" />

                                        <div class="row">
                                            <div
                                                class="form-group col-12 pb-1 pt-0"
                                            >
                                                <label for="example-text-input"
                                                    >Income Date</label
                                                >
                                                <date-picker
                                                    class="input-group"
                                                    v-model="income.bill_date"
                                                    format="DD/MM/YYYY"
                                                    valueType="YYYY-MM-DD"
                                                    placeholder="Income Date"
                                                    :class="{
                                                        'is-invalid':
                                                            errors.bill_date
                                                    }"
                                                    :default-value="new Date()"
                                                    :disabled-date="
                                                        disabledDatePicker
                                                    "
                                                    @input="changeDate()"
                                                ></date-picker>
                                                <transition name="slide-fade">
                                                    <p
                                                        class="text-danger"
                                                        v-if="errors.bill_date"
                                                    >
                                                        {{
                                                            errors.bill_date[0]
                                                        }}
                                                    </p>
                                                </transition>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label for="example-text-input"
                                                >Income Number</label
                                            >
                                            <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Income Number"
                                                v-model="income.bill_number"
                                                :class="{
                                                    'is-invalid':
                                                        errors.bill_number
                                                }"
                                            />
                                            <transition name="slide-fade">
                                                <p
                                                    class="text-danger"
                                                    v-if="errors.bill_number"
                                                >
                                                    {{ errors.bill_number[0] }}
                                                </p>
                                            </transition>
                                        </div>

                                        <transition name="slide-fade">
                                            <div class="form-group">
                                                <label for="example-text-input"
                                                    >Journal Memo *</label
                                                >
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="Income Memo"
                                                    v-model="
                                                        income.bill_journal_memo
                                                    "
                                                    :class="{
                                                        'is-invalid':
                                                            errors.bill_journal_memo
                                                    }"
                                                />
                                                <transition name="slide-fade">
                                                    <p
                                                        class="text-danger"
                                                        v-if="
                                                            errors.bill_journal_memo
                                                        "
                                                    >
                                                        {{
                                                            errors
                                                                .bill_journal_memo[0]
                                                        }}
                                                    </p>
                                                </transition>
                                            </div>
                                        </transition>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div class="table-responsive">
                                        <table
                                            class="table table-vcenter table-borderless mb-0"
                                        >
                                            <thead
                                                style="background-color: whitesmoke;"
                                            >
                                                <tr>
                                                    <th
                                                        class="table-header-relative column-minwidth-150"
                                                    >
                                                        Description
                                                    </th>
                                                    <th
                                                        class="table-header-relative column-minwidth-250"
                                                    >
                                                        Account No.
                                                    </th>
                                                    <th
                                                        class="table-header-relative column-minwidth-150"
                                                        width="20%"
                                                    >
                                                        Amount
                                                    </th>
                                                    <th
                                                        class="table-header-relative column-minwidth-150"
                                                        width="20%"
                                                    >
                                                        Tax
                                                    </th>
                                                    <th
                                                        class="table-header-relative"
                                                        width="50px"
                                                    ></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    v-for="(transaction,
                                                    index) in income.bill_transaction"
                                                    :key="index"
                                                >
                                                    <td class="data-detail">
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            placeholder="Description"
                                                            v-model="
                                                                transaction.transaction_description
                                                            "
                                                            :class="{
                                                                'is-invalid':
                                                                    errors[
                                                                        'bill_transaction.' +
                                                                            index +
                                                                            '.transaction_description'
                                                                    ]
                                                            }"
                                                        />
                                                        <span
                                                            v-if="
                                                                errors[
                                                                    'bill_transaction.' +
                                                                        index +
                                                                        '.transaction_description'
                                                                ]
                                                            "
                                                            class="text-danger data-detail-error"
                                                        >
                                                            {{
                                                                errors[
                                                                    "bill_transaction." +
                                                                        index +
                                                                        ".transaction_description"
                                                                ][0]
                                                            }}
                                                        </span>
                                                    </td>
                                                    <td
                                                        class="font-size-sm data-detail"
                                                    >
                                                        <selectize
                                                            class="form-control mt-1"
                                                            placeholder="Choose Account No."
                                                            v-model="
                                                                transaction.transaction_coa_id
                                                            "
                                                            :class="{
                                                                'is-invalid-selectize':
                                                                    errors[
                                                                        'bill_transaction.' +
                                                                            index +
                                                                            '.transaction_coa_id'
                                                                    ]
                                                            }"
                                                        >
                                                            <!-- settings is optional -->
                                                            <option
                                                                v-for="account in transactionAccounts"
                                                                :value="
                                                                    account.id
                                                                "
                                                                :key="
                                                                    account.id
                                                                "
                                                                >{{
                                                                    account.account_no
                                                                }}
                                                                {{
                                                                    account.account_name
                                                                }}</option
                                                            >
                                                        </selectize>
                                                        <span
                                                            v-if="
                                                                errors[
                                                                    'bill_transaction.' +
                                                                        index +
                                                                        '.transaction_coa_id'
                                                                ]
                                                            "
                                                            class="text-danger data-detail-error"
                                                        >
                                                            {{
                                                                errors[
                                                                    "bill_transaction." +
                                                                        index +
                                                                        ".transaction_coa_id"
                                                                ][0]
                                                            }}
                                                        </span>
                                                    </td>
                                                    <td
                                                        class="font-size-sm data-detail"
                                                    >
                                                        <money
                                                            class="form-control"
                                                            style="text-align: right;"
                                                            v-model="
                                                                transaction.transaction_amount
                                                            "
                                                            spellcheck="false"
                                                            @input="calculate()"
                                                            :class="{
                                                                'is-invalid':
                                                                    errors[
                                                                        'bill_transaction.' +
                                                                            index +
                                                                            '.transaction_amount'
                                                                    ]
                                                            }"
                                                        ></money>
                                                        <span
                                                            v-if="
                                                                errors[
                                                                    'bill_transaction.' +
                                                                        index +
                                                                        '.transaction_amount'
                                                                ]
                                                            "
                                                            class="text-danger data-detail-error"
                                                        >
                                                            {{
                                                                errors[
                                                                    "bill_transaction." +
                                                                        index +
                                                                        ".transaction_amount"
                                                                ][0]
                                                            }}
                                                        </span>
                                                    </td>
                                                    <td
                                                        class="font-size-sm data-detail"
                                                    >
                                                        <selectize
                                                            class="form-control mt-1"
                                                            placeholder="Choose Tax Code"
                                                            v-model="
                                                                transaction.transaction_tax_id
                                                            "
                                                            :class="{
                                                                'is-invalid-selectize':
                                                                    errors[
                                                                        'bill_transaction.' +
                                                                            index +
                                                                            '.transaction_tax_id'
                                                                    ]
                                                            }"
                                                            @input="
                                                                getTaxDetail(
                                                                    index,
                                                                    transaction.transaction_tax_id
                                                                )
                                                            "
                                                        >
                                                            <!-- settings is optional -->
                                                            <option
                                                                v-for="(tax,
                                                                indeks) in taxs"
                                                                :value="tax.id"
                                                                :key="indeks"
                                                            >
                                                                {{
                                                                    tax.tax_code
                                                                }}
                                                                ({{
                                                                    tax.tax_rate
                                                                }}%)
                                                            </option>
                                                        </selectize>
                                                        <span
                                                            v-if="
                                                                errors[
                                                                    'bill_transaction.' +
                                                                        index +
                                                                        '.transaction_tax_id'
                                                                ]
                                                            "
                                                            class="text-danger data-detail-error"
                                                        >
                                                            {{
                                                                errors[
                                                                    "bill_transaction." +
                                                                        index +
                                                                        ".transaction_tax_id"
                                                                ][0]
                                                            }}
                                                        </span>
                                                    </td>
                                                    <td
                                                        class="font-size-sm"
                                                        align="center"
                                                    >
                                                        <a
                                                            class="btn-icon-only btn-icon-danger"
                                                            v-if="index > 0"
                                                            @click="
                                                                removeDetail(
                                                                    index
                                                                )
                                                            "
                                                        >
                                                            <i
                                                                class="fa fa-trash"
                                                            ></i>
                                                        </a>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        class="font-size-sm pb-0 pt-3"
                                                        colspan="5"
                                                    >
                                                        <a
                                                            class="btn-icon-only icon-succes hover-hr-table"
                                                            @click="addDetail()"
                                                        >
                                                            <i
                                                                class="fa fa-plus"
                                                            ></i>
                                                            New Income Line
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        class="font-size-sm  opacity-25"
                                                        colspan="5"
                                                    >
                                                        <hr class="hr-table" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        class="font-size-sm"
                                                        colspan="5"
                                                    >
                                                        <div
                                                            class="col-lg-5 float-right"
                                                            style="background-color: #e9ecef !important; width: 350px; padding-top: 10px;"
                                                        >
                                                            <strong>
                                                                <p class="mb-2">
                                                                    Sub Total
                                                                    <span
                                                                        class="float-right"
                                                                        >{{
                                                                            income.bill_sub_total
                                                                                | currency
                                                                        }}</span
                                                                    >
                                                                </p>
                                                            </strong>
                                                            <p class="mb-2">
                                                                Tax
                                                                <small
                                                                    class="float-right"
                                                                    >{{
                                                                        income.bill_tax_total
                                                                            | currency
                                                                    }}</small
                                                                >
                                                            </p>
                                                            <strong>
                                                                <p class="mb-2">
                                                                    Grand Total
                                                                    <span
                                                                        class="float-right"
                                                                        >{{
                                                                            income.bill_grand_total
                                                                                | currency
                                                                        }}</span
                                                                    >
                                                                </p>
                                                            </strong>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        align="center"
                                                        colspan="4"
                                                    >
                                                        <div
                                                            class="col-12 text-center mb-0 pb-0 d-none d-sm-inline-block mt-2"
                                                        >
                                                            <p
                                                                class="font-size-sm text-muted mb-0"
                                                            >
                                                                * Required
                                                                filled, Make
                                                                sure all data is
                                                                correctly filled
                                                            </p>
                                                        </div>
                                                        <div
                                                            class="col-12 text-center d-none d-sm-inline-block"
                                                        >
                                                            <button
                                                                type="button"
                                                                class="btn btn-sm btn-info mb-1"
                                                                v-ripple="{
                                                                    center: true
                                                                }"
                                                                @click="
                                                                    printSaveBill()
                                                                "
                                                            >
                                                                <i
                                                                    class="fa fa-print mr-1"
                                                                ></i>
                                                                Save & Print
                                                            </button>
                                                            <button
                                                                type="button"
                                                                class="btn btn-sm btn-success mb-1"
                                                                v-ripple="{
                                                                    center: true
                                                                }"
                                                                @click="
                                                                    saveBill()
                                                                "
                                                            >
                                                                <i
                                                                    class="fa fa-fw fa-check mr-1"
                                                                ></i>
                                                                Save
                                                            </button>
                                                            <router-link
                                                                type="button"
                                                                class="btn btn-sm btn-alt-light mb-1"
                                                                tag="button"
                                                                v-ripple="{
                                                                    center: true
                                                                }"
                                                                :to="{
                                                                    name:
                                                                        'income'
                                                                }"
                                                            >
                                                                <i
                                                                    class="fa fa-fw fa-times mr-1"
                                                                ></i>
                                                                Close
                                                            </router-link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <p
                                            class="text-muted font-size-sm d-inline d-sm-none"
                                            style="opacity:0.5;"
                                        >
                                            Swipe left here to see full column
                                            >>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--END Form-->
                        <!-- Submit -->
                        <div class="row items-push d-inline d-sm-none">
                            <div class="col-12 text-center mb-0 pb-0">
                                <p class="font-size-sm text-muted mb-0">
                                    * Require to be filled, Make sure all data is
                                    correctly filled
                                </p>
                            </div>
                            <div class="col-12 text-center">
                                <button
                                    type="button"
                                    class="btn btn-sm btn-info mb-1"
                                    v-ripple="{ center: true }"
                                    @click="printSaveBill()"
                                >
                                    <i class="fa fa-print mr-1"></i> Save &
                                    Print
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-sm btn-success mb-1"
                                    v-ripple="{ center: true }"
                                    @click="saveBill()"
                                >
                                    <i class="fa fa-fw fa-check mr-1"></i> Save
                                </button>
                                <router-link
                                    type="button"
                                    class="btn btn-sm btn-alt-light mb-1"
                                    tag="button"
                                    v-ripple="{ center: true }"
                                    :to="{ name: 'income' }"
                                >
                                    <i class="fa fa-fw fa-times mr-1"></i> Close
                                </router-link>
                            </div>
                        </div>
                        <!-- END Submit -->
                    </form>
                </div>
            </div>
            <!-- END Basic -->
        </div>
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import moment from "moment";

export default {
    name: "income-enter",
    data() {
        return {
            loadingPage: 0,
            disabled: {},
            coaIncomes: []
        };
    },
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState("income", { income: state => state.income }),
        ...mapState("coa", { coas: state => state.coas.accounts }),
        ...mapState("contact", { contacts: state => state.contacts }),
        ...mapState("tax", { taxs: state => state.taxs }),
        ...mapState("forbiddendate", {
            forbiddenDateLast: state => state.forbiddenDateLast
        }),
        disableSave() {
            if (this.income.bill_due_date < this.income.bill_date) {
                return true;
            }
            if (this.income.bill_grand_total == 0) {
                return true;
            }
        },
        bankAccounts: function() {
            if (this.coas) {
                return this.coas.filter(function(coa) {
                    return coa.account_type === "Bank";
                });
            }
        },
        transactionAccounts: function() {
            if (this.coas) {
                return this.coas.filter(function(coa) {
                    return (
                        coa.account_type !== "Expense" &&
                        coa.account_type !== "Other Expense"
                    );
                });
            }
        }
    },
    created() {
        this.getContactAll();
        this.getCoaAll();
        this.getTaxAll();
        this.getIncomeNo();
        this.getForbiddenDate();
        this.getJournalMemo();
    },
    methods: {
        //VUEX
        ...mapMutations("income", ["CLEAR_FORM"]),
        ...mapActions("coa", ["getCoaAll"]),
        ...mapMutations(["CLEAR_ERRORS"]),
        ...mapActions("contact", ["getContactAll"]),
        ...mapActions("tax", ["getTaxAll"]),
        ...mapActions("income", ["getIncomeNo", "submitIncome", "printIncome"]),
        ...mapActions("forbiddendate", ["getForbiddenDate"]),

        disabledDatePicker(date) {
            const lastDisableDate = new Date(
                moment(this.forbiddenDateLast).add(1, "days")
            );
            lastDisableDate.setHours(0, 0, 0, 0);
            return date < lastDisableDate;
        },
        changeDate() {
            this.getIncomeNo();
            // this.getJournalMemo();
        },
        //GET DATA TAX RATE
        getTaxDetail(index, val) {
            var result = this.taxs.find(obj => {
                return obj.id === parseInt(val);
            });
            this.income.bill_transaction[index].transaction_tax_rate =
                result.tax_rate;
            //RE CALCULATE BILLS
            this.calculate();
        },
        //GET JOURNAL MEMO
        getJournalMemo() {
            if (
                this.income.bill_contact_id == "" ||
                this.income.bill_contact_id == 0
            ) {
                let newJournalMemo = "Income; ";
                this.income.bill_journal_memo = newJournalMemo;
            } else {
                var result = this.contacts.find(obj => {
                    return obj.id === parseInt(this.income.bill_contact_id);
                });
                let newJournalMemo = "Income; " + result.contact_name;
                this.income.bill_journal_memo = newJournalMemo;
            }
        },
        addDetail() {
            this.income.bill_transaction.push({
                transaction_tax_id: "",
                transaction_tax_rate: 0,
                transaction_coa_id: "",
                transaction_description: "",
                transaction_amount: 0
            });
        },
        removeDetail(index) {
            this.income.bill_transaction.splice(index, 1);
        },
        calculate() {
            var sub = 0;
            var tax = 0;
            this.income.bill_transaction.forEach(transaction => {
                sub += transaction.transaction_amount;
            });

            this.income.bill_transaction.forEach(transaction => {
                if (isNaN(transaction.transaction_tax_rate)) {
                } else {
                    tax +=
                        transaction.transaction_amount *
                        (transaction.transaction_tax_rate / 100);
                }
            });

            this.income.bill_sub_total = sub.toFixed(0);
            this.income.bill_tax_total = tax.toFixed(0);
            this.income.bill_grand_total = (sub + tax).toFixed(0);
        },
        printSaveBill() {
            this.CLEAR_ERRORS();
            this.loadingPage = 2;
            let bills_no = "";
            this.income.bill_status = 3;
            bills_no = this.income.bill_number;
            this.submitIncome()
                .then(response => {
                    this.alert("Successfully create Income Data ", 1);
                    this.$router.push({
                        name: "income-detail",
                        params: { id: response.bills_id }
                    });
                    // this.printIncome({
                    //     bills_id: response.bills_id,
                    //     type : this.type,
                    //     bills_no : bills_no
                    // }).then((response) => {
                    //     this.CLEAR_FORM();
                    //     this.alert("Successfully create Income Data ",1 );
                    //     this.loadingPage = 0;
                    // }).catch((error) => {
                    //     error.response.status != 422 ? this.alert(error.message,2): '';
                    //     this.loadingPage = 0;
                    // })
                })
                .catch(error => {
                    error.response.status == 500
                        ? this.alert(error.response.data.message, 2)
                        : "";
                    error.response.status != 422 && error.response.status != 500
                        ? this.alert(error.message, 2)
                        : "";
                    this.loadingPage = 0;
                });
        },
        saveBill() {
            this.CLEAR_ERRORS();
            this.loadingPage = 2;
            this.income.bill_status = 3;
            this.submitIncome()
                .then(response => {
                    console.log(response);
                    this.CLEAR_FORM();
                    this.alert("Successfully create Income Data ", 1);
                    this.loadingPage = 0;
                })
                .catch(error => {
                    error.response.status == 500
                        ? this.alert(error.response.data.message, 2)
                        : "";
                    error.response.status != 422 && error.response.status != 500
                        ? this.alert(error.message, 2)
                        : "";
                    this.loadingPage = 0;
                });
        }
    },
    destroyed() {
        this.CLEAR_FORM();
    }
};
</script>
