var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('hero',{attrs:{"title":"Create Tax","prevBreadcrumb":"Tax Data","routeBreadcrumb":"tax","currentBreadcrumb":"Create Tax"}}),_vm._v(" "),_c('div',{staticClass:"content"},[(_vm.loadingPage == 1)?_c('div',{staticClass:"block block-rounded block-mode-loading-oneui",staticStyle:{"height":"250px"}},[_c('div',{staticClass:"block-content block-content-full pb-0",staticStyle:{"padding-top":"80px"}},[_c('grid-loader',{staticClass:"mx-auto",attrs:{"color":'#70B9EB',"size":'10px'}}),_vm._v(" "),_c('center',{staticClass:"mt-2"},[_c('span',{staticClass:"text-center",staticStyle:{"opacity":"0.7"}},[_vm._v("Loading")])])],1)]):_vm._e(),_vm._v(" "),(_vm.loadingPage == 2)?_c('div',{staticClass:"block block-rounded block-mode-loading-oneui",staticStyle:{"height":"250px"}},[_c('div',{staticClass:"block-content block-content-full pb-0",staticStyle:{"padding-top":"80px"}},[_c('grid-loader',{staticClass:"mx-auto",attrs:{"color":'#70B9EB',"size":'10px'}}),_vm._v(" "),_c('center',{staticClass:"mt-2"},[_c('span',{staticClass:"text-center",staticStyle:{"opacity":"0.7"}},[_vm._v("Save Data")])])],1)]):_vm._e(),_vm._v(" "),(_vm.loadingPage == 0)?_c('div',{staticClass:"block col-12 col-md-8 offset-md-2 block-rounded block-mode-loading-oneui"},[_c('div',{staticClass:"block-content block-content-full"},[_c('form',{attrs:{"method":"POST","enctype":"multipart/form-data","onsubmit":"return false;"}},[_c('div',{staticClass:"row font-size-sm"},[_c('div',{staticClass:"col-12 col-md-10 offset-md-1"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Tax Code *")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tax.tax_code),expression:"tax.tax_code"}],staticClass:"form-control",class:{
                                        'is-invalid': _vm.errors.tax_code,
                                    },attrs:{"type":"text","placeholder":"Tax Code"},domProps:{"value":(_vm.tax.tax_code)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.tax, "tax_code", $event.target.value)}}}),_vm._v(" "),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.errors.tax_code)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                        "+_vm._s(_vm.errors.tax_code[0])+"\n                                    ")]):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Tax Name *")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tax.tax_name),expression:"tax.tax_name"}],staticClass:"form-control",class:{
                                        'is-invalid': _vm.errors.tax_name,
                                    },attrs:{"type":"text","placeholder":"Tax Name"},domProps:{"value":(_vm.tax.tax_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.tax, "tax_name", $event.target.value)}}}),_vm._v(" "),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.errors.tax_name)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                        "+_vm._s(_vm.errors.tax_name[0])+"\n                                    ")]):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Tax Rate (%) *")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tax.tax_rate),expression:"tax.tax_rate"}],staticClass:"form-control",class:{
                                        'is-invalid': _vm.errors.tax_rate,
                                    },attrs:{"type":"text","placeholder":"Tax Rate"},domProps:{"value":(_vm.tax.tax_rate)},on:{"keypress":function($event){return _vm.isNumber($event)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.tax, "tax_rate", $event.target.value)}}}),_vm._v(" "),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.errors.tax_rate)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                        "+_vm._s(_vm.errors.tax_rate[0])+"\n                                    ")]):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Account Receivable")]),_vm._v(" "),_c('selectize',{staticClass:"form-control mt-1",class:{
                                        'is-invalid-selectize':
                                            _vm.errors.tax_coa_ar,
                                    },attrs:{"placeholder":"Choose Account No."},model:{value:(_vm.tax.tax_coa_ar),callback:function ($$v) {_vm.$set(_vm.tax, "tax_coa_ar", $$v)},expression:"tax.tax_coa_ar"}},_vm._l((_vm.receivableAccounts),function(account){return _c('option',{key:account.id,domProps:{"value":account.id}},[_vm._v("\n                                        "+_vm._s(account.account_no)+"\n                                        "+_vm._s(account.account_name)+"\n                                    ")])}),0),_vm._v(" "),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.errors.tax_coa_ar)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                        "+_vm._s(_vm.errors.tax_coa_ar[0])+"\n                                    ")]):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Account Payable")]),_vm._v(" "),_c('selectize',{staticClass:"form-control mt-1",class:{
                                        'is-invalid-selectize':
                                            _vm.errors.tax_coa_ap,
                                    },attrs:{"placeholder":"Choose Account No."},model:{value:(_vm.tax.tax_coa_ap),callback:function ($$v) {_vm.$set(_vm.tax, "tax_coa_ap", $$v)},expression:"tax.tax_coa_ap"}},_vm._l((_vm.payableAccounts),function(account){return _c('option',{key:account.id,domProps:{"value":account.id}},[_vm._v("\n                                        "+_vm._s(account.account_no)+"\n                                        "+_vm._s(account.account_name)+"\n                                    ")])}),0),_vm._v(" "),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.errors.tax_coa_ap)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                        "+_vm._s(_vm.errors.tax_coa_ap[0])+"\n                                    ")]):_vm._e()])],1)])]),_vm._v(" "),_c('div',{staticClass:"row items-push"},[_c('div',{staticClass:"col-12 text-center"},[_c('p',{staticClass:"font-size-sm text-muted"},[_vm._v("\n                                * Require to be filled, Make sure all data is\n                                correctly filled\n                            ")]),_vm._v(" "),_c('button',{directives:[{name:"ripple",rawName:"v-ripple",value:({ center: true }),expression:"{ center: true }"}],staticClass:"btn btn-sm btn-success",attrs:{"type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('i',{staticClass:"fa fa-fw fa-check mr-1"}),_vm._v(" Save\n                            ")]),_vm._v(" "),_c('router-link',{directives:[{name:"ripple",rawName:"v-ripple",value:({ center: true }),expression:"{ center: true }"}],staticClass:"btn btn-sm btn-alt-light",attrs:{"type":"button","tag":"button","to":{ name: 'tax' }}},[_c('i',{staticClass:"fa fa-fw fa-times mr-1"}),_vm._v(" Close\n                            ")])],1)])])])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }