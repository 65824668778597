<template>
    <main>
        <!-- Page Content -->
        <div class="content p-0">
            <!--Loading-->
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 1"
                style="height:250px"
            >                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity:0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 2"
                style="height:250px"
            >                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity:0.7"
                            >Save Data</span
                        >
                    </center>
                </div>
            </div>
            <!-- Basic -->
            <div class="block col-12 mb-0" v-if="loadingPage == 0">
                <div class="block-content block-content-full p-0">
                    <form
                        method="POST"
                        enctype="multipart/form-data"
                        onsubmit="return false;"
                    >
                        <!--Form-->
                        <div class="row font-size-sm">
                            <div class="col-12 col-md-10 offset-md-1">
                                <div
                                    class="alert alert-info d-flex align-items-center"
                                    role="alert"
                                    v-if="coa.account_level == 1"
                                >
                                    <div class="flex-00-auto">
                                        <i class="fa fa-fw fa-info-circle"></i>
                                    </div>
                                    <div class="flex-fill ml-3">
                                        <p class="mb-0">
                                            This account is Level 1 Account, you
                                            only can edit this Account Name
                                        </p>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="d-block">Account Role</label>
                                    <div class="form-check form-check-inline">
                                        <input
                                            class="form-check-input"
                                            type="radio"
                                            v-model="coa.account_role"
                                            id="header"
                                            @change="setType()"
                                            value="Header"
                                            :checked="coa.account_level == 1"
                                            :disabled="
                                                coa.account_level == 1 ||
                                                    coa.account_link == true
                                            "
                                        />
                                        <label
                                            class="form-check-label"
                                            for="header"
                                            >Header Account</label
                                        >
                                    </div>
                                    <div
                                        class="form-check form-check-inline"
                                        v-if="coa.account_level != 1"
                                    >
                                        <input
                                            class="form-check-input"
                                            type="radio"
                                            v-model="coa.account_role"
                                            id="detail"
                                            @change="setType()"
                                            value="Detail"
                                            :disabled="
                                                coa.account_level == 1 ||
                                                    coa.account_link == true
                                            "
                                        />
                                        <label
                                            class="form-check-label"
                                            for="detail"
                                            >Detail Account</label
                                        >
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Account Type</label
                                    >
                                    <selectize
                                        class="form-control"
                                        v-if="coa.account_role == 'Header'"
                                        placeholder="Account Type"
                                        v-model="coa.account_type"
                                        @input="setMask()"
                                        :disabled="
                                            coa.account_level == 1 ||
                                                coa.account_link == true
                                        "
                                    >
                                        <!-- settings is optional -->
                                        <option value="Asset">Asset</option>
                                        <option value="Liability"
                                            >Liability</option
                                        >
                                        <option value="Equity">Equity</option>
                                        <option value="Income">Income</option>
                                        <option value="Cost of Sales"
                                            >Cost of Sales</option
                                        >
                                        <option value="Expense">Expense</option>
                                        <option value="Other Income"
                                            >Other Income</option
                                        >
                                        <option value="Other Expense"
                                            >Other Expense</option
                                        >
                                    </selectize>
                                    <selectize
                                        class="form-control"
                                        v-else
                                        placeholder="Account Type"
                                        v-model="coa.account_type"
                                        @input="setMask()"
                                        :disabled="
                                            coa.account_level == 1 ||
                                                coa.account_link == true
                                        "
                                    >
                                        <!-- settings is optional -->
                                        <option value="Bank">Bank</option>
                                        <option value="Account Receivable"
                                            >Account Receivable</option
                                        >
                                        <option value="Other Current Asset"
                                            >Other Current Asset</option
                                        >
                                        <option value="Fixed Asset"
                                            >Fixed Asset</option
                                        >
                                        <option value="Other Asset"
                                            >Other Asset</option
                                        >
                                        <option value="Credit Card"
                                            >Credit Card</option
                                        >
                                        <option value="Account Payable"
                                            >Account Payable</option
                                        >
                                        <option value="Other Current Liability"
                                            >Other Current Liability</option
                                        >
                                        <option value="Long Term Liability"
                                            >Long Term Liability</option
                                        >
                                        <option value="Other Liability"
                                            >Other Liability</option
                                        >
                                        <option value="Equity">Equity</option>
                                        <option value="Income">Income</option>
                                        <option value="Cost of Sales"
                                            >Cost of Sales</option
                                        >
                                        <option value="Expense">Expense</option>
                                        <option value="Other Income"
                                            >Other Income</option
                                        >
                                        <option value="Other Expense"
                                            >Other Expense</option
                                        >
                                    </selectize>
                                    <transition name="slide-fade">
                                        <p
                                            class="text-danger"
                                            v-if="errors.account_type"
                                        >
                                            {{ errors.account_type[0] }}
                                        </p>
                                    </transition>
                                </div>
                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Account No</label
                                    >
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">
                                                {{ prefix }}
                                            </span>
                                        </div>
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Account No."
                                            v-model="postfix"
                                            @input="mustNumber"
                                            maxlength="4"
                                            :class="{
                                                'is-invalid':
                                                    errors.account_no || exist
                                            }"
                                            :disabled="coa.account_level == 1"
                                        />
                                    </div>
                                    <transition name="slide-fade">
                                        <p
                                            class="text-danger"
                                            v-if="errors.account_no"
                                        >
                                            {{ errors.account_no[0] }}
                                        </p>
                                        <p class="text-danger" v-if="exist">
                                            Account No. has already been taken!
                                        </p>
                                    </transition>
                                </div>
                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Account Name</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Account Name"
                                        v-model="coa.account_name"
                                        :class="{
                                            'is-invalid': errors.account_name
                                        }"
                                    />
                                    <transition name="slide-fade">
                                        <p
                                            class="text-danger"
                                            v-if="errors.account_name"
                                        >
                                            {{ errors.account_name[0] }}
                                        </p>
                                    </transition>
                                </div>
                                <div
                                    class="form-group"
                                    v-if="coa.account_level != 1"
                                >
                                    <label for="example-text-input"
                                        >Account Level</label
                                    >
                                    <selectize
                                        class="form-control"
                                        placeholder="Account Type"
                                        v-model="coa.account_level"
                                    >
                                        <option
                                            v-for="(value, index) in level_list"
                                            :value="value"
                                            :key="index"
                                            >{{ value }}</option
                                        >
                                    </selectize>
                                    <transition name="slide-fade">
                                        <p
                                            class="text-danger"
                                            v-if="errors.account_level"
                                        >
                                            {{ errors.account_level[0] }}
                                        </p>
                                    </transition>
                                </div>
                                <transition name="slide-fade">
                                    <div
                                        class="form-group"
                                        v-if="coa.account_role == 'Detail'"
                                        data-toggle="appear"
                                        data-class="animated bounceIn"
                                    >
                                        <label for="example-text-input"
                                            >Opening Balance</label
                                        >
                                        <money
                                            class="form-control"
                                            style="text-align: right;"
                                            v-model="
                                                coa.account_opening_balance
                                            "
                                            spellcheck="false"
                                            :class="{
                                                'is-invalid':
                                                    errors.account_opening_balance
                                            }"
                                        ></money>
                                        <transition name="slide-fade">
                                            <p
                                                class="text-danger"
                                                v-if="
                                                    errors.account_opening_balance
                                                "
                                            >
                                                {{
                                                    errors
                                                        .account_opening_balance[0]
                                                }}
                                            </p>
                                        </transition>
                                    </div>
                                </transition>
                            </div>
                        </div>
                        <!--END Form-->
                        <!-- Submit -->
                        <div class="row items-push">
                            <div class="col-12 text-center mb-0">
                                <p class="font-size-sm text-muted">
                                    * Require to be filled, Make sure all data is
                                    correctly filled
                                </p>
                                <button
                                    type="submit"
                                    class="btn btn-sm btn-success"
                                    v-ripple="{ center: true }"
                                    :disabled="exist"
                                    @click.prevent="submitData"
                                >
                                    <i class="fa fa-fw fa-check mr-1"></i>
                                    Update
                                </button>
                                <button
                                    v-if="coa.account_level != 1"
                                    type="button"
                                    class="btn btn-sm btn-danger"
                                    v-ripple="{ center: true }"
                                    @click.prevent="deleteData"
                                >
                                    <i class="fa fa-fw fa-trash mr-1"></i>
                                    Delete
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-sm btn-alt-light"
                                    v-ripple="{ center: true }"
                                    @click.prevent="close"
                                >
                                    <i class="fa fa-fw fa-times mr-1"></i> Close
                                </button>
                            </div>
                        </div>
                        <!-- END Submit -->
                    </form>
                </div>
            </div>
            <!-- END Basic -->
        </div>
        <!-- END Page Content -->
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

export default {
    name: "edit-coa",
    props: ["coaId"],
    data() {
        return {
            loadingPage: 1,
            prefix: "1-",
            postfix: "",
            exist: false,
            level_list: [],
            level_list_original: []
        };
    },
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState("coa", {
            coa: state => state.coa
        })
    },
    mounted() {
        if (this.coaId) {
            this.editCoa(this.coaId)
                .then(response => {
                    (this.prefix = response.data.account_no.substring(0, 2)),
                        (this.postfix = response.data.account_no.substring(2)),
                        this.getAvailableLevel(this.coaId)
                            .then(response => {
                                this.level_list_original = response.data;
                                if (this.coa.account_role === "Header") {
                                    this.level_list = this.level_list_original.filter(
                                        val => {
                                            return val !== 1;
                                        }
                                    );
                                } else {
                                    this.level_list = this.level_list_original.filter(
                                        val => {
                                            return val !== 1;
                                        }
                                    );
                                }
                                this.loadingPage = 0;
                            })
                            .catch(error => {
                                console.log(error);
                                this.loadingPage = 0;
                            });
                })
                .catch(error => {
                    console.log(error);
                    this.loadingPage = 0;
                });
        } else {
            // this.$router.push({ name: 'coa' })
            this.alert("Data not found, select data first", 2);
        }
    },
    methods: {
        //VUEX
        ...mapMutations(["CLEAR_ERRORS"]),
        ...mapMutations("coa", ["CLEAR_FORM"]),
        ...mapActions("coa", [
            "editCoa",
            "updateCoa",
            "removeCoa",
            "checkCoaNo",
            "getAvailableLevel"
        ]),

        checkNo() {
            this.coa.account_no = this.prefix + this.postfix;
            if (this.postfix == "") {
                this.exist = false;
                return;
            }
            this.checkCoaNo({
                prefix: this.prefix,
                postfix: this.postfix
            }).then(response => {
                response !== "" ? (this.exist = true) : (this.exist = false);
            });
        },
        setType() {
            if (this.coa.account_role === "Header") {
                this.coa.account_type = "Asset";
                this.setMask();
                this.getAvailableLevel(this.coaId)
                    .then(response => {
                        this.level_list_original = response.data;
                        if (this.coa.account_role === "Header") {
                            this.level_list = this.level_list_original.filter(
                                val => {
                                    return val !== 1;
                                }
                            );
                        } else {
                            this.level_list = this.level_list_original.filter(
                                val => {
                                    return val !== 1;
                                }
                            );
                        }
                        this.loadingPage = 0;
                    })
                    .catch(error => {
                        console.log(error);
                        this.loadingPage = 0;
                    });
            } else {
                this.coa.account_type = "Bank";
                this.setMask();
                this.getAvailableLevel(this.coaId)
                    .then(response => {
                        this.level_list_original = response.data;
                        if (this.coa.account_role === "Header") {
                            this.level_list = this.level_list_original.filter(
                                val => {
                                    return val !== 1;
                                }
                            );
                        } else {
                            this.level_list = this.level_list_original.filter(
                                val => {
                                    return val !== 1;
                                }
                            );
                        }
                        this.loadingPage = 0;
                    })
                    .catch(error => {
                        console.log(error);
                        this.loadingPage = 0;
                    });
            }
        },
        setMask() {
            switch (this.coa.account_type) {
                case "Asset":
                case "Bank":
                case "Account Receivable":
                case "Other Current Asset":
                case "Fixed Asset":
                case "Other Asset":
                    this.prefix = "1-";
                    break;
                case "Liability":
                case "Credit Card":
                case "Account Payable":
                case "Other Current Liability":
                case "Long Term Liability":
                case "Other Liability":
                    this.prefix = "2-";
                    break;
                case "Equity":
                    this.prefix = "3-";
                    break;
                case "Income":
                    this.prefix = "4-";
                    break;
                case "Cost of Sales":
                    this.prefix = "5-";
                    break;
                case "Expense":
                    this.prefix = "6-";
                    break;
                case "Other Income":
                    this.prefix = "8-";
                    break;
                default:
                    this.prefix = "9-";
            }
        },
        mustNumber() {
            // Only for postfix account no. to prevent user input alphabet
            this.postfix = this.postfix.replace(/[^0-9]/g, "");
            this.checkNo();
        },
        submitData() {
            this.loadingPage = 2;
            this.updateCoa(this.coaId)
                .then(() => {
                    this.$root.$emit("refreshCoa");
                    this.$modal.hide("edit-coa");
                    this.alert("Successfully Update Data", 1);
                    this.loadingPage = 0;
                })
                .catch(error => {
                    error.response.status == 500
                        ? this.alert(error.response.data.message, 2)
                        : "";
                    error.response.status != 422 && error.response.status != 500
                        ? this.alert(error.message, 2)
                        : "";
                    console.log(error);
                    this.loadingPage = 0;
                });
        },
        deleteData() {
            this.$swal({
                title: "Are you sure ?",
                text: "Deleted data cannot be recovery",
                icon: "warning",
                buttons: ["Cancel", "Delete"],
                dangerMode: true
            }).then(willDelete => {
                if (willDelete) {
                    this.removeCoa(this.coaId)
                        .then(() => {
                            this.$root.$emit("refreshCoa");
                            this.$modal.hide("edit-coa");
                            this.alert("Data has been deleted !", 1);
                            this.loadingPage = 0;
                        })
                        .catch(error => {
                            error.response.status == 500
                                ? this.alert(error.response.data.message, 2)
                                : "";
                            error.response.status != 422 &&
                            error.response.status != 500
                                ? this.alert(error.message, 2)
                                : "";
                            console.log(error);
                            this.loadingPage = 0;
                        });
                }
            });
        },
        close() {
            this.$modal.hide("edit-coa");
            document.body.classList.remove("modal-open");
        }
    },
    destroyed() {
        //FORM DI BERSIHKAN
        this.CLEAR_FORM();
        this.CLEAR_ERRORS();
    }
};
</script>
