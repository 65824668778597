import Vue from "vue";
import $axios from "../../api.js";

const state = () => ({
    mutations: [],

    mutation: {
        mutation_id: 0,
        warehouse_id: 0,
        journal_id: 0,
        source_id: "",
        mutation_type: "1",
        mutation_no: "",
        mutation_date: Vue.moment().format("YYYY-MM-DD HH:mm"),
        mutation_memo: "",
        mutation_total: 0,
        user_id: 0,
        warehouse: {},
        journal: {},
        source: {},
        detail: [
            {
                item_id: 0,
                coa_id: 0,
                in_qty: 0,
                in_qoh: 0,
                in_amount: 0,
                in_price: 0,
                out_qty: 0,
                out_qoh: 0,
                out_amount: 0,
                out_price: 0,
                item_uom_list: [],
                item_uom_id: 0,
                error_cogs: false
            }
        ]
    },
    page: 1, //UNTUK MENCATAT PAGE PAGINATE YANG SEDANG DIAKSES
    last_page: 1,
    search: ""
});

const mutations = {
    ASSIGN_DATA(state, payload) {
        state.mutations = payload;
        state.last_page = payload.meta.last_page;
        state.page = payload.meta.current_page;
    },
    SET_PAGE(state, payload) {
        state.page = payload;
    },
    ASSIGN_FORM(state, payload) {
        state.mutation = {
            mutation_id: payload.id,
            warehouse_id: payload.warehouse_id,
            journal_id: payload.journal_id,
            source_id: payload.source_id,
            mutation_type: payload.mutation_type,
            mutation_no: payload.mutation_no,
            mutation_date: payload.mutation_date,
            mutation_memo: payload.mutation_memo,
            user_id: 0,
            warehouse: payload.warehouse,
            journal: payload.journal,
            source: payload.source,
            detail: payload.detail
        };
    },
    //ME-RESET STATE NEWS MENJADI KOSONG
    CLEAR_FORM(state) {
        state.mutation = {
            mutation_id: 0,
            warehouse_id: 0,
            journal_id: 0,
            source_id: "",
            mutation_type: "1",
            mutation_no: "",
            mutation_date: Vue.moment().format("YYYY-MM-DD HH:mm"),
            mutation_memo: "",
            mutation_total: 0,
            user_id: 0,
            warehouse: {},
            journal: {},
            source: {},
            detail: [
                {
                    item_id: 0,
                    coa_id: 0,
                    in_qty: 0,
                    in_qoh: 0,
                    in_amount: 0,
                    in_price: 0,
                    out_qty: 0,
                    out_qoh: 0,
                    out_amount: 0,
                    out_price: 0,
                    item_uom_list: [],
                    item_uom_id: 0,
                    error_cogs: false
                }
            ]
        };
    },
    CLEAR_DATA(state) {
        state.mutations = [];
    }
};

const actions = {
    getMutationInNo({ dispatch, commit, state }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .get(
                    `/mutation/in/get-mutation-no/` +
                        state.mutation.mutation_date
                )
                .then(response => {
                    state.mutation.mutation_no = response.data;
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    console.log(error);
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                });
        });
    },
    getMutationOutNo({ dispatch, commit, state }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .get(
                    `/mutation/out/get-mutation-no/` +
                        state.mutation.mutation_date
                )
                .then(response => {
                    state.mutation.mutation_no = response.data;
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    console.log(error);
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                });
        });
    },
    getMutation({ dispatch, commit, state }, payload) {
        let warehouse = payload.warehouse;
        let type = payload.type;
        let rows = payload.rows;
        let page =
            typeof payload.rows != "All" && payload.rowsUpdate != 1
                ? state.page
                : "1";

        return new Promise((resolve, reject) => {
            $axios
                .get(
                    `/mutation?page=${page}&warehouse=${warehouse}&rows=${rows}&type=${type}`
                )
                .then(response => {
                    commit("ASSIGN_DATA", response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                    reject(error);
                });
        });
    },
    reviewMutation({ dispatch, commit, state }) {
        return new Promise((resolve, reject) => {
            $axios
                .post(`/mutation/review`, state.mutation)
                .then(response => {
                    commit("ASSIGN_FORM", response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                });
        });
    },
    submitMutation({ dispatch, commit, state }) {
        return new Promise((resolve, reject) => {
            $axios
                .post(`/mutation`, state.mutation)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                });
        });
    },
    editMutation({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.get(`/mutation/${payload}/edit`).then(response => {
                commit("ASSIGN_FORM", response.data);
                resolve(response.data);
            });
        });
    },
    updateMutation({ state, commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .put(`/mutation/${payload}`, state.mutation)
                .then(response => {
                    //FORM DIBERSIHKAN
                    commit("CLEAR_FORM");
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                });
        });
    },
    //MENGHAPUS DATA
    removeMutation({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .delete(`/mutation/${payload}`)
                .then(response => {
                    commit("CLEAR_FORM");
                    resolve(response.data);
                })
                .catch(error => {
                    if (error.response) {
                        commit("SET_ERRORS", error.response.data, {
                            root: true
                        });
                    }
                    reject(error);
                });
        });
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
