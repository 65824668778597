<template>
    <div id="tracked-item" class="text-xs">
        <div class="container py-1">
            <div class="row">
                <div
                    class="col-12 font-weight-bold mb-0 py-2 px-0"
                    style="font-size:15px"
                >
                    Investing Activities
                </div>
            </div>

            <div
                class="row"
                v-for="(account, index) in cashFlow.data.investingActivities"
                :key="index"
            >
                <div class="col-5 pb-1 pl-0 pr-1">
                    {{account.account_no}} - {{account.account_name}}
                </div>
                <div class="col-3 pb-1 px-1 text-right">
                    <span v-if="account.mt_total != 0 && account.mt_total > 0">
                        {{ account.mt_total | currency }}
                    </span>
                    <span v-else-if="account.mt_total != 0 && account.mt_total < 0">
                        ({{ Math.abs(account.mt_total) | currency}})
                    </span>
                    <span v-else>
                        {{0 | currency}}
                    </span>
                </div>
                <div class="pb-1 px-1 text-right" v-bind:class="{ 'col-4': period == 1, 'col-2': period == 2 }">
                    <span v-if="cashFlow.data.investingActivities1[index].mt_total != 0 && cashFlow.data.investingActivities1[index].mt_total > 0">
                        {{ cashFlow.data.investingActivities1[index].mt_total | currency }}
                    </span>
                    <span v-else-if="cashFlow.data.investingActivities1[index].mt_total != 0 && cashFlow.data.investingActivities1[index].mt_total < 0">
                        ({{ Math.abs(cashFlow.data.investingActivities1[index].mt_total) | currency}})
                    </span>
                    <span v-else>
                        {{0 | currency}}
                    </span>
                </div>
                <div class="col-2 px-1 pb-1 text-right" v-if="period == 2">
                    <span v-if="cashFlow.data.investingActivities2[index].mt_total != 0 && cashFlow.data.investingActivities2[index].mt_total > 0">
                        {{ cashFlow.data.investingActivities2[index].mt_total | currency }}
                    </span>
                    <span v-else-if="cashFlow.data.investingActivities2[index].mt_total != 0 && cashFlow.data.investingActivities2[index].mt_total < 0">
                        ({{ Math.abs(cashFlow.data.investingActivities2[index].mt_total) | currency}})
                    </span>
                    <span v-else>
                        {{0 | currency}}
                    </span>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

export default {
    name: "operating-activities",
    props: ["period"],
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState(["auth"]),
        ...mapState("signature", { signatures: state => state.signatures }),
        ...mapState("report", { cashFlow: state => state.cashFlow })
    },
};
</script>
