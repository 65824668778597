<template>
    <main>
        <hero
            title="Cash Flow"
            prevBreadcrumb="Report"
            routeBreadcrumb="report"
            currentBreadcrumb="Cash Flow"
        ></hero>

        <div class="content">
            <div class="block mb-4 block-rounded block-mode-loading-oneui">
                <div class="block-content">
                    <div class="row">
                        <div class="form-group col-2 pb-0 pt-1">
                            <label for="example-text-input">Cash Flow</label>
                            <date-picker
                                class="input-group"
                                v-model="date_start"
                                format="DD/MM/YYYY"
                                valueType="YYYY-MM-DD"
                                placeholder="Choose Date"
                                :class="{
                                    'is-invalid': errors.date_start
                                }"
                            ></date-picker>
                            <transition name="slide-fade">
                                <p class="text-danger" v-if="errors.date_start">
                                    {{ errors.date_start[0] }}
                                </p>
                            </transition>
                        </div>
                        <div class="form-group col-2 pb-0 pt-1">
                            <label for="example-text-input">To</label>
                            <date-picker
                                class="input-group"
                                v-model="date_end"
                                format="DD/MM/YYYY"
                                valueType="YYYY-MM-DD"
                                placeholder="Choose Date"
                                :class="{
                                    'is-invalid': errors.date_end
                                }"
                            ></date-picker>
                            <transition name="slide-fade">
                                <p class="text-danger" v-if="errors.date_end">
                                    {{ errors.date_end[0] }}
                                </p>
                            </transition>
                        </div>
                        <div class="form-group col-3 pb-0 pt-1">
                            <label for="example-text-input"
                                >Compare Between</label
                            >
                            <selectize
                                class="form-control"
                                placeholder="Select Item"
                                v-model="period"
                                :class="{
                                    'is-invalid-selectize': errors.period
                                }"
                                @input="getData"
                            >
                                <option value="1">
                                    1 Period
                                </option>
                                <option value="2">
                                    2 Period
                                </option>
                            </selectize>
                            <transition name="slide-fade">
                                <p class="text-danger" v-if="errors.period">
                                    {{ errors.period[0] }}
                                </p>
                            </transition>
                        </div>
                        <div class="form-group col-5 pb-0 pt-1 text-right">
                            <button
                                type="button"
                                class="btn btn-sm btn-outline-info mt-3 mr-1"
                                v-ripple="{ center: true }"
                                @click.prevent="xlsReport"
                            >
                                <i class="fa fa-r fa-file-excel mr-1"></i>
                                Export to Excel
                            </button>
                            <button
                                type="button"
                                class="btn btn-sm btn-outline-info mt-3 mr-1"
                                v-ripple="{ center: true }"
                                @click.prevent="xlsReport"
                            >
                                <i class="fa fa-r fa-file-excel mr-1"></i>
                                Print
                            </button>
                            <button
                                type="button"
                                class="btn btn-sm btn-info mt-3 mr-1"
                                v-ripple="{ center: true }"
                                @click.prevent="getData"
                            >
                                <i class="fa fa-r fa-file-excel mr-1"></i>
                                Update
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <!--Loading-->
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 1"
                style="height: 250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Generate Report</span
                        >
                    </center>
                </div>
            </div>

            <div v-else class="block block-rounded block-mode-loading-oneui">
                <div class="content content-boxed" id="printMe">
                    <div class="block-content">
                        <div
                            class="p-0 p-md-2 p-print"
                            style="min-height: 800px"
                        >
                            <div class="row pt-0" v-if="useHeader">
                                <div class="col-6 font-size-sm pb-0 pt-0">
                                    <p class="h3 mb-2 mt-3 text-uppercase">
                                        {{
                                            auth.data.workspace
                                                .workspace_company_name
                                        }}
                                    </p>
                                    <p class="mb-0">
                                        {{
                                            auth.data.workspace
                                                .workspace_company_address
                                        }}
                                    </p>
                                    <p class="mb-0">
                                        <span
                                            v-if="
                                                auth.data.workspace
                                                    .workspace_company_province !==
                                                    '' &&
                                                    auth.data.workspace
                                                        .workspace_company_province !==
                                                        null
                                            "
                                        >
                                            {{
                                                auth.data.workspace
                                                    .workspace_company_province
                                            }}
                                        </span>
                                        <span
                                            v-if="
                                                auth.data.workspace
                                                    .workspace_company_postal !==
                                                    '' &&
                                                    auth.data.workspace
                                                        .workspace_company_postal !==
                                                        null
                                            "
                                        >
                                            ,
                                            {{
                                                auth.data.workspace
                                                    .workspace_company_postal
                                            }}
                                        </span>
                                    </p>
                                    <p class="mb-0">
                                        <span
                                            v-if="
                                                auth.data.workspace
                                                    .workspace_company_phone !==
                                                    '' &&
                                                    auth.data.workspace
                                                        .workspace_company_phone !==
                                                        null
                                            "
                                        >
                                            {{
                                                auth.data.workspace
                                                    .workspace_company_phone
                                            }}
                                        </span>
                                        <span
                                            v-if="
                                                auth.data.workspace
                                                    .workspace_company_email !==
                                                    '' &&
                                                    auth.data.workspace
                                                        .workspace_company_email !==
                                                        null
                                            "
                                        >
                                            {{
                                                "| " +
                                                    auth.data.workspace
                                                        .workspace_company_email
                                            }}
                                        </span>
                                    </p>
                                </div>
                                <div
                                    class="col-6 text-right font-size-sm pb-0 pt-0"
                                >
                                    <img
                                        :src="
                                            '/storage/workspace/' +
                                                auth.data.workspace
                                                    .workspace_company_logo
                                        "
                                        alt=""
                                        style="
                                            min-heigh: 100px;
                                            max-height: 120px;
                                            max-width: 180px;
                                        "
                                        @error="errorLogoUrl"
                                    />
                                </div>
                            </div>

                            <hr v-if="useHeader" />

                            <div class="row">
                                <div class="col-5 pb-1">
                                    <p class="m-0 text-muted-high">Account</p>
                                </div>
                                <div class="col-3 pb-1 text-right">
                                    <p class="m-0 text-muted-high">
                                        This Period
                                    </p>
                                    <p class="m-0 text-muted-high">
                                        {{
                                            this.date_start
                                                | moment("DD/MM/YYYY")
                                        }}
                                        sd.
                                    </p>
                                    <p class="m-0 text-muted-high">
                                        {{
                                            this.date_end | moment("DD/MM/YYYY")
                                        }}
                                    </p>
                                </div>
                                <div
                                    class="pb-1 text-right"
                                    v-bind:class="{
                                        'col-4': period == 1,
                                        'col-2': period == 2
                                    }"
                                >
                                    <p class="m-0 text-muted-high">
                                        Last Period
                                    </p>
                                    <p class="m-0 text-muted-high">
                                        {{
                                            this.cashFlow.data.date_start_period_1
                                                | moment("DD/MM/YYYY")
                                        }}
                                        sd.
                                    </p>
                                    <p class="m-0 text-muted-high">
                                        {{
                                            this.cashFlow.data.date_end_period_1 | moment("DD/MM/YYYY")
                                        }}
                                    </p>
                                </div>
                                <div
                                    class="col-2 pb-1 text-right"
                                    v-if="period == 2"
                                >
                                    <p class="m-0 text-muted-high">
                                        Last 2 Period
                                    </p>
                                    <p class="m-0 text-muted-high">
                                        {{
                                            this.cashFlow.data.date_start_period_2
                                                | moment("DD/MM/YYYY")
                                        }}
                                        sd.
                                    </p>
                                    <p class="m-0 text-muted-high">
                                        {{
                                            this.cashFlow.data.date_end_period_2 | moment("DD/MM/YYYY")
                                        }}
                                    </p>
                                </div>
                            </div>
                            <hr class="mt-0" />
                            <OperatingActivities
                                class="mb-3"
                                :period="period"
                            ></OperatingActivities>
                            <InvestingActivities
                                class="mb-3"
                                :period="period"
                            ></InvestingActivities>
                            <FinancingActivities
                                class="mb-3"
                                :period="period"
                            ></FinancingActivities>

                            <hr class="mb-0" />

                            <div id="total-section" class="text-xs">
                                <div class="container py-1">
                                    <div class="row">
                                        <div class="col-5 pb-1 pl-0 pr-1 font-weight-bold">
                                            Net Increase (Decrease) in Cash
                                        </div>
                                        <div class="col-3 pb-1 px-1 text-right font-weight-bold">
                                            <span
                                                v-if="
                                                        cashFlow.data
                                                            .mtGrandTotal >=
                                                        0
                                                "
                                            >
                                                {{
                                                    (cashFlow.data
                                                            .mtGrandTotal)
                                                        | currency
                                                }}
                                            </span>
                                            <span
                                                v-if="
                                                        cashFlow.data
                                                            .mtGrandTotal <
                                                        0
                                                "
                                            >
                                                ({{
                                                    Math.abs(
                                                            cashFlow.data
                                                                .mtGrandTotal
                                                    ) | currency
                                                }})
                                            </span>
                                        </div>
                                        <div class="pb-1 px-1 text-right font-weight-bold" v-bind:class="{ 'col-4': period == 1, 'col-2': period == 2 }">
                                            <span
                                                v-if="
                                                        cashFlow.data
                                                            .mtGrandTotal1 >=
                                                        0
                                                "
                                            >
                                                {{
                                                    (cashFlow.data
                                                            .mtGrandTotal1)
                                                        | currency
                                                }}
                                            </span>
                                            <span
                                                v-if="
                                                        cashFlow.data
                                                            .mtGrandTotal1 <
                                                        0
                                                "
                                            >
                                                ({{
                                                    Math.abs(
                                                            cashFlow.data
                                                                .mtGrandTotal1
                                                    ) | currency
                                                }})
                                            </span>
                                        </div>
                                        <div class="col-2 pb-1 px-1 text-right font-weight-bold" v-if="period == 2">
                                            <span
                                                v-if="
                                                        cashFlow.data
                                                            .mtGrandTotal2 >=
                                                        0
                                                "
                                            >
                                                {{
                                                    (cashFlow.data
                                                            .mtGrandTotal2)
                                                        | currency
                                                }}
                                            </span>
                                            <span
                                                v-if="
                                                        cashFlow.data
                                                            .mtGrandTotal2 <
                                                        0
                                                "
                                            >
                                                ({{
                                                    Math.abs(
                                                            cashFlow.data
                                                                .mtGrandTotal2
                                                    ) | currency
                                                }})
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-5 pb-1 pl-0 pr-1 font-weight-bold">
                                            Cash on Start Period
                                        </div>
                                        <div class="col-3 pb-1 px-1 text-right font-weight-bold">
                                            <span
                                                v-if="
                                                    cashFlow.data.opGrandTotal >=
                                                        0
                                                "
                                            >
                                                {{
                                                    (cashFlow.data
                                                        .opGrandTotal)
                                                        | currency
                                                }}
                                            </span>
                                            <span
                                                v-if="
                                                    cashFlow.data.opGrandTotal  <
                                                        0
                                                "
                                            >
                                                ({{
                                                    Math.abs(
                                                        cashFlow.data
                                                            .opGrandTotal
                                                    ) | currency
                                                }})
                                            </span>
                                        </div>
                                        <div class="pb-1 px-1 text-right font-weight-bold" v-bind:class="{ 'col-4': period == 1, 'col-2': period == 2 }">
                                            <span
                                                v-if="
                                                    cashFlow.data.opGrandTotal1 >=
                                                        0
                                                "
                                            >
                                                {{
                                                    (cashFlow.data
                                                        .opGrandTotal1)
                                                        | currency
                                                }}
                                            </span>
                                            <span
                                                v-if="
                                                    cashFlow.data.opGrandTotal1  <
                                                        0
                                                "
                                            >
                                                ({{
                                                    Math.abs(
                                                        cashFlow.data
                                                            .opGrandTotal1
                                                    ) | currency
                                                }})
                                            </span>
                                        </div>
                                        <div class="col-2 pb-1 px-1 text-right font-weight-bold" v-if="period == 2">
                                            <span
                                                v-if="
                                                    cashFlow.data.opGrandTotal2 >=
                                                        0
                                                "
                                            >
                                                {{
                                                    (cashFlow.data
                                                        .opGrandTotal2)
                                                        | currency
                                                }}
                                            </span>
                                            <span
                                                v-if="
                                                    cashFlow.data.opGrandTotal2  <
                                                        0
                                                "
                                            >
                                                ({{
                                                    Math.abs(
                                                        cashFlow.data
                                                            .opGrandTotal2
                                                    ) | currency
                                                }})
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-5 pb-1 pl-0 pr-1 font-weight-bold">
                                            Cash on End Period
                                        </div>
                                        <div class="col-3 pb-1 px-1 text-right font-weight-bold">
                                            <span
                                                v-if="
                                                    cashFlow.data.opGrandTotal +
                                                        cashFlow.data
                                                            .mtGrandTotal >=
                                                        0
                                                "
                                            >
                                                {{
                                                    (cashFlow.data
                                                        .opGrandTotal +
                                                        cashFlow.data
                                                            .mtGrandTotal)
                                                        | currency
                                                }}
                                            </span>
                                            <span
                                                v-if="
                                                    cashFlow.data.opGrandTotal +
                                                        cashFlow.data
                                                            .mtGrandTotal <
                                                        0
                                                "
                                            >
                                                ({{
                                                    Math.abs(
                                                        cashFlow.data
                                                            .opGrandTotal +
                                                            cashFlow.data
                                                                .mtGrandTotal
                                                    ) | currency
                                                }})
                                            </span>
                                        </div>
                                        <div class="pb-1 px-1 text-right font-weight-bold" v-bind:class="{ 'col-4': period == 1, 'col-2': period == 2 }">
                                            <span
                                                v-if="
                                                    cashFlow.data.opGrandTotal1 +
                                                        cashFlow.data
                                                            .mtGrandTotal1 >=
                                                        0
                                                "
                                            >
                                                {{
                                                    (cashFlow.data
                                                        .opGrandTotal1 +
                                                        cashFlow.data
                                                            .mtGrandTotal1)
                                                        | currency
                                                }}
                                            </span>
                                            <span
                                                v-if="
                                                    cashFlow.data.opGrandTotal1 +
                                                        cashFlow.data
                                                            .mtGrandTotal1 <
                                                        0
                                                "
                                            >
                                                ({{
                                                    Math.abs(
                                                        cashFlow.data
                                                            .opGrandTotal1 +
                                                            cashFlow.data
                                                                .mtGrandTotal1
                                                    ) | currency
                                                }})
                                            </span>
                                        </div>
                                        <div class="col-2 pb-1 px-1 text-right font-weight-bold" v-if="period == 2">
                                            <span
                                                v-if="
                                                    cashFlow.data.opGrandTotal2 +
                                                        cashFlow.data
                                                            .mtGrandTotal2 >=
                                                        0
                                                "
                                            >
                                                {{
                                                    (cashFlow.data
                                                        .opGrandTotal2 +
                                                        cashFlow.data
                                                            .mtGrandTotal2)
                                                        | currency
                                                }}
                                            </span>
                                            <span
                                                v-if="
                                                    cashFlow.data.opGrandTotal2 +
                                                        cashFlow.data
                                                            .mtGrandTotal2 <
                                                        0
                                                "
                                            >
                                                ({{
                                                    Math.abs(
                                                        cashFlow.data
                                                            .opGrandTotal2 +
                                                            cashFlow.data
                                                                .mtGrandTotal2
                                                    ) | currency
                                                }})
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row my-5">
                                <div class="col-6 text-left font-size-sm">
                                    <div class="form-group d-print-none">
                                        <label for="example-text-input"
                                            >Post Note *</label
                                        >
                                        <ckeditor
                                            tag-name="textarea"
                                            :editor="editor"
                                            v-model="docPostNote"
                                            :config="editorConfig"
                                        ></ckeditor>
                                    </div>
                                    <span
                                        class="d-none d-print-inline-block"
                                        v-html="docPostNote"
                                    ></span>
                                </div>
                                <div class="col-6 font-size-sm text-right">
                                    <div
                                        class="form-group text-left d-print-none"
                                    >
                                        <label for="example-text-input"
                                            >Choose Signature *</label
                                        >
                                        <selectize
                                            class="form-control"
                                            placeholder="Choose Signature"
                                            v-model="selectedSignature"
                                            @input="
                                                selectSignature(
                                                    selectedSignature
                                                )
                                            "
                                        >
                                            <option
                                                v-for="signature in signatures.data"
                                                :value="signature.id"
                                                :key="signature.id"
                                                >{{
                                                    signature.signature_name
                                                }}</option
                                            >
                                        </selectize>
                                    </div>
                                    <p
                                        class="mb-0 font-w700 mt-6 ml-9 text-center"
                                        style="border-bottom:1px solid grey"
                                        v-bind:class="{
                                            'd-print-none':
                                                selectedSignature == ''
                                        }"
                                    >
                                        {{ docSignature.signature_name }}
                                    </p>
                                    <p
                                        class="mb-0 font-w700 ml-9 text-center"
                                        v-bind:class="{
                                            'd-print-none':
                                                selectedSignature == ''
                                        }"
                                    >
                                        {{ docSignature.signature_position }}
                                    </p>
                                </div>
                            </div>

                            <hr />
                            <p
                                class="d-none d-print-inline-block"
                                style="opacity:0.6;"
                            >
                                <i> Printed : {{ dateNow }} </i>
                            </p>
                            <!-- END Footer -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import moment from "moment";
import OperatingActivities from "./OperatingActivities.vue";
import InvestingActivities from "./InvestingActivities.vue";
import FinancingActivities from "./FinancingActivities.vue";
export default {
    name: "cash-flow-report",
    components: {
        OperatingActivities,
        InvestingActivities,
        FinancingActivities
    },
    data() {
        return {
            loadingPage: 1,
            useHeader: true,
            date_start: moment()
                .startOf("year")
                .format("YYYY-MM-DD"),
            date_end: moment().format("YYYY-MM-DD"),
            dateNow: moment().format("MMMM Do YYYY, HH:mm:ss"),
            period: 1,
            selectedSignature: "",
            docSignature: {
                signature_name: "[select signature]",
                signature_position: "[select signature]"
            },
            editor: ClassicEditor,
            editorConfig: {
                toolbar: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "bulletedList",
                    "numberedList",
                    "blockQuote",
                    "undo",
                    "redo",
                    "insertTable"
                ]
            },
            docPostNote: ""
        };
    },
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState(["auth"]),
        ...mapState("signature", { signatures: state => state.signatures }),
        ...mapState("report", { cashFlow: state => state.cashFlow })
    },
    mounted() {
        this.getSignatureDocument({ document: "signature_profit_loss" });
        this.getData();
    },
    methods: {
        ...mapActions("signature", ["getSignatureDocument"]),
        ...mapMutations("report", ["CLEAR_CF"]),
        ...mapActions("report", [
            "generateReportCF",
            "pdfReportCF",
            "xlsReportCF"
        ]),
        getData() {
            this.loadingPage = 1;
            this.generateReportCF({
                date_start: this.date_start,
                date_end: this.date_end,
                period: this.period
            })
                .then(response => {
                    this.loadingPage = 0;
                })
                .catch(error => {
                    console.log(error);
                    this.loadingPage = 0;
                });
        },
        xlsReport() {
            this.loadingPage = 1;
            this.xlsReportCF({
                date_start: this.date_start,
                date_end: this.date_end,
                period: this.period
            })
                .then(response => {
                    this.loadingPage = 0;
                })
                .catch(error => {
                    console.log(error);
                    this.loadingPage = 0;
                });
        },
        errorLogoUrl(event) {
            event.target.src = "/media/workspace/no-logo.jpg";
        }
    }
};
</script>
