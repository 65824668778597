<template>
    <div id="tracked-item">
        <p class="mb-2 h4 font-weight-bold">
            {{ tracked == 1 ? "Tracked Inventory" : "Untracked Inventory" }}
        </p>

        <div class="pl-3 py-1 mb-2" v-for="(item, index) in data" :key="index">
            <div class="row">
                <div
                    class="col-12 font-weight-bold mb-0 py-2 px-0"
                    style="font-size:13px"
                >
                    <span class="pill-item-code">{{ item.item_code }}</span>
                    {{ item.item_name }}
                </div>
            </div>

            <!-- OPENING BALANCE -->
            <div class="row font-weight-bold">
                <div class="col-report-xl col-report-title col-responsive">
                    <p class="m-0">Opening Balance</p>
                </div>
                <div class="col-report col-responsive"></div>
                <div class="col-report-xl col-responsive"></div>
                <div class="col-report-sm col-responsive">
                    <p class="m-0 text-right" v-if="item.obAmountIn > 0">
                        {{ item.obAmountIn | currency }}
                    </p>
                    <p class="m-0 text-right" v-else>-</p>
                </div>
                <div class="col-report-sm col-responsive">
                    <p class="m-0 text-right" v-if="item.obAmountOut > 0">
                        {{ item.obAmountOut | currency }}
                    </p>
                    <p class="m-0 text-right " v-else>-</p>
                </div>
                <div class="col-report-xs col-responsive">
                    <p class="m-0 text-right">
                        {{ item.obQtyIn > 0 ? item.obQtyIn : "-" }}
                    </p>
                </div>
                <div class="col-report-xs col-responsive">
                    <p class="m-0 text-right">
                        {{
                            item.obQtyOut > 0
                                ? "(" + Math.abs(item.obQtyOut) + ")"
                                : "-"
                        }}
                    </p>
                </div>
                <div class="col-report-sm col-responsive">
                    <p class="m-0 text-right" v-if="item.obCogsIn > 0">
                        {{ item.obCogsIn | currency }}
                    </p>
                    <p class="m-0 text-right" v-else>-</p>
                </div>
                <div class="col-report-sm col-responsive">
                    <p class="m-0 text-right" v-if="item.obCogsOut > 0">
                        ({{ item.obCogsOut | currency }})
                    </p>
                    <p class="m-0 text-right" v-else>-</p>
                </div>
                <div class="col-report-sm col-responsive">
                    <p class="m-0 text-right" v-if="item.obSales > 0">
                        {{ item.obSales | currency }}
                    </p>
                    <p class="m-0 text-right" v-else>-</p>
                </div>
                <div class="col-report-sm col-responsive">
                    <p class="m-0 text-right">
                        {{ item.obProfit | currency }}
                    </p>
                </div>
            </div>

            <!-- MUTATION LIST -->
            <div
                class="row"
                v-for="(mutation, indexMutation) in item.datamutation"
                :key="indexMutation"
            >
                <div class="col-report-sm py-2">
                    <p class="m-0">
                        {{ mutation.mutation_date | moment("DD/MM/YYYY") }}
                    </p>
                </div>
                <div class="col-report-2sm py-2">
                    <p class="m-0">
                        {{ mutation.mutation_type == 1 ? "Adjustment" : "" }}
                        {{ mutation.mutation_type == 2 ? "Adjustment" : "" }}
                        {{ mutation.mutation_type == 3 ? "Purchase" : "" }}
                        {{ mutation.mutation_type == 4 ? "Bill" : "" }}
                    </p>
                </div>
                <div class="col-report py-2">
                    <p class="m-0">{{ mutation.mutation_no }}</p>
                </div>
                <div class="col-report-xl py-2">
                    <p class="m-0">{{ mutation.mutation_memo }}</p>
                </div>
                <div class="col-report-sm py-2">
                    <p class="m-0 text-right" v-if="mutation.in_amount > 0">
                        {{ mutation.in_amount | currency }}
                    </p>
                    <p class="m-0 text-right" v-else>-</p>
                </div>
                <div class="col-report-sm py-2">
                    <p class="m-0 text-right" v-if="mutation.out_amount > 0">
                        {{ mutation.out_amount | currency }}
                    </p>
                    <p class="m-0 text-right" v-else>-</p>
                </div>
                <div class="col-report-xs py-2">
                    <p class="m-0 text-right">
                        {{ mutation.in_qty > 0 ? mutation.in_qty : "-" }}
                    </p>
                </div>
                <div class="col-report-xs py-2">
                    <p class="m-0 text-right">
                        {{
                            mutation.out_qty > 0
                                ? "(" + Math.abs(mutation.out_qty) + ")"
                                : "-"
                        }}
                    </p>
                </div>
                <div class="col-report-sm py-2">
                    <p class="m-0 text-right" v-if="mutation.in_price > 0">
                        {{ mutation.in_price | currency }}
                    </p>
                    <p class="m-0 text-right" v-else>-</p>
                </div>
                <div class="col-report-sm py-2">
                    <p class="m-0 text-right" v-if="mutation.out_price > 0">
                        ({{ mutation.out_price | currency }})
                    </p>
                    <p class="m-0 text-right" v-else>-</p>
                </div>
                <div class="col-report-sm py-2">
                    <p class="m-0 text-right" v-if="mutation.salesAmount > 0">
                        {{ mutation.salesAmount | currency }}
                    </p>
                    <p class="m-0 text-right" v-else>-</p>
                </div>
                <div class="col-report-sm py-2">
                    <p class="m-0 text-right">
                        {{ mutation.profitAmount | currency }}
                    </p>
                </div>
            </div>

            <!-- EMPTY MUTATION LIST -->
            <div class="row" v-if="item.datamutation.length == 0">
                <div class="col-12 py-2 px-0 border-top text-center">
                    <p class="m-0 text-muted-high">
                        No Mutation Data
                    </p>
                </div>
            </div>

            <!-- TOTAL MUTATION -->
            <div class="row font-weight-bold">
                <div class="col-report-sm">
                    <p class="m-0">Total</p>
                </div>
                <div class="col-report-2sm"></div>
                <div class="col-report"></div>
                <div class="col-report-xl"></div>
                <div class="col-report-sm">
                    <p class="m-0 text-right" v-if="item.totalAmountIn > 0">
                        {{ item.totalAmountIn | currency }}
                    </p>
                    <p class="m-0 text-right" v-else>-</p>
                </div>
                <div class="col-report-sm">
                    <p class="m-0 text-right" v-if="item.totalAmountOut > 0">
                        {{ item.totalAmountOut | currency }}
                    </p>
                    <p class="m-0 text-right" v-else>-</p>
                </div>
                <div class="col-report-xs">
                    <p class="m-0 text-right">
                        {{ item.totalQtyIn > 0 ? item.totalQtyIn : "-" }}
                    </p>
                </div>
                <div class="col-report-xs">
                    <p class="m-0 text-right">
                        {{
                            item.totalQtyOut > 0
                                ? "(" + Math.abs(item.totalQtyOut) + ")"
                                : "-"
                        }}
                    </p>
                </div>
                <div class="col-report-sm">
                    <p class="m-0 text-right" v-if="item.totalCogsIn > 0">
                        {{ item.totalCogsIn | currency }}
                    </p>
                    <p class="m-0 text-right" v-else>-</p>
                </div>
                <div class="col-report-sm">
                    <p class="m-0 text-right" v-if="item.totalCogsOut > 0">
                        ({{ item.totalCogsOut | currency }})
                    </p>
                    <p class="m-0 text-right" v-else>-</p>
                </div>
                <div class="col-report-sm">
                    <p class="m-0 text-right" v-if="item.totalSales > 0">
                        {{ item.totalSales | currency }}
                    </p>
                    <p class="m-0 text-right" v-else>-</p>
                </div>
                <div class="col-report-sm">
                    <p class="m-0 text-right">
                        {{ item.totalProfit | currency }}
                    </p>
                </div>
            </div>

            <!-- CLOSE BALANCE -->
            <div class="row font-weight-bold">
                <div class="col-report-sm">
                    <p class="m-0">Close Balance</p>
                </div>
                <div class="col-report-2sm"></div>
                <div class="col-report"></div>
                <div class="col-report-xl"></div>
                <div class="col-report-sm">
                    <p class="m-0 text-right" v-if="item.closeAmountIn > 0">
                        {{ item.closeAmountIn | currency }}
                    </p>
                    <p class="m-0 text-right" v-else>-</p>
                </div>
                <div class="col-report-sm">
                    <p class="m-0 text-right" v-if="item.closeAmountOut > 0">
                        {{ item.closeAmountOut | currency }}
                    </p>
                    <p class="m-0 text-right" v-else>-</p>
                </div>
                <div class="col-report-xs">
                    <p class="m-0 text-right">
                        {{ item.closeQtyIn > 0 ? item.closeQtyIn : "-" }}
                    </p>
                </div>
                <div class="col-report-xs">
                    <p class="m-0 text-right">
                        {{
                            item.closeQtyOut > 0
                                ? "(" + Math.abs(item.closeQtyOut) + ")"
                                : "-"
                        }}
                    </p>
                </div>
                <div class="col-report-sm">
                    <p class="m-0 text-right" v-if="item.closeCogsIn > 0">
                        {{ item.closeCogsIn | currency }}
                    </p>
                    <p class="m-0 text-right" v-else>-</p>
                </div>
                <div class="col-report-sm">
                    <p class="m-0 text-right" v-if="item.closeCogsOut > 0">
                        ({{ item.closeCogsOut | currency }})
                    </p>
                    <p class="m-0 text-right" v-else>-</p>
                </div>
                <div class="col-report-sm">
                    <p class="m-0 text-right" v-if="item.closeSales > 0">
                        {{ item.closeSales | currency }}
                    </p>
                    <p class="m-0 text-right" v-else>-</p>
                </div>
                <div class="col-report-sm">
                    <p class="m-0 text-right">
                        {{ item.closeProfit | currency }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "item-list",
    props: ["data", "tracked"]
};
</script>
