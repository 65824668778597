import $axios from "../../api.js";

const state = () => ({
    uoms: [],

    uom: {
        uom_id: 0,
        uom_name: "",
        uom_code: "",
        uom_desc: "",
        uom_status: 1,
        uom_type: 1,
        uom_conversion_head: 0,
        uom_conversion_val: 0,
        user_id: 0
    },
    page: 1, //UNTUK MENCATAT PAGE PAGINATE YANG SEDANG DIAKSES
    last_page: 1,
    search: ""
});

const mutations = {
    ASSIGN_DATA(state, payload) {
        state.uoms = payload;
        state.last_page = payload.meta.last_page;
        state.page = payload.meta.current_page;
    },
    SET_PAGE(state, payload) {
        state.page = payload;
    },
    ASSIGN_FORM(state, payload) {
        state.uom = {
            uom_id: payload.id,
            uom_name: payload.uom_name,
            uom_code: payload.uom_code,
            uom_desc: payload.uom_desc,
            uom_status: payload.uom_status,
            uom_type: payload.uom_type,
            uom_conversion_head: payload.uom_conversion_head,
            uom_conversion_val: payload.uom_conversion_val,
            user_id: 0
        };
    },
    //ME-RESET STATE NEWS MENJADI KOSONG
    CLEAR_FORM(state) {
        state.uom = {
            uom_id: 0,
            uom_name: "",
            uom_code: "",
            uom_desc: "",
            uom_status: 1,
            uom_type: 1,
            uom_conversion_head: 0,
            uom_conversion_val: 0,
            user_id: 0
        };
    },
    CLEAR_DATA(state) {
        state.uoms = [];
    }
};

const actions = {
    getUom({ dispatch, commit, state }, payload) {
        let search = typeof payload.search != "undefined" ? payload.search : "";
        let rows = payload.rows;
        let page =
            typeof payload.rows != "All" && payload.rowsUpdate != 1
                ? state.page
                : "1";

        return new Promise((resolve, reject) => {
            $axios
                .get(`/uom?page=${page}&search=${search}&rows=${rows}`)
                .then(response => {
                    commit("ASSIGN_DATA", response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                    reject(error);
                });
        });
    },
    getUomAll({ dispatch, state }) {
        $axios
            .get(`/uom/retrive-all-data`)
            .then(response => {
                state.uoms = response.data;
                this.loading = false;
            })
            .catch(error => {
                error.response.status === 401
                    ? dispatch("DO_LOGOUT", null, { root: true })
                    : "";
                reject(error);
            });
    },
    getUomBase({ dispatch, state }) {
        $axios
            .get(`/uom/base-uom`)
            .then(response => {
                state.uoms = response.data;
                this.loading = false;
            })
            .catch(error => {
                error.response.status === 401
                    ? dispatch("DO_LOGOUT", null, { root: true })
                    : "";
                reject(error);
            });
    },
    getUomConvension({ dispatch, state }) {
        $axios
            .get(`/uom/convension-uom`)
            .then(response => {
                state.uoms = response.data;
                this.loading = false;
            })
            .catch(error => {
                error.response.status === 401
                    ? dispatch("DO_LOGOUT", null, { root: true })
                    : "";
                reject(error);
            });
    },
    submitUom({ dispatch, commit, state }) {
        return new Promise((resolve, reject) => {
            $axios
                .post(`/uom`, state.uom)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                });
        });
    },
    editUom({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.get(`/uom/${payload}/edit`).then(response => {
                commit("ASSIGN_FORM", response.data);
                resolve(response.data);
            });
        });
    },
    updateUom({ dispatch, state, commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .put(`/uom/${payload}`, state.uom)
                .then(response => {
                    //FORM DIBERSIHKAN
                    commit("CLEAR_FORM");
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                });
        });
    },
    //MENGHAPUS DATA
    removeUom({ dispatch, commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .delete(`/uom/${payload}`)
                .then(response => {
                    commit("CLEAR_FORM");
                    resolve(response.data);
                })
                .catch(error => {
                    if (error.response) {
                        commit("SET_ERRORS", error.response.data, {
                            root: true
                        });
                    }
                    reject(error);
                });
        });
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
