import Vue from "vue";
import VueRouter from "vue-router";
import store from "./store.js";

//PAGE
import Home from "./views/Home.vue";
import Login from "./views/Login.vue";
import Register from "./views/Register.vue";

//USER
import User from "./views/admin/user/User.vue";
import UserCreate from "./views/admin/user/Create.vue";
import UserEdit from "./views/admin/user/Edit.vue";
//NEWS
import News from "./views/admin/news/News.vue";
import NewsCreate from "./views/admin/news/Create.vue";
import NewsEdit from "./views/admin/news/Edit.vue";
//SIGNATURE
import Signature from "./views/admin/signature/Signature.vue";
import SignatureCreate from "./views/admin/signature/Create.vue";
import SignatureEdit from "./views/admin/signature/Edit.vue";
//BANK
import Bank from "./views/admin/bank/Bank.vue";
import BankCreate from "./views/admin/bank/Create.vue";
import BankEdit from "./views/admin/bank/Edit.vue";

//Notification
import Notification from "./views/admin/notification/Notification.vue"; 
import NotificationCreate from "./views/admin/notification/Create.vue"; 
import NotificationEdit from "./views/admin/notification/Edit.vue"; 
import NotificationDetail from "./views/admin/notification/Detail.vue"; 

//ACCOUNT-COA
import Coa from "./views/accounting/coa/Coa.vue";

//ACCOUNT-COA LINKED
import CoaLinked from "./views/accounting/coalinked/CoaLinked.vue";

//ACCOUNT-JOURNAL RECORD
import JournalRecord from "./views/accounting/journal/JournalRecord.vue";
import JournalTransaction from "./views/accounting/journal/JournalTransaction.vue";
import JournalTransactionEdit from "./views/accounting/journal/Edit.vue";
import JournalTransactionVoucher from "./views/accounting/journal/Voucher.vue";
import RecurringJournal from "./views/accounting/journal/RecurringJournal.vue";

//ACCOUNT-BILL
import BillEnter from "./views/accounting/bill/BillEnter.vue";
import BillRegister from "./views/accounting/bill/BillRegister.vue";
import BillEdit from "./views/accounting/bill/Edit.vue";
import BillDetail from "./views/accounting/bill/Detail.vue";

//ACCOUNT-RECEIVE PAYMENT
import ReceivePayment from "./views/accounting/receivepayment/ReceivePayment.vue";
import ReceivePaymentCreate from "./views/accounting/receivepayment/Create.vue";
import ReceivePaymentEdit from "./views/accounting/receivepayment/Edit.vue";
import ReceivePaymentDetail from "./views/accounting/receivepayment/Detail.vue";

//ACCOUNT-EXPENSE
import Expense from "./views/accounting/expense/Expense.vue";
import ExpenseCreate from "./views/accounting/expense/Create.vue";
import ExpenseEdit from "./views/accounting/expense/Edit.vue";
import ExpenseDetail from "./views/accounting/expense/Detail.vue";

//ACCOUNT-INCOME
import Income from "./views/accounting/income/Income.vue";
import IncomeCreate from "./views/accounting/income/Create.vue";
import IncomeEdit from "./views/accounting/income/Edit.vue";
import IncomeDetail from "./views/accounting/income/Detail.vue";

//ACCOUNT-PURCHASE
import PurchaseEnter from "./views/accounting/purchase/PurchaseEnter.vue";
import PurchaseRegister from "./views/accounting/purchase/PurchaseRegister.vue";
import PurchaseEdit from "./views/accounting/purchase/Edit.vue";
import PurchaseDetail from "./views/accounting/purchase/Detail.vue";

//ACCOUNT-RECEIVE PAYMENT
import PayPurchase from "./views/accounting/paypurchase/PayPurchase.vue";
import PayPurchaseCreate from "./views/accounting/paypurchase/Create.vue";
import PayPurchaseEdit from "./views/accounting/paypurchase/Edit.vue";
import PayPurchaseDetail from "./views/accounting/paypurchase/Detail.vue";

//ACCOUNT-ASSET MANAGEMENT
import Asset from "./views/accounting/asset/Asset.vue";
import AssetCreate from "./views/accounting/asset/Create.vue";
import AssetEdit from "./views/accounting/asset/Edit.vue";
import AssetDetail from "./views/accounting/asset/Detail.vue";
import AssetCategory from "./views/accounting/assetcategory/AssetCategory.vue";
import AssetCategoryCreate from "./views/accounting/assetcategory/Create.vue";
import AssetCategoryEdit from "./views/accounting/assetcategory/Edit.vue";
import AssetCategoryDepreciation from "./views/accounting/assetcategory/Depreciation.vue";
import AssetDepreciation from "./views/accounting/assetdepreciation/AssetDepreciation.vue";
import AssetDepreciationCreate from "./views/accounting/assetdepreciation/Create.vue";
import AssetDepreciationDelete from "./views/accounting/assetdepreciation/Delete.vue";
import AssetDepreciationDetail from "./views/accounting/assetdepreciation/Detail.vue";

//INVENTORY
import Item from "./views/inventory/item/Item.vue";
import ItemCreate from "./views/inventory/item/Create.vue";
import ItemEdit from "./views/inventory/item/Edit.vue";
import ItemDetail from "./views/inventory/item/Detail.vue";
import ItemType from "./views/inventory/itemtype/ItemType.vue";
import ItemTypeCreate from "./views/inventory/itemtype/Create.vue";
import ItemTypeEdit from "./views/inventory/itemtype/Edit.vue";
import ItemTypeDetail from "./views/inventory/itemtype/Detail.vue";
import IncomingItem from "./views/inventory/incomingitem/IncomingItem.vue";
import IncomingItemCreate from "./views/inventory/incomingitem/Create.vue";
import IncomingItemEdit from "./views/inventory/incomingitem/Edit.vue";
import IncomingItemDetail from "./views/inventory/incomingitem/Detail.vue";
import OutgoingItem from "./views/inventory/outgoingitem/OutgoingItem.vue";
import OutgoingItemCreate from "./views/inventory/outgoingitem/Create.vue";
import OutgoingItemEdit from "./views/inventory/outgoingitem/Edit.vue";
import OutgoingItemDetail from "./views/inventory/outgoingitem/Detail.vue";
import ProductsServices from "./views/inventory/productsservices/ProductsServices.vue";
import ProductsServicesCreate from "./views/inventory/productsservices/Create.vue";
import ProductsServicesEdit from "./views/inventory/productsservices/Edit.vue";
import ProductsServicesDetail from "./views/inventory/productsservices/Detail.vue";
import AdjustmentDetail from "./views/inventory/adjustment/Detail.vue";
import AdjustmentEdit from "./views/inventory/adjustment/Edit.vue";

//DATA-STORE
import Contact from "./views/datastore/contact/Contact.vue";
import ContactCreate from "./views/datastore/contact/Create.vue";
import ContactEdit from "./views/datastore/contact/Edit.vue";
import Tax from "./views/datastore/tax/Tax.vue";
import TaxCreate from "./views/datastore/tax/Create.vue";
import TaxEdit from "./views/datastore/tax/Edit.vue";
import Warehouse from "./views/datastore/warehouse/Warehouse.vue";
import WarehouseCreate from "./views/datastore/warehouse/Create.vue";
import WarehouseEdit from "./views/datastore/warehouse/Edit.vue";
import WarehouseDetail from "./views/datastore/warehouse/Detail.vue";
import Uom from "./views/datastore/uom/Uom.vue";
import UomCreate from "./views/datastore/uom/Create.vue";
import UomEdit from "./views/datastore/uom/Edit.vue";
import UomDetail from "./views/datastore/uom/Detail.vue";

//MASTER
import Workspace from "./views/master/workspace/Workspace.vue";
import WorkspaceCreate from "./views/master/workspace/Create.vue";
import EditProfile from "./views/master/profile/Profile.vue";
import Activity from "./views/master/activity/Activity.vue";

//REPORT
import Report from "./views/report/Report.vue";
import GeneralLedger from "./views/report/generalledger/GeneralLedger.vue";
import GeneralLedgerDisplay from "./views/report/generalledger/Report.vue";
import TrialBalance from "./views/report/trialbalance/TrialBalance.vue";
import TrialBalanceDisplay from "./views/report/trialbalance/Report.vue";
import BalanceSheet from "./views/report/balancesheet/BalanceSheet.vue";
import BalanceSheetDisplay from "./views/report/balancesheet/Report.vue";
import ProfitLoss from "./views/report/profitloss/ProfitLoss.vue";
import ProfitLossDisplay from "./views/report/profitloss/Report.vue";
import TrialBalanceCumulative from "./views/report/trialbalancecumulative/TrialBalanceCumulative.vue";
import TrialBalanceCumulativeDisplay from "./views/report/trialbalancecumulative/Report.vue";
import BillReport from "./views/report/bill/BillReport.vue";
import BillReportDisplay from "./views/report/bill/Report.vue";
import PurchaseReport from "./views/report/purchase/PurchaseReport.vue";
import PurchaseReportDisplay from "./views/report/purchase/Report.vue";
import ReportItemDetail from "./views/report/itemdetail/ItemDetail.vue";
import ReportItemSummary from "./views/report/itemsummary/ItemSummary.vue";
import ReportCashFlow from "./views/report/cashflow/CashFlow.vue";

import StartNewYear from "./views/accounting/startnewyear/StartNewYear.vue";

import UserGuides from "./views/help/UserGuides.vue";
import TutorialVideo from "./views/help/TutorialVideo.vue";

import NotFound from "./views/NotFound.vue";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: "history",
    routes: [
        {
            path: "/",
            name: "home",
            component: Home,
            meta: { authorize: [] }
        },
        {
            path: "/login",
            name: "login",
            component: Login
        },
        {
            path: "/register",
            name: "register",
            component: Register
        },
        {
            path: "/edit-profile",
            name: "edit-profile",
            component: EditProfile,
            meta: { authorize: [] }
        },
        {
            path: "/user",
            name: "user",
            component: User,
            meta: {
                authorize: ["masterAccount", "devAccount", "administrator"]
            }
        },
        {
            path: "/user/create",
            name: "user-create",
            component: UserCreate,
            meta: {
                authorize: ["masterAccount", "devAccount", "administrator"]
            }
        },
        {
            path: "/user/edit",
            name: "user-edit",
            component: UserEdit,
            meta: {
                authorize: ["masterAccount", "devAccount", "administrator"]
            }
        },
        {
            path: "/news",
            name: "news",
            component: News,
            meta: {
                authorize: ["masterAccount", "devAccount", "administrator"]
            }
        },
        {
            path: "/news/create",
            name: "news-create",
            component: NewsCreate,
            meta: {
                authorize: ["masterAccount", "devAccount", "administrator"]
            }
        },
        {
            path: "/news/edit",
            name: "news-edit",
            component: NewsEdit,
            meta: {
                authorize: ["masterAccount", "devAccount", "administrator"]
            }
        },
        {
            path: "/signature",
            name: "signature",
            component: Signature,
            meta: {
                authorize: ["masterAccount", "devAccount", "administrator"]
            }
        },
        {
            path: "/signature/create",
            name: "signature-create",
            component: SignatureCreate,
            meta: {
                authorize: ["masterAccount", "devAccount", "administrator"]
            }
        },
        {
            path: "/signature/edit",
            name: "signature-edit",
            component: SignatureEdit,
            meta: {
                authorize: ["masterAccount", "devAccount", "administrator"]
            }
        },
        {
            path: "/bank",
            name: "bank",
            component: Bank,
            meta: {
                authorize: ["masterAccount", "devAccount", "administrator"]
            }
        },
        {
            path: "/bank/create",
            name: "bank-create",
            component: BankCreate,
            meta: {
                authorize: ["masterAccount", "devAccount", "administrator"]
            }
        },
        {
            path: "/bank/edit",
            name: "bank-edit",
            component: BankEdit,
            meta: {
                authorize: ["masterAccount", "devAccount", "administrator"]
            }
        },
        {
            path: "/notification",
            name: "notification",
            component: Notification,
            meta: {
                authorize: ["masterAccount", "devAccount", "administrator"]
            }
        },
        {
            path: "/notification/create",
            name: "notification-create",
            component: NotificationCreate,
            meta: {
                authorize: ["masterAccount", "devAccount", "administrator"]
            }
        },
        {
            path: "/notification/edit",
            name: "notification-edit",
            component: NotificationEdit,
            meta: {
                authorize: ["masterAccount", "devAccount", "administrator"]
            }
        },
        {
            path: "/notification/detail",
            name: "notification-detail",
            component: NotificationDetail,
            meta: {
                authorize: ["masterAccount", "devAccount", "administrator"]
            }
        },
        {
            path: "/coa",
            name: "coa",
            component: Coa,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffAccount"
                ]
            }
        },
        {
            path: "/coa-linked",
            name: "coa-linked",
            component: CoaLinked,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffAccount"
                ]
            }
        },
        {
            path: "/journal-record",
            name: "journal-record",
            component: JournalRecord,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffJournal"
                ]
            }
        },
        {
            path: "/journal-transaction",
            name: "journal-transaction",
            component: JournalTransaction,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffJournal"
                ]
            }
        },
        {
            path: "/journal-transaction/edit",
            name: "journal-transaction-edit",
            component: JournalTransactionEdit,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffJournal"
                ]
            }
        },
        {
            path: "/journal-transaction/voucher",
            name: "journal-transaction-voucher",
            component: JournalTransactionVoucher,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffJournal",
                    "accStaffBill",
                    "accStaffBanking",
                    "accStaffPurchase",
                    "accStaffPettyCash"
                ]
            }
        },
        {
            path: "/recurring-journal",
            name: "recurring-journal",
            component: RecurringJournal,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffJournal"
                ]
            }
        },
        {
            path: "/bill-enter",
            name: "bill-enter",
            component: BillEnter,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffBill"
                ]
            }
        },
        {
            path: "/bill-register",
            name: "bill-register",
            component: BillRegister,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffBill"
                ]
            }
        },
        {
            path: "/bill-register/edit",
            name: "bill-edit",
            component: BillEdit,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffBill"
                ]
            }
        },
        {
            path: "/bill-register/detail",
            name: "bill-detail",
            component: BillDetail,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffBill"
                ]
            }
        },
        {
            path: "/receive-payment",
            name: "receive-payment",
            component: ReceivePayment,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffBanking"
                ]
            }
        },
        {
            path: "/receive-payment/create",
            name: "receive-payment-create",
            component: ReceivePaymentCreate,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffBanking"
                ]
            }
        },
        {
            path: "/receive-payment/edit",
            name: "receive-payment-edit",
            component: ReceivePaymentEdit,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffBanking"
                ]
            }
        },
        {
            path: "/receive-payment/detail",
            name: "receive-payment-detail",
            component: ReceivePaymentDetail,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffBanking"
                ]
            }
        },
        {
            path: "/purchase-enter",
            name: "purchase-enter",
            component: PurchaseEnter,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffPurchase"
                ]
            }
        },
        {
            path: "/purchase-register",
            name: "purchase-register",
            component: PurchaseRegister,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffPurchase"
                ]
            }
        },
        {
            path: "/purchase-register/edit",
            name: "purchase-edit",
            component: PurchaseEdit,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffPurchase"
                ]
            }
        },
        {
            path: "/purchase-register/detail",
            name: "purchase-detail",
            component: PurchaseDetail,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffPurchase"
                ]
            }
        },
        {
            path: "/pay-purchase",
            name: "pay-purchase",
            component: PayPurchase,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffBanking"
                ]
            }
        },
        {
            path: "/pay-purchase/create",
            name: "pay-purchase-create",
            component: PayPurchaseCreate,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffBanking"
                ]
            }
        },
        {
            path: "/pay-purchase/edit",
            name: "pay-purchase-edit",
            component: PayPurchaseEdit,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffBanking"
                ]
            }
        },
        {
            path: "/pay-purchase/detail",
            name: "pay-purchase-detail",
            component: PayPurchaseDetail,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffBanking"
                ]
            }
        },
        {
            path: "/expense",
            name: "expense",
            component: Expense,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffBanking",
                    "accStaffPettyCash"
                ]
            }
        },
        {
            path: "/expense/create",
            name: "expense-create",
            component: ExpenseCreate,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffBanking",
                    "accStaffPettyCash"
                ]
            }
        },
        {
            path: "/expense/edit",
            name: "expense-edit",
            component: ExpenseEdit,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffBanking",
                    "accStaffPettyCash"
                ]
            }
        },
        {
            path: "/expense/detail",
            name: "expense-detail",
            component: ExpenseDetail,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffBanking",
                    "accStaffPettyCash"
                ]
            }
        },
        {
            path: "/income",
            name: "income",
            component: Income,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffBanking",
                    "accStaffPettyCash"
                ]
            }
        },
        {
            path: "/income/create",
            name: "income-create",
            component: IncomeCreate,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffBanking",
                    "accStaffPettyCash"
                ]
            }
        },
        {
            path: "/income/edit",
            name: "income-edit",
            component: IncomeEdit,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffBanking",
                    "accStaffPettyCash"
                ]
            }
        },
        {
            path: "/income/detail",
            name: "income-detail",
            component: IncomeDetail,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffBanking",
                    "accStaffPettyCash"
                ]
            }
        },
        {
            path: "/contact",
            name: "contact",
            component: Contact,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffSetup",
                    "accStaffInventory"
                ]
            }
        },
        {
            path: "/contact/create",
            name: "contact-create",
            component: ContactCreate,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffSetup",
                    "accStaffInventory"
                ]
            }
        },
        {
            path: "/contact/edit",
            name: "contact-edit",
            component: ContactEdit,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffSetup",
                    "accStaffInventory"
                ]
            }
        },
        {
            path: "/tax",
            name: "tax",
            component: Tax,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffSetup",
                    "accStaffInventory"
                ]
            }
        },
        {
            path: "/tax/create",
            name: "tax-create",
            component: TaxCreate,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffSetup",
                    "accStaffInventory"
                ]
            }
        },
        {
            path: "/tax/edit",
            name: "tax-edit",
            component: TaxEdit,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffSetup",
                    "accStaffInventory"
                ]
            }
        },
        {
            path: "/uom",
            name: "uom",
            component: Uom,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffInventory"
                ]
            }
        },
        {
            path: "/uom/create",
            name: "uom-create",
            component: UomCreate,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffInventory"
                ]
            }
        },
        {
            path: "/uom/edit",
            name: "uom-edit",
            component: UomEdit,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffInventory"
                ]
            }
        },
        {
            path: "/uom/detail",
            name: "uom-detail",
            component: UomDetail,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffInventory"
                ]
            }
        },
        // {
        //     path: "/asset",
        //     name: "asset",
        //     component: Asset,
        //     meta: {
        //         authorize: [
        //             "masterAccount",
        //             "devAccount",
        //             "accChief",
        //             "accStaffAsset"
        //         ]
        //     }
        // },
        // {
        //     path: "/asset/create",
        //     name: "asset-create",
        //     component: AssetCreate,
        //     meta: {
        //         authorize: [
        //             "masterAccount",
        //             "devAccount",
        //             "accChief",
        //             "accStaffAsset"
        //         ]
        //     }
        // },
        // {
        //     path: "/asset/edit",
        //     name: "asset-edit",
        //     component: AssetEdit,
        //     meta: {
        //         authorize: [
        //             "masterAccount",
        //             "devAccount",
        //             "accChief",
        //             "accStaffAsset"
        //         ]
        //     }
        // },
        // {
        //     path: "/asset/detail",
        //     name: "asset-detail",
        //     component: AssetDetail,
        //     meta: {
        //         authorize: [
        //             "masterAccount",
        //             "devAccount",
        //             "accChief",
        //             "accStaffAsset"
        //         ]
        //     }
        // },
        // {
        //     path: "/asset-category",
        //     name: "asset-category",
        //     component: AssetCategory,
        //     meta: {
        //         authorize: [
        //             "masterAccount",
        //             "devAccount",
        //             "accChief",
        //             "accStaffAsset"
        //         ]
        //     }
        // },
        // {
        //     path: "/asset-category/create",
        //     name: "asset-category-create",
        //     component: AssetCategoryCreate,
        //     meta: {
        //         authorize: [
        //             "masterAccount",
        //             "devAccount",
        //             "accChief",
        //             "accStaffAsset"
        //         ]
        //     }
        // },
        // {
        //     path: "/asset-category/edit",
        //     name: "asset-category-edit",
        //     component: AssetCategoryEdit,
        //     meta: {
        //         authorize: [
        //             "masterAccount",
        //             "devAccount",
        //             "accChief",
        //             "accStaffAsset"
        //         ]
        //     }
        // },
        // {
        //     path: "/asset-category/depreciation",
        //     name: "asset-category-depreciation",
        //     component: AssetCategoryDepreciation,
        //     meta: {
        //         authorize: [
        //             "masterAccount",
        //             "devAccount",
        //             "accChief",
        //             "accStaffAsset"
        //         ]
        //     }
        // },
        // {
        //     path: "/asset-depreciation",
        //     name: "asset-depreciation",
        //     component: AssetDepreciation,
        //     meta: {
        //         authorize: [
        //             "masterAccount",
        //             "devAccount",
        //             "accChief",
        //             "accStaffAsset"
        //         ]
        //     }
        // },
        // {
        //     path: "/asset-depreciation/create",
        //     name: "asset-depreciation-create",
        //     component: AssetDepreciationCreate,
        //     meta: {
        //         authorize: [
        //             "masterAccount",
        //             "devAccount",
        //             "accChief",
        //             "accStaffAsset"
        //         ]
        //     }
        // },
        // {
        //     path: "/asset-depreciation/delete",
        //     name: "asset-depreciation-delete",
        //     component: AssetDepreciationDelete,
        //     meta: {
        //         authorize: [
        //             "masterAccount",
        //             "devAccount",
        //             "accChief",
        //             "accStaffAsset"
        //         ]
        //     }
        // },
        // {
        //     path: "/asset-depreciation/detail",
        //     name: "asset-depreciation-detail",
        //     component: AssetDepreciationDetail,
        //     meta: {
        //         authorize: [
        //             "masterAccount",
        //             "devAccount",
        //             "accChief",
        //             "accStaffAsset"
        //         ]
        //     }
        // },
        {
            path: "/item",
            name: "item",
            component: Item,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffInventory"
                ]
            }
        },
        {
            path: "/item/create",
            name: "item-create",
            component: ItemCreate,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffInventory"
                ]
            }
        },
        {
            path: "/item/edit",
            name: "item-edit",
            component: ItemEdit,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffInventory"
                ]
            }
        },
        {
            path: "/item/detail",
            name: "item-detail",
            component: ItemDetail,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffInventory"
                ]
            }
        },
        {
            path: "/products-services",
            name: "products-services",
            component: ProductsServices,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffInventory"
                ]
            }
        },
        {
            path: "/products-services/create",
            name: "products-services-create",
            component: ProductsServicesCreate,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffInventory"
                ]
            }
        },
        {
            path: "/products-services/edit",
            name: "products-services-edit",
            component: ProductsServicesEdit,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffInventory"
                ]
            }
        },
        {
            path: "/products-services/detail",
            name: "products-services-detail",
            component: ProductsServicesDetail,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffInventory"
                ]
            }
        },
        {
            path: "/adjustment/detail",
            name: "adjustment-detail",
            component: AdjustmentDetail,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffInventory"
                ]
            }
        },
        {
            path: "/adjustment/edit",
            name: "adjustment-edit",
            component: AdjustmentEdit,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffInventory"
                ]
            }
        },
        {
            path: "/incoming-item",
            name: "incoming-item",
            component: IncomingItem,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffInventory"
                ]
            }
        },
        {
            path: "/incoming-item/create",
            name: "incoming-item-create",
            component: IncomingItemCreate,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffInventory"
                ]
            }
        },
        {
            path: "/incoming-item/edit",
            name: "incoming-item-edit",
            component: IncomingItemEdit,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffInventory"
                ]
            }
        },
        {
            path: "/incoming-item/detail",
            name: "incoming-item-detail",
            component: IncomingItemDetail,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffInventory"
                ]
            }
        },
        {
            path: "/outgoing-item",
            name: "outgoing-item",
            component: OutgoingItem,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffInventory"
                ]
            }
        },
        {
            path: "/outgoing-item/create",
            name: "outgoing-item-create",
            component: OutgoingItemCreate,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffInventory"
                ]
            }
        },
        {
            path: "/outgoing-item/edit",
            name: "outgoing-item-edit",
            component: OutgoingItemEdit,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffInventory"
                ]
            }
        },
        {
            path: "/outgoing-item/detail",
            name: "outgoing-item-detail",
            component: OutgoingItemDetail,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffInventory"
                ]
            }
        },
        {
            path: "/item-type",
            name: "item-type",
            component: ItemType,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffInventory"
                ]
            }
        },
        {
            path: "/item-type/create",
            name: "item-type-create",
            component: ItemTypeCreate,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffInventory"
                ]
            }
        },
        {
            path: "/item-type/edit",
            name: "item-type-edit",
            component: ItemTypeEdit,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffInventory"
                ]
            }
        },
        {
            path: "/item-type/detail",
            name: "item-type-detail",
            component: ItemTypeDetail,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffInventory"
                ]
            }
        },
        {
            path: "/warehouse",
            name: "warehouse",
            component: Warehouse,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffInventory"
                ]
            }
        },
        {
            path: "/warehouse/create",
            name: "warehouse-create",
            component: WarehouseCreate,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffInventory"
                ]
            }
        },
        {
            path: "/warehouse/edit",
            name: "warehouse-edit",
            component: WarehouseEdit,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffInventory"
                ]
            }
        },
        {
            path: "/warehouse/detail",
            name: "warehouse-detail",
            component: WarehouseDetail,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStaffInventory"
                ]
            }
        },
        {
            path: "/workspace",
            name: "workspace",
            component: Workspace,
            meta: {
                authorize: ["masterAccount", "devAccount"]
            }
        },
        {
            path: "/workspace/create",
            name: "workspace-create",
            component: WorkspaceCreate,
            meta: {
                authorize: ["masterAccount", "devAccount"]
            }
        },
        {
            path: "/activity",
            name: "activity",
            component: Activity,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "administrator",
                    "accChief"
                ]
            }
        },
        {
            path: "/report",
            name: "report",
            component: Report,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStakeholder",
                    "accStaffBill",
                    "accStaffPurchase",
                    "accStaffReportGL",
                    "accStaffReportGLBank",
                    "accStaffReportBS",
                    "accStaffReportTB",
                    "accStaffReportPL",
                    "accStaffInventory"

                ]
            }
        },
        {
            path: "/report/bill",
            name: "bill-report",
            component: BillReport,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStakeholder",
                    "accStaffBill"
                ]
            }
        },
        {
            path: "/report/bill/display",
            name: "bill-report-display",
            component: BillReportDisplay,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStakeholder",
                    "accStaffBill"
                ]
            }
        },
        {
            path: "/report/purchase",
            name: "purchase-report",
            component: PurchaseReport,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStakeholder",
                    "accStaffPurchase"
                ]
            }
        },
        {
            path: "/report/purchase/display",
            name: "purchase-report-display",
            component: PurchaseReportDisplay,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStakeholder",
                    "accStaffPurchase"
                ]
            }
        },
        {
            path: "/report/general-ledger",
            name: "general-ledger",
            component: GeneralLedger,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStakeholder",
                    "accStaffReportGL",
                    "accStaffReportGLBank"
                ]
            }
        },
        {
            path: "/report/general-ledger/display",
            name: "general-ledger-display",
            component: GeneralLedgerDisplay,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStakeholder",
                    "accStaffReportGL",
                    "accStaffReportGLBank"
                ]
            }
        },
        {
            path: "/report/trial-balance",
            name: "trial-balance",
            component: TrialBalance,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStakeholder",
                    "accStaffReportTB"
                ]
            }
        },
        {
            path: "/report/trial-balance/display",
            name: "trial-balance-display",
            component: TrialBalanceDisplay,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStakeholder",
                    "accStaffReportTB"
                ]
            }
        },
        {
            path: "/report/trial-balance-cumulative",
            name: "trial-balance-cumulative",
            component: TrialBalanceCumulative,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStakeholder",
                    "accStaffReportTB"
                ]
            }
        },
        {
            path: "/report/trial-balance-cumulative/display",
            name: "trial-balance-cumulative-display",
            component: TrialBalanceCumulativeDisplay,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStakeholder",
                    "accStaffReportTB"
                ]
            }
        },
        {
            path: "/report/balance-sheet",
            name: "balance-sheet",
            component: BalanceSheet,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStakeholder",
                    "accStaffReportBS"
                ]
            }
        },
        {
            path: "/report/balance-sheet/display",
            name: "balance-sheet-display",
            component: BalanceSheetDisplay,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStakeholder",
                    "accStaffReportBS"
                ]
            }
        },
        {
            path: "/report/profit-loss",
            name: "profit-loss",
            component: ProfitLoss,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStakeholder",
                    "accStaffReportPL"
                ]
            }
        },
        {
            path: "/report/profit-loss/display",
            name: "profit-loss-display",
            component: ProfitLossDisplay,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStakeholder",
                    "accStaffReportPL"
                ]
            }
        },
        {
            path: "/report/item-detail",
            name: "report-item-detail",
            component: ReportItemDetail,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStakeholder",
                    "accStaffInventory"
                ]
            }
        },
        {
            path: "/report/item-summary",
            name: "report-item-summary",
            component: ReportItemSummary,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStakeholder",
                    "accStaffInventory"
                ]
            }
        },
        {
            path: "/report/cash-flow",
            name: "report-cash-flow",
            component: ReportCashFlow,
            meta: {
                authorize: [
                    "masterAccount",
                    "devAccount",
                    "accChief",
                    "accStakeholder",
                    "accStaffReportPL"
                ]
            }
        },
        {
            path: "/start-new-year",
            name: "start-new-year",
            component: StartNewYear,
            meta: {
                authorize: ["masterAccount", "devAccount", "accChief"]
            }
        },
        {
            path: "/user-guides",
            name: "user-guides",
            component: UserGuides,
            meta: { authorize: [] }
        },
        {
            path: "/tutorial-video",
            name: "tutorial-video",
            component: TutorialVideo,
            meta: { authorize: [] }
        },
        { path: "/404", component: NotFound, meta: { authorize: [] } },
        { path: "*", redirect: "/404" }
    ]
});

// [#BASE004]
router.beforeEach((to, from, next) => {
    store.commit("CLEAR_ERRORS");
    // redirect to login page if not logged in and trying to access a restricted page
    const { authorize } = to.meta;

    const auth = store.getters.isAuth;
    const role = store.getters.role;
    //AUTHORIZE ROLE
    if (authorize) {
        
        //Apakah Sudah Login Atau Belum
        if (!auth) {
            return next({ name: "login" });
        }
        
        //Apakah punya role untuk akses ke halaman tersebut atau tidak
        if (
            authorize.length &&
            !role.some(val => authorize.indexOf(val) !== -1)
        ) {
            Vue.$swal({
                title: "You have no Access to the page",
                text: "Contact your Administrator to grant Access",
                icon: "warning"
            });
            return next({ name: "home" });
        }
    }
    next();
});
// END [#BASE004]


export default router;
