<template>
    <main>
        <hero
            title="Journal Transaction"
            prevBreadcrumb="Dashboard"
            routeBreadcrumb="home"
            currentBreadcrumb="Journal Transaction"
        ></hero>

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 1 || !datas"
                style="height: 250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <!-- Dynamic Table Full -->
            <div class="block block-rounded block-mode-loading-oneui" v-else>
                <div class="block-content block-content-full pb-0 pt-3">
                    <div class="row">
                        <div
                            class="col-12 col-md-7 col-lg-7 col-xl-7 mb-2 mb-sm-0 pb-0 px-0 d-md-none d-inline"
                        >
                            <label class="col-12 col-md-1 py-0">Date :</label>
                            <date-picker
                                v-model="date_start"
                                type="date"
                                format="DD/MM/YYYY"
                                valueType="YYYY-MM-DD"
                                placeholder="Datepicker"
                                class="col-12"
                            ></date-picker>
                            <date-picker
                                v-model="date_end"
                                type="date"
                                format="DD/MM/YYYY"
                                valueType="YYYY-MM-DD"
                                placeholder="Datepicker"
                                class="col-12"
                            ></date-picker>
                            <button
                                type="button"
                                class="btn btn-sm btn-info float-right mb-5 mb-md-0"
                                tag="button"
                                v-ripple="{ center: true }"
                                style="margin-top: 2px"
                                @click="getData()"
                                :disabled="
                                    loadingPage == 2 ||
                                        date_start == null ||
                                        date_end == null
                                "
                            >
                                <i class="fa fa-calendar"></i> Apply Date
                            </button>
                        </div>
                        <div
                            class="col-12 col-md-7 col-lg-7 col-xl-7 mb-2 mb-sm-0 pb-0 d-md-inline d-none"
                        >
                            <label>Date :</label>
                            <date-picker
                                v-model="date_start"
                                type="date"
                                format="DD/MM/YYYY"
                                valueType="YYYY-MM-DD"
                                placeholder="Datepicker"
                            ></date-picker>
                            <date-picker
                                v-model="date_end"
                                type="date"
                                format="DD/MM/YYYY"
                                valueType="YYYY-MM-DD"
                                placeholder="Datepicker"
                            ></date-picker>
                            <button
                                type="button"
                                class="btn btn-sm btn-info ml-1 mb-5 mb-md-1"
                                tag="button"
                                v-ripple="{ center: true }"
                                @click="getData()"
                                :disabled="
                                    loadingPage == 2 ||
                                        date_start == null ||
                                        date_end == null
                                "
                            >
                                <i class="fa fa-calendar mr-1"></i> Apply Date
                            </button>
                        </div>
                        <div
                            class="col-12 col-md-5 col-lg-5 col-xl-5 pb-0 pt-0 pt-md-3"
                        >
                            <input
                                type="text"
                                class="form-control form-control col-12 col-md-8 float-right search-index"
                                name="example-text-input"
                                placeholder="Search.."
                                v-model="search"
                            />
                        </div>
                    </div>
                </div>
                <div class="block-content block-content-full py-2">
                    <v-app>
                        <v-data-table
                            :headers="headers"
                            :items="datas"
                            :search="search"
                            :hide-default-footer="true"
                            :items-per-page="50"
                            :mobile-breakpoint="0"
                        >
                            <template
                                v-slot:top="{
                                    pagination,
                                    options,
                                    updateOptions
                                }"
                            >
                                <div class="row">
                                    <div
                                        class="col-12 col-md-7 mb-2 mb-sm-0 py-0"
                                    >
                                        <v-layout justify-start>
                                            <v-data-footer
                                                :pagination="pagination"
                                                :options="options"
                                                @update:options="updateOptions"
                                                :items-per-page-options="[
                                                    10,
                                                    30,
                                                    50,
                                                    100,
                                                    -1
                                                ]"
                                                items-per-page-text="Rows :"
                                            />
                                        </v-layout>
                                    </div>
                                    <div
                                        class="col-12 col-md-5 col-lg-5 col-xl-5 pb-0 pt-2"
                                    >
                                        <button
                                            type="button"
                                            class="btn btn-sm btn-alt-secondary float-right m-1 mb-5 mb-md-0"
                                            tag="button"
                                            v-ripple="{ center: true }"
                                            style="vertical-align: middle"
                                            @click="getData()"
                                            :disabled="loadingPage == 2"
                                        >
                                            <i class="fa fa-sync"></i>
                                        </button>
                                        <router-link
                                            type="button"
                                            class="btn btn-sm btn-info float-right m-1 mb-5 mb-md-0"
                                            :to="{ name: 'journal-record' }"
                                            tag="button"
                                            v-ripple="{ center: true }"
                                            style="vertical-align: middle"
                                            :disabled="loadingPage == 2"
                                        >
                                            <i class="fa fa-plus mr-1"></i>
                                            Record Journal
                                        </router-link>
                                    </div>
                                </div>
                            </template>
                            <template v-slot:body="{ items }">
                                <tbody v-if="loadingPage == 2">
                                    <tr class="data-empty">
                                        <td colspan="6" class="py-2">
                                            <grid-loader
                                                class="mx-auto mt-5 pt-5"
                                                :color="'#70B9EB'"
                                                :size="'10px'"
                                            ></grid-loader>
                                            <center class="mt-2 mb-5 pb-5">
                                                <span
                                                    class="text-center"
                                                    style="opacity: 0.7"
                                                    >Loading</span
                                                >
                                            </center>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody
                                    v-if="items.length > 0 && loadingPage == 0"
                                >
                                    <tr
                                        v-for="(journal, index) in items"
                                        :key="index"
                                    >
                                        <td
                                            @click="toDetail(journal.jId)"
                                            class="text-top"
                                            align="center"
                                        >
                                            {{ journal.jDate }}
                                        </td>
                                        <td
                                            @click="toDetail(journal.jId)"
                                            class="text-top"
                                        >
                                            {{ journal.jDesc }}
                                        </td>
                                        <td
                                            @click="toDetail(journal.jId)"
                                            style="white-space: nowrap"
                                        >
                                            <span
                                                v-for="(detail,
                                                index2) in journal.jAcc"
                                                :key="index2"
                                            >
                                                {{ detail }}
                                                <br />
                                            </span>
                                        </td>
                                        <td
                                            @click="toDetail(journal.jId)"
                                            align="right"
                                        >
                                            <span
                                                v-for="(detail,
                                                index2) in journal.jDebit"
                                                :key="index2"
                                            >
                                                <span v-if="detail != 0">
                                                    {{ detail | currency }}
                                                </span>
                                                <br />
                                            </span>
                                        </td>
                                        <td
                                            @click="toDetail(journal.jId)"
                                            align="right"
                                        >
                                            <span
                                                v-for="(detail,
                                                index2) in journal.jCredit"
                                                :key="index2"
                                            >
                                                <span v-if="detail != 0">
                                                    {{ detail | currency }}
                                                </span>
                                                <br />
                                            </span>
                                        </td>
                                        <!-- DROPDOWN AURORA -->
                                        <td align="right">
                                            <div
                                                class="dropdown dropleft push m-0"
                                            >
                                                <button
                                                    type="button"
                                                    class="btn btn-sm btn-alt-secondary btn-transparent btn-rounded text--lighten-4"
                                                    id="dropdown-dropleft"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    <i
                                                        class="fa fa-fw fa-ellipsis-v"
                                                    ></i>
                                                </button>
                                                <div
                                                    class="dropdown-menu dropdown-menu-rounded font-size-sm px-1"
                                                    aria-labelledby="dropdown-dropleft"
                                                >
                                                    <button
                                                        @click="
                                                            toDetail(
                                                                journal.jId
                                                            )
                                                        "
                                                        class="btn btn-sm btn-transparent btn-alt-info dropdown-item-horizontal text-info"
                                                        v-ripple="{
                                                            center: true
                                                        }"
                                                        style="
                                                            position: inline-block;
                                                        "
                                                    >
                                                        <i
                                                            class="si si-magnifier mr-1"
                                                        ></i>
                                                        <span
                                                            class="d-none d-md-inline"
                                                            >Detail</span
                                                        >
                                                    </button>
                                                    <button
                                                        @click="
                                                            toEdit(journal.jId)
                                                        "
                                                        class="btn btn-sm btn-transparent btn-alt-success dropdown-item-horizontal text-success"
                                                        v-ripple="{
                                                            center: true
                                                        }"
                                                        style="
                                                            position: inline-block;
                                                        "
                                                    >
                                                        <i
                                                            class="si si-pencil mr-1"
                                                        ></i>
                                                        <span
                                                            class="d-none d-md-inline"
                                                            >Edit</span
                                                        >
                                                    </button>
                                                    <button
                                                        class="btn btn-sm btn-transparent btn-alt-danger text-danger dropdown-item-horizontal"
                                                        v-ripple="{
                                                            center: true
                                                        }"
                                                        @click.prevent="
                                                            deleteData(
                                                                journal.jId
                                                            )
                                                        "
                                                        style="
                                                            position: inline-block;
                                                        "
                                                    >
                                                        <i
                                                            class="si si-trash mr-1"
                                                        ></i>
                                                        <span
                                                            class="d-none d-md-inline"
                                                            >Delete</span
                                                        >
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody
                                    v-if="items.length <= 0 && loadingPage == 0"
                                >
                                    <tr class="data-empty">
                                        <td
                                            class="text-center"
                                            colspan="6"
                                            style="
                                                opacity: 0.8;
                                                padding: 2.5rem;
                                            "
                                        >
                                            <center>
                                                <img
                                                    :src="
                                                        '/media/proxima/empty-data.svg'
                                                    "
                                                    style="height: 100px"
                                                />
                                            </center>
                                            <br /><span style="opacity: 0.6"
                                                >Data Not Found</span
                                            >
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-data-table>
                    </v-app>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";

export default {
    name: "journal",
    data() {
        return {
            search: "",
            awaitingSearch: false,
            loadingPage: 0,
            sort: "journal_date",
            asc: true,
            date_start: moment()
                .subtract(30, "days")
                .format("YYYY-MM-DD"),
            date_end: moment().format("YYYY-MM-DD"),
            headers: [
                { text: "Date", align: "center", value: "jDate" },
                { text: "Journal No.", align: "start", value: "jDesc" },
                { text: "Accounts", value: "jAcc" },
                {
                    text: "Debit",
                    align: "right",
                    value: "jDebit",
                    sortable: false
                },
                {
                    text: "Credit",
                    align: "right",
                    value: "jCredit",
                    sortable: false
                },
                { text: "", sortable: false }
            ],
            datas: []
        };
    },
    mounted() {
        // if not edit /delete data, data cache used
        if (
            this.$session.has("journaltransaction_datas") &&
            !this.$route.params.reloadData
        ) {
            this.loadingPage = 0;
            // this.datas = this.$session.get("journaltransaction_datas");
            this.date_start = this.$session.get(
                "journaltransaction_date_start"
            );
            this.date_end = this.$session.get("journaltransaction_date_end");
            this.search = this.$session.get("journaltransaction_search");
            this.getData();

            // if edit/delete data, data new get
        } else if (
            this.$session.has("journaltransaction_datas") &&
            this.$route.params.reloadData
        ) {
            this.date_start = this.$session.get(
                "journaltransaction_date_start"
            );
            this.date_end = this.$session.get("journaltransaction_date_end");
            this.search = this.$session.get("journaltransaction_search");
            this.getData();
            // clean loading 1st
        } else {
            this.getData();
        }
        this.$session.remove("journal_voucher");
    },
    computed: {
        ...mapState("journal", { journals: state => state.journals })
    },
    watch: {
        search: function() {
            this.$session.set("journaltransaction_search", this.search);
        }
    },
    methods: {
        ...mapActions("journal", ["getJournal", "removeJournal"]),
        alert(text, tipe) {
            if (tipe === 1) {
                this.$swal({
                    title: "Succes",
                    text: text,
                    icon: "success"
                });
            } else if (tipe === 2) {
                this.$swal({
                    title: "Something Wrong",
                    text: text,
                    icon: "error"
                });
            } else if (tipe === 3) {
                this.$swal({
                    title: "Are you sure ?",
                    text: text,
                    icon: "warning",
                    buttons: ["Cancel", "Delete"],
                    dangerMode: true
                }).then(willDelete => {
                    if (willDelete) {
                        this.alert("Data has been deleted !", 1);
                    }
                });
            } else if (tipe === 4) {
                this.$swal({
                    title: "Are you sure ?",
                    text: text,
                    icon: "warning",
                    buttons: ["Cancel", "Update"],
                    dangerMode: false
                }).then(willDelete => {
                    if (willDelete) {
                        this.alert("Data has been deleted !", 1);
                    }
                });
            }
        },
        getData() {
            this.loadingPage = 2;
            this.datas = [];
            this.$session.set(
                "journaltransaction_date_start",
                moment(this.date_start).format("YYYY-MM-DD")
            );
            this.$session.set(
                "journaltransaction_date_end",
                moment(this.date_end).format("YYYY-MM-DD")
            );
            this.getJournal({
                rows: "All",
                date_start: new Date(this.date_start).toDateString(),
                date_end: new Date(this.date_end).toDateString()
            })
                .then(() => {
                    this.journals.data.forEach(element => {
                        let jDetailAcc = [];
                        let jDetailDebit = [];
                        let jDetailCredit = [];
                        element.l.forEach(elementDetail => {
                            let coaFullName =
                                elementDetail.o + "  " + elementDetail.n;
                            jDetailAcc.push(coaFullName);
                            jDetailDebit.push(elementDetail.d);
                            jDetailCredit.push(elementDetail.c);
                        });
                        this.datas.push({
                            jId: element.i,
                            jDate: moment(element.t).format("DD/MM/YYYY"),
                            jDesc: element.o + " : " + element.s,
                            jAcc: jDetailAcc,
                            jDebit: jDetailDebit,
                            jCredit: jDetailCredit
                        });
                    });
                    this.loadingPage = 0;
                    this.$session.set("journaltransaction_datas", this.datas);
                })
                .catch(error => {
                    error.response.status == 500
                        ? this.alert(error.response.data.message, 2)
                        : "";
                    error.response.status != 422 && error.response.status != 500
                        ? this.alert(error.message, 2)
                        : "";
                    this.loadingPage = 0;
                });
        },
        toDetail(val) {
            this.$router.push({
                name: "journal-transaction-voucher",
                params: { id: val }
            });
        },
        toEdit(val) {
            this.$router.push({
                name: "journal-transaction-edit",
                params: { id: val }
            });
        },
        deleteData(val) {
            this.$swal({
                title: "Are you sure ?",
                text: "Deleted data cannot be recovery",
                icon: "warning",
                buttons: ["Cancel", "Delete"],
                dangerMode: true
            }).then(willDelete => {
                if (willDelete) {
                    this.removeJournal(val)
                        .then(() => {
                            this.alert("Data has been deleted !", 1);
                            this.getData();
                        })
                        .catch(error => {
                            error.response.status == 500
                                ? this.alert(error.response.data.message, 2)
                                : "";
                            error.response.status != 422 &&
                            error.response.status != 500
                                ? this.alert(error.message, 2)
                                : "";
                            this.loadingPage = 0;
                        });
                }
            });
        },
        doLoading(type, long) {
            this.loadingPage = type;
            setTimeout(() => {
                this.loadingPage = 0;
            }, long);
        }
    }
};
</script>