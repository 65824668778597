<template>
    <main>
        <hero
            title="Detail Adjustment"
            prevBreadcrumb="Detail Item"
            routeBreadcrumb="products-services-detail"
            currentBreadcrumb="Detail Adjustment"
        ></hero>

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <!-- Invoice -->
            <div class="block mb-2 block-rounded block-mode-loading-oneui">
                <div class="block-header">
                    <h3 class="block-title">
                        Adjustment {{ mutation.mutation_no }}
                    </h3>
                    <div class="block-options">
                        <router-link
                            :to="{
                                name: 'journal-transaction-voucher',
                                params: { id: mutation.journal.id }
                            }"
                            tag="button"
                            type="button"
                            class="btn-block-option text-warning"
                            v-if="mutation.journal != null"
                        >
                            <i class="si si-notebook mr-1"></i>
                            <span class="d-none d-md-inline-block">
                                Journal Voucher
                            </span>
                        </router-link>
                        <router-link
                            :to="{
                                name: 'adjustment-edit',
                                params: { id: mutation.mutation_id }
                            }"
                            tag="button"
                            type="button"
                            class="btn-block-option text-success"
                        >
                            <i class="si si-pencil mr-1"></i>
                            <span class="d-none d-md-inline-block">
                                Edit Data
                            </span>
                        </router-link>
                        <!-- Print Page functionality is initialized in Helpers.print() -->
                        <button
                            type="button"
                            class="btn-block-option text-info"
                            @click="print()"
                        >
                            <i class="si si-printer mr-1"></i>
                            <span class="d-none d-md-inline-block"
                                >Print Document</span
                            >
                        </button>
                    </div>
                </div>
            </div>
            <div class="block bg-transparent mb-2">
                <div class="block-content pt-0 bg-transparent text-right">
                    <input
                        class="form-check-input"
                        style="margin-top: 3.5px"
                        type="checkbox"
                        v-model="useHeader"
                    />
                    <label class="form-check-label">Default Header</label>
                </div>
            </div>
            <!--Loading-->
            <div class="block" v-if="loadingPage == 1" style="height: 250px">
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <!-- Basic -->
            <div
                class="block block-rounded block-mode-loading-oneui block-transparent"
                v-else
            >
                <div class="row">
                    <div class="col-8">
                        <div
                            class="content content-boxed bg-white block-rounded p-0"
                            id="printMe"
                        >
                            <div class="block-content">
                                <div
                                    class="p-4"
                                    style="min-height: 800px;zoom: 0.9;"
                                >
                                    <!-- HEADER PART -->
                                    <div class="row pt-0" v-if="useHeader">
                                        <div
                                            class="col-8 font-size-sm pb-0 pt-0"
                                        >
                                            <p
                                                class="h3 mb-2 mt-3 text-uppercase"
                                            >
                                                {{
                                                    auth.data.workspace
                                                        .workspace_company_name
                                                }}
                                            </p>
                                            <p class="mb-0">
                                                {{
                                                    auth.data.workspace
                                                        .workspace_company_address
                                                }}
                                            </p>
                                            <p class="mb-0">
                                                <span
                                                    v-if="
                                                        auth.data.workspace
                                                            .workspace_company_province !==
                                                            '' &&
                                                            auth.data.workspace
                                                                .workspace_company_province !==
                                                                null
                                                    "
                                                >
                                                    {{
                                                        auth.data.workspace
                                                            .workspace_company_province
                                                    }}
                                                </span>
                                                <span
                                                    v-if="
                                                        auth.data.workspace
                                                            .workspace_company_postal !==
                                                            '' &&
                                                            auth.data.workspace
                                                                .workspace_company_postal !==
                                                                null
                                                    "
                                                >
                                                    ,
                                                    {{
                                                        auth.data.workspace
                                                            .workspace_company_postal
                                                    }}
                                                </span>
                                            </p>
                                            <p class="mb-0">
                                                <span
                                                    v-if="
                                                        auth.data.workspace
                                                            .workspace_company_phone !==
                                                            '' &&
                                                            auth.data.workspace
                                                                .workspace_company_phone !==
                                                                null
                                                    "
                                                >
                                                    {{
                                                        auth.data.workspace
                                                            .workspace_company_phone
                                                    }}
                                                </span>
                                                <span
                                                    v-if="
                                                        auth.data.workspace
                                                            .workspace_company_email !==
                                                            '' &&
                                                            auth.data.workspace
                                                                .workspace_company_email !==
                                                                null
                                                    "
                                                >
                                                    {{
                                                        "| " +
                                                            auth.data.workspace
                                                                .workspace_company_email
                                                    }}
                                                </span>
                                            </p>
                                        </div>
                                        <div
                                            class="col-4 text-right font-size-sm pb-0 pt-0"
                                        >
                                            <img
                                                :src="
                                                    '/storage/workspace/' +
                                                        auth.data.workspace
                                                            .workspace_company_logo
                                                "
                                                alt=""
                                                style="
                                            min-heigh: 100px;
                                            max-height: 120px;
                                            max-width: 180px;
                                        "
                                                @error="errorLogoUrl"
                                            />
                                        </div>
                                    </div>
                                    <hr class="mb-3" v-if="useHeader" />
                                    <div class="row mt-3">
                                        <p
                                            class="h4 mx-auto text-uppercase text-center my-2"
                                            style="line-height: 1;"
                                        >
                                            {{
                                                mutation.mutation_type == 1 ||
                                                mutation.mutation_type == 3 ||
                                                mutation.mutation_type == 5
                                                    ? "Adjustment In"
                                                    : "Adjustment Out"
                                            }}
                                            <br />
                                            <span
                                                class="font-size-sm text-info text-pointer"
                                                @click="
                                                    goToBill(mutation.source_id)
                                                "
                                                v-if="
                                                    mutation.mutation_type == 4
                                                "
                                            >
                                                Bill :
                                                {{
                                                    mutation.source.bill_number
                                                }}</span
                                            >
                                            <span
                                                class="font-size-sm text-info text-pointer"
                                                @click="
                                                    goToPurchase(
                                                        mutation.source_id
                                                    )
                                                "
                                                v-if="
                                                    mutation.mutation_type == 3
                                                "
                                            >
                                                Purchase :
                                                {{
                                                    mutation.source.bill_number
                                                }}</span
                                            >
                                        </p>
                                    </div>

                                    <!--Form-->
                                    <div class="row font-size-sm">
                                        <div class="col-12 py-0">
                                            <div class="row pt-2">
                                                <div class="col-6 pt-0">
                                                    <div
                                                        class="form-group mt-3"
                                                    >
                                                        <label
                                                            for="example-text-input"
                                                            >Date In</label
                                                        >
                                                        <p class="mb-0">
                                                            {{
                                                                mutation.mutation_date
                                                                    | moment(
                                                                        "DD/MM/YYYY  HH:mm"
                                                                    )
                                                            }}
                                                        </p>
                                                        <hr class="my-0" />
                                                    </div>
                                                    <div class="form-group">
                                                        <label
                                                            for="example-text-input"
                                                            >Mutation No.</label
                                                        >
                                                        <p class="mb-0">
                                                            {{
                                                                mutation.mutation_no
                                                            }}
                                                        </p>
                                                        <hr class="my-0" />
                                                    </div>
                                                </div>
                                                <div class="col-6 pt-0">
                                                    <div
                                                        class="form-group mt-3"
                                                    >
                                                        <label
                                                            for="example-text-input"
                                                            >Warehouse</label
                                                        >
                                                        <p class="mb-0">
                                                            {{
                                                                mutation
                                                                    .warehouse
                                                                    .warehouse_name
                                                            }}
                                                        </p>
                                                        <hr class="my-0" />
                                                    </div>
                                                    <div class="form-group">
                                                        <label
                                                            for="example-text-input"
                                                            >Memo</label
                                                        >
                                                        <p class="mb-0">
                                                            {{
                                                                mutation.mutation_memo
                                                            }}
                                                        </p>
                                                        <hr class="my-0" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--END Form-->
                                    <label class="my-2 form-group"
                                        >Item Data</label
                                    >
                                    <!-- Table -->
                                    <div class="table-responsive push">
                                        <table class="table table-bordered">
                                            <thead
                                                style="background-color: whitesmoke"
                                            >
                                                <tr>
                                                    <th
                                                        class="text-center align-middle"
                                                        style="width: 60px"
                                                    >
                                                        No.
                                                    </th>
                                                    <th
                                                        class="table-header-relative column-minwidth-150 align-middle"
                                                    >
                                                        Item
                                                    </th>
                                                    <th
                                                        class="table-header-relative column-minwidth-150 align-middle"
                                                    >
                                                        Account
                                                    </th>
                                                    <th
                                                        class="table-header-relative align-middle"
                                                        style="width: 100px"
                                                    >
                                                        Qty.
                                                    </th>
                                                    <th
                                                        class="table-header-relative align-middle"
                                                        style="width: 100px"
                                                    >
                                                        Unit
                                                    </th>
                                                    <th
                                                        class="table-header-relative column-minwidth-100 align-middle"
                                                    >
                                                        Unit Price
                                                    </th>
                                                    <th
                                                        class="table-header-relative column-minwidth-100 align-middle"
                                                    >
                                                        Total
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    v-for="(detail,
                                                    index) in mutation.detail"
                                                    :key="index"
                                                >
                                                    <td
                                                        class="font-size-sm text-center"
                                                    >
                                                        {{ ++index }}
                                                    </td>
                                                    <td class="font-size-sm">
                                                        {{
                                                            detail.item
                                                                .item_name
                                                        }}
                                                    </td>
                                                    <td class="font-size-sm">
                                                        {{
                                                            detail.coa_id != 0 
                                                                ? detail.coa
                                                                    .account_no
                                                                : "-"
                                                        }}
                                                        {{
                                                            detail.coa_id != 0 
                                                                ? detail.coa
                                                                    .account_name
                                                                : ""
                                                        }}
                                                    </td>
                                                    <td
                                                        class="font-size-sm text-center"
                                                    >
                                                        {{
                                                            mutation.mutation_type ==
                                                                1 ||
                                                            mutation.mutation_type ==
                                                                3 ||
                                                            mutation.mutation_type ==
                                                                5
                                                                ? detail.in_qty
                                                                : detail.out_qty
                                                        }}
                                                    </td>
                                                    <td
                                                        class="font-size-sm text-center"
                                                    >
                                                        {{
                                                            detail.uom_id = 0
                                                                ? "-"
                                                                : detail.uom
                                                                    .uom_code
                                                        }}
                                                    </td>
                                                    <td
                                                        class="font-size-sm text-right"
                                                    >
                                                        {{
                                                            mutation.mutation_type ==
                                                                1 ||
                                                            mutation.mutation_type ==
                                                                3 ||
                                                            mutation.mutation_type ==
                                                                5
                                                                ? detail.in_price
                                                                : detail.out_price
                                                                  | currency
                                                        }}
                                                    </td>
                                                    <td
                                                        class="font-size-sm text-right"
                                                    >
                                                        {{
                                                            mutation.mutation_type ==
                                                                1 ||
                                                            mutation.mutation_type ==
                                                                3 ||
                                                            mutation.mutation_type ==
                                                                5
                                                                ? detail.in_amount
                                                                : detail.out_amount
                                                                  | currency
                                                        }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <p
                                            class="text-muted font-size-sm d-inline d-sm-none"
                                            style="opacity: 0.5"
                                        >
                                            Swipe left here to see full column
                                            >>
                                        </p>
                                    </div>
                                    <!-- <hr class="mt-3" /> -->
                                    <div class="row mt-3 mb-5">
                                        <div
                                            class="col-6 text-left font-size-sm pt-5 mt-2"
                                        >
                                            <div
                                                class="form-group d-print-none"
                                            >
                                                <label for="example-text-input"
                                                    >Post Note</label
                                                >
                                                <ckeditor
                                                    tag-name="textarea"
                                                    :editor="editor"
                                                    v-model="docPostNote"
                                                    :config="editorConfig"
                                                ></ckeditor>
                                            </div>
                                            <span
                                                class="d-none d-print-inline-block"
                                                v-html="docPostNote"
                                            ></span>
                                        </div>
                                        <div
                                            class="col-6 font-size-sm text-right pt-5 mt-2"
                                        >
                                            <div
                                                class="form-group text-left d-print-none"
                                            >
                                                <label for="example-text-input"
                                                    >Choose Signature</label
                                                >
                                                <selectize
                                                    class="form-control"
                                                    placeholder="Choose Signature"
                                                    v-model="selectedSignature"
                                                    @input="
                                                        selectSignature(
                                                            selectedSignature
                                                        )
                                                    "
                                                >
                                                    <option
                                                        v-for="signature in signatures.data"
                                                        :value="signature.id"
                                                        :key="signature.id"
                                                    >
                                                        {{
                                                            signature.signature_name
                                                        }}
                                                    </option>
                                                </selectize>
                                            </div>
                                            <p
                                                class="mb-0 font-w700 mt-6 ml-9 text-center"
                                                style="border-bottom: 1px solid grey"
                                                v-bind:class="{
                                                    'd-print-none':
                                                        selectedSignature == ''
                                                }"
                                            >
                                                {{
                                                    docSignature.signature_name
                                                }}
                                            </p>
                                            <p
                                                class="mb-0 font-w700 ml-9 text-center"
                                                v-bind:class="{
                                                    'd-print-none':
                                                        selectedSignature == ''
                                                }"
                                            >
                                                {{
                                                    docSignature.signature_position
                                                }}
                                            </p>
                                        </div>
                                    </div>

                                    <hr />
                                    <p
                                        class="d-none d-print-inline-block"
                                        style="opacity: 0.6"
                                    >
                                        <i> Printed : {{ dateNow }} </i>
                                    </p>
                                    <!-- END Footer -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div
                            class="content content-boxed p-0"
                            v-if="
                                this.mutation.mutation_type == 3 ||
                                    this.mutation.mutation_type == 4
                            "
                        >
                            <div
                                class="block-content bg-white block-rounded px-3 py-3 mb-3"
                            >
                                <p
                                    class="font-w700 mb-3"
                                    v-if="this.mutation.mutation_type == 4"
                                >
                                    Bill Document
                                </p>
                                <p
                                    class="font-w700 mb-3"
                                    v-if="this.mutation.mutation_type == 3"
                                >
                                    Purchase Document
                                </p>
                                <p class="text-muted-high mb-0">Purchase No.</p>
                                <p class="mb-2">
                                    {{ this.mutation.source.bill_number }}
                                </p>
                                <p class="text-muted-high mb-0">Issued To</p>
                                <p class="mb-2">
                                    {{
                                        this.mutation.source.contact
                                            .contact_name
                                    }}
                                </p>
                                <div class="row">
                                    <div class="col-6 py-0">
                                        <p class="text-muted-high mb-0">
                                            On
                                        </p>
                                        <p class="mb-2">
                                            {{
                                                this.mutation.source.bill_date
                                                    | moment("DD/MM/YYYY")
                                            }}
                                        </p>
                                    </div>
                                    <div class="col-6 py-0">
                                        <p class="text-muted-high mb-0">
                                            Due On
                                        </p>
                                        <p class="mb-2">
                                            {{
                                                this.mutation.source
                                                    .bill_due_date
                                                    | moment("DD/MM/YYYY")
                                            }}
                                        </p>
                                    </div>
                                </div>
                                <p class="text-muted-high mb-0">Amount</p>
                                <p class="font-w700 mb-2">
                                    {{
                                        this.mutation.source.bill_grand_total
                                            | currency
                                    }}
                                </p>
                                <p
                                    class="mt-2 mb-0 text-right text-info text-pointer"
                                    @click="goToBill(mutation.source_id)"
                                    v-if="this.mutation.mutation_type == 4"
                                >
                                    To Bill Document
                                </p>
                                <p
                                    class="mt-2 mb-0 text-right text-info text-pointer"
                                    @click="goToPurchase(mutation.source_id)"
                                    v-if="this.mutation.mutation_type == 3"
                                >
                                    To Purchase Document
                                </p>
                            </div>
                        </div>
                        <div
                            class="content content-boxed p-0"
                            v-if="
                                this.mutation.mutation_type == 3 ||
                                    this.mutation.mutation_type == 4
                                    && this.mutation.source != null
                            "
                        >
                            <div
                                class="block-content bg-white block-rounded px-3 py-3 mb-3"
                            >
                                <p class="font-w700 mb-3">Journal Voucher</p>
                                <p class="text-muted-high mb-0">Journal No.</p>
                                <p class="mb-2">
                                    {{
                                        this.mutation.source.journal.journal_no
                                    }}
                                </p>
                                <p class="text-muted-high mb-0">Memo</p>
                                <p class="mb-2">
                                    {{
                                        this.mutation.source.journal
                                            .journal_description
                                    }}
                                </p>
                                <p
                                    class="mt-2 mb-0 text-right text-info text-pointer"
                                    @click="
                                        goToVoucher(mutation.source.journal.id)
                                    "
                                >
                                    To Journal Voucher
                                </p>
                            </div>
                        </div>
                        <div
                            class="content content-boxed p-0"
                            v-if="
                                (this.mutation.mutation_type == 1 ||
                                    this.mutation.mutation_type == 2) && this.mutation.journal != null
                            "
                        >
                            <div
                                class="block-content bg-white block-rounded px-3 py-3 mb-3"
                            >
                                <p class="font-w700 mb-3">Journal Voucher</p>
                                <p class="text-muted-high mb-0">Journal No.</p>
                                <p class="mb-2">
                                    {{ this.mutation.journal.journal_no }}
                                </p>
                                <p class="text-muted-high mb-0">Memo</p>
                                <p class="mb-2">
                                    {{
                                        this.mutation.journal
                                            .journal_description
                                    }}
                                </p>
                                <p
                                    class="mt-2 mb-0 text-right text-info text-pointer"
                                    @click="goToVoucher(mutation.journal.id)"
                                >
                                    To Journal Voucher
                                </p>
                            </div>
                        </div>
                        <div
                            class="content content-boxed p-0"
                            v-if="this.mutation.journal == null && this.mutation.source == null 
                            "
                        >
                            <div
                                class="block-content bg-white block-rounded px-3 py-3 mb-3"
                            >
                                <p class="font-w700 mb-3">Journal Voucher</p>
                                <p class="text-muted-high text-center">This mutation is not recorded at journal, regardles the item setup as Untracked Inventory</p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- END Invoice -->
            </div>
            <!-- END Basic -->
        </div>
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import moment from "moment";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
    name: "adjustment-detail",
    data() {
        return {
            loadingPage: 1,
            disabled: {},
            coaBills: [],
            useHeader: true,
            forbiddenData: false,
            dateNow: moment().format("MMMM Do YYYY, HH:mm:ss"),
            selectedSignature: "",
            docSignature: {
                signature_name: "[select signature]",
                signature_position: "[select signature]",
                signature_text: ""
            },
            selectedSignature2: "",
            docSignature2: {
                signature_name: "[select signature]",
                signature_position: "[select signature]",
                signature_text: ""
            },
            selectedBank: "",
            docBank: {
                bank_name: "[select bank]",
                bank_cabang: "[select bank]",
                bank_rekening: "[select bank]",
                bank_rekening_name: "[select bank]"
            },
            editor: ClassicEditor,
            editorConfig: {
                toolbar: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "bulletedList",
                    "numberedList",
                    "blockQuote",
                    "undo",
                    "redo",
                    "insertTable"
                ]
            },
            docPostNote: ""
        };
    },
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState(["auth"]),
        ...mapState("mutation", { mutation: state => state.mutation }),
        ...mapState("itemtype", {
            types: state => state.types
        }),
        ...mapState("signature", { signatures: state => state.signatures }),
        ...mapState("bank", { banks: state => state.banks }),
        ...mapState("warehouse", { warehouses: state => state.warehouses }),
        ...mapState("uom", { uoms: state => state.uoms }),
        ...mapState("item", { items: state => state.items })
    },
    created() {
        if (this.$route.params.id) {
            this.editMutation(this.$route.params.id)
                .then(response => {
                    this.$session.set("mutation_detail", this.$route.params.id);
                    this.loadingPage = 0;
                })
                .catch(error => {
                    console.log(error);
                    this.loadingPage = 0;
                });
        } else if (this.$session.has("mutation_detail")) {
            this.editMutation(this.$session.get("mutation_detail"))
                .then(response => {
                    this.loadingPage = 0;
                })
                .catch(error => {
                    console.log(error);
                    this.loadingPage = 0;
                });
        } else {
            this.$router.push({ name: "adjustment-detail" });
            this.alert("Data not found, select data first", 2);
        }
        this.getSignatureAndBank();
    },
    methods: {
        //VUEX
        ...mapMutations("mutation", ["CLEAR_FORM"]),
        ...mapActions("warehouse", ["getWarehouseAll"]),
        ...mapMutations(["CLEAR_ERRORS"]),
        ...mapActions("uom", ["getUomAll"]),
        ...mapActions("item", ["getItemAll"]),
        ...mapActions("mutation", [
            "getMutationOutNo",
            "editMutation",
            "printMutation"
        ]),
        ...mapActions("signature", ["getSignatureDocument"]),
        ...mapActions("bank", ["getBankDocument"]),
        goToBill(val) {
            this.$router.push({ name: "bill-detail", params: { id: val } });
        },
        goToPurchase(val) {
            this.$router.push({ name: "purchase-detail", params: { id: val } });
        },
        goToVoucher(val) {
            this.$router.push({
                name: "journal-transaction-voucher",
                params: { id: val }
            });
        },
        getSignatureAndBank() {
            this.getSignatureDocument({ document: "signature_asset" })
                .then(response => {
                    this.loadingPage = false;
                })
                .catch(error => {
                    console.log(error);
                    this.loadingPage = false;
                });
        },
        selectSignature(val) {
            if (val != "") {
                var result = this.signatures.data.find(obj => {
                    return obj.id === parseInt(val);
                });
                this.docSignature.signature_name = result.signature_name;
                this.docSignature.signature_position =
                    result.signature_position;
            } else {
                this.docSignature.signature_name = "[select signature]";
                this.docSignature.signature_position = "[select signature]";
            }
        },
        getJournalMemo() {
            this.mutation.mutation_memo =
                "Mutation Out " +
                moment(this.mutation.mutation_date).format("DD/MM/YYYY");
        },
        getUomUnit(index, val) {
            this.mutation.detail[index].item_uom_list = [];
            this.mutation.detail[index].uom_id = "";
            if (val != null && val != 0) {
                var item = this.items.find(obj => {
                    return obj.id === parseInt(val);
                });
                var result = this.uoms.filter(obj => {
                    return (
                        obj.id === parseInt(item.uom_id)
                        // || obj.uom_conversion_head === parseInt(item.uom_id)
                    );
                });
                this.mutation.detail[index].item_uom_list = result;
                this.mutation.detail[index].uom_id = result[0].id;
            }
            this.calculate();
        },
        calculate() {
            var total = 0;
            this.mutation.detail.forEach(detail => {
                total += detail.in_amount;
            });
            this.mutation.mutation_total = total;
        },
        calculateItem(index) {
            // this.mutation.detail[index].in_qty = this.mutation.detail[
            //     index
            // ].in_qty.replace(/[^0-9]/g, "");
            this.mutation.detail[index].in_amount =
                this.mutation.detail[index].in_qty *
                this.mutation.detail[index].in_price;
            this.calculate();
        },
        errorLogoUrl(event) {
            event.target.src = "/media/workspace/no-logo.jpg";
        },
        print() {
            var browserName = navigator.appName;
            if (browserName == "Safari") {
                var printContents = document.getElementById("printMe")
                    .innerHTML;
                var originalContents = document.body.innerHTML;
                document.body.innerHTML = printContents;
                window.print();
                document.body.innerHTML = originalContents;
            } else {
                this.$htmlToPaper("printMe");
            }
        }
    },
    destroyed() {
        this.CLEAR_FORM();
    }
};
</script>
