var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"block block-full mb-0",staticStyle:{"min-height":"450px"}},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"block-content pt-2"},[_c('div',{staticClass:"container"},[_c('p',{staticClass:"mb-0 mt-2 font-weight-bold"},[_vm._v("Payable & Receivable")]),_vm._v(" "),_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-4 p-2"},[_c('router-link',{staticClass:"block block-rounded block-report-panel h-100 mb-0",attrs:{"to":{
                            name: 'bill-report'
                        },"tag":"div"}},[_c('div',{staticClass:"block-header pb-0 px-3"},[_c('h3',{staticClass:"block-title"},[_vm._v("\n                                Bill Detail\n                                ")])]),_vm._v(" "),_c('div',{staticClass:"block-content py-1 text-muted-high px-3"},[_c('p',{staticClass:"mb-3"},[_vm._v("See a detailed listed bill transaction at certain period of time ")])])])],1),_vm._v(" "),_c('div',{staticClass:"col-4 p-2"},[_c('router-link',{staticClass:"block block-rounded block-report-panel h-100 mb-0",attrs:{"to":{
                            name: 'purchase-report'
                        },"tag":"div"}},[_c('div',{staticClass:"block-header pb-0 px-3"},[_c('h3',{staticClass:"block-title"},[_vm._v("\n                                Purchase Detail\n                                ")])]),_vm._v(" "),_c('div',{staticClass:"block-content py-1 text-muted-high px-3"},[_c('p',{staticClass:"mb-3"},[_vm._v("See a detailed  listed purchase transaction at certain period of time ")])])])],1)]),_vm._v(" "),_c('p',{staticClass:"mb-0 mt-2 font-weight-bold"},[_vm._v("Inventories")]),_vm._v(" "),_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-4 p-2"},[_c('router-link',{staticClass:"block block-rounded block-report-panel h-100 mb-0",attrs:{"to":{
                            name: 'report-item-summary'
                        },"tag":"div"}},[_c('div',{staticClass:"block-header pb-0 px-3"},[_c('h3',{staticClass:"block-title"},[_vm._v("\n                                Item Summary "),_c('small',[_vm._v("New")])])]),_vm._v(" "),_c('div',{staticClass:"block-content py-1 text-muted-high px-3"},[_c('p',{staticClass:"mb-3"},[_vm._v("See a summary of all your listed inventories")])])])],1),_vm._v(" "),_c('div',{staticClass:"col-4 p-2"},[_c('router-link',{staticClass:"block block-rounded block-report-panel h-100 mb-0",attrs:{"to":{
                            name: 'report-item-detail'
                        },"tag":"div"}},[_c('div',{staticClass:"block-header pb-0 px-3"},[_c('h3',{staticClass:"block-title"},[_vm._v("\n                                Item Detail "),_c('small',[_vm._v("New")])])]),_vm._v(" "),_c('div',{staticClass:"block-content py-1 text-muted-high px-3"},[_c('p',{staticClass:"mb-3"},[_vm._v("See quantity of hand & value movement of all your listed inventories")])])])],1)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"block-header"},[_c('h2',{staticClass:"block-title",staticStyle:{"font-size":"18px"}},[_vm._v("\n            Transaction\n        ")])])}]

export { render, staticRenderFns }