var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container"},[_c('main',{attrs:{"id":"main-container"}},[_c('div',{staticClass:"hero-static"},[_c('div',{staticClass:"content",staticStyle:{"background":"url(media/proxima/background.png) no-repeat","background-size":"cover"}},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-8 col-lg-6 col-xl-4"},[_c('div',{staticClass:"block block-themed block-fx-shadow mb-0 block-rounded block-mode-loading-oneui",staticStyle:{"border-radius":"15px !important"}},[_c('div',{staticClass:"block-header bg-info",staticStyle:{"border-top-left-radius":"15px","border-top-right-radius":"15px"}},[_c('img',{staticClass:"m-auto",attrs:{"src":'/media/proxima/logotype-white.png',"height":"30px"}})]),_vm._v(" "),_c('div',{staticClass:"block-content"},[_c('div',{staticClass:"p-sm-3 px-lg-4 py-lg-5"},[_c('h3',{staticClass:"mb-2 text-black-75"},[_vm._v("\n                                        Daftar Akun\n                                    ")]),_vm._v(" "),_c('p',{staticClass:"text-black-75"},[_vm._v("\n                                        Daftar dan coba gratis Proxima\n                                        selama 7 Hari !\n                                    ")]),_vm._v(" "),_c('form',{staticClass:"js-validation-signup"},[_c('div',{staticClass:"py-3"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.register.email),expression:"register.email"}],staticClass:"form-control form-control-lg form-control-alt",class:{
                                                        'is-invalid':
                                                            _vm.errors.email ||
                                                            _vm.errors.invalid,
                                                    },staticStyle:{"border-color":"#d5d5d5"},attrs:{"type":"email","placeholder":"Email"},domProps:{"value":(_vm.register.email)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.preventDefault();},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.register, "email", $event.target.value)}}}),_vm._v(" "),(_vm.errors.email)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                                    "+_vm._s(_vm.errors.email[0])+"\n                                                ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.register.name),expression:"register.name"}],staticClass:"form-control form-control-lg form-control-alt",class:{
                                                        'is-invalid':
                                                            _vm.errors.name ||
                                                            _vm.errors.invalid,
                                                    },staticStyle:{"border-color":"#d5d5d5"},attrs:{"type":"text","placeholder":"Nama Lengkap"},domProps:{"value":(_vm.register.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.register, "name", $event.target.value)}}}),_vm._v(" "),(_vm.errors.name)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                                    "+_vm._s(_vm.errors.name[0])+"\n                                                ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                        _vm.register.company
                                                    ),expression:"\n                                                        register.company\n                                                    "}],staticClass:"form-control form-control-lg form-control-alt",class:{
                                                        'is-invalid':
                                                            _vm.errors.company ||
                                                            _vm.errors.invalid,
                                                    },staticStyle:{"border-color":"#d5d5d5"},attrs:{"type":"text","placeholder":"Nama Perusahaan"},domProps:{"value":(
                                                        _vm.register.company
                                                    )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.register, "company", $event.target.value)}}}),_vm._v(" "),(_vm.errors.company)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                                    "+_vm._s(_vm.errors.company[0])+"\n                                                ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                        _vm.register.username
                                                    ),expression:"\n                                                        register.username\n                                                    "}],staticClass:"form-control form-control-lg form-control-alt",class:{
                                                        'is-invalid':
                                                            _vm.errors.username ||
                                                            _vm.errors.invalid,
                                                    },staticStyle:{"border-color":"#d5d5d5"},attrs:{"type":"text","placeholder":"Username"},domProps:{"value":(
                                                        _vm.register.username
                                                    )},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.preventDefault();},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.register, "username", $event.target.value)}}}),_vm._v(" "),(_vm.errors.username)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                                    "+_vm._s(_vm.errors.username[0])+"\n                                                ")]):_vm._e()]),_vm._v(" "),(!_vm.passwordHidden)?_c('div',{staticClass:"form-group has-feedback"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.register.password),expression:"register.password"}],staticClass:"form-control form-control-lg form-control-alt d-inline",class:{
                                                        'is-invalid':
                                                            _vm.errors.password ||
                                                            _vm.errors.invalid
                                                    },staticStyle:{"border-color":"#d5d5d5","margin-right":"-45px","padding-right":"35px"},attrs:{"type":"text","placeholder":"Password","autocomplete":"off"},domProps:{"value":(_vm.register.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.register, "password", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"display-eye fa fa-eye-slash text-pointer",on:{"click":_vm.hidePassword}}),_vm._v(" "),(_vm.errors.password)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                                    "+_vm._s(_vm.errors.password[0])+"\n                                                ")]):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.passwordHidden)?_c('div',{staticClass:"form-group has-feedback"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.register.password),expression:"register.password"}],staticClass:"form-control form-control-lg form-control-alt d-inline",class:{
                                                        'is-invalid':
                                                            _vm.errors.password ||
                                                            _vm.errors.invalid
                                                    },staticStyle:{"border-color":"#d5d5d5","margin-right":"-45px","padding-right":"35px"},attrs:{"type":"password","placeholder":"Password","autocomplete":"off"},domProps:{"value":(_vm.register.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.register, "password", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"display-eye fa fa-eye text-pointer",on:{"click":_vm.showPassword}}),_vm._v(" "),(_vm.errors.password)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                                    "+_vm._s(_vm.errors.password[0])+"\n                                                ")]):_vm._e()]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                        _vm.register.password_confirmation
                                                    ),expression:"\n                                                        register.password_confirmation\n                                                    "}],staticClass:"form-control form-control-lg form-control-alt",class:{
                                                        'is-invalid':
                                                            _vm.errors.password_confirmation ||
                                                            _vm.errors.invalid,
                                                    },staticStyle:{"border-color":"#d5d5d5"},attrs:{"type":"password","placeholder":"Konfirmasi Password","autocomplete":"off"},domProps:{"value":(
                                                        _vm.register.password_confirmation
                                                    )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.register, "password_confirmation", $event.target.value)}}})])]),_vm._v(" "),_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"col-12"},[(_vm.loadingPage == 0)?_c('button',{staticClass:"btn btn-sm btn-block btn-info",attrs:{"type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.postRegister($event)}}},[_c('i',{staticClass:"fa fa-fw fa-plus mr-1"}),_vm._v("\n                                                    Buat Akun\n                                                ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"col-6 text-center footer-lane-right"},[_c('span',[_vm._v("\n                                                    Sudah Punya Akun ?\n                                                    "),_c('br'),_vm._v(" "),_c('router-link',{staticClass:"text-info font-size-sm",attrs:{"to":{
                                                            name: 'login'
                                                        }}},[_vm._v("Login disini\n                                                    ")])],1)]),_vm._v(" "),_vm._m(0)])])])])])])]),_vm._v(" "),_vm._m(1)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-6 text-center"},[_c('span',[_vm._v("\n                                                    Ingin Berlangganan ?\n                                                    "),_c('br'),_vm._v(" "),_c('a',{staticClass:"text-info font-size-sm",attrs:{"href":"https://tripasysfo.com/proxima#price"}},[_vm._v("Registrasi disini\n                                                    ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"font-size-sm text-center text-muted py-3"},[_c('strong',[_vm._v("Proxima - Aquarius")]),_vm._v(" © Tripasysfo\n                    Development\n                ")])}]

export { render, staticRenderFns }