<template>
    <main>
        <hero
            title="Create Item"
            prevBreadcrumb="Products & Services"
            routeBreadcrumb="products-services"
            currentBreadcrumb="Create Item"
        ></hero>

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <div
                class="block col-12 col-md-10 offset-md-1 block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 1"
                style="height: 250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <div
                class="block col-12 col-md-10 offset-md-1 block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 2"
                style="height: 250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Save Data</span
                        >
                    </center>
                </div>
            </div>
            <form
                method="POST"
                enctype="multipart/form-data"
                onsubmit="return false;"
            >
                <!-- Basic -->
                <div
                    class="block col-12 col-md-10 offset-md-1 block-rounded block-mode-loading-oneui"
                    v-if="loadingPage == 0"
                >
                    <div class="block-content block-content-full">
                        <h5>General</h5>
                        <!--Form-->
                        <div class="row font-size-sm">
                            <div class="col-12">
                                <!-- WITH UNIT OF MEASURE -->
                                <div class="row mt-1">
                                    <div class="form-group col-4 py-1">
                                        <label for="example-text-input"
                                            >Code *</label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Item Code or SKU"
                                            v-model="item.item_code"

                                        />
                                        <transition name="slide-fade">
                                            <p
                                                class="text-danger"
                                                v-if="errors.item_code"
                                            >
                                                {{ errors.item_code[0] }}
                                            </p>
                                        </transition>
                                    </div>
                                    <div class="form-group col-8 py-1">
                                        <label for="example-text-input"
                                            >Name *</label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Enter item name, specification, etc"
                                            v-model="item.item_name"
                                            :class="{
                                                'is-invalid': errors.item_name
                                            }"
                                        />
                                        <transition name="slide-fade">
                                            <p
                                                class="text-danger"
                                                v-if="errors.item_name"
                                            >
                                                {{ errors.item_name[0] }}
                                            </p>
                                        </transition>
                                    </div>
                                </div>
                                <!-- WITH UNIT OF MEASURE -->
                                <div class="row mt-1">
                                    <div class="form-group col-4 py-1">
                                        <label for="example-text-input"
                                            >Base Unit *</label
                                        >
                                        <selectize
                                            class="form-control"
                                            placeholder="Item base unit"
                                            v-model="item.uom_id"
                                            :class="{
                                                'is-invalid-selectize':
                                                    errors.uom_id
                                            }"
                                        >
                                            <option
                                                v-for="uomdata in uoms"
                                                :value="uomdata.id"
                                                :key="uomdata.id"
                                            >
                                                {{ uomdata.uom_name }} ({{
                                                    uomdata.uom_code
                                                }})
                                            </option>
                                        </selectize>
                                        <transition name="slide-fade">
                                            <p
                                                class="text-danger"
                                                v-if="errors.uom_id"
                                            >
                                                {{ errors.uom_id[0] }}
                                            </p>
                                        </transition>
                                    </div>
                                    <div class="form-group col-4 py-1">
                                        <label for="example-text-input"
                                            >Type</label
                                        >
                                        <selectize
                                            class="form-control"
                                            placeholder="Item Type"
                                            v-model="item.type_id"
                                            :class="{
                                                'is-invalid-selectize':
                                                    errors.type_id
                                            }"
                                            @input="setItemType()"
                                        >
                                            <!-- settings is optional -->
                                            <option
                                                v-for="type in types"
                                                :value="type.id"
                                                :key="type.id"
                                            >
                                                {{ type.type_name }}
                                            </option>
                                        </selectize>
                                        <transition name="slide-fade">
                                            <p
                                                class="text-danger"
                                                v-if="errors.type_id"
                                            >
                                                {{ errors.type_id[0] }}
                                            </p>
                                        </transition>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Description</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Item Description"
                                        v-model="item.item_desc"
                                        :class="{
                                            'is-invalid': errors.item_desc
                                        }"
                                    />
                                    <transition name="slide-fade">
                                        <p
                                            class="text-danger"
                                            v-if="errors.item_desc"
                                        >
                                            {{ errors.item_desc[0] }}
                                        </p>
                                    </transition>
                                </div>

                            </div>
                        </div>
                        <!--END Form-->
                    </div>
                </div>
                <!-- END Basic -->

                <!-- Basic -->
                <div
                    class="block col-12 col-md-10 offset-md-1 block-rounded block-mode-loading-oneui"
                    v-if="loadingPage == 0"
                >
                    <div class="block-content block-content-full">
                        <h5 class="mb-3">Opening Balance</h5>
                        <!--Form-->
                        <div class="row font-size-sm">
                            <div class="col-12">
                                <div class="form-notification">
                                    <div class="row mb-1">
                                        <div class="col-12">
                                            <div class="information-panel">
                                                <p class="my-1">
                                                    <i
                                                        class="fa fa-info-circle mr-1"
                                                    ></i
                                                    ><b>Information</b>
                                                </p>
                                                <p class="mb-2">
                                                    You can fill the Opening
                                                    Balance Amount if Item
                                                    already have Opening
                                                    Balance, this Opening
                                                    Balance will not included to
                                                    any account's opening
                                                    balance, it will only used
                                                    for Item Stock & Mutation
                                                    purpose only
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-1 mb-2">
                                    <div class="form-group col-4 py-0 mb-1">
                                        <label for="example-text-input"
                                            >Opening Date</label
                                        >
                                        <date-picker
                                            class="input-group"
                                            v-model="item.opening_date"
                                            format="DD/MM/YYYY"
                                            valueType="YYYY-MM-DD"
                                            placeholder="Issued On"
                                            :class="{
                                                'is-invalid':
                                                    errors.opening_date
                                            }"
                                            :default-value="new Date()"
                                            :disabled-date="disabledDatePicker"
                                        ></date-picker>
                                        <transition name="slide-fade">
                                            <p
                                                class="text-danger"
                                                v-if="errors.opening_date"
                                            >
                                                {{ errors.opening_date[0] }}
                                            </p>
                                        </transition>
                                    </div>
                                    <div
                                        class="form-group col-5 py-0 mb-1 mt-1 text-muted-high"
                                    >
                                        Opening Date will be set as the initial
                                        date for this inventory item, all
                                        transaction & mutation for these item
                                        can only be made after the opening date.
                                    </div>
                                </div>
                                <div class="row mt-1">
                                    <div class="form-group col-4 py-0 mb-1">
                                        <label for="example-text-input"
                                            >Opening Quantity</label
                                        >
                                        <numeric
                                            class="form-control"
                                            placeholder="Quantity"
                                            v-model.number="item.opening_qty"
                                            :class="{
                                                'is-invalid': errors.opening_qty
                                            }"
                                            separator="."
                                            :precision="2"
                                            @input="calculate()"
                                        />
                                        <transition name="slide-fade">
                                            <p
                                                class="text-danger"
                                                v-if="errors.opening_qty"
                                            >
                                                {{ errors.opening_qty[0] }}
                                            </p>
                                        </transition>
                                    </div>
                                    <div class="form-group col-4 py-0 mb-1">
                                        <label for="example-text-input"
                                            >Opening Cost per Item</label
                                        >
                                        <money
                                            class="form-control"
                                            style="text-align: right;"
                                            v-model="item.opening_price"
                                            spellcheck="false"
                                            :class="{
                                                'is-invalid':
                                                    errors.opening_price
                                            }"
                                            @input="calculate()"
                                        ></money>
                                        <transition name="slide-fade">
                                            <p
                                                class="text-danger"
                                                v-if="errors.opening_price"
                                            >
                                                {{ errors.opening_price[0] }}
                                            </p>
                                        </transition>
                                    </div>
                                    <div class="form-group col-4 py-0 mb-1">
                                        <label for="example-text-input"
                                            >Opening Amount</label
                                        >
                                        <money
                                            class="form-control"
                                            style="text-align: right;"
                                            v-model="item.opening_amount"
                                            spellcheck="false"
                                            :class="{
                                                'is-invalid':
                                                    errors.opening_amount
                                            }"
                                        ></money>
                                        <transition name="slide-fade">
                                            <p
                                                class="text-danger"
                                                v-if="errors.opening_amount"
                                            >
                                                {{ errors.opening_amount[0] }}
                                            </p>
                                        </transition>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--END Form-->
                    </div>
                </div>
                <!-- END Basic -->

                <!-- Basic -->
                <div
                    class="block col-12 col-md-10 offset-md-1 block-rounded block-mode-loading-oneui"
                    v-if="loadingPage == 0"
                >
                    <div class="block-content block-content-full">
                        <h5 class="mb-3">Bookkeeping</h5>
                        <!--Form-->
                        <div class="row font-size-sm">
                            <div class="col-12">
                                <!-- INVENTORY SECTION -->
                                <div class="form-group">
                                    <div
                                        class="form-check form-check-inline mr-0 mb-1"
                                    >
                                        <input
                                            class="form-check-input mr-1"
                                            type="checkbox"
                                            id="checkbox-inventory"
                                            @change="
                                                activeInactiveInventory($event)
                                            "
                                            v-model="checkedInventory"
                                        />
                                        <label
                                            class="form-check-label font-weight-bolder ml-1"
                                            for="checkbox-inventory"
                                            >I Track this inventory item</label
                                        >
                                    </div>
                                    <div class="form-information ml-4">
                                        Track the quantity and value of stock on
                                        hand. This options is suitable for
                                        business that
                                        <b class="font-weight-bolder">
                                            track item price when the item is
                                            purchased or acquired before being
                                            sold and using Perpetual FIFO
                                            inventory system,</b
                                        >
                                        if you don’t do this or using Periodic
                                        or using Average Inventory Method,
                                        proceed with not tracking this inventory
                                    </div>
                                    <div
                                        class="form-notification ml-4"
                                        v-if="checkedInventory"
                                    >
                                        <div class="row mb-1">
                                            <div class="col-12">
                                                <div class="information-panel">
                                                    <p class="my-1">
                                                        <i
                                                            class="fa fa-info-circle mr-1"
                                                        ></i
                                                        ><b>Information</b>
                                                    </p>
                                                    <p class="mb-2">
                                                        You cannot change this
                                                        value once they appear
                                                        in a transaction (bills,
                                                        purchase, etc)
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mt-1 ml-4">
                                        <div
                                            class="form-group col-4 py-0 mb-1 pl-0"
                                            v-if="checkedInventory"
                                        >
                                            <label for="example-text-input"
                                                >Inventory Account *</label
                                            >
                                            <selectize
                                                class="form-control mt-0"
                                                placeholder="Choose Account No."
                                                v-model="item.inventory_coa_id"
                                                :class="{
                                                    'is-invalid-selectize':
                                                        errors.inventory_coa_id
                                                }"
                                            >
                                                <!-- settings is optional -->
                                                <option
                                                    v-for="account in coas"
                                                    :value="account.id"
                                                    :key="account.id"
                                                >
                                                    {{ account.account_no }}
                                                    {{ account.account_name }}
                                                </option>
                                            </selectize>
                                            <transition name="slide-fade">
                                                <p
                                                    class="text-danger"
                                                    v-if="
                                                        errors.inventory_coa_id
                                                    "
                                                >
                                                    {{
                                                        errors
                                                            .inventory_coa_id[0]
                                                    }}
                                                </p>
                                            </transition>
                                        </div>
                                        <div
                                            class="form-group col-4 py-0 mb-1"
                                            v-if="checkedInventory"
                                        >
                                            <label for="example-text-input"
                                                >COGS Account *</label
                                            >
                                            <selectize
                                                class="form-control mt-0"
                                                placeholder="Choose Account No."
                                                v-model="item.expense_coa_id"
                                                :class="{
                                                    'is-invalid-selectize':
                                                        errors.expense_coa_id
                                                }"
                                            >
                                                <!-- settings is optional -->
                                                <option
                                                    v-for="account in coas"
                                                    :value="account.id"
                                                    :key="account.id"
                                                >
                                                    {{ account.account_no }}
                                                    {{ account.account_name }}
                                                </option>
                                            </selectize>
                                            <transition name="slide-fade">
                                                <p
                                                    class="text-danger"
                                                    v-if="errors.expense_coa_id"
                                                >
                                                    {{
                                                        errors.expense_coa_id[0]
                                                    }}
                                                </p>
                                            </transition>
                                        </div>
                                    </div>
                                </div>
                                <!-- PURCHASE SECTION -->
                                <div class="form-group">
                                    <div
                                        class="form-check form-check-inline mr-0 mb-1"
                                    >
                                        <input
                                            class="form-check-input mr-1"
                                            type="checkbox"
                                            id="checkbox-purchase"
                                            @change="
                                                activeInactivePurchase($event)
                                            "
                                            v-model="checkedPurchase"
                                        />
                                        <label
                                            class="form-check-label font-weight-bolder ml-1"
                                            for="checkbox-purchase"
                                            >I Purchase this item</label
                                        >
                                    </div>
                                    <div class="form-information ml-4">
                                        Add item to purchases. Enabling
                                        expense/any other reports made for this
                                        item
                                    </div>
                                    <div class="row mt-1 ml-4">
                                        <div
                                            class="form-group col-4 py-0 mb-1 pl-0"
                                            v-if="
                                                checkedPurchase &&
                                                    !checkedInventory
                                            "
                                        >
                                            <label for="example-text-input"
                                                >Purchase Account *</label
                                            >
                                            <selectize
                                                class="form-control mt-0"
                                                placeholder="Choose Account No."
                                                v-model="item.expense_coa_id"
                                                :class="{
                                                    'is-invalid-selectize':
                                                        errors.expense_coa_id
                                                }"
                                            >
                                                <!-- settings is optional -->
                                                <option
                                                    v-for="account in coas"
                                                    :value="account.id"
                                                    :key="account.id"
                                                >
                                                    {{ account.account_no }}
                                                    {{ account.account_name }}
                                                </option>
                                            </selectize>
                                            <transition name="slide-fade">
                                                <p
                                                    class="text-danger"
                                                    v-if="errors.expense_coa_id"
                                                >
                                                    {{
                                                        errors.expense_coa_id[0]
                                                    }}
                                                </p>
                                            </transition>
                                        </div>
                                        <div
                                            class="form-group col-4 py-0 mb-1"
                                            v-bind:class="{
                                                'pl-0':
                                                    checkedPurchase &&
                                                    checkedInventory
                                            }"
                                            v-if="checkedPurchase"
                                        >
                                            <label for="example-text-input"
                                                >Purchase Price *</label
                                            >
                                            <money
                                                class="form-control"
                                                style="text-align: right;"
                                                v-model="item.purchase_price"
                                                spellcheck="false"
                                                :class="{
                                                    'is-invalid':
                                                        errors.purchase_price
                                                }"
                                            ></money>
                                            <transition name="slide-fade">
                                                <p
                                                    class="text-danger"
                                                    v-if="errors.purchase_price"
                                                >
                                                    {{
                                                        errors.purchase_price[0]
                                                    }}
                                                </p>
                                            </transition>
                                        </div>
                                        <div
                                            class="form-group col-4 py-0 mb-1"
                                            v-if="checkedPurchase"
                                        >
                                            <label for="example-text-input"
                                                >Purchase Tax *</label
                                            >
                                            <selectize
                                                class="form-control mt-0"
                                                placeholder="Choose Tax"
                                                v-model="item.purchase_tax_id"
                                                :class="{
                                                    'is-invalid-selectize':
                                                        errors.purchase_tax_id
                                                }"
                                            >
                                                <!-- settings is optional -->
                                                <option
                                                    v-for="tax in purchaseTaxs"
                                                    :value="tax.id"
                                                    :key="tax.id"
                                                >
                                                    {{ tax.tax_code }}
                                                    ({{ tax.tax_rate }}%)
                                                </option>
                                            </selectize>
                                            <transition name="slide-fade">
                                                <p
                                                    class="text-danger"
                                                    v-if="
                                                        errors.purchase_tax_id
                                                    "
                                                >
                                                    {{
                                                        errors
                                                            .purchase_tax_id[0]
                                                    }}
                                                </p>
                                            </transition>
                                        </div>
                                    </div>

                                    <div class="row ml-4">
                                        <div
                                            class="form-group col-12 py-0 pl-0"
                                            v-if="checkedPurchase"
                                        >
                                            <label for="example-text-input"
                                                >Description</label
                                            >
                                            <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Item Description"
                                                v-model="item.purchase_desc"
                                                :class="{
                                                    'is-invalid':
                                                        errors.purchase_desc
                                                }"
                                            />
                                            <transition name="slide-fade">
                                                <p
                                                    class="text-danger"
                                                    v-if="errors.purchase_desc"
                                                >
                                                    {{
                                                        errors.purchase_desc[0]
                                                    }}
                                                </p>
                                            </transition>
                                        </div>
                                    </div>
                                </div>
                                <!-- SELL SECTION -->
                                <div class="form-group">
                                    <div
                                        class="form-check form-check-inline mr-0 mb-1"
                                    >
                                        <input
                                            class="form-check-input mr-1"
                                            type="checkbox"
                                            id="checkbox-sell"
                                            @change="activeInactiveSell($event)"
                                            v-model="checkedSell"
                                        />
                                        <label
                                            class="form-check-label font-weight-bolder ml-1"
                                            for="checkbox-sell"
                                            >I Sell this item</label
                                        >
                                    </div>
                                    <div class="form-information ml-4">
                                        Add item to purchases. Enabling
                                        expense/any other reports made for this
                                        item
                                    </div>
                                    <div class="row mt-1 ml-4">
                                        <div
                                            class="form-group col-4 py-0 mb-1 pl-0"
                                            v-if="checkedSell"
                                        >
                                            <label for="example-text-input"
                                                >Sales Account *</label
                                            >
                                            <selectize
                                                class="form-control mt-0"
                                                placeholder="Choose Account No."
                                                v-model="item.income_coa_id"
                                                :class="{
                                                    'is-invalid-selectize':
                                                        errors.income_coa_id
                                                }"
                                            >
                                                <!-- settings is optional -->
                                                <option
                                                    v-for="account in coas"
                                                    :value="account.id"
                                                    :key="account.id"
                                                >
                                                    {{ account.account_no }}
                                                    {{ account.account_name }}
                                                </option>
                                            </selectize>
                                            <transition name="slide-fade">
                                                <p
                                                    class="text-danger"
                                                    v-if="errors.income_coa_id"
                                                >
                                                    {{
                                                        errors.income_coa_id[0]
                                                    }}
                                                </p>
                                            </transition>
                                        </div>
                                        <div
                                            class="form-group col-4 py-0 mb-1"
                                            v-if="checkedSell"
                                        >
                                            <label for="example-text-input"
                                                >Sales Price *</label
                                            >
                                            <money
                                                class="form-control"
                                                style="text-align: right;"
                                                v-model="item.sell_price"
                                                spellcheck="false"
                                                :class="{
                                                    'is-invalid':
                                                        errors.sell_price
                                                }"
                                            ></money>
                                            <transition name="slide-fade">
                                                <p
                                                    class="text-danger"
                                                    v-if="errors.sell_price"
                                                >
                                                    {{ errors.sell_price[0] }}
                                                </p>
                                            </transition>
                                        </div>
                                        <div
                                            class="form-group col-4 py-0 mb-1"
                                            v-if="checkedSell"
                                        >
                                            <label for="example-text-input"
                                                >Sales Tax *</label
                                            >
                                            <selectize
                                                class="form-control mt-0"
                                                placeholder="Choose Tax"
                                                v-model="item.sell_tax_id"
                                                :class="{
                                                    'is-invalid-selectize':
                                                        errors.sell_tax_id
                                                }"
                                            >
                                                <!-- settings is optional -->
                                                <option
                                                    v-for="tax in purchaseTaxs"
                                                    :value="tax.id"
                                                    :key="tax.id"
                                                >
                                                    {{ tax.tax_code }}
                                                    ({{ tax.tax_rate }}%)
                                                </option>
                                            </selectize>
                                            <transition name="slide-fade">
                                                <p
                                                    class="text-danger"
                                                    v-if="errors.sell_tax_id"
                                                >
                                                    {{ errors.sell_tax_id[0] }}
                                                </p>
                                            </transition>
                                        </div>
                                    </div>

                                    <div class="row ml-4">
                                        <div
                                            class="form-group col-12 py-0 pl-0"
                                            v-if="checkedSell"
                                        >
                                            <label for="example-text-input"
                                                >Description</label
                                            >
                                            <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Item Description"
                                                v-model="item.sell_desc"
                                                :class="{
                                                    'is-invalid':
                                                        errors.sell_desc
                                                }"
                                            />
                                            <transition name="slide-fade">
                                                <p
                                                    class="text-danger"
                                                    v-if="errors.sell_desc"
                                                >
                                                    {{ errors.sell_desc[0] }}
                                                </p>
                                            </transition>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--END Form-->
                    </div>
                </div>
                <!-- END Basic -->
                <!-- Basic -->
                <div
                    class="block col-12 col-md-10 offset-md-1 block-rounded block-mode-loading-oneui"
                    v-if="loadingPage == 0"
                >
                    <div class="block-content block-content-full py-0">
                        <!-- Submit -->
                        <div class="row items-push">
                            <div class="col-12 text-right mb-0">
                                <button
                                    type="submit"
                                    class="btn btn-sm btn-outline-info mr-1"
                                    v-ripple="{ center: true }"
                                    @click.prevent="submit('withRefresh')"
                                >
                                    <i class="fa fa-fw fa-plus mr-1"></i> Save &
                                    Add Another
                                </button>
                                <button
                                    type="submit"
                                    class="btn btn-sm btn-info"
                                    v-ripple="{ center: true }"
                                    @click.prevent="submit('withPush')"
                                >
                                    <i class="fa fa-fw fa-check mr-1"></i> Save
                                </button>
                            </div>
                        </div>
                        <!-- END Submit -->
                    </div>
                </div>
                <!-- END Basic -->
            </form>
        </div>
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import moment from "moment";

export default {
    name: "item-create",
    data() {
        return {
            loadingPage: 0,
            disabled: {},
            checkedSell: false,
            checkedPurchase: false,
            checkedInventory: false,
            checkedOpening: false
        };
    },
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState("item", { item: state => state.item }),
        ...mapState("coa", { coas: state => state.coas.accounts }),
        ...mapState("tax", { taxs: state => state.taxs }),
        ...mapState("uom", { uoms: state => state.uoms }),
                ...mapState("forbiddendate", {
            forbiddenDateLast: state => state.forbiddenDateLast
        }),
        ...mapState("itemtype", {
            types: state => state.types
        }),
        inventoryAccounts: function() {
            if (this.coas) {
                return this.coas.filter(function(coa) {
                    return (
                        coa.account_type === "Bank" ||
                        coa.account_type === "Account Receivable" ||
                        coa.account_type === "Other Current Asset" ||
                        coa.account_type === "Other Asset" ||
                        coa.account_type === "Fixed Asset"
                    );
                    // return coa;
                });
            }
        },
        incomeAccounts: function() {
            if (this.coas) {
                return this.coas.filter(function(coa) {
                    return (
                        coa.account_type === "Income" ||
                        coa.account_type === "Other Income"
                    );
                });
            }
        },
        expenseAccounts: function() {
            if (this.coas) {
                if (this.checkedInventory == true) {
                    return this.coas.filter(function(coa) {
                        return coa.account_type === "Cost of Sales";
                        // return coa;
                    });
                } else {
                    return this.coas.filter(function(coa) {
                        return (
                            coa.account_type === "Expense" ||
                            coa.account_type === "Other Expense"
                        );
                        // return coa;
                    });
                }
            }
        },
        sellTaxs: function() {
            if (this.taxs) {
                return this.taxs.filter(function(tax) {
                    return tax.tax_coa_ap !== null || tax.tax_rate == 0;
                });
            }
        },
        purchaseTaxs: function() {
            if (this.taxs) {
                return this.taxs.filter(function(tax) {
                    return tax.tax_coa_ar !== null || tax.tax_rate == 0;
                });
            }
        }
    },
    created() {
        this.getTypeAll();
        this.getForbiddenDate();
        this.getUomBase();
        this.getCoaAll();
        this.getTaxAll();
    },
    methods: {
        //VUEX
        ...mapMutations("item", ["CLEAR_FORM"]),
        ...mapActions("coa", ["getCoaAll"]),
        ...mapActions("tax", ["getTaxAll"]),
        ...mapMutations(["CLEAR_ERRORS"]),
        ...mapActions("item", ["submitItem"]),
        ...mapActions("itemtype", ["getTypeAll"]),
        ...mapActions("forbiddendate", ["getForbiddenDate"]),
        ...mapActions("uom", ["getUomBase"]),
        activeInactiveSell() {
            this.checkedSell == true
                ? (this.item.is_sell = 1)
                : (this.item.is_sell = 0);
        },
        activeInactivePurchase() {
            this.checkedPurchase == true
                ? (this.item.is_purchase = 1)
                : (this.item.is_purchase = 0);
        },
        activeInactiveInventory() {
            this.checkedInventory == true
                ? (this.item.is_inventory = 1)
                : (this.item.is_inventory = 0);
            this.item.expense_coa_id == 0;
        },
        activeInactiveOpening() {
            this.checkedOpening == true
                ? (this.item.is_opening = 1)
                : (this.item.is_opening = 0);
        },
        calculate(){
            this.item.opening_amount = this.item.opening_qty * this.item.opening_price; 
        },
        setItemType() {
            let obj = this.types.find(o => o.id == this.item.type_id);
            console.log(obj);
            this.item.is_inventory = obj.is_inventory;
            this.item.is_purchase = obj.is_purchase;
            this.item.is_sell = obj.is_sell;
            this.checkedInventory = obj.is_inventory == 1 ? true : false;
            this.checkedSell = obj.is_sell == 1 ? true : false;
            this.checkedPurchase = obj.is_purchase == 1 ? true : false;
            this.item.inventory_coa_id = obj.inventory_coa_id;
            this.item.income_coa_id = obj.income_coa_id;
            this.item.expense_coa_id = obj.expense_coa_id;
            this.item.sell_tax_id = obj.sell_tax_id;
            this.item.purchase_tax_id = obj.purchase_tax_id;
        },
        disabledDatePicker(date) {
            const lastDisableDate = new Date(
                moment(this.forbiddenDateLast).add(1, "days")
            );
            lastDisableDate.setHours(0, 0, 0, 0);
            return date < lastDisableDate;
        },
        submit(type) {
            this.CLEAR_ERRORS();
            this.loadingPage = 2;
            this.submitItem()
                .then(response => {
                    if (type == "withPush") {
                        this.$router.push({ name: "products-services" });
                    }
                    if (type == "withRefresh") {
                        this.CLEAR_FORM();
                        this.checkedSell = false;
                        this.checkedPurchase = false;
                        this.checkedInventory = false;
                    }
                    this.alert("Successfully create Item Data ", 1);
                    this.loadingPage = 0;
                })
                .catch(error => {
                    error.response.status != 422
                        ? this.alert(error.message, 2)
                        : "";
                    this.loadingPage = 0;
                });
        }
    },
    destroyed() {
        this.CLEAR_FORM();
    }
};
</script>
