import Vue from "vue";

import $axios from "../../api.js";

const state = () => ({
    forbiddenDateDatepicker: {
        to: Vue.moment("2010-01-01").format("YYYY-MM-DD"),
        dates: []
    },
    forbiddenDateLast: ""
});

const mutations = {
    CLEAR_DATA(state) {
        (state.forbiddenDateDatepicker = {
            to: Vue.moment("2010-01-01").format("YYYY-MM-DD"),
            dates: []
        }),
            (state.forbiddenDateLast = "");
    }
};

const actions = {
    getForbiddenDate({ commit, state }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .get(`/journal/get-forbidden-date`)
                .then(response => {
                    //ASSIGN FORBIDDEN DATE LAST
                    state.forbiddenDateLast = Vue.moment(
                        response.data.forbiddenDateLast
                    ).format("YYYY-MM-DD");
                    //RESOLVE
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    console.log(error);
                });
        });
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
