<template>
    <main>
        <hero
            title="Expenses"
            prevBreadcrumb="Dashboard"
            routeBreadcrumb="home"
            currentBreadcrumb="Expenses"
        ></hero>

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 1 || !datas"
                style="height:250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity:0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <!-- Dynamic Table Full -->
            <div class="block block-rounded block-mode-loading-oneui" v-else>
                <div class="block-content block-content-full pb-0 pt-3">
                    <div class="row">
                        <div
                            class="col-12 col-md-7 col-lg-7 col-xl-7 mb-2 mb-sm-0 pb-0 px-0 d-md-none d-inline"
                        >
                            <label class="col-12 col-md-1 py-0">Date :</label>
                            <date-picker
                                v-model="date_start"
                                type="date"
                                format="DD/MM/YYYY"
                                valueType="YYYY-MM-DD"
                                placeholder="Datepicker"
                                class="col-12"
                            ></date-picker>
                            <date-picker
                                v-model="date_end"
                                type="date"
                                format="DD/MM/YYYY"
                                valueType="YYYY-MM-DD"
                                placeholder="Datepicker"
                                class="col-12"
                            ></date-picker>
                            <button
                                type="button"
                                class="btn btn-sm btn-info float-right mb-5 mb-md-0"
                                tag="button"
                                v-ripple="{ center: true }"
                                style="margin-top: 2px;"
                                @click="getData()"
                                :disabled="
                                    loadingPage == 2 ||
                                        date_start == null ||
                                        date_end == null
                                "
                            >
                                <i class="fa fa-calendar"></i> Apply Date
                            </button>
                        </div>
                        <div
                            class="col-12 col-md-7 col-lg-7 col-xl-7 mb-2 mb-sm-0 pb-0 d-md-inline d-none"
                        >
                            <label>Date :</label>
                            <date-picker
                                v-model="date_start"
                                type="date"
                                format="DD/MM/YYYY"
                                valueType="YYYY-MM-DD"
                                placeholder="Datepicker"
                            ></date-picker>
                            <date-picker
                                v-model="date_end"
                                type="date"
                                format="DD/MM/YYYY"
                                valueType="YYYY-MM-DD"
                                placeholder="Datepicker"
                            ></date-picker>
                            <button
                                type="button"
                                class="btn btn-sm btn-info ml-1 mb-5 mb-md-1"
                                tag="button"
                                v-ripple="{ center: true }"
                                @click="getData()"
                                :disabled="
                                    loadingPage == 2 ||
                                        date_start == null ||
                                        date_end == null
                                "
                            >
                                <i class="fa fa-calendar mr-1"></i> Apply Date
                            </button>
                        </div>
                        <div
                            class="col-12 col-md-5 col-lg-5 col-xl-5 pb-0 pt-0 pt-md-3"
                        >
                            <input
                                type="text"
                                class="form-control form-control col-12 col-md-8 float-right search-index"
                                name="example-text-input"
                                placeholder="Search.."
                                v-model="search"
                            />
                        </div>
                    </div>
                </div>
                <div class="block-content block-content-full py-2">
                    <v-app>
                        <v-data-table
                            :headers="headers"
                            :items="datas"
                            :search="search"
                            :hide-default-footer="true"
                            :items-per-page="50"
                            :mobile-breakpoint="0"
                        >
                            <template
                                v-slot:top="{
                                    pagination,
                                    options,
                                    updateOptions
                                }"
                            >
                                <div class="row">
                                    <div
                                        class="col-12 col-md-7 mb-2 mb-sm-0 pt-0 pb-2"
                                    >
                                        <v-layout justify-start>
                                            <v-data-footer
                                                :pagination="pagination"
                                                :options="options"
                                                @update:options="updateOptions"
                                                :items-per-page-options="[
                                                    10,
                                                    30,
                                                    50,
                                                    100,
                                                    -1
                                                ]"
                                                items-per-page-text="Rows :"
                                            />
                                        </v-layout>
                                    </div>
                                    <div
                                        class="col-12 col-md-5 col-lg-5 col-xl-5 pb-0 pt-2"
                                    >
                                        <button
                                            type="button"
                                            class="btn btn-sm btn-alt-secondary float-right m-1 mb-5 mb-md-0"
                                            tag="button"
                                            v-ripple="{ center: true }"
                                            style="vertical-align: middle"
                                            @click="getData()"
                                            :disabled="loadingPage == 2"
                                        >
                                            <i class="fa fa-sync"></i>
                                        </button>
                                        <router-link
                                            type="button"
                                            class="btn btn-sm btn-info float-right btn-adjust-1"
                                            :to="{ name: 'expense-create' }"
                                            tag="button"
                                            v-ripple="{ center: true }"
                                        >
                                            <i class="fa fa-plus mr-1"></i> New
                                            Expense
                                        </router-link>
                                    </div>
                                </div>
                            </template>
                            <template v-slot:body="{ items }">
                                <tbody v-if="loadingPage == 2">
                                    <tr class="data-empty">
                                        <td colspan="7" class="py-2">
                                            <grid-loader
                                                class="mx-auto mt-5 pt-5"
                                                :color="'#70B9EB'"
                                                :size="'10px'"
                                            ></grid-loader>
                                            <center class="mt-2 mb-5 pb-5">
                                                <span
                                                    class="text-center"
                                                    style="opacity:0.7"
                                                    >Loading</span
                                                >
                                            </center>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody
                                    v-if="items.length > 0 && loadingPage == 0"
                                >
                                    <tr
                                        v-for="(expense, index) in items"
                                        :key="index"
                                    >
                                        <td
                                            @click="toDetail(expense.expenseId)"
                                            class="text-center font-size-sm"
                                        >
                                            {{ ++index }}
                                        </td>
                                        <td
                                            @click="toDetail(expense.expenseId)"
                                            class="font-size-sm"
                                            align="center"
                                        >
                                            {{ expense.expenseDate }}
                                        </td>
                                        <td
                                            @click="toDetail(expense.expenseId)"
                                            class="font-size-sm"
                                        >
                                            {{ expense.expenseNo }}
                                        </td>
                                        <td
                                            @click="toDetail(expense.expenseId)"
                                            class="font-size-sm"
                                            style="max-width:300px;"
                                        >
                                            <span
                                                v-html="expense.expenseContact"
                                            >
                                            </span>
                                        </td>
                                        <td
                                            @click="toDetail(expense.expenseId)"
                                            class="font-size-sm"
                                            align="right"
                                        >
                                            {{
                                                expense.expenseAmount | currency
                                            }}
                                        </td>
                                        <!-- DROPDOWN AURORA -->
                                        <td align="right">
                                            <div
                                                class="dropdown dropleft push m-0"
                                            >
                                                <button
                                                    type="button"
                                                    class="btn btn-sm btn-alt-secondary btn-transparent btn-rounded text--lighten-4"
                                                    id="dropdown-dropleft"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    <i
                                                        class="fa fa-fw fa-ellipsis-v"
                                                    ></i>
                                                </button>
                                                <div
                                                    class="dropdown-menu dropdown-menu-rounded font-size-sm px-1"
                                                    aria-labelledby="dropdown-dropleft"
                                                >
                                                    <button
                                                        @click="
                                                            toDetail(
                                                                expense.expenseId
                                                            )
                                                        "
                                                        class="btn btn-sm btn-transparent btn-alt-info dropdown-item-horizontal text-info"
                                                        v-ripple="{
                                                            center: true
                                                        }"
                                                        style="position:inline-block"
                                                    >
                                                        <i
                                                            class="si si-magnifier mr-1"
                                                        ></i>
                                                        <span
                                                            class="d-none d-md-inline"
                                                            >Detail</span
                                                        >
                                                    </button>
                                                    <button
                                                        @click="
                                                            toJournalVoucher(
                                                                expense.expenseJournalId
                                                            )
                                                        "
                                                        class="btn btn-sm btn-transparent btn-alt-warning dropdown-item-horizontal text-warning"
                                                        v-ripple="{
                                                            center: true
                                                        }"
                                                        style="position:inline-block"
                                                    >
                                                        <i
                                                            class="si si-notebook mr-1"
                                                        ></i>
                                                        <span
                                                            class="d-none d-md-inline"
                                                            >Journal</span
                                                        >
                                                    </button>
                                                    <button
                                                        @click="
                                                            toEdit(
                                                                expense.expenseId
                                                            )
                                                        "
                                                        class="btn btn-sm btn-transparent btn-alt-success dropdown-item-horizontal text-success"
                                                        v-ripple="{
                                                            center: true
                                                        }"
                                                        style="position:inline-block"
                                                    >
                                                        <i
                                                            class="si si-pencil mr-1"
                                                        ></i>
                                                        <span
                                                            class="d-none d-md-inline"
                                                            >Edit</span
                                                        >
                                                    </button>
                                                    <button
                                                        class="btn btn-sm btn-transparent btn-alt-danger text-danger dropdown-item-horizontal"
                                                        v-ripple="{
                                                            center: true
                                                        }"
                                                        @click.prevent="
                                                            deleteData(
                                                                expense.expenseId
                                                            )
                                                        "
                                                    >
                                                        <i
                                                            class="si si-trash mr-1"
                                                        ></i>
                                                        <span
                                                            class="d-none d-md-inline"
                                                            >Delete</span
                                                        >
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody
                                    v-if="items.length <= 0 && loadingPage == 0"
                                >
                                    <tr class="data-empty">
                                        <td
                                            class="text-center"
                                            colspan="7"
                                            style="opacity:0.8; padding:2.5rem;"
                                        >
                                            <center>
                                                <img
                                                    :src="
                                                        '/media/proxima/empty-data.svg'
                                                    "
                                                    style="height: 100px;"
                                                />
                                            </center>
                                            <br /><span style="opacity:0.6;"
                                                >Data Not Found</span
                                            >
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-data-table>
                    </v-app>
                </div>
                <div class="block-content block-content-full pt-0">
                    <div class="row">
                        <div class="col-3 offset-9 pt-0">
                            <label for="field-1" class="control-label">
                                Total Expense :
                            </label>
                            <money
                                class="form-control"
                                style="text-align: right;"
                                v-model="totalExpense"
                                spellcheck="false"
                                disabled
                            ></money>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";

export default {
    name: "expense-register",
    data() {
        return {
            search: "",
            awaitingSearch: false,
            rows: "All",
            loadingPage: 1,
            type: "All Expenses",
            amountExp: 0,
            totalExpense: 0,
            date_start: moment()
                .subtract(30, "days")
                .format("YYYY-MM-DD"),
            date_end: moment().format("YYYY-MM-DD"),
            headers: [
                { text: "No.", align: "center", sortable: false },
                { text: "Date", align: "center", value: "expenseDate" },
                { text: "Expense No.", align: "start", value: "expenseNo" },
                { text: "Contact Name", value: "expenseContact" },
                {
                    text: "Amount",
                    align: "right",
                    value: "expenseAmount",
                    sortable: false
                },
                { text: "", sortable: false }
            ],
            datas: []
        };
    },
    created() {
        if (
            this.$session.has("expense_datas") &&
            !this.$route.params.reloadData
        ) {
            this.loadingPage = 0;
            // this.datas = this.$session.get("expense_datas");
            this.date_start = this.$session.get("expense_date_start");
            this.date_end = this.$session.get("expense_date_end");
            this.search = this.$session.get("expense_search");
            this.getData();
            // if edit/delete data, data new get
        } else if (
            this.$session.has("expense_datas") &&
            this.$route.params.reloadData
        ) {
            this.date_start = this.$session.get("expense_date_start");
            this.date_end = this.$session.get("expense_date_end");
            this.search = this.$session.get("expense_search");
            this.getData();
            // clean loading 1st
        } else {
            this.getData();
        }
        this.$session.remove("expense_detail");
    },
    computed: {
        ...mapState("expense", { expenses: state => state.expenses }),
        ...mapState(["auth"])
    },
    methods: {
        ...mapActions("expense", [
            "getExpense",
            "getExpensePerProyek",
            "getExpenseAmount",
            "removeExpense"
        ]),
        getData() {
            this.loadingPage = 2;
            this.datas = [];
            this.totalExpense = 0;
            this.$session.set(
                "expense_date_start",
                moment(this.date_start).format("YYYY-MM-DD")
            );
            this.$session.set(
                "expense_date_end",
                moment(this.date_end).format("YYYY-MM-DD")
            );
            if (
                this.auth.role.includes("masterAccount") ||
                this.auth.role.includes("devAccount") ||
                this.auth.role.includes("accChief") ||
                this.auth.role.includes("accStaffJournal")
            ) {
                this.getExpense({
                    rows: this.rows,
                    type: this.type,
                    date_start: new Date(this.date_start).toDateString(),
                    date_end: new Date(this.date_end).toDateString()
                })
                    .then(() => {
                        this.expenses.data.forEach(element => {
                            let dataContact =
                                element.contact.contact_name ??
                                "" +
                                    '<br><small style="color:#c2c2c2!important;">' +
                                    element.contact.contact_address ??
                                "" + "</small>";
                            this.datas.push({
                                expenseId: element.id,
                                expenseDate: moment(element.bill_date).format(
                                    "DD/MM/YYYY"
                                ),
                                expenseNo: element.bill_number ?? "",
                                expenseContact: dataContact ?? "",
                                expenseAmount: element.bill_grand_total ?? "",
                                expenseJournalId: element.journal.id ?? ""
                            });
                            this.totalExpense =
                                this.totalExpense + element.bill_grand_total;
                        });
                        this.loadingPage = 0;
                        this.$session.set("expense_datas", this.datas);
                    })
                    .catch(error => {
                        error.response.status == 500
                            ? this.alert(error.response.data.message, 2)
                            : "";
                        error.response.status != 422 &&
                        error.response.status != 500
                            ? this.alert(error.message, 2)
                            : "";
                        this.loadingPage = 0;
                        this.totalExpense = 0;
                    });
            } else {
                this.getExpensePerProyek({
                    rows: this.rows,
                    type: this.type,
                    date_start: new Date(this.date_start).toDateString(),
                    date_end: new Date(this.date_end).toDateString()
                })
                    .then(() => {
                        this.expenses.data.forEach(element => {
                            let dataContact =
                                element.contact.contact_name +
                                '<br><small style="color:#c2c2c2!important;">' +
                                element.contact.contact_address +
                                "</small>";
                            this.datas.push({
                                expenseId: element.id,
                                expenseDate: moment(element.bill_date).format(
                                    "DD/MM/YYYY"
                                ),
                                expenseNo: element.bill_number,
                                expenseContact: dataContact,
                                expenseAmount: element.bill_grand_total,
                                expenseJournalId: element.journal.id
                            });
                            this.totalExpense =
                                this.totalExpense + element.bill_grand_total;
                        });
                        this.loadingPage = 0;
                        this.$session.set("expense_datas", this.datas);
                    })
                    .catch(error => {
                        error.response.status == 500
                            ? this.alert(error.response.data.message, 2)
                            : "";
                        error.response.status != 422 &&
                        error.response.status != 500
                            ? this.alert(error.message, 2)
                            : "";
                        this.loadingPage = 0;
                        this.totalExpense = 0;
                    });
            }
        },
        toDetail(val) {
            this.$router.push({ name: "expense-detail", params: { id: val } });
        },
        toEdit(val) {
            this.$router.push({ name: "expense-edit", params: { id: val } });
        },
        toJournalVoucher(val) {
            this.$router.push({
                name: "journal-transaction-voucher",
                params: { id: val }
            });
        },
        deleteData(val) {
            this.$swal({
                title: "Are you sure ?",
                text: "Deleted data cannot be recovery",
                icon: "warning",
                buttons: ["Cancel", "Delete"],
                dangerMode: true
            }).then(willDelete => {
                if (willDelete) {
                    this.loadingPage = 1;
                    this.removeExpense(val)
                        .then(() => {
                            this.alert("Data has been deleted !", 1);
                            this.getData();
                            this.loadingPage = 0;
                        })
                        .catch(error => {
                            error.response.status == 500
                                ? this.alert(error.response.data.message, 2)
                                : "";
                            error.response.status != 422 &&
                            error.response.status != 500
                                ? this.alert(error.message, 2)
                                : "";
                            this.loadingPage = 0;
                        });
                }
            });
        }
    }
};
</script>