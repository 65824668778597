import axios from "axios";
import store from "./store.js";

const $axios = axios.create({
    baseURL: "/api",
    headers: {
        "Content-Type": "application/json"
    }
});


// [#BASE002 --1]
$axios.interceptors.request.use(
    function(config) {
        const token = store.state.token;
        if (token) config.headers.Authorization = `Bearer ${token}`;
        return config;
    },
    function(error) {
        return Promise.reject(error);
    }
);
// [#BASE002 --1]

export default $axios;
