<template>
    <main>
        <hero title="Asset Category" prevBreadcrumb="Dashboard" routeBreadcrumb="home" currentBreadcrumb="Asset Category"></hero>

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 1 || !categories.data"
                style="height: 250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <!-- Dynamic Table Full -->
            <div class="block block-rounded block-mode-loading-oneui" v-else>
                <div class="block-content block-content-full pt-3">
                    <v-app>
                        <v-data-table
                            :headers="headers"
                            :items="datas"
                            :search="search"
                            :hide-default-footer="true"
                            :items-per-page="50"
                            :mobile-breakpoint="0"
                        >
                            <template
                                v-slot:top="{
                                    pagination,
                                    options,
                                    updateOptions,
                                }"
                            >
                                <div class="row">
                                    <div
                                        class="col-12 col-md-5 mb-2 mb-sm-0 pt-0 pb-2"
                                    >
                                        <v-layout justify-start>
                                            <v-data-footer
                                                :pagination="pagination"
                                                :options="options"
                                                @update:options="updateOptions"
                                                :items-per-page-options="[
                                                    10,
                                                    30,
                                                    50,
                                                    100,
                                                    -1,
                                                ]"
                                                items-per-page-text="Rows :"
                                            />
                                        </v-layout>
                                    </div>
                                    <div
                                        class="col-12 col-md-7 col-lg-7 col-xl-7 pb-0 pt-2"
                                    >
                                        <input
                                            type="text"
                                            class="form-control form-control-sm col-12 col-md-6 float-right ml-1 mt-1 search-index"
                                            name="example-text-input"
                                            placeholder="Search.."
                                            v-model="search"
                                        />
                                        <router-link
                                            type="button"
                                            class="btn btn-sm btn-info float-right btn-adjust-1"
                                            :to="{
                                                name: 'asset-category-create',
                                            }"
                                            tag="button"
                                            v-ripple="{ center: true }"
                                        >
                                            <i class="fa fa-plus mr-1"></i> New
                                            Category
                                        </router-link>
                                        <!-- <router-link
                                            type="button"
                                            class="btn btn-sm btn-success float-right btn-adjust-1"
                                            :to="{
                                                name: 'asset-category-depreciation',
                                            }"
                                            tag="button"
                                            v-ripple="{ center: true }"
                                        >
                                            <i class="fa fa-list-alt mr-1"></i> Depreciation
                                        </router-link> -->
                                    </div>
                                </div>
                            </template>
                            <template v-slot:body="{ items }">
                                <tbody v-if="loadingPage == 2">
                                    <tr class="data-empty">
                                        <td colspan="5" class="py-2">
                                            <grid-loader
                                                class="mx-auto mt-5 pt-5"
                                                :color="'#70B9EB'"
                                                :size="'10px'"
                                            ></grid-loader>
                                            <center class="mt-2 mb-5 pb-5">
                                                <span
                                                    class="text-center"
                                                    style="opacity: 0.7"
                                                    >Loading</span
                                                >
                                            </center>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody
                                    v-if="items.length > 0 && loadingPage == 0"
                                >
                                    <tr
                                        v-for="(category, index) in items"
                                        :key="index"
                                    >
                                        <td
                                            @click="toEdit(category.categoryId)"
                                            class="text-center font-size-sm"
                                        >
                                            {{ ++index }}
                                        </td>
                                        <td
                                            @click="toEdit(category.categoryId)"
                                            class="font-size-sm"
                                        >
                                            {{ category.categoryName }}
                                        </td>
                                        <td
                                            @click="toEdit(category.categoryId)"
                                            class="font-size-sm"
                                        >
                                            {{ category.categoryMethod }}
                                        </td>
                                        <td
                                            @click="toEdit(category.categoryId)"
                                            class="text-center font-size-sm"
                                        >
                                            {{ category.categoryPeriod }}
                                        </td>
                                        <td
                                            @click="toEdit(category.categoryId)"
                                            class="text-center font-size-sm"
                                        >
                                            {{ category.categoryYear }}
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody
                                    v-if="items.length <= 0 && loadingPage == 0"
                                >
                                    <tr class="data-empty">
                                        <td
                                            class="text-center"
                                            colspan="5"
                                            style="
                                                opacity: 0.8;
                                                padding: 2.5rem;
                                            "
                                        >
                                            <center>
                                                <img
                                                    :src="'/media/proxima/empty-data.svg'"
                                                    style="height: 100px"
                                                />
                                            </center>
                                            <br /><span style="opacity: 0.6"
                                                >Data Not Found</span
                                            >
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-data-table>
                    </v-app>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";


export default {
    name: "asset-category",
    data() {
        return {
            search: "",
            awaitingSearch: false,
            rows: "All",
            loadingPage: 1,
            type: "All Categorys",
            amountExp: 0,
            totalCategory: 0,
            headers: [
                { text: "No.", align: "center", sortable: false },
                {
                    text: "Category Name",
                    align: "start",
                    value: "categoryName",
                },
                {
                    text: "Depreciate Method",
                    align: "start",
                    value: "categoryMethod",
                },
                {
                    text: "Useful in Period",
                    align: "center",
                    value: "categoryPeriod",
                },
                {
                    text: "Useful in Year",
                    align: "center",
                    value: "categoryYear",
                },
            ],
            datas: [],
        };
    },
    created() {
        this.getData();
        // this.getCategoryAmount().then(response => {
        //     this.amountExp = response;
        // });
        this.$session.remove("category_detail");
    },
    computed: {
        ...mapState("assetcategory", {
            categories: (state) => state.categories,
        }),
        ...mapState(["auth"]),
    },
    methods: {
        ...mapActions("assetcategory", ["getCategory", "removeCategory"]),
        getData() {
            this.loadingPage = 2;
            this.datas = [];
            this.getCategory({ rows: this.rows, type: this.type })
                .then(() => {
                    this.categories.data.forEach((element) => {
                        let method = "";
                        if (element.depreciate_method == "none") {
                            method = "No. Depreciation";
                        }
                        if (element.depreciate_method == "straight") {
                            method = "Straight-Line Method";
                        }
                        this.datas.push({
                            categoryId: element.id,
                            categoryName: element.category_name,
                            categoryMethod: method,
                            categoryPeriod: element.month_life,
                            categoryYear: element.year_life,
                        });
                    });
                    this.loadingPage = 0;
                })
                .catch((error) => {
                    error.response.status == 500
                        ? this.alert(error.response.data.message, 2)
                        : "";
                    error.response.status != 422 && error.response.status != 500
                        ? this.alert(error.message, 2)
                        : "";
                    this.loadingPage = 0;
                    this.totalCategory = 0;
                });
        },
        toEdit(val) {
            this.$router.push({
                name: "asset-category-edit",
                params: { id: val },
            });
        },
        toJournalVoucher(val) {
            this.$router.push({
                name: "journal-transaction-voucher",
                params: { id: val },
            });
        },
        deleteData(val) {
            this.$swal({
                title: "Are you sure ?",
                text: "Deleted data cannot be recovery",
                icon: "warning",
                buttons: ["Cancel", "Delete"],
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    this.loadingPage = 1;
                    this.removeCategory(val)
                        .then(() => {
                            this.alert("Data has been deleted !", 1);
                            this.getData();
                            this.loadingPage = 0;
                        })
                        .catch((error) => {
                            error.response.status == 500
                                ? this.alert(error.response.data.message, 2)
                                : "";
                            error.response.status != 422 &&
                            error.response.status != 500
                                ? this.alert(error.message, 2)
                                : "";
                            this.loadingPage = 0;
                        });
                }
            });
        },
    },
};
</script>
