var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[(this.bill.bill_transaction_from_order.length > 0)?_c('div',{staticClass:"row px-2"},[_vm._m(0),_vm._v(" "),_vm._m(1),_vm._v(" "),_vm._m(2)]):_vm._e(),_vm._v(" "),_vm._l((_vm.bill.bill_transaction_from_order),function(transaction,index){return _c('div',{key:"tr_order_" + index,staticClass:"row"},[_c('div',{staticClass:"col-sm-4 py-1"},[_c('div',{staticClass:"row",class:{
                    'd-none': transaction.transaction_format == 2
                }},[_c('div',{staticClass:"col-9 pr-0 py-1"},[_c('selectize',{staticClass:"form-control mt-1",class:{
                            'is-invalid-selectize':
                                _vm.errors[
                                    'bill_transaction_from_order.' +
                                        index +
                                        '.item_id'
                                ]
                        },attrs:{"placeholder":'Item',"id":'transaction-item-' + index,"disabled":""},model:{value:(transaction.item_id),callback:function ($$v) {_vm.$set(transaction, "item_id", $$v)},expression:"transaction.item_id"}},_vm._l((_vm.items),function(item){return _c('option',{key:item.id,domProps:{"value":item.id}},[_vm._v("\n                            "+_vm._s(item.item_name))])}),0),_vm._v(" "),(
                            _vm.errors[
                                'bill_transaction_from_order.' +
                                    index +
                                    '.item_id'
                            ]
                        )?_c('span',{staticClass:"text-danger data-detail-error"},[_vm._v("\n                        "+_vm._s(_vm.errors[
                                "bill_transaction_from_order." +
                                    index +
                                    ".item_id"
                            ][0])+"\n                    ")]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"col-3 pr-0 py-1"},[(
                            transaction.transaction_format == 0 ||
                                transaction.transaction_format == 1
                        )?_c('selectize',{staticClass:"form-control mt-1",class:{
                            'is-invalid-selectize':
                                _vm.errors[
                                    'bill_transaction_from_order.' +
                                        index +
                                        '.uom_id'
                                ]
                        },attrs:{"placeholder":'Unit',"id":'transaction-uom-' + index,"disabled":""},model:{value:(transaction.uom_id),callback:function ($$v) {_vm.$set(transaction, "uom_id", $$v)},expression:"transaction.uom_id"}},_vm._l((transaction.uom_list),function(uom){return _c('option',{key:uom.id,domProps:{"value":uom.id}},[_vm._v("\n                            "+_vm._s(uom.uom_name))])}),0):_vm._e(),_vm._v(" "),(
                            _vm.errors[
                                'bill_transaction_from_order.' +
                                    index +
                                    '.uom_id'
                            ]
                        )?_c('span',{staticClass:"text-danger data-detail-error"},[_vm._v("\n                        "+_vm._s(_vm.errors[
                                "bill_transaction_from_order." +
                                    index +
                                    ".uom_id"
                            ][0])+"\n                    ")]):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"row",class:{
                    'd-none': transaction.transaction_format == 0
                }},[_c('div',{staticClass:"col-6 pr-0 py-1"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(transaction.transaction_description),expression:"transaction.transaction_description"}],staticClass:"form-control my-1",class:{
                            'is-invalid':
                                _vm.errors[
                                    'bill_transaction_from_order.' +
                                        index +
                                        '.transaction_description'
                                ]
                        },attrs:{"type":"text","placeholder":'Description',"id":'transaction-desc-' + index,"disabled":""},domProps:{"value":(transaction.transaction_description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(transaction, "transaction_description", $event.target.value)}}}),_vm._v(" "),(
                            _vm.errors[
                                'bill_transaction_from_order.' +
                                    index +
                                    '.transaction_description'
                            ]
                        )?_c('span',{staticClass:"text-danger data-detail-error"},[_vm._v("\n                        "+_vm._s(_vm.errors[
                                "bill_transaction_from_order." +
                                    index +
                                    ".transaction_description"
                            ][0])+"\n                    ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"col-6 pr-0 py-1"},[(
                            transaction.transaction_format == 1 ||
                                transaction.transaction_format == 2
                        )?_c('selectize',{staticClass:"form-control mt-1",class:{
                            'is-invalid-selectize':
                                _vm.errors[
                                    'bill_transaction_from_order.' +
                                        index +
                                        '.transaction_coa_id'
                                ]
                        },attrs:{"placeholder":'Account No.',"id":'transaction-coa-' + index,"disabled":""},model:{value:(transaction.transaction_coa_id),callback:function ($$v) {_vm.$set(transaction, "transaction_coa_id", $$v)},expression:"transaction.transaction_coa_id"}},_vm._l((_vm.transactionAccounts),function(account){return _c('option',{key:account.id,domProps:{"value":account.id}},[_vm._v(_vm._s(account.account_no)+"\n                            "+_vm._s(account.account_name))])}),0):_vm._e(),_vm._v(" "),(
                            _vm.errors[
                                'bill_transaction_from_order.' +
                                    index +
                                    '.transaction_coa_id'
                            ]
                        )?_c('span',{staticClass:"text-danger data-detail-error"},[_vm._v("\n                        "+_vm._s(_vm.errors[
                                "bill_transaction_from_order." +
                                    index +
                                    ".transaction_coa_id"
                            ][0])+"\n                    ")]):_vm._e()],1)])]),_vm._v(" "),_c('div',{staticClass:"col-sm-2 py-1"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 pr-0 py-1 text-center"},[_c('numeric',{staticClass:"form-control mt-1 mb-2",attrs:{"placeholder":"Qty","spellcheck":"false","disabled":"","separator":".","precision":2},model:{value:(transaction.transaction_qty),callback:function ($$v) {_vm.$set(transaction, "transaction_qty", $$v)},expression:"transaction.transaction_qty"}}),_vm._v(" "),_c('span',{staticClass:"text-muted-high"},[_vm._v("\n                        Sold : "+_vm._s(transaction.transaction_done_qty)+" /\n                        "+_vm._s(transaction.transaction_qty)+"\n                    ")])],1),_vm._v(" "),_c('div',{staticClass:"col-6 pr-0 py-1"},[_c('numeric',{staticClass:"form-control mt-1 mb-2",attrs:{"placeholder":"Received Qty","spellcheck":"false","separator":".","precision":2,"max":transaction.transaction_qty -
                                transaction.transaction_done_qty,"disabled":transaction.transaction_qty -
                                transaction.transaction_done_qty ==
                                0},nativeOn:{"blur":function($event){return _vm.calculateTransaction(index)}},model:{value:(transaction.transaction_inv_qty),callback:function ($$v) {_vm.$set(transaction, "transaction_inv_qty", $$v)},expression:"transaction.transaction_inv_qty"}}),_vm._v(" "),(
                            transaction.transaction_done_qty ==
                                transaction.transaction_qty
                        )?_c('span',{staticClass:"text-muted-high"},[_vm._v("\n                        Already Sold\n                    ")]):_vm._e(),_vm._v(" "),(
                            _vm.errors[
                                'bill_transaction_from_order.' +
                                    index +
                                    '.transaction_inv_qty'
                            ]
                        )?_c('span',{staticClass:"text-danger data-detail-error"},[_vm._v("\n                        "+_vm._s(_vm.errors[
                                "bill_transaction_from_order." +
                                    index +
                                    ".transaction_inv_qty"
                            ][0])+"\n                    ")]):_vm._e()],1)])]),_vm._v(" "),_c('div',{staticClass:"col-sm-6 py-1"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4 pr-0 py-1"},[_c('money',{staticClass:"form-control mt-1 mb-2 text-right",class:{
                            'is-invalid':
                                _vm.errors[
                                    'bill_transaction_from_order.' +
                                        index +
                                        '.transaction_price'
                                ]
                        },attrs:{"type":"text","placeholder":"Unit Price","disabled":""},model:{value:(transaction.transaction_price),callback:function ($$v) {_vm.$set(transaction, "transaction_price", $$v)},expression:"transaction.transaction_price"}}),_vm._v(" "),(
                            _vm.errors[
                                'bill_transaction_from_order.' +
                                    index +
                                    '.transaction_price'
                            ]
                        )?_c('span',{staticClass:"text-danger data-detail-error"},[_vm._v("\n                        "+_vm._s(_vm.errors[
                                "bill_transaction_from_order." +
                                    index +
                                    ".transaction_price"
                            ][0])+"\n                    ")]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"col-3 pr-0 py-1"},[_c('selectize',{staticClass:"form-control mt-1",class:{
                            'is-invalid-selectize':
                                _vm.errors[
                                    'bill_transaction_from_order.' +
                                        index +
                                        '.transaction_tax_id'
                                ]
                        },attrs:{"placeholder":"Tax","disabled":""},on:{"input":function($event){return _vm.getTaxDetail(
                                index,
                                transaction.transaction_tax_id
                            )}},model:{value:(transaction.transaction_tax_id),callback:function ($$v) {_vm.$set(transaction, "transaction_tax_id", $$v)},expression:"transaction.transaction_tax_id"}},_vm._l((_vm.taxs),function(tax,indeks){return _c('option',{key:indeks,domProps:{"value":tax.id}},[_vm._v("\n                            "+_vm._s(tax.tax_code)+"\n                            ("+_vm._s(tax.tax_rate)+"%)\n                        ")])}),0),_vm._v(" "),(
                            _vm.errors[
                                'bill_transaction_from_order.' +
                                    index +
                                    '.transaction_tax_id'
                            ]
                        )?_c('span',{staticClass:"text-danger data-detail-error"},[_vm._v("\n                        "+_vm._s(_vm.errors[
                                "bill_transaction_from_order." +
                                    index +
                                    ".transaction_tax_id"
                            ][0])+"\n                    ")]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"col-4 pr-0 py-1"},[_c('money',{staticClass:"form-control mt-1 mb-2 text-right",class:{
                            'is-invalid':
                                _vm.errors[
                                    'bill_transaction_from_order.' +
                                        index +
                                        '.transaction_amount'
                                ]
                        },attrs:{"type":"text","placeholder":"Amount","disabled":""},model:{value:(transaction.transaction_amount),callback:function ($$v) {_vm.$set(transaction, "transaction_amount", $$v)},expression:"transaction.transaction_amount"}}),_vm._v(" "),_c('span',{staticClass:"text-muted-high"},[_vm._v("\n                        Ord. Amnt. :\n                        "+_vm._s(_vm._f("currency")(transaction.transaction_amount_base))+"\n                    ")]),_vm._v(" "),(
                            _vm.errors[
                                'bill_transaction_from_order.' +
                                    index +
                                    '.transaction_amount'
                            ]
                        )?_c('span',{staticClass:"text-danger"},[_c('br'),_vm._v("\n                        "+_vm._s(_vm.errors[
                                "bill_transaction_from_order." +
                                    index +
                                    ".transaction_amount"
                            ][0])+"\n                    ")]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"col-1 pr-0 py-1"})])])])})],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-sm-4 py-2 mb-1 bg-whitesmoke"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-11 py-1 text-left font-weight-bold"},[_vm._v("\n                    ITEM\n                ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-sm-2 py-2 mb-1 bg-whitesmoke"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 pr-0 py-1 text-left font-weight-bold"},[_vm._v("\n                    QTY\n                ")]),_vm._v(" "),_c('div',{staticClass:"col-6 pr-0 py-1 text-left font-weight-bold"},[_vm._v("\n                    SOLD\n                ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-sm-6 py-2 mb-1 bg-whitesmoke"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4 pr-0 py-1 text-left font-weight-bold"},[_vm._v("\n                    UNIT PRICE\n                ")]),_vm._v(" "),_c('div',{staticClass:"col-3 pr-0 py-1 text-left font-weight-bold"},[_vm._v("\n                    TAX RATE\n                ")]),_vm._v(" "),_c('div',{staticClass:"col-4 pr-0 py-1 text-left font-weight-bold"},[_vm._v("\n                    AMOUNT\n                ")]),_vm._v(" "),_c('div',{staticClass:"col-1 pr-0 py-1 text-left font-weight-bold"})])])}]

export { render, staticRenderFns }