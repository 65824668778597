<template>
    <main>
        <!-- Page Content -->
        <div class="content py-0">
            <!--Loading-->
            <div class="block" v-if="loadingPage == 1" style="height: 250px">
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <div class="block" v-if="loadingPage == 2" style="height: 250px">
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Save Data</span
                        >
                    </center>
                </div>
            </div>
            <!-- Basic -->
            <div class="block" v-if="loadingPage == 0">
                <div class="block-content block-content-full">
                    <form
                        method="POST"
                        enctype="multipart/form-data"
                        onsubmit="return false;"
                    >
                        <!--Form-->
                        <div class="row push font-size-sm mb-0">
                            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Select Workspace</label
                                    >
                                    <selectize
                                        class="form-control"
                                        placeholder="Choose Workspace"
                                        v-model="assignData.workspace_token"
                                        :class="{
                                            'is-invalid-selectize':
                                                errors.workspace_id,
                                        }"
                                        @input="selectWorkspace()"
                                    >
                                        <!-- settings is optional -->
                                        <option
                                            v-for="workspace in workspacesAssign"
                                            :value="workspace.workspace_token"
                                            :key="workspace.workspace_token"
                                        >
                                            {{ workspace.workspace_name }}
                                        </option>
                                    </selectize>
                                    <p
                                        class="text-danger"
                                        v-if="errors.workspace_id"
                                    >
                                        {{ errors.workspace_id[0] }}
                                    </p>
                                </div>
                                <div
                                    class="form-group"
                                    v-if="assignData.workspace_token"
                                >
                                    <label for="example-text-input"
                                        >Select User</label
                                    >
                                    <selectize
                                        class="form-control"
                                        placeholder="Choose User"
                                        v-model="assignData.user_username"
                                        :class="{
                                            'is-invalid-selectize':
                                                errors.workspace_id,
                                        }"
                                    >
                                        <!-- settings is optional -->
                                        <option
                                            v-for="user in users"
                                            :value="user.user.username"
                                            :key="user.user.username"
                                        >
                                            {{ user.user.name }} ({{
                                                user.user.username
                                            }})
                                        </option>
                                    </selectize>
                                    <p
                                        class="text-danger"
                                        v-if="errors.username"
                                    >
                                        {{ errors.username[0] }}
                                    </p>
                                </div>
                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Attention</label
                                    >
                                    <ol class="ml-3">
                                        <li>
                                            You must select the workspace first
                                            before selecting the user to add
                                        </li>
                                        <li>
                                            You can only add user which already
                                            registered in the selected workspace
                                        </li>
                                        <li>
                                            Make sure the access privileges are
                                            given correctly
                                        </li>
                                        <li>
                                            Adding a user to a new workspace
                                            does not change the user's login
                                            data
                                        </li>
                                    </ol>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                                <!--OTHER-->
                                <div class="form-group font-size-sm">
                                    <label class="d-block"
                                        >System Administrator</label
                                    >
                                    <div
                                        class="form-check form-check-inline mb-1"
                                    >
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            v-model="assignData.administrator"
                                        />
                                        <label class="form-check-label"
                                            >Administrator</label
                                        >
                                    </div>
                                </div>
                                <!--ACCOUNTING-->
                                <div class="form-group font-size-sm">
                                    <label class="d-block">Accounting</label>
                                    <div
                                        class="form-check form-check-inline mb-1"
                                    >
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            v-model="assignData.accChief"
                                        />
                                        <label class="form-check-label"
                                            >Chief Accounting</label
                                        >
                                    </div>
                                    <div
                                        class="form-check form-check-inline mb-1"
                                    >
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            v-model="assignData.accStaffAccount"
                                            :disabled="assignData.accChief"
                                        />
                                        <label class="form-check-label"
                                            >Staff: Account</label
                                        >
                                    </div>
                                    <div
                                        class="form-check form-check-inline mb-1"
                                    >
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            v-model="assignData.accStaffSetup"
                                            :disabled="assignData.accChief"
                                        />
                                        <label class="form-check-label"
                                            >Staff: Setup Data</label
                                        >
                                    </div>
                                    <div
                                        class="form-check form-check-inline mb-1"
                                    >
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            v-model="assignData.accStaffAsset"
                                            :disabled="assignData.accChief"
                                        />
                                        <label class="form-check-label"
                                            >Staff: Asset Data</label
                                        >
                                    </div>
                                    <div
                                        class="form-check form-check-inline mb-1"
                                    >
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            v-model="assignData.accStaffJournal"
                                            :disabled="assignData.accChief"
                                        />
                                        <label class="form-check-label"
                                            >Staff: Journal</label
                                        >
                                    </div>
                                    <div
                                        class="form-check form-check-inline mb-1"
                                    >
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            v-model="assignData.accStaffBill"
                                            :disabled="assignData.accChief"
                                        />
                                        <label class="form-check-label"
                                            >Staff: Bills</label
                                        >
                                    </div>
                                    <div
                                        class="form-check form-check-inline mb-1"
                                    >
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            v-model="
                                                assignData.accStaffPurchase
                                            "
                                            :disabled="assignData.accChief"
                                        />
                                        <label class="form-check-label"
                                            >Staff: Purchase</label
                                        >
                                    </div>
                                    <div
                                        class="form-check form-check-inline mb-1"
                                    >
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            v-model="
                                                assignData.accStaffPettyCash
                                            "
                                            :disabled="assignData.accChief"
                                        />
                                        <label class="form-check-label"
                                            >Staff: Petty Cash</label
                                        >
                                    </div>
                                    <div
                                        class="form-check form-check-inline mb-1"
                                    >
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            v-model="assignData.accStaffBanking"
                                            :disabled="assignData.accChief"
                                        />
                                        <label class="form-check-label"
                                            >Staff: Banking</label
                                        >
                                    </div>
                                    <div
                                        class="form-check form-check-inline mb-1"
                                    >
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            v-model="
                                                assignData.accStaffReportGL
                                            "
                                            :disabled="
                                                assignData.accChief ||
                                                assignData.accStaffReportGLBank
                                            "
                                        />
                                        <label class="form-check-label"
                                            >Staff: Report General Ledger</label
                                        >
                                    </div>
                                    <div
                                        class="form-check form-check-inline mb-1"
                                    >
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            v-model="
                                                assignData.accStaffReportGLBank
                                            "
                                            :disabled="
                                                assignData.accChief ||
                                                assignData.accStaffReportGL
                                            "
                                        />
                                        <label class="form-check-label"
                                            >Staff: Report General Ledger
                                            (Bank)</label
                                        >
                                    </div>
                                    <div
                                        class="form-check form-check-inline mb-1"
                                    >
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            v-model="
                                                assignData.accStaffReportTB
                                            "
                                            :disabled="assignData.accChief"
                                        />
                                        <label class="form-check-label"
                                            >Staff: Report Trial Balance</label
                                        >
                                    </div>
                                    <div
                                        class="form-check form-check-inline mb-1"
                                    >
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            v-model="
                                                assignData.accStaffReportBS
                                            "
                                            :disabled="assignData.accChief"
                                        />
                                        <label class="form-check-label"
                                            >Staff: Report Balance Sheet</label
                                        >
                                    </div>
                                    <div
                                        class="form-check form-check-inline mb-1"
                                    >
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            v-model="
                                                assignData.accStaffReportPL
                                            "
                                            :disabled="assignData.accChief"
                                        />
                                        <label class="form-check-label"
                                            >Staff: Report Profit & Loss</label
                                        >
                                    </div>
                                    <div
                                        class="form-check form-check-inline mb-1"
                                    >
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            v-model="assignData.accStakeholder"
                                            :disabled="assignData.accChief"
                                        />
                                        <label class="form-check-label"
                                            >Stakeholder</label
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--END Form-->
                        <!-- Submit -->
                        <div class="row items-push">
                            <div class="col-12 text-center mb-0">
                                <p class="font-size-sm text-muted">
                                    * Require to be filled, Make sure all data is
                                    correctly filled
                                </p>
                                <button
                                    type="button"
                                    class="btn btn-sm btn-success"
                                    v-ripple="{ center: true }"
                                    @click="assignUser()"
                                    :disabled="
                                        !assignData.workspace_token ||
                                        !assignData.user_username ||
                                        assignData.workspace_token == '' ||
                                        assignData.user_username == '' ||
                                        assignData.role == []
                                    "
                                >
                                    <i class="fa fa-fw fa-plus mr-1"></i> Add
                                    User
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-sm btn-alt-light"
                                    @click="closeModal()"
                                    v-ripple="{ center: true }"
                                >
                                    <i class="fa fa-fw fa-times mr-1"></i> Close
                                </button>
                            </div>
                        </div>
                        <!-- END Submit -->
                    </form>
                </div>
            </div>
            <!-- END Basic -->
        </div>
        <!-- END Page Content -->
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

export default {
    name: "assign-workspace",

    data() {
        return {
            loadingPage: 0,
            users: [],
            assignData: {
                role: [],
            },
            workspacesAssign: [],
        };
    },
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState(["auth"]),
        ...mapState("workspace", { workspaces: (state) => state.workspaces }),
    },
    mounted() {
        this.filterWorkspaces();
    },
    methods: {
        //VUEX
        ...mapMutations("user", ["CLEAR_FORM"]),
        ...mapActions("workspace", [
            "getWorkspaces",
            "getWorkspace",
            "assignUserWorkspace",
        ]),

        closeModal() {
            this.$modal.hide("assign-workspace");
        },
        filterWorkspaces() {
            var result = this.workspaces.filter((obj) => {
                return obj.workspace_token !== this.auth.data.workspace_token;
            });
            this.workspacesAssign = result;
        },
        selectWorkspace() {
            this.getWorkspace(this.assignData.workspace_token)
                .then((response) => {
                    this.users = response.detail;
                    this.loadingPage = 0;
                })
                .catch((error) => {
                    this.loadingPage = 0;
                });
        },
        // selectUser(){
        //     var result = this.users.filter(obj => {
        //         return obj.user.username == this.assignData.username
        //     })
        //     this.assignData.user = result;
        // },
        assignUser() {
            this.loadingPage = 2;
            this.assignData.administrator
                ? this.assignData.role.push("administrator")
                : "";
            this.assignData.accChief
                ? this.assignData.role.push("accChief")
                : "";
            this.assignData.accStakeholder
                ? this.assignData.role.push("accStakeholder")
                : "";
            this.assignData.accStaffAccount
                ? this.assignData.role.push("accStaffAccount")
                : "";
            this.assignData.accStaffSetup
                ? this.assignData.role.push("accStaffSetup")
                : "";
            this.assignData.accStaffAsset
                ? this.assignData.role.push("accStaffAsset")
                : "";
            this.assignData.accStaffBill
                ? this.assignData.role.push("accStaffBill")
                : "";
            this.assignData.accStaffPurchase
                ? this.assignData.role.push("accStaffPurchase")
                : "";
            this.assignData.accStaffJournal
                ? this.assignData.role.push("accStaffJournal")
                : "";
            this.assignData.accStaffPettyCash
                ? this.assignData.role.push("accStaffPettyCash")
                : "";
            this.assignData.accStaffBanking
                ? this.assignData.role.push("accStaffBanking")
                : "";
            this.assignData.accStaffReportGL
                ? this.assignData.role.push("accStaffReportGL")
                : "";
            this.assignData.accStaffReportGLBank
                ? this.assignData.role.push("accStaffReportGLBank")
                : "";
            this.assignData.accStaffReportTB
                ? this.assignData.role.push("accStaffReportTB")
                : "";
            this.assignData.accStaffReportBS
                ? this.assignData.role.push("accStaffReportBS")
                : "";
            this.assignData.accStaffReportPL
                ? this.assignData.role.push("accStaffReportPL")
                : "";
            this.assignData.accStakeholder
                ? this.assignData.role.push("accStakeholder")
                : "";
            this.assignUserWorkspace(this.assignData)
                .then((response) => {
                    this.$modal.hide("assign-workspace");
                    this.alert("Successfully add User to Workspace ", 1);
                    this.$root.$emit("newUser", true);
                    this.loadingPage = 0;
                })
                .catch((error) => {
                    error.response.status != 422
                        ? this.alert(error.message, 2)
                        : "";
                    this.loadingPage = 0;
                });
        },
    },
    destroyed() {
        //FORM DI BERSIHKAN
        this.CLEAR_FORM();
    },
};
</script>
