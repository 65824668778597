<template>
    <main>
        <!-- Page Content -->
        <div class="content p-0">
            <!--Loading-->
            <div class="block" v-if="loadingPage == 1" style="height:200px">
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity:0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <div class="block" v-if="loadingPage == 2" style="height:200px">
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <div
                        class="spinner-border spinner-border-lg text-info mx-auto"
                        role="status"
                        style="display: flex;
                        justify-content: center;"
                    ></div>
                    <center class="mt-2">
                        <span class="text-center" style="opacity:0.7"
                            >Save Data</span
                        >
                    </center>
                </div>
            </div>
            <!-- Basic -->
            <div class="block col-12 mb-0" v-if="loadingPage == 0">
                <div class="block-content block-content-full px-0">
                    <form
                        method="POST"
                        enctype="multipart/form-data"
                        onsubmit="return false;"
                    >
                        <!--Form-->
                        <div class="row font-size-sm">
                            <div class="col-12 col-md-10 offset-md-1">
                                <div class="form-group">
                                    <label class="d-block">Account Role</label>
                                    <div class="form-check form-check-inline">
                                        <input
                                            class="form-check-input"
                                            type="radio"
                                            v-model="coa.account_role"
                                            id="header"
                                            @change="setType()"
                                            value="Header"
                                            checked
                                        />
                                        <label
                                            class="form-check-label"
                                            for="header"
                                            >Header Account</label
                                        >
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input
                                            class="form-check-input"
                                            type="radio"
                                            v-model="coa.account_role"
                                            id="detail"
                                            @change="setType()"
                                            value="Detail"
                                        />
                                        <label
                                            class="form-check-label"
                                            for="detail"
                                            >Detail Account</label
                                        >
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Account Type</label
                                    >
                                    <selectize
                                        class="form-control"
                                        v-if="coa.account_role == 'Header'"
                                        placeholder="Account Type"
                                        v-model="coa.account_type"
                                        @input="setMask()"
                                    >
                                        <!-- settings is optional -->
                                        <option value="Asset">Asset</option>
                                        <option value="Liability"
                                            >Liability</option
                                        >
                                        <option value="Equity">Equity</option>
                                        <option value="Income">Income</option>
                                        <option value="Cost of Sales"
                                            >Cost of Sales</option
                                        >
                                        <option value="Expense">Expense</option>
                                        <option value="Other Income"
                                            >Other Income</option
                                        >
                                        <option value="Other Expense"
                                            >Other Expense</option
                                        >
                                    </selectize>
                                    <selectize
                                        class="form-control"
                                        v-else
                                        placeholder="Account Type"
                                        v-model="coa.account_type"
                                        @input="setMask()"
                                    >
                                        <!-- settings is optional -->
                                        <option value="Bank">Bank</option>
                                        <option value="Account Receivable"
                                            >Account Receivable</option
                                        >
                                        <option value="Other Current Asset"
                                            >Other Current Asset</option
                                        >
                                        <option value="Fixed Asset"
                                            >Fixed Asset</option
                                        >
                                        <option value="Other Asset"
                                            >Other Asset</option
                                        >
                                        <option value="Credit Card"
                                            >Credit Card</option
                                        >
                                        <option value="Account Payable"
                                            >Account Payable</option
                                        >
                                        <option value="Other Current Liability"
                                            >Other Current Liability</option
                                        >
                                        <option value="Long Term Liability"
                                            >Long Term Liability</option
                                        >
                                        <option value="Other Liability"
                                            >Other Liability</option
                                        >
                                        <option value="Equity">Equity</option>
                                        <option value="Income">Income</option>
                                        <option value="Cost of Sales"
                                            >Cost of Sales</option
                                        >
                                        <option value="Expense">Expense</option>
                                        <option value="Other Income"
                                            >Other Income</option
                                        >
                                        <option value="Other Expense"
                                            >Other Expense</option
                                        >
                                    </selectize>
                                    <transition name="slide-fade">
                                        <p
                                            class="text-danger"
                                            v-if="errors.account_type"
                                        >
                                            {{ errors.account_type[0] }}
                                        </p>
                                    </transition>
                                </div>
                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Account No</label
                                    >
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">
                                                {{ prefix }}
                                            </span>
                                        </div>
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Account No."
                                            v-model="postfix"
                                            @input="mustNumber"
                                            maxlength="4"
                                            :class="{
                                                'is-invalid': errors.account_no
                                            }"
                                        />
                                    </div>
                                    <transition name="slide-fade">
                                        <p
                                            class="text-danger"
                                            v-if="errors.account_no"
                                        >
                                            {{ errors.account_no[0] }}
                                        </p>
                                        <p class="text-danger" v-if="exist">
                                            Account No. has already been taken!
                                        </p>
                                    </transition>
                                </div>
                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Account Name</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Account Name"
                                        v-model="coa.account_name"
                                        :class="{
                                            'is-invalid': errors.account_name
                                        }"
                                    />
                                    <transition name="slide-fade">
                                        <p
                                            class="text-danger"
                                            v-if="errors.account_name"
                                        >
                                            {{ errors.account_name[0] }}
                                        </p>
                                    </transition>
                                </div>
                                <transition name="slide-fade">
                                    <div
                                        class="form-group"
                                        v-if="coa.account_role == 'Detail'"
                                        data-toggle="appear"
                                        data-class="animated bounceIn"
                                    >
                                        <label for="example-text-input"
                                            >Opening Balance</label
                                        >
                                        <money
                                            class="form-control"
                                            style="text-align: right;"
                                            v-model="
                                                coa.account_opening_balance
                                            "
                                            spellcheck="false"
                                            :class="{
                                                'is-invalid':
                                                    errors.account_opening_balance
                                            }"
                                        ></money>
                                        <p
                                            class="text-danger"
                                            v-if="
                                                errors.account_opening_balance
                                            "
                                        >
                                            {{
                                                errors
                                                    .account_opening_balance[0]
                                            }}
                                        </p>
                                    </div>
                                </transition>
                            </div>
                        </div>
                        <!--END Form-->
                        <!-- Submit -->
                        <div class="row items-push">
                            <div class="col-12 text-center mb-0">
                                <p class="font-size-sm text-muted">
                                    * Require to be filled, Make sure all data is
                                    correctly filled
                                </p>
                                <button
                                    type="submit"
                                    class="btn btn-sm btn-success"
                                    v-ripple="{ center: true }"
                                    :disabled="exist"
                                    @click.prevent="submit"
                                >
                                    <i class="fa fa-fw fa-check mr-1"></i> Save
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-sm btn-alt-light"
                                    tag="button"
                                    @click.prevent="close"
                                >
                                    <i class="fa fa-fw fa-times mr-1"></i> Close
                                </button>
                            </div>
                        </div>
                        <!-- END Submit -->
                    </form>
                </div>
            </div>
            <!-- END Basic -->
        </div>
        <!-- END Page Content -->
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

export default {
    name: "create-coa",

    data() {
        return {
            loadingPage: 0,
            prefix: "1-",
            postfix: "",
            exist: false
        };
    },
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState("coa", {
            coa: state => state.coa
        })
    },
    methods: {
        //VUEX
        ...mapMutations(["CLEAR_ERRORS"]),
        ...mapMutations("coa", ["CLEAR_FORM"]),
        ...mapActions("coa", ["submitCoa", "checkCoaNo"]),

        //METHOD
        doLoading(type) {
            this.loadingPage = type;
            setTimeout(() => {
                this.loadingPage = 0;
            }, 500);
        },
        checkNo() {
            this.coa.account_no = this.prefix + this.postfix;
            if (this.postfix == "") {
                this.exist = false;
                return;
            }
            this.checkCoaNo({
                prefix: this.prefix,
                postfix: this.postfix
            }).then(response => {
                response !== "" ? (this.exist = true) : (this.exist = false);
            });
        },
        setType() {
            if (this.coa.account_role === "Header") {
                this.coa.account_type = "Asset";
                this.coa.account_opening_balance = 0;
                this.setMask();
            } else {
                this.coa.account_type = "Bank";
                this.setMask();
            }
        },
        setMask() {
            this.coa.account_no = this.prefix + this.postfix;
            switch (this.coa.account_type) {
                case "Asset":
                case "Bank":
                case "Account Receivable":
                case "Other Current Asset":
                case "Fixed Asset":
                case "Other Asset":
                    this.prefix = "1-";
                    break;
                case "Liability":
                case "Credit Card":
                case "Account Payable":
                case "Other Current Liability":
                case "Long Term Liability":
                case "Other Liability":
                    this.prefix = "2-";
                    break;
                case "Equity":
                    this.prefix = "3-";
                    break;
                case "Income":
                    this.prefix = "4-";
                    break;
                case "Cost of Sales":
                    this.prefix = "5-";
                    break;
                case "Expense":
                    this.prefix = "6-";
                    break;
                case "Other Income":
                    this.prefix = "8-";
                    break;
                default:
                    this.prefix = "9-";
            }
            this.checkNo();
        },
        mustNumber() {
            // Only for postfix account no. to prevent user input alphabet
            this.postfix = this.postfix.replace(/[^0-9]/g, "");
            this.checkNo();
        },
        submit() {
            this.coa.account_no = this.prefix + this.postfix;
            this.loadingPage = 2;
            this.submitCoa()
                .then(response => {
                    this.$root.$emit("refreshCoa");
                    this.$modal.hide("create-coa");
                    this.alert("Successfully create Account Data ", 1);
                    this.loadingPage = 0;
                })
                .catch(error => {
                    error.response.status == 500
                        ? this.alert(error.response.data.message, 2)
                        : "";
                    error.response.status != 422 && error.response.status != 500
                        ? this.alert(error.message, 2)
                        : "";
                    console.log(error);
                    this.loadingPage = 0;
                });
        },
        close() {
            this.$modal.hide("create-coa");
            document.body.classList.remove("modal-open");
        }
    },
    destroyed() {
        //FORM DI BERSIHKAN
        this.CLEAR_FORM();
        this.CLEAR_ERRORS();
    }
};
</script>
