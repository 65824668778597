<template>
    <main>
        <hero
            title="Report : Balance Sheet"
            prevBreadcrumb="Report"
            routeBreadcrumb="report"
            currentBreadcrumb="Balance Sheet"
        ></hero>

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <div
                class="block col-12 col-md-8 offset-md-2"
                v-if="loadingPage == 1"
                style="height:250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity:0.7"
                            >Generate Report</span
                        >
                    </center>
                </div>
            </div>
            <div
                class="block col-12 col-md-8 offset-md-2"
                v-if="loadingPage == 2"
                style="height:250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity:0.7"
                            >Generate Report</span
                        >
                    </center>
                </div>
            </div>
            <!-- Basic -->
            <div
                class="block col-12 col-md-8 offset-md-2  block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 0"
            >
                <div class="block-content block-content-full">
                    <form
                        method="POST"
                        enctype="multipart/form-data"
                        onsubmit="return false;"
                    >
                        <!--Form-->
                        <div class="row font-size-sm">
                            <div class="col-12 col-md-10 offset-md-1">
                                <div class="row">
                                    <!-- special report for TBM/TRIKARYA -->
                                    <div v-if="this.auth.data.workspace_token == 'workspace_jckhk'" class="form-group col-6 pb-0 pt-1">
                                        <label for="example-text-input"
                                            >Report Type :
                                        </label>
                                        <selectize
                                            class="form-control"
                                            placeholder="Type"
                                            v-model="type"
                                            :class="{
                                                'is-invalid-selectize':
                                                    errors.type
                                            }"
                                        >
                                            <!-- settings is optional -->
                                            <option :value="1">On Date Selected</option>
                                            <option :value="2">Date Range Selected</option>
                                        </selectize>
                                        <transition name="slide-fade">
                                            <p
                                                class="text-danger"
                                                v-if="errors.account_level"
                                            >
                                                {{ errors.account_level[0] }}
                                            </p>
                                        </transition>
                                    </div>
                                    <div v-else class="form-group col-6 pb-0 pt-1">
                                        <label for="example-text-input"
                                            >Report Type :
                                        </label>
                                        <selectize
                                            class="form-control"
                                            placeholder="Type"
                                            v-model="type"
                                            :class="{
                                                'is-invalid-selectize':
                                                    errors.type
                                            }"
                                            disabled
                                        >
                                            <!-- settings is optional -->
                                            <option :value="1">On Date Selected</option>
                                            <!-- <option :value="2">Date Range Selected</option> -->
                                        </selectize>
                                        <transition name="slide-fade">
                                            <p
                                                class="text-danger"
                                                v-if="errors.account_level"
                                            >
                                                {{ errors.account_level[0] }}
                                            </p>
                                        </transition>
                                    </div>
                                    <div class="form-group col-6 pb-0 pt-1">
                                        <label for="example-text-input"
                                            >Account Level :
                                        </label>
                                        <selectize
                                            class="form-control"
                                            placeholder="Choose Account No."
                                            v-model="account_level"
                                            :class="{
                                                'is-invalid-selectize':
                                                    errors.account_level
                                            }"
                                        >
                                            <!-- settings is optional -->
                                            <option :value="1">1</option>
                                            <option :value="2">2</option>
                                            <option :value="3">3</option>
                                            <option :value="4">4</option>
                                        </selectize>
                                        <transition name="slide-fade">
                                            <p
                                                class="text-danger"
                                                v-if="errors.account_level"
                                            >
                                                {{ errors.account_level[0] }}
                                            </p>
                                        </transition>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-6 pb-0 pt-1" v-if="type == 1">
                                        <label for="example-text-input"
                                            >On Date :</label
                                        >
                                        <date-picker
                                            class="input-group"
                                            v-model="date_end"
                                            format="DD/MM/YYYY"
                                            valueType="YYYY-MM-DD"
                                            placeholder="Choose Date"
                                            :class="{
                                                'is-invalid': errors.date_end
                                            }"
                                            @input="perDateSelected()"
                                        ></date-picker>
                                        <transition name="slide-fade">
                                            <p
                                                class="text-danger"
                                                v-if="errors.date_end"
                                            >
                                                {{ errors.date_end[0] }}
                                            </p>
                                        </transition>
                                    </div>
                                    <div class="form-group col-6 pb-0 pt-1" v-if="type == 2">
                                        <label for="example-text-input"
                                            >Date Range From :</label
                                        >
                                        <date-picker
                                            class="input-group"
                                            v-model="date_start"
                                            format="DD/MM/YYYY"
                                            valueType="YYYY-MM-DD"
                                            placeholder="Choose Date"
                                            :class="{
                                                'is-invalid': errors.date_start
                                            }"
                                        ></date-picker>
                                        <transition name="slide-fade">
                                            <p
                                                class="text-danger"
                                                v-if="errors.date_start"
                                            >
                                                {{ errors.date_start[0] }}
                                            </p>
                                        </transition>
                                    </div>
                                    <div class="form-group col-6 pb-0 pt-1" v-if="type == 2">
                                        <label for="example-text-input">
                                            To :</label
                                        >
                                        <date-picker
                                            class="input-group"
                                            v-model="date_end"
                                            format="DD/MM/YYYY"
                                            valueType="YYYY-MM-DD"
                                            placeholder="Choose Date"
                                            :class="{
                                                'is-invalid': errors.date_end
                                            }"
                                            :default-value="new Date()"
                                        ></date-picker>
                                        <transition name="slide-fade">
                                            <p
                                                class="text-danger"
                                                v-if="errors.date_end"
                                            >
                                                {{ errors.date_end[0] }}
                                            </p>
                                        </transition>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--END Form-->
                        <!-- Submit -->
                        <div class="row items-push">
                            <div class="col-12 text-center">
                                <p class="font-size-sm text-muted">
                                    * Require to be filled, Make sure all data is
                                    correctly filled
                                </p>
                                <router-link
                                    type="button"
                                    class="btn btn-sm btn-info"
                                    :to="{
                                        name: 'balance-sheet-display',
                                        params: {
                                            date_start: date_start,
                                            date_end: date_end,
                                            account_level: account_level,
                                            type: type
                                        }
                                    }"
                                    tag="button"
                                    v-ripple="{ center: true }"
                                >
                                    <i class="fa fa-print mr-1"></i> Display
                                    Report
                                </router-link>
                                <!-- <button
                                    type="button"
                                    class="btn btn-sm btn-danger"
                                    v-ripple="{ center: true }"
                                    @click.prevent="pdfReport"
                                >
                                    <i class="fa fa-file-pdf mr-1"></i> Export to PDF
                                </button> -->
                                <button
                                    type="button"
                                    class="btn btn-sm btn-success"
                                    v-ripple="{ center: true }"
                                    @click.prevent="xlsReport"
                                >
                                    <i class="fa fa-r fa-file-excel mr-1"></i>
                                    Export to Excel
                                </button>
                            </div>
                        </div>
                        <!-- END Submit -->
                    </form>
                </div>
            </div>
            <!-- END Basic -->
        </div>
        <!-- END Page Content -->
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import moment from "moment";

export default {
    name: "balance-sheet",

    data() {
        return {
            loadingPage: 0,
            date_start: moment()
                .startOf("year")
                .format("YYYY-MM-DD"),
            date_end: moment().format("YYYY-MM-DD"),
            account_level: 1,
            type: 1
        };
    },
    computed: {
        ...mapState(["errors","auth"]) //MENGAMBIL STATE ERRORS
    },
    methods: {
        ...mapActions("report", ["pdfReportBS", "xlsReportBS"]),

        //METHOD
        perDateSelected(){
            this.date_start = this.date_end;
        },
        pdfReport() {
            this.loadingPage = 2;
            this.pdfReportBS({
                date_start: this.date_start,
                date_end: this.date_end,
                account_level: this.account_level,
                type: this.type
            })
                .then(response => {
                    this.loadingPage = 0;
                })
                .catch(error => {
                    console.log(error);
                    this.loadingPage = 0;
                });
        },
        xlsReport() {
            this.loadingPage = 2;
            this.xlsReportBS({
                date_start: this.date_start,
                date_end: this.date_end,
                account_level: this.account_level,
                type: this.type
            })
                .then(response => {
                    this.loadingPage = 0;
                })
                .catch(error => {
                    console.log(error);
                    this.loadingPage = 0;
                });
        }
    }
};
</script>
