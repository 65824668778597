<template>
    <canvas id="chart-dashboard-sales" height="0"></canvas>
</template>
<script>
import { mapActions, mapState, mapMutations } from "vuex";

export default {
    name: "chart-dashboard-sale",
    data() {
        return {
            chartId: "chart-dashboard-sales",
            type: "line",
            data: {
                labels: [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec"
                ],
                datasets: []
            },
            options: {
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                                callback: function(value, index, values) {
                                    if (
                                        parseInt(value) >= 1000 ||
                                        parseInt(value) <= 1000
                                    ) {
                                        return (value >= 100000 ||
                                        value <= -100000
                                            ? value / 1000000
                                            : value
                                        )
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                "."
                                            );
                                    } else {
                                        return value;
                                    }
                                }
                            },
                            gridLines: {
                                display: true
                            }
                        }
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                display: true
                            }
                        }
                    ]
                },
                legend: {
                    display: true
                },
                tooltips: {
                    display: true,
                    callbacks: {
                        label: function(tooltipItem, data) {
                            if (
                                parseInt(tooltipItem.yLabel) >= 1000 ||
                                parseInt(tooltipItem.yLabel) <= 1000
                            ) {
                                return (
                                    "Rp " +
                                    tooltipItem.yLabel
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                );
                            } else {
                                return "Rp " + tooltipItem.yLabel;
                            }
                        }
                    }
                },
                responsive: true,
                maintainAspectRatio: false
            }
        };
    },
    mounted() {
        this.dataChart();
    },
    methods: {
        ...mapActions("bill", ["generateDataChartSales"]),
        dataChart() {
            this.generateDataChartSales()
                .then(response => {
                    let dataSet = [];
                    // let backgroundColor = ["rgba(220,220,220,.3)", "rgba(112,185,235,.5)"];
                    // let borderColor = ["rgba(220,220,220,1)", "rgba(112,185,235,1)"];
                    let backgroundColor = ["rgba(112,185,235,.5)"];
                    let borderColor = ["rgba(112,185,235,1)"];
                    let data = response.data;
                    let i = 0;
                    $.each(data, function(key, value) {
                        let dataAmountBill = [];
                        $.each(value, function(key2, value2) {
                            dataAmountBill.push(
                                value2.Bills == 0 ? 0 : value2.Bills
                            );
                        });
                        dataSet.push({
                            label: key,
                            backgroundColor: backgroundColor[i],
                            fill: true,
                            borderColor: borderColor[i],
                            pointBackgroundColor: borderColor[i],
                            pointBorderColor: "#fff",
                            pointHoverBackgroundColor: "#fff",
                            pointHoverBorderColor: borderColor[i],
                            //Data to be represented on y-axis
                            data: dataAmountBill
                        });
                        i++;
                    });
                    if (dataSet.length == 0) {
                        this.$emit("emptySalesChild", true);
                        this.$emit("loadingSalesChild", false);
                    } else {
                        this.data.datasets = dataSet;
                        this.createChart();
                        this.$emit("loadingSalesChild", false);
                    }
                })
                .catch(error => {
                    this.$emit("loadingSalesChild", false);
                    this.$emit("emptySalesChild", true);
                    console.log(error);
                });
        },
        createChart() {
            const ctx = document.getElementById(this.chartId);
            ctx.height = 250;
            const myChart = new Chart(ctx, {
                type: this.type,
                data: this.data,
                options: this.options
            });
        },
        refreshChart() {
            vm.$forceUpdate();
        }
    }
};
</script>
