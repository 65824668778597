<template>
    <main>
        <hero title="User" prevBreadcrumb="Dashboard" routeBreadcrumb="home" currentBreadcrumb="User"></hero>

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <div
                class="block block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 1 || !users.data"
                style="height:250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity:0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <!-- Dynamic Table Full -->
            <div class="block block-rounded block-mode-loading-oneui" v-else>
                <div class="block-content block-content-full pb-0 pt-3">
                    <div class="row">
                        <div
                            class="col-12 col-md-4 col-lg-4 col-xl-6 mb-2 mb-sm-0 pb-0"
                        >
                            <label>Show :</label>
                            <select
                                class="form-control-sm custom-select"
                                style="max-width:115px"
                                v-model="rows"
                            >
                                <option value="10">10 Rows</option>
                                <option value="20">20 Rows</option>
                                <option value="50">50 Rows</option>
                                <option value="100">100 Rows</option>
                                <option value="All">All Rows</option>
                            </select>
                        </div>
                        <div class="col-12 col-md-8 col-lg-8 col-xl-6 pb-0">
                            <input
                                type="text"
                                class="form-control form-control form-control-sm col-12 col-md-6 float-right ml-1 mt-1 search-index"
                                placeholder="Search.."
                                v-model="search"
                            />
                            <!-- <button
                                type="button"
                                class="btn btn-sm btn-success btn-icon float-right m-1"
                                v-ripple="{ center: true }"
                            >
                                <i class="fa fa-file-excel"></i>
                            </button>
                            <button
                                type="button"
                                class="btn btn-sm btn-danger btn-icon float-right m-1"
                                v-ripple="{ center: true }"
                            >
                                <i class="fa fa-file-pdf"></i>
                            </button> -->
                            <router-link
                                type="button"
                                class="btn btn-sm btn-info float-right m-1"
                                :to="{ name: 'user-create' }"
                                tag="button"
                                v-ripple="{ center: true }"
                            >
                                <i class="fa fa-plus mr-1"></i> Add Data
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="block-content block-content-full">
                    <div class="table-responsive">
                        <table class="table  table-vcenter table-hover">
                            <thead style="background-color: whitesmoke;">
                                <tr>
                                    <th
                                        class="table-header-relative text-center"
                                        style="width: 80px;"
                                    >
                                        No.
                                    </th>
                                    <th
                                        class="table-header-relative sort-head column-minwidth-150"
                                        @click="sortBy('name')"
                                    >
                                        Name
                                        <i
                                            class="fa fa-sort sort-icon"
                                            v-bind:class="{
                                                active: sort == 'name'
                                            }"
                                        ></i>
                                    </th>
                                    <th
                                        class="table-header-relative sort-head column-minwidth-150"
                                        @click="sortBy('username')"
                                    >
                                        Username
                                        <i
                                            class="fa fa-sort sort-icon"
                                            v-bind:class="{
                                                active: sort == 'username'
                                            }"
                                        ></i>
                                    </th>
                                    <th
                                        class="table-header-relative sort-head column-minwidth-150"
                                        @click="sortBy('email')"
                                    >
                                        Email
                                        <i
                                            class="fa fa-sort sort-icon"
                                            v-bind:class="{
                                                active: sort == 'email'
                                            }"
                                        ></i>
                                    </th>
                                    <th
                                        class="table-header-relative"
                                        style="width: 15%;"
                                    >
                                        Phone
                                    </th>
                                    <th
                                        class="table-header-relative sort-head column-minwidth-150"
                                        @click="sortBy('updated_at')"
                                        style="width: 15%;"
                                    >
                                        Last Login
                                        <i
                                            class="fa fa-sort sort-icon"
                                            v-bind:class="{
                                                active: sort == 'updated_at'
                                            }"
                                        ></i>
                                    </th>
                                </tr>
                            </thead>
                            <tbody v-if="users.data && users.data.length > 0">
                                <tr v-if="loadingPage == 2">
                                    <td colspan="6">
                                        <grid-loader
                                            class="mx-auto mt-5"
                                            :color="'#70B9EB'"
                                            :size="'10px'"
                                        ></grid-loader>
                                        <center class="mt-2 mb-5">
                                            <span
                                                class="text-center"
                                                style="opacity:0.7"
                                                >Loading</span
                                            >
                                        </center>
                                    </td>
                                </tr>
                                <router-link
                                    v-else
                                    :to="{
                                        name: 'user-edit',
                                        params: {
                                            name: user.name,
                                            username: user.username
                                        }
                                    }"
                                    tag="tr"
                                    v-for="(user, index) in users.data"
                                    :key="index"
                                >
                                    <td class="text-center font-size-sm">
                                        {{ index + 1 }}
                                    </td>
                                    <td class="font-size-sm">
                                        {{ user.name }}
                                    </td>
                                    <td class="font-size-sm">
                                        {{ user.username }}
                                    </td>
                                    <td class="font-size-sm">
                                        {{ user.email }}
                                    </td>
                                    <td class="font-size-sm">
                                        {{
                                            user.phone != "" ? user.phone : "-"
                                        }}
                                    </td>
                                    <td>
                                        <em
                                            class="text-muted font-size-sm"
                                            v-if="user.updated_at != null"
                                        >
                                            {{
                                                user.updated_at
                                                    | moment(
                                                        "HH:MM DD/MM/YYYY "
                                                    )
                                            }}
                                        </em>
                                        <em
                                            class="text-muted font-size-sm"
                                            v-else
                                        >
                                            -
                                        </em>
                                    </td>
                                </router-link>
                            </tbody>
                            <tbody v-else>
                                <tr class="data-empty">
                                    <td
                                        class="text-center"
                                        colspan="6"
                                        style="opacity:0.8; padding:2.5rem;"
                                    >
                                        <center>
                                            <img
                                                :src="
                                                    '/media/proxima/empty-data.svg'
                                                "
                                                style="height: 100px;"
                                            />
                                        </center>
                                        <br /><span style="opacity:0.6;"
                                            >Data Not Found</span
                                        >
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p
                            class="text-muted font-size-sm d-inline d-sm-none"
                            style="opacity:0.5;"
                        >
                            Swipe left here to see full column >>
                        </p>
                    </div>

                    <!-- <div class="text-center" v-if="users.meta.last_page">
                        <v-pagination
                            v-model="page"
                            :length="users.meta.last_page"
                            :total-visible="6"
                        ></v-pagination>
                    </div> -->

                    <paginate
                        v-model="page"
                        :page-count="this.$store.state.user.last_page"
                        :prev-text="'Prev'"
                        :next-text="'Next'"
                        :container-class="'pagination mt-3 mt-md-0'"
                        :pageClass="'page-item'"
                        :pageLinkClass="'page-link'"
                        :prevClass="'page-item'"
                        :prevLinkClass="'page-link'"
                        :nextClass="'page-item'"
                        :nextLinkClass="'page-link'"
                    >
                    </paginate>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
    name: "DataUser",
    data() {
        return {
            search: "",
            awaitingSearch: false,
            rows: 20,
            loadingPage: 1,
            sort: "username",
            asc: true
        };
    },
    created() {
        this.getUsers({
            search: this.search,
            rows: this.rows,
            sort: this.sort,
            asc: this.asc
        })
            .then(response => {
                this.loadingPage = 0;
            })
            .catch(error => {
                console.log(error);
                this.loadingPage = 0;
            });
    },
    computed: {
        ...mapState("user", {
            users: state => state.users
        }),
        page: {
            get() {
                return this.$store.state.user.page
                    ? this.$store.state.user.page
                    : 1;
            },
            set(val) {
                this.$store.commit("user/SET_PAGE", val);
                this.getUsers({
                    search: this.search,
                    rows: this.rows,
                    rowsUpdate: 0,
                    sort: this.sort,
                    asc: this.asc
                });
            }
        }
    },
    watch: {
        search() {
            this.loadingPage = 2;
            if (!this.awaitingSearch) {
                setTimeout(() => {
                    this.getUsers({
                        search: this.search,
                        rows: this.rows,
                        rowsUpdate: 1,
                        sort: this.sort,
                        asc: this.asc
                    })
                        .then(response => {
                            this.loadingPage = 0;
                        })
                        .catch(error => {
                            console.log(error);
                            this.loadingPage = 0;
                        });
                    this.awaitingSearch = false;
                }, 1500);
            }
            this.awaitingSearch = true;
        },
        rows() {
            this.getUsers({
                search: this.search,
                rows: this.rows,
                rowsUpdate: 1,
                sort: this.sort,
                asc: this.asc
            });
        }
    },
    methods: {
        ...mapActions("user", ["getUsers", "removeUser"]),
        sortBy(val) {
            this.asc = this.sort == val ? !this.asc : false;
            this.sort = val;
            this.getUsers({
                search: this.search,
                rows: this.rows,
                rowsUpdate: 1,
                sort: this.sort,
                asc: this.asc
            });
        }
    }
};
</script>
