var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('hero',{attrs:{"title":"Create UOM","prevBreadcrumb":"UOM Data","routeBreadcrumb":"uom","currentBreadcrumb":"Create UOM"}}),_vm._v(" "),_c('div',{staticClass:"content"},[(_vm.loadingPage == 1)?_c('div',{staticClass:"block col-12 col-md-8 offset-md-2 block-rounded block-mode-loading-oneui",staticStyle:{"height":"250px"}},[_c('div',{staticClass:"block-content block-content-full pb-0",staticStyle:{"padding-top":"80px"}},[_c('grid-loader',{staticClass:"mx-auto",attrs:{"color":'#70B9EB',"size":'10px'}}),_vm._v(" "),_c('center',{staticClass:"mt-2"},[_c('span',{staticClass:"text-center",staticStyle:{"opacity":"0.7"}},[_vm._v("Loading")])])],1)]):_vm._e(),_vm._v(" "),(_vm.loadingPage == 2)?_c('div',{staticClass:"block col-12 col-md-8 offset-md-2 block-rounded block-mode-loading-oneui",staticStyle:{"height":"250px"}},[_c('div',{staticClass:"block-content block-content-full pb-0",staticStyle:{"padding-top":"80px"}},[_c('grid-loader',{staticClass:"mx-auto",attrs:{"color":'#70B9EB',"size":'10px'}}),_vm._v(" "),_c('center',{staticClass:"mt-2"},[_c('span',{staticClass:"text-center",staticStyle:{"opacity":"0.7"}},[_vm._v("Save Data")])])],1)]):_vm._e(),_vm._v(" "),(_vm.loadingPage == 0)?_c('div',{staticClass:"block col-12 col-md-8 offset-md-2  block-rounded block-mode-loading-oneui"},[_c('div',{staticClass:"block-content block-content-full"},[_c('form',{attrs:{"method":"POST","encuom":"multipart/form-data","onsubmit":"return false;"}},[_c('div',{staticClass:"row font-size-sm"},[_c('div',{staticClass:"col-12 col-md-10 offset-md-1"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Unit Name *")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.uom.uom_name),expression:"uom.uom_name"}],staticClass:"form-control",class:{
                                        'is-invalid': _vm.errors.uom_name,
                                    },attrs:{"uom":"text","placeholder":"Unit Name"},domProps:{"value":(_vm.uom.uom_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.uom, "uom_name", $event.target.value)}}}),_vm._v(" "),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.errors.uom_name)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                        "+_vm._s(_vm.errors.uom_name[0])+"\n                                    ")]):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Unit Code *")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.uom.uom_code),expression:"uom.uom_code"}],staticClass:"form-control",class:{
                                        'is-invalid': _vm.errors.uom_code,
                                    },attrs:{"uom":"text","placeholder":"Unit Code"},domProps:{"value":(_vm.uom.uom_code)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.uom, "uom_code", $event.target.value)}}}),_vm._v(" "),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.errors.uom_code)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                        "+_vm._s(_vm.errors.uom_code[0])+"\n                                    ")]):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Unit Description")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.uom.uom_desc),expression:"uom.uom_desc"}],staticClass:"form-control",class:{
                                        'is-invalid': _vm.errors.uom_desc,
                                    },attrs:{"uom":"text","placeholder":"Unit Description"},domProps:{"value":(_vm.uom.uom_desc)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.uom, "uom_desc", $event.target.value)}}}),_vm._v(" "),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.errors.uom_desc)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                        "+_vm._s(_vm.errors.uom_desc[0])+"\n                                    ")]):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-6 pb-1 pt-0"},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Status *")]),_vm._v(" "),_c('selectize',{staticClass:"form-control mt-1",class:{
                                            'is-invalid-selectize':
                                                _vm.errors.uom_status,
                                        },attrs:{"placeholder":"Choose Status"},model:{value:(_vm.uom.uom_status),callback:function ($$v) {_vm.$set(_vm.uom, "uom_status", $$v)},expression:"uom.uom_status"}},[_c('option',{attrs:{"value":"1"}},[_vm._v("\n                                            Active\n                                        ")]),_vm._v(" "),_c('option',{attrs:{"value":"0"}},[_vm._v("\n                                            Inactive\n                                        ")])]),_vm._v(" "),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.errors.uom_status)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                            "+_vm._s(_vm.errors.uom_status[0])+"\n                                        ")]):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"form-group col-6 pb-1 pt-0"},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Type *")]),_vm._v(" "),_c('selectize',{staticClass:"form-control mt-1",class:{
                                            'is-invalid-selectize':
                                                _vm.errors.uom_type,
                                        },attrs:{"placeholder":"Choose Account No."},model:{value:(_vm.uom.uom_type),callback:function ($$v) {_vm.$set(_vm.uom, "uom_type", $$v)},expression:"uom.uom_type"}},[_c('option',{attrs:{"value":"1"}},[_vm._v("\n                                            Base Unit\n                                        ")]),_vm._v(" "),_c('option',{attrs:{"value":"2"}},[_vm._v("\n                                            Conversion Unit\n                                        ")])]),_vm._v(" "),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.errors.uom_type)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                            "+_vm._s(_vm.errors.uom_type[0])+"\n                                        ")]):_vm._e()])],1)]),_vm._v(" "),(_vm.uom.uom_type == 2)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-6 pb-1 pt-0"},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Base Unit")]),_vm._v(" "),_c('selectize',{staticClass:"form-control",class:{
                                            'is-invalid-selectize':
                                                _vm.errors.uom_conversion_head,
                                        },attrs:{"placeholder":"Choose Type"},model:{value:(
                                            _vm.uom.uom_conversion_head
                                        ),callback:function ($$v) {_vm.$set(_vm.uom, "uom_conversion_head", $$v)},expression:"\n                                            uom.uom_conversion_head\n                                        "}},_vm._l((_vm.uoms),function(uomdata){return _c('option',{key:uomdata.id,domProps:{"value":uomdata.id}},[_vm._v("\n                                            "+_vm._s(uomdata.uom_name)+"\n                                        ")])}),0),_vm._v(" "),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.errors.uom_conversion_head)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                            "+_vm._s(_vm.errors.uom_conversion_head[0])+"\n                                        ")]):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"form-group col-6 pb-1 pt-0"},[_c('label',{attrs:{"for":"example-text-input"}},[_vm._v("Value")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.uom.uom_conversion_val),expression:"uom.uom_conversion_val"}],staticClass:"form-control",class:{
                                            'is-invalid': _vm.errors.uom_name,
                                        },attrs:{"uom":"text","placeholder":"Unit Name"},domProps:{"value":(_vm.uom.uom_conversion_val)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.uom, "uom_conversion_val", $event.target.value)}}}),_vm._v(" "),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.errors.uom_name)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                                            "+_vm._s(_vm.errors.uom_name[0])+"\n                                        ")]):_vm._e()])],1)]):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"row items-push"},[_c('div',{staticClass:"col-12 text-center"},[_c('p',{staticClass:"font-size-sm text-muted"},[_vm._v("\n                                * Required filled, Make sure all data is\n                                correctly filled\n                            ")]),_vm._v(" "),_c('button',{directives:[{name:"ripple",rawName:"v-ripple",value:({ center: true }),expression:"{ center: true }"}],staticClass:"btn btn-sm btn-success",attrs:{"uom":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('i',{staticClass:"fa fa-fw fa-check mr-1"}),_vm._v(" Save\n                            ")]),_vm._v(" "),_c('router-link',{directives:[{name:"ripple",rawName:"v-ripple",value:({ center: true }),expression:"{ center: true }"}],staticClass:"btn btn-sm btn-alt-light",attrs:{"uom":"button","tag":"button","to":{ name: 'uom' }}},[_c('i',{staticClass:"fa fa-fw fa-times mr-1"}),_vm._v(" Close\n                            ")])],1)])])])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }