<template>
    <main>
        <hero title="Edit Category" prevBreadcrumb="Asset Category Data" routeBreadcrumb="asset-category" currentBreadcrumb="Edit Category"></hero>

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <div
                class="block col-12 col-md-8 offset-md-2 block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 1"
                style="height: 250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Loading</span
                        >
                    </center>
                </div>
            </div>
            <div
                class="block col-12 col-md-8 offset-md-2 block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 2"
                style="height: 250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Save Data</span
                        >
                    </center>
                </div>
            </div>
            <!-- Basic -->
            <div
                class="block col-12 col-md-8 offset-md-2  block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 0"
            >
                <div class="block-content block-content-full">
                    <form
                        method="POST"
                        enctype="multipart/form-data"
                        onsubmit="return false;"
                    >
                        <!--Form-->
                        <div class="row font-size-sm">
                            <div class="col-12 col-md-10 offset-md-1">
                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Category Name *</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Category Name"
                                        v-model="category.category_name"
                                        :class="{
                                            'is-invalid': errors.category_name,
                                        }"
                                    />
                                    <transition name="slide-fade">
                                        <p
                                            class="text-danger"
                                            v-if="errors.category_name"
                                        >
                                            {{ errors.category_name[0] }}
                                        </p>
                                    </transition>
                                </div>
                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Depreciate Method *</label
                                    >
                                    <selectize
                                        class="form-control mt-1"
                                        placeholder="Choose Account No."
                                        v-model="category.depreciate_method"
                                        :class="{
                                            'is-invalid-selectize':
                                                errors.depreciate_method,
                                        }"
                                    >
                                        <option value="none">
                                            No. Depreciation
                                        </option>
                                        <option value="straight">
                                            Straight Line
                                        </option>
                                        <!-- <option value="double">Double Declining</option> -->
                                    </selectize>
                                    <transition name="slide-fade">
                                        <p
                                            class="text-danger"
                                            v-if="errors.depreciate_method"
                                        >
                                            {{ errors.depreciate_method[0] }}
                                        </p>
                                    </transition>
                                </div>
                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Useful Life (Year) *</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Useful Life"
                                        v-model="category.year_life"
                                        :class="{
                                            'is-invalid': errors.year_life,
                                        }"
                                        maxlength="2"
                                        @input="mustNumber()"
                                    />
                                    <transition name="slide-fade">
                                        <p
                                            class="text-danger"
                                            v-if="errors.year_life"
                                        >
                                            {{ errors.year_life[0] }}
                                        </p>
                                    </transition>
                                </div>
                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Asset Account *</label
                                    >
                                    <selectize
                                        class="form-control mt-1"
                                        placeholder="Choose Account No."
                                        v-model="category.coa_id"
                                        :class="{
                                            'is-invalid-selectize':
                                                errors.coa_id,
                                        }"
                                    >
                                        <!-- settings is optional -->
                                        <option
                                            v-for="account in assetAccounts"
                                            :value="account.id"
                                            :key="account.id"
                                        >
                                            {{ account.account_no }}
                                            {{ account.account_name }}
                                        </option>
                                    </selectize>
                                    <transition name="slide-fade">
                                        <p
                                            class="text-danger"
                                            v-if="errors.coa_id"
                                        >
                                            {{ errors.coa_id[0] }}
                                        </p>
                                    </transition>
                                </div>
                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Depreciation Account *</label
                                    >
                                    <selectize
                                        class="form-control mt-1"
                                        placeholder="Choose Account No."
                                        v-model="
                                            category.depreciation_coa_id
                                        "
                                        :class="{
                                            'is-invalid-selectize':
                                                errors.depreciation_coa_id,
                                        }"
                                    >
                                        <!-- settings is optional -->
                                        <option
                                            v-for="account in depreciationAccounts"
                                            :value="account.id"
                                            :key="account.id"
                                        >
                                            {{ account.account_no }}
                                            {{ account.account_name }}
                                        </option>
                                    </selectize>
                                    <transition name="slide-fade">
                                        <p
                                            class="text-danger"
                                            v-if="
                                                errors.depreciation_coa_id
                                            "
                                        >
                                            {{
                                                errors
                                                    .depreciation_coa_id[0]
                                            }}
                                        </p>
                                    </transition>
                                </div>
                                <div class="form-group">
                                    <label for="example-text-input"
                                        >Accumulated Account *</label
                                    >
                                    <selectize
                                        class="form-control mt-1"
                                        placeholder="Choose Account No."
                                        v-model="
                                            category.accumulated_coa_id
                                        "
                                        :class="{
                                            'is-invalid-selectize':
                                                errors.accumulated_coa_id,
                                        }"
                                    >
                                        <!-- settings is optional -->
                                        <option
                                            v-for="account in accumulatedAccounts"
                                            :value="account.id"
                                            :key="account.id"
                                        >
                                            {{ account.account_no }}
                                            {{ account.account_name }}
                                        </option>
                                    </selectize>
                                    <transition name="slide-fade">
                                        <p
                                            class="text-danger"
                                            v-if="errors.accumulated_coa_id"
                                        >
                                            {{
                                                errors.accumulated_coa_id[0]
                                            }}
                                        </p>
                                    </transition>
                                </div>
                            </div>
                        </div>
                        <!--END Form-->
                        <!-- Submit -->
                        <div class="row items-push">
                            <div class="col-12 text-center">
                                <p class="font-size-sm text-muted">
                                    * Required filled, Make sure all data is
                                    correctly filled
                                </p>
                                <button
                                    type="button"
                                    class="btn btn-sm btn-success"
                                    v-ripple="{ center: true }"
                                    @click.prevent="submitData"
                                >
                                    <i class="fa fa-fw fa-check mr-1"></i>
                                    Update
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-sm btn-danger"
                                    v-ripple="{ center: true }"
                                    @click.prevent="deleteData"
                                >
                                    <i class="fa fa-fw fa-trash mr-1"></i>
                                    Delete
                                </button>
                                <router-link
                                    type="button"
                                    class="btn btn-sm btn-alt-light"
                                    tag="button"
                                    v-ripple="{ center: true }"
                                    :to="{ name: 'asset-category' }"
                                >
                                    <i class="fa fa-fw fa-times mr-1"></i> Close
                                </router-link>
                            </div>
                        </div>
                        <!-- END Submit -->
                    </form>
                </div>
            </div>
            <!-- END Basic -->
            <!-- END Basic -->
        </div>
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import moment from "moment";

export default {
    name: "edit-bills",
    data() {
        return {
            loadingPage: 1,
            items: [],
            disabled: {},
            coaCategorys: [],
            forbiddenData: false,
        };
    },
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState("assetcategory", { category: (state) => state.category }),
        ...mapState("coa", { coas: (state) => state.coas.accounts }),
        ...mapState("forbiddendate", {
            forbiddenDateLast: (state) => state.forbiddenDateLast,
        }),
        assetAccounts: function () {
            if (this.coas) {
                return this.coas.filter(function (coa) {
                    return coa.account_type === "Fixed Asset";
                });
            }
        },
        accumulatedAccounts: function () {
            if (this.coas) {
                return this.coas.filter(function (coa) {
                    return coa.account_type === "Fixed Asset";
                });
            }
        },
        depreciationAccounts: function () {
            if (this.coas) {
                return this.coas.filter(function (coa) {
                    return (
                        coa.account_type === "Expense" ||
                        coa.account_type === "Other Expense"
                    );
                });
            }
        },
    },
    created() {
        this.getCoaAll();
        if (this.$route.params.id) {
            this.editCategory(this.$route.params.id)
                .then((response) => {
                    this.loadingPage = 0;
                })
                .catch((error) => {
                    console.log(error);
                    this.loadingPage = 0;
                });
        } else {
            this.$router.push({ name: "asset-category" });
            this.alert("Data not found, select data first", 2);
        }
    },
    methods: {
        //VUEX
        ...mapMutations("assetcategory", ["CLEAR_FORM"]),
        ...mapActions("coa", ["getCoaAll"]),
        ...mapMutations(["CLEAR_ERRORS"]),
        ...mapActions("assetcategory", [
            "editCategory",
            "updateCategory",
            "removeCategory",
        ]),
        mustNumber() {
            this.category.year_life = this.category.year_life.replace(
                /[^0-9]/g,
                ""
            );
        },
        submitData() {
            this.loadingPage = 2;
            this.CLEAR_ERRORS();
            this.updateCategory(this.$route.params.id)
                .then((response) => {
                    this.CLEAR_FORM();
                    this.alert("Successfully create Category Data ", 1);
                    this.loadingPage = 0;
                    this.$router.push({ name: "asset-category" });
                })
                .catch((error) => {
                    error.response.status == 500
                        ? this.alert(error.response.data.message, 2)
                        : "";
                    error.response.status != 422 && error.response.status != 500
                        ? this.alert(error.message, 2)
                        : "";
                    this.loadingPage = 0;
                });
        },
        deleteData() {
            this.$swal({
                title: "Are you sure ?",
                text: "Deleted data cannot be recovery",
                icon: "warning",
                buttons: ["Cancel", "Delete"],
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    this.loadingPage = 1;
                    this.removeCategory(this.$route.params.id)
                        .then(() => {
                            this.alert("Data has been deleted !", 1);
                            this.loadingPage = 0;
                            this.$router.push({ name: "asset-category" });
                        })
                        .catch((error) => {
                            error.response.status == 500
                                ? this.alert(error.response.data.message, 2)
                                : "";
                            error.response.status != 422 &&
                            error.response.status != 500
                                ? this.alert(error.message, 2)
                                : "";
                            this.loadingPage = 0;
                        });
                }
            });
        },
    },
    destroyed() {
        this.CLEAR_FORM();
    },
};
</script>
