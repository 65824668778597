<template>
    <main>
        <hero
            title="Report : Purchase"
            prevBreadcrumb="Report"
            routeBreadcrumb="report"
            currentBreadcrumb="Purchase"
        ></hero>

        <!-- Page Content -->
        <div class="content">
            <!--Loading-->
            <div
                class="block col-12 col-md-8 offset-md-2 block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 1"
                style="height: 250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Generate Report</span
                        >
                    </center>
                </div>
            </div>
            <div
                class="block col-12 col-md-8 offset-md-2 block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 2"
                style="height: 250px"
            >
                <div
                    class="block-content block-content-full pb-0"
                    style="padding-top: 80px"
                >
                    <grid-loader
                        class="mx-auto"
                        :color="'#70B9EB'"
                        :size="'10px'"
                    ></grid-loader>
                    <center class="mt-2">
                        <span class="text-center" style="opacity: 0.7"
                            >Generate Report</span
                        >
                    </center>
                </div>
            </div>
            <!-- Basic -->
            <div
                class="block col-12 col-md-8 offset-md-2  block-rounded block-mode-loading-oneui"
                v-if="loadingPage == 0"
            >
                <div class="block-content block-content-full">
                    <form
                        method="POST"
                        enctype="multipart/form-data"
                        onsubmit="return false;"
                    >
                        <!--Form-->
                        <div class="row font-size-sm">
                            <div class="col-12 col-md-10 offset-md-1">
                                <div class="row">
                                    <div class="form-group col-6 pb-0 pt-1">
                                        <label for="example-text-input"
                                            >Name Supplier *</label
                                        >
                                        <selectize
                                            class="form-control"
                                            placeholder="Supplier Name"
                                            v-model="supplier_id"
                                            :class="{
                                                'is-invalid-selectize':
                                                    errors.supplier_id,
                                            }"
                                        >
                                            <option value="All">
                                                All Supplier
                                            </option>
                                            <!-- settings is optional -->
                                            <option
                                                v-for="contact in contacts"
                                                :value="contact.id"
                                                :key="contact.id"
                                            >
                                                {{ contact.contact_name }}
                                            </option>
                                        </selectize>
                                        <transition name="slide-fade">
                                            <p
                                                class="text-danger"
                                                v-if="errors.supplier_id"
                                            >
                                                {{ errors.supplier_id[0] }}
                                            </p>
                                        </transition>
                                    </div>
                                    <div class="form-group col-6 pb-0 pt-1">
                                        <label for="example-text-input"
                                            >Bill Type :</label
                                        >
                                        <selectize
                                            class="form-control"
                                            placeholder="Bills Type"
                                            v-model="bill_type"
                                            :class="{
                                                'is-invalid-selectize':
                                                    errors.bill_type,
                                            }"
                                        >
                                            <option value="All">
                                                All Type
                                            </option>
                                            <option value="Order">Order</option>
                                            <option value="Quotation">
                                                Quotation
                                            </option>
                                            <option value="Invoice">
                                                Open Invoice
                                            </option>
                                            <option value="CloseInvoice">
                                                Close Invoice
                                            </option>
                                        </selectize>
                                        <transition name="slide-fade">
                                            <p
                                                class="text-danger"
                                                v-if="errors.bill_type"
                                            >
                                                {{ errors.bill_type[0] }}
                                            </p>
                                        </transition>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-6 pb-0 pt-1">
                                        <label for="example-text-input"
                                            >Date Range From :</label
                                        >
                                        <date-picker
                                            class="input-group"
                                            v-model="date_start"
                                            format="DD/MM/YYYY"
                                            valueType="YYYY-MM-DD"
                                            placeholder="Choose Date"
                                            :class="{
                                                'is-invalid': errors.date_start,
                                            }"
                                        ></date-picker>
                                        <transition name="slide-fade">
                                            <p
                                                class="text-danger"
                                                v-if="errors.date_start"
                                            >
                                                {{ errors.date_start[0] }}
                                            </p>
                                        </transition>
                                    </div>
                                    <div class="form-group col-6 pb-0 pt-1">
                                        <label for="example-text-input">
                                            To :</label
                                        >
                                        <date-picker
                                            class="input-group"
                                            v-model="date_end"
                                            format="DD/MM/YYYY"
                                            valueType="YYYY-MM-DD"
                                            placeholder="Choose Date"
                                            :class="{
                                                'is-invalid': errors.date_end,
                                            }"
                                            :default-value="new Date()"
                                        ></date-picker>
                                        <transition name="slide-fade">
                                            <p
                                                class="text-danger"
                                                v-if="errors.date_end"
                                            >
                                                {{ errors.date_end[0] }}
                                            </p>
                                        </transition>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--END Form-->
                        <!-- Submit -->
                        <div class="row items-push">
                            <div class="col-12 text-center">
                                <p class="font-size-sm text-muted">
                                    * Require to be filled, Make sure all data is
                                    correctly filled
                                </p>
                                <router-link
                                    type="button"
                                    class="btn btn-sm btn-info"
                                    :to="{
                                        name: 'purchase-report-display',
                                        params: {
                                            date_start: date_start,
                                            date_end: date_end,
                                            supplier_id: supplier_id,
                                            bill_type: bill_type,
                                        },
                                    }"
                                    tag="button"
                                    v-ripple="{ center: true }"
                                >
                                    <i class="fa fa-print mr-1"></i> Display
                                    Report
                                </router-link>
                                <!-- <button
                                    type="button"
                                    class="btn btn-sm btn-danger"
                                    style="cursor:default;"
                                    v-ripple="{ center: true }"
                                    @click.prevent="pdfReport"
                                >
                                    <i class="fa fa-file-pdf mr-1"></i> Export to PDF
                                </button> -->
                                <!-- <button
                                    @click="xlsReport()"
                                    class="btn btn-sm btn-success"
                                    style="cursor: default"
                                    v-ripple="{ center: true }"
                                >
                                    <i class="fa fa-r fa-file-excel mr-1"></i>
                                    Export to Excel
                                </button> -->
                            </div>
                        </div>
                        <!-- END Submit -->
                    </form>
                </div>
            </div>
            <!-- END Basic -->
        </div>
        <!-- END Page Content -->
    </main>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import moment from "moment";

export default {
    name: "general-ledger",

    data() {
        return {
            loadingPage: 1,
            date_start: moment().startOf("year").format("YYYY-MM-DD"),
            date_end: moment().format("YYYY-MM-DD"),
            supplier_id: "All",
            bill_type: "Invoice",
        };
    },
    computed: {
        ...mapState(["errors"]), //MENGAMBIL STATE ERRORS
        ...mapState(["auth"]),
        ...mapState("coa", { coas: (state) => state.coas.accounts }),
        ...mapState("contact", { contacts: (state) => state.contacts }),

        filteredCOA: function () {
            if (this.coas) {
                if (
                    this.auth.role.includes("masterAccount") ||
                    this.auth.role.includes("devAccount") ||
                    this.auth.role.includes("accChief") ||
                    this.auth.role.includes("accStakeholder") ||
                    this.auth.role.includes("accStaffReportGL")
                ) {
                    return this.coas.filter(function (coa) {
                        return coa;
                    });
                } else if (this.auth.role.includes("accStaffReportGLBank")) {
                    return this.coas.filter(function (coa) {
                        return coa.account_type === "Bank";
                    });
                }
            }
        },
    },
    mounted() {
        this.getContactSupplierList()
            .then((response) => {
                this.loadingPage = 0;
            })
            .catch((error) => {
                this.alert(error.message, 2);
                this.loadingPage = 0;
            });
    },
    methods: {
        ...mapActions("coa", ["getCoaAll"]),
        ...mapActions("report", ["pdfReportGL", "xlsReportGL"]),
        ...mapActions("contact", ["getContactSupplierList"]),

        pdfReport() {
            this.loadingPage = 2;
            this.pdfReportGL({
                date_start: this.date_start,
                date_end: this.date_end,
                account_end: this.account_end,
                account_start: this.account_start,
            })
                .then((response) => {
                    this.loadingPage = 0;
                })
                .catch((error) => {
                    console.log(error);
                    this.loadingPage = 0;
                });
        },
        xlsReport() {
            this.loadingPage = 2;
            this.xlsReportGL({
                date_start: this.date_start,
                date_end: this.date_end,
                account_end: this.account_end,
                account_start: this.account_start,
            })
                .then((response) => {
                    this.loadingPage = 0;
                })
                .catch((error) => {
                    console.log(error);
                    this.loadingPage = 0;
                });
        },
    },
};
</script>
