import $axios from "../../api.js";

const state = () => ({
    warehouses: [],

    warehouse: {
        warehouse_id: 0,
        warehouse_name: "",
        warehouse_location: "",
        warehouse_status: "Active",
        user_id: 0,
        inventoryData: {},
        inMutation: {},
        outMutation: {}
    },
    page: 1, //UNTUK MENCATAT PAGE PAGINATE YANG SEDANG DIAKSES
    last_page: 1,
    search: ""
});

const mutations = {
    ASSIGN_DATA(state, payload) {
        state.warehouses = payload;
        state.last_page = payload.meta.last_page;
        state.page = payload.meta.current_page;
    },
    SET_PAGE(state, payload) {
        state.page = payload;
    },
    ASSIGN_FORM(state, payload) {
        state.warehouse = {
            warehouse_id: payload.id,
            warehouse_name: payload.warehouse_name,
            warehouse_location: payload.warehouse_location,
            warehouse_status: payload.warehouse_status,
            user_id: payload.user_id,
            inventoryData: payload.inventoryData,
            inMutation: payload.inMutation,
            outMutation: payload.outMutation
        };
    },
    //ME-RESET STATE NEWS MENJADI KOSONG
    CLEAR_FORM(state) {
        state.warehouse = {
            warehouse_id: 0,
            warehouse_name: "",
            warehouse_location: "",
            warehouse_status: "Active",
            user_id: 0,
            inventoryData: {},
            inMutation: {},
            outMutation: {}
        };
    },
    CLEAR_DATA(state) {
        state.warehouses = [];
    }
};

const actions = {
    getWarehouse({ dispatch, commit, state }, payload) {
        let search = typeof payload.search != "undefined" ? payload.search : "";
        let rows = payload.rows;
        let page =
            typeof payload.rows != "All" && payload.rowsUpdate != 1
                ? state.page
                : "1";

        return new Promise((resolve, reject) => {
            $axios
                .get(`/warehouse?page=${page}&search=${search}&rows=${rows}`)
                .then(response => {
                    commit("ASSIGN_DATA", response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                    reject(error);
                });
        });
    },
    getWarehouseAll({ dispatch, state }) {
        $axios
            .get(`/warehouse/retrive-all-data`)
            .then(response => {
                state.warehouses = response.data;
                this.loading = false;
            })
            .catch(error => {
                error.response.status === 401
                    ? dispatch("DO_LOGOUT", null, { root: true })
                    : "";
                reject(error);
            });
    },
    submitWarehouse({ dispatch, commit, state }) {
        return new Promise((resolve, reject) => {
            $axios
                .post(`/warehouse`, state.warehouse)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                });
        });
    },
    editWarehouse({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.get(`/warehouse/${payload}/edit`).then(response => {
                commit("ASSIGN_FORM", response.data);
                resolve(response.data);
            });
        });
    },
    updateWarehouse({ state, commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .put(`/warehouse/${payload}`, state.warehouse)
                .then(response => {
                    //FORM DIBERSIHKAN
                    commit("CLEAR_FORM");
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                });
        });
    },
    //MENGHAPUS DATA
    removeWarehouse({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .delete(`/warehouse/${payload}`)
                .then(response => {
                    commit("CLEAR_FORM");
                    resolve(response.data);
                })
                .catch(error => {
                    if (error.response) {
                        commit("SET_ERRORS", error.response.data, {
                            root: true
                        });
                    }
                    reject(error);
                });
        });
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
