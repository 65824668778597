import Vue from "vue";

import $axios from "../../api.js";

const state = () => ({
    expenses: [],

    expense: {
        bills_id: 0,
        bill_type: "Expenses",
        bill_contact_id: "",
        bill_contact_beginning_balance: 0,
        bill_date: Vue.moment().format("YYYY-MM-DD"),
        bill_due_date: Vue.moment().format("YYYY-MM-DD"),
        bill_shipaddress: "",
        bill_status: 5,
        bill_number: "",
        bill_coa_id: "",
        bill_journal_memo: "",
        bill_sub_total: 0,
        bill_tax_total: 0,
        bill_grand_total: 0,
        bill_paid_total: 0,
        bill_transaction: [
            {
                transaction_tax_id: "",
                transaction_tax_rate: 0,
                transaction_coa_id: "",
                transaction_description: "",
                transaction_amount: 0
            }
        ],
        bill_transaction_delete: [],
        contact: {},
        journal: {},
        created_by: "",
        created_at: ""
    },
    page: 1, //UNTUK MENCATAT PAGE PAGINATE YANG SEDANG DIAKSES
    last_page: 1,
    search: ""
});

const mutations = {
    ASSIGN_DATA(state, payload) {
        state.expenses = payload;
        state.last_page = payload.meta.last_page;
        state.page = payload.meta.current_page;
    },
    SET_PAGE(state, payload) {
        state.page = payload;
    },
    ASSIGN_FORM(state, payload) {
        state.expense = {
            bills_id: payload.id,
            bill_type: payload.bill_type,
            bill_contact_id: payload.bill_contact_id,
            bill_contact_beginning_balance:
                payload.bill_contact_beginning_balance,
            bill_date: payload.bill_date,
            bill_due_date: payload.bill_due_date,
            bill_shipaddress: payload.bill_shipaddress,
            bill_status: payload.bill_status,
            bill_number: payload.bill_number,
            bill_journal_memo: payload.bill_journal_memo,
            bill_coa_id: payload.bill_coa_id,
            bill_sub_total: payload.bill_sub_total,
            bill_tax_total: payload.bill_tax_total,
            bill_grand_total: payload.bill_grand_total,
            bill_paid_total: payload.bill_paid_total,
            bill_transaction: payload.transaction,
            bill_transaction_delete: [],
            journal: payload.journal,
            contact: payload.contact,
            created_by: payload.created_by,
            created_at: payload.created_at
        };
    },
    //ME-RESET STATE NEWS MENJADI KOSONG
    CLEAR_FORM(state) {
        state.expense = {
            bills_id: 0,
            bill_type: "Expenses",
            bill_contact_id: "",
            bill_contact_beginning_balance: 0,
            bill_date: Vue.moment().format("YYYY-MM-DD"),
            bill_due_date: Vue.moment().format("YYYY-MM-DD"),
            bill_shipaddress: "",
            bill_status: 1,
            bill_number: "",
            bill_coa_id: "",
            bill_journal_memo: "",
            bill_sub_total: 0,
            bill_tax_total: 0,
            bill_grand_total: 0,
            bill_paid_total: 0,
            bill_transaction: [
                {
                    transaction_tax_id: "",
                    transaction_tax_rate: 0,
                    transaction_coa_id: "",
                    transaction_description: "",
                    transaction_amount: 0
                }
            ],
            bill_transaction_delete: [],
            journal: {},
            contact: {},
            created_by: "",
            created_at: ""
        };
    },
    CLEAR_DATA(state) {
        state.expenses = [];
    }
};

const actions = {
    getExpenseNo({ dispatch, commit, state }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .get(`/expense/get-expense-no/` + state.expense.bill_date)
                .then(response => {
                    state.expense.bill_number = response.data;
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    console.log(error);
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                });
        });
    },
    getExpense({ dispatch, commit, state }, payload) {
        let type = payload.type;
        let rows = payload.rows;
        let date_start = payload.date_start;
        let date_end = payload.date_end;

        return new Promise((resolve, reject) => {
            $axios
                .get(
                    `/expense?rows=${rows}&type=${type}&date_start=${date_start}&date_end=${date_end}`
                )
                .then(response => {
                    commit("ASSIGN_DATA", response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                    reject(error);
                });
        });
    },
    getExpensePerProyek({ dispatch, commit, state }, payload) {
        let type = payload.type;
        let rows = payload.rows;

        return new Promise((resolve, reject) => {
            $axios
                .get(`/expense-proyek?rows=${rows}&type=${type}`)
                .then(response => {
                    commit("ASSIGN_DATA", response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                    reject(error);
                });
        });
    },
    getExpenseContact({ dispatch, commit, state }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .get(`/expense/get-expense-contact/${payload}`)
                .then(response => {
                    resolve(response.data);
                });
        });
    },
    getExpenseAmount({ dispatch, commit, state }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .get(`/expense/total-amount`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                    reject(error);
                });
        });
    },
    getExpenseAmountWeekly({ dispatch, commit, state }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .get(`/expense/total-amount-weekly`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                    reject(error);
                });
        });
    },
    getExpenseAmountWeeklyProject({ dispatch, commit, state }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .get(`/expense/total-amount-weekly-project`)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    error.response.status === 401
                        ? dispatch("DO_LOGOUT", null, { root: true })
                        : "";
                    reject(error);
                });
        });
    },
    submitExpense({ dispatch, commit, state }) {
        return new Promise((resolve, reject) => {
            $axios
                .post(`/expense`, state.expense)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                });
        });
    },
    editExpense({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.get(`/expense/${payload}/edit`).then(response => {
                commit("ASSIGN_FORM", response.data.data);
                resolve(response.data);
            });
        });
    },
    updateExpense({ state, commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .put(`/expense/${payload}`, state.expense)
                .then(response => {
                    //FORM DIBERSIHKAN
                    commit("CLEAR_FORM");
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                    if (error.response.status === 422) {
                        commit("SET_ERRORS", error.response.data.errors, {
                            root: true
                        });
                    }
                });
        });
    },
    printExpense({ dispatch, commit, state }, payload) {
        return new Promise((resolve, reject) => {
            $axios({
                url: "/expense/print/" + payload.bills_id,
                method: "GET",
                responseType: "blob" // important
            }).then(response => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute(
                    "download",
                    payload.type + " " + payload.bills_no + ".pdf"
                );
                document.body.appendChild(link);
                link.click();
                resolve(response.data);
            });
        });
    },
    removeExpense({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios
                .delete(`/expense/${payload}`)
                .then(response => {
                    commit("CLEAR_FORM");
                    resolve(response.data);
                })
                .catch(error => {
                    if (error.response) {
                        commit("SET_ERRORS", error.response.data, {
                            root: true
                        });
                    }
                    reject(error);
                });
        });
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
